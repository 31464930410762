//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './index.css';

/// for user story 5508
function CoachMeetingRequest({ openMeetingRequestDialog, cmsData, fetchLabel }) {
    return (
        <>
            <Container fluid>
                <Row className="coachMeetingRequestContainerRow">
                    <Col md="xs" lg="9">
                        <div className="schedule_meeting" >
                            <div className="schedule_coaching_session-title">{cmsData && cmsData?.ScheduleCoachingSession_Title}</div>
                            <div className="schedule_coaching_session-description">{fetchLabel("MyCoachPage.MyCoachScheduleCoachingSession.ExecutiveCoachingSessions", "ScheduleCoachingSession_Labels")}</div>
                        </div>
                    </Col>
                    <Col md="xs" lg="3" className="d-flex align-items-center justify-content-start justify-content-lg-center">
                        <div>
                            <Cirrus.Button
                                onClick={() => { openMeetingRequestDialog(true) }}
                                className='request-meeting-button'
                            >
                                {fetchLabel("MyCoachPage.MyCoachScheduleCoachingSession.RequestMeeting", "ScheduleCoachingSession_Labels")}
                            </Cirrus.Button>
                        </div>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CoachMeetingRequest;
