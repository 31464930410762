//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Row, Col, Container } from "react-bootstrap";

import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import sideimage from "./sideimage.png";
import { AssessmentActions } from "../AssessmentActions";
import "../style.css";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function ReviewAccomplishments() {
  const context = useContext(Context);
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cmsRA, setCmsRA] = useState({});
  const [assessmentStartPageData, setAssessmentStartPageData] = useState();
  const [flagForStartPageDataAPI, setFlagForStartPageDataAPI] = useState(false);
  let path = JSON.parse(window.sessionStorage.getItem("setPath"));

  useEffect(() => {
    fetchCMSData();
    window.scrollTo(0, 0);
  }, [context.language]);

  const fetchCMSData = async () => {
    setScreenLoader(true);
    let refCmsRA = JSON.parse(JSON.stringify(cmsRA));
    let resType = "";
    let payload = {
      PageName: "ReviewingAccomplishments",
    };
    await AssessmentActions.GetAssessmentCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data;

          resType = resData?.ID;
          refCmsRA["id"] = resData?.ID;
          refCmsRA["externalId"] = resData?.ExternalId;
          refCmsRA["title"] = resData?.Title;
          refCmsRA["shortDescription"] = resData?.ShortDescription;
          refCmsRA["subTitle"] = resData?.SubTitle;
          refCmsRA["description"] = resData?.Description;
          refCmsRA["focusArea"] = resData?.FocusArea[0];
          refCmsRA["resourceType"] = resData?.ResourceType;
          refCmsRA["resourceCategoryType"] = resData?.ResourceCategoryType;
          refCmsRA["duration"] = resData?.Duration;
          refCmsRA["largeImage"] = resData?.LargeImage;
          refCmsRA["mediumImage"] = resData?.MediumImage;
          refCmsRA["smallImage"] = resData?.SmallImage;

          let label = [
            ...resData?.Components?.CommonComponent?.Labels,
            ...resData?.Components?.CommonResourceComponent?.Labels,
          ];

          refCmsRA["allLabel"] = label;
          refCmsRA["allLOVList"] =
            resData?.Components?.CommonComponent?.LOVList[0]?.Values;

          let nameArr = [];
          resData?.ChildPages?.map((name) => {
            if (
              name.Key ===
              "Assessments.ReviewingAccomplishments.AccomplishmentTitlePage"
            ) {
              nameArr.push({
                key: name.Key,
                name: name.Title,
              });
              nameArr.push({
                key: name.Components[
                  "Resources.Assessments.ReviewingAccomplishments.AccomplishmentTitle"
                ].Key,
                name: name.Components[
                  "Resources.Assessments.ReviewingAccomplishments.AccomplishmentTitle"
                ].QuestionList[0].QuestionTitle,
              });
            }
            if (
              name.Key === "Assessments.ReviewingAccomplishments.SkillsPage"
            ) {
              nameArr.push({
                key: name.Key,
                name: name.Title,
              });
            }
          });

          setCmsRA(refCmsRA);
          saveActivity(refCmsRA?.id, "startassessment", refCmsRA?.title);
          TrackActions.EventTrack(appInsights, "ViewCard", {
            "Entity Id": refCmsRA?.id,
            "Entity Name": refCmsRA?.title,
            CardType: "Assessment",
          });
          TrackActions.PageAction("ViewCard", {
            "Entity Id": refCmsRA?.id,
            "Entity Name": refCmsRA?.title,
            CardType: "Assessment",
          });
          context.setCmsRAchildPagesAndLabel({
            masterCMS: resData,
            masterLabel: label,
            masterLOVList:
              resData?.Components?.CommonComponent?.LOVList[0]?.Values,
            nameArr: nameArr,
          });
          setFlagForStartPageDataAPI(true);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const saveActivity = (id, type, title) => {
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: id,
      type: type,
      value: title ? title : "null",
    };
    AssessmentActions.CreateActivity(payload).then(
      (res) => {
        if (res.status === 200) {
          AssessmentActions.GetCreatedActivity().then(
            (res) => {},
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveAndLikeHandler = async (type) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: cmsRA.id,
      learningResourceType: cmsRA.resourceCategoryType?.ID,
      learningResourceStatus: assessmentStartPageData.learningResourceStatus,
      resourceTypeId: cmsRA.resourceType?.ID,
      isRetaken: assessmentStartPageData.isRetaken,
      lastAnsweredPageId: assessmentStartPageData.lastAnsweredPageId,
      isSaved:
        type === "save"
          ? assessmentStartPageData?.isSaved === true
            ? false
            : true
          : assessmentStartPageData?.isSaved,
      isLiked:
        type === "like"
          ? assessmentStartPageData?.isLiked === true
            ? false
            : true
          : assessmentStartPageData?.isLiked,
      isDownloaded: assessmentStartPageData?.isDownloaded,
      isSharedWithCoach: assessmentStartPageData?.isSharedWithCoach,
      resultDocumentId: assessmentStartPageData?.resultDocumentId,
    };
    await AssessmentActions.SaveAssessmentStatus(
      cmsRA.resourceCategoryType?.ID,
      cmsRA.id,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          setFlagForStartPageDataAPI(true);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const updateAssessmentStatusHandler = async () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setScreenLoader(true);
    if (
      assessmentStartPageData.learningResourceStatus?.toLowerCase() ===
      fetchLOVListID("ResourceStatus.Completed").toLowerCase()
    ) {
      await AssessmentActions.RetakeAssessment(cmsRA.id).then(
        (res) => {
          if (res.status === 200) {
            TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
              InternalAssessmentId: cmsRA?.id,
              InternalAssessmentName: cmsRA?.title,
            });
            TrackActions.PageAction("StartinternalAssessment", {
              InternalAssessmentId: cmsRA?.id,
              InternalAssessmentName: cmsRA?.title,
            });
            setScreenLoader(false);
            navigate(
              "/" +
                pageOrgin.id +
                "/reviewing-accomplishments/questions" +
                "/addAnother=false"
            );
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );
    } else {
      let payload = {
        candidateId: candidateID,
        learningResourceId: cmsRA.id,
        learningResourceStatus: fetchLOVListID("ResourceStatus.InProgress"),
        learningResourceType: cmsRA.resourceCategoryType?.ID,
        resourceTypeId: cmsRA.resourceType?.ID,
        isSaved: assessmentStartPageData?.isSaved,
        isLiked: assessmentStartPageData?.isLiked,
        isRetaken: assessmentStartPageData.isRetaken,
        lastAnsweredPageId: assessmentStartPageData.lastAnsweredPageId,
        isDownloaded: assessmentStartPageData?.isDownloaded,
        isSharedWithCoach: assessmentStartPageData?.isSharedWithCoach,
        resultDocumentId: assessmentStartPageData?.resultDocumentId,
      };
      await AssessmentActions.SaveAssessmentStatus(
        cmsRA.resourceCategoryType?.ID,
        cmsRA.id,
        payload
      ).then(
        (res) => {
          if (res.status === 200) {
            TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
              InternalAssessmentId: cmsRA?.id,
              InternalAssessmentName: cmsRA?.title,
            });
            TrackActions.PageAction("StartinternalAssessment", {
              InternalAssessmentId: cmsRA?.id,
              InternalAssessmentName: cmsRA?.title,
            });
            setScreenLoader(false);
            navigate(
              "/" +
                pageOrgin.id +
                "/reviewing-accomplishments/questions" +
                "/addAnother=false"
            );
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );
    }
  };

  useEffect(() => {
    if (flagForStartPageDataAPI) {
      fetchStartPageData();
    }
  }, [flagForStartPageDataAPI]);

  const fetchStartPageData = async () => {
    let resourceType = "assessmentsandtasks";
    let payload = {
      learningResourceType: resourceType,
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: resourceType,
    };

    await AssessmentActions.GetStartPageData(payload).then(
      (res) => {
        if (res.status === 200) {
          let data = res?.data && res?.data?.responsemodel;
          let resData = data?.filter(
            (x) => x.key === "Assessments.ReviewingAccomplishments"
          );
          setAssessmentStartPageData(resData[0]);
          setFlagForStartPageDataAPI(false);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLOVListValue = (key) => {
    let refCmsRA = JSON.parse(JSON.stringify(cmsRA));
    let label = "";
    if (refCmsRA?.allLOVList) {
      refCmsRA?.allLOVList.map((x) => {
        if (x.Key === key) {
          label = x.Value;
        }
      });
    }
    return label;
  };
  const fetchLOVListID = (key) => {
    let refCmsRA = JSON.parse(JSON.stringify(cmsRA));
    let label = "";
    if (refCmsRA?.allLOVList) {
      refCmsRA?.allLOVList.map((x) => {
        if (x.Key === key) {
          label = x.ID;
        }
      });
    }
    return label;
  };

  const fetchLabel = (key) => {
    let refCmsRA = JSON.parse(JSON.stringify(cmsRA));
    let label = "";
    if (refCmsRA?.allLabel) {
      refCmsRA?.allLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchStatus = (status) => {
    if (status !== undefined && status !== "") {
      if (
        status?.toLowerCase() === fetchLOVListID("ResourceStatus.InProgress")
      ) {
        return <>{fetchLOVListValue("ResourceStatus.InProgress")}</>;
      } else if (
        status?.toLowerCase() === fetchLOVListID("ResourceStatus.Completed")
      ) {
        return <>{fetchLOVListValue("ResourceStatus.Completed")}</>;
      } else {
        return (
          <>
            {cmsRA?.resourceType?.Value} <sup>.</sup>
            <span>{cmsRA?.duration}</span>
          </>
        );
      }
    } else {
      return (
        <>
          {cmsRA?.resourceType?.Value} <sup>.</sup>
          <span>{cmsRA?.duration}</span>
        </>
      );
    }
  };

  const fetchButtonStatus = (status) => {
    if (status !== undefined && status !== "") {
      if (
        status?.toLowerCase() === fetchLOVListID("ResourceStatus.InProgress")
      ) {
        return <>{fetchLabel("CommonComponent.CommonContinueButton")}</>;
      } else if (
        status?.toLowerCase() === fetchLOVListID("ResourceStatus.Completed")
      ) {
        return <>{fetchLabel("CommonComponentCommonRetakeButton")}</>;
      } else {
        return <>{fetchLabel("CommonComponent.CommonStartButton")}</>;
      }
    } else {
      return <>{fetchLabel("CommonComponent.CommonStartButton")}</>;
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="assessment-page">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      {loadSpinner && (
        <div className="loader">
          <Cirrus.Spinner size={50} />
        </div>
      )}

      <>
        <Container
          fluid
          className="background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20"
        >
          <Row className="justify-content-between">
            <Col md="12" lg="7" className="mb-3 pb-lg-5">
              <Cirrus.Button
                isSecondary
                onClick={() => {
                  navigate(`/${context.path ? context.path : path}`);
                }}
                className="mb-lg-5 mb-3"
              >
                {fetchLabel("CommonComponent.CommonBackButton")}
              </Cirrus.Button>
              <Cirrus.Header size="h3" className="assessment-title">
                {cmsRA?.title}
              </Cirrus.Header>
              <div className="save-section mt-4 mb-3">
                <button
                  className="saveBtn"
                  onClick={() => saveAndLikeHandler("save")}
                >
                  {assessmentStartPageData?.isSaved === true ? (
                    <i className="icon-saved"></i>
                  ) : (
                    <i className="icon-save"></i>
                  )}
                  <span className="saveBtnText">
                    {assessmentStartPageData?.isSaved === true
                      ? fetchLabel("CommonResourceComponent.Saved")
                      : fetchLabel("CommonComponent.CommonSaveButton")}
                  </span>
                </button>
                <Cirrus.Label id="category-label">
                  {cmsRA?.focusArea?.Value}
                </Cirrus.Label>
                <Cirrus.Label id="cardtype">
                  {fetchStatus(assessmentStartPageData?.learningResourceStatus)}
                </Cirrus.Label>
              </div>
              <div>
                <Cirrus.Button
                  size="small"
                  className="likeBtn"
                  onClick={() => saveAndLikeHandler("like")}
                >
                  {assessmentStartPageData?.isLiked === true ? (
                    <Icon icon="heart_fill" size={16} color="#9D323D" />
                  ) : (
                    <Icon icon="heart" size={16} color="#000" />
                  )}
                  <span className="likeBtnText">
                    {" "}
                    {assessmentStartPageData?.likeCount === 0
                      ? ""
                      : assessmentStartPageData?.likeCount}{" "}
                    {assessmentStartPageData?.likeCount === 0 ||
                    assessmentStartPageData?.likeCount === 1
                      ? fetchLabel(
                          "CommonComponent.CommonLikeText"
                        ).toUpperCase()
                      : fetchLabel(
                          "CommonComponent.CommonLikesText"
                        ).toUpperCase()}
                  </span>
                </Cirrus.Button>
              </div>
            </Col>
            <Col md="12" lg="5" className="position-relative">
              <div className="assesment_startpage-image-wrapper">
                <img
                  src={cmsRA?.largeImage?.ImageUrl}
                  alt="sideimage"
                  className="image-overflow"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-lg-5 p-3 pb-lg-0 pb-md-0">
          <Row>
            <Col md="12" lg="6" className="assessment_second-container-intro">
              <Cirrus.Headline
                size="Medium"
                className="mb-5 assessment_introHeading"
              >
                {cmsRA?.shortDescription}
              </Cirrus.Headline>
              <Cirrus.Header size="h5" className="mb-3">
                {cmsRA?.subTitle}
              </Cirrus.Header>
              <p className="mb-3">
                <Cirrus.RichText
                  content={cmsRA?.description}
                  className="instructions"
                />
              </p>

              {assessmentStartPageData?.learningResourceStatus ===
                "notstarted" && (
                <Cirrus.Button
                  className="my-5"
                  onClick={updateAssessmentStatusHandler}
                >
                  {fetchButtonStatus(
                    assessmentStartPageData?.learningResourceStatus
                  )}
                </Cirrus.Button>
              )}
            </Col>
            <Col md="6" lg="6"></Col>
          </Row>

          {assessmentStartPageData?.learningResourceStatus !== "notstarted" && (
            <Row className="mb-5">
              {assessmentStartPageData?.learningResourceStatus ===
                fetchLOVListID("ResourceStatus.Completed") && (
                <Col lg="12">
                  <Cirrus.Header size="h5" className="mb-3">
                    {fetchLabel(
                      "CommonResourceComponent.AssessmentCompletedMessage"
                    )}
                  </Cirrus.Header>
                </Col>
              )}

              <Col lg="12">
                <Cirrus.Button
                  className="me-2 mb-3"
                  disabled={
                    assessmentStartPageData?.learningResourceStatus ===
                    fetchLOVListID("ResourceStatus.Completed")
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    navigate(
                      "/" +
                        pageOrgin.id +
                        "/reviewing-accomplishments/questions" +
                        "/addAnother=true"
                    );
                  }}
                >
                  {fetchLabel(
                    "CommonResourceComponent.AddAnotherAccomplishment"
                  )}
                </Cirrus.Button>

                {/* <Cirrus.Button
                                    isSecondary
                                    className='me-2 mb-3'
                                    onClick={(e) => {
                                        navigate("/" + pageOrgin.id + "/reviewing-accomplishments/review")
                                    }}
                                >
                                    {fetchLabel("CommonResourceComponent.EditExistingAccomplishment")}
                                </Cirrus.Button> */}
                <Cirrus.Button
                  isSecondary
                  className="me-2 mb-3"
                  onClick={updateAssessmentStatusHandler}
                >
                  {fetchButtonStatus(
                    assessmentStartPageData?.learningResourceStatus
                  )}
                </Cirrus.Button>
              </Col>
            </Row>
          )}
        </Container>
      </>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}

export default ReviewAccomplishments;
