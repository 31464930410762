//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';





const http = new httpHelper();

export const EventsActions = {
    GetEventsCMS: getEventsCMS,
    SaveEvents: saveEvents,
    GetEvents : getEvents,
    GetOnDemandEvents : getOnDemandEvents,
    GetEventDetails : getEventDetails,
    EnrollSession : enrollSession,
    UnEnrollSession : unEnrollSession,
    UpdateEvent : updateEvent,
    GetDoceboSessionLink:getDoceboSessionLink,
    saveEventCalender : saveEventCalender,
    MarkAttendance : markAttendance
    
}



function getEventsCMS(payload) {
    return http.CMSpost(environments.GET_EVENTS_LABELS, payload);
}

function saveEvents(payload) {
    return http.AssessmentsPost(environments.SAVE_EVENTS, payload);
}

function getEvents(payload) {
    return http.GetEvents(environments.GET_EVENTS, payload);
}

function getOnDemandEvents(payload) {
    return http.CMSpost(environments.GET_ONDEMAND_EVENTS, payload);
}

function getEventDetails(id) {
    let eventsResults = environments.GET_EVENT_DETAILS.replace('{id}', id);
    return http.GetEvents(eventsResults);
}
function updateEvent(payload, id) {
    let url = environments.UPDATE_EVENT.replace('{id}', id);
    return http.updateEvents(url, payload);
}
function enrollSession(payload) {
    return http.GetEvents(environments.ENROLL_SESSION, payload);
}
function unEnrollSession(payload) {
    return http.GetEvents(environments.UNENROLL_SESSION, payload);
}
function getDoceboSessionLink() {
    return http.GetDoceboUrl(environments.GET_DOCEBO_LINK);
}
function saveEventCalender(payload) {
    return http.GetEvents(environments.SAVE_EVENT_CALENDER, payload);
}
function markAttendance(payload) {
    return http.updateEvents(environments.MARK_ATTENDANCE, payload);
}




