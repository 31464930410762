//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from "../../common/helpers/HttpHelper";
import environments from "../../common/environments/environment";
const http = new httpHelper();

export const AboutMeActions = {
  GetCMSAboutMeHeaderLabels: GetCMSAboutMeHeaderLabels,
};

function GetCMSAboutMeHeaderLabels(payload) {
  return http.CMSpost(environments.GET_ABOUTME_LABELS, payload);
}
