//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import './AutoComplete.css';
import * as Cirrus from 'manpowergroup-cirrus';

function AutoComplete({ noDataFound, inputId, inputLabel, inputName, inputValue, onChangeHandler, suggestionsItem, inputPlaceholder, selectItemHandler }) {
    return (
        <> {
            <div className='autoWrap'>
                <Cirrus.Label htmlFor="Example"> {inputLabel} </Cirrus.Label>
                <input
                    id={inputId}
                    label={inputLabel}
                    name={inputName}
                    autoComplete="off"
                    // value={suggestionsItem.text}
                    value={inputValue}
                    onChange={onChangeHandler}
                    type={"text"}
                    className='inputStyle'
                    placeholder={inputPlaceholder}
                />
                {noDataFound && (
                    <Cirrus.ErrorMessage>
                        No matches found
                    </Cirrus.ErrorMessage>
                )}
                {suggestionsItem.suggestions.length > 0 && (
                    <div className='itemWrap'>
                        {suggestionsItem.suggestions.map((item) => (
                            <div className='item' key={item.code}>
                                <div className='itemButton'
                                    key={item.code}
                                    onClick={() => {
                                        selectItemHandler(item)
                                    }}
                                >
                                    {item.Name}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        }
        </>
    )
}

export default AutoComplete
