import React, { useState, useEffect } from "react";
import "./JobCompareModal.css";

const JobCompareModal = (props) => {
  console.log("props", props);
  const [showModal, setShowModal] = useState(false);
  const [jobdata, setJobData] = useState([]);
  let jobtitle = [];
  let companyName = [];
  let location = [];
  //let startDate = [];
  let datePosted = [];
  let salary = [];
  let deadline = [];
  let employmentType = [];
  let contractType = [];
  let description = [];
  props?.jobs.map((job) => {
    if (job.jobRole) {
      jobtitle.push(<td>{job.jobRole}</td>);
    } else {
      jobtitle.push(<td></td>);
    }
    if (job.companyName) {
      companyName.push(<td>{job.companyName}</td>);
    } else {
      companyName.push(<td></td>);
    }
    if (job.location) {
      location.push(<td>{job.location}</td>);
    } else {
      location.push(<td></td>);
    }
    // if (job.startDate) {
    //   startDate.push(<td>{job.startDate}</td>);
    // } else {
    //   startDate.push(<td></td>);
    // }
    if (job.datePosted) {
      datePosted.push(<td>{job.datePosted}</td>);
    } else {
      datePosted.push(<td></td>);
    }
    if (job.deadline) {
      deadline.push(<td>{job.deadline}</td>);
    } else {
      deadline.push(<td></td>);
    }
    if (job.salary) {
      salary.push(<td>{job.salary}</td>);
    } else {
      salary.push(<td></td>);
    }
    if (job.contractType) {
      contractType.push(<td>{job.contractType}</td>);
    } else {
      contractType.push(<td></td>);
    }
    if (job.employmentType) {
      employmentType.push(<td>{job.employmentType}</td>);
    } else {
      employmentType.push(<td></td>);
    }
    if (job.description) {
      description.push(
        <td>
          <div className="description">{job.description}</div>
        </td>
      );
    } else {
      description.push(
        <td>
          <div className="description"></div>
        </td>
      );
    }
  });
  return (
    <>
      {/* <button id="myBtn" onClick={() => setShowModal(true)}>
        Open Modal
      </button> */}
      {/* {showModal && (
        <div id="myModal" className={showModal ? "newmodal show" : "newmodal"}> */}
      <div id="myModal" className="newmodal show">
        <div className="newmodal-content">
          <div className="newmodal-header">
            <div className="newmodal-title">
              <h2>{props?.heading}</h2>
            </div>
            <div className="closeCont">
              <button
                type="button"
                className="modalClose"
                aria-label="Close"
                onClick={() => props?.closeModal()}
                // onClick={() => setShowModal(false)}
              >
                <span className="visually-hidden"></span>
                <i className="sc-bdvvtL dYnyLf icon-close "></i>
              </button>
            </div>
          </div>
          <div className="newmodal-body">
            <div className="scrollable">
              <table>
                <tbody>
                  <tr>
                    <td className="">{props?.JobTitle}</td>
                    {jobtitle}
                  </tr>
                  <tr>
                    <td className="">{props?.Company}</td>
                    {companyName}
                  </tr>
                  <tr>
                    <td className="">{props?.Location}</td>
                    {location}
                  </tr>
                  {/* <tr>
                    <td className="">{props?.StartDate}</td>
                    {startDate}
                  </tr> */}
                  <tr>
                    <td className="">{props?.DatePosted}</td>
                    {datePosted}
                  </tr>
                  <tr>
                    <td className="">{props?.Deadline}</td>
                    {deadline}
                  </tr>
                  <tr>
                    <td className="">{props?.Salary}</td>
                    {salary}
                  </tr>
                  <tr>
                    <td className="">{props?.EmploymentType}</td>
                    {employmentType}
                  </tr>
                  <tr>
                    <td className="">{props?.ContractType}</td>
                    {contractType}
                  </tr>
                  <tr>
                    <td className="">{props?.JobDescription}</td>
                    {description}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="newmodal-footer">
            <h3></h3>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default JobCompareModal;
