//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { OnBoardingActions } from "../OnBoardingActions";
import background from "manpowergroup-cirrus/assets/images/background.png";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import Header from "../../header/simpleHeader";
import { useMsal } from "@azure/msal-react";
import { loginRequest, b2cPolicies } from "../../../common/helpers/AuthService";
import Footer from "../../../common/components/footer";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function RegisterFirstTimeUser() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [screenLoader, setScreenLoader] = useState(false);
  const [desabledContinueBtn, setDesabledContinueBtn] = useState(true);
  const [registerStates, setRegisterStates] = useState({
    referenceNumber: {
      name: "referenceNumber",
      value: "",
      errorMsg: "",
      state: "",
    },
  });
  const [cmsContent, setCmsContent] = useState({
    title: "",
    description: "",
    labels: [],
    largeImage: "",
  });
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    let accessToken = window.sessionStorage.getItem("AccessToken");
    if (context.beforeDashboardLogoRedirection === true && accessToken) {
      let refRegisterStates = JSON.parse(JSON.stringify(registerStates));
      refRegisterStates.referenceNumber.errorMsg =
        "You have already logged In (Click on sign in)";
      refRegisterStates.referenceNumber.state = "error";
      setRegisterStates(refRegisterStates);
    }
    fetchCMSContent();
    TrackActions.PageViewRecord(appInsights, "RegisterFirstTimeUser");
    TrackActions.PageAction("RegisterFirstTimeUser");
  }, []);

  const fetchCMSContent = async () => {
    setScreenLoader(true);
    // let resData = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'))
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));

    let payload = {
      PageName: "OnboardingPages",
    };
    await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
      (res) => {
        if (res.status === 200) {
          res?.data?.Pages?.filter((x) => {
            if (x.Key === "RegisterFirsttimeUser") {
              let compName =
                x?.Components[
                  "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser"
                ];
              refCmsContent.title = compName?.Title;
              refCmsContent.description = x?.Description;
              refCmsContent.labels = compName?.Labels;
            }
          });
          setCmsContent(refCmsContent);
          setScreenLoader(false);
          context.setCmsOnBoadingContent(res?.data);
          window.sessionStorage.setItem(
            "setCmsOnBoadingContent",
            JSON.stringify(res?.data)
          );
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (refCmsContent?.labels.length > 0) {
      refCmsContent.labels?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const formHandler = (event) => {
    if (event.target.value !== "") {
      setDesabledContinueBtn(false);
    } else {
      setDesabledContinueBtn(true);
      let refRegisterStates = JSON.parse(JSON.stringify(registerStates));
      refRegisterStates.referenceNumber.errorMsg = "";
      refRegisterStates.referenceNumber.state = "";
      setRegisterStates(refRegisterStates);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let refRegisterStates = JSON.parse(JSON.stringify(registerStates));
    registerStates.referenceNumber.errorMsg = "";
    registerStates.referenceNumber.state = "";
    let nextAPIcallFlag = false;

    let payload = {
      registrationCode: event.target.elements.referenceNumber.value,
    };
    setScreenLoader(true);
    await OnBoardingActions.ReferenceCode(payload).then(
      (res) => {
        if (res.status === 200) {
          window.sessionStorage.setItem(
            "ValidatedRegCode",
            JSON.stringify(event.target.elements.referenceNumber.value)
          );
          sessionStorage.removeItem("AcceptedPolicy");
          nextAPIcallFlag = true;
        } else {
          setScreenLoader(false);
          refRegisterStates.referenceNumber.errorMsg = fetchLabel(
            "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.ReferenceNumberValidationMessage"
          );
          refRegisterStates.referenceNumber.state = "error";
          setRegisterStates(refRegisterStates);
        }
      },
      (err) => {
        setScreenLoader(false);
        refRegisterStates.referenceNumber.errorMsg = fetchLabel(
          "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.ReferenceNumberValidationMessage"
        );
        refRegisterStates.referenceNumber.state = "error";
        setRegisterStates(refRegisterStates);
      }
    );

    if (nextAPIcallFlag) {
      await OnBoardingActions.GetProgramLanguage(
        event.target.elements.referenceNumber.value
      ).then(
        (progLang) => {
          if (progLang.status === 200) {
            console.log(progLang, "progLang");
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify({
                selectedCountryFullName: progLang?.data?.programCountry,
                selectedCountryShortName: progLang?.data?.programCountryCode,
                selectedLanguageISOCode: progLang?.data?.programLanguageCode,
                selectedLanguageFullName: progLang?.data?.programLanguage,
              })
            );
            sessionStorage.removeItem("headerCache");
            sessionStorage.removeItem("footerCache");

            setTimeout(() => {
              setScreenLoader(false);
              navigate("/our-policy");
            }, 500);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const redirectingADB2C = () => {
    sessionStorage.removeItem("AccessToken");
    sessionStorage.removeItem("setIdTokenClaims");
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
      <Cirrus.Page
        background="primary"
        backgroundShape="singleDesktop"
        header={<Header mode="back" />}
      >
        <Cirrus.FirsTimeUser
          buttonText={fetchLabel(
            "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.ContinueButton"
          )}
          buttonDisabled={desabledContinueBtn}
          copy={fetchLabel(
            "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.AlreadyHaveAnAccount"
          )}
          copyLink={{
            children: fetchLabel(
              "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.SignIn"
            ),
            onClick: redirectingADB2C,
          }}
          image={{
            altText: "",
            isRounded: true,
            src: cmsContent?.largeImage,
          }}
          inputProps={{
            // helperLink: {
            //     children: fetchLabel('RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.NeedHelp'),
            //     // url: 'www.google.com'
            // },
            id: "referenceNumberInput",
            label: fetchLabel(
              "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.ReferenceNumber"
            ),
            name: registerStates.referenceNumber.name,
            placeholder: fetchLabel(
              "RegisterFirstTimeUser.OnboardingRegisterFirstTimeUser.ReferenceNumberPlaceholder"
            ),
            errorMessage: registerStates.referenceNumber.errorMsg,
            state: registerStates.referenceNumber.state,
            type: "text",
            onChange: formHandler,
          }}
          onSubmit={submitHandler}
          subtext={cmsContent.description}
          title={cmsContent.title}
        />
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </Cirrus.Page>
      <Footer isLoggedIn={false} />
    </>
  );
}

export default RegisterFirstTimeUser;
