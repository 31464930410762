//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const coachActions = {
    GetCMSJobLabels: getCMSJobLabels,
    GetMyCoach: getMyCoach,
    DeleteMeeting: deleteMeeting,
    GetCoachingSessions:getCoachingSessions,
    SaveMeetingConfirmedStatus:SaveMeetingConfirmedStatus,
    SaveRescheduleMeeting: SaveRescheduleMeeting,
    GetMyCoachmatching: getMyCoachmatching,
    SaveNoCoachMeetingRequest: SaveNoCoachMeetingRequest,
    SaveNoCoachRescheduleMeetingRequest: SaveNoCoachRescheduleMeetingRequest,
    GetMeetingRequestCronofyAvailabilitySlots: GetMeetingRequestCronofyAvailabilitySlots
}

function getCMSJobLabels(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function getMyCoach() {
    return http.get(environments.GET_MY_COACH);
}

function deleteMeeting(meetingid) {
    let deletemeeting = environments.DeleteCoachMeeting.replace('{meetingid}', meetingid);
    return http.CoachingDelete(deletemeeting);
}

function getCoachingSessions(payload) {
    return http.CoachingPost(environments.CoachingSessions, payload);
}

function SaveMeetingConfirmedStatus(id, payload) {
    let relpaceId = environments.ConfirmCoachMeeting.replace('{id}', id);
    return http.CoachingPut(relpaceId, payload);
}

function SaveRescheduleMeeting(payload) {
    return http.CoachingPost(environments.CoachingMeetingUpdate, payload);
}

function GetMeetingRequestCronofyAvailabilitySlots (payload)
{
    return http.CoachingPost(environments.GET_COACH_MEETING_CRONOFY_AVAILABLE_SLOTS, payload);
}

/// No Coach related functions
function getMyCoachmatching() {
    return http.get(environments.GETMyCOACHMATCHING);
}

function SaveNoCoachMeetingRequest (payload)
{
    return http.NoCoachPost(environments.SAVE_NOCOACH_MEETING, payload);
}

function SaveNoCoachRescheduleMeetingRequest (payload,calendarInviteId)
{
    let relpaceId = environments.SAVE_NOCOACH_RESCHUDLE_MEETING.replace('{calendarInviteId}', calendarInviteId);
    return http.NoCoachPost(relpaceId, payload);
}
