export const getSatelliteCountryCode = () => {
  const programDetails = JSON.parse(
    window.sessionStorage.getItem("setProgramDetails")
  );
  const profileDetails = JSON.parse(
    window.sessionStorage.getItem("setProfileDetails")
  );
  const satelliteCountryCode =
    programDetails.programSatelliteCountryCode != null
      ? programDetails.programSatelliteCountryCode
      : programDetails.rightOffices.find(
          (rightOffice) => rightOffice.id == profileDetails.rightOfficeId
        )?.satelliteCountryCode;
  return satelliteCountryCode.toUpperCase();
};
