//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const HeaderActions = {
    headerLabels: headerLabels,
    getNotifications:getNotifications,
    readNotifications:readNotifications,
    getSystemNotifications: getSystemNotifications,
    getNegotiateUrl: getNegotiateUrl,
    readSystemMessages: readSystemMessages,
    CalenderMenu: calenderMenu,
    SaveCandisdateProfile: saveCandisdateProfile
}

function headerLabels(payload) {
    return http.CMSpost(environments.GET_HEADER_VALUE, payload);
}

function getNotifications(offset, count, locale){
    const off = environments.GET_NOTIFICATIONS.replace('{off}', offset)
    const lim = off.replace('{lim}', count)
    const local = lim.replace('{locale}', locale)
    return http.GetNotifications(local)
}

function readNotifications(payload){
    return http.ReadNotificationPost(environments.READ_NOTIFICATIONS, payload)
}

function getSystemNotifications(locale){
    return http.GetNotifications(environments.GET_SYSTEM_MESSAGES.replace('{locale}', locale))
}

function getNegotiateUrl(id) {
    let negotiateUrl = environments.NEGOTIATE_URL.replace('{id}', id);
    return http.signalRNotification(negotiateUrl);
}

function readSystemMessages(payload){
    return http.ReadNotificationPost(environments.READ_SYSTEM_MESSAGES, payload)
}

function calenderMenu(){
    return http.CalenderGet(environments.HIDING_CALENDER_WIDGET);
}

function saveCandisdateProfile(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_PROFILE.replace('{id}', id);
    return http.put(relpaceId, payload);
}
