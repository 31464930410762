//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
const InterestQuestionPage = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState([]);
    const [questionLabel, setQuestionLabel] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [assessmentPercentage, setAssessmentPercentage] = useState(0);
    const [assessmentStatus, setAssessmentStatus] = useState({
        Parrent: {},
        Skills: {},
        Interests: {},
        answeredPageNumber: 0
    })

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        let cmsResponse = context.cmsStrengthAnalysis;
        setScreenLoader(true)

        if (Object.keys(cmsResponse).length !== 0) {
            let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
            setCmsMasterContent(cmsResponse)
            setQuestionLabel(cmsResponse?.assessmentInterests?.questionLabel)
            let statusApiCallFlag = false;

            await AssessmentActions.GetStrengthsAnalysisIdentifyYourInterest(cmsResponse.masterCMS.ID).then((res) => {
                if (res.status === 200) {
                    cmsResponse?.assessmentInterests?.Question.map((resourcePages) => {
                        resourcePages.map((interestedSet) => {
                            res?.data?.map((interestAns) => {
                                if (interestAns.categoryId === interestedSet.Skills.ID) {
                                    interestedSet.IsSelected = interestAns.isInterested;
                                }
                            })
                            return interestedSet;
                        })
                        return resourcePages;
                    })
                    setMasterQuestionAnswer(cmsResponse?.assessmentInterests?.Question)
                    statusApiCallFlag = true;
                } else {
                    cmsResponse?.assessmentInterests?.Question.map((resourcePages) => {
                        resourcePages.map((interestedSet) => {
                            interestedSet.IsSelected = "";
                            return interestedSet;
                        })
                        return resourcePages;
                    })
                    setMasterQuestionAnswer(cmsResponse?.assessmentInterests?.Question)
                }
            }, err => {
                console.log(err);
                setScreenLoader(false)
            })

            if (statusApiCallFlag) {
                await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(cmsResponse?.masterCMS?.ID).then(
                    (res) => {
                        if (res.status === 200) {
                            let parent = res?.data.filter((status) => status.subLearningResourceId === undefined)
                            refAssessmentStatus.Parrent = parent[0];
                            cmsResponse.assIds.map((x) => {
                                res?.data.map((status) => {
                                    if (x.id === status.subLearningResourceId) {
                                        refAssessmentStatus[`${x.name}`] = status;
                                    }
                                })
                            })
                            setAssessmentStatus(refAssessmentStatus)
                        } else {
                            setScreenLoader(false)
                        }
                    },
                    (err) => {
                        console.log(err);
                        setScreenLoader(false)
                    }
                );
            }

            let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
            setScreenLoader(false)

            if (refAssessmentStatus?.Interests?.lastAnsweredPageId !== undefined) {
                let pageNumber;
                if (parseInt(refAssessmentStatus?.Interests?.lastAnsweredPageId) > 3) {
                    pageNumber = 3;
                } else {
                    pageNumber = parseInt(refAssessmentStatus?.Interests?.lastAnsweredPageId)
                }

                if (pageNumber < assessmentQuestion.length) {
                    assessmentQuestion[pageNumber].style.display = "block";
                    setCurrentQuestion(currentQuestion + pageNumber);

                    refAssessmentStatus.answeredPageNumber = pageNumber;
                    setAssessmentStatus(refAssessmentStatus)

                    let percentageCalc = Math.ceil((pageNumber / assessmentQuestion.length) * 100);
                    setAssessmentPercentage(percentageCalc);
                } else {
                    assessmentQuestion[pageNumber - 1].style.display = "block";
                    setCurrentQuestion(pageNumber - 1);

                    refAssessmentStatus.answeredPageNumber = pageNumber;
                    setAssessmentStatus(refAssessmentStatus)

                    let percentageCalc = Math.ceil((pageNumber / assessmentQuestion.length) * 100);
                    setAssessmentPercentage(percentageCalc);
                }
            } else {
                assessmentQuestion[currentQuestion].style.display = "block";
            }
        }
    }

    const saveAnswers = async (event, selectedQueAnswer, selectedSet) => {
        let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));

        refMasterQuestionAnswer.map((resourcePages) => {
            resourcePages?.map((firstSetQuestion) => {
                if (firstSetQuestion.Skills.ID === selectedSet.Skills.ID) {
                    firstSetQuestion.IsSelected = selectedQueAnswer;
                }
                return firstSetQuestion;
            })
            return resourcePages;
        })
        setMasterQuestionAnswer(refMasterQuestionAnswer)

        let savePayload = [{
            "SubAssessmentId": cmsMasterContent?.assessmentInterests?.ID,
            "CategoryId": selectedSet.Skills.ID,
            "CategoryTitle": selectedSet.Skills.Value,
            "CategoryDescription": selectedSet.Description,
            "IsInterested": selectedQueAnswer,
        }]
        await AssessmentActions.StrengthsAnalysisUpdateIdentifyYourInterest(cmsMasterContent.masterCMS.ID, savePayload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }

    const nextPrev = async (n, pageNumber) => {
        let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        if (n > 0) {
            let page = pageNumber + 1;
            if (!isNaN(page)) {
                if (page > assessmentStatus.answeredPageNumber) {
                    let statusPayload = {
                        "SubLearningResourceStatus": "inprogress",
                        "SubLearningResourceId": cmsMasterContent?.assessmentInterests?.ID,
                        "LastAnsweredPageId": page
                    }

                    await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, statusPayload).then((res) => {
                        if (res.status === 200) {
                            refAssessmentStatus.answeredPageNumber = page;
                            setAssessmentStatus(refAssessmentStatus)

                            let percentageCalc = Math.ceil((page / assessmentQuestion.length) * 100);
                            setAssessmentPercentage(percentageCalc);

                            setTimeout(() => {
                                assessmentQuestion[currentQuestion].style.display = "none";
                                setCurrentQuestion(currentQuestion + n);
                                showQuestion(currentQuestion + n);
                            }, 100);

                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    showQuestion(currentQuestion + n);
                }
            } else {
                assessmentQuestion[currentQuestion].style.display = "none";
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        } else {
            assessmentQuestion[currentQuestion].style.display = "none";
            setCurrentQuestion(currentQuestion + n);
            showQuestion(currentQuestion + n);
        }
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n == -1) {
            navigate("/" + pageOrgin.id + "/strength-analysis/interest-start");
        } else {
            if (n < assessmentQuestion.length) {
                assessmentQuestion[n].style.display = "block";
            }
            if (n == assessmentQuestion.length) {
                navigate("/" + pageOrgin.id + "/strength-analysis/interest-refining-question");
            }
        }
    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (Object.keys(refCmsMasterContent).length !== 0) {
            refCmsMasterContent?.masterLabel?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const disabledContinueButton = (item) => {
        let returnValue = '';
        let questionCount = item?.length;
        let ansCount = item?.filter((firstSetQuestion) => firstSetQuestion.IsSelected !== '')
        if (questionCount === ansCount.length) {
            returnValue = false
        } else {
            returnValue = true
        }
        return returnValue;
    }



    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }


    return (
        <div className="assessment-page sa-interest-questions">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={
                    <HeaderWithNavigation />
                }
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pt-2 pb-lg-4 pb-3'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button isSecondary onClick={(e) => { nextPrev(-1) }}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {assessmentPercentage}%</Cirrus.Label>
                            <ProgressBar now={assessmentPercentage} label={`${assessmentPercentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end" onClick={() => navigate("/" + pageOrgin.id + "/strength-analysis")}>
                        <Cirrus.Button>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                    </Col>
                </Row>


                <Row className='d-flex d-lg-none'>
                    <Col lg={10} className='px-5 m-auto'>
                        <Row className='pt-3'>
                            <Col lg={8} className="d-none d-lg-block">
                            </Col>
                            {
                                questionLabel?.map((label, labelIndex) => {
                                    return (
                                        <Col xs={6} lg={2} className='text-center headerRadios' key={labelIndex}>
                                            {labelIndex === 0 ?
                                                <label className='custom-yes-no-radio active'>
                                                    <input
                                                        type='radio'
                                                        name="headingRadio"
                                                        aria-label="Select"
                                                        checked={true}
                                                        defaultChecked={true}
                                                        readOnly
                                                    />
                                                    <span>
                                                        <i className='icon-check'></i>
                                                    </span>
                                                </label> :
                                                <label className='custom-yes-no-radio'>
                                                    <input
                                                        type='radio'
                                                        name="headingRadio"
                                                        aria-label="Select"
                                                        checked={false}
                                                        defaultChecked={false}
                                                        readOnly
                                                    />
                                                    <span>
                                                        <i className='icon-close'></i>
                                                    </span>
                                                </label>
                                            }
                                            {label.AnswerText}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
            {
                masterQuestionAnswer?.map((resourcePages, resoIndex) => {
                    return (
                        <Container className='assessment-question-wrapper text-start mb-lg-5 mb-4 p-lg-5 p-3' key={resoIndex}>
                            <Row className='d-none d-lg-flex'>
                                <Col lg={10} className='px-5 m-auto'>
                                    <Row className='mb-3'>
                                        <Col lg={8} className="d-none d-lg-block">
                                        </Col>
                                        {
                                            questionLabel?.map((label, labelIndex) => {
                                                return (
                                                    <Col xs={6} lg={2} className='text-center headerRadios' key={labelIndex}>
                                                        {labelIndex === 0 ?
                                                            <label className='custom-yes-no-radio active'>
                                                                <input
                                                                    type='radio'
                                                                    name="headingRadio"
                                                                    aria-label="Select"
                                                                    checked={true}
                                                                    defaultChecked={true}
                                                                    readOnly
                                                                />
                                                                <span>
                                                                    <i className='icon-check'></i>
                                                                </span>
                                                            </label> :
                                                            <label className='custom-yes-no-radio'>
                                                                <input
                                                                    type='radio'
                                                                    name="headingRadio"
                                                                    aria-label="Select"
                                                                    checked={false}
                                                                    defaultChecked={false}
                                                                    readOnly
                                                                />
                                                                <span>
                                                                    <i className='icon-close'></i>
                                                                </span>
                                                            </label>
                                                        }
                                                        {label.AnswerText}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    resourcePages?.map((firstSetQuestion, firstIndex) => {
                                        return (<>
                                            <Col lg={10} className='px-5 m-auto' key={firstIndex}>
                                                <Row className='py-3 align-items-center item-border'>
                                                    <Col lg={8} className='mb-3 text-center'>
                                                        {firstSetQuestion?.Skills?.Value}
                                                    </Col>
                                                    <Col lg={2} xs={6} className='text-center'>
                                                        <label className='custom-yes-no-radio'>
                                                            <input
                                                                type='radio'
                                                                name={firstSetQuestion.Skills.Key}
                                                                aria-label="Select"
                                                                defaultChecked={firstSetQuestion.IsSelected !== '' ? firstSetQuestion.IsSelected === true ? true : false : ''}
                                                                onChange={(event) => {
                                                                    saveAnswers(event, true, firstSetQuestion);
                                                                }}
                                                            />
                                                            <span>
                                                                <i className='icon-check'></i>
                                                            </span>
                                                        </label>
                                                    </Col>
                                                    <Col lg={2} xs={6} className='text-center'>
                                                        <label className='custom-yes-no-radio'>
                                                            <input
                                                                type='radio'
                                                                name={firstSetQuestion.Skills.Key}
                                                                aria-label="Deselect"
                                                                defaultChecked={firstSetQuestion.IsSelected !== '' ? firstSetQuestion.IsSelected === true ? false : true : ''}
                                                                onChange={(event) => {
                                                                    saveAnswers(event, false, firstSetQuestion);
                                                                }}
                                                            />
                                                            <span>
                                                                <i className='icon-close'></i>
                                                            </span>
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                        )
                                    })
                                }
                            </Row>
                            <Row>
                                <Col lg={10} className='p-5 m-auto text-center strength-questionpage'>
                                    <Cirrus.Button
                                        className='w-25'
                                        onClick={(e) => { nextPrev(1, resoIndex) }}
                                        disabled={disabledContinueButton(resourcePages)}
                                    >
                                        {fetchLabel("CommonComponent.CommonContinueButton")}
                                    </Cirrus.Button>
                                </Col>
                            </Row>
                        </Container>
                    )
                })
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}
export default InterestQuestionPage;
