//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export const TrackActions = {

  TrackMetric: trackMetric,
  EventTrack: eventTrack,
  TimeRecord: TimeRecord,
  PageViewRecord: pageViewRecord,
  PageAction: pageAction,
}

function pageAction(pageName, property) {
  window.newrelic.addPageAction(pageName, payloadReturn(property, pageName));
}

function trackMetric(additionalProperties, appInsights, time) {
  console.log(additionalProperties)
  const metricData = {
    average: time,
    name: "React Component Engaged Time (seconds)"
  };
  appInsights.trackMetric(metricData, payloadReturn(additionalProperties));
}

function eventTrack(appInsights, propname, property) {
  const measurements = { 'Severity': SeverityLevel.Information }
  appInsights.trackEvent({ name: propname, properties: payloadReturn(property, propname), measurements })
}

function TimeRecord(StartTime, additionalProperties, appInsights) {
  let StopTime = new Date();
  let timeDiff = StopTime - StartTime; //in ms
  // strip the ms
  console.log(StartTime, StopTime)
  timeDiff /= 1000;

  // get seconds 
  let seconds = Math.round(timeDiff);
  console.log(seconds + " seconds");
  TrackActions.TrackMetric(additionalProperties, appInsights, seconds)
}

function pageViewRecord(appInsights, Pagename) {
  const measurements = { 'Severity': SeverityLevel.Information }
  appInsights.trackPageView({ name: Pagename, properties: payloadReturn(), measurements: measurements })
}

function payloadReturn(property, propname) {
  let ProgramDetails = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : "";
  let ProfileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails")) && JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
  let noData = "Not Available";
  let payload
  if (JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null) {
    payload = {
      "CandidateID": JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
      "Severity": SeverityLevel.Information.toString(),
      "Client Name": ProgramDetails?.companyName ? ProgramDetails?.companyName : noData,
      "Program Class": ProgramDetails?.programClass ? ProgramDetails?.programClass : noData,
      "Market": ProfileDetails?.preferredLanguageCode ? ProfileDetails?.preferredLanguageCode : noData,
      "Time": new Date(),
      "Persona": ProfileDetails?.personaName ? ProfileDetails?.personaName : noData,
      "EmailAddress": ProfileDetails?.emailAddress ? ProfileDetails?.emailAddress : noData,
      "Language": JSON.parse(window.sessionStorage.getItem("Language")) && JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageFullName'],
      "Name": propname,
      ...property
    }
  }
  return payload
}
