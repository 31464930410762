//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useState, useEffect } from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import Moment from 'moment';
import { Context } from '../../common/context/Context';
import { JobActions } from "./JobActions";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";

function JobDetails({ jobDetailsBackFlag, selectJobForDetails, jobSaveFromJDpageFlag, appliedJobFromJDpageFlag }) {
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const [saveButton, setSaveButton] = useState();
    const [appliedButton, setAppliedButton] = useState(false);

    const [cmsJobDetailsContent, setCmsJobDetailsContent] = useState({
        browserTitle: '',
        description: '',
        metadata: '',
        pageContent: {},
        label: []
    })
    const fetchJobDetailsLabel = (key) => {
        let refCmsJobContent = JSON.parse(JSON.stringify(cmsJobDetailsContent));
        let label = '';
        if (refCmsJobContent?.label) {
            refCmsJobContent?.label.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (selectJobForDetails.length > 0) {
            setSaveButton(selectJobForDetails[0]?.isSaved)
            setAppliedButton(selectJobForDetails[0]?.isApplied === true ? true : false)
            fetchCMSData()
            ActivityAction.SaveActivity(selectJobForDetails[0]?.id, "job", selectJobForDetails[0]?.jobRole)
            TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": selectJobForDetails[0]?.id, "Entity Name": selectJobForDetails[0]?.jobRole, "CardType": "Job" })
            TrackActions.PageAction("ViewCard", { "Entity Id": selectJobForDetails[0]?.id, "Entity Name": selectJobForDetails[0]?.jobRole, "CardType": "Job" })
        }
    }, [selectJobForDetails]);


    const fetchCMSData = () => {

        let refCmsJobDetailsContent = JSON.parse(JSON.stringify(cmsJobDetailsContent));
        const cmsPayload = {
            PageName: "JobDetails Page"
        }
        JobActions.GetCMSJobLabels(cmsPayload).then((res) => {
            if (res?.status === 200) {
                let resData = res.data && res.data;
                resData?.Pages.filter((x) => {
                    if (x.Key === "jobdetailspage") {
                        refCmsJobDetailsContent.browserTitle = x.BrowserTitle;
                        refCmsJobDetailsContent.description = x.Description;
                        refCmsJobDetailsContent.metadata = x.Metadata;
                        refCmsJobDetailsContent.pageContent = x.Components['JobSearch.JobDetails'];
                        let allLabel = [...x.Components['JobSearch.JobDetails'].Labels, ...x.Components['JobSearch.JobCard'].Labels]
                        refCmsJobDetailsContent.label = allLabel;
                    }
                })
                setCmsJobDetailsContent(refCmsJobDetailsContent);
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const saveJob = async () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        const saveData = {
            "OwnerId": candidateID,
            "OwnerType": "Candidate",
            "JobId": selectJobForDetails[0]?.id,
            "JobTitle": selectJobForDetails[0]?.jobRole != undefined ? selectJobForDetails[0]?.jobRole : '',
            "CompanyName": selectJobForDetails[0]?.companyName != undefined ? selectJobForDetails[0]?.companyName : '',
            "JobUrl": selectJobForDetails[0]?.jobUrl != undefined ? selectJobForDetails[0]?.jobUrl : '',
            "JobDescription": selectJobForDetails[0]?.description != undefined ? selectJobForDetails[0]?.description : '',
            "IsFeaturedJob": 1,
            "IsSaved": saveButton === true ? 0 : 1,
            "IsBookmarked": saveButton === true ? 0 : 1,
            "IsDeleted": 0,
            "CreatedBy": '',
            "MatchPercentage": '', // need clarification
            "JobLocation": selectJobForDetails[0]?.location != undefined ? selectJobForDetails[0]?.location : '',
            "Salary": 0, // need clarification
            "EmploymentType": selectJobForDetails[0]?.contractType != undefined ? selectJobForDetails[0]?.contractType : '',

            "JobPostedTimeStamp": selectJobForDetails[0]?.postedTimeStamp != undefined ? Moment(selectJobForDetails[0]?.postedTimeStamp).format("MM-DD-YYYY") : '',
            "LastSavedTimeStamp": Moment(new Date()).format("MM-DD-YYYY"),
            // "JobStartTimeStamp": selectJobForDetails[0]?.startDate === "Flexible Start" ? null : selectJobForDetails[0]?.startDate,
            "CreatedTimeStamp": Moment(new Date()).format("MM-DD-YYYY"),
            // "LastUnsavedTimeStamp": savedJobsId.includes(inputJobId) ? Moment(new Date()).format("MM-DD-YYYY") : null,
            "UpdatedTimeStamp": selectJobForDetails[0]?.lastUpdatedDate != undefined ? Moment(selectJobForDetails[0]?.lastUpdatedDate).format('MM-DD-YYYY') : "",
        }

        await JobActions.PostSaveJob(candidateID, saveData).then(res => {
            if (res.status === 200 && res.data === true) {
                if (saveButton === true) {
                    setSaveButton(false)
                } else {
                    setSaveButton(true)
                }
                jobSaveFromJDpageFlag(selectJobForDetails[0]?.id)
            }
        }, err => {
            console.log(err);
        });
    }

    const iHaveApplied = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        const appliedData = {
            "OwnerId": candidateID,
            "OwnerType": "Candidate",
            "JobId": selectJobForDetails[0]?.id,
            "JobTitle": selectJobForDetails[0]?.jobRole != undefined ? selectJobForDetails[0]?.jobRole : '',
            "CompanyName": selectJobForDetails[0]?.companyName != undefined ? selectJobForDetails[0]?.companyName : '',
            "JobDescription": selectJobForDetails[0]?.description != undefined ? selectJobForDetails[0]?.description : '',
            "JobUrl": selectJobForDetails[0]?.jobUrl != undefined ? selectJobForDetails[0]?.jobUrl : '',
            "IsFeaturedJob": 1,
            "IsSaved": 1,
            "IsBookmarked": 1,
            "IsDeleted": 0,
            "CreatedBy": '',
            "MatchPercentage": '', // need clarification
            "JobLocation": selectJobForDetails[0]?.location != undefined ? selectJobForDetails[0]?.location : '',
            "Salary": 0, // need clarification
            "EmploymentType": selectJobForDetails[0]?.contractType != undefined ? selectJobForDetails[0]?.contractType : '',
            "JobPostedTimeStamp": selectJobForDetails[0]?.postedTimeStamp != undefined ? Moment(selectJobForDetails[0]?.postedTimeStamp).format("MM-DD-YYYY") : '',
            // "LastSavedTimeStamp": todayDate(new Date()),
            "CreatedTimeStamp": Moment(new Date()).format("MM-DD-YYYY"),
        }

        JobActions.AppliedJob(candidateID, appliedData).then(res => {
            if (res.status === 200 && res.data === true) {
                setAppliedButton(!appliedButton)
                appliedJobFromJDpageFlag(selectJobForDetails[0]?.id)
            }
        }, err => {
            console.log(err);
        });
    }

    const applyForTheRole = () => {
        window.open(selectJobForDetails[0]?.jobUrl, '_blank');
    }

    const todayDate = (date) => {
        let convDate = Moment(date).format("MM-DD-YYYY")
        return convDate;
    }

    return (
        <>
            <Cirrus.JobDetails
                applicationDeadline={selectJobForDetails.length > 0 && selectJobForDetails[0] && selectJobForDetails[0].applicationDeadline && selectJobForDetails[0].applicationDeadline}
                appliedButtonProps={{
                    children: appliedButton ? fetchJobDetailsLabel('JobSearch.JobDetails.Applied') : fetchJobDetailsLabel('JobSearch.JobDetails.IHaveApplied'),
                    isSecondary: true,
                    onClick: () => { iHaveApplied() },
                    size: 'Large',
                    type: 'button'
                }}
                applyButtonProps={{
                    children: fetchJobDetailsLabel('JobSearch.JobDetails.ApplyForTheRole'),
                    onClick: applyForTheRole,
                    size: 'Large',
                    type: 'button'
                }}
                backButtonProps={{
                    children: fetchJobDetailsLabel('JobSearch.JobDetails.Back'),
                    isSecondary: true,
                    onClick: () => {
                        jobDetailsBackFlag();
                    },
                    size: 'Small',
                    type: 'button'
                }}
                bookmarkButtonProps={{
                    children: saveButton === true ? fetchJobDetailsLabel("JobSearch.JobCard.SavedButton") : fetchJobDetailsLabel('JobSearch.JobCard.Save'),
                    isSecondary: saveButton === true ? false : true,
                    leadingIcon: saveButton === true ? 'saved' : 'save',
                    onClick: saveJob,
                    size: 'Large',
                    type: 'button'
                }}
                companyName={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.companyName : ''}
                contractType={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.contractType : ''}
                description={[
                    {
                        sectionBody: selectJobForDetails.length > 0 ? selectJobForDetails[0]?.description : '',
                        sectionHeading: fetchJobDetailsLabel('JobSearch.JobDetails.JobDescription')
                    },
                    {
                        sectionBody: selectJobForDetails.length > 0 ? selectJobForDetails[0]?.organizationDescription : '',
                        sectionHeading: fetchJobDetailsLabel('JobSearch.JobDetails.AboutTheCompany')
                    }
                ]}
                jobRole={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.jobRole : ''}
                location={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.location : ''}
                salary={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.salary : ''}
                startDate={selectJobForDetails.length > 0 ? selectJobForDetails[0]?.startDate : ''}
                staticCopy={{
                    Company: fetchJobDetailsLabel('JobSearch.JobDetails.Company'),
                    ContractType: fetchJobDetailsLabel('JobSearch.JobDetails.Type'),
                    Deadline: selectJobForDetails.length > 0 && selectJobForDetails[0] && selectJobForDetails[0].applicationDeadline && fetchJobDetailsLabel('JobSearch.JobDetails.DeadLineToApply'),
                    Location: fetchJobDetailsLabel('JobSearch.JobDetails.Location'),
                    Salary: fetchJobDetailsLabel('JobSearch.JobDetails.Salary'),
                    StartDate: fetchJobDetailsLabel('JobSearch.JobDetails.StartDate'),
                    RelatedVacanciesHeading: fetchJobDetailsLabel('JobSearch.JobDetails.YouMayInterestedIn'),
                    ComputerSkills: 'Computer skills',
                    Education: 'Education level',
                    Experience: 'Years of experience',
                    LanguageSkills: 'Language skills',
                }}
            />
        </>
    )
}

export default JobDetails














