//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext, memo } from "react";
import { Context } from "../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { MyProfileActions } from "../myProfile/MyProfileActions";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";

function JobGreetings(greetingCMS, matchCount) {


    const navigate = useNavigate();
    const context = useContext(Context);
    const appInsights = useAppInsightsContext()

    const [preferredName, setPreferredName] = useState('');

    const fetchLabels = (key) => {

        let refCmsContent = greetingCMS?.greetingCMS && greetingCMS?.greetingCMS;
        let label = "";
        if (refCmsContent?.labels) {
            refCmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    }

    const fetchPersonalInfoData = async () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        try {

            let Profilejson = {};
            if (candidateID != "") {
                const res = await MyProfileActions.GetcandidateProfile(candidateID);
                if (res.status === 200) {
                    Profilejson = res.data;
                    if (res?.data?.preferredName !== '') {
                        setPreferredName(Profilejson.preferredName);
                    } else {
                        setPreferredName(res?.data?.firstName);
                    }
                } else {
                    Profilejson = {};
                }
            } else {
                Profilejson = {};
            }
            return Profilejson;
        } catch (error) {
            return {};
        }
    };

    useEffect(() => {
        fetchPersonalInfoData()
        TrackActions.PageViewRecord(appInsights, "JobGreetings")
        TrackActions.PageAction("JobGreetings")
    }, [context.language])

    let countLabel = fetchLabels("Job.JobMatches.JobGreetings.NumberOfMatches");
    countLabel = countLabel.replace("#NUMBER", context.jobCount && context.jobCount);

    let firstName = context?.idTokenClaims?.firstName;
    let greetingText = fetchLabels("Job.JobMatches.JobGreetings.NameWithMatches");
    if (greetingText) {
        greetingText = greetingText.replace("#NAME", preferredName ? preferredName : firstName);
    }
    let greetingTextZero = fetchLabels("Job.JobMatches.JobGreetings.NameWithNoMatches");
    if (greetingTextZero) {
        greetingTextZero = greetingTextZero.replace("#NAME", preferredName ? preferredName : firstName);
    }

    return (
        <>
            <Cirrus.JobGreetingCard
                bodyText={countLabel}
                buttonProps={{
                    children: fetchLabels("Job.JobMatches.JobGreetings.SeeAllJobMatches"),
                    size: 'Large',
                    onClick: () => { navigate("/jobsearchPage") }
                }}
                longHeading={context.jobCount > 0 ? greetingText : greetingTextZero}
                shortHeading="Your Matches"
            />
        </>
    );
}
export default memo(JobGreetings);
