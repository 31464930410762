//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react";
import { useNavigate } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Row, Col, Container } from "react-bootstrap";
import './job.css';

function StartBookMarkSection({ title, description, buttonText, navigateUrl }) {

  const navigate = useNavigate();

  return (
    <>
      <div className="start-bookmarking-section">
        <Container className='start-section rounded-right text-start p-lg-5 p-3'>
          <Row className="align-items-center">
            <Col lg={8} className='col-12'>
              <Cirrus.Headline size='Medium'>{title} </Cirrus.Headline>
              <Cirrus.RichText content={description} className='bodyText' />
            </Col>
            <Col lg={2} className='mt-lg-0 mt-3 text-lg-center'>
              <Cirrus.Button hasArrow onClick={() => { navigate('/' + navigateUrl) }}>
                {buttonText}
              </Cirrus.Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StartBookMarkSection;
