//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { useEffect, useState, useContext, useRef } from 'react';
import Header from '../../header/simpleHeader';
import { OnBoardingActions } from "../OnBoardingActions";
import Footer from "../../../common/components/footer";
import { Context } from '../../../common/context/Context';
import { MyProfileActions } from "../../myProfile/MyProfileActions";
import { Spinner as BusyLoading } from 'react-bootstrap';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function QuestionEdit() {
    const heightRef = useRef();
    const fixedHeightRef = useRef();
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("/recap");
    }
    const { qid } = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const context = useContext(Context);
    let cmsQuestions;
    let answerShortNameArr = [];
    const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState(true);
    let [allAnswer, setallAnswer] = useState();
    const [selected, setSelected] = useState([]);
    const [getOptions, setgetOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [searchOptions, setSearchOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [questionsContent, setQuestionsContent] = useState({
        browserTitle: '',
        metaDescription: '',
        metadata: '',
        title: '',
        questionsList: [
            {
                QueryTitle: '',
                Answers: [
                    {
                        AnswerTitle: "",
                        AnswerId: ""
                    }
                ]
            }
        ],
        modalExitLabels: []
    });

    const appInsights = useAppInsightsContext()
    let autoCompleteSectorAnswers, autoCompleteJobAnswers;
    const [isAutoCompleteShow, setIsAutoCompleteShow] = useState(false)
    const [autoPopulateList, setAutoPopulateList] = useState([])
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedSectorsnJobs, setSelectedSectorsnJobs] = useState([]);
    const [noResponse, setNoResponse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [answerJobId, setAnswerJobId] = useState([])
    const autopopulateRef = useRef(null);
    const [answerSectorId, setAnswerSectorID] = useState([])
    let programDetails = JSON.parse(sessionStorage.getItem("setProgramDetails"))

    const fnLoadPersonalInfoData = async () => {
        try {
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let Profilejson = {};
            if (candidateID != "") {
                const res = await MyProfileActions.GetcandidateProfile(candidateID);
                if (res.status === 200) {
                    Profilejson = res.data;
                } else {
                    Profilejson = {};
                }
            } else {
                Profilejson = {};
            }
            return Profilejson;
        } catch (error) {
            return {};
        }
    };
    const fetchCMSContent = async () => {

        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        setScreenLoader(true);
        // let resData = context.cmsOnBoadingContent;
        let resData = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'));

        if (Object.keys(resData).length !== 0) {
            resData?.Pages.filter((x) => {
                if (x.Key === "QueryPage") {
                    refCmsContent.browserTitle = x.BrowserTitle;
                    refCmsContent.metaDescription = x.MetaDescription;
                    refCmsContent.metadata = x.Metadata;
                    refCmsContent.title = x.Title;
                    refCmsContent.questionsList = [];
                    let questionsObj;
                    if (
                        programDetails?.persona === undefined ||
                        programDetails?.persona === null ||
                        programDetails?.persona === "Non-Executive"
                    ) {
                        questionsObj = x.Components["Common.JobSeekerQuestions"];
                        refCmsContent.labels = x.Components["Common.JobSeekerQuestions"].Labels;
                        refCmsContent.componentKey = x.Components["Common.JobSeekerQuestions"].Key;
                    } else if (
                        programDetails?.persona === "Executive"
                    ) {
                        questionsObj = x.Components["Common.ExecutiveQuestions"];
                        refCmsContent.labels = x.Components["Common.ExecutiveQuestions"].Labels;
                        refCmsContent.componentKey = x.Components["Common.ExecutiveQuestions"].Key;
                    }
                    questionsObj.QuestionList.map((newArr, idx) => {
                        if (newArr.QuestionTitle) {
                            let newanswers = [];
                            newArr.AnswerList.map((answers, id) => {
                                newanswers.push({
                                    ...answers,
                                    id: answers.AnswerId,
                                    name: (newArr.QuestionDesignType == "MultiSelect") ? answers.AnswerText.replace(/ /g, "") : "radio",
                                    label: answers.AnswerText,
                                    value: answers.AnswerText,
                                    labelText: answers.AnswerText,
                                    defaultChecked: false
                                });
                            });
                            let IsMandatory;
                            if (newArr.IsMandatory === "") {
                                IsMandatory = false;
                            } else {
                                IsMandatory = true;
                            }
                            refCmsContent.questionsList.push({
                                ...newArr,
                                AnswerList: newanswers,
                                IsMandatory: IsMandatory,
                            });
                        }
                    });
                }
            });
            setQuestionsContent(refCmsContent);
            cmsQuestions = refCmsContent;

            let arr = [];
            let example = refCmsContent?.questionsList.filter((ques) => (
                ques?.QuestionTitle === "How do you prefer to learn new things?"
            ))

            example[0]?.AnswerList.map((val) => (
                arr.push(val?.AnswerId)
            ))

            setallAnswer(arr?.filter((val) => val !== 'JobSeekerQuestions.PreferToLearnNewThings.NoPreference'))
            setScreenLoader(false);
        }
    }
    const loadSavedQuestions = async (e) => {
        try {
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let questionsJson = [];
            if (candidateID != "") {
                await OnBoardingActions.getCandidatePreferences(candidateID).then(async res => {
                    if (res.status === 200) {
                        questionsJson = res.data;
                        let filterSectorQuestion = questionsJson.find(filt => filt?.questionShortName === qid)
                        let sectorAnswerIds = filterSectorQuestion?.answerShortName?.split(',')
                        autoCompleteSectorAnswers = await getAnsLists(sectorAnswerIds)

                    } else {
                        questionsJson = [];
                    }
                }, err => {
                    console.log(err);
                })
            } else {
                questionsJson = [];
            }
            return questionsJson;
        } catch (error) {
            return {}
        }
    }
    const getAnsLists = async (ids) => {
        let locale = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        let autoCompleteAnswers;
        try {
            const payload = {
                locale: [locale?.programLanguageCode],
                key: [],
                id: ids
            }
            const res = await OnBoardingActions.GetOnboardingQuestionsList(payload)
            if (res?.data) {
                let resData = res?.data
                let title = [];
                let saved = [];
                resData?.map(data => {
                    data?.items?.map(item => {
                        title.push(item.title)
                        saved.push({ title: item.title, id: item.id })
                    })
                })
                autoCompleteAnswers = title;
                setSelectedSectorsnJobs(saved);

            }
            return autoCompleteAnswers;
        } catch (err) {
            console.log(err)
        }
    }
    const renderQuestions = async () => {
        let savedQuestions = await loadSavedQuestions();
        let selectedOpts = [];
        cmsQuestions?.questionsList?.map(questions => {
            if (questions.QuestionId == qid) {
                questions.AnswerList.map(options => {
                    savedQuestions?.map(result => {
                        let savedQuestionId = result.questionShortName;
                        let savedAnswerId = result.answerShortName != null ? result.answerShortName.split(",") : "";
                        if (savedQuestionId == questions.QuestionId) {
                            if (savedAnswerId.length) {
                                savedAnswerId?.map(savedAnswer => {
                                    if (savedAnswer == options.AnswerId) {
                                        answerShortNameArr.push(options.AnswerId);
                                        selectedOpts.push({ ...options, defaultChecked: true });
                                    }
                                })
                            }
                        }
                    })
                })
                let mergedOpts = questions.AnswerList.map(options => {
                    let tempOpts = selectedOpts.find(opts => opts.AnswerId === options.AnswerId);
                    return tempOpts ? { ...options, ...tempOpts } : options
                })
                selectedOpts = mergedOpts;
            }
        })
        setgetOptions(selectedOpts);
        setSearchOptions(selectedOpts);
        setFilteredOptions(selectedOpts);
        setSelected(answerShortNameArr);
    }
    useEffect(async () => {
        window.scrollTo(0, 0)
        await fetchCMSContent();
        await renderQuestions();
        TrackActions.PageViewRecord(appInsights, "QuestionEdit")
        TrackActions.PageAction("QuestionEdit")
    }, []);
    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        let label = "";
        if (refCmsContent?.labels?.length > 0) {
            refCmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const updateHandler = async (e) => {
        let selectedOpts = selectedOptions;
        selectedOpts = uniqueSelectedList.map(data => { return data.id }).toString()

        let savePreferences;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];

        let selectFilter;
        if (selectedOptions !== undefined) {
            selectFilter = selectedOptions.includes("JobSeekerQuestions.PreferToLearnNewThings.NoPreference") ? selectedOptions.split(",").filter((val) => (val == "JobSeekerQuestions.PreferToLearnNewThings.NoPreference")).join(",") : selectedOptions.includes('JobSeekerQuestions.PreferToLearnNewThings.AllOfTheAbove') ? allAnswer.join(",") : selectedOptions
        }

        if (selectFilter !== "") {
            savePreferences = [{
                "questionShortName": selectedQuestion,
                "answerShortName": selectFilter
            }];
        }
        if (selectFilter == "") {
            savePreferences = [{
                "questionShortName": selectedQuestion,
                "answerShortName": ""
            }]
        }

        if (selectedQuestion === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || selectedQuestion === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
            savePreferences = [{
                "questionShortName": selectedQuestion,
                "answerShortName": selectedOpts
            }];
        }

        if (savePreferences) {
            OnBoardingActions.saveCandidatePreferences(candidateID, savePreferences).then(res => {
                if (res.status == '200') {
                    navigateTo();
                } else {

                }
            }, err => {
                console.log(err);
            })
        }
    }

    function useAutoCompleteOutsideClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideAutoComplete(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsAutoCompleteShow(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideAutoComplete);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideAutoComplete);
            };
        }, [ref]);
    }
    useAutoCompleteOutsideClick(autopopulateRef);

    const getOnboardingAutoSuggestion = (event, key) => {
        const input = event.target.value
        let payload = {
            "SearchKey": input.toLowerCase()
        }
        setSelectedItem(input)
        if (input.length >= 3) {
            setIsLoading(true)
            OnBoardingActions.GetOnboardingQuestions(key, payload).then(res => {
                if (res.status === 200) {
                    setNoResponse(false)
                    setAutoPopulateList(res?.data)
                    setIsAutoCompleteShow(true)
                    setIsLoading(false)
                } else if (res.status === 204) {
                    setNoResponse(true)
                    setAutoPopulateList("")
                    setIsLoading(false)
                    setIsAutoCompleteShow(true)
                } else {

                }
            }, err => {
                console.log(err)
            })
        }
    }

    const selectHandlerIndustry = (title, id, questionId) => {
        setSelectedItem(title)
        setSelectedSectorsnJobs([...selectedSectorsnJobs, { title: title, id: id }])
        setSelectedItem('');
        setSelectedQuestion(questionId)
        setIsUpdateBtnDisabled(false);
        setIsAutoCompleteShow(false);
    }

    const uniqueSelectedList = [...selectedSectorsnJobs?.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    const removeSectorsnJobs = (index, questionId) => {
        const filterdJobs = [...uniqueSelectedList].filter((job, i) => {
            return i !== index
        })
        setSelectedSectorsnJobs(filterdJobs)
        setSelectedQuestion(questionId)
        setIsUpdateBtnDisabled(false);
    }

    return (
        <>
            {
            // getOptions?.length > 0 && filteredOptions?.length > 0 &&
                <Cirrus.FullHeightContainerLayout
                    ref={heightRef}
                    footerHeight={0}
                    headerHeight={0}
                >
                    {questionsContent?.questionsList?.map((questions) => {
                        if (questions.QuestionId === qid) {
                            return (
                                <Cirrus.Container
                                    ref={fixedHeightRef}
                                    heading={questions.QuestionTitle}
                                    primaryButton={{
                                        children: fetchLabel(questionsContent.componentKey + ".UpdateButton"),
                                        onClick: updateHandler,
                                        size: 'Large',
                                        type: 'button',
                                        disabled: isUpdateBtnDisabled
                                    }}
                                    secondaryButton={{
                                        children: fetchLabel(questionsContent.componentKey + ".Cancel"),
                                        isSecondary: true,
                                        onClick: navigateTo,
                                        size: 'Large',
                                        type: 'button'
                                    }}
                                    subheading={questions.QuestionDesignType === "Single Select" && fetchLabel(questionsContent.componentKey + ".PleaseSelectOneOption") ||
                                        questions.QuestionDesignType === "MultiSelect" && fetchLabel(questionsContent.componentKey + ".PleaseSelectAllThatApply")}
                                >
                                    {
                                        questions.OptionType === "Search" && questions.QuestionDesignType === "MultiSelect" &&
                                        questions.QuestionId !== "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" &&
                                        questions.QuestionId !== "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={
                                                filteredOptions.map((option, i) => {
                                                    return {
                                                        onChange: (e) => {
                                                            setIsUpdateBtnDisabled(false);
                                                            let chosen = selected;
                                                            if (e.target.checked) {
                                                                chosen.push(option.AnswerId);
                                                                setSearchOptions(
                                                                    searchOptions.map((options) => {
                                                                        if (options.AnswerId === option.AnswerId) {
                                                                            return { ...options, defaultChecked: true }
                                                                        } else {
                                                                            return options
                                                                        }
                                                                    })
                                                                )
                                                            } else {
                                                                chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                                setSearchOptions(
                                                                    searchOptions.map((options) => {
                                                                        if (options.AnswerId === option.AnswerId) {
                                                                            return { ...options, defaultChecked: false }
                                                                        } else {
                                                                            return options
                                                                        }
                                                                    })
                                                                )
                                                            }
                                                            setSelectedOptions(chosen.toString());
                                                            setSelectedQuestion(questions.QuestionId);
                                                        },
                                                        ...option
                                                    }
                                                })
                                            }
                                            searchInput={{
                                                hideLabel: true,
                                                icon: 'search',
                                                id: 'search',
                                                isRounded: true,
                                                label: 'search',
                                                name: 'search',
                                                onChange: (event) => {
                                                    let updatedList = searchOptions;
                                                    updatedList = updatedList.filter((filterOption) => { return !event.target.value || filterOption.label.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0 });
                                                    if (updatedList.length) {
                                                        setFilteredOptions(updatedList);
                                                    } else {
                                                        updatedList = [{
                                                            id: 0,
                                                            label: "No matching results found",
                                                            name: "noResult",
                                                            value: "No matching results found",
                                                            disabled: true
                                                        }];
                                                        setFilteredOptions(updatedList);
                                                    }
                                                },
                                                placeholder: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.SearchHere") : fetchLabel("Common.JobSeekerQuestions.SearchHereText"),
                                                type: 'text'
                                            }}
                                        />
                                    }
                                    {
                                        questions.OptionType === 'Search' &&
                                        (questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || questions.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor")
                                        && (
                                            <div className="sector_select">
                                                <Cirrus.Input
                                                    placeholder={questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? fetchLabel("Common.JobSeekerQuestions.PreferredIndustry") : fetchLabel("Common.JobSeekerQuestions.PreferredJob")}
                                                    id="sectors"
                                                    icon="search"
                                                    className="mb-3"
                                                    name="sectors"
                                                    value={selectedItem}
                                                    showSearchButton={{
                                                        children: 'Search',
                                                        type: 'button'
                                                    }}
                                                    isRounded
                                                    onChange={(event) => getOnboardingAutoSuggestion(event, questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? "industry" : "job")}
                                                />
                                                {isAutoCompleteShow && !isLoading &&
                                                    <ul className='onBoardingDropdown' ref={autopopulateRef}>
                                                        {autoPopulateList.length > 0 && autoPopulateList?.map((autoComplete, idx) => {
                                                            return <li key={idx} onClick={() => selectHandlerIndustry(autoComplete.title, autoComplete.id, questions.QuestionId)}>
                                                                {autoComplete.title}
                                                            </li>
                                                        })}
                                                        {autoPopulateList.length < 1 && <li>{fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound")}</li>}
                                                    </ul>
                                                }
                                                {isLoading && (
                                                    <div className='loading'>
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                    </div>
                                                )}
                                                <div className="selectedWrapper">
                                                    {uniqueSelectedList.length > 0 && uniqueSelectedList?.map((sectors, i) => {
                                                        return (
                                                            <div className="selectedBox_jobs" key={i}>
                                                                <div className="delete_button" onClick={() => removeSectorsnJobs(i, questions.QuestionId)}>
                                                                    <i className="icon-close "></i>
                                                                </div>
                                                                <span>{sectors.title}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    }

                                    {questions.OptionType === "Options" && questions.QuestionDesignType === "MultiSelect" &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={getOptions.map((option) => {
                                                return {
                                                    onChange: (e) => {
                                                        setIsUpdateBtnDisabled(false);
                                                        let chosen = selected;
                                                        if (e.target.checked) {
                                                            chosen.push(option.AnswerId);
                                                        } else {
                                                            chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                        }
                                                        setSelectedOptions(chosen.toString());
                                                        setSelectedQuestion(questions.QuestionId);
                                                    },
                                                    ...option

                                                }
                                            })}
                                        />}

                                    {questions.QuestionDesignType === "Single Select" &&
                                        <Cirrus.SingleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={getOptions && getOptions.map((option, i) => {
                                                return {
                                                    onChange: (e) => {
                                                        setIsUpdateBtnDisabled(false);
                                                        let chosen = selected;
                                                        if (e.target.checked) {
                                                            chosen = option.AnswerId;
                                                        }
                                                        setSelectedOptions(chosen.toString());
                                                        setSelectedQuestion(questions.QuestionId);
                                                    },
                                                    ...option
                                                }
                                            })}
                                        />}
                                </Cirrus.Container>
                            )
                        }
                    })}
                </Cirrus.FullHeightContainerLayout>
            }
        </>
    )
}

export default QuestionEdit;
