//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { AssessmentActions } from "../AssessmentActions";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import "../style.css";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";

const InterestResultPage = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState();
  const [cmsMasterContent, setCmsMasterContent] = useState({});
  const [masterQuestionAnswer, setMasterQuestionAnswer] = useState();
  const [questionLabel, setQuestionLabel] = useState([]);
  const [assessmentStatus, setAssessmentStatus] = useState({
    Parrent: {},
    Skills: {},
    Interests: {},
  });

  let content = document.getElementById("assessment-result-wrapper");

  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (/Chrome/.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browser = "Safari";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Microsoft Edge";
  } else {
    browser = "Unknown Browser";
  }

  useEffect(() => {
    fetchCMSData();
    window.scrollTo(0, 0);
  }, [context.language]);

  const fetchCMSData = async () => {
    let cmsResponse = context.cmsStrengthAnalysis;
    setScreenLoader(true);
    if (Object.keys(cmsResponse).length !== 0) {
      let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
      // console.log('cmsResponse', cmsResponse)
      setCmsMasterContent(cmsResponse);
      setQuestionLabel(cmsResponse?.assessmentInterests?.subQuestionLabel);
      let refineApiCallFlag = false;
      let modifiedResultSet = [];
      let filterInterested = [];
      let filterNotInterested = [];

      cmsResponse?.assessmentInterests?.Question?.map((resourcePages) => {
        resourcePages?.map((interestedSet) => {
          modifiedResultSet.push(interestedSet);
        });
      });

      await AssessmentActions.GetStrengthsAnalysisIdentifyYourInterest(
        cmsResponse.masterCMS.ID
      ).then(
        (res) => {
          if (res.status === 200) {
            let interestedResp = res?.data?.filter(
              (interestAns) => interestAns.isInterested === true
            );
            if (interestedResp.length > 0) {
              modifiedResultSet.map((interestedSet) => {
                res?.data?.map((interestAns) => {
                  if (interestAns.categoryId === interestedSet.Skills.ID) {
                    interestedSet.IsSelected = interestAns.isInterested;
                  }
                });
                return interestedSet;
              });

              filterInterested = modifiedResultSet.filter(
                (interestedSet) => interestedSet.IsSelected === true
              );
              filterNotInterested = modifiedResultSet.filter(
                (notInterestedSet) => notInterestedSet.IsSelected === false
              );
              refineApiCallFlag = true;
            } else {
              setScreenLoader(false);
              setMasterQuestionAnswer(modifiedResultSet);
            }
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );

      if (refineApiCallFlag) {
        await AssessmentActions.GetStrengthsAnalysisResult(
          cmsResponse.masterCMS.ID
        ).then(
          (resp) => {
            if (resp.status === 200) {
              let isDataSaved = resp.data.filter(
                (item) =>
                  item.subAssessmentId === cmsResponse?.assessmentInterests?.ID
              );

              filterInterested?.map((interestedSet) => {
                isDataSaved[0]?.response?.map((refineAns) => {
                  interestedSet?.SubQuestion?.map((subQuestion) => {
                    if (refineAns.questionId === subQuestion.QuestionId) {
                      if (parseInt(refineAns.answerValue) === 1) {
                        subQuestion.RefinedAnswer =
                          parseInt(refineAns.answerValue) - 1;
                      } else {
                        subQuestion.RefinedAnswer = parseInt(
                          refineAns.answerValue
                        );
                      }
                    }
                    return subQuestion;
                  });
                });
                return interestedSet;
              });

              filterInterested?.map((interestedSet) => {
                let arr = [];
                interestedSet?.SubQuestion?.map((refineQuestion) => {
                  arr.push(refineQuestion.RefinedAnswer);
                });

                let questotal = arr?.reduce(function (a, b) {
                  return a + b;
                }, 0);
                let an =
                  (questotal / (interestedSet.SubQuestion.length * 5)) * 100;
                interestedSet.InterestedPercentage = parseInt(an);
                return interestedSet;
              });
              let mainArr = [...filterInterested, ...filterNotInterested];
              mainArr.sort(function (a, b) {
                return (
                  parseFloat(a.InterestedPercentage) -
                  parseFloat(b.InterestedPercentage)
                );
              });
              setMasterQuestionAnswer(mainArr.reverse());
              setScreenLoader(false);
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      }
      await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(
        cmsResponse.masterCMS.ID
      ).then(
        (res) => {
          if (res.status === 200) {
            let parent = res?.data.filter(
              (status) => status.subLearningResourceId === undefined
            );
            refAssessmentStatus.Parrent = parent[0];
            cmsResponse.assIds.map((x) => {
              res?.data.map((status) => {
                if (x.id === status.subLearningResourceId) {
                  refAssessmentStatus[`${x.name}`] = status;
                }
              });
            });
            setAssessmentStatus(refAssessmentStatus);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const checkIsCompleted = () => {
    if (assessmentStatus.Interests.subLearningResourceStatus !== "completed") {
      let statusPayload = {
        SubLearningResourceStatus: "completed",
        SubLearningResourceId: cmsMasterContent?.assessmentInterests?.ID,
        LastAnsweredPageId: assessmentStatus?.Interests?.lastAnsweredPageId,
      };

      AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
        cmsMasterContent.masterCMS.ID,
        statusPayload
      ).then(
        (res) => {
          if (res.status === 200) {
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (assessmentStatus.Skills.subLearningResourceStatus === "completed") {
      let payload = {
        LearningResourceStatus: "completed",
      };
      AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
        cmsMasterContent.masterCMS.ID,
        payload
      ).then(
        (res) => {
          if (res.status === 200) {
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsMasterContent));
    let label = "";
    if (Object.keys(refCmsContent).length !== 0) {
      refCmsContent?.masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const retakeHandler = async () => {
    await AssessmentActions.StrengthsAnalysisRetakeAssessment(
      cmsMasterContent?.assessmentInterests?.ID
    ).then(
      (res) => {
        if (res.status === 200) {
          checkIsCompleted();
          navigate("/" + pageOrgin.id + "/strength-analysis/interest-start");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const downloadPDF = () => {
    let content = document.getElementById("assessment-result-wrapper");

    const datetime = new Date();
    const year = datetime.getFullYear();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hours = datetime.getHours();
    const mins = datetime.getMinutes();
    const sec = datetime.getSeconds();
    const newDatetime =
      year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

    let img = new Image();
    html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        img.src = imgData;

        const pdf = new jsPDF({ orientation: "l", format: "a2", unit: "px" });
        pdf.viewerPreferences(
          { FitWindow: true, PrintArea: "MediaBox", PrintClip: "MediaBox" },
          true
        );
        console.log(browser);
        if (browser == "Safari") {
          let totalPages = Math.ceil(
            canvas.height / pdf.internal.pageSize.height
          );
          for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
              pdf.addPage();
            }
            let pageHeight = pdf.internal.pageSize.height;
            let pageWidth = pdf.internal.pageSize.width;
            let yPosition = -(i * pageHeight);

            // Add the canvas image to the PDF
            pdf.addImage(
              canvas,
              "JPEG",
              20,
              yPosition + 60,
              canvas.width,
              canvas.height
            );

            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
            pdf.setFontSize(18);
            //header background
            pdf.setFillColor(244, 244, 245);
            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

            //header
            const header = `${PDFheaderLogo}`;
            pdf.addImage(header, "png", 20, 20, 60, 20);

            //line above footer
            pdf.setDrawColor(236, 236, 236);
            pdf.line(
              20,
              pdf.internal.pageSize.height - 90,
              pdf.internal.pageSize.width - 20,
              pdf.internal.pageSize.height - 90,
              "S"
            );

            // footer
            const footerLeft = `${PDFfooterLogo}`;
            const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`;
            pdf.text(
              footerRight,
              pdf.internal.pageSize.width - 300,
              pdf.internal.pageSize.height - 50
            );
            pdf.addImage(
              footerLeft,
              "png",
              20,
              pdf.internal.pageSize.height - 80,
              160,
              45
            );
          }

          let file = new File(
            [pdf.output("blob")],
            `${cmsMasterContent?.masterCMS?.Title + " "}${
              cmsMasterContent?.assessmentInterests?.ResourceCardTitle + "-"
            }${newDatetime.toLocaleString() + ".pdf"}`,
            { type: "application/pdf" }
          );
          let formData = new FormData();
          formData.append(file.name, file);
          let candidateID =
            context?.idTokenClaims?.signupId != undefined
              ? context?.idTokenClaims?.signupId
              : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
                  "signupId"
                ];
          formData.append("OwnerId", candidateID);
          formData.append("OwnerType", "Candidate");

          MyDocumentsActions.UploadDocuments(formData).then(
            (res) => {
              if (res.status === 200) {
                let docId;
                res.data.map((doc) => {
                  docId = doc.id;
                });
                context.setGenerateInterestsPdfFlag(false);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          pdf.html(img, {
            margin: [100, 10, 120, 10],
            callback: (pdf) => {
              let pageCount = pdf.internal.getNumberOfPages();

              for (let i = 0; i < pageCount; i++) {
                pdf.setPage(i);

                let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                pdf.setFontSize(18);

                //header background
                pdf.setFillColor(244, 244, 245);
                pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

                //header
                const header = `${PDFheaderLogo}`;
                pdf.addImage(header, "png", 20, 20, 60, 20);

                //line above footer
                pdf.setDrawColor(236, 236, 236);
                pdf.line(
                  20,
                  pdf.internal.pageSize.height - 90,
                  pdf.internal.pageSize.width - 20,
                  pdf.internal.pageSize.height - 90,
                  "S"
                );

                // footer
                const footerLeft = `${PDFfooterLogo}`;
                const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`;
                pdf.text(
                  footerRight,
                  pdf.internal.pageSize.width - 300,
                  pdf.internal.pageSize.height - 50
                );
                pdf.addImage(
                  footerLeft,
                  "png",
                  20,
                  pdf.internal.pageSize.height - 80,
                  160,
                  45
                );
              }

              let file = new File(
                [pdf.output("blob")],
                `${cmsMasterContent?.masterCMS?.Title + " "}${
                  cmsMasterContent?.assessmentInterests?.ResourceCardTitle + "-"
                }${newDatetime.toLocaleString() + ".pdf"}`,
                { type: "application/pdf" }
              );
              let formData = new FormData();
              formData.append(file.name, file);
              let candidateID =
                context?.idTokenClaims?.signupId != undefined
                  ? context?.idTokenClaims?.signupId
                  : JSON.parse(
                      window.sessionStorage.getItem("setIdTokenClaims")
                    )["signupId"];
              formData.append("OwnerId", candidateID);
              formData.append("OwnerType", "Candidate");

              MyDocumentsActions.UploadDocuments(formData).then(
                (res) => {
                  if (res.status === 200) {
                    let docId;
                    res.data.map((doc) => {
                      docId = doc.id;
                    });
                    context.setGenerateInterestsPdfFlag(false);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            },
          });
        }
      }
    );
  };

  useEffect(() => {
    if (screenLoader === false) {
      if (context.generateInterestsPdfFlag) {
        downloadPDF();
      }
    }
  }, [screenLoader]);

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="assessment-page">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <Container className="pt-4 pb-4 back-exit-container">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={(e) =>
                navigate("/" + pageOrgin.id + "/strength-analysis")
              }
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
          </Col>
          <Col
            sm={12}
            md={{ span: 8, order: 2 }}
            lg={{ span: 8, order: 2 }}
            xs={{ order: 3 }}
            className="d-flex"
          ></Col>
          <Col
            md={2}
            lg={2}
            xs={{ span: 6, order: 2 }}
            className="text-end"
            onClick={() => {
              checkIsCompleted();
              navigate("/" + pageOrgin.id);
            }}
          >
            <Cirrus.Button>
              {fetchLabel("CommonResourceComponent.Exit")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>

      <Container className="assessment-review-result-wrapper assessment-result-wrapper p-lg-5 p-3 mb-5">
        <Row className="mb-5 pb-3 border-bottom you-are-done-wrapper">
          <Col lg={6}>
            <Cirrus.Header size="h4" className="mb-3 text-lg-start text-center">
              {cmsMasterContent?.interestsResultsPage?.Title}
            </Cirrus.Header>
          </Col>
          <Col lg={6} className="download-share-retake text-end">
            <Cirrus.Button
              className="me-2"
              onClick={(e) => {
                checkIsCompleted();
                navigate("/" + pageOrgin.id + "/strength-analysis");
              }}
            >
              {fetchLabel("CommonResourceComponent.NextAssessment")}
            </Cirrus.Button>
            <Cirrus.Button isSecondary className="me-2" onClick={retakeHandler}>
              {fetchLabel("CommonComponentCommonRetakeButton")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="pl-5 mb-3 text-start">
            <Cirrus.Headline size="Small">
              {fetchLabel(
                "Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.InterestsReviewPageSubHeading"
              )}
            </Cirrus.Headline>
            <p>
              <Cirrus.RichText
                content={cmsMasterContent?.interestsResultsPage?.Description}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="pl-5 mb-3">
            {masterQuestionAnswer && masterQuestionAnswer.length > 0 ? (
              <>
                {masterQuestionAnswer?.map((interestedSet, firstSetIndex) => {
                  return (
                    <Container className="mb-2 p-lg-4 p-3 result-progress">
                      <Row className="align-items-center gap-3 gap-md-0">
                        <Col
                          lg={4}
                          md={{ span: 6, order: 1 }}
                          xs={{ span: 12, order: 1 }}
                          className="text-start"
                        >
                          <Cirrus.Label>
                            <span className="skill-heading">
                              {interestedSet?.Skills?.Value}
                            </span>
                          </Cirrus.Label>
                        </Col>
                        <Col
                          lg={{ span: 7, order: 2 }}
                          md={{ span: 12, order: 3 }}
                          xs={{ span: 12, order: 3 }}
                          className="mt-md-3 mt-lg-0"
                        >
                          <Row>
                            <Col
                              className="d-flex align-items-center justify-content-md-end justify-content-start text-end interest-result-lables-start"
                              lg={{ order: 1 }}
                              md={{ order: 1 }}
                              sm={{ order: 1 }}
                              xs={{ order: 1 }}
                            >
                              <Cirrus.Label className="progress-label">
                                <small> {questionLabel[0]?.AnswerText} </small>
                              </Cirrus.Label>
                            </Col>
                            <Col
                              className="d-flex align-items-center strength-progress-bar"
                              lg={{ order: 2, span: 6 }}
                              md={{ order: 2, span: 8 }}
                              sm={{ order: 3 }}
                              xs={{ span: 12, order: 3 }}
                            >
                              <ProgressBar
                                now={interestedSet.InterestedPercentage}
                                label={`${interestedSet.InterestedPercentage}%`}
                                visuallyHidden
                                className="progressBar w-100"
                              />
                            </Col>
                            <Col
                              className="d-flex align-items-center result-lables"
                              lg={{ order: 3 }}
                              md={{ order: 3 }}
                              sm={{ order: 2 }}
                              xs={{ order: 2 }}
                            >
                              <Cirrus.Label className="progress-label">
                                <small>{questionLabel[4]?.AnswerText}</small>
                              </Cirrus.Label>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg={1}
                          md={{ span: 6, order: 2 }}
                          xs={{ span: 12, order: 2 }}
                          className="strength-interest-result_page"
                        >
                          <div className="progress-wrapper strength-progress-wrapper">
                            <Cirrus.Label className="progress-label">
                              {interestedSet.InterestedPercentage}%
                            </Cirrus.Label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  );
                })}
              </>
            ) : (
              <div>No Records Found</div>
            )}
          </Col>
        </Row>
      </Container>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
      <div style={{ opacity: "0" }}>
        <div
          id="assessment-result-wrapper"
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            zIndex: "-1",
            display: "block",
            width: "1300px",
          }}
        >
          <table
            cellPadding={10}
            cellSpacing={10}
            style={{
              width: "90%",
              border: "0px",
              borderRadius: "5px",
              textAlign: "left",
              fontSize: "13px",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <Container style={{ width: "100%" }}>
                    <Row>
                      <Col lg={10} className="pl-5 mb-3 text-start">
                        <Cirrus.Headline size="Small">
                          {fetchLabel(
                            "Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.InterestsReviewPageSubHeading"
                          )}
                        </Cirrus.Headline>
                        <p>
                          <Cirrus.RichText
                            content={
                              cmsMasterContent?.interestsResultsPage
                                ?.Description
                            }
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-5 mb-3" style={{ width: "100%" }}>
                        {masterQuestionAnswer?.map(
                          (interestedSet, firstSetIndex) => {
                            return (
                              <Container className="mb-2 p-4 result-progress">
                                <Row>
                                  <Col lg={4} className="text-start">
                                    <Cirrus.Label>
                                      {interestedSet?.Skills?.Value}
                                    </Cirrus.Label>
                                  </Col>
                                  <Col lg={6}>
                                    <Row>
                                      <Col className="text-end">
                                        <Cirrus.Label className="progress-label">
                                          <small>
                                            {" "}
                                            {questionLabel[0]?.AnswerText}
                                          </small>
                                        </Cirrus.Label>
                                      </Col>
                                      <Col className="d-flex align-items-center">
                                        <ProgressBar
                                          now={
                                            interestedSet?.InterestedPercentage
                                          }
                                          label={`${interestedSet?.InterestedPercentage}%`}
                                          visuallyHidden
                                          className="progressBar w-100"
                                        />
                                      </Col>
                                      <Col>
                                        <Cirrus.Label className="progress-label">
                                          <small>
                                            {questionLabel[4]?.AnswerText}
                                          </small>
                                        </Cirrus.Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={1} className="text-end">
                                    <div className="progress-wrapper">
                                      <Cirrus.Label className="progress-label">
                                        {interestedSet.InterestedPercentage}%
                                      </Cirrus.Label>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </Container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InterestResultPage;
