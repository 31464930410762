//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState } from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import './job.css';
import Moment from "moment";
import { useNavigate } from "react-router-dom";


function JobCard({ data, layout, saveJobHandler, staticCopy, cmsJobCardContent, loadMoreJobHandler, savedDataLength, viewAll, paginationState }) {
    const navigate = useNavigate();
    const fetchJobCardCMSData = (key) => {
        let refCmsJobCardContent = JSON.parse(
            JSON.stringify(cmsJobCardContent)
        );
        let label = "";
        if (refCmsJobCardContent?.pageContent?.Labels) {
            refCmsJobCardContent?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const convertPostedDate = (date) => {
        let postDate = date && date;
        let todayDate = new Date();
        postDate = Moment(postDate).format("MM-DD-YYYY");
        todayDate = Moment(todayDate).format("MM-DD-YYYY");
        function parseDate(str) {
            var date = str && str.split("-");
            return new Date(date && date[2], date && date[0] - 1, date && date[1]);
        }
        function dateDiff(first, second) {
            return Math.round((second - first) / (1000 * 60 * 60 * 24));
        }
        let posted_Date = dateDiff(parseDate(postDate), parseDate(todayDate));
        let postedDate;
        if (posted_Date < 1) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.Hoursago").replace("#TIME#", posted_Date);
        } else if (posted_Date >= 1 && posted_Date <= 30) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.Day").replace("#TIME#", posted_Date);
        } else if (posted_Date > 30 && posted_Date <= 60) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.1Month").replace("#TIME#", posted_Date - 30);
        } else if (posted_Date > 60 && posted_Date <= 90) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.2Month").replace("#TIME#", posted_Date - 60);
        } else if (posted_Date > 90 && posted_Date <= 180) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.3Month");
        } else if (posted_Date > 180) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.6Month");
        }

        postDate =
            fetchJobCardCMSData("JobSearch.JobCard.Posted") + " " + postedDate;
        return postDate;
    };

    const JDclickedId = (id) => {
        const path = "/job-details/:id".replace(":id", id);
        navigate('/bookmark' + path)
    }
    return (
        <>
            {<>
                {/* <div style={{ paddingTop: '5px', width: '100%' }}>
                    <Cirrus.Label id='searchResultCount'>{paginationState && paginationState}</Cirrus.Label>
                </div>
                <br /> */}
                {layout === 'twocolumn' ? <ul className='jobListTwoColumn'>
                    {
                        data?.map((job, id) => <li key={job.jobId}>
                            {job.companyName && job.companyName.includes("Manpower") ? <Cirrus.JobCard
                                companyLogo={{
                                    altText: job.companyLogo.altText,
                                    src: job.companyLogo.src
                                }}
                                companyName={job.companyName}
                                contractType={job.employmentType}
                                description={job.jobDescription}
                                id={job.jobId}
                                isComparable={false}
                                isComparing={false}
                                isMatchText={job.matchPercentage ? job.isMatchText : ''}
                                isSaveable={true}
                                isSaved={true}
                                jobRole={job.jobTitle}
                                location={job.jobLocation}
                                onClick={() => {
                                    JDclickedId(job.jobId)
                                }}
                                onComparingButtonClick={() => { }}
                                onSaveButtonClick={() => saveJobHandler(job)}
                                posted={convertPostedDate(job.jobPostedTimeStamp)}
                                salary={job.salary}
                                startDate={job.jobStartTimeStamp ? Moment(job.jobStartTimeStamp).format("YYYY-MM-DD") : "Flexible start"}
                                staticCopy={{
                                    CollapseJobInfo: staticCopy.CollapseJobInfo,
                                    CompanyName: staticCopy.CompanyName,
                                    Compare: staticCopy.Compare,
                                    ExpandJobInfo: staticCopy.ExpandJobInfo,
                                    IsFeatured: staticCopy.IsFeatured,
                                    IsNotCompared: staticCopy.IsNotCompared,
                                    IsSaved: staticCopy.IsSaved,
                                    IsUnsaved: staticCopy.IsUnsaved,
                                    JobDescription: staticCopy.JobDescription,
                                    JobInfo: staticCopy.JobInfo,
                                    JobRole: staticCopy.JobRole,
                                    JobType: staticCopy.JobType,
                                    Location: staticCopy.Location,
                                    Salary: staticCopy.Salary,
                                    Save: staticCopy.Save,
                                    StartDate: staticCopy.StartDate
                                }}
                            />
                                :
                                <Cirrus.JobCard
                                    companyName={job.companyName}
                                    contractType={job.employmentType}
                                    description={job.jobDescription}
                                    id={job.jobId}
                                    isComparable={false}
                                    isComparing={false}
                                    isMatchText={job.matchPercentage ? job.isMatchText : ''}
                                    isSaveable={true}
                                    isSaved={true}
                                    jobRole={job.jobTitle}
                                    location={job.jobLocation}
                                    onClick={() => {
                                        JDclickedId(job.jobId)
                                    }}
                                    onComparingButtonClick={() => { }}
                                    onSaveButtonClick={() => saveJobHandler(job)}
                                    posted={convertPostedDate(job.jobPostedTimeStamp)}
                                    salary={job.salary}
                                    startDate={job.jobStartTimeStamp ? Moment(job.jobStartTimeStamp).format("YYYY-MM-DD") : "Flexible start"}
                                    staticCopy={{
                                        CollapseJobInfo: staticCopy.CollapseJobInfo,
                                        CompanyName: staticCopy.CompanyName,
                                        Compare: staticCopy.Compare,
                                        ExpandJobInfo: staticCopy.ExpandJobInfo,
                                        IsFeatured: staticCopy.IsFeatured,
                                        IsNotCompared: staticCopy.IsNotCompared,
                                        IsSaved: staticCopy.IsSaved,
                                        IsUnsaved: staticCopy.IsUnsaved,
                                        JobDescription: staticCopy.JobDescription,
                                        JobInfo: staticCopy.JobInfo,
                                        JobRole: staticCopy.JobRole,
                                        JobType: staticCopy.JobType,
                                        Location: staticCopy.Location,
                                        Salary: staticCopy.Salary,
                                        Save: staticCopy.Save,
                                        StartDate: staticCopy.StartDate
                                    }}
                                />
                            }
                        </li>
                        )
                    }
                </ul>
                    : <ul className='jobListOneColumn'>
                        {
                            data?.map((job, id) => <li key={job.jobId}>
                                {job.companyName && job.companyName.includes("Manpower") ? <Cirrus.JobCard
                                    companyLogo={{
                                        altText: job.companyLogo.altText,
                                        src: job.companyLogo.src
                                    }}
                                    companyName={job.companyName}
                                    contractType={job.employmentType}
                                    description={job.jobDescription}
                                    id={job.jobId}
                                    isComparable={false}
                                    isComparing={false}
                                    isMatchText={job.isMatchText}
                                    isSaveable={true}
                                    isSaved={true}
                                    jobRole={job.jobTitle}
                                    location={job.jobLocation}
                                    onClick={() => {
                                        JDclickedId(job.jobId)
                                    }}
                                    onComparingButtonClick={() => { }}
                                    onSaveButtonClick={() => saveJobHandler(job)}
                                    posted={convertPostedDate(job.jobPostedTimeStamp)}
                                    salary={job.salary}
                                    startDate={job.jobStartTimeStamp ? Moment(job.jobStartTimeStamp).format("YYYY-MM-DD") : "Flexible start"}
                                    staticCopy={{
                                        CollapseJobInfo: staticCopy.CollapseJobInfo,
                                        CompanyName: staticCopy.CompanyName,
                                        Compare: staticCopy.Compare,
                                        ExpandJobInfo: staticCopy.ExpandJobInfo,
                                        IsFeatured: staticCopy.IsFeatured,
                                        IsNotCompared: staticCopy.IsNotCompared,
                                        IsSaved: staticCopy.IsSaved,
                                        IsUnsaved: staticCopy.IsUnsaved,
                                        JobDescription: staticCopy.JobDescription,
                                        JobInfo: staticCopy.JobInfo,
                                        JobRole: staticCopy.JobRole,
                                        JobType: staticCopy.JobType,
                                        Location: staticCopy.Location,
                                        Salary: staticCopy.Salary,
                                        Save: staticCopy.Save,
                                        StartDate: staticCopy.StartDate
                                    }}
                                />
                                    :
                                    <Cirrus.JobCard
                                        companyName={job.companyName}
                                        contractType={job.employmentType}
                                        description={job.jobDescription}
                                        id={job.jobId}
                                        isComparable={false}
                                        isComparing={false}
                                        isMatchText={job.isMatchText}
                                        isSaveable={true}
                                        isSaved={true}
                                        jobRole={job.jobTitle}
                                        location={job.jobLocation}
                                        onClick={() => {
                                            JDclickedId(job.jobId)
                                        }}
                                        onComparingButtonClick={() => { }}
                                        onSaveButtonClick={() => saveJobHandler(job)}
                                        posted={convertPostedDate(job.jobPostedTimeStamp)}
                                        salary={job.salary}
                                        startDate={job.jobStartTimeStamp ? Moment(job.jobStartTimeStamp).format("YYYY-MM-DD") : "Flexible start"}
                                        staticCopy={{
                                            CollapseJobInfo: staticCopy.CollapseJobInfo,
                                            CompanyName: staticCopy.CompanyName,
                                            Compare: staticCopy.Compare,
                                            ExpandJobInfo: staticCopy.ExpandJobInfo,
                                            IsFeatured: staticCopy.IsFeatured,
                                            IsNotCompared: staticCopy.IsNotCompared,
                                            IsSaved: staticCopy.IsSaved,
                                            IsUnsaved: staticCopy.IsUnsaved,
                                            JobDescription: staticCopy.JobDescription,
                                            JobInfo: staticCopy.JobInfo,
                                            JobRole: staticCopy.JobRole,
                                            JobType: staticCopy.JobType,
                                            Location: staticCopy.Location,
                                            Salary: staticCopy.Salary,
                                            Save: staticCopy.Save,
                                            StartDate: staticCopy.StartDate
                                        }}
                                    />}
                            </li>
                            )
                        }
                    </ul>}
                {
                    viewAll === "Jobs" && (savedDataLength && savedDataLength > 10 && savedDataLength !== data?.length) ? <>
                        {
                            <div style={{ paddingTop: '50px', width: '100%' }}>
                                <Cirrus.Button
                                    onClick={loadMoreJobHandler}
                                >
                                    {
                                        'Load more'
                                    }
                                </Cirrus.Button>
                            </div>
                        }
                    </> : ''
                }
            </>
            }
        </>
    )
}

export default JobCard
