//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../../common/helpers/HttpHelper';
import environments from '../../../common/environments/environment';
const http = new httpHelper();

export const ResourcesActions = {
    GetResourcesCMS: getResourcesCMS,
    SaveResourceAssessmentCard: saveResourceAssessmentCard,
    GetResourceData: getResourceData,
    UpdateStatus: updateStatus,
    UpdateEvent : updateEvent,
    ExternalToolsRedirectURL: externalToolsRedirectURL,
    GetResmeBuilderUrl: getResmeBuilderUrl,
}

function getResourcesCMS (payload) {
    return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function saveResourceAssessmentCard(resourceType, id, payload) {
    let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
    return http.learningResourcePut(url, payload);
}

function getResourceData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function updateStatus(resourceType, id, payload) {
    return http.AssessmentsPut(environments.SAVE_ASSESSMENT_CARD.replace('{resourcetype}', resourceType).replace('{id}', id), payload);
}
function updateEvent(payload, id) {
    let url = environments.UPDATE_EVENT.replace('{id}', id);
    return http.updateEvents(url, payload);
}

function getResmeBuilderUrl() {
    return http.GetResumeBuilderUrl(environments.GET_RESUME_BUILDER_URL);
}

function externalToolsRedirectURL(id) {
    return http.AssessmentsGet(environments.GET_EXTERNAL_TOOLS_REDIRECT_URL.replace("{toolId}", id));
}