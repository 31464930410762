//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const DashboardActions = {
    GetDashboardData:getDashboardData,
    SaveAssessmentCard: saveAssessmentCard,
    GetCMSData: getCMSData,
    GetCMSWidgetData: getCMSWidgetData,
    GetAssessmentStatusAndSavedResponseById: getAssessmentStatusAndSavedResponseById,
    GetMyCoach: getMyCoach,
    GetCMScontent: getCMScontent,
    GetAllDocuments:getAllDocuments,
    GetDocument:getDocument,
    UploadDocuments:uploadDocuments,
    DownloadDocument:downloadDocument,
    DeleteDocument:deleteDocument,
    UpdateDocument:updateDocument,
    ResumeUploadStatus: resumeUploadStatus,
    GetMatchedjobs: getMatchedjobs,
    GetCoachingSessions:getCoachingSessions,
    SaveMeetingConfirmedStatus:SaveMeetingConfirmedStatus,
    SaveRescheduleMeeting: SaveRescheduleMeeting,
    GetMeetingRequestCronofyAvailabilitySlots: GetMeetingRequestCronofyAvailabilitySlots,
    GetDiscoverData:getDiscoverData,
    DeleteMeeting: deleteMeeting,
    GetProgramVideo: getProgramVideo,
    UpdateProgramVideo: updateProgramVideo,
    GetEvents : getEvents,
    UpdateEvent : updateEvent,
    GetFeaturedResourceData: getFeaturedResourceData,
    GetLastSession: getLastSession,
    GetFeaturedResourceData: getFeaturedResourceData,
    Get_30_secondCommercialCMS: get_30_secondCommercialCMS,
    GetResmeBuilderUrl: getResmeBuilderUrl,
    SaveResmeBuilderUrl: saveResmeBuilderUrl,
    UploadResume: uploadResume,
    GetUploadResumeStatus:getUploadResumeStatus,
    ExternalToolsRedirectURL: externalToolsRedirectURL,
    GetDoceboLink: getDoceboLink,
    GetFeaturedetails: getFeaturedetails,
    GetClientSpecificProgramVideo:getClientSpecificProgramVideo
}

function resumeUploadStatus() {
    let url = environments.GET_RESUME_UPLOADED_STATUS;
    return http.jobGet(url);
}

function getFeaturedResourceData(payload) {
    let url = environments.GET_IVEIW_CSM;
    return http.CMSpost(url, payload);
}

function getMatchedjobs(payload) {
    return http.jobPost(environments.GET_RESUME_MATCH, payload);
}

function getCMScontent(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function getAllDocuments(payload) {
    return http.documentsPost(environments.GET_ALL_DOCUMENTS, payload);
}

function getDocument(id) {
    let savePreferences = environments.GET_DOCUMENT.replace('{id}', id);
    return http.documentsGet(savePreferences);
}

function uploadDocuments(payload) {
    return http.documentsPost(environments.UPLOAD_DOCUMENTS, payload);
}

function downloadDocument(id) {
    let savePreferences = environments.DOWNLOAD_DOCUMENT.replace('{id}', id);
    return http.documentsDownloadGet(savePreferences);
}

function deleteDocument(id) {
    let savePreferences = environments.DELETE_DOCUMENT.replace('{id}', id);
    return http.documentsDelete(savePreferences);
}

function updateDocument(id, payload) {
    let savePreferences = environments.UPDATE_DOCUMENT.replace('{id}', id);
    return http.documentsPut(savePreferences, payload);
}


function getDashboardData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function saveAssessmentCard(resourceType, id, payload) {
    let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
    return http.learningResourcePut(url, payload);
}

function getCMSData(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function getCMSWidgetData(payload) {
    return http.CMSpost(environments.GET_DASHBOARD_WIDGET, payload);
}

function getAssessmentStatusAndSavedResponseById(assessmentId) {
    return http.AssessmentsGet(environments.GET_ASSESSMENT_AND_RESPONSES_BY_ASSESSMENT_ID.replace('{id}', assessmentId));
}

function getMyCoach() {
    return http.get(environments.GET_MY_COACH);
}

function getCoachingSessions(payload) {
    return http.CoachingPost(environments.CoachingSessions, payload);
}

function SaveMeetingConfirmedStatus(id, payload) {
    let relpaceId = environments.ConfirmCoachMeeting.replace('{id}', id);
    return http.CoachingPut(relpaceId, payload);
}

function SaveRescheduleMeeting(payload) {
    return http.CoachingPost(environments.CoachingMeetingUpdate, payload);
}

function GetMeetingRequestCronofyAvailabilitySlots (payload) {
    return http.CoachingPost(environments.GET_COACH_MEETING_CRONOFY_AVAILABLE_SLOTS, payload);
}

function getDiscoverData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function deleteMeeting(meetingid) {
    let deletemeeting = environments.DeleteCoachMeeting.replace('{meetingid}', meetingid);
    return http.CoachingDelete(deletemeeting);
}

function getProgramVideo() {
    return http.get(environments.GET_PROGRAM_VIDEO);
}

function getClientSpecificProgramVideo(payload) {
    return http.learningResourcePost(environments.GET_CLIENT_SPECIFIC_VIDEO, payload);
}

function updateProgramVideo(payload) {
    return http.post(environments.UPDATE_PROGRAM_VIDEO, payload);
}

function getEvents(payload) {
    return http.GetEvents(environments.GET_EVENTS, payload);
}

function updateEvent(payload, id) {
    let url = environments.UPDATE_EVENT.replace('{id}', id);
    return http.updateEvents(url, payload);
}

function getLastSession() {
    return http.get(environments.GET_LAST_SESSION);

}
function get_30_secondCommercialCMS(payload) {
    return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function getResmeBuilderUrl() {
    return http.GetResumeBuilderUrl(environments.GET_RESUME_BUILDER_URL);
}

function saveResmeBuilderUrl(payload) {
    return http.SaveResumeBuilderUrl(environments.SAVE_RESUME_BUILDER_URL, payload);
}

function uploadResume(payload) {
    return http.jobPost(environments.UPLOAD_RESUME, payload);
}

function getUploadResumeStatus() {
    return http.jobGet(environments.GET_RESUME_UPLOADED);
}

function externalToolsRedirectURL(id) {
    return http.AssessmentsGet(environments.GET_EXTERNAL_TOOLS_REDIRECT_URL.replace("{toolId}", id));
}

function getDoceboLink() {
    return http.GetDoceboUrl(environments.GET_DOCEBO_LINK);
}
function getFeaturedetails(){
    return http.AssessmentsGet(environments.GET_Featuredetails)
}