//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../../environments/environment';
const http = new httpHelper();

export const UploadActions = {
    UploadDocuments:uploadDocuments,
    DeleteDocument:deleteDocument,
    UpdateDocument:updateDocument,
    UploadResume: uploadResume,
    CreateActivity:createActivity,
}

function uploadDocuments(payload) {
    return http.documentsPost(environments.UPLOAD_DOCUMENTS, payload);
}

function deleteDocument(id) {
    let savePreferences = environments.DELETE_DOCUMENT.replace('{id}', id);
    return http.documentsDelete(savePreferences);
}

function updateDocument(id, payload) {
    let savePreferences = environments.UPDATE_DOCUMENT.replace('{id}', id);
    return http.documentsPut(savePreferences, payload);
}

function uploadResume(payload) {
    return http.jobPost(environments.UPLOAD_RESUME, payload);
}

function createActivity(payload) {
    return http.post(environments.CreateCandidateActivities, payload);
}

