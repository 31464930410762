//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { saveAs } from "file-saver";
import { DashboardActions } from "./dashboardActions";
import MultipleFilesUpload from "../../common/components/uploadFile/MultipleFilesUpload";
import { AssessmentActions } from "../assessments/AssessmentActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";

function MyDocument({
  documentCMS,
  documentsLists,
  setDocumentsLists,
  getAllDocumentsLists,
}) {
  const context = useContext(Context);
  const navigate = useNavigate();
  const [uploadModal, setUploadModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [documentName, setDocumentName] = useState();
  const [screenLoader, setScreenLoader] = useState(false);
  const [uploadModalFlag, setUploadModalFlag] = useState({
    apiFlag: "DocumentUpload",
    replaceID: "",
    uploadType: "Multiple",
  });
  const [noRecordsMsg, setNoRecordsMsg] = useState("");
  const [cmsMyDocuments, setCmsMyDocuments] = useState({
    title: "",
    pageLabel: [],
    sortOptions: [],
  });
  const [cmsUploadDocuments, setCmsUploadDocuments] = useState({});

  const [embedFile, setEmbedFile] = useState();
  const [embedFileType, setEmbedFileType] = useState();
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [reference, setReference] = useState(null);
  const [viewModalDocID, setViewModalDocID] = useState();
  const appInsights = useAppInsightsContext();

  const fetchCMSContent = async () => {
    let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));

    setScreenLoader(true);
    let payload = {
      PageName: "MyDocuments",
    };
    await DashboardActions.GetCMScontent(payload).then(
      (res) => {
        if (res.status === 200) {
          res?.data?.Pages?.map((x) => {
            let sortArray = [];
            refCmsMyDocuments.title = x.Title;
            x.Components?.[
              "MyDocumentsPage.MyDocuments"
            ]?.LOVList[0]?.Values?.map((sort) => {
              let obj = {
                name: sort.Value,
                value: sort.Value,
              };
              sortArray.push(obj);
            });
            refCmsMyDocuments.sortOptions = sortArray;
            refCmsMyDocuments.pageLabel =
              x.Components?.["MyDocumentsPage.MyDocuments"]?.Labels;

            refCmsUploadDocuments["modalTitle"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.Title;
            refCmsUploadDocuments["uploadDescription"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.Description;
            refCmsUploadDocuments["dragDropText"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.RichTexts;
            refCmsUploadDocuments["uploadProgressDescription"] =
              x.Components?.["MyDocumentsPage.UploadProgressView"]?.Description;
            let labels = [
              ...x.Components?.["MyDocumentsPage.UploadDocuments"]?.Labels,
              ...x.Components?.["Common.UploadProgressView"]?.Labels,
            ];
            refCmsUploadDocuments["labels"] = labels;
          });
          setCmsMyDocuments(refCmsMyDocuments);
          setCmsUploadDocuments(refCmsUploadDocuments);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
    let label = "";
    if (refCmsMyDocuments?.pageLabel) {
      refCmsMyDocuments?.pageLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExitLabel = (key) => {
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
    let label = "";
    if (refCmsUploadDocuments?.labels) {
      refCmsUploadDocuments?.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const openUploadModal = async () => {
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
    if (Object.keys(refCmsUploadDocuments).length == 0) {
      await fetchCMSContent();
    }
    setUploadModalFlag({
      apiFlag: "DocumentUpload",
      replaceID: "",
      uploadType: "Multiple",
    });
    setUploadModal(true);
  };

  const uploadModalCloseHandler = () => {
    setUploadModal(false);
  };

  const viewModalCloseHandler = () => {
    setViewModal(false);
    setEmbedFile("");
    setEmbedFileType("");
  };

  const deleteHandler = async (id) => {
    let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
    await DashboardActions.DeleteDocument(id).then(
      (res) => {
        if (res.status === 200) {
          let filteredItem = refDocumentsLists.filter((fw) => {
            return fw.id !== id;
          });
          setViewModal(false);
          setDocumentsLists(filteredItem);
          if (refDocumentsLists.length === 1) {
            setNoRecordsMsg(
              fetchLabel("MyDocumentsPage.MyDocuments.NoDocumentsMessage")
            );
          } else {
            setNoRecordsMsg("");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const downloadHandler = async (id) => {
    let fileName = documentsLists.filter((x) => {
      if (x.id === id) {
        return x.name;
      }
    });
    await DashboardActions.DownloadDocument(id).then(
      (response) => {
        if (response && response.status === 200 && response.data) {
          TrackActions.EventTrack(appInsights, "DownloadDocument", {
            DocumentId: id,
          });
          TrackActions.PageAction("DownloadDocument", { DocumentId: id });
          let contentTypeHeader = response.headers["content-type"];
          var blob = new Blob([response.data], {
            type: contentTypeHeader.toLowerCase(),
            charset: "UTF-8",
          });
          saveAs(blob, fileName[0].name);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const replaceHandler = async (id) => {
    setUploadModal(true);
    setViewModal(false);
    setUploadModalFlag({
      apiFlag: "DocumentReplace",
      replaceID: id,
      uploadType: "Single",
    });
  };

  const viewHandler = async (id) => {
    TrackActions.EventTrack(appInsights, "ViewDocument", { DocumentId: id });
    TrackActions.PageAction("ViewDocument", { DocumentId: id });
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
    if (Object.keys(refCmsUploadDocuments).length == 0) {
      await fetchCMSContent();
    }
    let fileName = documentsLists.filter((x) => {
      if (x.id === id) {
        return x.name;
      }
    });
    if (fileName[0].fileFormat === "pdf" || fileName[0].fileFormat === "docx") {
      setViewModal(true);
      setViewModalDocID(id);
    }
    documentsLists.map((document) => {
      if (id === document.id) {
        setDocumentName(document.documentName);
      }
    });

    await DashboardActions.DownloadDocument(id).then(
      (response) => {
        if (response && response.status === 200 && response.data) {
          let contentTypeHeader = response.headers["content-type"];
          var blob = new Blob([response.data], {
            type: contentTypeHeader.toLowerCase(),
            charset: "UTF-8",
          });

          /* embed is working only for pdf and images */
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (contentTypeHeader === "application/pdf") {
              let base64data =
                reader.result + "#toolbar=0&page=1&view=FitH&zoom=100";
              setEmbedFile(
                "data:application/pdf;base64," +
                  base64data.replace("data:", "").replace(/^.+,/, "")
              );
              setEmbedFileType("application/pdf");
            } else if (fileName[0].fileFormat === "docx") {
              let docFile = new File([blob], "document");
              setEmbedFile(docFile);
              let docx = require("../../../node_modules/docx-preview/dist/docx-preview");

              var docxOptions = Object.assign(docx.defaultOptions, {
                useMathMLPolyfill: true,
              });
              //Reference the Container DIV.
              var container = document.querySelector("#word-container");

              //Render the Word Document.
              docx.renderAsync(docFile, container, null, docxOptions);
            } else {
              let docFile = new File([blob], fileName[0].documentName);
              saveAs(docFile);
            }
          };
          /**********************************************/
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const finishUploadHandler = (uploadedFiles) => {
    getAllDocumentsLists(true);
    setUploadModal(false);
  };

  const sortHandler = (event) => {
    let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
    if (event.target.value === "A-Z") {
      refDocumentsLists.sort(function (a, b) {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      setDocumentsLists(refDocumentsLists);
    }
    if (event.target.value === "Type") {
      refDocumentsLists.sort(function (a, b) {
        if (a.type.toUpperCase() < b.type.toUpperCase()) {
          return -1;
        }
        if (a.type.toUpperCase() > b.type.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      setDocumentsLists(refDocumentsLists);
    }
    if (event.target.value === "Newest") {
      setDocumentsLists(sortByNewest(refDocumentsLists));
    }
  };

  const sortByNewest = (data) => {
    data.sort(function (a, b) {
      return new Date(b.createdTimeStamp) - new Date(a.createdTimeStamp);
    });
    return data;
  };

  const convertSize = (bytes) => {
    const sufixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (!bytes && "0 Bytes") ||
      (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i]
    );
  };

  const dateFormat = (date) => {
    let convDate = Moment(date).format("DD/MM/YYYY");
    return convDate;
  };

  const fetchLabels = (key) => {
    let refCmsContent = documentCMS && documentCMS;
    let label = "";
    if (refCmsContent?.labels) {
      refCmsContent?.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchRichTexts = (key) => {
    let refCmsContent = documentCMS && documentCMS;
    let label = "";
    if (refCmsContent?.richText) {
      refCmsContent?.richText.map((x) => {
        if (x.Key === key) {
          label = x.Value;
        }
      });
    }
    return label;
  };

  return (
    <>
      <div className="p-1 w-100 rounded-right jobseeker-doc-cols">
        <Cirrus.MyDocuments
          bodyText={fetchRichTexts(
            "MyDocumentsPage.MyDocuments.FirstVisitDescription"
          )}
          heading={documentCMS?.title}
          primaryButtonProps={{
            children: fetchLabels("MyDocumentsPage.MyDocuments.Upload"),
            href: "",
            isAlt: true,
            isSecondary: true,
            leadingIcon: "save_alt",
            size: "Large",
            onClick: () => {
              openUploadModal();
            },
            className: "upload-icon-btn",
          }}
          onView={viewHandler}
          linkProps={{
            children: fetchLabels(
              "MyDocumentsPage.MyDocuments.GoToMyDocuments"
            ),
            onClick: () => {
              navigate("/my-documents");
            },
          }}
        />
        {uploadModal && (
          <Cirrus.Modal
            closeButtonProps={{
              children: fetchExitLabel(
                "MyDocumentsPage.UploadDocuments.DocumentUploadExit"
              ),
              isSecondary: true,
              onClick: uploadModalCloseHandler,
              size: "Small",
              type: "button",
              disabled: context.uploadModalExitDisable,
            }}
            headerProps={{
              children: cmsUploadDocuments?.modalTitle,
              className: "no-margin",
              size: "h5",
            }}
            isScrollable
            role="dialog"
            size="default"
          >
            <MultipleFilesUpload
              cmsContent={cmsUploadDocuments}
              finishUploadHandler={finishUploadHandler}
              uploadModalFlag={uploadModalFlag}
              extension={"Common.UploadProgressView"}
              origin={"MyDocuments"}
            />
          </Cirrus.Modal>
        )}
        {viewModal && (
          <Cirrus.ViewDocumentModal
            className="document-viewer-modal"
            closeButtonProps={{
              children: fetchExitLabel(
                "MyDocumentsPage.UploadDocuments.DocumentUploadExit"
              ),
              onClick: viewModalCloseHandler,
              size: "Small",
              type: "button",
            }}
            headerProps={{
              children: documentName,
              className: "document-name no-margin",
              size: "h5",
            }}
            role="dialog"
            size="default"
            isScrollable
          >
            <Cirrus.IconButton
              icon="more_vert"
              type="button"
              className="moreBtn"
              isSecondary="true"
              onClick={() => setIsPopUpVisible(!isPopUpVisible)}
              ref={setReference}
            />
            {isPopUpVisible && (
              <Cirrus.PopUp
                referenceElement={reference}
                onClose={() => {
                  setIsPopUpVisible(false);
                }}
                className="viewPopup"
              >
                <Cirrus.LinkButton
                  children={fetchLabel(
                    "MyDocumentsPage.MyDocuments.DownloadDocument"
                  )}
                  type="button"
                  onClick={() => downloadHandler(viewModalDocID)}
                  className="viewLinks"
                />
                <Cirrus.LinkButton
                  children={fetchLabel(
                    "MyDocumentsPage.MyDocuments.ReplaceDocument"
                  )}
                  type="button"
                  onClick={() => replaceHandler(viewModalDocID)}
                  className="viewLinks"
                />
                <Cirrus.LinkButton
                  children={fetchLabel(
                    "MyDocumentsPage.MyDocuments.DeleteDocument"
                  )}
                  type="button"
                  onClick={() => deleteHandler(viewModalDocID)}
                  className="viewLinks"
                />
              </Cirrus.PopUp>
            )}
            {/* <embed src={embedFile} type={embedFileType} width="100%" style={{ minHeight: '450px', maxHeight: '100%' }} /> */}

            {embedFileType === "application/pdf" && (
              <embed
                src={embedFile}
                type={embedFileType}
                width="100%"
                style={{ minHeight: "450px", maxHeight: "100%" }}
              />
            )}
            {embedFileType !== "application/pdf" && (
              <div id="word-container"></div>
            )}
          </Cirrus.ViewDocumentModal>
        )}
      </div>
    </>
  );
}
export default MyDocument;
