//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import { Row, Col, Container } from "react-bootstrap";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

function StartPage() {
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [startPageData, setStartPageData] = useState();

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        let resData = context.cmsEvaluateAJobOffer;
        setScreenLoader(true)
        if (Object.keys(resData).length !== 0) {
            setCmsMasterContent(resData)

            let payload = {
                learningResourceType: "assessmentsandtasks",
                recommended: "false",
                isAllLearningResources: "true",
                ResourceCategory: "assessmentsandtasks"
            };
            await AssessmentActions.GetStartPageData(payload).then(
                (res) => {
                    if (res.status === 200) {
                        let resData = res?.data && res?.data?.responsemodel;
                        let refData = resData?.filter(
                            (x) => x.key === "Assessments.EvaluateAJobOffer"
                        );
                        setStartPageData(refData && refData[0] && refData[0]);
                        setScreenLoader(false)
                    } else {
                        setScreenLoader(false)
                    }
                },
                (err) => {
                    console.log(err);
                    setScreenLoader(false)
                }
            );
        }
    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (refCmsMasterContent?.masterLabel) {
            refCmsMasterContent?.masterLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchButtonStatus = (status) => {
        if (status !== undefined && status !== '') {
            if (status?.toLowerCase() === 'inprogress') {
                return (
                    <>
                        {fetchLabel("CommonComponent.CommonContinueButton")}
                    </>
                )
            } else if (status?.toLowerCase() === 'completed') {
                return (
                    <>
                        {fetchLabel("CommonComponentCommonRetakeButton")}
                    </>
                )
            } else {
                return (
                    <>
                        {fetchLabel("CommonComponent.CommonStartButton")}
                    </>
                )
            }
        } else {
            return (
                <>
                    {fetchLabel("CommonComponent.CommonStartButton")}
                </>
            )
        }
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            <Container className='pt-lg-4 pt-3 pb-4'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button onClick={(e) => navigate('/' + pageOrgin.id + '/evaluate-a-job-offer')} isSecondary>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchLabel("CommonComponent.CommonExitButton")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            <Container className='assessment-review-result-wrapper assessment-result-wrapper text-start mb-lg-5 mb-5 p-lg-5 p-3'>
                <Row>
                    <Col lg={10} className='pt-5 pl-5 pb-4 m-auto'>
                        <Cirrus.Header size='h4' className='pb-1'>{cmsMasterContent?.assessmentEJO?.Title}</Cirrus.Header>
                    </Col>
                </Row>
                <Row>
                    <Col lg={10} className='pl-5 pt-2 m-auto evaluate_start-page'>
                        <Cirrus.Headline size='Small'>{cmsMasterContent?.assessmentEJO?.SubTitle}</Cirrus.Headline>
                        <p>
                            <Cirrus.RichText content={cmsMasterContent?.assessmentEJO?.Description} />
                        </p>
                        <Cirrus.Button
                            className='mt-3 my-5 w-25'
                            onClick={() => { navigate('/' + pageOrgin.id + '/evaluate-a-job-offer/questions') }}
                        >
                            {fetchButtonStatus(startPageData?.learningResourceStatus)}
                        </Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default StartPage;
