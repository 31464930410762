//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const ErrorActions = {
    GetCMSData: getCMSData
}

function getCMSData(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}
