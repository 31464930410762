//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { AssessmentActions } from "../AssessmentActions";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import "../style.css";
import EJOTemplate from "./pdfTemplate";
import { coachActions } from "../../coach/coachActions";
import { arrIndexOf } from "@microsoft/applicationinsights-core-js";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function ResultPage() {
  const [loadSpinner, setLoadSpinner] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState(false);
  const [cmsIYIPContent, setCmsIYIPContent] = useState();
  const [cmsEJOContent, setEJOContent] = useState();
  const [getMaster, setGetMaster] = useState({});
  const [getrole, setgetrole] = useState();
  const [getResponse, setGetResponse] = useState([]);
  const [getResponseEJO, setGetResponseEJO] = useState([]);
  const [getIYIFData, setgetIYIFData] = useState();
  const [getEJOData, setgetEJOData] = useState();
  const [getAssementData, setgetAssementData] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [docID, setDocID] = useState();
  const [display, setDisplay] = useState(false);
  const [coachdata, setcoachdata] = useState();
  const [pdfRole, setpdfRole] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [iyipOrg, SetiyipOrg] = useState();
  const [ejoOrg, SetejoOrg] = useState();
  const [iyipRole, SetiyipRole] = useState();
  const [ejoRole, SetejoRole] = useState();
  const [iyipCompen, SetiyipCompen] = useState();
  const [ejoCompen, SetejoCompen] = useState();
  const [iyipRoleQ, SetiyipRoleQ] = useState();
  const [ejoRoleQ, SetejoRoleQ] = useState();

  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (/Chrome/.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browser = "Safari";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Microsoft Edge";
  } else {
    browser = "Unknown Browser";
  }

  useEffect(() => {
    fetchCMSData();
    getMyCoach();
    window.scrollTo(0, 0);
  }, [context.language]);

  useEffect(() => {
    renderSavedAssessment();
    renderSavedAssessmentEJO();
    window.scrollTo(0, 0);
  }, [getEJOData, getIYIFData]);

  useEffect(() => {
    if (getAssementData?.learningResourceId && docID != undefined) {
      updateAssessmentStatus();
    }
  }, [docID]);

  const fetchCMSData = async () => {
    setScreenLoader(true);
    setGetMaster(context.cmsEvaluateAJobOffer);

    ActivityAction.SaveActivity(
      context.cmsEvaluateAJobOffer?.assessmentEJO?.ID,
      "completedassesment",
      context.cmsEvaluateAJobOffer?.assessmentEJO?.Title
    );
    let refCmsAssessmentContent = {};
    let resData = context.cmsEvaluateAJobOffer?.assessmentIYIP;
    refCmsAssessmentContent.internalAssessmentId = resData?.ID;
    refCmsAssessmentContent.assessmentQuestionSet = resData?.ChildPages.filter(
      (x) =>
        x.Key !== "Assessments.IdentifyYourIdealPosition.AssessmentComplete"
    );
    let questionsArr = [];

    refCmsAssessmentContent?.assessmentQuestionSet?.map((assessment) => {
      Object.keys(assessment.Components).forEach((key) => {
        if (
          key ===
          "Resources.Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
        ) {
          questionsArr.push({
            ...assessment,
            QuestionList: assessment.Components[key].QuestionList,
          });
        }
        if (
          key ===
          "Resources.Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
        ) {
          questionsArr.push({
            ...assessment,
            QuestionList: assessment.Components[key].QuestionList,
          });
        }
        if (
          key === "Resources.Assessments.IdentifyYourIdealPosition.Compensation"
        ) {
          questionsArr.push({
            ...assessment,
            QuestionList: assessment.Components[key].QuestionList,
          });
        }
      });
    });
    refCmsAssessmentContent.assessmentQuestionSet = questionsArr;
    refCmsAssessmentContent.resultPage = resData?.ChildPages?.find(
      (x) =>
        x.Key === "Assessments.IdentifyYourIdealPosition.AssessmentComplete"
    );
    setCmsIYIPContent(refCmsAssessmentContent);

    let refEJOContent = {};
    let resEJOData = context.cmsEvaluateAJobOffer?.assessmentEJO;

    refEJOContent.internalAssessmentId = resEJOData?.ID;
    refEJOContent.assessmentQuestionSet = resEJOData?.QuestionPages;

    setEJOContent(refEJOContent);

    let IYIFDATA = await AssessmentActions.GetSavedResponseByAssessmentId(
      refCmsAssessmentContent.internalAssessmentId
    );
    setgetIYIFData(IYIFDATA);

    let EJODATA = await AssessmentActions.GetSavedResponseByAssessmentId(
      refEJOContent.internalAssessmentId
    );
    setgetEJOData(EJODATA);

    let role = await AssessmentActions.GetEJOSaveUpdateJobOffer(
      refEJOContent.internalAssessmentId
    );

    role?.data?.companyName
      ? setpdfRole(`${role?.data?.jobRole} at ${role?.data?.companyName}`)
      : setpdfRole(role?.data?.jobRole);

    setgetrole(role);

    let AssStatus = await AssessmentActions.GetAssessmentStatusById(
      refEJOContent.internalAssessmentId
    );

    setgetAssementData(AssStatus.data);
    setIsSaved(AssStatus.data.isSaved);
    setIsLiked(AssStatus.data.isLiked);
  };

  const fetchLabel = (key) => {
    let refCmsMasterContent = getMaster;
    let label = "";
    if (refCmsMasterContent?.masterLabel) {
      refCmsMasterContent?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchResultQuestionTitle = (key) => {
    let refCmsAssessmentContent =
      cmsIYIPContent.resultPage.Components
        .IdentifyIdealPositionAssessmentComplete;
    let label = "";
    if (refCmsAssessmentContent?.Labels) {
      refCmsAssessmentContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const renderSavedAssessment = async (id) => {
    let Responses = getIYIFData;
    let savedResponses = Responses?.data;
    let cmsContent = cmsIYIPContent;
    let arrResponse = [],
      unSortedOrg = [],
      unSortedJob = [],
      unSortedComp = [],
      unSortedQuestion = [],
      unSortedQ = [],
      unSortedQC = [];
    if (savedResponses?.length) {
      savedResponses?.map((savedResponse) => {
        let QuestionId = savedResponse.questionId;
        let AnswerId =
          savedResponse.answerValue != ""
            ? savedResponse.answerValue.split(",")
            : "";
        cmsContent?.assessmentQuestionSet.map((assessment) => {
          assessment.QuestionList.map((question, i) => {
            if (question.QuestionId === QuestionId) {
              if (AnswerId.length) {
                AnswerId.map((answerid) => {
                  question.AnswerList.map((options) => {
                    if (options.AnswerId === answerid) {
                      let quest;
                      //trial//
                      let que;
                      //trial//
                      if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                      ) {
                        unSortedOrg.push({
                          index: i,
                          answertext: options.AnswerText,
                        });
                        let sortedAnswers = unSortedOrg
                          .sort((a, b) => a.index - b.index)
                          .map((option) => option.answertext);
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        );

                        // this is trial
                        unSortedQuestion.push({
                          index: i,
                          questext: question.QuestionTitle,
                        });
                        let sortedQues = unSortedQuestion
                          .sort((a, b) => a.index - b.index)
                          .map((option) => option.questext);
                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        );
                        // till here

                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
                            ),
                            Answer: sortedAnswers,
                            PageId: assessment.Key,
                          });
                        }

                        // This is trial
                        if (que.length) {
                          if (typeof sortedQues != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQues;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
                            ),
                            Questi: sortedQues,
                            PageId: assessment.Key,
                          });
                        }
                        // till here
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                      ) {
                        let sortedAnswers;
                        let sortedQue;
                        if (
                          options.ResultPageTitle !== "" &&
                          options.AnswerText !== "Somewhat" &&
                          options.AnswerText !== "Not at all"
                        ) {
                          unSortedJob.push({
                            index: i,
                            answertext:
                              options.IsQuestionTitleAppended == true
                                ? options.ResultPageTitle +
                                  " " +
                                  question.QuestionTitle
                                : options.ResultPageTitle,
                          });
                          sortedAnswers = unSortedJob
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.answertext);

                          //trail

                          unSortedQ.push({
                            index: i,
                            questext: question.QuestionTitle,
                          });
                          sortedQue = unSortedQ
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.questext);
                          //end
                        }
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        );
                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          if (options.ResultPageTitle !== "") {
                            arrResponse.push({
                              Question: fetchResultQuestionTitle(
                                "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
                              ),
                              Answer: sortedAnswers,
                              PageId: assessment.Key,
                            });
                          }
                        }
                        //trail
                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        );
                        if (que.length) {
                          if (typeof sortedQue != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQue;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
                            ),
                            Questi: sortedQue,
                            PageId: assessment.Key,
                          });
                        }
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.Compensation"
                      ) {
                        let sortedAnswers;
                        let sortedQue;
                        if (
                          options.ResultPageTitle !== "" &&
                          options.ResultPageTitle !== "Not important"
                        ) {
                          unSortedComp.push({
                            index: i,
                            answertext:
                              options.IsQuestionTitleAppended == true
                                ? options.ResultPageTitle +
                                  " " +
                                  question.QuestionTitle
                                : options.ResultPageTitle,
                          });
                          sortedAnswers = unSortedComp
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.answertext);

                          unSortedQC.push({
                            index: i,
                            questext: question.QuestionTitle,
                          });
                          sortedQue = unSortedQC
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.questext);
                        }
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.Compensation"
                        );
                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
                            ),
                            Answer: sortedAnswers,
                            PageId: assessment.Key,
                          });
                        }

                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.Compensation"
                        );
                        if (que.length) {
                          if (typeof sortedQue != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQue;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
                            ),
                            Questi: sortedQue,
                            PageId: assessment.Key,
                          });
                        }
                      }
                    }
                  });
                });
              }
            }
          });
        });
      });
      let tempArrResponse = [];
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId === "Assessments.IdentifyYourIdealPosition.Compensation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.Compensation"
          )
        );
      }
      arrResponse = tempArrResponse;
      setGetResponse(arrResponse);
    }
  };

  const renderSavedAssessmentEJO = async (id) => {
    let Responses = getEJOData;
    let savedResponses = Responses?.data;
    let cmsContent = cmsEJOContent;
    let arrResponse = [],
      unSortedOrg = [],
      unSortedJob = [],
      unSortedComp = [],
      unSortedQuestion = [],
      unSortedQ = [],
      unSortedQC = [];
    if (savedResponses?.length) {
      savedResponses?.map((savedResponse) => {
        let QuestionId = savedResponse.questionId;
        let AnswerId =
          savedResponse.answerValue != ""
            ? savedResponse.answerValue.split(",")
            : "";
        cmsContent?.assessmentQuestionSet.map((assessment) => {
          assessment.questions.map((question, i) => {
            if (question.QuestionId === QuestionId) {
              if (AnswerId.length) {
                AnswerId.map((answerid) => {
                  question.AnswerList.map((options) => {
                    if (options.AnswerId === answerid) {
                      let quest;
                      //trial//
                      let que;
                      //trial//
                      if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                      ) {
                        unSortedOrg.push({
                          index: i,
                          answertext: options.AnswerText,
                        });
                        let sortedAnswers = unSortedOrg
                          .sort((a, b) => a.index - b.index)
                          .map((option) => option.answertext);
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        );
                        // this is trial
                        unSortedQuestion.push({
                          index: i,
                          questext: question.QuestionTitle,
                        });
                        let sortedQues = unSortedQuestion
                          .sort((a, b) => a.index - b.index)
                          .map((option) => option.questext);
                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        );
                        // till here
                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
                            ),
                            Answer: sortedAnswers,
                            PageId: assessment.Key,
                          });
                        }
                        // This is trial
                        if (que.length) {
                          if (typeof sortedQues != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQues;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
                            ),
                            Questi: sortedQues,
                            PageId: assessment.Key,
                          });
                        }
                        // till here
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                      ) {
                        let sortedAnswers;
                        let sortedQue;
                        if (options.AnswerText !== "") {
                          unSortedJob.push({
                            index: i,
                            answertext:
                              options.AnswerText == "Yes"
                                ? question?.QuestionTitle
                                : options.ResultPageTitle,
                          });
                          sortedAnswers = unSortedJob
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.answertext);

                          //trail
                          unSortedQ.push({
                            index: i,
                            questext: question.QuestionTitle,
                          });
                          sortedQue = unSortedQ
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.questext);
                          //end
                        }
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        );
                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          if (options.ResultPageTitle !== "") {
                            arrResponse.push({
                              Question: fetchResultQuestionTitle(
                                "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
                              ),
                              Answer: sortedAnswers,
                              PageId: assessment.Key,
                            });
                          }
                        }

                        //trail
                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        );
                        if (que.length) {
                          if (typeof sortedQue != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQue;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
                            ),
                            Questi: sortedQue,
                            PageId: assessment.Key,
                          });
                        }
                        //end
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.Compensation"
                      ) {
                        let sortedAnswers;
                        let sortedQue;

                        if (options.ResultPageTitle !== "") {
                          unSortedComp.push({
                            index: i,
                            answertext:
                              question.QuestionTitle != "Salary range"
                                ? options.ResultPageTitle +
                                  " " +
                                  question.QuestionTitle
                                : options.ResultPageTitle,
                          });
                          sortedAnswers = unSortedComp
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.answertext);

                          //trial
                          unSortedQC.push({
                            index: i,
                            questext: question.QuestionTitle,
                          });
                          sortedQue = unSortedQC
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.questext);
                          //end
                        }
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.Compensation"
                        );
                        if (quest.length) {
                          if (typeof sortedAnswers != "undefined") {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
                            ),
                            Answer: sortedAnswers,
                            PageId: assessment.Key,
                          });
                        }

                        //trial
                        que = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.Compensation"
                        );
                        if (que.length) {
                          if (typeof sortedQue != "undefined") {
                            let question = [];
                            que[0].Questi = sortedQue;
                            question.push(que[0].Questi);
                          }
                        } else {
                          arrResponse.push({
                            Question: fetchResultQuestionTitle(
                              "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
                            ),
                            Questi: sortedQue,
                            PageId: assessment.Key,
                          });
                        }
                        //end
                      }
                    }
                  });
                });
              }
            }
          });
        });
      });
      let tempArrResponse = [];
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId === "Assessments.IdentifyYourIdealPosition.Compensation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.Compensation"
          )
        );
      }
      arrResponse = tempArrResponse;
      setGetResponseEJO(arrResponse);
      AsseAnswr(getResponse, arrResponse);
      setScreenLoader(false);
    }
  };

  const getAssesmentUpdate = async () => {
    let AssStatus = await AssessmentActions.GetAssessmentStatusById(
      cmsEJOContent.internalAssessmentId
    );
    setUpdatedData(AssStatus.data);
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: getAssementData?.learningResourceId,
                learningResourceStatus: getAssementData.learningResourceStatus,
                learningResourceType:
                  getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
                resourceTypeId: getMaster?.assessmentEJO?.ResourceType?.ID,
                isSaved: getAssementData.isSaved,
                isLiked: getAssementData.isLiked,
                lastAnsweredPageId: getAssementData.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: updatedData?.isSharedWithCoach
                  ? updatedData.isSharedWithCoach
                  : false,
                resultDocumentId: docId,
              };
              AssessmentActions.SaveAssessmentStatus(
                getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
                getAssementData?.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setLoadSpinner(false);
                    getAssesmentUpdate();
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            } else {
              setLoadSpinner(false);
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const updateAssessmentStatus = async () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: getAssementData?.learningResourceId,
      learningResourceStatus: getAssementData?.learningResourceStatus,
      learningResourceType: getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
      resourceTypeId: getMaster?.assessmentEJO?.ResourceType?.ID,
      isSaved: getAssementData?.isSaved,
      isLiked: getAssementData?.isLiked,
      lastAnsweredPageId: getAssementData?.lastAnsweredPageId,
      isDownloaded: getAssementData?.isDownloaded,
      isSharedWithCoach: getAssementData?.isSharedWithCoach,
      resultDocumentId: docID,
    };
    await AssessmentActions.SaveAssessmentStatus(
      getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
      getAssementData?.learningResourceId,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          AssessmentActions.GetAssessmentStatusById(
            getMaster?.assessmentEJO?.ID
          ).then((response) => {
            if (response.status === 200) {
              setgetAssementData(response.data);
              setIsSaved(response.data.isSaved);
              setIsLiked(response.data.isLiked);
            }
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const generatePDF = () => {
    let content = document.getElementById("assessment-result-wrapper");
    setLoadSpinner(true);
    setDisplay(true);

    const datetime = new Date();
    const year = datetime.getFullYear();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hours = datetime.getHours();
    const mins = datetime.getMinutes();
    const sec = datetime.getSeconds();
    const newDatetime =
      year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

    let img = new Image();
    html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        img.src = imgData;

        const pdf = new jsPDF({ orientation: "l", format: "a2", unit: "px" });
        pdf.viewerPreferences(
          { FitWindow: true, PrintArea: "MediaBox", PrintClip: "MediaBox" },
          true
        );
        console.log(browser);
        if (browser == "Safari") {
          let totalPages = Math.ceil(
            canvas.height / pdf.internal.pageSize.height
          );
          for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
              pdf.addPage();
            }
            let pageHeight = pdf.internal.pageSize.height;
            let pageWidth = pdf.internal.pageSize.width;
            let yPosition = -(i * pageHeight);

            // Add the canvas image to the PDF
            pdf.addImage(
              canvas,
              "JPEG",
              20,
              yPosition + 60,
              canvas.width,
              canvas.height
            );

            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
            pdf.setFontSize(18);
            //header background
            pdf.setFillColor(244, 244, 245);
            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

            //header
            const header = `${PDFheaderLogo}`;
            pdf.addImage(header, "png", 20, 20, 60, 20);

            //line above footer
            pdf.setDrawColor(236, 236, 236);
            pdf.line(
              20,
              pdf.internal.pageSize.height - 90,
              pdf.internal.pageSize.width - 20,
              pdf.internal.pageSize.height - 90,
              "S"
            );

            // footer
            const footerLeft = `${PDFfooterLogo}`;
            const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`;
            pdf.text(
              footerRight,
              pdf.internal.pageSize.width - 300,
              pdf.internal.pageSize.height - 50
            );
            pdf.addImage(
              footerLeft,
              "png",
              20,
              pdf.internal.pageSize.height - 80,
              160,
              45
            );
          }
          let file = new File(
            [pdf.output("blob")],
            context.cmsEvaluateAJobOffer?.assessmentEJO?.Title +
              "-" +
              newDatetime.toLocaleString() +
              ".pdf",
            { type: "application/pdf" }
          );

          let formData = new FormData();
          formData.append(file.name, file);
          let candidateID =
            context?.idTokenClaims?.signupId != undefined
              ? context?.idTokenClaims?.signupId
              : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
                  "signupId"
                ];
          formData.append("OwnerId", candidateID);
          formData.append("OwnerType", "Candidate");

          MyDocumentsActions.UploadDocuments(formData).then(
            (res) => {
              let docId;
              res.data.map((doc) => {
                docId = doc.id;
              });
              setDocID(docId);

              context.setGeneratePdfFlag(false);
              setDisplay(false);
              setLoadSpinner(false);
            },
            (err) => {
              console.log(err);
              setDisplay(false);
              setLoadSpinner(false);
            }
          );
        } else {
          pdf.html(img, {
            margin: [100, 10, 120, 10],
            callback: (pdf) => {
              let pageCount = pdf.internal.getNumberOfPages();

              for (let i = 0; i < pageCount; i++) {
                pdf.setPage(i);

                let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                pdf.setFontSize(18);

                //header background
                pdf.setFillColor(244, 244, 245);
                pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

                //header
                const header = `${PDFheaderLogo}`;
                pdf.addImage(header, "png", 20, 20, 60, 20);

                //line above footer
                pdf.setDrawColor(236, 236, 236);
                pdf.line(
                  20,
                  pdf.internal.pageSize.height - 90,
                  pdf.internal.pageSize.width - 20,
                  pdf.internal.pageSize.height - 90,
                  "S"
                );
                // footer
                const footerLeft = `${PDFfooterLogo}`;
                const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`;
                pdf.text(
                  footerRight,
                  pdf.internal.pageSize.width - 300,
                  pdf.internal.pageSize.height - 50
                );
                pdf.addImage(
                  footerLeft,
                  "png",
                  20,
                  pdf.internal.pageSize.height - 80,
                  160,
                  45
                );
              }

              let file = new File(
                [pdf.output("blob")],
                context.cmsEvaluateAJobOffer?.assessmentEJO?.Title +
                  "-" +
                  newDatetime.toLocaleString() +
                  ".pdf",
                { type: "application/pdf" }
              );

              let formData = new FormData();
              formData.append(file.name, file);
              let candidateID =
                context?.idTokenClaims?.signupId != undefined
                  ? context?.idTokenClaims?.signupId
                  : JSON.parse(
                      window.sessionStorage.getItem("setIdTokenClaims")
                    )["signupId"];
              formData.append("OwnerId", candidateID);
              formData.append("OwnerType", "Candidate");

              MyDocumentsActions.UploadDocuments(formData).then(
                (res) => {
                  let docId;
                  res.data.map((doc) => {
                    docId = doc.id;
                  });
                  setDocID(docId);

                  context.setGeneratePdfFlag(false);
                  setDisplay(false);
                  setLoadSpinner(false);
                },
                (err) => {
                  console.log(err);
                  setDisplay(false);
                  setLoadSpinner(false);
                }
              );
            },
          });
        }
      }
    );
  };

  const shareResultWithCoach = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: context.cmsEvaluateAJobOffer?.assessmentEJO?.Title,
      assessmentId: getMaster?.assessmentEJO?.ID,
      documentId: docId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: getAssementData?.learningResourceId,
            learningResourceStatus: getAssementData?.learningResourceStatus,
            learningResourceType:
              getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
            resourceTypeId: getMaster?.assessmentEJO?.ResourceType?.ID,
            isSaved: getAssementData?.isSaved,
            isLiked: getAssementData?.isLiked,
            lastAnsweredPageId: getAssementData?.lastAnsweredPageId,
            isSharedWithCoach: true,
            isDownloaded: updatedData?.isDownloaded
              ? updatedData?.isDownloaded
              : false,
            resultDocumentId: docId,
          };
          AssessmentActions.SaveAssessmentStatus(
            getMaster?.assessmentEJO?.ResourceCategoryType?.ID,
            getAssementData?.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                getAssesmentUpdate();
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchLabel("CommonComponent.CommonClose"),
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  useEffect(() => {
    if (
      getResponseEJO[0]?.Answer?.length > 1 &&
      screenLoader === false &&
      pdfRole != undefined
    ) {
      if (context.generatePdfFlag) {
        setTimeout(() => {
          generatePDF();
        }, 500);
      }
    }
  }, [screenLoader, pdfRole]);

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  const retakeHandler = async () => {
    await AssessmentActions.EJORetakeAssessment(
      cmsEJOContent?.internalAssessmentId
    ).then(
      (res) => {
        if (res.status === 200) {
          navigate("/" + pageOrgin.id + "/evaluate-a-job-offer/start");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const AsseAnswr = (IYIP, EJO) => {
    let org = [];
    let orgIYIP = [];
    let role = [];
    let roleIYIP = [];
    let roleQ = [];
    let roleIYIPQ = [];
    let compen = [];
    let compenIYIP = [];

    if (IYIP[0]?.Questi?.length > EJO[0]?.Questi?.length) {
      IYIP[0]?.Answer?.map((val, index) => {
        if (EJO[0]?.Answer?.includes(val)) {
          let indexE = EJO[0]?.Answer?.indexOf(val);
          if (IYIP[0]?.Answer[index] == EJO[0]?.Answer[indexE]) {
            orgIYIP.push(IYIP[0]?.Answer[index]);
            org.push(EJO[0]?.Answer[indexE]);
          }
        }
      });
      if (
        IYIP[0]?.Question ==
        fetchResultQuestionTitle(
          "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
        )
      ) {
        orgIYIP.push(...IYIP[0]?.Answer);
        SetiyipOrg([...new Set(orgIYIP)], "IYIPOrg");
      } else {
        SetiyipOrg([], "IYIPOrg");
      }

      if (
        EJO[0]?.Question ==
        fetchResultQuestionTitle(
          "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
        )
      ) {
        org.push(...EJO[0]?.Answer);
        SetejoOrg([...new Set(org)], "EJOOrg");
      } else {
        SetejoOrg([], "EJOOrg");
      }
    } else {
      EJO[0]?.Answer?.map((val, index) => {
        if (IYIP[0]?.Answer?.includes(val)) {
          let indexE = IYIP[0]?.Answer?.indexOf(val);
          if (EJO[0]?.Answer[index] == IYIP[0]?.Answer[indexE]) {
            orgIYIP.push(IYIP[0]?.Answer[indexE]);
            org.push(EJO[0]?.Answer[index]);
          }
        }
      });
      if (
        IYIP[0]?.Question ==
        fetchResultQuestionTitle(
          "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
        )
      ) {
        orgIYIP.push(...IYIP[0]?.Answer);
        SetiyipOrg([...new Set(orgIYIP)], "IYIPOrg");
      } else {
        SetiyipOrg([], "IYIPOrg");
      }

      if (
        EJO[0]?.Question ==
        fetchResultQuestionTitle(
          "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteAboutTheOrganisation"
        )
      ) {
        org.push(...EJO[0]?.Answer);
        SetejoOrg([...new Set(org)], "EJOOrg");
      } else {
        SetejoOrg([], "EJOOrg");
      }
    }

    if (IYIP[1]?.Questi?.length > EJO[1]?.Questi?.length) {
      IYIP[1]?.Questi?.map((val, index) => {
        if (EJO[1] ? EJO[1] : EJO[0]?.Questi?.includes(val)) {
          let indexE = EJO[1] ? EJO[1] : EJO[0]?.Questi?.indexOf(val);
          if (IYIP[1]?.Questi[index] == EJO[1]?.Questi[indexE]) {
            roleIYIP.push(IYIP[1]?.Answer[index]);
            role.push(EJO[1]?.Answer[indexE]);

            roleIYIPQ.push(IYIP[1]?.Questi[index]);
            roleQ.push(EJO[1] ? EJO[1] : EJO[0]?.Questi[indexE]);
          }
        }
      });

      if (
        IYIP[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          ) ||
        IYIP[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          )
      ) {
        IYIP[1]
          ? roleIYIP.push(...IYIP[1]?.Answer)
          : roleIYIP.push(...IYIP[0]?.Answer);
        IYIP[1]
          ? roleIYIPQ.push(...IYIP[1]?.Questi)
          : roleIYIPQ.push(...IYIP[0]?.Questi);
        SetiyipRole([...new Set(roleIYIP)], "IYIPOrg");
        SetiyipRoleQ([...new Set(roleIYIPQ)], "IYIPOrg");
      } else {
        SetiyipRole([], "IYIPOrg");
        SetiyipRoleQ([], "IYIPOrg");
      }

      if (
        EJO[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          ) ||
        EJO[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          )
      ) {
        IYIP[1]
          ? roleIYIP.push(...IYIP[1]?.Answer)
          : roleIYIP.push(...IYIP[0]?.Answer);
        EJO[1] ? role.push(...EJO[1]?.Answer) : role.push(...EJO[0]?.Answer);
        IYIP[1]
          ? roleIYIPQ.push(...IYIP[1]?.Questi)
          : roleIYIPQ.push(...IYIP[0]?.Questi);
        EJO[1] ? roleQ.push(...EJO[1]?.Questi) : roleQ.push(...EJO[0]?.Questi);
        SetejoRole([...new Set(role)], "EJOOrg");
        SetejoRoleQ([...new Set(roleQ)], "EJOOrg");
      } else {
        SetejoRole([], "EJOOrg");
        SetejoRoleQ([], "EJOOrg");
      }
    } else {
      EJO[1]?.Questi?.map((val, index) => {
        if (IYIP[1] ? IYIP[1] : IYIP[0]?.Questi?.includes(val)) {
          let indexE = IYIP[1] ? IYIP[1] : IYIP[0]?.Questi?.indexOf(val);
          if (
            EJO[1]?.Questi[index] == IYIP[1] ? IYIP[1] : IYIP[0]?.Questi[indexE]
          ) {
            roleIYIP.push(IYIP[1] ? IYIP[1] : IYIP[0]?.Answer[indexE]);
            role.push(EJO[1]?.Answer[index]);

            roleIYIPQ.push(IYIP[1] ? IYIP[1] : IYIP[0]?.Questi[indexE]);
            roleQ.push(EJO[1]?.Questi[index]);
          }
        }
      });

      if (
        IYIP[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          ) ||
        IYIP[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          )
      ) {
        IYIP[1]
          ? roleIYIP.push(...IYIP[1]?.Answer)
          : roleIYIP.push(...IYIP[0]?.Answer);
        IYIP[1]
          ? roleIYIPQ.push(...IYIP[1]?.Questi)
          : roleIYIPQ.push(...IYIP[0]?.Questi);
        SetiyipRole([...new Set(roleIYIP)], "IYIPOrg");
        SetiyipRoleQ([...new Set(roleIYIPQ)], "IYIPOrg");
      } else {
        SetiyipRole([], "IYIPOrg");
        SetiyipRoleQ([], "IYIPOrg");
      }

      if (
        EJO[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          ) ||
        EJO[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"
          )
      ) {
        IYIP[1]
          ? roleIYIP.push(...IYIP[1]?.Answer)
          : roleIYIP.push(...IYIP[0]?.Answer);
        EJO[1] ? role.push(...EJO[1]?.Answer) : role.push(...EJO[0]?.Answer);
        IYIP[1]
          ? roleIYIPQ.push(...IYIP[1]?.Questi)
          : roleIYIPQ.push(...IYIP[0]?.Questi);
        EJO[1] ? roleQ.push(...EJO[1]?.Questi) : roleQ.push(...EJO[0]?.Questi);
        SetejoRole([...new Set(role)], "EJOOrg");
        SetejoRoleQ([...new Set(roleQ)], "EJOOrg");
      } else {
        SetejoRole([], "EJOOrg");
        SetejoRoleQ([], "EJOOrg");
      }
    }

    if (IYIP[2]?.Questi?.length > EJO[2]?.Questi?.length) {
      IYIP[2]?.Questi?.map((val, index) => {
        if (EJO[2]?.Questi?.includes(val)) {
          let indexE = EJO[2]?.Questi?.indexOf(val);
          if (IYIP[2]?.Questi[index] == EJO[2]?.Questi[indexE]) {
            compenIYIP.push(IYIP[2]?.Answer[index]);
            compen.push(EJO[2]?.Answer[indexE]);
          }
        }
      });

      if (
        IYIP[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        IYIP[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        IYIP[2]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          )
      ) {
        IYIP[2]
          ? compenIYIP.push(...IYIP[2]?.Answer)
          : IYIP[1]
          ? compenIYIP.push(...IYIP[1]?.Answer)
          : compenIYIP.push(...IYIP[0]?.Answer);
        SetiyipCompen([...new Set(compenIYIP)], "IYIPcom");
      } else {
        SetiyipCompen([], "IYIPcom");
      }

      if (
        EJO[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        EJO[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        EJO[2]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          )
      ) {
        EJO[2]
          ? compen.push(...EJO[2]?.Answer)
          : EJO[1]
          ? compen.push(...EJO[1]?.Answer)
          : compen.push(...EJO[0]?.Answer);
        SetejoCompen([...new Set(compen)], "EJOcom");
      } else {
        SetejoCompen([], "EJOcom");
      }
    } else {
      EJO[2]?.Questi?.map((val, index) => {
        if (IYIP[2]?.Questi?.includes(val)) {
          let indexE = IYIP[2]?.Questi?.indexOf(val);
          if (EJO[2]?.Questi[index] == IYIP[2]?.Questi[indexE]) {
            compenIYIP.push(IYIP[2]?.Answer[indexE]);
            compen.push(EJO[2]?.Answer[index]);
          }
        }
      });

      if (
        IYIP[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        IYIP[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        IYIP[2]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          )
      ) {
        IYIP[2]
          ? compenIYIP.push(...IYIP[2]?.Answer)
          : IYIP[1]
          ? compenIYIP.push(...IYIP[1]?.Answer)
          : compenIYIP.push(...IYIP[0]?.Answer);
        SetiyipCompen([...new Set(compenIYIP)], "IYIPcom");
      } else {
        SetiyipCompen([], "IYIPcom");
      }

      if (
        EJO[0]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        EJO[1]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          ) ||
        EJO[2]?.Question ==
          fetchResultQuestionTitle(
            "IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"
          )
      ) {
        EJO[2]
          ? compen.push(...EJO[2]?.Answer)
          : EJO[1]
          ? compen.push(...EJO[1]?.Answer)
          : compen.push(...EJO[0]?.Answer);
        SetejoCompen([...new Set(compen)], "EJOcom");
      } else {
        SetejoCompen([], "EJOcom");
      }
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {/* <Cirrus.Page
        className="assessment-page"
        background="secondary"
        backgroundShape="singleDesktop"
        header={<HeaderWithNavigation />}
        removeFooterPadding
      > */}
      <div className="assessment-page ejo_result_page">
        <Container className="pt-lg-4 pt-3 pb-lg-4 pb-3">
          <Row className="justify-content-between">
            <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
              <Cirrus.Button
                isSecondary
                onClick={() => {
                  navigate("/" + pageOrgin.id + "/evaluate-a-job-offer");
                }}
              >
                {fetchLabel("CommonComponent.CommonBackButton")}
              </Cirrus.Button>
            </Col>
            <Col
              sm={12}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xs={{ order: 3 }}
              className="d-flex"
            >
              <div className="progress-wrapper">
                {/* <Cirrus.Label className="progress-label">
                  Progress 4%
                </Cirrus.Label>
                <Cirrus.ProgressBar progress="4"></Cirrus.ProgressBar> */}
              </div>
            </Col>
            <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
              <Cirrus.Button
                onClick={() => {
                  navigate("/" + pageOrgin.id);
                }}
              >
                {fetchLabel("CommonComponent.CommonExitButton")}
              </Cirrus.Button>
            </Col>
          </Row>
        </Container>
        <Container className="assessment-review-result-wrapper assessment-result-wrapper p-lg-5 p-3 mb-lg-5 mb-3">
          {loadSpinner && (
            <div className="loader">
              <Cirrus.Spinner size={50} />
            </div>
          )}
          <Row className="mb-lg-5 mb-4 pb-3 border-bottom">
            <Col lg={5}>
              <Cirrus.Header
                size="h4"
                className="mb-3 text-lg-start text-md-start text-center"
              >
                {context.cmsEvaluateAJobOffer?.assessmentComplete?.Title}
              </Cirrus.Header>
            </Col>
            <Col lg={7} className="download-share-retake">
              <Cirrus.Button
                isSecondary
                className="me-2"
                disabled={coachdata ? false : true}
                onClick={() =>
                  shareResultWithCoach(getAssementData?.resultDocumentId)
                }
              >
                {fetchLabel("CommonResourceComponent.ShareResultsWithCoach")}
              </Cirrus.Button>
              <Cirrus.Button
                isSecondary
                className="me-2"
                onClick={() =>
                  downloadDocument(getAssementData?.resultDocumentId)
                }
              >
                {fetchLabel("CommonResourceComponent.DownloadAsPDF")}
              </Cirrus.Button>
              <Cirrus.Button isSecondary onClick={retakeHandler}>
                {fetchLabel("CommonComponentCommonRetakeButton")}
              </Cirrus.Button>
            </Col>
          </Row>
          <Row className="resultIntro">
            <p>
              {context.cmsEvaluateAJobOffer?.assessmentComplete?.Description}
            </p>
          </Row>
          <div className="resultHead">
            <Row className="JobRoleHeading">
              <Col lg={6}>
                <p className="ResultResponsHide">
                  {fetchLabel(
                    "Assessments.EvaluateAJobOffer.CommonLabels.YourIdealPosition"
                  )}
                </p>
              </Col>
              <Col lg={6}>
                <p className="ResultResponsHide">
                  {getrole?.data?.companyName
                    ? `${getrole?.data?.jobRole} at ${getrole?.data?.companyName}`
                    : getrole?.data?.jobRole}
                </p>
              </Col>
            </Row>
            <div className="resultBack">
              <Row>
                <Col lg={6} className="JobRoleHeaderUncolored lineB">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p className="Label">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.AboutTheOrganisation"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="resultBackSm">
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.AboutTheOrganisation"
                        ).toUpperCase()}
                      </p>
                      <p className="ResultResponseShow">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.YourIdealPosition"
                        )}
                      </p>

                      {iyipOrg?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              getResponseEJO[0]?.Answer?.includes(answer)
                                ? "highlgt"
                                : "check"
                            }`}
                          >
                            <p
                              className={
                                answer == "Not Available"
                                  ? "borderlessWH"
                                  : null
                              }
                              key={index}
                            >
                              {answer}
                            </p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="JobRoleHeader lineB">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p>
                        {getrole?.data?.companyName
                          ? `${getrole?.data?.jobRole} at ${getrole?.data?.companyName}`
                          : getrole?.data?.jobRole}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.AboutTheOrganisation"
                        ).toUpperCase()}
                      </p>

                      {ejoOrg?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              getResponse[0]?.Answer?.includes(answer)
                                ? "highlgt"
                                : "checkWh"
                            }`}
                          >
                            <p
                              className={
                                answer == "Not Available" ? "borderless" : null
                              }
                              key={index}
                            >
                              {answer}
                            </p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="JobRoleHeaderUncolored lineB">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p className="Label">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealJobRole"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="resultBackSm">
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealJobRole"
                        ).toUpperCase()}
                      </p>
                      <p className="ResultResponseShow">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.YourIdealPosition"
                        )}
                      </p>
                      {iyipRole?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              iyipRoleQ[index] == ejoRoleQ[index]
                                ? "highlgt"
                                : "check"
                            }`}
                          >
                            <p key={index}>{answer}</p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="JobRoleHeader lineB">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p>
                        {getrole?.data?.companyName
                          ? `${getrole?.data?.jobRole} at ${getrole?.data?.companyName}`
                          : getrole?.data?.jobRole}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealJobRole"
                        ).toUpperCase()}
                      </p>
                      {ejoRole?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              iyipRoleQ[index] == ejoRoleQ[index]
                                ? "highlgt"
                                : "checkWh"
                            }`}
                          >
                            <p key={index}>{answer}</p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="JobRoleHeaderUncolored ">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p className="Label">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealCompensation"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="resultBackSm">
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealCompensation"
                        ).toUpperCase()}
                      </p>
                      <p className="ResultResponseShow">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.YourIdealPosition"
                        )}
                      </p>
                      {iyipCompen?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              getResponseEJO[2]?.Answer?.includes(answer)
                                ? "highlgt"
                                : "check"
                            }`}
                          >
                            <p key={index}>{answer}</p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="JobRoleHeader">
                  <Row>
                    <Col className="ResultResponseShow">
                      <p>
                        {getrole?.data?.companyName
                          ? `${getrole?.data?.jobRole} at ${getrole?.data?.companyName}`
                          : getrole?.data?.jobRole}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ejo_result-page">
                      <p className="ResultResponsHide HiddenResponse">
                        {fetchLabel(
                          "Assessments.EvaluateAJobOffer.CommonLabels.IdealCompensation"
                        ).toUpperCase()}
                      </p>
                      {ejoCompen?.map((answer, index) => {
                        return (
                          <span
                            className={`Resultpointer ${
                              getResponse[2]?.Answer?.includes(answer)
                                ? "highlgt"
                                : "checkWh"
                            }`}
                          >
                            <p key={index}>{answer}</p>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      {/* </Cirrus.Page>
      <Footer isLoggedIn={true} className="login-footer" /> */}
      <div style={{ opacity: "0" }}>
        <div
          id="assessment-result-wrapper"
          className="assessment-review-result-wrapper"
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            zIndex: "-1",
            display: "block",
            width: "1300px",
          }}
        >
          {display ? (
            <EJOTemplate
              getResponse={getResponse}
              getResponseEJO={getResponseEJO}
              fetchLabel={fetchLabel}
              getrole={pdfRole}
              iyipOrg={iyipOrg}
              ejoOrg={ejoOrg}
              iyipRole={iyipRole}
              ejoRole={ejoRole}
              iyipCompen={iyipCompen}
              ejoCompen={ejoCompen}
              iyipRoleQ={iyipRoleQ}
              ejoRoleQ={ejoRoleQ}
            >
              {" "}
            </EJOTemplate>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ResultPage;
