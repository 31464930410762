//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';

function QuestionsPage() {
    let featuresConfig = JSON.parse(window.sessionStorage.getItem("featuresConfig"))
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [assessmentSetspinner, setAssessmentSetspinner] = useState(false);

    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [masterFlowWithQuestionAnswer, setMasterFlowWithQuestionAnswer] = useState([]);

    const [masterAppliedJob, setMasterAppliedJob] = useState([]);
    const [appliedJobLists, setAppliedJobLists] = useState([]);
    const [selectedAppliedJob, setSelectedAppliedJob] = useState({});
    const [responseJobOfferAndAppliedJob, setResponseJobOfferAndAppliedJob] = useState({});
    const [jobNotFoundModal, setJobNotFoundModal] = useState(false);
    const [jobDescriptionModal, setJobDescriptionModal] = useState(false);
    const [dropDownDefaultValue, setDropDownDefaultValue] = useState();

    const [offerForm, setOfferForm] = useState({
        title: {
            name: 'title',
            value: '',
            errorMsg: '',
            state: ''
        },
        description: {
            name: 'description',
            value: '',
            errorMsg: '',
            state: ''
        },
        continueBtnStatus: true
    })

    const [assessmentStatus, setAssessmentStatus] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [lastAnsweredPage, setLastAnsweredPage] = useState();
    const [percentage, setPercentage] = useState(0);
    const [selectedStep, setSelectedStep] = useState({
        'SelectAnAppliedJob': false,
        'AboutTheOffer': false,
    });


    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        setScreenLoader(true)
        let resData = context.cmsEvaluateAJobOffer;
        if (Object.keys(resData).length !== 0) {
            setCmsMasterContent(resData)
            let mappedSetOfData = [];
            let fatchSaveJobList = false;
            let jobOfferAndAppliedJobResponse = {}

            await AssessmentActions.GetEJOSaveUpdateJobOffer(resData?.assessmentEJO?.ID).then((resp) => {
                if (resp.status === 200) {
                    if (resp.data.isFromAppliedJob === true) {
                        fatchSaveJobList = true
                        resData?.assessmentEJO?.AboutTheRole?.OptionList?.map((roleStatus) => {
                            if (roleStatus?.Key === "Assessments.EvaluateAJobOffer.AboutTheRole.SelectAnAppliedJobFrom") {
                                roleStatus.IsSelected = true;
                            } else {
                                roleStatus.IsSelected = false;
                            }
                            return roleStatus;
                        })
                        mappedSetOfData = [...[resData?.assessmentEJO?.AboutTheRole, resData?.assessmentEJO?.SelectAnAppliedJob], ...resData?.assessmentEJO?.QuestionPages];
                        setSelectedStep({
                            "SelectAnAppliedJob": true,
                            "AboutTheOffer": false
                        })
                        setScreenLoader(false)
                    } else {
                        let refOfferForm = JSON.parse(JSON.stringify(offerForm));
                        resData?.assessmentEJO?.AboutTheRole?.OptionList?.map((roleStatus) => {
                            if (roleStatus?.Key === "Assessments.EvaluateAJobOffer.AboutTheRole.AboutTheOfferReceived") {
                                roleStatus.IsSelected = true;
                            } else {
                                roleStatus.IsSelected = false;
                            }
                            return roleStatus;
                        })
                        mappedSetOfData = [...[resData?.assessmentEJO?.AboutTheRole, resData?.assessmentEJO?.AboutTheOffer], ...resData?.assessmentEJO?.QuestionPages]
                        setSelectedStep({
                            "SelectAnAppliedJob": false,
                            "AboutTheOffer": true
                        })

                        refOfferForm.title.value = resp?.data?.jobRole;
                        refOfferForm.description.value = resp?.data?.jobDescription;
                        refOfferForm.continueBtnStatus = false;
                        setOfferForm(refOfferForm)
                    }
                    setResponseJobOfferAndAppliedJob(resp.data)
                    jobOfferAndAppliedJobResponse = resp.data;
                    setScreenLoader(false)
                } else {
                    mappedSetOfData = [...[resData?.assessmentEJO?.AboutTheRole]]
                    setScreenLoader(false)
                }
            }, err => {
                console.log(err);
                setScreenLoader(false)
            })

            await AssessmentActions.GetAssessmentStatusAndSavedResponseById(resData?.assessmentEJO?.ID).then(res => {
                if (res.status === 200) {
                    setAssessmentStatus(res.data);
                    setLastAnsweredPage(parseInt(res?.data?.lastAnsweredPageId))
                    let savedResponses = res.data.learningResourceAssessmentResponseModels;
                    if (savedResponses.length) {
                        savedResponses.map(savedResponse => {
                            let QuestionId = savedResponse.questionId;
                            let AnswerId = savedResponse.answerValue != "" ? savedResponse.answerValue.split(",") : "";

                            mappedSetOfData?.map(assessment => {
                                assessment?.questions?.map(question => {
                                    if (question.QuestionId === QuestionId) {
                                        if (AnswerId.length) {
                                            AnswerId.map(answerid => {
                                                question.AnswerList.map(options => {
                                                    if (options.AnswerId === answerid) {
                                                        if (question.OptionType !== "Dropdown") {
                                                            options.IsSelected = true;
                                                        }
                                                        else {
                                                            setDropDownDefaultValue(answerid);
                                                        }
                                                    }
                                                })
                                            })
                                        }
                                    }
                                })
                            })
                        })
                        setMasterFlowWithQuestionAnswer(mappedSetOfData)
                        setScreenLoader(false)
                    } else {
                        setMasterFlowWithQuestionAnswer(mappedSetOfData)
                        setScreenLoader(false)
                    }
                    let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                    if (res?.data?.lastAnsweredPageId) {
                        if (parseInt(res?.data?.lastAnsweredPageId) < assessmentQuestion?.length) {
                            setCurrentQuestion(currentQuestion + parseInt(res?.data?.lastAnsweredPageId));
                            assessmentQuestion[parseInt(res?.data?.lastAnsweredPageId)].style.display = "block";
                            let percentageCalc = Math.ceil((parseInt(res?.data?.lastAnsweredPageId) / assessmentQuestion?.length) * 100);
                            setPercentage(percentageCalc);
                        } else {
                            setCurrentQuestion(parseInt(res?.data?.lastAnsweredPageId) - 1);
                            assessmentQuestion[parseInt(res?.data?.lastAnsweredPageId) - 1].style.display = "block";
                            let percentageCalc = Math.ceil((parseInt(res?.data?.lastAnsweredPageId) / assessmentQuestion?.length) * 100);
                            setPercentage(percentageCalc);
                        }
                    } else {
                        assessmentQuestion[currentQuestion].style.display = "flex";
                    }
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            });

            if (fatchSaveJobList) {
                await AssessmentActions.GetEJOAllSavedJob().then((resp) => {
                    if (resp.status === 200) {
                        let shortData = sortByNewest(resp?.data)
                        shortData?.map((jobList) => {
                            if (jobOfferAndAppliedJobResponse?.jobId === jobList?.jobId) {
                                jobList["IsSelected"] = true;
                                setSelectedAppliedJob(jobList)
                            }
                            return jobList;
                        })

                        setMasterAppliedJob(shortData)
                        let dataSet = shortData?.length > 0 ? shortData?.slice(0, 5) : [];
                        setAppliedJobLists(shortData?.length > 0 ? dataSet : [])
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false);
                })
            }



        }
    }

    const aboutTheRoleHandler = async (item) => {
        setLoadSpinner(true)
        let getJobList = [];
        let NoJobModal = false;
        if (item.Key === "Assessments.EvaluateAJobOffer.AboutTheRole.SelectAnAppliedJobFrom") {
            await AssessmentActions.GetEJOAllSavedJob().then((resp) => {
                if (resp.status === 200) {
                    getJobList = sortByNewest(resp?.data)
                } else {
                    setLoadSpinner(false)
                    setJobNotFoundModal(true)
                    NoJobModal = true
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        }

        if (getJobList.length > 0 && item.Key === "Assessments.EvaluateAJobOffer.AboutTheRole.SelectAnAppliedJobFrom") {
            let refOfferForm = JSON.parse(JSON.stringify(offerForm));

            if (selectedStep.SelectAnAppliedJob === false) {
                setMasterFlowWithQuestionAnswer([...[cmsMasterContent?.assessmentEJO?.AboutTheRole, cmsMasterContent?.assessmentEJO?.SelectAnAppliedJob], ...cmsMasterContent?.assessmentEJO?.QuestionPages])
                refOfferForm.title.value = '';
                refOfferForm.description.value = '';
                setOfferForm(refOfferForm)
            }
            setSelectedStep({
                "SelectAnAppliedJob": true,
                "AboutTheOffer": false
            })

            let returnValur = getJobList?.map((jobList) => {
                if (responseJobOfferAndAppliedJob?.jobId === jobList?.jobId) {
                    jobList["IsSelected"] = true;
                    setSelectedAppliedJob(jobList)
                }
                return jobList;
            })

            let percentageCalc = Math.ceil((1 / 5) * 100);
            if (percentageCalc >= percentage) {
                setPercentage(percentageCalc);
            }

            setMasterAppliedJob(returnValur)
            let dataSet = returnValur?.length > 0 ? returnValur?.slice(0, 5) : [];
            setAppliedJobLists(returnValur?.length > 0 ? dataSet : [])

            setLoadSpinner(false)
            nextPrev(1, 'noStatusCall')

        } else {
            if (!NoJobModal) {
                if (selectedStep.AboutTheOffer === false) {
                    setMasterFlowWithQuestionAnswer([...[cmsMasterContent?.assessmentEJO?.AboutTheRole, cmsMasterContent?.assessmentEJO?.AboutTheOffer], ...cmsMasterContent?.assessmentEJO?.QuestionPages])
                    let dataSet = getJobList?.length > 0 ? getJobList?.slice(0, 5) : [];
                    setAppliedJobLists(getJobList?.length > 0 ? dataSet : [])
                }
                setSelectedStep({
                    "SelectAnAppliedJob": false,
                    "AboutTheOffer": true
                })

                setSelectedAppliedJob({})
                let percentageCalc = Math.ceil((1 / 5) * 100);
                if (percentageCalc >= percentage) {
                    setPercentage(percentageCalc);
                }

                setTimeout(() => {
                    setLoadSpinner(false)
                    nextPrev(1, 'noStatusCall')
                }, 1000);
            }
        }
    }

    const noJobRedirection = () => {
        setJobNotFoundModal(false)
        setLoadSpinner(true)
        setSelectedAppliedJob({})
        let percentageCalc = Math.ceil((1 / 5) * 100);
        if (percentageCalc >= percentage) {
            setPercentage(percentageCalc);
        }

        setMasterFlowWithQuestionAnswer([...[cmsMasterContent?.assessmentEJO?.AboutTheRole, cmsMasterContent?.assessmentEJO?.AboutTheOffer], ...cmsMasterContent?.assessmentEJO?.QuestionPages])
        setTimeout(() => {
            setLoadSpinner(false)
        }, 500);
        setTimeout(() => {
            nextPrev(1, 'noStatusCall')
        }, 600);
    }

    const selectAnAppliedJobSubmitHandler = async () => {
        setAssessmentSetspinner(true)
        let payload = {
            "JobRole": selectedAppliedJob?.jobTitle,
            "CompanyName": selectedAppliedJob?.companyName,
            "JobId": selectedAppliedJob?.jobId,
            "JobDescription": "",
            "IsFromAppliedJob": "true"
        }

        await AssessmentActions.PostEJOSaveUpdateJobOffer(cmsMasterContent?.assessmentEJO?.ID, payload).then((resp) => {
            if (resp.status === 200) {


                AssessmentActions.GetEJOSaveUpdateJobOffer(cmsMasterContent?.assessmentEJO?.ID).then((res) => {
                    if (res.status === 200) {
                        setResponseJobOfferAndAppliedJob(res.data)
                    } else {
                        setScreenLoader(false)
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false)
                })



                nextPrev(1, 'statusCall')
            } else {
                setAssessmentSetspinner(false);
            }
        }, err => {
            console.log(err);
            setAssessmentSetspinner(false);
        })
    }

    const aboutTheOfferFormHandler = (event) => {
        let refOfferForm = JSON.parse(JSON.stringify(offerForm));
        let { name, value } = event.target;
        if (name === refOfferForm.title.name) {
            if (value.length >= 3) {
                refOfferForm.title.value = value;
                refOfferForm.continueBtnStatus = false;
                setOfferForm(refOfferForm)
            } else {
                refOfferForm.title.value = value;
                refOfferForm.continueBtnStatus = true;
                setOfferForm(refOfferForm)
            }
        }
        if (name === refOfferForm.description.name) {
            refOfferForm.description.value = value;
            setOfferForm(refOfferForm)
        }
    }

    const aboutTheOfferSubmitHandler = async () => {
        setAssessmentSetspinner(true)
        let payload = {
            "JobRole": offerForm.title.value,
            "CompanyName": "",
            "JobId": "",
            "JobDescription": offerForm.description.value,
            "IsFromAppliedJob": "false"
        }

        await AssessmentActions.PostEJOSaveUpdateJobOffer(cmsMasterContent?.assessmentEJO?.ID, payload).then((resp) => {
            if (resp.status === 200) {

                AssessmentActions.GetEJOSaveUpdateJobOffer(cmsMasterContent?.assessmentEJO?.ID).then((res) => {
                    if (res.status === 200) {
                        setResponseJobOfferAndAppliedJob(res.data)
                    } else {
                        setScreenLoader(false)
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false)
                })

                nextPrev(1, 'statusCall')
            } else {
                setAssessmentSetspinner(false)
            }
        }, err => {
            console.log(err);
            setAssessmentSetspinner(false)
        })
    }

    const saveAnswers = async (questionId, answersId, sectionTitle, sectionID, AnswerType) => {
        let payload = [{
            "AssessementId": cmsMasterContent.assessmentEJO.ID,
            "AssessementType": "Internal",
            "CategoryType": sectionID,
            "CategoryValue": sectionTitle,
            "QuestionId": questionId,
            "AnswerType": AnswerType,
            "AnswerValue": answersId
        }]

        await AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }

    const removePreviousSelection = (e) => {
        let options = e.target.closest('.options').childNodes;
        options.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove("active")
            }
        });
    }

    const nextPrev = async (n, statusCallFlag) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        if (n > 0) {
            if (statusCallFlag !== 'noStatusCall') {
                if ((currentQuestion + n) > lastAnsweredPage) {
                    setAssessmentSetspinner(true)
                    let payload = {
                        "candidateId": candidateID,
                        "learningResourceId": cmsMasterContent?.assessmentEJO?.ID,
                        "learningResourceType": cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID,
                        "resourceTypeId": cmsMasterContent?.assessmentEJO?.ResourceType?.ID,
                        "learningResourceStatus": (currentQuestion === assessmentQuestion.length - 1) === true ? "completed" : "inprogress",
                        "isRetaken": false,
                        "isSaved": assessmentStatus.isSaved,
                        "isLiked": assessmentStatus.isLiked,
                        "lastAnsweredPageId": currentQuestion + n,
                        "isDownloaded": assessmentStatus?.isDownloaded,
                        "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                        "resultDocumentId": assessmentStatus?.resultDocumentId
                    }
                    await AssessmentActions.SaveAssessmentStatus(cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID, cmsMasterContent?.assessmentEJO?.ID, payload).then((res) => {
                        if (res.status === 200) {

                            let percentageCalc = Math.ceil(((currentQuestion + n) / assessmentQuestion.length) * 100);
                            if (percentageCalc >= percentage) {
                                setPercentage(percentageCalc);
                            }

                            setTimeout(() => {
                                setAssessmentSetspinner(false)
                                setLastAnsweredPage(currentQuestion + n)
                                assessmentQuestion[currentQuestion].style.display = "none";
                                setCurrentQuestion(currentQuestion + n);
                                showQuestion(currentQuestion + n);
                            }, 1000);
                        } else {
                            setAssessmentSetspinner(false)
                        }
                    }, err => {
                        console.log(err);
                        setAssessmentSetspinner(false)
                    })
                } else {
                    setAssessmentSetspinner(false)
                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    showQuestion(currentQuestion + n);
                }
            } else {
                setAssessmentSetspinner(false)
                assessmentQuestion[currentQuestion].style.display = "none";
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        } else {
            setAssessmentSetspinner(false)
            assessmentQuestion[currentQuestion].style.display = "none";
            setCurrentQuestion(currentQuestion + n);
            showQuestion(currentQuestion + n);
        }
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n == -1) {
            navigate("/" + pageOrgin.id + "/evaluate-a-job-offer/start");
        } else {
            if (n < assessmentQuestion.length) {
                window.scrollTo(0, 0)
                assessmentQuestion[n].style.display = "block";
            }
            if (n == assessmentQuestion.length) {
                context.setGeneratePdfFlag(true)
                navigate("/" + pageOrgin.id + "/evaluate-a-job-offer/result");
            }
        }
    }

    const viewJobDescriptionHandler = () => {
        setJobDescriptionModal(true)
    }

    const loadMoreHandler = () => {
        let dataSet = masterAppliedJob.slice(appliedJobLists?.length, appliedJobLists?.length + 5);
        setAppliedJobLists([...appliedJobLists, ...dataSet])
    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (refCmsMasterContent?.masterLabel) {
            refCmsMasterContent?.masterLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const sortByNewest = (data) => {
        data.sort(function (a, b) {
            return new Date(b.lastAppliedTimeStamp) - new Date(a.lastAppliedTimeStamp);
        });
        return data;
    }

    const selectJobHandler = (seleJob) => {
        let newWeeklySchedArray = [...appliedJobLists]
        newWeeklySchedArray.map((x) => {
            if (x.jobId === seleJob.jobId) {
                x.IsSelected = true;
            } else {
                x.IsSelected = false;
            }
        })
        setSelectedAppliedJob(seleJob)
        setAppliedJobLists(newWeeklySchedArray)
    };

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page">
                <Container className='pt-4 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button isSecondary onClick={(e) => { nextPrev(-1) }}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchLabel("CommonComponent.CommonExitButton")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>

                {
                    loadSpinner === true ? <>
                        <div className='loader'>
                            <Cirrus.Spinner size={50} />
                        </div>
                    </> : <>
                        {
                            masterFlowWithQuestionAnswer?.map((assessmentSet, idx) => {
                                if (assessmentSet.Key === 'Assessments.EvaluateAJobOffer.AboutTheRole') {
                                    return (<Container className='assessment-question-wrapper mb-5'>
                                        {
                                            assessmentSetspinner &&
                                            <div className='loader'>
                                                <Cirrus.Spinner size={50} />
                                            </div>
                                        }
                                        <Row>
                                            <Col lg={12} className="assessment-question mb-3 pt-5">
                                                <Cirrus.Headline size='Small'>{assessmentSet?.Title}</Cirrus.Headline>
                                                <p className='mb-5 mx-auto about-role_question'>{assessmentSet?.Description}</p>
                                            </Col>
                                        </Row>
                                        {
                                            assessmentSet?.OptionList?.map((item) => {
                                                return (<>
                                                    <Row className='py-3 item-border'>
                                                        <Col lg={9} md={12} className='d-flex justify-content-lg-start justify-content-md-center role'>
                                                            <p>{item?.QuestionTitle}</p>
                                                        </Col>
                                                        <Col lg={3} md={12} className='d-flex justify-content-lg-end justify-content-md-center gap-3 role'>
                                                            {/* <Cirrus.Button disabled={item?.IsSelected === "" ? "" : item?.IsSelected === false ? true : false} onClick={() => aboutTheRoleHandler(item)}>{item?.ButtonLabel[0]?.Phrase}</Cirrus.Button> */}
                                                            <Cirrus.Button onClick={() => aboutTheRoleHandler(item)}
                                                            disabled={item.Key === "Assessments.EvaluateAJobOffer.AboutTheRole.SelectAnAppliedJobFrom" ? (featuresConfig?.isJobSearchFeatureAvailable == true ? false : true) : false}>{item?.ButtonLabel[0]?.Phrase}</Cirrus.Button>
                                                        </Col>
                                                    </Row>
                                                </>)
                                            })
                                        }
                                    </Container>)
                                } else if (assessmentSet.Key === 'Assessments.EvaluateAJobOffer.SelectAnAppliedJob') {
                                    return (<>
                                        <Container className='assessment-question-wrapper mb-5'>
                                            {
                                                assessmentSetspinner &&
                                                <div className='loader'>
                                                    <Cirrus.Spinner size={50} />
                                                </div>
                                            }
                                            <Row>
                                                <Col lg={12} className="assessment-question mb-3">
                                                    <Cirrus.Headline size='Small'>{assessmentSet?.Title}</Cirrus.Headline>
                                                    <p className='mb-5'>{assessmentSet?.Description}</p>
                                                </Col>
                                            </Row>

                                            <div className='job-scroll'>
                                                {
                                                    appliedJobLists?.map((job) => {
                                                        return (<>
                                                            <Row>
                                                                <Col lg={12} className="mb-3 text-start">
                                                                    <div className={job?.IsSelected === true ? 'job-list active' : "job-list"}>
                                                                        <div className='job-title'>{job?.jobTitle}</div>
                                                                        <p className='compName'>{job?.companyName}</p>
                                                                        <label className='selectJobLabel'>
                                                                            <input
                                                                                type='radio'
                                                                                name='jobroles'
                                                                                onChange={() => { selectJobHandler(job) }}
                                                                                defaultChecked={job?.IsSelected === "" ? false : job?.IsSelected}
                                                                            />
                                                                            <i className='icon-check_box_outline_blank unselected'></i>
                                                                            <i className='icon-check selected'></i>
                                                                            <span>{job?.IsSelected === '' ? "Select" : job?.IsSelected === true ? "Selected" : "Select"}</span>
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>)
                                                    })
                                                }
                                            </div>
                                            {
                                                masterAppliedJob?.length > 0 ? <>
                                                    {
                                                        appliedJobLists.length >= masterAppliedJob?.length ? '' : <div className='text-start'>
                                                            <Cirrus.Button
                                                                isSecondary
                                                                onClick={loadMoreHandler}
                                                            >
                                                                {fetchLabel("CommonComponent.CommonLoadMore")}
                                                            </Cirrus.Button>
                                                        </div>
                                                    }
                                                </> : ''
                                            }
                                            <Row>
                                                <Col lg={10} className='p-5 m-auto text-center evaluate-questions'>
                                                    <Cirrus.Button
                                                        className='w-25'
                                                        onClick={selectAnAppliedJobSubmitHandler}
                                                    >
                                                        {fetchLabel("CommonComponent.CommonContinueButton")}
                                                    </Cirrus.Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>)
                                } else if (assessmentSet.Key === 'Assessments.EvaluateAJobOffer.AboutTheOffer') {
                                    return (<>
                                        <Container className='assessment-question-wrapper mb-5'>
                                            {
                                                assessmentSetspinner &&
                                                <div className='loader'>
                                                    <Cirrus.Spinner size={50} />
                                                </div>
                                            }
                                            <Row>
                                                <Col lg={12} className="assessment-question mb-3">
                                                    <Cirrus.Headline size='Small'>{assessmentSet?.Title}</Cirrus.Headline>
                                                    <p className='mb-5'>{assessmentSet?.Description}</p>
                                                    <div className='text-start mb-3'>
                                                        {
                                                            assessmentSet?.questions.map((que) => {
                                                                if (que.OptionType === "TextBox") {
                                                                    return (<>
                                                                        <label>{que.QuestionTitle}</label>
                                                                        <input
                                                                            id={offerForm.title.name}
                                                                            name={offerForm.title.name}
                                                                            defaultValue={offerForm.title.value}
                                                                            placeholder={fetchLabel("CommonResourceComponent.EnterHere")}
                                                                            className='text-input mb-3'
                                                                            maxLength={50}
                                                                            type='text'
                                                                            onChange={(event) => aboutTheOfferFormHandler(event)}
                                                                        />
                                                                    </>)
                                                                } else {
                                                                    return (<>
                                                                        <label>{que.QuestionTitle}</label>
                                                                        <textarea
                                                                            id={offerForm.description.name}
                                                                            name={offerForm.description.name}
                                                                            defaultValue={offerForm.description.value}
                                                                            placeholder={fetchLabel("CommonResourceComponent.EnterHere")}
                                                                            maxLength={250}
                                                                            rows="10"
                                                                            onChange={(event) => aboutTheOfferFormHandler(event)}
                                                                        />
                                                                    </>)
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={10} className='p-5 m-auto text-center evaluate-continue-btn'>
                                                    <Cirrus.Button
                                                        className='w-25'
                                                        disabled={offerForm.continueBtnStatus}
                                                        onClick={aboutTheOfferSubmitHandler}
                                                    >
                                                        {fetchLabel("CommonComponent.CommonContinueButton")}
                                                    </Cirrus.Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>)
                                } else {
                                    return (<Container className='assessment-question-wrapper mb-5'>
                                        {
                                            assessmentSetspinner &&
                                            <div className='loader'>
                                                <Cirrus.Spinner size={50} />
                                            </div>
                                        }
                                        <Row>
                                            <Col lg={12} className="assessment-question mb-3">
                                                <Cirrus.Headline size='Small'>{assessmentSet?.Title}</Cirrus.Headline>
                                                <p className='mb-4 mx-auto about-role_question'>{assessmentSet?.Description}</p>
                                                <div className='job-role-details-section'>
                                                    {
                                                        selectedAppliedJob?.jobId !== undefined ? <div>{selectedAppliedJob?.jobTitle} at {selectedAppliedJob?.companyName}</div> : <div>{offerForm?.title?.value}</div>
                                                    }
                                                    {
                                                        selectedAppliedJob?.jobId !== undefined ? <>
                                                            <Cirrus.Button isSecondary onClick={viewJobDescriptionHandler}>{fetchLabel("Assessments.EvaluateAJobOffer.CommonLabels.ViewJobDescription")}</Cirrus.Button>
                                                        </> : ''
                                                    }
                                                </div>
                                                <div className='text-start'>
                                                    {
                                                        assessmentSet?.questions.map((que) => {
                                                            let chosen = [];
                                                            if (que.OptionType === "Options") {
                                                                return (<>
                                                                    <div
                                                                        className={assessmentSet.Key === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole" ?
                                                                            "iyip-questionSection mb-2 border-bottom py-3 single-row-qna"
                                                                            :
                                                                            "iyip-questionSection mb-2 border-bottom py-3"}
                                                                    >
                                                                        <div className='iyip-questionTitle mb-2'>
                                                                            {que?.QuestionTitle}
                                                                        </div>
                                                                        <div
                                                                            className={assessmentSet.Key === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation" ? "iyip-options options equal-width-options" : "iyip-options options"}>

                                                                            {que.AnswerList.map((options, optIdx) => {
                                                                                if (que.QuestionDesignType === "Single Select") {
                                                                                    if (assessmentSet.Key === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") {
                                                                                        return (
                                                                                            <Cirrus.RadioButton key={optIdx}
                                                                                                labelText={options.AnswerText}
                                                                                                name={que.QuestionId}
                                                                                                value={options.AnswerId}
                                                                                                defaultChecked={options.IsSelected}
                                                                                                onChange={(e) => {
                                                                                                    chosen = [];
                                                                                                    if (e.target.checked) {
                                                                                                        chosen.push(options.AnswerId)
                                                                                                    }
                                                                                                    saveAnswers(que.QuestionId, chosen.toString(), assessmentSet.Title, assessmentSet.ID, 'id');
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <label key={optIdx}
                                                                                                className={options.IsSelected === true ? "custom-radio-button active" : "custom-radio-button"}
                                                                                            >
                                                                                                <input type="radio"
                                                                                                    name={que.QuestionId}
                                                                                                    value={options.AnswerText}
                                                                                                    questionid={que.QuestionId}
                                                                                                    answerid={options.AnswerId}
                                                                                                    defaultChecked={options.IsSelected}
                                                                                                    onChange={(e) => {
                                                                                                        removePreviousSelection(e)
                                                                                                        chosen = [];
                                                                                                        if (e.target.checked) {
                                                                                                            chosen.push(options.AnswerId)
                                                                                                            e.target.parentNode.classList.add("active");
                                                                                                        } else {
                                                                                                            e.target.parentNode.classList.remove("active");
                                                                                                        }
                                                                                                        saveAnswers(que.QuestionId, chosen.toString(), assessmentSet.Title, assessmentSet.ID, 'id');
                                                                                                    }}
                                                                                                />
                                                                                                {options.AnswerText}
                                                                                            </label>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                else if (que.QuestionDesignType === "MultiSelect") {
                                                                                    return (
                                                                                        <Cirrus.Checkbox removeBorder key={optIdx}
                                                                                            label={options.AnswerText}
                                                                                            name={options.AnswerText}
                                                                                            value={options.AnswerId}
                                                                                            questionid={que.QuestionId}
                                                                                            defaultChecked={options.IsSelected}
                                                                                            onChange={(e) => {
                                                                                                if (e.target.checked) {
                                                                                                    chosen.push(options.AnswerId)
                                                                                                } else {
                                                                                                    chosen.splice(chosen.indexOf(options.AnswerId), 1);
                                                                                                }
                                                                                                saveAnswers(que.QuestionId, chosen.toString(), assessmentSet.Title, assessmentSet.ID, 'id');
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            } else {
                                                                return (<>
                                                                    <div className='iyip-questionSection mb-2 border-bottom py-3'>
                                                                        <div className='iyip-questionTitle mb-2'>
                                                                            {que?.QuestionTitle}
                                                                        </div>
                                                                        <div className='iyip-options'>
                                                                            <Cirrus.Select
                                                                                id='Organisation size'
                                                                                name='Organisation size'
                                                                                placeholder={fetchLabel("CommonResourceComponent.PleaseSelect")}
                                                                                defaultValue={dropDownDefaultValue}
                                                                                options={
                                                                                    que.AnswerList.map((options, optIdx) => {
                                                                                        return { value: options.AnswerId, name: options.AnswerText }
                                                                                    })
                                                                                }
                                                                                onChange={(e) => {
                                                                                    saveAnswers(que.QuestionId, e.target.value, assessmentSet.Title, assessmentSet.ID, 'id');
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10} className='p-5 m-auto text-center evaluate-questions'>
                                                {/* <Cirrus.Button
                                                    className='w-25'
                                                    onClick={(e) => { nextPrev(1) }}
                                                >
                                                    {fetchLabel("CommonComponent.CommonContinueButton")}
                                                </Cirrus.Button> */}
                                                {
                                                    document.getElementsByClassName("assessment-question-wrapper").length - 1 !== idx &&
                                                    <Cirrus.Button className='w-25' onClick={(e) => nextPrev(1)}>
                                                        {fetchLabel("CommonComponent.CommonContinueButton")}
                                                    </Cirrus.Button>
                                                }
                                                {
                                                    document.getElementsByClassName("assessment-question-wrapper").length - 1 === idx &&
                                                    <Cirrus.Button className='w-25' onClick={(e) => nextPrev(1)}>
                                                        {fetchLabel("CommonComponent.CommonCompleteButton")}
                                                    </Cirrus.Button>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>)
                                }
                            })
                        }
                    </>
                }
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}

            {
                jobNotFoundModal && <Cirrus.AlertModal
                    heading={fetchLabel("Assessments.EvaluateAJobOffer.SelectAnAppliedJob.WeAreSorry")}
                    primaryButton={{
                        children: fetchLabel("Assessments.EvaluateAJobOffer.SelectAnAppliedJob.Dismiss"),
                        onClick: noJobRedirection,
                        size: 'Large',
                        type: 'button'
                    }}
                    role="alert"
                    subheading={fetchLabel("Assessments.EvaluateAJobOffer.SelectAnAppliedJob.Description")}
                    className='nojob-alertModal'
                />
            }

            {
                jobDescriptionModal && <Cirrus.Modal
                    closeButtonProps={{
                        children: 'Close',
                        isSecondary: true,
                        onClick: () => { setJobDescriptionModal(false) },
                        size: 'Small',
                        type: 'button'
                    }}
                    headerProps={{
                        children: 'Job description',
                        className: 'no-margin',
                        size: 'h5'
                    }}
                    isScrollable
                    role="dialog"
                    size="default"
                >
                    <p>
                        <Cirrus.RichText
                            content={selectedAppliedJob?.jobDescription}
                        />
                    </p>
                </Cirrus.Modal>
            }

        </>
    )
}

export default QuestionsPage
