//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect, useState, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import { OnBoardingActions } from "../OnBoardingActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import twoPeople from "manpowergroup-cirrus/assets/images/twoPeople.png";
import Icon from "../../../assets/fonts/icomoon/Icon";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { globalDateFormat } from "../../../common/helpers/DateTimeHelper";
import LangHeader from "../../header/simpleHeader/LangHeader";

function Welcome() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const [screenLoader, setScreenLoader] = useState(false);
  const [cmsContent, setCmsContent] = useState({
    title: "",
    description: "",
    labels: [],
  });

  const [desc, setDesc] = useState("");

  const [newCMSData, setNewCMSData] = useState();

  useEffect(() => {
    fetchPrgDetl();
    TrackActions.EventTrack(appInsights, "SignUp", { SignUpTime: new Date() });
    TrackActions.PageAction("SignUp", { SignUpTime: new Date() });
  }, []);

  useEffect(() => {
    fetchPrgDetl();
  }, [context?.language]);

  const fetchPrgDetl = async () => {
    let payload = {
      PageName: "OnboardingPages",
    };
    await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
      (res) => {
        if (res.status === 200) {
          window.sessionStorage.setItem(
            "setCmsOnBoadingContent",
            JSON.stringify(res.data)
          );
          let resData = JSON.parse(
            window.sessionStorage.getItem("setCmsOnBoadingContent")
          );
          if (Object.keys(resData).length !== 0) {
            var refCmsContent = JSON.parse(JSON.stringify(cmsContent));
            setScreenLoader(true);
            let refSetProgramDetails = JSON.parse(
              window.sessionStorage.getItem("setProgramDetails")
            );
            resData?.Pages?.filter((x) => {
              if (x.Key === "SignUpWelcome") {
                setNewCMSData(x);
                let compName =
                  x.Components["SignUpWelcome.OnboardingWelcomeConfirmDetails"];
                refCmsContent.title = compName.Title;
                let componentContents = x.Description;
                let replaceProgramName = componentContents.replace(
                  "{program_name}",
                  refSetProgramDetails.programName
                );
                let replaceProgramNameAndDate = replaceProgramName.replace(
                  "{program_date}",
                  globalDateFormat(refSetProgramDetails.programEndDate)
                );
                componentContents = replaceProgramNameAndDate;
                refCmsContent.description = componentContents;
                refCmsContent.labels = compName.Labels;

                let descLabel = x.Components[
                  "SignUpWelcome.OnboardingWelcomeConfirmDetails"
                ]?.Labels?.find((item) => {
                  return (
                    item.Key ===
                    "SignUpWelcome.OnboardingWelcomeConfirmDetails.Youhavebeenenrolledintoprogram"
                  );
                })?.Phrase;

                let replaceProgName = descLabel.replace(
                  "{program_name}",
                  refSetProgramDetails.programName
                );
                descLabel = replaceProgName;
                if (refSetProgramDetails.programEndDate === null) {
                  refCmsContent.description = descLabel;
                }
              }
            });
            setCmsContent(refCmsContent);
            setScreenLoader(false);
          }
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (refCmsContent?.labels.length > 0) {
      refCmsContent.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const navigateTo = () => {
    navigate("/signup-confirm-details");
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="welcome-onboard">
      <div className="customLangHeader">
        <LangHeader />
      </div>
      <Cirrus.OnboardingWelcomePanel
        buttonAction={navigateTo}
        buttonText={fetchLabel(
          "SignUpWelcome.OnboardingWelcomeConfirmDetails.Confirm"
        )}
        content={cmsContent.description}
        imageSource={{
          altText: "",
          src: twoPeople,
        }}
        title={cmsContent.title}
        titleIcon={<Icon icon="hand-wave" size={40} color="orange" />}
      />
    </div>
  );
}
export default Welcome;
