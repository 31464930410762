//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext } from 'react';
import { React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import MultipleFilesUpload from '../../../common/components/uploadFile/MultipleFilesUpload';
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import Icon from "../../../assets/fonts/icomoon/Icon";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../../common/utility/LogAction';

const PFAIAssessment = () => {
    let featuresConfig = JSON.parse(window.sessionStorage.getItem("featuresConfig"))
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [percentage, setPercentage] = useState(0);
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
    const [assessmentStatus, setAssessmentStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [dropDownDefaultValue, setDropDownDefaultValue] = useState();
    const [showJobRoleFields, setShowJobRoleFields] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [uploadModalFlag, setUploadModalFlag] = useState({
        apiFlag: "DocumentUpload",
        replaceID: "",
        uploadType: "Single"
    });
    const [cmsUploadDocuments, setCmsUploadDocuments] = useState({})
    const [answer, setAnswer] = useState('');
    const [jobRoleAnswer, setJobRoleAnswer] = useState('');
    const [enableSelectAppliedJob, setEnableSelectAppliedJob] = useState(false);
    const [allAppliedJobs, setAllAppliedJobs] = useState();
    const [savedJobRoleData, setSavedJobRoleData] = useState();
    const [showDescriptionModal, setShowDescriptionModal] = useState(false)
    const [isJobsDisabled, setIsJobsDisabled] = useState(false)
    const [embedFile, setEmbedFile] = useState();
    const [embedFileType, setEmbedFileType] = useState('');
    const [hidden, setHidden] = useState({});

    const [enableJobContinue, setEnableJobContinue] = useState(false)
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        getAllAppliedJobs()
        window.scrollTo(0, 0)
        TrackActions.PageViewRecord(appInsights, "PrepareInterviewAssessment")
        TrackActions.PageAction("PrepareInterviewAssessment")
    }, [cmsAssessmentContent, context.language])

    useEffect(() => {
        getAssessmentStatusWithAnswers();
    }, [])

    const getAllAppliedJobs = async () => {
        await AssessmentActions.GetEJOAllSavedJob().then(res => {
            if (res.status === 200) {
                let arr = [];
                let resData = res.data;
                if (res.data.length) {
                    resData.map(jobs => {
                        arr.push({ ...jobs, defaultChecked: false })
                    })
                    let sorted = arr.sort(function (a, b) {
                        return new Date(b.lastAppliedTimeStamp) - new Date(a.lastAppliedTimeStamp);
                    });
                    setAllAppliedJobs(sorted)
                    // setIsJobsDisabled(false)
                    if(featuresConfig?.isJobSearchFeatureAvailable) {
                        setIsJobsDisabled(false)
                    } else {
                        setIsJobsDisabled(true)
                    }
                } else {
                    setIsJobsDisabled(true)
                }
            } else {
                setIsJobsDisabled(true)
            }
        }, err => {
            console.log(err)
        })
    }

    const fetchCMSData = () => {
        let resData = context.cmsPrepareForInterviewContent;
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));

        let data = context.cmsPrepareForInterviewContent?.assessmentQuestionSet?.filter((x) => x.Key === "Assessments.PrepareForAnInterview.AboutTheRole")

        refCmsUploadDocuments.title = data[0]?.Components["Common.UploadProgressView"]?.Title;
        refCmsUploadDocuments.uploadDescription = "";
        refCmsUploadDocuments.labels = data[0]?.Components["Common.UploadProgressView"]?.Labels;
        refCmsUploadDocuments.dragDropText = context.cmsPrepareForInterviewContent.uploadAJob.RichTexts;
        refCmsUploadDocuments.titleLabel = data[0]?.Components["Resources.Assessments.PrepareForAnInterview.AboutTheRole"]?.QuestionList[0]?.QuestionTitle;
        setCmsUploadDocuments(refCmsUploadDocuments)

        let assessments = resData?.assessmentQuestionSet?.map(assessment => {
            if (assessment.Key === "Assessments.PrepareForAnInterview.AboutTheOrganisation") {
                let quest = assessment?.QuestionList?.map(question => {
                    let opts = question?.AnswerList?.map(options => {
                        if (question?.OptionType !== "Dropdown") {
                            return ({ ...options, defaultChecked: false })
                        } else {
                            return (options)
                        }
                    })
                    return ({ ...question, AnswerList: opts })
                })
                return ({ ...assessment, QuestionList: quest })
            } else {
                return (assessment)
            }
        })
        Object.keys(resData).forEach(key => {
            if (key === "assessmentQuestionSet") {
                resData[key] = assessments;
            }
        })
        return resData
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchTooltipLabel = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.prepareForAnInterviewCommonLabels) {
            refCmsAssessmentContent?.prepareForAnInterviewCommonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchTooltipDescLabel = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.prepareForAnInterviewLabels.RichTexts) {
            refCmsAssessmentContent?.prepareForAnInterviewLabels.RichTexts.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const fetchAboutRoleLabel = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.aboutTheRoleLabels) {
            refCmsAssessmentContent?.aboutTheRoleLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchSelectJobLabels = (key) => {
        let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
        let label = '';
        if (refCmsAssessmentContent?.prepareForAnInterviewCommonLabels) {
            refCmsAssessmentContent?.prepareForAnInterviewCommonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const getAssessmentStatusWithAnswers = async () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        await AssessmentActions.GetJobRole(context.cmsPrepareForInterviewContent.internalAssessmentId).then(resp => {
            if (resp.status === 200) {
                setJobRoleAnswer(resp.data.jobRole);
                setSavedJobRoleData(resp.data)
                setShowJobRoleFields(true)
            }
        }, err => {
            console.log(err)
        })
        await AssessmentActions.GetAssessmentStatusAndSavedResponseById(context.cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                setAssessmentStatus(res.data);
                setIsSaved(res.data.isSaved);
                setIsLiked(res.data.isLiked);

                let cmsData = fetchCMSData();
                let savedResponses = res.data.learningResourceAssessmentResponseModels;
                if (savedResponses.length) {
                    savedResponses.map(savedResponse => {
                        let AnswerId;
                        let QuestionId = savedResponse.questionId;
                        if (savedResponse.answerType === "id") {
                            AnswerId = savedResponse.answerValue != "" ? savedResponse.answerValue.split(",") : "";
                        } else {
                            AnswerId = savedResponse.answerValue
                        }


                        cmsData?.assessmentQuestionSet?.map(assessment => {
                            if (assessment.Key === "Assessments.PrepareForAnInterview.AboutTheOrganisation") {
                                assessment.QuestionList.map(question => {
                                    if (question.QuestionId === QuestionId) {
                                        if (AnswerId.length) {
                                            if (Array.isArray(AnswerId)) {
                                                AnswerId?.map(answerid => {
                                                    question.AnswerList.map(options => {
                                                        if (options.AnswerId === answerid) {
                                                            if (question.OptionType !== "Dropdown") {
                                                                Object.keys(options).forEach(key => {
                                                                    if (key === "defaultChecked") {
                                                                        options[key] = true;
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                setDropDownDefaultValue(answerid);
                                                            }
                                                        }
                                                    })
                                                })
                                            }
                                        }
                                        if (question.OptionType === "TextArea") {
                                            Object.keys(question).forEach(key => {
                                                if (key === 'AnswerList') {
                                                    question[key] = savedResponse.answerValue;
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            if (assessment.Key === "Assessments.PrepareForAnInterview.Rationale") {
                                assessment.QuestionList.map(question => {
                                    if (question.QuestionId === QuestionId) {
                                        Object.keys(question).forEach(key => {
                                            if (key === 'AnswerList') {
                                                question[key] = savedResponse.answerValue;
                                            }
                                        })
                                    }
                                })
                            }
                            if (assessment.Key === "Assessments.PrepareForAnInterview.Questions") {
                                assessment.QuestionList.map(question => {
                                    if (question.QuestionId === QuestionId) {
                                        Object.keys(question).forEach(key => {
                                            if (key === 'AnswerList') {
                                                question[key] = savedResponse.answerValue;
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    })
                    setCmsAssessmentContent(cmsData);
                } else {
                    setCmsAssessmentContent(cmsData);
                }

                let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                if (res.data.lastAnsweredPageId) {
                    if (parseInt(res.data.lastAnsweredPageId) < assessmentQuestion.length) {
                        setCurrentQuestion(currentQuestion + parseInt(res.data.lastAnsweredPageId));
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId)].style.display = "block";
                    } else {
                        setCurrentQuestion(parseInt(res.data.lastAnsweredPageId) - 1);
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId) - 1].style.display = "block";
                    }
                    let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / assessmentQuestion.length) * 100);
                    setPercentage(percentageCalc);
                } else {
                    assessmentQuestion[currentQuestion].style.display = "block";
                }
            } else {
                console.log(res)
            }
        }, err => {
            console.log(err)
        })
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n < assessmentQuestion.length) {
            window.scrollTo(0, 0)
            assessmentQuestion[n].style.display = "block";
        }
        if (n == assessmentQuestion.length) {
            context.setGeneratePdfFlag(true)
            navigate("/" + pageOrgin.id + "/prepare-for-an-interview/result")
        }
    }

    const nextPrev = (e, n) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        let payload;
        if (n > 0) {
            setLoadSpinner(true);
            if (currentQuestion === assessmentQuestion.length - 1) {
                payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsAssessmentContent.internalAssessmentId,
                    "learningResourceType": cmsAssessmentContent.resourceType.Value,
                    "learningResourceStatus": fetchStatusID("ResourceStatus.Completed"),
                    "isSaved": isSaved,
                    "isLiked": isLiked,
                    "lastAnsweredPageId": currentQuestion + n,
                    "isDownloaded": assessmentStatus?.isDownloaded,
                    "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                    "resultDocumentId": assessmentStatus?.resultDocumentId
                }
            } else {
                payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsAssessmentContent.internalAssessmentId,
                    "learningResourceType": cmsAssessmentContent.resourceCategoryType.ID,
                    "resourceTypeId": cmsAssessmentContent.resourceType.ID,
                    "learningResourceStatus": assessmentStatus.learningResourceStatus,
                    "isSaved": isSaved,
                    "isLiked": isLiked,
                    "lastAnsweredPageId": currentQuestion + n,
                    "isDownloaded": assessmentStatus?.isDownloaded,
                    "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                    "resultDocumentId": assessmentStatus?.resultDocumentId
                }
            }

            AssessmentActions.SaveAssessmentStatus(cmsAssessmentContent.resourceCategoryType.ID, cmsAssessmentContent.internalAssessmentId, payload).then((res) => {
                if (res.status === 200) {

                    let percentageCalc = Math.ceil(((currentQuestion + n) / assessmentQuestion.length) * 100);
                    if (percentageCalc >= percentage) {
                        setPercentage(percentageCalc);
                    }

                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    if (currentQuestion < 0) {
                        setCurrentQuestion(0);
                    }
                    showQuestion(currentQuestion + n);
                    setLoadSpinner(false);
                } else {
                    setLoadSpinner(false);
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        } else {
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setPercentage(percentage);
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const saveAnswers = (assessmentId, questionId, answersId, sectionTitle, sectionID, pageNumber) => {
        let payload = [{
            "AssessementId": assessmentId,
            "AssessementType": "Internal",
            "CategoryType": sectionID,
            "CategoryValue": sectionTitle,
            "QuestionId": questionId,
            "AnswerType": "id",
            "AnswerValue": answersId
        }]

        AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }

    const handleOnchange = (e, idx) => {
        if (e.target.value.length > 0) {
            setShowJobRoleFields(true)
            saveJobRole(e.target.value)
        } else {
            setShowJobRoleFields(false)
        }
    }

    const openUploadModal = () => {
        if(savedJobRoleData && savedJobRoleData.documentId == undefined) {
            setUploadModalFlag({
                apiFlag: "DocumentUpload",
                replaceID: "",
                uploadType: "Single"
            });
        } else {
            setUploadModalFlag({
                apiFlag: "DocumentReplace",
                replaceID: savedJobRoleData.documentId,
                uploadType: "Single"
            });
        }
        setUploadModal(true)
    }

    const uploadModalCloseHandler = () => {
        setUploadModal(false);
    }

    const finishUploadHandler = (uploadedFiles) => {
        setUploadModal(false);
        setEmbedFileType('')
        updateJDdocument(uploadedFiles[0].id)
    }

    const updateJDdocument = async (docId) => {
        let payload = {
            "jobRole": jobRoleAnswer,
            "documentId": docId,
            "isFromAppliedJob": false
        }
        await AssessmentActions.SaveJobRole(payload, context.cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
            if (res.status === 200) {

            } else {
                console.log(res)
            }
        }, err => {
            console.log(err)
        })

        await AssessmentActions.GetJobRole(context.cmsPrepareForInterviewContent.internalAssessmentId).then(resp => {
            if (resp.status === 200) {
                setJobRoleAnswer(resp.data.jobRole)
                setSavedJobRoleData(resp.data)
            }
        }, err => {
            console.log(err)
        })
        nextPrev(1, 1)
    }

    const showDescModal = () => {
        setShowDescriptionModal(true)
        viewJdHandler();
    }

    const closeDescModal = () => {
        setShowDescriptionModal(false)
    }

    const viewJdHandler = async () => {
        await MyDocumentsActions.DownloadDocument(savedJobRoleData.documentId).then(response => {
            if (response.status === 200) {
                let contentTypeHeader = response.headers["content-type"];
                var blob = new Blob([response.data], {
                    type: contentTypeHeader.toLowerCase(),
                    charset: "UTF-8"
                });

                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    if (contentTypeHeader === 'application/pdf') {
                        let base64data = reader.result + "#toolbar=0&page=1&view=FitH&zoom=100";
                        setEmbedFile("data:application/pdf;base64," + base64data.replace('data:', '').replace(/^.+,/, ''));
                        setEmbedFileType("application/pdf")
                    } else if(contentTypeHeader === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        console.log('DOCX')
                        let docFile = new File([blob], 'document')
                        setEmbedFile(docFile)
                        let docx = require('../../../../node_modules/docx-preview/dist/docx-preview')

                        var docxOptions = Object.assign(docx.defaultOptions, {
                            useMathMLPolyfill: true
                        });
                        //Reference the Container DIV.
                        var container = document.querySelector("#word-container");

                        //Render the Word Document.
                        docx.renderAsync(docFile, container, null, docxOptions);
                    } 
                }
            }
        }, err => {
            console.log(err)
        })
    }

    const saveJobRole = async (jobRole) => {
        let payload = {
            "jobRole": jobRole,
            "isFromAppliedJob": false
        }
        await AssessmentActions.SaveJobRole(payload, context.cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                AssessmentActions.GetJobRole(context.cmsPrepareForInterviewContent.internalAssessmentId).then(resp => {
                    if (resp.status === 200) {
                        setJobRoleAnswer(resp.data.jobRole)
                        setSavedJobRoleData(resp.data)
                    }
                }, err => {
                    console.log(err)
                })
            }
        }, err => {
            console.log(err)
        })
    }

    const saveOrganisationQuestions = async (e, questionId, sectionId, sectionTitle) => {
        let payload = [{
            "AssessementId": context.cmsPrepareForInterviewContent.internalAssessmentId,
            "AssessementType": "Internal",
            "CategoryType": sectionId,
            "CategoryValue": sectionTitle,
            "QuestionId": questionId,
            "AnswerType": "text",
            "AnswerValue": e.target.value
        }]

        await AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }

    const saveRationaleQuestions = async (e, questionId, sectionId, sectionTitle) => {
        setAnswer(e.target.value)
        let payload = [{
            "AssessementId": context.cmsPrepareForInterviewContent.internalAssessmentId,
            "AssessementType": "Internal",
            "CategoryType": sectionId,
            "CategoryValue": sectionTitle,
            "QuestionId": questionId,
            "AnswerType": "text",
            "AnswerValue": e.target.value
        }]

        await AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }

    const showAppliedJobsList = () => {
        setEnableSelectAppliedJob(true)
    }

    const selectedJobRole = async (jobTitle, companyName, jobId, jobDescription) => {

        setLoadSpinner(true)
        let payload = {
            "jobId": jobId,
            "jobRole": jobRoleAnswer,
            "companyName": companyName,
            "jobDescription": jobDescription,
            "documentId": "",
            "isFromAppliedJob": true
        }
        await AssessmentActions.SaveJobRole(payload, context.cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                getAllAppliedJobs()
                AssessmentActions.GetJobRole(context.cmsPrepareForInterviewContent.internalAssessmentId).then(resp => {
                    setSavedJobRoleData(resp.data)
                    setLoadSpinner(false)
                    setEnableJobContinue(true)
                })
            } else {
                setLoadSpinner(false)
            }
        }, err => {
            console.log(err)
            setLoadSpinner(false)
        })
    }



    const toggleHide = index => {
        setHidden({ ...hidden, [index]: !hidden[index] });
    }

    return (
        <>
            <div className="assessment-page prepare_interview-page">
                {/* <Cirrus.Page className='assessment-page'
                    background="secondary"
                    backgroundShape="singleDesktop"
                    header={<HeaderWithNavigation />}
                    removeFooterPadding
                > */}
                <Container className='pt-lg-4 pt-3 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            {
                                currentQuestion == 0 ?
                                    (enableSelectAppliedJob === true ?
                                        <Cirrus.Button isSecondary onClick={(e) => setEnableSelectAppliedJob(false)}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                        : <Cirrus.Button isSecondary onClick={(e) => navigate("/" + pageOrgin.id + "/prepare-for-an-interview")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>)
                                    :
                                    <Cirrus.Button isSecondary onClick={(e) => { nextPrev(e, -1) }}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                            }
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonResourceLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                {
                    cmsAssessmentContent?.assessmentQuestionSet?.map((assessment, idx) => {
                        if (assessment.Key === "Assessments.PrepareForAnInterview.AboutTheRole") {
                            return (
                                <Container className='assessment-question-wrapper mb-5' key={idx}>
                                    {
                                        loadSpinner &&
                                        <div className='loader'>
                                            <Cirrus.Spinner size={50} />
                                        </div>
                                    }
                                    {
                                        !enableSelectAppliedJob &&
                                        <Row>
                                            <Col lg={12} className='p-lg-5 p-2 m-auto'>
                                                <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                                <p className='about-role_question mx-auto'>{assessment.Description}</p>
                                                <div className="text-start mt-4">
                                                    <Cirrus.Input
                                                        errorMessage="Please enter role title"
                                                        id="test-input"
                                                        label={cmsUploadDocuments?.titleLabel}
                                                        name="FirstName"
                                                        maxLength={50}
                                                        defaultValue={jobRoleAnswer}
                                                        onChange={(e) => handleOnchange(e)}
                                                        placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                    />
                                                    {
                                                        showJobRoleFields &&
                                                        <>
                                                            <div className="mt-5 border-bottom d-flex justify-content-between interview_assesment">
                                                                <p>{fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.SelectAnAppliedJobFrom")}</p>
                                                                <Cirrus.Button className="mb-4" onClick={() => { showAppliedJobsList() }} disabled={isJobsDisabled}>
                                                                    {fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.SelectJob")}
                                                                </Cirrus.Button>
                                                            </div>
                                                            <div className="mt-5 border-bottom d-flex justify-content-between interview_assesment">
                                                                <p>{fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.UploadAJobDescriptionText")}</p>
                                                                <Cirrus.Button className="mb-4" onClick={() => { openUploadModal() }}>
                                                                    {fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.UploadAJobDescription")}
                                                                </Cirrus.Button>
                                                            </div>
                                                            <div className="mt-5 text-end interview_assesment-skip">
                                                                <p>
                                                                    {fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.SkipText")}
                                                                    <Cirrus.Button className="text-end ms-3" isSecondary onClick={(e) => nextPrev(e, 1)}>
                                                                        {fetchAboutRoleLabel("Resources.Assessments.PrepareForAnInterview.AboutTheRole.SkipButton")}
                                                                    </Cirrus.Button>
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        enableSelectAppliedJob &&
                                        <>
                                            {
                                                loadSpinner &&
                                                <div className='loader'>
                                                    <Cirrus.Spinner size={50} />
                                                </div>
                                            }
                                            <Row>
                                                <Col lg={12} className='p-lg-5 p-2 m-auto'>
                                                    <Cirrus.Headline size='Small'>{cmsAssessmentContent.selectAnAppliedJob.Title}</Cirrus.Headline>
                                                    <p className='mb-5'>{cmsAssessmentContent.selectAnAppliedJob.Description}</p>
                                                    {
                                                        allAppliedJobs &&
                                                        <>
                                                            <div className='text-start appliedJobsSection'>
                                                                {
                                                                    allAppliedJobs.map((jobs, jobIdx) => {
                                                                        if (jobs.jobId === savedJobRoleData.jobId) {
                                                                            return (
                                                                                <div className='applied-jobs-list active' key={jobIdx}>
                                                                                    <div>
                                                                                        <h6 className='mb-2'>{jobs.jobTitle}</h6>
                                                                                        <p className='mb-0'>
                                                                                            {jobs.companyName}
                                                                                        </p>
                                                                                    </div>
                                                                                    <label className='selectJobLabel'>
                                                                                        <input type='radio' name='jobroles' defaultChecked={true} onChange={(e) => selectedJobRole(jobs.jobTitle, jobs.companyName, jobs.jobId, jobs.jobDescription)} />
                                                                                        <i className='icon-check_box_outline_blank unselected'></i>
                                                                                        <i className='icon-check selected'></i>
                                                                                        <span>{fetchSelectJobLabels("Resources.Assessments.PrepareForAnInterview.CommonLabels.Selected")}</span>
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div className='applied-jobs-list' key={jobIdx}>
                                                                                    <div>
                                                                                        <h6 className='mb-2'>{jobs.jobTitle}</h6>
                                                                                        <p className='mb-0'>
                                                                                            {jobs.companyName}
                                                                                        </p>
                                                                                    </div>
                                                                                    <label className='selectJobLabel'>
                                                                                        <input type='radio' name='jobroles' defaultChecked={jobs.defaultChecked} onChange={(e) => selectedJobRole(jobs.jobTitle, jobs.companyName, jobs.jobId, jobs.jobDescription)} />
                                                                                        <i className='icon-check_box_outline_blank unselected'></i>
                                                                                        <i className='icon-check selected'></i>
                                                                                        <span>{fetchSelectJobLabels("Resources.Assessments.PrepareForAnInterview.CommonLabels.Select")}</span>
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            <Cirrus.Button disabled={enableJobContinue ? false : true} onClick={(e) => nextPrev(e, 1)}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Container>
                            )
                        }
                        if (assessment.Key === "Assessments.PrepareForAnInterview.AboutTheOrganisation") {
                            return (
                                <Container className='assessment-question-wrapper mb-5' key={idx}>
                                    {
                                        loadSpinner &&
                                        <div className='loader'>
                                            <Cirrus.Spinner size={50} />
                                        </div>
                                    }
                                    <Row>
                                        <Col lg={12} className='p-lg-5 p-3 m-auto'>
                                            <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                            <p className='mb-5'>{assessment.Description}</p>
                                            <div className='job-role-details-section'>
                                                <div>
                                                    {jobRoleAnswer}
                                                </div>
                                                {
                                                    (savedJobRoleData?.jobDescription || savedJobRoleData?.documentId) &&
                                                    <Cirrus.Button isSecondary onClick={() => { showDescModal() }}>{fetchTooltipLabel('Resources.Assessments.PrepareForAnInterview.CommonLabels.ViewJobDescription')}</Cirrus.Button>
                                                }
                                            </div>
                                            <div className='text-start mb-5'>
                                                {
                                                    assessment.QuestionList.map((question, qIdx) => {
                                                        let chosen = [];
                                                        if (question.OptionType === "Options") {
                                                            return (
                                                                <div className={"iyip-questionSection mb-2 border-bottom py-3"} key={qIdx}>
                                                                    <div className='iyip-questionTitle mb-2'>
                                                                        {question.QuestionTitle}
                                                                    </div>
                                                                    <div className={"iyip-options options equal-width-options"}>
                                                                        {
                                                                            question.AnswerList.map((options, optIdx) => {
                                                                                if (question.QuestionDesignType === "Single Select") {
                                                                                    return (
                                                                                        <Cirrus.RadioButton key={optIdx}
                                                                                            labelText={options.AnswerText}
                                                                                            name={question.QuestionId}
                                                                                            value={options.AnswerId}
                                                                                            defaultChecked={options.defaultChecked}
                                                                                            onChange={(e) => {
                                                                                                chosen = [];
                                                                                                if (e.target.checked) {
                                                                                                    chosen.push(options.AnswerId)
                                                                                                }
                                                                                                saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, chosen.toString(), assessment.Title, assessment.ID, idx);
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <Cirrus.Checkbox removeBorder key={optIdx}
                                                                                            label={options.AnswerText}
                                                                                            name={options.AnswerText}
                                                                                            value={options.AnswerId}
                                                                                            questionid={question.QuestionId}
                                                                                            defaultChecked={options.defaultChecked}
                                                                                            onChange={(e) => {
                                                                                                if (e.target.checked) {
                                                                                                    chosen.push(options.AnswerId)
                                                                                                } else {
                                                                                                    chosen.splice(chosen.indexOf(options.AnswerId), 1);
                                                                                                }
                                                                                                saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, chosen.toString(), assessment.Title, assessment.ID, idx);
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else if (question.OptionType === "TextArea") {
                                                            return (
                                                                <div className='mb-5'>
                                                                    <label className='mb-2'>{question.QuestionTitle}</label>
                                                                    <textarea
                                                                        id={question.QuestionId}
                                                                        placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                                        name={question.QuestionId}
                                                                        maxLength={300}
                                                                        rows="10"
                                                                        defaultValue={question.AnswerList}
                                                                        onChange={(e) => saveOrganisationQuestions(e, question.QuestionId, assessment.ID, assessment.Title)}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className='iyip-questionSection mb-2 border-bottom py-3' key={qIdx}>
                                                                    <div className='iyip-questionTitle mb-2' key={qIdx}>
                                                                        {question.QuestionTitle}
                                                                    </div>
                                                                    <div className='iyip-options'>
                                                                        <Cirrus.Select
                                                                            id={question.QuestionId}
                                                                            name={question.QuestionTitle}
                                                                            placeholder="-- Please select --"
                                                                            aria-label="select"
                                                                            defaultValue={dropDownDefaultValue}
                                                                            options={
                                                                                question.AnswerList.map((options, optIdx) => {
                                                                                    return { ...options, value: options.AnswerId, name: options.AnswerText }
                                                                                })
                                                                            }
                                                                            onChange={(e) => {
                                                                                saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, e.target.value, assessment.Title, assessment.ID, idx);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                                        </Col>
                                    </Row>
                                </Container>
                            )
                        }
                        if (assessment.Key === "Assessments.PrepareForAnInterview.Rationale") {
                            return (
                                <Container className='assessment-question-wrapper mb-5' key={idx}>
                                    {
                                        loadSpinner &&
                                        <div className='loader'>
                                            <Cirrus.Spinner size={50} />
                                        </div>
                                    }
                                    <Row>
                                        <Col lg={9} className='text-start pt-lg-5 pt-2 m-auto'>
                                            <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                            <p className='mb-4'>{assessment.Description}</p>
                                            <div>
                                                {
                                                    (savedJobRoleData?.jobDescription || savedJobRoleData?.documentId) &&
                                                    <Cirrus.Button isSecondary className='mb-4' onClick={() => { showDescModal() }}>{fetchTooltipLabel('Resources.Assessments.PrepareForAnInterview.CommonLabels.ViewJobDescription')}</Cirrus.Button>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={3}></Col>
                                    </Row>
                                    {
                                        assessment.QuestionList.map((question, qIdx) => {
                                            return (
                                                <Row>
                                                    <Col lg={9} className='text-start m-auto'>
                                                        <div key={qIdx} className='mb-lg-5 mb-3'>
                                                            <label className='mb-2'>{question.QuestionTitle}</label>
                                                            <textarea
                                                                id={question.QuestionId}
                                                                placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                                name={question.QuestionId}
                                                                maxLength={300}
                                                                rows="10"
                                                                defaultValue={question.AnswerList}
                                                                onChange={(e) => saveRationaleQuestions(e, question.QuestionId, assessment.ID, assessment.Title)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <label className='mb-2 d-none d-lg-block'>&nbsp;</label>
                                                        <div className='help-tips mb-3'>
                                                            <div className='tip-header' onClick={() => toggleHide(idx)}>
                                                                <div>
                                                                    <span className='tip-icon'>
                                                                        <Icon icon="bulb" size={16} />
                                                                    </span>
                                                                    <span>
                                                                        {fetchTooltipLabel("Resources.Assessments.PrepareForAnInterview.CommonLabels.Tip")}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {!!hidden[idx] && <i className='icon-button-arrow rotate-arrow'></i> || <i className='icon-button-arrow'></i>}
                                                                </div>
                                                            </div>
                                                            {
                                                                !!hidden[idx] &&
                                                                <div className='help-tips-desc'>
                                                                    {fetchTooltipDescLabel("Resources.Assessments.PrepareForAnInterview.CommonLabels.ToolTipText")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                                </Container>
                            )
                        }
                        if (assessment.Key === "Assessments.PrepareForAnInterview.Questions") {
                            return (
                                <Container className='assessment-question-wrapper mb-5' key={idx}>
                                    {
                                        loadSpinner &&
                                        <div className='loader'>
                                            <Cirrus.Spinner size={50} />
                                        </div>
                                    }
                                    <Row>
                                        <Col lg={9} className='text-start pt-5 m-auto'>
                                            <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                            <p className='mb-4 text-start'>{assessment.Description}</p>
                                            <div>
                                                {
                                                    (savedJobRoleData?.jobDescription || savedJobRoleData?.documentId) &&
                                                    <Cirrus.Button isSecondary className='mb-4' onClick={() => { showDescModal() }}>{fetchTooltipLabel('Resources.Assessments.PrepareForAnInterview.CommonLabels.ViewJobDescription')}</Cirrus.Button>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={3}></Col>
                                    </Row>
                                    {
                                        assessment.QuestionList.map((question, qIdx) => {
                                            return (
                                                <Row>
                                                    <Col lg={9} className='text-start m-auto'>
                                                        <div key={qIdx} className='mb-lg-5 mb-3'>
                                                            <label className='mb-2'>{question.QuestionTitle}</label>
                                                            <textarea
                                                                id={question.QuestionId}
                                                                placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                                name={question.QuestionId}
                                                                maxLength={500}
                                                                rows="10"
                                                                defaultValue={question.AnswerList}
                                                                onChange={(e) => saveRationaleQuestions(e, question.QuestionId, assessment.ID, assessment.Title)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <label className='mb-2 d-none d-lg-block'>&nbsp;</label>
                                                        <div className='help-tips mb-3'>
                                                            <div className='tip-header' onClick={() => toggleHide(idx)}>
                                                                <div>
                                                                    <span className='tip-icon'>
                                                                        <Icon icon="bulb" size={16} />
                                                                    </span>
                                                                    <span>
                                                                    {fetchTooltipLabel("Resources.Assessments.PrepareForAnInterview.CommonLabels.Tip")}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {!!hidden[idx] && <i className='icon-button-arrow rotate-arrow'></i> || <i className='icon-button-arrow'></i>}
                                                                </div>
                                                            </div>
                                                            {
                                                                !!hidden[idx] &&
                                                                <div className='help-tips-desc'>
                                                                    {fetchTooltipDescLabel("Resources.Assessments.PrepareForAnInterview.CommonLabels.ToolTipText")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>{fetchCommonLabel("CommonComponent.CommonCompleteButton")}</Cirrus.Button>
                                </Container>
                            )
                        }
                    })
                }
                {/* </Cirrus.Page>
                <Footer isLoggedIn={true} className="login-footer" /> */}
            </div>
            {
                uploadModal && <Cirrus.Modal
                    closeButtonProps={{
                        children: fetchCommonLabel("CommonComponent.CommonCancel"),
                        isSecondary: true,
                        onClick: uploadModalCloseHandler,
                        size: 'Small',
                        type: 'button',
                        disabled: context.uploadModalExitDisable
                    }}
                    headerProps={{
                        // children: "Upload a Job",
                        children: context.cmsPrepareForInterviewContent?.uploadModalTitle,
                        className: 'no-margin',
                        size: 'h5'
                    }}
                    isScrollable
                    role="dialog"
                    size="default"
                >
                    <MultipleFilesUpload cmsContent={cmsUploadDocuments} finishUploadHandler={finishUploadHandler} uploadModalFlag={uploadModalFlag}
                        extension={"Common.UploadProgressView"}
                        origin={"Resume"}
                    />
                </Cirrus.Modal>
            }

            {
                showDescriptionModal &&
                <Cirrus.Modal
                    className={savedJobRoleData?.jobDescription ? 'job-description-modal' : "job-document-modal"}
                    closeButtonProps={{
                        children: 'Exit',
                        isSecondary: true,
                        onClick: () => { closeDescModal() },
                        size: 'Small',
                        type: 'button'
                    }}
                    headerProps={{
                        children: 'Job description',
                        className: 'no-margin job-description',
                        size: 'h4'
                    }}
                    isScrollable
                    role="dialog"
                    size="default"
                >
                    <p>{savedJobRoleData?.jobDescription}</p>
                    {
                        embedFileType === "application/pdf" &&
                        <embed src={embedFile} type={embedFileType} width="100%" style={{ minHeight: '450px', maxHeight: '95%', height: '100%' }} />
                    }
                    {
                        embedFileType !== "application/pdf" &&
                        <div id="word-container"></div>
                    }
                </Cirrus.Modal>
            }
        </>
    )
}

export default PFAIAssessment;
