//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';
import { Link } from 'react-router-dom';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function ValuesAndDrivers() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsValuesAndDriversContent, setCmsValuesAndDriversContent] = useState({});
    const [startPageData, setStartPageData] = useState();
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setViewPortWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        fetchCMSData()
        fetchStartPageData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        let refCmsValuesAndDriversContent = JSON.parse(JSON.stringify(cmsValuesAndDriversContent));
        setScreenLoader(true);
        let payload = {
            PageName: "ValuesAndDrivers",
            Language: JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            MarketName: "US",
            Persona: "Default",
        };
        await AssessmentActions.GetAssessmentCMS(payload).then((res) => {
            if (res.status === 200) {
                let resData = res.data;
                ActivityAction.SaveActivity(resData?.ID, "startassessment", resData?.Title)
                TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                TrackActions.PageAction("ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                if (resData !== undefined) {
                    refCmsValuesAndDriversContent["browserTitle"] = resData.BrowserTitle;
                    refCmsValuesAndDriversContent["metaDescription"] = resData.MetaDescription;
                    refCmsValuesAndDriversContent["metadata"] = resData.Metadata;
                    refCmsValuesAndDriversContent["title"] = resData.Title;
                    refCmsValuesAndDriversContent["focusArea"] = resData.FocusArea[0];
                    refCmsValuesAndDriversContent["resourceType"] = resData.ResourceType;
                    refCmsValuesAndDriversContent["resourceCategoryType"] = resData.ResourceCategoryType;
                    refCmsValuesAndDriversContent["duration"] = resData.Duration;
                    refCmsValuesAndDriversContent["description"] = resData.Description;
                    refCmsValuesAndDriversContent["shortDescription"] = resData.ShortDescription;
                    refCmsValuesAndDriversContent["subTitle"] = resData.SubTitle;
                    refCmsValuesAndDriversContent["internalAssessmentId"] = resData.ID;
                    refCmsValuesAndDriversContent["externalAssessmentId"] = resData.ExternalId;
                    refCmsValuesAndDriversContent["commonLabels"] = resData.Components.CommonComponent.Labels;
                    refCmsValuesAndDriversContent["commonStatus"] = resData.Components.CommonComponent.LOVList[0].Values;
                    refCmsValuesAndDriversContent["commonResourceLabels"] = resData.Components.CommonResourceComponent.Labels;
                    refCmsValuesAndDriversContent["largeImage"] = resData.LargeImage.ImageUrl;
                    refCmsValuesAndDriversContent["mediumImage"] = resData.MediumImage;
                    refCmsValuesAndDriversContent["smallImage"] = resData.SmallImage;
                    refCmsValuesAndDriversContent["commonAnswer"] = resData?.Components["ValueandDrivers.CommonAnswers"]?.CommonAnswerList;

                    let clildPages = resData.ChildPages;
                    let resPage = {};
                    let revPage = {};
                    clildPages.filter((x) => {
                        if (x.Key === "Assessments.ValueAndDrivers.Review") {
                            revPage = x;
                        }
                        if (x.Key === "Assessments.ValueAndDrivers.Results") {
                            resPage = x;
                        }
                    })
                    refCmsValuesAndDriversContent["reviewPage"] = revPage;
                    refCmsValuesAndDriversContent["resultPage"] = resPage;
                    let questionPage = clildPages.filter(x => x.Key !== "Assessments.ValueAndDrivers.Review" && x.Key !== "Assessments.ValueAndDrivers.Results");
                    refCmsValuesAndDriversContent["assessmentQuestionSet"] = questionPage;
                    setCmsValuesAndDriversContent(refCmsValuesAndDriversContent);
                    context.setCmsValuesAndDriversContent(refCmsValuesAndDriversContent);
                    setScreenLoader(false);
                } else {
                    setScreenLoader(false);
                }
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const fetchStartPageData = async () => {
        let resourceType = "assessmentsandtasks";
        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "marketName": "Global",
            "language": "en-US",
            "persona": "Default",
            "ResourceCategory": resourceType
        }
        await AssessmentActions.GetStartPageData(payload).then((res) => {
            if (res.status === 200) {
                let resData = res?.data && res?.data?.responsemodel;
                let refData = resData?.filter(x => x.key === "Assessments.ValuesAndDrivers")
                setStartPageData(refData && refData[0] && refData[0]);
            }
        }, (err) => {
            console.log(err);
        });
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsValuesAndDriversContent));
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsValuesAndDriversContent));
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonStatus = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsValuesAndDriversContent));
        let label = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsValuesAndDriversContent));
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const saveLikeAssessment = (type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": cmsValuesAndDriversContent.internalAssessmentId,
            "learningResourceStatus": startPageData.learningResourceStatus,
            "learningResourceType": cmsValuesAndDriversContent.resourceCategoryType?.ID,
            "resourceTypeId": cmsValuesAndDriversContent.resourceType?.ID,
            "isSaved": type === "save" ? startPageData.isSaved == true ? false : true : startPageData.isSaved,
            "isLiked": type === "like" ? startPageData.isLiked == true ? false : true : startPageData.isLiked,
            "lastAnsweredPageId": startPageData.lastAnsweredPageId,

            "isDownloaded": startPageData?.isDownloaded,
            "isSharedWithCoach": startPageData?.isSharedWithCoach,
            "resultDocumentId": startPageData?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(cmsValuesAndDriversContent.resourceCategoryType?.ID, cmsValuesAndDriversContent.internalAssessmentId, payload).then(res => {
            if (res.status === 200) {
                fetchStartPageData();
            }
        }, err => {
            console.log(err);
        })
    }

    const updateAssessmentStatus = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setScreenLoader(true)
        if (startPageData?.learningResourceStatus === fetchStatusID("ResourceStatus.Completed")) {
            AssessmentActions.RetakeAssessment(cmsValuesAndDriversContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsValuesAndDriversContent?.internalAssessmentId, "InternalAssessmentName": cmsValuesAndDriversContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsValuesAndDriversContent?.internalAssessmentId, "InternalAssessmentName": cmsValuesAndDriversContent?.title })
                    setScreenLoader(false);
                    navigate("/" + pageOrgin.id + "/values-and-drivers/assessment")
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        } else {
            let payload = {
                "candidateId": candidateID,
                "learningResourceId": cmsValuesAndDriversContent.internalAssessmentId,
                "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
                "learningResourceType": cmsValuesAndDriversContent.resourceCategoryType?.ID,
                "resourceTypeId": cmsValuesAndDriversContent.resourceType?.ID,
                "isSaved": startPageData.isSaved,
                "isLiked": startPageData.isLiked,
                "lastAnsweredPageId": startPageData?.lastAnsweredPageId,

                "isDownloaded": startPageData?.isDownloaded,
                "isSharedWithCoach": startPageData?.isSharedWithCoach,
                "resultDocumentId": startPageData?.resultDocumentId
            }
            AssessmentActions.SaveAssessmentStatus(cmsValuesAndDriversContent.resourceCategoryType?.ID, cmsValuesAndDriversContent.internalAssessmentId, payload).then(async (res) => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsValuesAndDriversContent?.internalAssessmentId, "InternalAssessmentName": cmsValuesAndDriversContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsValuesAndDriversContent?.internalAssessmentId, "InternalAssessmentName": cmsValuesAndDriversContent?.title })
                    setScreenLoader(false)
                    navigate("/" + pageOrgin.id + "/values-and-drivers/assessment")
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        }
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            {cmsValuesAndDriversContent &&
                <>
                    <Container fluid className='background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20'>
                        <Row className='justify-content-between'>
                            <Col md="12" lg="7" className='mb-3 pb-lg-5'>
                                <Cirrus.Button isSecondary onClick={() => { navigate(`/${context.path ? context.path : path}`) }} className="mb-lg-5 mb-3">
                                    {fetchCommonLabel("CommonComponent.CommonBackButton")}
                                </Cirrus.Button>
                                <Cirrus.Header size='h3' className='assessment-title'>{cmsValuesAndDriversContent?.title}</Cirrus.Header>
                                <div className='save-section mt-4 mb-3'>
                                    <button className='saveBtn'
                                        onClick={() => { saveLikeAssessment("save") }}
                                    >
                                        {startPageData?.isSaved === true ? <i className='icon-saved'></i> : <i className='icon-save'></i>}
                                        <span className='saveBtnText'>
                                            {startPageData?.isSaved === true ? fetchCommonResourceLabel("CommonResourceComponent.Saved") : fetchCommonLabel("CommonComponent.CommonSaveButton")}
                                        </span>
                                    </button>
                                    <Cirrus.Label id='category-label'>{cmsValuesAndDriversContent?.focusArea?.Value}</Cirrus.Label>
                                    <Cirrus.Label id="cardtype">
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                            <>
                                                {cmsValuesAndDriversContent?.resourceType?.Value} <sup>.</sup>
                                                <span>{cmsValuesAndDriversContent?.duration}</span>
                                            </>
                                        }

                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.InProgress")}
                                            </>
                                        }
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.Completed")}
                                            </>
                                        }
                                    </Cirrus.Label>
                                </div>
                                <div>
                                    <Cirrus.Button size='small' className='likeBtn'
                                        onClick={() => { saveLikeAssessment("like") }}
                                    >
                                        {startPageData?.isLiked === true ? <Icon icon="heart_fill" size={16} color="#9D323D" /> : <Icon icon="heart" size={16} color="#000" />}
                                        <span className='likeBtnText'>
                                            {startPageData?.likeCount <= 1 ? startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() : startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()}
                                        </span>
                                    </Cirrus.Button>
                                </div>
                            </Col>
                            <Col md="12" lg="5" className='position-relative'>
                                <div className='assesment_startpage-image-wrapper'>
                                    <img src={cmsValuesAndDriversContent.largeImage} alt='sideimage' className='image-overflow' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='p-lg-5 p-3 pb-0'>
                        <Row>
                            <Col md="12" lg="6" className='assessment_second-container-intro'>
                                <Cirrus.Headline size='Medium' className='mb-5 assessment_introHeading'>{cmsValuesAndDriversContent?.shortDescription}</Cirrus.Headline>
                                <Cirrus.Header size='h5' className='mb-3'>{cmsValuesAndDriversContent?.subTitle}</Cirrus.Header>
                                <p className='mb-3'>
                                    <Cirrus.RichText content={cmsValuesAndDriversContent?.description} className="instructions" />
                                </p>

                                <Cirrus.Button className='my-4'
                                    onClick={() => {
                                        if (viewPortWidth <= 992) {
                                            startPageData?.learningResourceStatus == "inprogress" && startPageData?.lastAnsweredPageId == "52" ? navigate("/" + pageOrgin.id + "/values-and-drivers/review-mobile-view") : updateAssessmentStatus()
                                        } else {
                                            startPageData?.learningResourceStatus == "inprogress" && startPageData?.lastAnsweredPageId == "52" ? navigate("/" + pageOrgin.id + "/values-and-drivers/review") : updateAssessmentStatus()
                                        }
                                    }}>
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonStartButton")}
                                        </>
                                    }

                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonContinueButton")}
                                        </>
                                    }
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                        <>
                                            {fetchCommonLabel("CommonComponentCommonRetakeButton")}
                                        </>
                                    }
                                </Cirrus.Button>
                                {/* <Cirrus.Button onClick={()=> navigate("/" + pageOrgin.id + "/values-and-drivers/review-mobile-view")}>
                                        Review Page
                                    </Cirrus.Button> */}
                            </Col>
                            <Col md="12" lg="6">

                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    );
}

export default ValuesAndDrivers;
