//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../../common/helpers/AuthService";
import { MyProfileActions } from "./MyProfileActions";
import PhoneInput from "react-phone-number-input";
import Spinner from "../../common/components/spinner-loader/Spinner";
import "./index.css";
import { Context } from "../../common/context/Context";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { globalDateFormat } from "../../common/helpers/DateTimeHelper";
import { getSatelliteCountryCode } from "../../common/helpers/CountryCode";
import { returnCountryLanguagePersona } from "../../common/helpers/CountryLanguage";

function MyProfile(progileprops) {
  const userLang = JSON.parse(window.sessionStorage.getItem("Language"));
  const sDefaultCountryCode = userLang?.selectedLanguageISOCode?.split("-")[1];
  const navigate = useNavigate();
  const { instance } = useMsal();
  const navigateTo = (sURL) => {
    if (sURL != "") {
      navigate(sURL);
    }
  };
  const context = useContext(Context);
  const [pageLoader, setPageLoader] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [bIsFormInValid, setIsFormInValid] = useState(true);
  const [bPRIsFormInValid, setPRIsFormInValid] = useState(true);
  const [bProgramVideo, setProgramVideo] = useState(false);
  const [bProgramVideoButtonFlag, setProgramVideoButtonFlag] = useState(false);
  const [bIsPageLoad, setIsPageLoad] = useState(true);
  const [contactPreferencesEmailList, setContactPreferencesEmailList] =
    useState([]);
  const [contactPreferencesSMSDisable, setcontactPreferencesSMSDisable] =
    useState(true);
  const [contPrefEmailState, setcontPrefEmailState] = useState(false);
  const [displayContactPrefNotiAlert, setdisplayContactPrefNotiAlert] =
    useState(false);
  const [personalInfoLables, setpersonalInfoLables] = useState({});
  const [personalInfoPronouns, setpersonalInfoPronouns] = useState([]);
  const [platformSettingLables, setplatformSettingLables] = useState({});
  const [platformSettingTimezone, setplatformSettingTimezone] = useState([]);
  const [platformSettingLang, setplatformSettingLang] = useState([]);
  const [programDetailsLables, setprogramDetailsLables] = useState({});
  const [contactPreferenceLables, setcontactPreferenceLables] = useState({});
  const [privarcyPreferenceLables, setprivarcyPreferenceLables] = useState({});
  const [selectCountrycode, setselectCountrycode] = useState("");
  const appInsights = useAppInsightsContext();
  const [myProfileCMSData, setMyProfileCMSData] = useState();
  const [countryCode, setCountryCode] = useState();
  const [rightOffices, setRightOffices] = useState([]);
  const [rightOfficeId, setRightOfficeId] = useState();
  const [contact, setContact] = useState(0);
  const [videoURL, setVideoURL] = useState("");
  const [preferredNumberDisabled, PreferredNumberDisabled] = useState(true);
  const [noClientSpecificContent, setNoClientSpecificContent] = useState(false);
 
  const fnLoadMyProfileCSMData = async () => {
    try {
      // setScreenLoader(true)
      let sPersona = "Default";
      if (window.sessionStorage.getItem("setProfileDetails") != null) {
        sPersona = JSON.parse(
          window.sessionStorage.getItem("setProfileDetails")
        )["personaName"];
      }
      let cmsmyprofilepayload = {
        PageName: "MyProfile",
      };
      const res = await MyProfileActions.GetCMSMyProfileheaderLabels(
        cmsmyprofilepayload
      );
      if (res.status === 200) {
        // setScreenLoader(false)
        setIsFormInValid(true);
        setPRIsFormInValid(true);
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchCMSLabel = (key, ssection) => {
    let label = "";
    if (ssection != undefined) {
      let refCmsContent = JSON.parse(JSON.stringify(ssection));
      if (refCmsContent?.Labels) {
        refCmsContent?.Labels.map((x) => {
          if (x.Key === key) {
            label = x.Phrase;
          }
        });
      }
    }
    return label;
  };

  const FetchVideo = async () => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";

    let payload = {
      MarketName: ProgramData ? ProgramData.programCountryCode : "US",
      Language: ProgramData ? ProgramData.programLanguageCode : "en-US",
      ProgramClassIds: ProgramData?.programClassId
        ? [(ProgramData?.programClassId).toString()]
        : ["19"],
    };
    await MyProfileActions.GetVideoURL(payload).then((res) => {
      if (res.status == 200 && res?.data.length > 0) {
        setVideoURL(res?.data && res?.data[0]);
      } else if (res?.data.length <= 0 || res.status == 204) {
        setNoClientSpecificContent(true);
      }
    });
  };

  const fetchCMSLinkTextURL = (ssection, sFields) => {
    let label = "";
    if (Object.keys(ssection).length > 0) {
      if (ssection?.LinkList[0]?.Link) {
        label = ssection?.LinkList[0]?.Link[sFields];
        return label;
      }
    }
  };

  const fnValidForm = (nFlag) => {
    if (nFlag === 0) {
      if (bIsPageLoad) {
        return true;
      } else if (
        PersonalInfo.PreferredName?.FieldValue?.length === 0 &&
        PersonalInfo?.Pronouns?.FieldValue?.length === 0 &&
        PersonalInfo.SecondaryEmail?.FieldValue?.length === 0 &&
        PersonalInfo.PreferredNumber?.FieldValue?.length === 0
      ) {
        return true;
      } else if (
        PersonalInfo?.PreferredNumber?.status === "error" ||
        PersonalInfo?.SecondaryEmail?.status === "error"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (nFlag === 1) {
      if (bIsPageLoad) {
        return true;
      } else if (
        ProfileSetting?.Timezone?.FieldValue?.length === 0 &&
        ProfileSetting?.CityResidence?.FieldValue?.length === 0 &&
        ProfileSetting?.PreferedLanguage?.FieldValue?.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const fnSaveProfileData = async (nflag, event) => {
    //setContact(PersonalInfo.PreferredNumber.FieldValue.replace(/[^\d]/g, ''));
    event.preventDefault();

    let pLang = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))[
      "preferredLanguageCode"
    ];
    if (nflag === 2 && pLang !== ProfileSetting.PreferedLanguage.FieldValue) {
      setScreenLoader(true);
    } else {
      setPageLoader(true);
    }

    nflag === 1 ? setPRIsFormInValid(true) : setIsFormInValid(true);
    let objSettingUpdateJSON = {};
    // objSettingUpdateJSON["id"] = PersonalInfo.id
    objSettingUpdateJSON["firstName"] = PersonalInfo.FirstName.FieldValue;
    objSettingUpdateJSON["lastName"] = PersonalInfo.LastName.FieldValue;
    objSettingUpdateJSON["preferredName"] =
      PersonalInfo.PreferredName.FieldValue;
    objSettingUpdateJSON["pronouns"] = PersonalInfo.Pronouns.FieldValue;
    objSettingUpdateJSON["emailAddress"] = PersonalInfo.Email.FieldValue;
    objSettingUpdateJSON["secondaryEmailAddress"] =
      PersonalInfo.SecondaryEmail.FieldValue;
    objSettingUpdateJSON["preferredContactNo"] = PersonalInfo.PreferredNumber
      .FieldValue
      ? selectCountrycode + " " + contact
      : "";
    // objSettingUpdateJSON["languageCode"] = ProfileSetting.PreferedLanguage.FieldValue
    objSettingUpdateJSON["preferredLanguageCode"] =
      ProfileSetting.PreferedLanguage.FieldValue;
    objSettingUpdateJSON["cityOfResidence"] = cityList?.value
      ? cityList?.value
      : ProfileSetting.CityResidence.FieldValue;
    objSettingUpdateJSON["timeZone"] = ProfileSetting.Timezone.FieldValue;
    // objSettingUpdateJSON["rightOfficeName"] = (rightOfficeId && rightOffices) ? rightOffices.filter(rightOffice => rightOffice.id === rightOfficeId)[0].name : ""
    objSettingUpdateJSON["rightOfficeName"] = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    )["rightOfficeName"];

    //let sUserID = "FCAFF40D-318B-EC11-A507-501AC5E8D21A"
    let sUserID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
            "candidateID"
          ];
    const res = await MyProfileActions.SavecandidateProfile(
      sUserID,
      objSettingUpdateJSON
    );

    if (res.status === 204) {
      nflag === 1 ? setPRIsFormInValid(true) : setIsFormInValid(true);
      setPageLoader(false);
      fnSaveContactPreference(event);
      setcontactPreferencesSMSDisable(
        PersonalInfo.PreferredNumber.FieldValue.length ? false : true
      );
      setdisplayContactPrefNotiAlert(false);
      let arrCONPREEmailList = [];
      if (PersonalInfo.Email.FieldValue.length > 0) {
        arrCONPREEmailList.push({
          name: PersonalInfo.Email.FieldValue,
          value: "Primary",
        });
      }
      if (PersonalInfo.SecondaryEmail.FieldValue.length > 0) {
        arrCONPREEmailList.push({
          name: PersonalInfo.SecondaryEmail.FieldValue,
          value: "Secondary",
        });
      }

      if (PersonalInfo.PreferredNumber.FieldValue !== "") {
        PreferredNumberDisabled(false);
      } else {
        let objTempSMS = {
          FieldValue: "",
          status: "",
          errorMsg: "",
          FieldSMSCheck: false,
        };
        setcontactPreferences({
          ...contactPreferences,
          preferenceTypeSMS: objTempSMS,
          preferredEmailAddress: "Primary",
          Loading: false,
          error: false,
        });
        PreferredNumberDisabled(true);
      }

      setIsFormInValid(true);
      setPRIsFormInValid(true);
      setScreenLoader(false);
      let pLang = JSON.parse(
        window.sessionStorage.getItem("setProfileDetails")
      )["preferredLanguageCode"];
      if (nflag === 2 && pLang !== ProfileSetting.PreferedLanguage.FieldValue) {
        let refLanguage = JSON.parse(window.sessionStorage.getItem("Language"));
        window.sessionStorage.setItem(
          "Language",
          JSON.stringify({
            selectedCountryFullName: refLanguage.selectedCountryFullName,
            selectedCountryShortName: refLanguage.selectedCountryShortName,
            selectedLanguageISOCode: ProfileSetting.PreferedLanguage.FieldValue,
          })
        );
        sessionStorage.removeItem("headerCache");
        sessionStorage.removeItem("footerCache");
        context.setLanguageChanged(!context.languageChanged);
        context.setLanguage({
          selectedCountryFullName: refLanguage.selectedCountryFullName,
          selectedCountryShortName: refLanguage.selectedCountryShortName,
          selectedLanguageISOCode: ProfileSetting.PreferedLanguage.FieldValue,
        });
      }

      setContactPreferencesEmailList(arrCONPREEmailList);

      if (nflag === 2 && pLang === ProfileSetting.PreferedLanguage.FieldValue) {
        let sUserID =
          context?.idTokenClaims?.signupId != undefined
            ? context?.idTokenClaims?.signupId
            : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
                "candidateID"
              ];
        let resData;
        if (sUserID != "") {
          const res = await MyProfileActions.GetcandidateProfile(sUserID);
          if (res.status === 200) {
            window.sessionStorage.setItem(
              "setProfileDetails",
              JSON.stringify(res.data)
            );
            resData = res.data;
          }
        }
        let suggestions = [];
        setCityList({
          suggestions,
          value: resData?.cityOfResidence,
          noData: false,
        });
        setCityInput(resData?.cityOfResidence);
      }
    } else {
      setPageLoader(false);
    }
  };

  //// ******************** Personal INfor related code

  const [PersonalInfo, setPersonalInfo] = useState({
    CandidateID: { FieldValue: "", status: "", errorMsg: "" },
    FirstName: { FieldValue: "", status: "", errorMsg: "" },
    LastName: { FieldValue: "", status: "", errorMsg: "" },
    PreferredName: { FieldValue: "", status: "", errorMsg: "" },
    Pronouns: { FieldValue: "", status: "" },
    Email: { FieldValue: "", status: "", errorMsg: "" },
    SecondaryEmail: { FieldValue: "", status: "", errorMsg: "" },
    PreferredNumber: { FieldValue: "", status: "", errorMsg: "" },
    Loading: true,
    Error: false,
  });

  const fnLoadPersonalInfoData = async () => {
    try {
      // setScreenLoader(true)
      setPageLoader(true);
      let sUserID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
              "candidateID"
            ];
      let Profilejson = {};
      if (sUserID != "") {
        const res = await MyProfileActions.GetcandidateProfile(sUserID);
        if (res.status === 200) {
          Profilejson = res.data;
          window.sessionStorage.setItem(
            "setProfileDetails",
            JSON.stringify(Profilejson)
          );
          let suggestions = [];
          setCityList({
            suggestions,
            value: Profilejson?.cityOfResidence,
            noData: false,
          });
          setCityInput(Profilejson?.cityOfResidence);
          setRightOfficeId(Profilejson?.rightOfficeId);
        } else {
          Profilejson = {};
        }
      } else {
        Profilejson = {};
      }
      return Profilejson;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    let objCMSData = fnLoadMyProfileCSMData();
    objCMSData.then((res) => {
      if (Object.keys(res).length > 0) {
        let arrPronous = [];
        setMyProfileCMSData(res && res?.Pages[0]);
        setpersonalInfoLables(
          res && res?.Pages[0]?.Components["MyProfilePage.PersonalInformation"]
        );
        setplatformSettingLables(
          res && res?.Pages[0]?.Components["MyProfilePage.PlatformSettings"]
        );
        setprogramDetailsLables(
          res && res?.Pages[0]?.Components["MyProfilePage.ProgrammeDetails"]
        );
        setcontactPreferenceLables(
          res && res?.Pages[0]?.Components["MyProfilePage.ContactPreference"]
        );
        setprivarcyPreferenceLables(
          res && res?.Pages[0]?.Components["MyProfilePage.PrivacyPreferences"]
        );

        res &&
          res?.Pages[0]?.Components[
            "MyProfilePage.PersonalInformation"
          ]?.LOVList[0]?.Values?.map((ele) => {
            arrPronous.push({
              name: ele.Value,
              value: ele.ID,
            });
          });
        setpersonalInfoPronouns(arrPronous);

        let arrTimezone = [];
        let masterTimeZone = JSON.parse(
          window.sessionStorage.getItem("MasterTimezoneDetails")
        );
        masterTimeZone?.filter((item) => {
          if (item.key === "timezone") {
            item?.items?.map((tz) => {
              arrTimezone.push({
                name: tz.title,
                value: tz.id,
              });
            });
          }
        });

        const fetchLabel = (key) => {
          let label = "";
          let refCmsContent = JSON.parse(JSON.stringify(platformSettingLables));
          if (refCmsContent?.Labels) {
            refCmsContent?.Labels.map((x) => {
              if (x.Key === key) {
                label = x.Phrase;
              }
            });
          }
          return label;
        };

        arrTimezone.unshift({
          name: fetchLabel(
            "MyProfilePage.PlatformSettings.Select",
            platformSettingLables
          ),
          value: "",
        });
        setplatformSettingTimezone(arrTimezone);
        //********************************************** */
        // let arrCities = []
        // res && res?.MarketDetails[0]?.CityOfResidences.map((ele) => {
        //     arrCities.push({
        //         "name": ele.Value,
        //         "value": ele.Key
        //     })
        // })
        // setplatformSettingCities(arrCities)
        // //********************************************** */
        let arrLangs = [];
        res &&
          res?.MarketDetails[0]?.Supportedlanguage.map((ele) => {
            arrLangs.push({
              name: ele.LanguageName,
              value: ele.ISOCode,
            });
          });
        // arrLangs.unshift({ name: "Select", value: "" })
        setplatformSettingLang(arrLangs);
      }
    });

    try {
      let objData = fnLoadPersonalInfoData();
      objData
        .then((res) => {
          if (Object.keys(res).length > 0) {
            if (res.preferredContactNo) {
              setselectCountrycode(
                String(res.preferredContactNo).split(" ")[0]
              );
              setContact(String(res.preferredContactNo).split(" ")[1]);
            } else {
              setselectCountrycode("+1");
            }
            if (
              res.preferredContactNo !== null &&
              res.preferredContactNo !== undefined &&
              res.preferredContactNo !== ""
            ) {
              PreferredNumberDisabled(false);
            }
            setPersonalInfo({
              ...PersonalInfo,
              CandidateID: { FieldValue: res.id, status: "", errorMsg: "" },
              FirstName: {
                FieldValue: res.firstName,
                status: "",
                errorMsg: "",
              },
              LastName: { FieldValue: res.lastName, status: "", errorMsg: "" },
              PreferredName: {
                FieldValue: res.preferredName,
                status: "",
                errorMsg: "",
              },
              Pronouns: { FieldValue: res.pronouns, status: "", errorMsg: "" },
              Email: { FieldValue: res.emailAddress, status: "", errorMsg: "" },
              SecondaryEmail: {
                FieldValue: res.secondaryEmailAddress
                  ? res.secondaryEmailAddress
                  : "",
                status: "",
                errorMsg: "",
              },
              PreferredNumber: {
                FieldValue: res.preferredContactNo,
                status: "",
                errorMsg: "",
              },
              Loading: false,
              error: false,
            });
            setProfileSetting({
              ...ProfileSetting,
              Timezone: { FieldValue: res.timeZone, status: "", errorMsg: "" },
              CityResidence: {
                FieldValue: res.cityOfResidence,
                status: "",
                errorMsg: "",
              },
              PreferedLanguage: {
                FieldValue: res.preferredLanguageCode,
                status: "",
                errorMsg: "",
              },
              Loading: false,
              error: false,
            });
            let sSecondaryEmail = res.secondaryEmailAddress
              ? res.secondaryEmailAddress
              : "";
            fnSetContactPrefOptions(
              res.emailAddress,
              sSecondaryEmail,
              res.preferredContactNo
            );
            setCountryCode(res.preferredContactNo.slice(0, -10));
            // setPageLoader(false);
            // setScreenLoader(false)
            setIsFormInValid(true);
            setPRIsFormInValid(true);
          } else {
            setProfileSetting({
              ...ProfileSetting,
              Error: true,
              Loading: false,
            });
            setPersonalInfo({
              ...PersonalInfo,
              Error: true,
              Loading: false,
            });
            setPageLoader(false);

            // setScreenLoader(false)
            setIsFormInValid(true);
            setPRIsFormInValid(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setProfileSetting({
            ...ProfileSetting,
            Error: true,
            Loading: false,
          });
          setPersonalInfo({
            ...PersonalInfo,
            Error: true,
            Loading: false,
          });
          setPageLoader(false);
        });
    } catch (error) {
      setProfileSetting({
        ...ProfileSetting,
        Error: true,
        Loading: false,
      });
      setPersonalInfo({
        ...PersonalInfo,
        Error: true,
        Loading: false,
      });
      setPageLoader(false);
    }

    let objPrgramData = fngetProgramData();
    objPrgramData.then((resProg) => {
      if (Object.keys(resProg).length > 0) {
        let bButtonflag = resProg.programName?.length === 0 ? true : false;
        setRightOffices(resProg?.rightOffices);
        setProgramVideoButtonFlag(bButtonflag);
        setProgramInfo({
          ...ProgramInfo,
          programName: { FieldValue: resProg.programName },
          programEndDate: {
            FieldValue: globalDateFormat(resProg.programEndDate),
          },
          programCountryCode: { FieldValue: resProg.programCountryCode },
          Loading: false,
          error: false,
        });

        // setScreenLoader(false)
        setIsFormInValid(true);
        setPRIsFormInValid(true);
      } else {
        setProgramVideoButtonFlag(true);
      }
    });

    fnContPrefUITaggng();
    fngetCandidatePrivacyConsent();
    setPageLoader(false);
    let objSelect = document.getElementsByClassName("PhoneInputCountrySelect");
    if (objSelect.length > 0) {
      objSelect[0].addEventListener("click", function (e) {
        setselectCountrycode(
          document.getElementsByClassName("PhoneInputInput")[0].value
        );
        setIsPageLoad(false);
      });
    }
  }, [context?.language]);

  useEffect(() => {
    let bFormInValid = fnValidForm(0);
    setPRIsFormInValid(bFormInValid);
  }, [PersonalInfo, selectCountrycode]);

  let inputsPIHandler = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let objData = { FieldValue: value };

    // const regexPhone = /^(\+\d{1,3}[- ]?)?\d{10}$/i;
    // const regexPhone = /^\d{10}$/
    const regexPhone = /^[0-9][0-9]{7,14}$/;
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPreferredName = /^[a-zA-Z\s]*$/;
    switch (name) {
      case "PreferredName":
        if (value.length > 0) {
          objData.status =
            regexPreferredName.test(value) === false ? "error" : "";
          objData.errorMsg =
            regexPreferredName.test(value) === false
              ? "Allowed characters only "
              : "";
        }
        break;
      case "SecondaryEmail":
        if (value.length > 0) {
          objData.status = regexEmail.test(value) === false ? "error" : "";
          objData.errorMsg = fetchCMSLabel(
            "MyProfilePage.PersonalInformation.InvalidEmailEntered",
            personalInfoLables
          );
        }
        break;
      case "PreferredNumber":
        if (value.length > 0) {
          value = value.replace(/[^\d]/g, "");
          setContact(value);
          if (value.length < 7 || !regexPhone.test(value)) {
            objData.status = "error";
            objData.errorMsg = objData.status
              ? fetchCMSLabel(
                  "MyProfilePage.PersonalInformation.InvalidPhoneNumberEntered",
                  personalInfoLables
                )
              : "";
          }
        }
        break;
      default:
        objData["status"] = "";
        objData["errorMsg"] = "";
        break;
    }
    setPersonalInfo({ ...PersonalInfo, [name]: objData });

    setIsPageLoad(false);
  };

  const appendPhone = () => {
    let parentNode = document.querySelector(".phoneNumberComponent");
    let newComponent = document.querySelector("#phoneNumber");
    parentNode.replaceChild(newComponent, parentNode.firstElementChild);
    parentNode.removeChild(parentNode.childNodes[1]);
  };

  //// ******************** Program INfor related code
  const [ProgramInfo, setProgramInfo] = useState({
    programEndDate: { FieldValue: "" },
    programName: { FieldValue: "" },
    programCountryCode: { FieldValue: "" },
    Loading: true,
    Error: false,
  });

  const programEndDate = JSON.parse(
    window.sessionStorage.getItem("setProgramDetails")
  )["programEndDate"];

  // console.log("programEndDate=", programEndDate);

  const fngetProgramData = async (userID) => {
    try {
      // setScreenLoader(true)

      let sUserEmail = JSON.parse(
        window.sessionStorage.getItem("LoginCandidateToken")
      )["registrationCode"];
      let Programjson = {};
      if (sUserEmail != "") {
        const res = await MyProfileActions.GetcandidateProgramDetails(
          sUserEmail
        );
        if (res.status === 200) {
          Programjson = res.data;
        } else {
          Programjson = {};
        }
      } else {
        Programjson = {};
      }
      return Programjson;
    } catch (error) {
      return {};
    }
  };

  const fnOpenCloseProgramVideo = (nflag) => {
    if (nflag === 0) {
      setProgramVideo(true);
    } else {
      setProgramVideo(false);
    }
  };

  //// ******************** My profile Setting related code
  const [ProfileSetting, setProfileSetting] = useState({
    Timezone: { FieldValue: "", status: "", errorMsg: "" },
    CityResidence: { FieldValue: "", status: "", errorMsg: "" },
    PreferedLanguage: { FieldValue: "", status: "", errorMsg: "" },
    Loading: true,
    Error: false,
  });

  const [cityList, setCityList] = useState({
    value: "",
    suggestions: [],
    noData: false,
  });
  const [cityInput, setCityInput] = useState("");

  useEffect(() => {
    let bFormInValid = fnValidForm(1);
    setIsFormInValid(bFormInValid);
  }, [ProfileSetting]);

  useEffect(() => {
    FetchVideo();
    TrackActions.PageViewRecord(appInsights, "Profile");
    TrackActions.PageAction("Profile");
  }, []);

  const onChangeHandler = (event) => {
    const value = event.target.value;
    let suggestions = [];
    setCityInput(value);
    if (value === "") {
      setCityList({ suggestions, value: "", noData: false });
    }
    if (value.length > 0) {
      setCityList({ suggestions, value: value, noData: false });
    }
    if (value.length >= 3) {
      const satelliteCountryCode = getSatelliteCountryCode();
      const inputTextCountryCode =
        satelliteCountryCode === "UK" ? "GB" : satelliteCountryCode;
      const payload = {
        field_to_query: "city",
        input_text: inputTextCountryCode + " " + value,
        country: satelliteCountryCode,
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
      };
      MyProfileActions.TKcityList(payload).then((res) => {
        res?.data?.auto_suggest?.map((item) => {
          let obj = {
            ID: item.item,
            Name: item.item,
            Value: item.item,
          };
          if (item?.item) {
            suggestions.push(obj);
          }
        });

        if (suggestions.length > 0) {
          setCityList({ suggestions, value: value, noData: false });
        } else {
          setCityList({ suggestions, value: value, noData: true });
        }
      });
    }
  };

  const selectItemHandler = (event, data) => {
    setIsFormInValid(false);
    setCityInput(data.Name);
    setCityList({
      value: data.Name,
      suggestions: [],
      noData: false,
    });
  };

  let selectoptionHandler = (e) => {
    e.preventDefault();

    const { name, value } = e.currentTarget;
    let objData = { FieldValue: value };
    let bFormInValid = false;
    switch (name) {
      case "Timezone":
        // objData.status = value.length === 0 ? "error" : ""
        // objData.errorMsg = value.length === 0 ? "Please select the timezone" : ""
        bFormInValid = value.length === 0 ? true : false;
        break;
      case "PreferedLanguage":
        // objData.status = value.length === 0 ? "error" : ""
        // objData.errorMsg = value.length === 0 ? "Please select the Langauge" : ""
        bFormInValid = value.length === 0 ? true : false;
        break;
      default:
        break;
    }

    setProfileSetting({ ...ProfileSetting, [name]: objData });
    setIsPageLoad(false);
  };

  //// ******************** My profile Contact Preference related code
  const [contactPreferences, setcontactPreferences] = useState({
    preferenceTypeEmail: {
      FieldValue: "EMAIL",
      status: "",
      errorMsg: "",
      FieldEmailCheck: false,
    },
    preferenceTypeSMS: {
      FieldValue: "SMS",
      status: "",
      errorMsg: "",
      FieldSMSCheck: false,
    },
    preferredEmailAddress: "Primary",
    Loading: true,
    Error: false,
  });

  const [contactPrefSaveButton, setcontactPrefSaveButton] = useState(true);

  const fngetContactPreference = async () => {
    try {
      let sCandidateID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
              "candidateID"
            ];
      // let sCandidateID = "A016D264-318B-EC11-A507-501AC5E8D21B"
      let contactPreferencejson = {};
      if (sCandidateID != "") {
        const res = await MyProfileActions.GetcandidateContactPreference(
          sCandidateID
        );
        if (res.status === 200) {
          contactPreferencejson = res.data;
        }
      }
      return contactPreferencejson;
    } catch (error) {
      return {};
    }
  };

  const fnContPrefUITaggng = () => {
    let objContactPerference = fngetContactPreference();
    objContactPerference.then((resContPref) => {
      if (resContPref.length > 0) {
        let objTempEmail = {};
        let objTempSMS = {};
        let sPreferedEmail = "";
        let objEmailResult = resContPref.filter(
          (eleConf) =>
            String(eleConf.preferenceType).toString().toUpperCase() === "EMAIL"
        );
        if (objEmailResult.length > 0) {
          objTempEmail = {
            FieldValue: "EMAIL",
            status: "",
            errorMsg: "",
            FieldEmailCheck: objEmailResult[0].hasOptedforNotification,
          };
          sPreferedEmail = objEmailResult[0].preferredEmailAddress;
        }

        let objSMSResult = resContPref.filter(
          (eleConf) =>
            String(eleConf.preferenceType).toString().toUpperCase() === "SMS"
        );

        if (objSMSResult.length > 0) {
          objTempSMS = {
            FieldValue: "SMS",
            status: "",
            errorMsg: "",
            FieldSMSCheck: objSMSResult[0].hasOptedforNotification,
          };
        }

        setcontactPreferences({
          ...contactPreferences,
          preferenceTypeEmail: objTempEmail,
          preferenceTypeSMS: objTempSMS,
          preferredEmailAddress: sPreferedEmail,
          Loading: false,
          error: false,
        });
      }
    });
  };

  const fnContPrefMouseOver = (event) => {
    if (
      PersonalInfo &&
      PersonalInfo.PreferredNumber &&
      PersonalInfo?.PreferredNumber?.FieldValue == ""
    ) {
      setdisplayContactPrefNotiAlert(true);
    }
  };

  const fnSetContactPrefOptions = (
    sEmailAddress,
    sSecondaryEmail,
    sSecondaryContact
  ) => {
    let arrCONPREEmailList = [];
    if (sEmailAddress?.length > 0) {
      arrCONPREEmailList.push({ name: sEmailAddress, value: "Primary" });
    }
    if (sSecondaryEmail?.length > 0) {
      arrCONPREEmailList.push({ name: sSecondaryEmail, value: "Secondary" });
    }
    setContactPreferencesEmailList(arrCONPREEmailList);
    if (sSecondaryContact?.length === 0) {
      setcontactPreferencesSMSDisable(true);
    }
  };

  const closeAlert = () => {
    setdisplayContactPrefNotiAlert(false);
  };

  const handlerContPrefNotification = (e) => {
    //e.preventDefault();
    const { name, checked, value } = e.target;

    switch (name) {
      case "notificationsMethodEmail":
        let objTempEmail = {
          FieldValue: value,
          status: "",
          errorMsg: "",
          FieldEmailCheck: e.target.checked,
        };
        setcontactPreferences({
          ...contactPreferences,
          preferenceTypeEmail: objTempEmail,
          preferredEmailAddress: "Primary",
          Loading: false,
          error: false,
        });
        break;
      case "notificationsMethodSms":
        let objTempSMS = {
          FieldValue: value,
          status: "",
          errorMsg: "",
          FieldSMSCheck: e.target.checked,
        };
        setcontactPreferences({
          ...contactPreferences,
          preferenceTypeSMS: objTempSMS,
          preferredEmailAddress: "Primary",
          Loading: false,
          error: false,
        });
        break;
      case "preferredEmail":
        setcontactPreferences({
          ...contactPreferences,
          preferredEmailAddress: value,
          Loading: false,
          error: false,
        });
      default:
        // objData['status'] = ''
        //     objData['errorMsg'] = ''
        break;
    }

    setcontactPrefSaveButton(false);
    let sChecked = checked ? false : true;
    setcontPrefEmailState(sChecked);
  };

  const fnSaveContactPreference = async (event) => {
    event.preventDefault();
    setPageLoader(true);
    setcontactPrefSaveButton(true);
    let sCandidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
            "candidateID"
          ];
    // let sCandidateID = "A016D264-318B-EC11-A507-501AC5E8D21A"

    let arrData = [];
    arrData.push({
      CandidateId: sCandidateID,
      PreferenceType: "EMAIL",
      PreferenceValue: "Email Notification",
      HasOptedforNotification:
        contactPreferences.preferenceTypeEmail.FieldEmailCheck,
      PreferredEmailAddress: contactPreferences.preferredEmailAddress,
    });
    arrData.push({
      CandidateId: sCandidateID,
      PreferenceType: "SMS",
      PreferenceValue: "SMS Notification",
      HasOptedforNotification: PersonalInfo.PreferredNumber.FieldValue
        ? contactPreferences.preferenceTypeSMS.FieldSMSCheck
        : false,
      PreferredEmailAddress: "",
    });
    const res = await MyProfileActions.SavecandidateContactPreference(
      sCandidateID,
      arrData
    );

    if (res.status === 200) {
      setcontactPrefSaveButton(true);
    }
    setPageLoader(false);
  };

  //// ******************** My profile Privacy Preferences related code
  const [privacyPreferencesTCDate, setprivacyPreferencesTCDate] = useState("");
  const [privacyPreferencesPPDate, setprivacyPreferencesPPDate] = useState("");

  const fngetCandidatePrivacyConsent = async () => {
    try {
      let sCandidateID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
              "candidateID"
            ];
      if (sCandidateID != "") {
             // const res = await MyProfileActions.GetCandidateConsentDetails(
        //   sCandidateID
        // );
        const genericData = returnCountryLanguagePersona();
        let getConsentVrsnPayload = {
          Country : genericData ? genericData.MarketName : "US",
          //Language: genericData ? genericData.language : "en-US",
        };
        const res = await MyProfileActions.GetCandidateConsentVersionDetails(
          sCandidateID, getConsentVrsnPayload
        );
        if (res.status === 200) {
          let sPCDate = " " + globalDateFormat(res.data["pcAcceptedDateTime"]);
          let sTCDate = " " + globalDateFormat(res.data["tcAcceptedDateTime"]);
          setprivacyPreferencesPPDate(sPCDate);
          setprivacyPreferencesTCDate(sTCDate);
        }
      } else {
        setprivacyPreferencesPPDate("");
        setprivacyPreferencesTCDate("");
      }
    } catch (error) {
      return {};
    }
  };

  const forgotPassword = () => {
    instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
  };

  if (screenLoader) {
    return (
      <div style={{ zIndex: "99", position: "fixed" }}>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {/* <Cirrus.Page
                background='primary'
                backgroundShape="double"
                header={<HeaderWithNavigation />}
            > */}
      <Cirrus.PageBanner
        bodyText={myProfileCMSData && myProfileCMSData?.Description}
        heading={myProfileCMSData && myProfileCMSData?.Title}
        className="myProfile-pageBanner"
      />
      <Spinner screenLoader={true} spinnerTimer={pageLoader} />
      <Cirrus.OneColumnLayout type="wrapped" className="myProfileWrapper">
        <>
          <div className="myProfileContainer">
            <div
              style={{
                padding: "0.5rem",
              }}
            >
              <Cirrus.PersonalInformationForm
                heading={personalInfoLables.Title}
                subheading={personalInfoLables.Description}
                firstNameInputProps={{
                  disabled: true,
                  id: "FirstName",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.FirstName",
                    personalInfoLables
                  ),
                  name: "FirstName",
                  type: "text",
                  value: PersonalInfo.FirstName.FieldValue,
                }}
                lastNameInputProps={{
                  disabled: true,
                  id: "LastName",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.LastName",
                    personalInfoLables
                  ),
                  name: "LastName",
                  type: "text",
                  value: PersonalInfo.LastName.FieldValue,
                }}
                preferredNameInputProps={{
                  id: "PreferredName",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.PreferredName",
                    personalInfoLables
                  ),
                  name: "PreferredName",
                  type: "text",
                  onChange: inputsPIHandler,
                  state: PersonalInfo.PreferredName.status,
                  value: PersonalInfo.PreferredName.FieldValue,
                  errorMessage: PersonalInfo.PreferredName.errorMsg,
                  placeholder: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.NotAdded",
                    personalInfoLables
                  ),
                }}
                // pronounsSelectProps={{
                //     id: 'Pronouns',
                //     label: 'Pronouns',
                //     name: 'Pronouns',
                //     options: personalInfoPronouns,
                //     // options: [],
                //     onChange: inputsPIHandler,
                //     state: PersonalInfo.Pronouns.status,
                //     errorMessage: PersonalInfo.Pronouns.errorMsg,
                //     value: PersonalInfo.Pronouns.FieldValue

                // }}

                emailInputProps={{
                  disabled: true,
                  id: "Email",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.Email",
                    personalInfoLables
                  ),
                  name: "Email",
                  onChange: inputsPIHandler,
                  type: "text",
                  value: PersonalInfo.Email.FieldValue,
                }}
                secondaryEmailInputProps={{
                  id: "SecondaryEmail",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.AlternateEmail",
                    personalInfoLables
                  ),
                  name: "SecondaryEmail",
                  placeholder: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.NotAdded",
                    personalInfoLables
                  ),
                  onChange: inputsPIHandler,
                  type: "text",
                  state: PersonalInfo.SecondaryEmail.status,
                  value: PersonalInfo.SecondaryEmail.FieldValue,
                  errorMessage: PersonalInfo.SecondaryEmail.errorMsg,
                }}
                preferredNumberInputProps={{
                  id: "PreferredNumber",
                  label: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.PreferredNumber",
                    personalInfoLables
                  ),
                  name: "PreferredNumber",
                  onChange: inputsPIHandler,
                  type: "text",
                  state: PersonalInfo.PreferredNumber.status,
                  value: PersonalInfo.PreferredNumber.FieldValue,
                  errorMessage: PersonalInfo.PreferredNumber.errorMsg,
                  placeholder: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.NotAdded",
                    personalInfoLables
                  ),
                  maxLength: 20,
                }}
                submitButtonProps={{
                  children: fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.SaveUpdateBtn",
                    personalInfoLables
                  ),
                  size: "Large",
                  type: "submit",
                  disabled: bPRIsFormInValid,
                  id: "cmdSave",
                }}
                onFormSubmit={(event) => fnSaveProfileData(1, event)}
              />
              <div
                id="phoneNumber"
                onLoad={() => {
                  appendPhone();
                }}
              >
                <Cirrus.Label>
                  {fetchCMSLabel(
                    "MyProfilePage.PersonalInformation.PreferredNumber",
                    personalInfoLables
                  )}
                </Cirrus.Label>
                <div className="inpStyle">
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    value={selectCountrycode}
                    onChange={setselectCountrycode}
                    className="countryCode"
                    autoComplete="new-password"
                  />
                  <input
                    className="phoneField"
                    id="Phone"
                    label={fetchCMSLabel(
                      "MyProfilePage.PersonalInformation.PreferredNumber",
                      personalInfoLables
                    )}
                    // name={signUpStates.phone.name}
                    name="PreferredNumber"
                    placeholder="1234567890"
                    // onChange={(event) => {
                    //     // formHandler(event)
                    //     inputsPIHandler(event)
                    //     // setCode(document.getElementsByClassName('PhoneInputInput')[0].value)
                    // }}
                    onBlur={(event) => {
                      inputsPIHandler(event);
                    }}
                    maxLength={15}
                    state={PersonalInfo.PreferredNumber.status}
                    defaultValue={PersonalInfo.PreferredNumber.FieldValue.slice(
                      PersonalInfo.PreferredNumber.FieldValue.indexOf(" ") + 1
                    )}
                    errorMessage={PersonalInfo.PreferredNumber.errorMsg}
                    autoComplete="new-password"
                  />
                </div>
                {/* {signUpStates.phone.state && (
                                        <Cirrus.ErrorMessage>
                                            {signUpStates.phone.errorMsg}
                                        </Cirrus.ErrorMessage>
                                    )} */}
              </div>
            </div>

            <div
              style={{
                padding: "0.5rem",
              }}
            >
              <Cirrus.ProgrammeDetailsPanel
                buttonProps={{
                  children: fetchCMSLabel(
                    "MyProfilePage.ProgrammeDetails.RewatchProgramVideo",
                    programDetailsLables
                  ),
                  isSecondary: true,
                  leadingIcon: "play",
                  onClick: () => {
                    fnOpenCloseProgramVideo(0);
                  },
                  size: "Large",
                  type: "button",
                  disabled: bProgramVideoButtonFlag,
                  flag: noClientSpecificContent === true ? true : false,
                }}
                heading={programDetailsLables.Title}
                programEndDate={
                  programEndDate === null
                    ? fetchCMSLabel(
                        "MyProfilePage.ProgrammeDetails.TBC",
                        programDetailsLables
                      )
                    : ProgramInfo.programEndDate.FieldValue
                }
                programEndDateLabel={fetchCMSLabel(
                  "MyProfilePage.ProgrammeDetails.ProgrammeEndDate",
                  programDetailsLables
                )}
                programName={ProgramInfo.programName.FieldValue}
                programNameLabel={fetchCMSLabel(
                  "MyProfilePage.ProgrammeDetails.ProgramAssigned",
                  programDetailsLables
                )}
                startExpanded
              />
            </div>

            <div>
              {bProgramVideo && (
                <Cirrus.VideoLightbox
                  closeButtonText="Close lightbox"
                  controls
                  description=""
                  mp4Src={videoURL?.MediaUrl}
                  // mp4Src={ fetchCMSLinkTextURL(programDetailsLables, "Url")}
                  onClose={() => {
                    fnOpenCloseProgramVideo(1);
                  }}
                  onPlay={function noRefCheck() {}}
                  playButtonText="Play video"
                  posterImageUrl={videoURL?.VideoThumbnail}
                  videoTitle="Sample video"
                />
              )}
            </div>
            {(ProfileSetting.CityResidence.FieldValue.length > 0 ||
              ProfileSetting.PreferedLanguage.FieldValue.length > 0 ||
              ProfileSetting.Timezone.FieldValue.length > 0) && (
              <div
                style={{
                  padding: "0.5rem",
                }}
                className="platform_settings"
              >
                <Cirrus.PlatformSettings
                  timeZoneSelectProps={{
                    // defaultValue: 'gmt',
                    id: "Timezone",
                    label: fetchCMSLabel(
                      "MyProfilePage.PlatformSettings.TimeZone",
                      platformSettingLables
                    ),
                    name: "Timezone",
                    options: platformSettingTimezone,
                    onChange: selectoptionHandler,
                    state: ProfileSetting.Timezone.status,
                    errorMessage: ProfileSetting.Timezone.errorMsg,
                    value: ProfileSetting.Timezone.FieldValue,
                  }}
                  citySelectProps={{
                    inputId: "CityResidence",
                    inputLabel: fetchCMSLabel(
                      "MyProfilePage.PlatformSettings.CityofResidence",
                      platformSettingLables
                    ),
                    inputName: "CityResidence",
                    inputValue: cityInput,
                    onChangeHandler: (e) => onChangeHandler(e),
                    suggestionsItem: cityList,
                    inputPlaceholder: fetchCMSLabel(
                      "MyProfilePage.PlatformSettings.SelectCity",
                      platformSettingLables
                    ),
                    selectItemHandler: selectItemHandler,
                    noDataFound: cityList?.noData,
                    errorMessage: "No match found",
                    state: cityList?.noData ? "error" : "",
                    // TextVal: ProfileSetting.CityResidence.FieldValue,
                    // // options: platformSettingCities,
                    // options: cityList,
                    // defaultValue: {
                    //     activeOption: 0,
                    //     filteredOptions: [],
                    //     showOptions: false,
                    //     userInput: ProfileSetting.CityResidence.FieldValue
                    // },
                    // // onChange: selectoptionHandler,
                    // // state: ProfileSetting.CityResidence.status,
                    // // errorMessage: ProfileSetting.CityResidence.errorMsg,

                    // onStateUpdate: (_state) => handleLocationOnChange(_state)
                  }}
                  languageSelectProps={{
                    id: "PreferedLanguage",
                    label: fetchCMSLabel(
                      "MyProfilePage.PlatformSettings.LanguagePreferences",
                      platformSettingLables
                    ),
                    name: "PreferedLanguage",
                    options: platformSettingLang,
                    onChange: selectoptionHandler,
                    state: ProfileSetting.PreferedLanguage.status,
                    errorMessage: ProfileSetting.PreferedLanguage.errorMsg,
                    value: ProfileSetting.PreferedLanguage.FieldValue,
                  }}
                  heading={platformSettingLables.Title}
                  managePasswordButtonProps={{
                    children: fetchCMSLinkTextURL(
                      platformSettingLables,
                      "Text"
                    ),
                    onClick: forgotPassword,
                    size: "Large",
                  }}
                  managePasswordLabel={fetchCMSLabel(
                    "MyProfilePage.PlatformSettings.PasswordReset",
                    platformSettingLables
                  )}
                  onFormSubmit={(event) => fnSaveProfileData(2, event)}
                  submitButtonProps={{
                    children: fetchCMSLabel(
                      "MyProfilePage.PlatformSettings.SaveUpdates",
                      platformSettingLables
                    ),
                    size: "Large",
                    disabled: bIsFormInValid,
                  }}
                />
              </div>
            )}

            <div
              style={{
                padding: "0.5rem",
              }}
            >
              {displayContactPrefNotiAlert && contactPreferencesSMSDisable && (
                <Cirrus.AlertModal
                  closeIconButtonProps={{
                    icon: "close",
                    isSecondary: true,
                    onClick: closeAlert,
                    text: "Cancel",
                    type: "button",
                  }}
                  heading="Warning"
                  primaryButton={{
                    children: "Close",
                    onClick: closeAlert,
                    size: "Large",
                    type: "button",
                  }}
                  role="alert"
                  subheading="Please provide your phone number to opt for this preference."
                />
              )}

              <Cirrus.ContactPreferences
                emailSelectProps={{
                  defaultValue: "london",
                  id: "preferredEmail",
                  label: fetchCMSLabel(
                    "MyProfilePage.ContactPreference.SelectEmailForNotifications",
                    contactPreferenceLables
                  ),
                  name: "preferredEmail",
                  options: contactPreferencesEmailList,
                  // disabled: contPrefEmailState,
                  onChange: handlerContPrefNotification,
                  value: contactPreferences.preferredEmailAddress,
                }}
                heading={contactPreferenceLables.Title}
                notificationsCheckboxes={[
                  {
                    id: "notificationsMethodSms",
                    label: fetchCMSLabel(
                      "MyProfilePage.ContactPreference.SMSNotifications",
                      contactPreferenceLables
                    ),
                    name: "notificationsMethodSms",
                    value: contactPreferences.preferenceTypeSMS.FieldValue,
                    disabled: preferredNumberDisabled,
                    // MouseOver: fnContPrefMouseOver,
                    defaultChecked:
                      contactPreferences.preferenceTypeSMS.FieldSMSCheck,
                    checked: PersonalInfo.PreferredNumber.FieldValue.length
                      ? contactPreferences.preferenceTypeSMS.FieldSMSCheck
                      : false,
                    onChange: handlerContPrefNotification,
                  },
                  {
                    id: "notificationsMethodEmail",
                    label: fetchCMSLabel(
                      "MyProfilePage.ContactPreference.EmailNotifications",
                      contactPreferenceLables
                    ),
                    name: "notificationsMethodEmail",
                    value: contactPreferences.preferenceTypeEmail.FieldValue,
                    onChange: handlerContPrefNotification,
                    defaultChecked:
                      contactPreferences.preferenceTypeEmail.FieldEmailCheck,
                    checked:
                      contactPreferences.preferenceTypeEmail.FieldEmailCheck,
                  },
                ]}
                notificationsLabel={
                  myProfileCMSData?.Components[
                    "MyProfilePage.ContactPreference"
                  ]?.Description
                }
                // notificationsLabel={fetchCMSLabel('MyProfilePage.ContactPreference.Description', contactPreferenceLables)}
                onFormSubmit={fnSaveContactPreference}
                submitButtonProps={{
                  children: fetchCMSLabel(
                    "MyProfilePage.ContactPreference.SaveUpdates",
                    contactPreferenceLables
                  ),
                  size: "Large",
                  disabled: contactPrefSaveButton,
                }}
              />
            </div>

            <div
              style={{
                padding: "0.5rem",
              }}
            >
              <Cirrus.PrivacyPreferencesPanel
                heading={privarcyPreferenceLables.Title}
                privacyPolicyDetails={{
                  acceptedText:
                    fetchCMSLabel(
                      "MyProfilePage.PrivacyPreferences.PrivacyPolicyAcceptedOn",
                      privarcyPreferenceLables
                    ) + privacyPreferencesPPDate,
                  policyLink: {
                    children: fetchCMSLabel(
                      "MyProfilePage.PrivacyPreferences.PrivacyPolicyReadHere",
                      privarcyPreferenceLables
                    ),
                    onClick: () => {
                      navigate("/privacy-policy");
                    },
                  },
                  policyName: fetchCMSLabel(
                    "MyProfilePage.PrivacyPreferences.PrivacyPolicy",
                    privarcyPreferenceLables
                  ),
                }}
                startExpanded
                termsAndConditionsPolicyDetails={{
                  acceptedText:
                    fetchCMSLabel(
                      "MyProfilePage.PrivacyPreferences.TermsAndConditionsAcceptedOn",
                      privarcyPreferenceLables
                    ) + privacyPreferencesTCDate,
                  policyLink: {
                    children: fetchCMSLabel(
                      "MyProfilePage.PrivacyPreferences.TermsAndConditionsReadHere",
                      privarcyPreferenceLables
                    ),
                    onClick: () => {
                      navigate("/t&c-policy");
                    },
                  },
                  policyName: fetchCMSLabel(
                    "MyProfilePage.PrivacyPreferences.TermsAndConditions",
                    privarcyPreferenceLables
                  ),
                }}
              />
            </div>
          </div>
        </>
      </Cirrus.OneColumnLayout>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} /> */}
    </>
  );
}

export default MyProfile;
