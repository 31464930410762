//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useContext } from 'react'
import './UploadStyle.css';
import * as Cirrus from 'manpowergroup-cirrus';
import { Context } from "../../context/Context";
import { UploadActions } from './UploadActions';
import UploadIcon from '../../../assets/Images/UploadIcon.png';
import RefreshIcon from '../../../assets/Images/RefreshIcon.png';
import TickIcon from '../../../assets/Images/TickIcon.png';
import CrossIcon from '../../../assets/Images/CrossIcon.png';
import DeleteIcon from '../../../assets/Images/DeleteIcon.png';
import { ActivityAction } from "../../utility/ActivityActions";


function MultipleFilesUpload({ cmsContent, finishUploadHandler, uploadModalFlag, extension, origin }) {
    const maxSize = 10000;
    const context = useContext(Context);
    const [uploadFilesStore, setUploadFilesStore] = useState([]);
    const [dropZoneAndListsShowHide, setDropZoneAndListsShowHide] = useState(true);
    const [disabledFinishUploadBtn, setDisabledFinishUploadBtn] = useState(true);
    const [replaceMultiUploadError, setReplaceMultiUploadError] = useState("");

    function browseFileHandler(files) {
        let browseFiles = [...files];
        if (uploadModalFlag.uploadType === "Single" && browseFiles.length > 1) {
            setReplaceMultiUploadError(fetchExitLabel(`${extension}.UploadAJob.ReplaceMutipleFilesMessage`))
            return;
        }

        let duplicateNameArr = [];
        uploadFilesStore.map((Name) => {
            duplicateNameArr.push(Name.name)
        })

        let fileStore = [];
        let accFile = [];
        let rejFile = [];
        browseFiles?.map((browsee) => {
            if (duplicateNameArr.includes(browsee.name)) {
                browsee["isUploaded"] = true;
                browsee["isSuccess"] = false;
                browsee["message"] = fetchExitLabel(`${extension}.UploadAJob.FileNameExists`);
                browsee["errorCode"] = '105';
                rejFile.push(browsee);
            } else {
                if (browsee.type.toLowerCase() !== "image/jpeg" &&
                    browsee.type.toLowerCase() !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    browsee.type.toLowerCase() !== "application/msword" &&
                    browsee.type.toLowerCase() !== "application/pdf" &&
                    browsee.type.toLowerCase() !== "image/png") {
                    browsee["isUploaded"] = true;
                    browsee["isSuccess"] = false;
                    browsee["message"] = fetchExitLabel(`${extension}.FileFormatError`);
                    browsee["errorCode"] = '101';
                    rejFile.push(browsee);
                } else {
                    let fileSize = parseInt((browsee.size) / 1024);
                    if (fileSize > maxSize) {
                        browsee["isUploaded"] = true;
                        browsee["isSuccess"] = false;
                        browsee["message"] = fetchExitLabel(`${extension}.ResumeUpload.FileIsLargerThanMaxLimit`);
                        browsee["errorCode"] = '103';
                        rejFile.push(browsee);
                    } else {
                        if (uploadModalFlag.apiFlag === "ResumeParsing") {
                            browsee["isUploaded"] = true;
                            browsee["isSuccess"] = true;
                        } else {
                            browsee["isUploaded"] = false;
                        }
                        accFile.push(browsee)
                    }
                }
            }
            fileStore.push(browsee)
        })
        setUploadFilesStore([...uploadFilesStore, ...fileStore])

        setDisabledFinishUploadBtn(true)
        let filteredSuccessUpload = [...uploadFilesStore, ...fileStore].filter((successUpload) => successUpload.isSuccess === true)
        context.setUploadModalExitDisable(false)
        if (filteredSuccessUpload.length > 0) {
            context.setUploadModalExitDisable(true)
            setDisabledFinishUploadBtn(false)
        }
        if (accFile.length > 0) {
            setDisabledFinishUploadBtn(true)
            context.setUploadModalExitDisable(true)
            if (uploadModalFlag.apiFlag === "ResumeParsing") {
                setDisabledFinishUploadBtn(false)
            } else {
                uploadFilesHandler(accFile, [...uploadFilesStore, ...fileStore])
            }
        }
        setDropZoneAndListsShowHide(false)
    }

    const uploadFilesHandler = async (files, totalfile) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(files[i].name, files[i])
        }
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        formData.append('OwnerId', candidateID);
        formData.append('OwnerType', "Candidate");

        if (uploadModalFlag.apiFlag === "ResumeUpload" && uploadModalFlag.isResumeBuilder === true) {
            formData.append('IsResumeBuilder', true);
        }

        if (uploadModalFlag.apiFlag === "DocumentReplace") {
            await UploadActions.UpdateDocument(uploadModalFlag.replaceID, formData).then(res => {
                if (res.status === 200) {
                    let mappedTotalfile = totalfile?.map((totalFileBrowsed) => {
                        res?.data?.map((afterUpload) => {
                            if (totalFileBrowsed.name === afterUpload?.documentName) {
                                totalFileBrowsed["isUploaded"] = true;
                                totalFileBrowsed["id"] = afterUpload?.id
                                totalFileBrowsed["documentUploadedTimeStamp"] = afterUpload?.documentUploadedTimeStamp
                                totalFileBrowsed["isSuccess"] = afterUpload?.isSuccess
                                totalFileBrowsed["message"] = afterUpload?.message
                                totalFileBrowsed["errorCode"] = afterUpload?.errorCode
                            }
                        })
                        return totalFileBrowsed;
                    })
                    let filteredSuccessUpload = mappedTotalfile.filter((successUpload) => successUpload.isSuccess === true)
                    if (filteredSuccessUpload.length > 0) {
                        setDisabledFinishUploadBtn(false)
                    } else {
                        context.setUploadModalExitDisable(false)
                    }
                    setUploadFilesStore(mappedTotalfile)
                }
            }, err => {
                console.log(err);
            });
        }

        if (uploadModalFlag.apiFlag === "DocumentUpload") {
            await UploadActions.UploadDocuments(formData).then(res => {
                if (res.status === 200) {
                    let mappedTotalfile = totalfile?.map((totalFileBrowsed) => {
                        res?.data?.map((afterUpload) => {
                            if (totalFileBrowsed.name === afterUpload?.documentName) {
                                totalFileBrowsed["isUploaded"] = true;
                                totalFileBrowsed["id"] = afterUpload?.id
                                totalFileBrowsed["documentUploadedTimeStamp"] = afterUpload?.documentUploadedTimeStamp
                                totalFileBrowsed["isSuccess"] = afterUpload?.isSuccess
                                totalFileBrowsed["message"] = afterUpload?.message
                                totalFileBrowsed["errorCode"] = afterUpload?.errorCode

                            }
                        })
                        return totalFileBrowsed;
                    })
                    let filteredSuccessUpload = mappedTotalfile.filter((successUpload) => successUpload.isSuccess === true)
                    if (filteredSuccessUpload.length > 0) {
                        setDisabledFinishUploadBtn(false)
                        context.setUploadModalExitDisable(true)
                    } else {
                        context.setUploadModalExitDisable(false)
                    }
                    setUploadFilesStore(mappedTotalfile)

                    res?.data?.map((val) => ActivityAction.SaveActivity(val.id, "document", val.documentName));
                }
            }, err => {
                console.log(err);
            });
        }

        if (uploadModalFlag.apiFlag === "ResumeUpload") {
            await UploadActions.UploadResume(formData).then(res => {
                if (res.status === 200) {
                    let mappedTotalfile = totalfile?.map((totalFileBrowsed) => {
                        res?.data?.map((afterUpload) => {
                            // resume_
                            let fileName = `resume_${totalFileBrowsed.name}`
                            if (fileName === afterUpload?.documentName) {
                                totalFileBrowsed["isUploaded"] = true;
                                totalFileBrowsed["id"] = afterUpload?.documentIdentifier
                                totalFileBrowsed["documentUploadedTimeStamp"] = afterUpload?.resumeUploadedTimeStamp
                                totalFileBrowsed["isSuccess"] = afterUpload?.isSuccess
                                totalFileBrowsed["message"] = afterUpload?.message
                                totalFileBrowsed["errorCode"] = afterUpload?.errorCode

                            }
                        })
                        return totalFileBrowsed;
                    })
                    let filteredSuccessUpload = mappedTotalfile.filter((successUpload) => successUpload.isSuccess === true)
                    if (filteredSuccessUpload.length > 0) {
                        setDisabledFinishUploadBtn(false)
                    } else {
                        context.setUploadModalExitDisable(false)
                    }
                    setUploadFilesStore(mappedTotalfile)
                }
            }, err => {
                console.log(err);
            });
        }
    }

    const browseMoreHandler = () => {
        setDropZoneAndListsShowHide(true)
    }

    const deleteFileHandler = async (file) => {
        await UploadActions.DeleteDocument(file.id).then(res => {
            if (res.status === 200) {
                let filteredItem = uploadFilesStore.filter((fw) => {
                    return fw.id !== file.id
                })
                let filteredItem2 = filteredItem.filter((fw) => {
                    return fw.isSuccess === true
                })

                setUploadFilesStore(filteredItem)
                if (filteredItem.length === 0) {
                    setDropZoneAndListsShowHide(true)
                    context.setUploadModalExitDisable(false)
                }
                if (filteredItem.length > 0 && filteredItem2.length === 0) {
                    setDisabledFinishUploadBtn(true)
                    context.setUploadModalExitDisable(false)
                }
            }
        }, err => {
            console.log(err);
        });
    }

    const retryHandler = async (file) => {
        let retryItem = []
        let totalArr = []
        uploadFilesStore.map((fw) => {
            if (fw.name === file.name && fw.id === file.id) {
                fw["isUploaded"] = false;
                retryItem.push(fw);
            }
            totalArr.push(fw)
        })
        setUploadFilesStore(totalArr)
        uploadFilesHandler(retryItem, totalArr)
    }

    const convertSize = (bytes) => {
        const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
    }

    const fetchExitLabel = (key) => {
        let label = '';
        if (cmsContent?.labels) {
            cmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    // START BROWSE & DRAG&DROP FILE FUNCTIONALITY
    const [dragActive, setDragActive] = React.useState(false);
    const inputRef = React.useRef(null);
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            browseFileHandler(e.dataTransfer.files);
        }
    };
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            browseFileHandler(e.target.files);
        }
    };
    const onButtonClick = () => {
        inputRef.current.click();
    };
    // END BROWSE & DRAG&DROP FILE FUNCTIONALITY

    return (
        <div className='uploadWrapper'>
            {
                dropZoneAndListsShowHide === true ? <div className='dropZoneWrap'>
                    {/* <p>{cmsContent?.uploadDescription}</p> */}
                    <p>{cmsContent?.uploadDescription}</p>
                    {
                        replaceMultiUploadError !== "" ? <div className='errorMsg' style={{ marginTop: '10px' }}>{replaceMultiUploadError}</div> : ""
                    }
                    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={uploadModalFlag.uploadType === "Single" ? false : true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                            <div className='uploadIconWrap'><img src={UploadIcon} alt='' /></div>
                            {/* <div dangerouslySetInnerHTML={{ __html:  cmsContent?.dragDropText[0]?.Value !== undefined ? cmsContent?.dragDropText[0]?.Value : ""}} className='uploadText'></div> */}
                            <div dangerouslySetInnerHTML={{ __html: cmsContent?.dragDropText[0]?.Value }} className='uploadText'></div>
                            <button className="upload-button" onClick={onButtonClick} aria-label="Upload a file"></button>
                        </label>
                        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                    </form>
                </div> : <div className='filesItemsWrapper'>
                    {/* <p style={{ marginTop: 0 }}>{cmsContent?.uploadProgressDescription}</p> */}
                    <p style={{ marginTop: 0 }}>{cmsContent?.uploadStatusDescription}</p>
                    {
                        uploadFilesStore?.map((displayFile, idx) => (
                            <div className='fileWrap' key={idx}>
                                <div className='fileLeft'>
                                    {
                                        displayFile?.isUploaded === false ? <div className='fileuploadloader'><Cirrus.Spinner size={10} /></div> : displayFile?.isSuccess === true ? <div className='tickCrossIcon'><img src={TickIcon} alt='' /></div> : <div className='tickCrossIcon'><img src={CrossIcon} alt='' /></div>
                                    }
                                    <div className='itemInfo'>
                                        <div className='fileName'>{displayFile?.name}</div>
                                        {
                                            (displayFile?.isUploaded && !displayFile?.isSuccess) && <div className='errorMsg'>
                                                {displayFile?.message}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='fileRight'>
                                    {
                                        displayFile.errorCode === '500' ? <button className='reUploadIcon' onClick={() => { retryHandler(displayFile) }}>
                                            <img src={RefreshIcon} alt='' />
                                        </button> : ""
                                    }

                                    {
                                        displayFile?.isUploaded === false ? <div className='fileSize'>{convertSize(displayFile?.size)}</div> :
                                            displayFile?.isSuccess === true ? <div className='fileSize'>{convertSize(displayFile?.size)}</div> :
                                                displayFile.errorCode !== '500' ? <div className='errorStatus'>{fetchExitLabel(`${extension}.InternalServerError`)}</div> :
                                                    <div className='errorStatus'>{fetchExitLabel(`${extension}.ErrorButton`)}</div>
                                    }
                                    {
                                        uploadModalFlag.apiFlag === "DocumentUpload" && uploadModalFlag.uploadType === "Multiple" ? <>
                                            {
                                                displayFile?.isSuccess && <button className='deleteIcon' onClick={() => deleteFileHandler(displayFile)} aria-label='Delete Button'>
                                                    <img src={DeleteIcon} alt='' />
                                                </button>
                                            }
                                        </> : ""
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <div className='footerButton'>
                        {
                            uploadModalFlag?.uploadType !== "Single" && <Cirrus.Button
                                isSecondary
                                onClick={browseMoreHandler}
                            >
                                {fetchExitLabel(`${extension}.NewUploadButton`)}
                            </Cirrus.Button>
                        }

                        <Cirrus.Button
                            disabled={disabledFinishUploadBtn}
                            onClick={() => {
                                finishUploadHandler(uploadFilesStore.filter((successUpload) => successUpload.isSuccess === true))
                                context.setUploadModalExitDisable(false)
                            }}
                        >
                            {/* {fetchExitLabel("MyDocumentsPage.UploadProgressView.FinishUploadButton")} */}
                            {fetchExitLabel(`${extension}.FinishUploadButton`)}
                        </Cirrus.Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default MultipleFilesUpload
