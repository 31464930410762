//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { getDateMeta } from "@fullcalendar/react";
import axios from "axios";
import Interceptor from "./Interceptor";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { returnCountryLanguagePersona } from './CountryLanguage';

class httpHelper {
  constructor() {
    this.ApiHostUrl = process.env.REACT_APP_API_URL;
    this.TextKernelApiHostUrl = process.env.REACT_APP_TEXTKERNEL_API_URL;
    this.ApiLearningResourceUrl = process.env.REACT_APP_LR_URL;
    this.TKapiKey = process.env.REACT_APP_TEXTKERNEL_API_KEY;
    this.CMSApiHostUrl = process.env.REACT_APP_CMS_API_URL;
    this.CMSapiKey = process.env.REACT_APP_CMS_API_KEY;
    this.ApiJobUrl = process.env.REACT_APP_JOB_API_URL;
    this.DocumentsHostURL = process.env.REACT_APP_DOCUMENTS_URL;
    this.CountryBaseUrl = process.env.REACT_APP_REFERENCES_URL;
    this.CalenderBaseURL = process.env.REACT_APP_CALENDAR_API_URL;
    this.AssessmentsBaseURL = process.env.REACT_APP_LR_URL;
    this.IviewWelcomeResourceUrl = process.env.REACT_APP_LR_URL;
    this.NotificationsURL = process.env.REACT_APP_NOTIFICATION_API_URL;
    this.SignalrBaseUrl = process.env.REACT_APP_SIGNAL_R_APP_URL;
  }
   

  async get(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.ApiHostUrl + url, data);
  }
  async put(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.ApiHostUrl + url, data);
  }
  async post(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.ApiHostUrl + url, data);
  }
  
  async learningResourceGet(url, data = {}) {
    let _instance = new Interceptor().getInstanse();

    let genericData = returnCountryLanguagePersona();
    data = {...data, ...genericData};
    
    return _instance.get(this.ApiLearningResourceUrl + url, data)
  }

  async learningResourcePut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();

    let genericData = returnCountryLanguagePersona();
    data = {...data, ...genericData};
    
    return _instance.put(this.ApiLearningResourceUrl + url, data);
  }

  async learningResourcePost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();

    let genericData = returnCountryLanguagePersona();
    data = {...data, ...genericData};

    return _instance.post(this.ApiLearningResourceUrl + url, data);
  }

  async jobPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.ApiJobUrl + url, data);
  }

  async jobGet(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.ApiJobUrl + url, data);
  }

  async jobDelete(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.delete(this.ApiJobUrl + url, data);
  }

  async jobPut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.ApiJobUrl + url, data);
  }

  //**this function is for app Insight */
  UseAppInsight(response){
    //***App Insight SetUp***//
    var reactPlugin = new ReactPlugin();
    const browserHistory = createBrowserHistory({ basename: '' });
    var appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_INSIGHT_KEY,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        disableAjaxTracking:true,
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();
    //***till here***//

    //**Payload below**//
    let ProgramDetails = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")):"";
    let ProfileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails")) && JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
    let noData = "Not Available";
    let payload
    if (JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null){
    payload = {
    "CandidateID": JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
    "Severity": SeverityLevel.Error.toString(),
    "Client Name": ProgramDetails?.companyName ? ProgramDetails?.companyName : noData,
    "Program Class":ProgramDetails?.programClass ? ProgramDetails?.programClass : noData,
    "Market":ProfileDetails?.preferredLanguageCode ? ProfileDetails?.preferredLanguageCode : noData,
    "Time": new Date(),
    "Persona": ProfileDetails?.personaName ? ProfileDetails?.personaName : noData,
    "EmailAddress": ProfileDetails?.emailAddress ? ProfileDetails?.emailAddress : noData,
    "Language": JSON.parse(window.sessionStorage.getItem("Language")) && JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageFullName']}
    }
    let url = response?.config.url;
    let errorMessage = response?.data?.errorMessage?response?.data?.errorMessage:response?.data?.message?response?.data?.message:url;
    let status = response?.status;
    let xCorrelationId = response?.headers["x-correlation-id"]?response?.headers["x-correlation-id"]: "no-correlation Id found";
    //**till here **//

    //**calling exception track **//
    reactPlugin.trackException({ error: {errorMessage},severityLevel:SeverityLevel.Error,properties:{"url":url,"status":status,"xCorrelationId":xCorrelationId,...payload} })
    //**till here */
  }
  //**end here */

  async textCernelPost(url, data = {}) {
    let res = axios.post(this.TextKernelApiHostUrl + url, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": this.TKapiKey,
        "Content-Type": "application/json",
      },
    });
    res.catch(err =>{
      this.UseAppInsight(err.response)
    })
    return res
  }

  async textCernelDelete(url, data = {}) {
    let res = axios.delete(this.TextKernelApiHostUrl + url, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": this.TKapiKey,
        "Content-Type": "application/json",
      },
    });
    res.catch(err =>{
      this.UseAppInsight(err.response)
    })
    return res
  }

  async textCernelPut(url, data = {}) {
    let res = axios.put(this.TextKernelApiHostUrl + url, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": this.TKapiKey,
        "Content-Type": "application/json",
      },
    });
    res.catch(err =>{
      this.UseAppInsight(err.response)
    })
    return res
  }

  async CMSpost(url, data = {}) {

    let genericData = returnCountryLanguagePersona();
    let datas = {...data, ...genericData};

    let res = axios.post(this.CMSApiHostUrl + url, datas, {
      headers: {
        sc_apikey: this.CMSapiKey,
        "Content-Type": "application/json",
      },
    });
    res.catch(err =>{
      this.UseAppInsight(err.response)
    })
    return res
  }

  async documentsGet(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.DocumentsHostURL + url, data);
  }
  async documentsPut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.DocumentsHostURL + url, data);
  }
  async documentsPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.DocumentsHostURL + url, data);
  }
  async documentsDelete(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.delete(this.DocumentsHostURL + url, data);
  }

  async documentsDownloadGet(url) {
    let accessToken = window.sessionStorage.getItem("AccessToken");
    let res =  axios.get(this.DocumentsHostURL + url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    res.catch(err =>{
      this.UseAppInsight(err.response)
    })
    return res
  }

  async getCountryList(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.CountryBaseUrl + url);
  }

  async CalenderPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.CalenderBaseURL + url, data);
  }

  async CalenderGet(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.CalenderBaseURL + url, data);
  }

  async AssessmentsGet(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.AssessmentsBaseURL + url);
  }

  async AssessmentsPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.AssessmentsBaseURL + url, data);
  }
  
  async GetEvents(url, data = {}) {    

    let genericData = returnCountryLanguagePersona();
    data = {...data, ...genericData};
    
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.AssessmentsBaseURL + url, data);
  } 
  async updateEvents(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.AssessmentsBaseURL + url, data);
  } 
  async GetDoceboUrl(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.AssessmentsBaseURL + url, data);
  } 
  

  async AssessmentsPut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.AssessmentsBaseURL + url, data);
  }

  async AssessmentsDelete(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.delete(this.AssessmentsBaseURL + url);
  }

  async AssessmentsCareerDelete(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.delete(this.AssessmentsBaseURL + url, data);
  }

  async CoachingPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.CalenderBaseURL + url, data);
  }

  async CoachingPut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.CalenderBaseURL + url, data);
  }

  async CoachingDelete(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.delete(this.CalenderBaseURL + url);
  }

  async IviewVideoGet(url, data = {}) {   

    let genericData = returnCountryLanguagePersona();
    data = {...data, ...genericData};    
    
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.IviewWelcomeResourceUrl + url, data);
  }

  async IviewVideoPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.IviewWelcomeResourceUrl + url, data);
  }

  async IviewInterviewURLPut(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.IviewWelcomeResourceUrl + url, data);
  }

  async IviewReviewURLPost(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.IviewWelcomeResourceUrl + url);
  }

  async IviewResetPut(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.put(this.IviewWelcomeResourceUrl + url);
  }

  async NoCoachPost(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.CalenderBaseURL + url, data);
  }

  async GetNotifications(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.NotificationsURL + url);
  }

  async ReadNotificationPost(url, data ={}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.NotificationsURL + url, data);
  }

  async signalRNotification(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.SignalrBaseUrl + url);
  }

  async notifyJobmatches(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.NotificationsURL + url, data);
  } 

  async GetResumeBuilderUrl(url) {
    let _instance = new Interceptor().getInstanse();
    return _instance.get(this.ApiLearningResourceUrl + url);
  }

  async SaveResumeBuilderUrl(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.ApiLearningResourceUrl + url, data);
  }

  async OnBoardingQuestions(url, data = {}) {
    let _instance = new Interceptor().getInstanse();
    return _instance.post(this.CountryBaseUrl + url, data);
  }
}
export default httpHelper;
