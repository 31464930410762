//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

const LookingAtOptions = () => {
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsLookingAtOptionsContent, setcmsLookingAtOptionsContent] = useState();
    const [startPageData, setStartPageData] = useState()
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));
    const [assessmentStatus, setAssessmentStatus] = useState()
    const [isLiked, setIsLiked] = useState(false)
    const [isSaved, setIsSaved] = useState(false)

    useEffect(() => {
        fetchCMSData()
        fetchStartPageData();
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        setScreenLoader(true);
        let refCmsLookingAtOptionsContent = {};
        let payload = {
            PageName: "LookingAtOptions"
        };
        await AssessmentActions.GetAssessmentCMS(payload).then(async (res) => {
            if (res.status === 200) {
                let resData = res.data;
                refCmsLookingAtOptionsContent.browserTitle = resData.BrowserTitle;
                refCmsLookingAtOptionsContent.metaDescription = resData.MetaDescription;
                refCmsLookingAtOptionsContent.metadata = resData.Metadata;
                refCmsLookingAtOptionsContent.title = resData.Title;
                refCmsLookingAtOptionsContent.focusArea = resData.FocusArea[0];
                refCmsLookingAtOptionsContent.resourceType = resData.ResourceType;
                refCmsLookingAtOptionsContent.resourceCategoryType = resData.ResourceCategoryType;
                refCmsLookingAtOptionsContent.duration = resData.Duration;
                refCmsLookingAtOptionsContent.description = resData.Description;
                refCmsLookingAtOptionsContent.shortDescription = resData.ShortDescription;
                refCmsLookingAtOptionsContent.subTitle = resData.SubTitle;
                refCmsLookingAtOptionsContent.internalAssessmentId = resData.ID;
                refCmsLookingAtOptionsContent.externalAssessmentId = resData.ExternalId;
                refCmsLookingAtOptionsContent.commonLabels = resData.Components.CommonComponent.Labels;
                refCmsLookingAtOptionsContent.commonResourceLabels = resData.Components.CommonResourceComponent.Labels;
                refCmsLookingAtOptionsContent.largeImage = resData.LargeImage.ImageUrl;
                refCmsLookingAtOptionsContent.mediumImage = resData.MediumImage;
                refCmsLookingAtOptionsContent.smallImage = resData.SmallImage;
                refCmsLookingAtOptionsContent.commonStatus = resData.Components.CommonComponent.LOVList[0].Values;
                refCmsLookingAtOptionsContent.assessmentQuestionSet = resData.ChildPages.filter(x => x.Key !== "Assessments.LookingAtOptions.ResultsPage");

                let questionsArr = [];
                refCmsLookingAtOptionsContent.assessmentQuestionSet.map(assessment => {
                    Object.keys(assessment.Components).forEach(key => {
                        if (key === "Resources.Assessments.LookingAtOptions.TellUsAboutTheCareerPath1") {
                            questionsArr.push({
                                ...assessment,
                                Components: [{
                                    careerHeading: assessment.Components[key].Title,
                                    careerID: assessment.Components[key].ID,
                                    pathTitleID: assessment.Components[key].QuestionList.find(question => question.OptionType === 'TextBox').QuestionId,
                                    pathTitle: assessment.Components[key].QuestionList.find(question => question.OptionType === 'TextBox').QuestionTitle,
                                    pathValue: '',
                                    aboutPathTitleID: assessment.Components[key].QuestionList.find(question => question.OptionType === 'TextArea').QuestionId,
                                    aboutPathTitle: assessment.Components[key].QuestionList.find(question => question.OptionType === 'TextArea').QuestionTitle,
                                    aboutPathValue: ''
                                }]
                            })
                        }
                        if (key === "Resources.Assessments.LookingAtOptions.CareerFactors") {
                            let questList = assessment.Components[key].QuestionList.map(question => {
                                let opts = question.AnswerList.map(options => {
                                    return ({ ...options, defaultChecked: false })
                                })
                                return ({ ...question, AnswerList: opts })
                            })
                            questionsArr.push({ ...assessment, QuestionList: questList })

                            refCmsLookingAtOptionsContent.addNewFactorLabels = assessment.Components["Resources.Assessments.LookingAtOptions.AddNewFactors"].Labels
                            refCmsLookingAtOptionsContent.addNewFactorTitle = assessment.Components["Resources.Assessments.LookingAtOptions.AddNewFactors"].Title
                        }
                    })
                });

                refCmsLookingAtOptionsContent.assessmentQuestionSet = questionsArr;
                refCmsLookingAtOptionsContent.reviewPage = resData.ChildPages.find(x => x.Key === 'Assessments.LookingAtOptions.LookingAtOptionsReview')
                refCmsLookingAtOptionsContent.resultPage = resData.ChildPages.find(x => x.Key === "Assessments.LookingAtOptions.ResultsPage")


                ActivityAction.SaveActivity(refCmsLookingAtOptionsContent?.internalAssessmentId, "startassessment", refCmsLookingAtOptionsContent?.title)
                TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": refCmsLookingAtOptionsContent?.internalAssessmentId, "Entity Name": refCmsLookingAtOptionsContent?.title, "CardType": "Assessment" })
                TrackActions.PageAction("ViewCard", { "Entity Id": refCmsLookingAtOptionsContent?.internalAssessmentId, "Entity Name": refCmsLookingAtOptionsContent?.title, "CardType": "Assessment" })
                setcmsLookingAtOptionsContent(refCmsLookingAtOptionsContent);
                context.setCmsLookingAtOptionsContent(refCmsLookingAtOptionsContent);
                setScreenLoader(false);
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }



    const fetchStartPageData = async () => {
        let resourceType = "assessmentsandtasks";
        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }
        await AssessmentActions.GetStartPageData(payload).then((res) => {
            if (res.status === 200) {
                let resData = res?.data && res?.data?.responsemodel;
                let refData = resData?.filter(x => x.key === "Assessments.LookingAtOptions")
                setStartPageData(refData && refData[0] && refData[0]);
            }
        }, (err) => {
            console.log(err);
        })
    }

    const fetchCommonLabel = (key) => {
        let refCmsLookingAtOptionsContent = JSON.parse(JSON.stringify(cmsLookingAtOptionsContent));
        let label = '';
        if (refCmsLookingAtOptionsContent?.commonLabels) {
            refCmsLookingAtOptionsContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsLookingAtOptionsContent = JSON.parse(JSON.stringify(cmsLookingAtOptionsContent));
        let label = '';
        if (refCmsLookingAtOptionsContent?.commonResourceLabels) {
            refCmsLookingAtOptionsContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsLookingAtOptionsContent = JSON.parse(JSON.stringify(cmsLookingAtOptionsContent));
        let id = '';
        if (refCmsLookingAtOptionsContent?.commonStatus) {
            refCmsLookingAtOptionsContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const fetchCommonStatus = (key) => {
        let refCmsLookingAtOptionsContent = JSON.parse(JSON.stringify(cmsLookingAtOptionsContent));
        let label = '';
        if (refCmsLookingAtOptionsContent?.commonStatus) {
            refCmsLookingAtOptionsContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const saveAndLikeHandler = (type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": cmsLookingAtOptionsContent.internalAssessmentId,
            "learningResourceStatus": startPageData.learningResourceStatus,
            "learningResourceType": cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            "resourceTypeId": cmsLookingAtOptionsContent.resourceType?.ID,
            "isSaved": type === "save" ? startPageData.isSaved == true ? false : true : startPageData.isSaved,
            "isLiked": type === "like" ? startPageData.isLiked == true ? false : true : startPageData.isLiked,
            "lastAnsweredPageId": startPageData.lastAnsweredPageId,
            "isDownloaded": startPageData?.isDownloaded,
            "isSharedWithCoach": startPageData?.isSharedWithCoach,
            "resultDocumentId": startPageData?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(cmsLookingAtOptionsContent.resourceCategoryType?.ID, cmsLookingAtOptionsContent.internalAssessmentId, payload).then(res => {
            if (res.status === 200) {
                AssessmentActions.GetAssessmentStatusAndSavedResponseById(cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                    if (res.status === 200) {
                        setAssessmentStatus(res.data)
                        setIsSaved(res.data.isSaved)
                        setIsLiked(res.data.isLiked)
                        setStartPageData(res.data)
                    }
                }, err => {
                    console.log(err)
                })
            }
        }, err => {
            console.log(err);
        })
    }

    const updateAssessmentStatus = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setScreenLoader(true)
        if (startPageData.learningResourceStatus === fetchStatusID("ResourceStatus.Completed")) {
            AssessmentActions.RetakeCareerAssessment(cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsLookingAtOptionsContent?.internalAssessmentId, "InternalAssessmentName": cmsLookingAtOptionsContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsLookingAtOptionsContent?.internalAssessmentId, "InternalAssessmentName": cmsLookingAtOptionsContent?.title })
                    setScreenLoader(false);
                    navigate("/" + pageOrgin.id + "/looking-at-options/career-path");
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        } else {
            let payload = {
                "candidateId": candidateID,
                "learningResourceId": cmsLookingAtOptionsContent.internalAssessmentId,
                "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
                "learningResourceType": cmsLookingAtOptionsContent.resourceCategoryType?.ID,
                "resourceTypeId": cmsLookingAtOptionsContent.resourceType?.ID,
                "isSaved": startPageData.isSaved,
                "isLiked": startPageData.isLiked,
                "lastAnsweredPageId": startPageData.lastAnsweredPageId,
                "isDownloaded": startPageData?.isDownloaded,
                "isSharedWithCoach": startPageData?.isSharedWithCoach,
                "resultDocumentId": startPageData?.resultDocumentId
            }
            AssessmentActions.SaveAssessmentStatus(cmsLookingAtOptionsContent.resourceCategoryType?.ID, cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsLookingAtOptionsContent?.internalAssessmentId, "InternalAssessmentName": cmsLookingAtOptionsContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsLookingAtOptionsContent?.internalAssessmentId, "InternalAssessmentName": cmsLookingAtOptionsContent?.title })
                    setScreenLoader(false)
                    if (startPageData.lastAnsweredPageId < "1") {
                        navigate("/" + pageOrgin.id + "/looking-at-options/career-path")
                    } else {
                        navigate("/" + pageOrgin.id + "/looking-at-options/career-factors")
                    }
                } else {
                    setScreenLoader(false)
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        }
    }


    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page looking-atoption_assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            {
                cmsLookingAtOptionsContent &&
                <>
                    <Container fluid className='background-White p-lg-5 p-3 pb-0'>
                        <Row className='justify-content-between'>
                            <Col md="7" lg="7" className='mb-3 pb-lg-5'>
                                <Cirrus.Button isSecondary onClick={() => { navigate(`/${context.path ? context.path : path}`) }} className="mb-lg-5 mb-3">{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                <Cirrus.Header size='h3' className='assessment-title'>{cmsLookingAtOptionsContent?.title}</Cirrus.Header>
                                <div className='save-section mt-4 mb-3'>
                                    <button className='saveBtn' onClick={() => { saveAndLikeHandler('save') }}>
                                        {startPageData?.isSaved === true ? <i className='icon-saved'></i> : <i className='icon-save'></i>}
                                        <span className='saveBtnText'>
                                            {startPageData?.isSaved === true ? fetchCommonResourceLabel("CommonResourceComponent.Saved") : fetchCommonLabel("CommonComponent.CommonSaveButton")}
                                        </span>
                                    </button>
                                    <Cirrus.Label id='category-label'>{cmsLookingAtOptionsContent?.focusArea?.Value}</Cirrus.Label>
                                    <Cirrus.Label id="cardtype">
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                            <>
                                                {cmsLookingAtOptionsContent?.resourceType?.Value} <sup>.</sup>
                                                <span>{cmsLookingAtOptionsContent?.duration}</span>
                                            </>
                                        }

                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.InProgress")}
                                            </>
                                        }
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.Completed")}
                                            </>
                                        }
                                    </Cirrus.Label>
                                </div>
                                <div>
                                    <Cirrus.Button size='small' className='likeBtn' onClick={() => { saveAndLikeHandler('like') }}>
                                        {startPageData?.isLiked === true ? <Icon icon="heart_fill" size={16} color="#9D323D" /> : <Icon icon="heart" size={16} color="#000" />}
                                        <span className='likeBtnText'>
                                            {
                                                startPageData?.likeCount === 0 ? " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                                    startPageData?.likeCount === 1 ? startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                                        startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()
                                            }
                                        </span>
                                    </Cirrus.Button>
                                </div>
                            </Col>
                            <Col md="12" lg="5" className='position-relative'>
                                <div className='assesment_startpage-image-wrapper'>
                                    <img src={cmsLookingAtOptionsContent.largeImage} alt='sideimage' className='image-overflow' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='p-lg-5 p-3 pb-0'>
                        <Row>
                            <Col md="12" lg="6" className='looking_start-page assessment_second-container-intro'>
                                <Cirrus.Headline size='Medium' className='mb-5 assessment_introHeading'>{cmsLookingAtOptionsContent?.shortDescription}</Cirrus.Headline>
                                <Cirrus.Header size='h5' className='mb-3'>{cmsLookingAtOptionsContent?.subTitle}</Cirrus.Header>
                                <p className='mb-3'>
                                    <Cirrus.RichText content={cmsLookingAtOptionsContent?.description} className="instructions" />
                                </p>

                                <Cirrus.Button className='my-4 w-25' onClick={() => { updateAssessmentStatus(cmsLookingAtOptionsContent?.internalAssessmentId) }}>
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonStartButton")}
                                        </>
                                    }

                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonContinueButton")}
                                        </>
                                    }
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                        <>
                                            {fetchCommonLabel("CommonComponentCommonRetakeButton")}
                                        </>
                                    }
                                </Cirrus.Button>

                            </Col>
                            <Col md="6" lg="6">

                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default LookingAtOptions;
