//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import Spinner from '../../common/components/spinner-loader/Spinner';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { Context } from '../../common/context/Context';
import { EventsActions } from './EventsActions';
import { TrackActions } from "../../common/utility/LogAction";
import { DiscoverActions } from "../discover/DiscoverActions";

import "./myEvents.css";

function Events() {

  const context = useContext(Context);
  const [cmsSA, setCmsSA] = useState({});
  const navigate = useNavigate();

  const [allEventsTab, setAllEventsTab] = useState(false);
  const [myEventsTab, setMyEventsTab] = useState(false);
  const [onDemandTab, setOnDemandTab] = useState(false);
  const [events, setEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [recommendedEvents, setRecommendedEvents] = useState([]);
  const [recommendedOnDemandEvents, setRecommendedOnDemandEvents] = useState([]);
  const [recommendedLiveEvents, setRecommendedLiveEvents] = useState([]);
  const [commonImage, setCommonImage] = useState({});
  const [featureImage, setFeatureImage] = useState({});
  const [pageImage, setPageImage] = useState();
  const [recommendedEventsCMS, setRecommendedEventsCMS] = useState({});
  const [myEvents, setMyEvents] = useState([]);
  const [showLiveEvents, setShowLiveEvents] = useState(true)
  const [screenLoader, setScreenLoader] = useState(true);
  const [onDemandEvents, setOnDemandEvents] = useState([]);
  const [chunkedAllEvents, setChunkedAllEvents] = useState([]);
  const [chunkedDemandEvents, setChunkedDemandEvents] = useState([]);
  const [chunkedMyEvents, setChunkedMyEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [chunkedPastEvents, setChunkedPastEvents] = useState([]);

  const [shownCards, setShownCards] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('')
  const [enableSearchButton, setenableSearchButton] = useState(true)
  const [demandEventsLoadMore, setDemandEventsLoadMore] = useState(true)
  const [showEventsLoadMore, setShowEventsLoadMore] = useState(true)
  const [errorMessage, setErrorMessage] = useState({})
  const [eventsWithTags, setEventsWithTags] = useState([]);
  const [onDemandEventsWithTags, setOnDemandEventsWithTags] = useState([]);

  // ***appInsights*****
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Eventing");

  // ***appInsights*****
  // ***appInsights*****

  useEffect(async () => {
    window.scrollTo(0, 0)
    sessionStorage.setItem("setPath", JSON.stringify("event"))
    TrackActions.PageViewRecord(appInsights, "Event")
    TrackActions.PageAction("Event")
  }, [])

  useEffect(async () => {
    window.scrollTo(0, 0)
    await fetchCMSData();
    fetchEvents();
    fetchOnDemandEvents();
    // activeMatchTab('all')

    setAllEventsTab(false);
    setMyEventsTab(false);
    setOnDemandTab(false);
  }, [context?.language])

  useEffect(async () => {
    getRecommendedEvents();
  }, [recommendedLiveEvents, recommendedOnDemandEvents])

  const fetchCMSData = async () => {
    window.scrollTo(0, 0);
    setScreenLoader(true);
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let resType = '';
    let payload = {
      PageName: "Events"
    };
    await EventsActions.GetEventsCMS(payload).then((res) => {
      if (res.status === 200) {
        let resData = res.data.Pages[0];
        refCmsSA["title"] = resData?.Title;
        refCmsSA["shortDescription"] = resData?.Description;
        // refCmsRA["subTitle"] = resData?.SubTitle;

        let label = [...resData?.Components?.CommonComponent?.Labels];
        resData?.Components?.CommonComponent?.LOVList[0]?.Values?.map((x) => {
          let obj = {
            "Key": x.ID,
            "Phrase": x.Value,
          }
          label.push(obj);
        })
        resData?.Components?.['Events.EventsCourseCard'].LOVList.map((Lovs) => {
          Lovs?.Values?.map((x) => {
            let obj = {
              "Key": x.ID,
              "Phrase": x.Value,
            }
            label.push(obj);
          })
        })


        label = [...label, ...resData?.Components?.['Events.EventsCommonData']?.Labels]
        label = [...label, ...resData?.Components?.['Events.EventsCourseCard']?.Labels]
        label = [...label, ...resData?.Components?.['Events.EventsRecommendedEvents']?.Labels]

        setRecommendedEventsCMS(resData?.Components?.['Events.EventsRecommendedEvents']);
        setCommonImage(resData?.Components?.['Events.EventsCourseCard']?.MediaData);
        setFeatureImage(resData?.Components?.['Events.EventsFeatureEvents']?.MediaData);
        setPageImage(resData?.Components?.['Events.EventsPageImage']?.MediaData);

        refCmsSA["masterLabel"] = label;

        setCmsSA(refCmsSA);
      } else {
        setScreenLoader(false);
      }
    }, (err) => {
      console.log(err);
      setScreenLoader(false);
    })
  }

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsSA));
    let label = '';
    if (Object.keys(refCmsContent).length !== 0) {
      refCmsContent?.masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase
        }
      })
    }
    return label;
  }
  const fetchEvents = async (saveClick) => {
    if (saveClick) {
      setScreenLoader(false);
    } else {
      setScreenLoader(true);
    }

    let payload = {
      "cource_type": "classroom"
    };
    await EventsActions.GetEvents(payload).then((res) => {
      if (res.status === 200) {
        let filteredAllEnents = res.data?.filter(allEvents => !isNaN(allEvents.event_id));
        setEvents(filteredAllEnents);
        setShownCards(res.data.length > 8 ? 8 : 0)
        setChunkedAllEvents(res.data?.slice(0, 8));
        if (searchKeyword) {
          searchEvents(filteredAllEnents)
        }
        setFeaturedEvents(res.data?.filter(allEvents => allEvents.is_featured))
        setRecommendedLiveEvents(res.data?.filter(allEvents => allEvents.is_recommended))
        setMyEvents(res.data?.filter(allEvents => allEvents.user_enrolled))
        setChunkedMyEvents(res.data.filter(allEvents => allEvents.user_enrolled && !isPastDate(allEvents))?.slice(0, 6))
        setShowEventsLoadMore(filteredAllEnents?.length > 8)
        getPastEvents(res.data);
        setScreenLoader(false);
        setErrorMessage({
          showErrorMessage: false,
          liveEventError: '',
          myEventsError: ''
        })
      } else {
        setScreenLoader(false);
        setErrorMessage({
          showErrorMessage: true,
          liveEventError: 'Live events are not available at the moment. Please try again after sometime.',
          myEventsError: 'My events are not available at the moment. Please try again after sometime'
        })
        //navigate('/');

      }
    }, (err) => {
      setScreenLoader(false);
      // navigate('/');
    })
  }
  const isPastDate = (session) => {
    let sessionStartDate = Date.parse(new Date(session.startTime));
    let sessionEndDate = Date.parse(new Date(session.endTime));
    const todayDate = Date.parse(new Date());
    let difference = (sessionEndDate - todayDate);

    if (difference < 0) {
      return true
    } else {
      return false
    }
  }

  const getPastEvents = (allEvents) => {
    let myEvents = allEvents?.filter(allEvents => allEvents.user_enrolled)
    let pastEvent = myEvents.filter(event => isPastDate(event))
    setPastEvents(pastEvent);
    setChunkedPastEvents(pastEvent.slice(0, 6))
  }
  const getRecommendedEvents = async () => {
    if (getFeaturesConfig("EventsPage")) {
      setRecommendedEvents([...recommendedLiveEvents, ...recommendedOnDemandEvents])
      if (!allEventsTab && !myEventsTab && !onDemandTab) {
        activeMatchTab('all')
      }
    } else {
      setRecommendedEvents(recommendedOnDemandEvents)
      if (!onDemandTab) {
        activeMatchTab('onDemand')
      }
    }
    setShowLiveEvents(getFeaturesConfig("EventsPage"))
  }

  const fetchOnDemandEvents = async () => {
    let ProgramData = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : ""
    //setScreenLoader(true);
    let payload = {
      "ResourceCategory": "events",
      "ResourceSubCategory": "E3",
      "learningResourceType": "e3",
      "recommended": "false",
      "isAllLearningResources": "true",
      "ProgramClassIds": ProgramData?.programClassId ? [ProgramData?.programClassId] : ["18"]
    };
    await EventsActions.GetEvents(payload).then((res) => {
      if (res.status === 200) {
        setOnDemandEvents(res.data);
        context.setOnDemand(res.data);
        res.data && res.data.map(events => getTags(events, true))

        let recommendedOnDemandEvents = res.data?.filter(allEvents => allEvents.isRecommended);
        recommendedOnDemandEvents = recommendedOnDemandEvents.map(ondemandEvent => {
          let eventObj = {
            event_name: ondemandEvent.title,
            event_description: ondemandEvent.shortDescription,
            event_id: ondemandEvent.id,
            isSaved: ondemandEvent.isSaved,
            image_thumbnail: ondemandEvent?.largeImage?.imageUrl,
            event_type: 'E3',
            isRegistered: ondemandEvent.isAttended,
            status: ondemandEvent.isAttended,
            isAttended: ondemandEvent.isAttended,
          }
          return eventObj;
        })

        setRecommendedOnDemandEvents(recommendedOnDemandEvents)
        setChunkedDemandEvents(res.data?.slice(0, 8))
        let filteredAllEnents = res.data;
        if (searchKeyword) {
          searchEvents(filteredAllEnents)
        }
      } else {
        setScreenLoader(false);
        setErrorMessage({
          showErrorMessage: true,
          liveEventError: '',
          myEventsError: '',
          onDemandEventsError: 'OnDemand events are not available at the moment. Please try again after sometime'
        })
      }
    }, (err) => {
      console.log(err);
      // setScreenLoader(false);
    })
  }

  const fetchSearchResetData = () => {
    if (allEventsTab) {
      setChunkedAllEvents(events?.slice(0, 8));
      eventsLoadMore(events)
    } else if (onDemandTab) {
      setChunkedDemandEvents(onDemandEvents?.slice(0, 8))
      loadMoreDemandEvents(onDemandEvents)
    }
  }

  const enableSearch = (keyword) => {
    setSearchKeyword(keyword);
    if (keyword.length >= 2) {
      setenableSearchButton(false)
    } else if (keyword?.length == 0 || keyword?.length == undefined) {
      setSearchKeyword('');
      fetchSearchResetData();
      setenableSearchButton(true)
    } else {
      setenableSearchButton(true)
    }
  }

  const searchEvents = (filteredAllEnents) => {
    let searchData = [];
    let titleSearchData = [];
    let tagSearchData = [];
    if (searchKeyword) {
      if (allEventsTab) {
        if (filteredAllEnents) {
          filteredAllEnents?.map(event => getTags(event, false, true));
          filteredAllEnents?.map(eve => {
            eventsWithTags?.map(eveTags => {
              if (eve.event_id == eveTags.event_id) {
                Object.keys(eveTags).forEach(key => {
                  if (key == 'event_id') {
                    eveTags['isSaved'] = eve.isSaved
                  }
                })
              }
            })
          })
        } else {
          events.map(event => getTags(event, false, true));
        }
        let uniqueEvents = [...new Set(eventsWithTags)]
        uniqueEvents = uniqueEvents.filter((item, idx) => idx === uniqueEvents.findIndex(itm => itm.event_id === item.event_id && itm.event_code === item.event_code))
        uniqueEvents = uniqueEvents.map(x => {
          x.tags = x.tags.map(tag => tag.toLowerCase())
          if (x.tags.filter(tag => tag.includes(searchKeyword.toLowerCase()).length)) {
            return x;
          }
        })

        tagSearchData = uniqueEvents.filter(eve => {
          if (eve.tags?.filter(tag => tag.includes(searchKeyword.toLowerCase())).length)
            return eve;
        })

        titleSearchData = uniqueEvents && uniqueEvents.filter(x => x.event_name?.toLowerCase().includes(searchKeyword.toLowerCase())
          || x.event_description?.toLowerCase().includes(searchKeyword.toLowerCase()))

        searchData = [...new Set([...tagSearchData, ...titleSearchData])]
        setSearchResults(searchData)
        eventsLoadMore(searchData);
      } else if (onDemandTab) {
        if (filteredAllEnents !== undefined) {
          filteredAllEnents?.map(event => getTags(event, true, true));
          filteredAllEnents?.map(eve => {
            onDemandEventsWithTags?.map(eveTags => {
              if (eve.id == eveTags.id) {
                Object.keys(eveTags).forEach(key => {
                  if (key == 'id') {
                    eveTags['isSaved'] = eve.isSaved
                  }
                })
              }
            })
          })
        } else {
          onDemandEvents.map(onDemandEvent => getTags(onDemandEvent, true, true));
        }
        //getTags(onDemandEvents, true, true);
        let uniqueEvents = [...new Set(onDemandEventsWithTags)]
        if (filteredAllEnents !== undefined) {
          uniqueEvents = uniqueEvents.filter((item, idx) => idx === uniqueEvents.findIndex(itm => itm.id === item.id && itm.title === item.title))
        }
        uniqueEvents = uniqueEvents.map(x => {
          x.tags = x.tags.map(tag => tag.toLowerCase())
          if (x.tags.filter(tag => tag.includes(searchKeyword.toLowerCase()).length)) {
            return x;
          }
        })
        uniqueEvents.map(x => {
          Object.keys(x).forEach(key => {
            if (key === 'subjectCategory') {
              x.subjectCategoryVals = x[key].map(val => { return val.value.toLowerCase() });
            }
          })
        })

        tagSearchData = uniqueEvents.filter(eve => {
          if (eve.tags?.filter(tag => tag.includes(searchKeyword.toLowerCase())).length)
            return eve;
        })

        titleSearchData = uniqueEvents && uniqueEvents.filter((x => x.title?.toLowerCase().includes(searchKeyword.toLowerCase())
          || x.shortDescription?.toLowerCase().includes(searchKeyword.toLowerCase())))

        // let subjectCategorySearchData = uniqueEvents.filter(filt => {
        //   if (filt.subjectCategoryVals?.filter(subCat => subCat.includes(searchKeyword.toLowerCase())).length){
        //     return filt;
        //   }
        // })

        let subjectCategorySearchData = uniqueEvents.filter(filt => {
          if(filt.subjectCategoryVals?.includes(searchKeyword.toLowerCase())) {
            return filt;
          }
        })

        searchData = [...new Set([...tagSearchData, ...titleSearchData, ...subjectCategorySearchData])]
        //setChunkedDemandEvents(searchData);
        setSearchResults(searchData);
        loadMoreDemandEvents(searchData)
      } else {
        searchData = myEvents && myEvents.filter((x => x.event_name?.toLowerCase().includes(searchKeyword.toLowerCase())
          || x.event_description?.toLowerCase().includes(searchKeyword.toLowerCase())))
        // setChunkedMyEvents(searchData);
        setSearchResults(searchData)
      }
    }
  }
  const loadMoreMyEvents = (type) => {

    if (type === 'past') {

      let pastEventCount = chunkedPastEvents.length + 6;

      setChunkedPastEvents(pastEvents.slice(0, pastEventCount));
    } else {
      let myEventCount = chunkedMyEvents.length + 6;

      setChunkedMyEvents(myEvents.slice(0, myEventCount));
    }


  }

  const displayLoadMore = (load, type) => {
    return (

      load && <Cirrus.Button
        onClick={() => {
          allEventsTab ? eventsLoadMore() : onDemandTab ? loadMoreDemandEvents() : loadMoreMyEvents(type);
        }}
      >
        {fetchLabel("CommonComponent.CommonLoadMore")}
      </Cirrus.Button>
    )
  }
  const updateEvents = async (eventDetails, onDemand) => {
    let id = eventDetails.event_id ? eventDetails.event_id : eventDetails.id ? eventDetails.id : '';
    let payload = {
      "ResourceType": "External",
      // "EventType": eventDetails?.event_type ? fetchLabel(eventDetails.event_type) : 'onDemand',
      "EventType": eventDetails?.resourceType?.id || eventDetails?.event_type == 'E3' ? 'onDemand' : fetchLabel(eventDetails.event_type),
      "EventName": onDemand ? eventDetails?.title : eventDetails?.event_name,
      "IsSaved": !eventDetails.isSaved,
      "Status": eventDetails.status,
      "IsRegistered": eventDetails?.isRegistered,
      "RegisteredTimeStamp": eventDetails?.RegisteredTimeStamp,
      // "EventDate": eventDetails?.eventDate,
      "EventDate": eventDetails?.resourceType?.id || eventDetails?.event_type == 'E3' ? undefined : eventDetails?.eventDate,
      "isClientSpecific": eventDetails?.isClientSpecific,
      // "EventDate": new Date(),
      // "STARTTIME": "00:00",
      // "ENDTIME": "00:00",
      "STARTTIME": eventDetails?.startTime,
      "ENDTIME": eventDetails?.endTime,
      "StartDate": eventDetails?.startTime,
      "EndDate": eventDetails?.endTime,
      "ResourceId": eventDetails?.event_id
    }

    await EventsActions.UpdateEvent(payload, id).then((res) => {
      if (res.status === 200) {
        if (eventDetails?.event_type == "E3" || onDemand) {
          fetchOnDemandEvents();
        } else {
          setScreenLoader(false);
          fetchEvents(true);
        }
      }
    }, (err) => {
      console.log(err);
      setScreenLoader(false);
    })
  }

  const eventsLoadMore = (results) => {
    let searchData = searchResults;
    let refchunkedData = JSON.parse(JSON.stringify(chunkedAllEvents))
    let refshownCards = JSON.parse(JSON.stringify(shownCards))
    if (results) {
      setShownCards(0);
      refshownCards = 8;
      searchData = results;
    } else {
      refshownCards = shownCards + 8;
    }
    setShownCards(refshownCards)
    if (searchKeyword.length) {
      refchunkedData = searchData && searchData.slice(0, refshownCards)
    } else {
      refchunkedData = events && events.slice(0, refshownCards)
    }
    setChunkedAllEvents(refchunkedData)
    if (searchKeyword.length) {
      if (searchData.length >= 8 && searchData.length == refchunkedData.length) {
        setShowEventsLoadMore(false)
      } else {
        setShowEventsLoadMore(searchData.length > 8)
      }
    } else {
      if (events.length == refchunkedData.length) {
        setShowEventsLoadMore(false)
      } else {
        setShowEventsLoadMore(true)
      }

    }

  }
  const loadMoreDemandEvents = (results) => {
    let searchData = searchResults;
    let refchunkedData = JSON.parse(JSON.stringify(chunkedDemandEvents))
    let refshownCards = JSON.parse(JSON.stringify(shownCards))
    if (results) {
      setShownCards(0);
      refshownCards = 8;
      searchData = results;
    } else {
      refshownCards = shownCards + 8;
    }
    setShownCards(refshownCards)
    if (searchKeyword.length) {
      refchunkedData = searchData && searchData.slice(0, refshownCards)
    } else {
      refchunkedData = onDemandEvents && onDemandEvents.slice(0, refshownCards)
    }
    setChunkedDemandEvents(refchunkedData)
    if (searchKeyword.length) {
      if (searchData.length > 8 && searchData.length == refchunkedData.length) {
        setDemandEventsLoadMore(false)
      } else {
        setDemandEventsLoadMore(searchData.length > 8)
      }
    } else {
      if (onDemandEvents.length == refchunkedData.length) {
        setDemandEventsLoadMore(false)
      } else {
        setDemandEventsLoadMore(true)
      }

    }

  }

  const getEventStatus = (eventDetails) => {
    let statusLabel = '';
    if (eventDetails.isRegistered || eventDetails.user_enrolled) {
      if (eventDetails?.status && typeof eventDetails?.status === 'string') {
        if(eventDetails?.user_enrolled > 0 && !eventDetails?.waiting){
          statusLabel = fetchLabel('enrolled');
        } else if(eventDetails?.user_enrolled > 0 && eventDetails?.waiting){
            statusLabel = fetchLabel('waitlisted');
        } else if (eventDetails?.status?.toLowerCase() === 'completed') {
          statusLabel = fetchLabel('completed');
        }
          return statusLabel
      } 
    }     
  }

  const activeMatchTab = (type) => {

    if (type === 'all') {
      setAllEventsTab(true);
      setMyEventsTab(false);
      setOnDemandTab(false);
      setSearchKeyword('');
      setChunkedAllEvents(events && events.slice(0, 8));
      setShowEventsLoadMore(events?.length > 8)
      if (getFeaturesConfig("EventsPage") === false) {
        setAllEventsTab(false);
        setMyEventsTab(false);
        setOnDemandTab(true);

        setChunkedDemandEvents(onDemandEvents && onDemandEvents.slice(0, 8));
        setDemandEventsLoadMore(onDemandEvents?.length > 8)
      }
    } else if (type === 'myEvents') {
      setAllEventsTab(false);
      setMyEventsTab(true);
      setOnDemandTab(false);
      setSearchKeyword('');
      //setMyEvents(myEvents && myEvents.slice(0, 6));

    } else {
      setAllEventsTab(false);
      setMyEventsTab(false);
      setOnDemandTab(true);
      setSearchKeyword('');

      setChunkedDemandEvents(onDemandEvents && onDemandEvents.slice(0, 8));
      setDemandEventsLoadMore(onDemandEvents?.length > 8)
      // fetchOnDemandEvents();
    }
  };

  const getTags = (event, isOnDemad, isSearch) => {
    let programTags = [];
    let recommended = false;
    let tagNames = [];
    if (isOnDemad) {
      programTags = event.focusArea?.map(tag => tag.id);
      recommended = event.isRecommended

    } else {
      programTags = event.program_category?.replace(/ /g, "").match(/.{1,3}/g);
      recommended = event.is_recommended;
    }


    tagNames = programTags && programTags.map((PCcode) => fetchLabel(PCcode))
    if (recommended) {
      if (tagNames) {
        tagNames.push(fetchLabel('Events.EventsCourseCard.Recommended'))
      } else {
        tagNames = [fetchLabel('Events.EventsCourseCard.Recommended')]
      }
    }
    //    let recommendedTag = recommended? tagNames?.push(fetchLabel('Events.EventsCourseCard.Recommended')):;
    event.tags = tagNames ? tagNames : [];
    if (isSearch) {
      if (isOnDemad) {
        onDemandEventsWithTags.push(event);
      } else {
        eventsWithTags.push(event);
      }
    }
    return tagNames;
  }

  let featuresConfig = JSON.parse(window.sessionStorage.getItem("featuresConfig"))
  function getFeaturesConfig(featureName) {
    let val = false;
    featuresConfig?.features?.map(feature => {
      if (feature.featureName === featureName) {
        val = feature.isActive
      }
    })
    return val;
  }

  const getDescription = (desc) => {
    return <Cirrus.RichText content={desc} />
  }
  return (
    <>
      {/* <HeaderWithNavigation /> */}
      <Cirrus.PageBanner
        heading={cmsSA['title']}
        type="large"
        bodyText={cmsSA['shortDescription']}
        maskImageSource={pageImage?.LargeImage?.ImageUrl}
      />

      {screenLoader ?
        <div>
          <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>
        :
        <Cirrus.OneColumnLayout type="wrapped" onHover={trackComponent}>

          <div className="self-discovery">
            <Cirrus.JobMatchesLayout>
              <Cirrus.JobMatchesLayout.GridCell gridArea="featured">
                {featuredEvents.length ? <Cirrus.FeaturedCard
                  heading={featuredEvents[0].event_name}
                  bodyText={getDescription(featuredEvents[0].event_description.split('</p>')[0])}
                  imageProps={{
                    src: featuredEvents[0]?.image_thumbnail ? featuredEvents[0]?.image_thumbnail : Object.keys(featureImage).length !== 0 ? featureImage?.LargeImage?.ImageUrl : '',
                  }}
                  primaryButtonProps={{
                    children: fetchLabel("Events.EventsCommonData.MoreInformation"),
                    size: "Large",
                    href: "#/eventDetails/:id/:isSaved".replace(":id", featuredEvents[0]?.event_id)
                      .replace(":isSaved", featuredEvents[0]?.isSaved ? featuredEvents[0]?.isSaved : false),
                    isAlt: true
                  }}

                  isWide={true}
                  className="clsHeading"
                  type="assessment"
                  layout="horizontal"
                />
                  : ''
                }
              </Cirrus.JobMatchesLayout.GridCell>
            </Cirrus.JobMatchesLayout>

          </div>
          {recommendedEvents.length ?

            <div className="recommendedEvents event-page-recommended">
              {recommendedEvents.length > 3 ?
                <div className="carousel ">
                  <Cirrus.Carousel
                    className='p-3'
                    nextIconButtonProps={{
                      icon: 'arrow_forward',
                      onClick: () => { },
                      text: 'Next',
                      type: 'button'
                    }}
                    previousIconButtonProps={{
                      icon: 'arrow_back',
                      onClick: function noRefCheck() { },
                      text: 'Previous',
                      type: 'button'
                    }}
                    heading={recommendedEventsCMS?.Title}
                    subheading={recommendedEventsCMS?.Description}
                  >
                    {/* {recommendedEvents?.slice(0, recommendedEvents.length > 12 ? recommendedEvents.length : recommendedEvents.length).map((allEvents, index) => { */}
                    {recommendedEvents?.slice(0, recommendedEvents.length).map((allEvents, index) => {
                      return <div>
                        <Cirrus.RelatedResourcesCard
                          key={index}
                          className="EventCard"
                          title={allEvents.event_name}
                          description={getDescription(allEvents?.event_description.split('</p>')[0])}
                          onCardClick={() => {
                            if (allEvents?.event_type == "E3") {
                              window.open(`#/article-page/${allEvents?.event_id}`, "_self");
                            } else {
                              window.open(`#/eventDetails/${allEvents?.event_id}/${allEvents?.isSaved ? allEvents.isSaved : false}`, "_self");
                            }
                          }}
                          tags={getTags(allEvents)}
                          image={allEvents.image_thumbnail ? { src: allEvents.image_thumbnail } : { src: Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : '' }}
                          saveButton={{
                            label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                            secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                            onClick: (e) => {
                              updateEvents(allEvents)
                            },
                          }}
                          status={{
                            name: 'new',
                            label: 'Assessment',
                            value: '40 min'
                          }}
                          type='event'
                          layout="vertical"
                          eventType={{
                            eventDetails: [
                              fetchLabel(allEvents.event_type)
                            ],
                            isSuccess: true,
                            status: getEventStatus(allEvents)
                          }}
                          isSaved={allEvents?.isSaved ? true : false}
                        ></Cirrus.RelatedResourcesCard>
                      </div>
                      //}
                    })}

                  </Cirrus.Carousel>
                </div>
                :
                <Cirrus.Carousel
                  heading={recommendedEventsCMS?.Title}
                  subheading={recommendedEventsCMS?.Description}
                >
                  {recommendedEvents.map((allEvents) => {
                    return <div className="recommendedEventsCard">
                      <Cirrus.RelatedResourcesCard
                        className="EventCard"
                        title={allEvents.event_name}
                        description={getDescription(allEvents.event_description.split('</p>')[0])}
                        onCardClick={() => {
                          if (allEvents?.event_type == "E3") {
                            window.open(`#/article-page/${allEvents?.event_id}`, "_self");
                          } else {
                            window.open(`#/eventDetails/${allEvents?.event_id}/${allEvents?.isSaved ? allEvents.isSaved : false}`, "_self");
                          }
                        }}
                        tags={getTags(allEvents)}
                        image={allEvents.image_thumbnail ? { src: allEvents.image_thumbnail } : { src: Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : '' }}
                        saveButton={{
                          label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                          secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                          onClick: (e) => { updateEvents(allEvents) },
                        }}
                        status={{
                          name: 'new',
                          label: 'Assessment',
                          value: '40 min'
                        }}
                        type='event'
                        layout="vertical"
                        eventType={{
                          "eventDetails": [
                            fetchLabel(allEvents.event_type)],
                          isSuccess: true,
                          status: getEventStatus(allEvents)
                        }}
                        isSaved={allEvents?.isSaved ? true : false}
                      ></Cirrus.RelatedResourcesCard>
                    </div>
                  })
                  }
                </Cirrus.Carousel>
              }

            </div> : ''
          }

          <Cirrus.Placeholder style={{ float: "left", width: "100%", padding: '0' }}>
            <p className='events-mobile_title'>View</p>
            {showLiveEvents ? <Cirrus.TabbedNavigation
              defaultSelected="all"
              onChange={(selectedTab) => { activeMatchTab(selectedTab) }}
              tabs={[
                {
                  heading: fetchLabel("Events.EventsCommonData.AllAvailableEvents"),
                  id: 'all',
                  tabpanelId: "all-upcoming-events",
                  onClick: () => {
                    activeMatchTab('all');
                  },
                  isVisible: getFeaturesConfig("EventsPage"),
                },
                {
                  heading: fetchLabel("Events.EventsCommonData.OnDemand"),
                  id: 'onDemand',
                  tabpanelId: "on-demand",
                  onClick: () => {
                    activeMatchTab('onDemand');
                  },
                  isVisible: featuresConfig?.isOnDemandEventFeatureAvailable,
                },
                {
                  heading: fetchLabel("Events.EventsCommonData.MyEvents"),
                  id: 'myEvents',
                  tabpanelId: "my-events",
                  onClick: () => {
                    activeMatchTab('myEvents');
                  },
                  isVisible: true,
                }
              ]}
            ></Cirrus.TabbedNavigation>
              : <Cirrus.TabbedNavigation
                defaultSelected="onDemand"
                onChange={(selectedTab) => { activeMatchTab(selectedTab) }}
                tabs={[

                  {
                    heading: fetchLabel("Events.EventsCommonData.OnDemand"),
                    id: 'onDemand',
                    tabpanelId: "onDemand",
                    onClick: () => {
                      activeMatchTab('onDemand');
                    },
                    isVisible: true,
                  }

                ]}
              ></Cirrus.TabbedNavigation>
            }
          </Cirrus.Placeholder>

          {allEventsTab && <div className="self-discovery">
            {events.length ?
              <>
                <div className='allEvents_searchblock'>
                  <h3 className="mb-lg-4 mb-2 allevents_heading-title">{fetchLabel("Events.EventsCommonData.AllAvailableEvents")}</h3>
                  <div className="search-filters">
                    <Cirrus.Input
                      icon="search"
                      id="search"
                      isRounded
                      onBlur={() => { }}
                      maxLength={50}
                      onChange={(event) => enableSearch(event.target.value)}
                      searchButtonProps={{
                        children: fetchLabel("Events.EventsCommonData.SearchButton"),
                        size: 'large',
                        type: 'button',
                        onClick: () => searchEvents(),
                        disabled: enableSearchButton
                      }}
                      showSearchButton
                      placeholder={fetchLabel("Events.EventsCommonData.KeywordSearchPlaceholder")}
                      onKeyPress={(event) => event.key === 'Enter' ? searchEvents() : null}
                    />
                  </div>
                </div>

                <div className="search-filters-mobile">
                  <Cirrus.Input
                    className='mb-3'
                    icon="search"
                    id="search"
                    isRounded
                    onBlur={() => { }}
                    maxLength={50}
                    onChange={(event) => enableSearch(event.target.value)}
                    searchButtonProps={{
                      children: fetchLabel("Events.EventsCommonData.SearchButton"),
                      // size: 'large',
                      type: 'button',
                      onClick: () => searchEvents(),
                      disabled: enableSearchButton
                    }}
                    placeholder={fetchLabel("Events.EventsCommonData.KeywordSearchPlaceholder")}
                    onKeyPress={(event) => event.key === 'Enter' ? searchEvents() : null}
                  />
                  <Cirrus.Button
                    className='mb-5'
                    children={fetchLabel("Events.EventsCommonData.SearchButton")}
                    onClick={() => searchEvents()}
                    disabled={enableSearchButton}
                    isFullWidth={true}
                  />
                </div>

                <div className="events-block">
                  {chunkedAllEvents.length ? chunkedAllEvents.map((allEvents) => {
                    return <>
                      {!isNaN(allEvents.event_id) && <div className="events-card">
                        <Cirrus.Card
                          title={allEvents.event_name}
                          description={getDescription(allEvents.event_description.split('</p>')[0])}
                          onCardClick={() => {
                            window.open(`#/eventDetails/${allEvents?.event_id}/${allEvents?.isSaved ? allEvents.isSaved : false}`, "_self");
                          }}
                          // tags={getTags(allEvents)}
                          tags={getTags(allEvents)?.toString().length === 0 ? '' : getTags(allEvents)}
                          image={allEvents.image_thumbnail ? { src: allEvents.image_thumbnail } : { src: Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : '' }}
                          saveButton={{
                            label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                            secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                            onClick: (e) => { updateEvents(allEvents) },
                          }}
                          status={{
                            name: 'new',
                            label: 'Assessment',
                            value: '40 min'
                          }}
                          type='event'
                          layout="horizontal"
                          eventType={{
                            "eventDetails": [
                              fetchLabel(allEvents.event_type)
                            ],
                            isSuccess: true,
                            status: getEventStatus(allEvents)
                          }}
                          isSaved={allEvents?.isSaved ? true : false}
                        />

                      </div>
                      }
                    </>
                  })
                    :
                    <p className='nodata-text'>{fetchLabel("Events.EventsCommonData.NoCoursesFoundMessage")}</p>
                  }

                </div>

                {displayLoadMore(showEventsLoadMore)}
              </>
              : errorMessage.showErrorMessage ? <p className='nodata-text'>{fetchLabel("Events.EventsCommonData.LiveEventTechnicalErrorMessage")}</p>
                : <p className='nodata-text'>{fetchLabel("Events.EventsCommonData.NoUpComingEventsMessage")}</p>}
          </div>
          }

          {onDemandTab && <div className="self-discovery">
            {/* {chunkedDemandEvents && chunkedDemandEvents.length ?  */}
            <div className='allEvents_searchblock'>
              <h3 className="mb-lg-4 mb-2 allevents_heading-title">{fetchLabel("Events.EventsCommonData.OnDemand")}</h3>
              <div className="search-filters">
                <Cirrus.Input
                  icon="search"
                  id="search"
                  isRounded
                  onBlur={() => { }}
                  maxLength={50}
                  onChange={(event) => enableSearch(event.target.value)}
                  searchButtonProps={{
                    children: fetchLabel("Events.EventsCommonData.SearchButton"),
                    size: 'large',
                    type: 'button',
                    onClick: () => searchEvents(),
                    disabled: enableSearchButton
                  }}
                  showSearchButton
                  placeholder={fetchLabel("Events.EventsCommonData.KeywordSearchPlaceholder")}
                  onKeyPress={(event) => event.key === 'Enter' ? searchEvents() : null}
                />
              </div>
            </div>

            <div className="search-filters-mobile">
              <Cirrus.Input
                className='mb-3'
                icon="search"
                id="search"
                isRounded
                onBlur={() => { }}
                maxLength={50}
                onChange={(event) => enableSearch(event.target.value)}
                searchButtonProps={{
                  children: fetchLabel("Events.EventsCommonData.SearchButton"),
                  size: 'large',
                  type: 'button',
                  onClick: () => searchEvents(),
                  disabled: enableSearchButton
                }}
                placeholder={fetchLabel("Events.EventsCommonData.KeywordSearchPlaceholder")}
                onKeyPress={(event) => event.key === 'Enter' ? searchEvents() : null}
              />
              <Cirrus.Button
                className='mb-5'
                children={fetchLabel("Events.EventsCommonData.SearchButton")}
                onClick={() => searchEvents()}
                disabled={enableSearchButton}
                isFullWidth={true}
              />
            </div>
            {chunkedDemandEvents && chunkedDemandEvents.length ? <>
              <div className="events-block">

                {chunkedDemandEvents.map((allEvents) => {
                  context.resourceDetails = allEvents;
                  return <div className="events-card">
                    <Cirrus.Card
                      title={allEvents.title}
                      description={allEvents.shortDescription}
                      onCardClick={() => {
                        window.open(`#/article-page/${allEvents?.id}`, "_self");
                      }}
                      tags={getTags(allEvents, true)}
                      image={{
                        src: allEvents?.largeImage?.imageUrl ? allEvents?.largeImage?.imageUrl : Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : ''
                      }}//|| allEvents.image_thumbnail ?{ src: allEvents.image_thumbnail }: {src: commonImage}}
                      saveButton={{
                        label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                        secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                        onClick: (e) => { updateEvents(allEvents, 'true') },
                      }}
                      status={{
                        name: 'new',
                        label: 'Assessment',
                        value: '40 min'
                      }}
                      // type='resource'
                      type='event'
                      layout="horizontal"
                      resourceType={{
                        name: 'video',
                        label: allEvents.isAttended ? 'Watch Again' : 'Watch Now',
                        value: ''
                      }}
                      isSaved={allEvents?.isSaved ? true : false}

                    />

                  </div>

                })}
              </div>
              <div>{displayLoadMore(demandEventsLoadMore)}</div>
            </>

              : errorMessage.showErrorMessage ? fetchLabel("Events.EventsCommonData.NoCardsOnDemandMessage") :
                <p className='nodata-text'>{fetchLabel("Events.EventsCommonData.NoCardsOnDemandMessage")}</p>
            }
          </div>
          }


          {myEventsTab &&
            <>
              <div className="self-discovery myeventsWrapper myevents_section">
                <h3 className="mb-lg-4 mb-2 allevents_heading-title">{fetchLabel("Events.EventsCommonData.MyEvents")}</h3>

                {chunkedMyEvents && chunkedMyEvents.length ?
                  <>
                    <div className="events-block">
                      {chunkedMyEvents.map((allEvents) => {
                        return <>
                          {!isNaN(allEvents.event_id) && <div className="events-card">
                            <Cirrus.Card
                              title={allEvents.event_name}
                              description={getDescription(allEvents.event_description.split('</p>')[0])}
                              onCardClick={() => {
                                if (allEvents?.event_type == "E3") {
                                  window.open(`#/article-page/${allEvents?.event_id}`, "_self");
                                } else {
                                  window.open(`/#/eventDetails/${allEvents?.event_id}/${allEvents?.isSaved ? allEvents.isSaved : false}`, "_self")
                                }
                              }}
                              tags={getTags(allEvents)}
                              image={allEvents.image_thumbnail ? { src: allEvents.image_thumbnail } : { src: Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : '' }}
                              saveButton={{
                                label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                                secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                                onClick: (e) => { updateEvents(allEvents) },
                              }}
                              status={{
                                name: 'new',
                                label: 'Assessment',
                                value: '40 min'
                              }}
                              type='event'
                              layout="horizontal"
                              eventType={{
                                "eventDetails": [
                                  fetchLabel(allEvents.event_type)
                                ],
                                status: getEventStatus(allEvents),
                                isSuccess: true
                              }}
                              isSaved={allEvents?.isSaved ? true : false}
                            />
                          </div>
                          }
                        </>
                      })}

                    </div>
                    <div>{displayLoadMore(chunkedMyEvents.length > myEvents.length)}</div>
                  </>

                  : errorMessage.showErrorMessage ? fetchLabel("Events.EventsCommonData.MyEventsTechnicalIssue") :
                    <div>
                      <p className='nodata-text'>{fetchLabel("Events.EventsCommonData.NoRegisteredEvents")}</p>
                      <Cirrus.Button
                        onClick={() => {
                          document.querySelector("#all").click()
                        }}
                      >
                        {fetchLabel("Events.EventsCommonData.FindAnEvent")}
                      </Cirrus.Button>
                    </div>
                }
              </div>
              <div className="self-discovery myeventsWrapper">{!errorMessage.showErrorMessage ? <>
                <h3 className="mb-4">{fetchLabel("Events.EventsCommonData.PastEvents")}</h3>

                {pastEvents && pastEvents.length ?
                  <>
                    <div className="events-block">
                      {chunkedPastEvents.map((allEvents) => {
                        return <>
                          {!isNaN(allEvents.event_id) && <div className="events-card">
                            <Cirrus.Card
                              title={allEvents.event_name}
                              description={getDescription(allEvents.event_description.split('</p>')[0])}
                              onCardClick={() => {
                                if (allEvents?.event_type == "E3") {
                                  window.open(`#/article-page/${allEvents?.event_id}`, "_self");
                                } else {
                                  window.open(`/#/eventDetails/${allEvents?.event_id}/${allEvents?.isSaved ? allEvents.isSaved : false}`, "_self")
                                }
                              }}
                              tags={getTags(allEvents)}
                              image={allEvents.image_thumbnail ? { src: allEvents.image_thumbnail } : { src: Object.keys(commonImage).length !== 0 ? commonImage?.LargeImage?.ImageUrl : '' }}
                              saveButton={{
                                label: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                                secondaryLabel: allEvents?.isSaved ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton"),
                                onClick: (e) => { updateEvents(allEvents) },
                              }}
                              status={{
                                name: 'new',
                                label: 'Assessment',
                                value: '40 min'
                              }}
                              type='event'
                              layout="horizontal"
                              eventType={{
                                "eventDetails": [
                                  fetchLabel(allEvents.event_type)
                                ],
                                status: getEventStatus(allEvents),
                                isSuccess: true
                              }}
                              isSaved={allEvents?.isSaved ? true : false}
                            />

                          </div>
                          }
                        </>
                      })}

                    </div>
                    <div>{displayLoadMore(chunkedPastEvents.length < pastEvents.length, 'past')}</div>
                  </>
                  :
                  ''

                }</> : ''}
              </div>
            </>
          }

        </Cirrus.OneColumnLayout>
      }



      {/* <Footer isLoggedIn={true} /> */}
    </>
  )
}


export default Events
