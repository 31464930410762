//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useState, useEffect } from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import Moment from 'moment';
import { useParams, useNavigate } from "react-router-dom";
import { Context } from '../../common/context/Context';
import { BookMarkActions } from "./bookmarkActions";
import Spinner from '../../common/components/spinner-loader/Spinner';

function JobDetails() {
    const context = useContext(Context);
    const pageOrgin = useParams();
    const navigate = useNavigate();
    const [screenLoader, setScreenLoader] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [appliedButton, setAppliedButton] = useState(false);

    const [cmsJobDetailsContent, setCmsJobDetailsContent] = useState({
        browserTitle: '',
        description: '',
        metadata: '',
        pageContent: {}
    })
    const [selectJobForDetails, setSelectJobForDetails] = useState([])
    let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];


    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language]);

    const fetchCMSData = async () => {
        setScreenLoader(true)
        let refCmsJobDetailsContent = JSON.parse(JSON.stringify(cmsJobDetailsContent));
        const cmsPayload = {
            PageName: "JobDetails Page"
        }
        await BookMarkActions.GetCMSBookmarkheaderLabels(cmsPayload).then((res) => {
            if (res?.status === 200) {
                let resData = res.data && res.data;
                resData?.Pages?.filter((x) => {
                    if (x.Key === "jobdetailspage") {
                        refCmsJobDetailsContent.browserTitle = x.BrowserTitle;
                        refCmsJobDetailsContent.description = x.Description;
                        refCmsJobDetailsContent.metadata = x.Metadata;
                        refCmsJobDetailsContent.pageContent = x.Components['JobSearch.JobDetails'];
                    }
                })
                setCmsJobDetailsContent(refCmsJobDetailsContent);
            } else {
                setScreenLoader(true)
            }
        }).catch((err) => {
            console.log(err);
            setScreenLoader(true)
        });

        let savedJob = [];
        await BookMarkActions.GetJobSavedStatus(candidateID).then((res) => {
            if (res?.status === 200) {
                savedJob = res?.data?.filter((item) => {
                    if (item.jobId === pageOrgin.id) {
                        item["isApplied"] = false;

                        return item;
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
        });

        await BookMarkActions.GetAppliedJobs().then((res) => {
            if (res?.status === 200) {
                savedJob?.map((save) => {
                    res?.data?.map((apply) => {
                        if (save.jobId === apply.jobId) {
                            save["isApplied"] = true;
                        }
                    })
                    return save;
                })
            }
        }).catch((err) => {
            console.log(err);
        });
        setSaveButton(savedJob[0].isSaved)
        setAppliedButton(savedJob[0].isApplied)
        setSelectJobForDetails(savedJob)
        setScreenLoader(false)
    }

    const saveJob = async () => {
        const saveData = {
            "OwnerId": candidateID,
            "OwnerType": "Candidate",
            "JobId": selectJobForDetails[0]?.jobId,
            "JobTitle": selectJobForDetails[0]?.jobTitle != undefined ? selectJobForDetails[0]?.jobTitle : '',
            "CompanyName": selectJobForDetails[0]?.companyName != undefined ? selectJobForDetails[0]?.companyName : '',
            "JobUrl": selectJobForDetails[0]?.jobUrl != undefined ? selectJobForDetails[0]?.jobUrl : '',
            "IsFeaturedJob": 1,
            "IsSaved": saveButton === true ? 0 : 1,
            "IsBookmarked": saveButton === true ? 0 : 1,
            "IsDeleted": 0,
            "JobPostedTimeStamp": selectJobForDetails[0]?.jobPostedTimeStamp != undefined ? selectJobForDetails[0]?.jobPostedTimeStamp : '',
            "LastSavedTimeStamp": todayDate(new Date()),
            "CreatedTimeStamp": todayDate(new Date()),
            "CreatedBy": '',
            "MatchPercentage": selectJobForDetails[0]?.matchPercentage != undefined ? selectJobForDetails[0]?.matchPercentage : "",
            "JobLocation": selectJobForDetails[0]?.jobLocation != undefined ? selectJobForDetails[0]?.jobLocation : '',
            "Salary": selectJobForDetails[0]?.salary != undefined ? selectJobForDetails[0]?.salary : 0,
            "EmploymentType": selectJobForDetails[0]?.employmentType != undefined ? selectJobForDetails[0]?.employmentType : '',
        }

        await BookMarkActions.PostSaveJob(candidateID, saveData).then(res => {
            if (res.status === 200 && res.data === true) {
                setSaveButton(!saveButton)
            }
        }, err => {
            console.log(err);
        });
    }

    const iHaveApplied = () => {
        const appliedData = {
            "OwnerId": candidateID,
            "OwnerType": "Candidate",
            "JobId": selectJobForDetails[0]?.jobId,
            "JobTitle": selectJobForDetails[0]?.jobTitle != undefined ? selectJobForDetails[0]?.jobTitle : '',
            "CompanyName": selectJobForDetails[0]?.companyName != undefined ? selectJobForDetails[0]?.companyName : '',
            "JobDescription": selectJobForDetails[0]?.jobDescription != undefined ? selectJobForDetails[0]?.jobDescription : '',
            "JobUrl": selectJobForDetails[0]?.jobUrl != undefined ? selectJobForDetails[0]?.jobUrl : '',
            "IsFeaturedJob": 1,
            "IsSaved": 1,
            "IsBookmarked": 1,
            "IsDeleted": 0,
            "JobPostedTimeStamp": selectJobForDetails[0]?.jobPostedTimeStamp != undefined ? selectJobForDetails[0]?.jobPostedTimeStamp : '',
            "LastSavedTimeStamp": todayDate(new Date()),
            "CreatedTimeStamp": todayDate(new Date()),
            "CreatedBy": '',
            "MatchPercentage": selectJobForDetails[0]?.matchPercentage != undefined ? selectJobForDetails[0]?.matchPercentage : "",
            "JobLocation": selectJobForDetails[0]?.jobLocation != undefined ? selectJobForDetails[0]?.jobLocation : '',
            "Salary": selectJobForDetails[0]?.salary != undefined ? selectJobForDetails[0]?.salary : 0,
            "EmploymentType": selectJobForDetails[0]?.employmentType != undefined ? selectJobForDetails[0]?.employmentType : '',
        }

        BookMarkActions.AppliedJob(candidateID, appliedData).then(res => {
            if (res.status === 200 && res.data === true) {
                setAppliedButton(!appliedButton)
            }
        }, err => {
            console.log(err);
        });
    }

    const applyForTheRole = () => {
        window.open(selectJobForDetails[0]?.jobUrl, '_blank');
    }

    const fetchJobDetailsLabel = (key) => {
        let refCmsJobContent = JSON.parse(JSON.stringify(cmsJobDetailsContent));
        let label = '';
        if (refCmsJobContent?.pageContent?.Labels) {
            refCmsJobContent?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const todayDate = (date) => {
        let convDate = Moment(date).format("MM-DD-YYYY")
        return convDate;
    }

    const dateFormat = (date) => {
        let convDate = Moment(date).format("DD/MM/YYYY");
        return convDate;
    }

    if (screenLoader) {
        return (<div>
            <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>)
    }

    return (
        <>
            <Cirrus.JobDetails
                applicationDeadline={selectJobForDetails.length > 0 && selectJobForDetails[0] && selectJobForDetails[0].deadlineDate && selectJobForDetails[0].deadlineDate}
                appliedButtonProps={{
                    children: appliedButton ? fetchJobDetailsLabel('JobSearch.JobDetails.Applied') : fetchJobDetailsLabel('JobSearch.JobDetails.IHaveApplied'),
                    isSecondary: true,
                    onClick: () => { iHaveApplied() },
                    size: 'Large',
                    type: 'button'
                }}
                applyButtonProps={{
                    children: fetchJobDetailsLabel('JobSearch.JobDetails.ApplyForTheRole'),
                    onClick: applyForTheRole,
                    size: 'Large',
                    type: 'button'
                }}
                backButtonProps={{
                    children: fetchJobDetailsLabel('JobSearch.JobDetails.Back'),
                    isSecondary: true,
                    onClick: () => {
                        navigate("/" + pageOrgin.origin)
                    },
                    size: 'Small',
                    type: 'button'
                }}
                bookmarkButtonProps={{
                    children: fetchJobDetailsLabel('JobSearch.JobDetails.Save'),
                    isSecondary: saveButton === true ? false : true,
                    leadingIcon: saveButton === true ? 'saved' : 'save',
                    onClick: saveJob,
                    size: 'Large',
                    type: 'button'
                }}
                companyName={selectJobForDetails[0]?.companyName != undefined ? selectJobForDetails[0]?.companyName : "NA"}
                contractType={selectJobForDetails[0]?.employmentType != undefined ? selectJobForDetails[0]?.employmentType : "NA"}
                description={[
                    {
                        sectionBody: selectJobForDetails[0]?.jobDescription != undefined ? selectJobForDetails[0]?.jobDescription : "NA",
                        sectionHeading: fetchJobDetailsLabel('JobSearch.JobDetails.JobDescription')
                    },
                ]}
                jobRole={selectJobForDetails[0]?.jobTitle}
                location={selectJobForDetails[0]?.jobLocation != undefined ? selectJobForDetails[0]?.jobLocation : "NA"}
                salary={selectJobForDetails[0]?.salary != undefined ? selectJobForDetails[0]?.salary : "NA"}
                startDate={selectJobForDetails[0]?.jobPostedTimeStamp != undefined ? dateFormat(selectJobForDetails[0]?.jobPostedTimeStamp) : "NA"}
                staticCopy={{
                    Company: 'Company:',
                    ContractType: 'Type:',
                    Deadline: selectJobForDetails.length > 0 && selectJobForDetails[0] && selectJobForDetails[0].deadlineDate && 'Deadline to apply:',
                    Location: 'Location:',
                    RelatedVacanciesHeading: 'You may be interested in...',
                    Salary: 'Salary:',
                    StartDate: 'Start date:'
                }}
            />
        </>
    )
}

export default JobDetails
