//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from '../../common/context/Context';
import './Job.css';
import Moment from "moment";
import { JobActions } from "./JobActions";
import JobCard from './JobCard';
import Spinner from "../../common/components/spinner-loader/Spinner";
import JobDetails from './JobDetails';
import womanOnLaptop from 'manpowergroup-cirrus/assets/images/womanOnLaptop.png';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../common/utility/LogAction';

function JobSavedTab({ tabStatus, JDshowHide, savedCMSData, jobCardCMSData, saveUnsaveFlag, cmsJobWidgetContent }) {
    const context = useContext(Context);
    const [jobLoader, setJobLoader] = useState(false);
    const [forTabStatus, setForTabStatus] = useState({});
    const [hasJob, setHasJob] = useState();
    const [paginationState, setPaginationState] = useState({
        desabledLoadMore: false,
        searchCountAndLabel: '',
        totalCounts: 0,
        fetchedCounts: 0,
        shownCounts: 0,
        payload: {},
    });
    const [fetchedJobs, setFetchedJobs] = useState([]);
    const [shownJobs, setShownJobs] = useState([]);
    const [appliedjobs, setAppliedjobs] = useState([]);
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (tabStatus.savedjobsTab === true) {
            setForTabStatus(tabStatus)
            getAllSavedjobs();
        }
        TrackActions.PageViewRecord(appInsights, "Job Save")
        TrackActions.PageAction("Job Save")
    }, [tabStatus, savedCMSData])

    useEffect(() => {
        getJourneyStatus()
    }, [])

    const fetchJobSavedLabel = (Key) => {
        if (savedCMSData.pageContent["JobSearch.SavedList"]) {
            let refJobSavedLabel = JSON.parse(JSON.stringify(savedCMSData.pageContent["JobSearch.SavedList"]))
            let label = "";
            if (refJobSavedLabel?.Labels) {
                refJobSavedLabel?.Labels.map((x) => {
                    if (x.Key === Key) {
                        label = x.Phrase;
                    }
                });
            }
            return label;
        }
    }

    const getAllSavedjobs = async () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setJobLoader(true);
        let refPaginationState = JSON.parse(JSON.stringify(paginationState));
        let allJobsList = [];
        let allAppliedJobList = [...appliedjobs];
        await JobActions.GetSavedJobs(candidateID).then(
            (res) => {
                if (res.status === 200) {
                    if (res?.data.length > 0) {
                        res?.data?.map((job) => {
                            const matchPercent = job?.score?.score * 100
                            let jobObj = {
                                companyLogo: {
                                    altText: 'cmplogo',
                                    src: ''
                                },
                                companyName: job?.companyName !== '' ? job?.companyName : 'NA',
                                contractType: job?.employmentType !== '' ? job?.employmentType : 'NA',
                                description: job?.jobDescription !== '' ? job?.jobDescription : 'NA',
                                id: job?.jobId,
                                isComparable: true,
                                isFeaturedText: "Featured listing",
                                isMatchText:  fetchJobCardCMSData("JobSearch.JobCard.StrongMatch"),
                                isSaveable: true,
                                isSaved: job?.isSaved !== '' ? job?.isSaved : false,
                                jobRole: job?.jobTitle !== '' ? job?.jobTitle : 'NA',
                                location: job?.jobLocation !== '' ? job?.jobLocation : 'NA',
                                onClick: {},
                                onComparingButtonClick: {},
                                onSaveButtonClick: {},
                                posted: convertPostedDate(job?.jobPostedTimeStamp),
                                postedTimeStamp: job?.jobPostedTimeStamp !== '' ? job?.jobPostedTimeStamp : 'NA',
                                jobUrl: job?.jobUrl !== '' ? job?.jobUrl : 'NA',
                                salary: job?.salary !== '' ? job?.salary : 'NA',
                                startDate: job?.jobStartdate !== '' ? job?.jobStartdate : 'NA',
                                matchPercentage: job?.matchPercentage !== '' ? job?.matchPercentage : 'NA',
                                staticCopy: {
                                    CollapseJobInfo: 'Hide job info',
                                    CompanyName: 'Company',
                                    Compare: 'Compare',
                                    ExpandJobInfo: 'View job info',
                                    IsCompared: 'Compare this job',
                                    IsFeatured: 'This role is featured',
                                    IsNotCompared: 'Remove this job from comparison',
                                    IsSaved: 'Unsave this job',
                                    IsUnsaved: 'Save this job',
                                    JobDescription: 'Job Description',
                                    JobInfo: fetchJobCardCMSData("JobSearch.JobCard.JobCardMoreInfo"),
                                    JobRole: 'Role',
                                    JobType: 'Type:',
                                    Location: 'Location',
                                    Salary: 'Salary:',
                                    Save: fetchJobCardCMSData("JobSearch.JobCard.SavedButton"),
                                    StartDate: 'StartDate'
                                }
                            };
                            if (matchPercent >= 70) {
                                jobObj = {
                                    ...jobObj,
                                    matchPercentage: matchPercent
                                }
                            }
                            allJobsList.push(jobObj);
                        })
                        refPaginationState.searchCountAndLabel = fetchJobSavedLabel("Job.SavedJobs.SavedList.ShowingResults")
                        refPaginationState.searchCountAndLabel = refPaginationState.searchCountAndLabel.replace("#COUNT", allJobsList.length)
                        refPaginationState.totalCounts = allJobsList.length;
                    } else {
                        refPaginationState.searchCountAndLabel = fetchJobSavedLabel("Job.SavedJobs.SavedList.ShowingResults")
                        refPaginationState.searchCountAndLabel = refPaginationState.searchCountAndLabel.replace("#COUNT", allJobsList.length)
                        refPaginationState.totalCounts = allJobsList.length;
                        setFetchedJobs([]);
                        allJobsList = [];
                    }
                } else {
                    setJobLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setJobLoader(false);
            }
        );

        if (allJobsList.length > 0) {
            await JobActions.GetAppliedJobs().then(
                (resp) => {
                    if (resp.status === 200) {
                        resp?.data.map((ele1) => {
                            ele1['id'] = ele1.jobId
                        })
                        allAppliedJobList = resp?.data;
                        setAppliedjobs(resp?.data);
                    } else {
                        setJobLoader(false);
                    }
                },
                (err) => {
                    console.log(err);
                    setJobLoader(false);
                }
            );
        }

        let allAppliedJobsID = [];
        allAppliedJobList?.map((allApplied) => {
            allAppliedJobsID.push(allApplied.id);
        });
        let jobsWithAppliedStatus = allJobsList.map((ele1) => {
            if (allAppliedJobsID.includes(ele1.id)) {
                ele1["isApplied"] = true;
            } else {
                ele1["isApplied"] = false;
            }
            return ele1;
        })

        refPaginationState.fetchedCounts = jobsWithAppliedStatus.length;
        refPaginationState.shownCounts = jobsWithAppliedStatus.length > 0 ? 6 : 0;
        setPaginationState(refPaginationState);
        setFetchedJobs(jobsWithAppliedStatus.length > 0 ? jobsWithAppliedStatus : []);
        let sc = jobsWithAppliedStatus.length > 0 ? jobsWithAppliedStatus.slice(0, 6) : [];
        setShownJobs(jobsWithAppliedStatus.length > 0 ? sc : [])
        setJobLoader(false);
    }

    const fetchJobCardCMSData = (key) => {
        let label = "";
        if (jobCardCMSData?.pageContent?.Labels) {
            jobCardCMSData?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    }

    const getJourneyStatus = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "journeyStatus": "Found a job",
            "isActive": true
        }

        JobActions.GetCandidateJourneyStatus(candidateID, payload).then(
            (resp) => {
                if (resp.status === 200) {
                    setHasJob(resp && resp.data && resp.data.isActive && resp.data.isActive)
                    setJobLoader(false);
                } else {
                    setJobLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setJobLoader(false);
            }
        );
    }

    const convertPostedDate = (date) => {
        let postDate = date && date;
        let todayDate = new Date();
        postDate = Moment(postDate).format("MM-DD-YYYY");
        todayDate = Moment(todayDate).format("MM-DD-YYYY");
        function parseDate(str) {
            var date = str && str.split("-");
            return new Date(date && date[2], date && date[0] - 1, date && date[1]);
        }
        function dateDiff(first, second) {
            return Math.round((second - first) / (1000 * 60 * 60 * 24));
        }
        let posted_Date = dateDiff(parseDate(postDate), parseDate(todayDate));
        let postedDate;
        if (posted_Date < 1) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.Hoursago").replace("#TIME#", posted_Date);
        } else if (posted_Date >= 1 && posted_Date <= 30) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.Day").replace("#TIME#", posted_Date);
        } else if (posted_Date > 30 && posted_Date <= 60) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.1Month").replace("#TIME#", posted_Date - 30);
        } else if (posted_Date > 60 && posted_Date <= 90) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.2Month").replace("#TIME#", posted_Date - 60);
        } else if (posted_Date > 90 && posted_Date <= 180) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.3Month");
        } else if (posted_Date > 180) {
            postedDate = fetchJobCardCMSData("JobSearch.JobCard.6Month");
        }

        postDate =
            fetchJobCardCMSData("JobSearch.JobCard.Posted") + " " + postedDate;
        return postDate;
    };

    // Start for showing the JD
    const [isJobDetailsHide, setIsJobDetailsHide] = useState(false);
    const [selectJobForDetails, setSelectJobForDetails] = useState([]);
    const JDclickedId = (id) => {
        if (id === '') {
            return;
        }
        let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
        let selectForJD = refShownJobs.filter((item) => {
            return item.id === id;
        })
        setSelectJobForDetails(selectForJD);
        window.scrollTo(0, 0);
        setIsJobDetailsHide(true);
        JDshowHide(true)
    }
    const jobSaveFromJDpageFlag = (id) => {
        if (id === '') {
            return;
        }
        let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
        let refSavedjobs = JSON.parse(JSON.stringify(fetchedJobs));
        const shownJobsId = refShownJobs?.map(job => job.id)
        if (shownJobsId.includes(id)) {
            refShownJobs.map((x) => {
                if (x.id === id) {
                    saveUnsaveFlag(id, false)
                    x.isSaved = false;
                    var filtered = refShownJobs.filter(function (job) { return job.id != x.id; });
                    refShownJobs = filtered;
                }
                return x;
            })
            setShownJobs(refShownJobs);
        } else {
            refSavedjobs.map((y) => {
                if (y.id === id) {
                    y.isSaved = true;
                    refShownJobs.push(y);
                }
            })
            setShownJobs(refShownJobs);
        }
    }
    const appliedJobFromJDpageFlag = (id) => {
        if (id === '') {
            return;
        }
        let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
        refShownJobs?.map((ele1) => {
            if (ele1.id === id) {
                ele1.isApplied = true;
            }
            return ele1;
        })
        setShownJobs(refShownJobs);
    }
    const jobDetailsBackFlag = () => {
        setIsJobDetailsHide(false);
        JDshowHide(false)
    }
    // End for showing the JD
    let countText = fetchJobSavedLabel("Job.SavedJobs.SavedList.ShowingResults");
    if (countText) {
        countText = countText.replace("#COUNT", fetchedJobs.length)
    }

    const saveJobHandler = async (job) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
        let refSavedjobs = JSON.parse(JSON.stringify(fetchedJobs));
        const savedJobsId = refSavedjobs?.map(job => job.id)
        const inputJobId = job && job.id && job.id;
        let saveJobPayload = {
            "OwnerId": candidateID,
            "OwnerType": "Candidate",
            "JobId": job?.id,
            "JobTitle": job?.jobRole != undefined ? job?.jobRole : "",
            "CompanyName": job?.companyName != undefined ? job?.companyName : '',
            "JobUrl": job?.jobUrl != undefined ? job?.jobUrl : "",
            "IsFeaturedJob": 1,
            "IsSaved": savedJobsId.includes(inputJobId) ? 0 : 1,
            "IsBookmarked": savedJobsId.includes(inputJobId) ? 0 : 1,
            "IsDeleted": 0,
            "CreatedBy": "Admin",
            "MatchPercentage": "37%",
            "JobLocation": job?.location != undefined ? job?.location : "",
            "Salary": job?.salary != undefined ? job?.salary : "",
            "EmploymentType": job?.contractType != undefined ? job?.contractType : "",
            "UpdatedBy": '',
            "UpdatedTimeStampUpdatedBy": '',
            "JobDescription": job?.description != undefined ? job?.description : "",


            "JobPostedTimeStamp": job?.postedTimeStamp != undefined ? Moment(job?.postedTimeStamp).format('MM-DD-YYYY') : "",
            "LastSavedTimeStamp": Moment(new Date()).format("MM-DD-YYYY"),
            // "JobStartTimeStamp": job?.startDate === "Flexible Start" ? null : job?.startDate,
            "CreatedTimeStamp": Moment(new Date()).format("MM-DD-YYYY"),
            "LastUnsavedTimeStamp": savedJobsId.includes(inputJobId) ? Moment(new Date()).format("MM-DD-YYYY") : null,
            "UpdatedTimeStamp": job?.lastUpdatedDate != undefined ? Moment(job?.lastUpdatedDate).format('MM-DD-YYYY') : "",
        }
        await JobActions.PostSaveJob(candidateID, saveJobPayload).then(
            (resp) => {
                if (resp.status === 200) {
                    refShownJobs.map((x) => {
                        if (x.id === job.id) {
                            if (savedJobsId.includes(x.id)) {
                                saveUnsaveFlag(inputJobId, false)
                                x.isSaved = false;
                                var filtered = refShownJobs.filter(function (job) { return job.id != x.id; });
                                refShownJobs = filtered;
                            } else {
                                x.isSaved = true;
                                refShownJobs.push(x);
                            }
                        }
                        return x;
                    })
                    setShownJobs(refShownJobs);
                } else {
                    setJobLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setJobLoader(false);
            }
        );
    }

    const fetchJobWidgetLabel = (key) => {
        let refCmsJobContent = JSON.parse(JSON.stringify(cmsJobWidgetContent));
        let label = "";
        if (refCmsJobContent?.pageContent?.Labels) {
            refCmsJobContent?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const foundJob = (journey) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let postPayload = {
            "candidateId": candidateID,
            "journeyStatus": "Found a job",
            "isActive": true
        }

        let putPayload = {
            "candidateId": candidateID,
            "journeyStatus": "Found a job",
            "isActive": false
        }

        const Action = journey == "found" ? JobActions.PostCandidateJourneyStatus : journey == "undo" ? JobActions.PutCandidateJourneyStatus : ''

        Action(candidateID, journey == "found" ? postPayload : journey == "undo" ? putPayload : "").then(
            (resp) => {
                if (resp.status === 200 && resp == true) {
                    setJobLoader(false);
                } else {
                    setJobLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setJobLoader(false);
            }
        );
    }

    const loadMoreJobHandler = () => {
        let refPaginationState = JSON.parse(JSON.stringify(paginationState));
        let refFetchedJobs = JSON.parse(JSON.stringify(fetchedJobs));
        let sc = refFetchedJobs.slice(refPaginationState.shownCounts, refPaginationState.shownCounts + 6);
        setShownJobs([...shownJobs, ...sc])
        refPaginationState.shownCounts = refPaginationState.shownCounts + 6;
        setPaginationState(refPaginationState);
    }

    return (
        <div className='savedTab'>
            <>
                <div style={isJobDetailsHide === true ? { height: 0, overflow: 'hidden' } : { height: '100%', overflow: 'visible' }}>
                    <Cirrus.OneColumnLayout type="wrapped">
                        {
                            shownJobs.length > 0 ? <JobCard JDclickedId={JDclickedId} saveJobHandler={saveJobHandler} shownJobs={shownJobs} paginationState={paginationState} loadMoreJobHandler={loadMoreJobHandler} layout={'twocolumn'} jobCardCMSData={jobCardCMSData} />
                                : shownJobs.length == 0 ?
                                    <Cirrus.Label>{fetchJobSavedLabel("Job.SavedJobs.SavedList.ValidationMessage")}</Cirrus.Label> :
                                    <div className='spinner-center'>
                                        <Spinner screenLoader={false} spinnerTimer={jobLoader} /><br />
                                        <Cirrus.Label>Loading Saved Jobs...</Cirrus.Label>
                                    </div>
                        }
                    </Cirrus.OneColumnLayout>
                </div>
                <div style={isJobDetailsHide === true ? { display: 'block' } : { display: 'none' }}>
                    <JobDetails
                        selectJobForDetails={selectJobForDetails}
                        jobDetailsBackFlag={jobDetailsBackFlag}
                        jobSaveFromJDpageFlag={jobSaveFromJDpageFlag}
                        appliedJobFromJDpageFlag={appliedJobFromJDpageFlag}
                    />
                </div>
                <div className='jobWidget acceptedJob'>
                    <Cirrus.AcceptedAJobCard
                        foundAJobButtonProps={{
                            children: fetchJobWidgetLabel("JobSearchPages.Job.JobWidget.IhaveFoundaJobButton"),
                            onClick: () => {
                                foundJob('found')
                                setHasJob(true)
                            },
                            size: 'Large',
                            type: 'button'
                        }}
                        role="dialog"
                        aria-modal={true}
                        heading={cmsJobWidgetContent && cmsJobWidgetContent.pageContent && cmsJobWidgetContent.pageContent.Description && cmsJobWidgetContent.pageContent.Description}
                        subheading={fetchJobWidgetLabel("JobSearchPages.Job.JobWidget.JobWidgetDescription")}
                        undoButtonProps={{
                            children: fetchJobWidgetLabel("JobSearchPages.Job.JobWidget.UndoLinkText"),
                            onClick: () => {
                                foundJob('undo')
                                setHasJob(false)
                            },
                            type: 'button'
                        }}
                        hasFoundAJob={hasJob}
                        imageProps={{
                            src: womanOnLaptop
                        }}
                    />
                </div>
            </>
        </div>
    )
}

export default JobSavedTab
