//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { AssessmentActions } from "../AssessmentActions";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import "../style.css";
import { jsPDF } from "jspdf";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { coachActions } from "../../coach/coachActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";

const LookingAtOptionsResult = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
  const [assessmentStatus, setAssessmentStatus] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [resultData, setResultData] = useState();
  const [historyData, setHistoryData] = useState();
  const [coachdata, setcoachdata] = useState();
  const [assessmentStartPageData, setAssessmentStartPageData] = useState();
  const [commonAnswerList, setCommonAnswerList] = useState(
    context.cmsLookingAtOptionsContent.assessmentQuestionSet
      .find(
        (factors) =>
          factors.Key === "Assessments.LookingAtOptions.CareerFactors"
      )
      .QuestionList.find((question) => question.AnswerList).AnswerList
  );
  const [commonCareerFactors, setCommonCareerFactors] = useState(
    context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(
      (factors) => factors.Key === "Assessments.LookingAtOptions.CareerFactors"
    ).QuestionList
  );

  const careerLabels = context.cmsLookingAtOptionsContent.assessmentQuestionSet
    .find(
      (career) =>
        career.Key === "Assessments.LookingAtOptions.TellUsAboutYourCareerPath"
    )
    .Components.find((filt) => filt.careerHeading)
    .careerHeading.replace(/[0-9]/g, "");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCMSData();
    getAssessmentStatus();
    getMyCoach();
  }, [context.language]);

  const fetchCMSData = () => {
    let resData = context.cmsLookingAtOptionsContent;

    ActivityAction.SaveActivity(
      resData?.internalAssessmentId,
      "completedassesment",
      resData?.title
    );
    setCmsAssessmentContent(resData);
  };

  const getAssessmentStatus = async () => {
    let response = await AssessmentActions.GetAssessmentStatusById(
      context.cmsLookingAtOptionsContent.internalAssessmentId
    );
    setAssessmentStatus(response.data);
    setIsSaved(response.data.isSaved);
    setIsLiked(response.data.isLiked);

    AssessmentActions.GetAllCareerPathWithFactors(
      context.cmsLookingAtOptionsContent.internalAssessmentId
    ).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data.careerFactorsResponse;
          resData.map((resp) => {
            resp.factorAssessmentResponsesList.map((factorQuest) => {
              commonCareerFactors.map((question) => {
                if (factorQuest.questionId === question.QuestionId) {
                  question.AnswerList.map((options) => {
                    if (options.AnswerId === factorQuest.answerValue) {
                      Object.keys(factorQuest).forEach((key) => {
                        factorQuest["questionTitle"] = question.QuestionTitle;
                      });
                    }
                  });
                }
              });
            });
          });
          setResultData(resData);
        } else {
          console.log(res);
        }
        AssessmentActions.GetAssessmentHistory(
          context.cmsLookingAtOptionsContent.internalAssessmentId
        ).then(
          (res) => {
            if (res.status === 200) {
              let responses = res.data;
              responses.map((history) => {
                let resData = JSON.parse(history.previousOptionsResponse);

                resData.map((resp) => {
                  resp.factorAssessmentResponsesList.map((factorQuest) => {
                    commonCareerFactors.map((question) => {
                      if (factorQuest.QuestionId === question.QuestionId) {
                        question.AnswerList.map((options) => {
                          if (options.AnswerId === factorQuest.AnswerValue) {
                            Object.keys(factorQuest).forEach((key) => {
                              factorQuest["QuestionTitle"] =
                                question.QuestionTitle;
                            });
                          } else {
                          }
                        });
                      }
                    });
                  });
                });
                setHistoryData(resData);
              });
            } else {
              console.log(res);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchCommonLabel = (key) => {
    let refCmsAssessmentContent = context.cmsLookingAtOptionsContent;
    let label = "";
    if (refCmsAssessmentContent?.commonLabels) {
      refCmsAssessmentContent?.commonLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsAssessmentContent = context.cmsLookingAtOptionsContent;
    let label = "";
    if (refCmsAssessmentContent?.commonResourceLabels) {
      refCmsAssessmentContent?.commonResourceLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus.learningResourceId,
                learningResourceStatus: assessmentStatus.learningResourceStatus,
                learningResourceType:
                  context.cmsLookingAtOptionsContent?.resourceCategoryType?.ID,
                resourceTypeId:
                  context.cmsLookingAtOptionsContent?.resourceType?.ID,
                isSaved: assessmentStatus.isSaved,
                isLiked: assessmentStatus.isLiked,
                lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: assessmentStatus?.isSharedWithCoach,
                resultDocumentId: assessmentStatus?.resultDocumentId,
              };
              AssessmentActions.SaveAssessmentStatus(
                context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
                assessmentStatus.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setLoadSpinner(false);
                    let startPageResponse =
                      AssessmentActions.GetAssessmentStatusById(
                        assessmentStatus.learningResourceId
                      );
                    setAssessmentStatus(startPageResponse?.data);
                    getAssessmentStatus();
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const shareResultWithCoach = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: context.cmsLookingAtOptionsContent.title,
      assessmentId: context.cmsLookingAtOptionsContent.internalAssessmentId,
      documentId: assessmentStatus.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus.learningResourceId,
            learningResourceStatus: assessmentStatus.learningResourceStatus,
            learningResourceType:
              context.cmsLookingAtOptionsContent?.resourceCategoryType?.ID,
            resourceTypeId:
              context.cmsLookingAtOptionsContent?.resourceType?.ID,
            isSaved: assessmentStatus.isSaved,
            isLiked: assessmentStatus.isLiked,
            lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
            isDownloaded: assessmentStatus?.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStatus?.resultDocumentId,
          };
          AssessmentActions.SaveAssessmentStatus(
            context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            assessmentStatus.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                setLoadSpinner(false);
                let startPageResponse =
                  AssessmentActions.GetAssessmentStatusById(
                    assessmentStatus.learningResourceId
                  );
                setAssessmentStatus(startPageResponse?.data);
                getAssessmentStatus();
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  return (
    <div className="assessment-page">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <Container className="pt-4 pb-4">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/looking-at-options")
              }
            >
              {fetchCommonLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
          </Col>
          <Col
            sm={12}
            md={{ span: 8, order: 2 }}
            lg={{ span: 8, order: 2 }}
            xs={{ order: 3 }}
            className="d-flex"
          >
            {/* <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>Progress {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div> */}
          </Col>
          <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
              {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>
      <Container className="assessment-review-result-wrapper assessment-result-wrapper px-4 py-5 p-md-5 mb-5">
        {loadSpinner && (
          <div className="loader">
            <Cirrus.Spinner size={50} />
          </div>
        )}
        <Row className="mb-5 pb-3 border-bottom">
          <Col lg={5}>
            <Cirrus.Header size="h4" className="mb-3 text-start">
              {cmsAssessmentContent?.resultPage?.Title}
            </Cirrus.Header>
          </Col>
          <Col lg={7} className="download-share-retake">
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() =>
                downloadDocument(assessmentStatus.resultDocumentId)
              }
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.DownloadAsPDF"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() => shareResultWithCoach()}
              disabled={coachdata ? false : true}
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.ShareResultsWithCoach"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/looking-at-options")
              }
            >
              {fetchCommonLabel("CommonComponentCommonRetakeButton")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-start career-labels">
            {resultData?.map((data, idx) => {
              return (
                <div className="mb-4" key={idx}>
                  <Cirrus.Label>
                    {careerLabels + (idx + 1) + " - " + data.categoryTitle}
                  </Cirrus.Label>
                  <Container fluid className="factors-wrapper py-3">
                    <Row className="d-flex flex-lg-row-reverse flex-md-row-reverse flex-column-reverse">
                      {commonAnswerList?.map((commonAnswers, colIdx) => {
                        return (
                          <Col key={colIdx} className="factor-category">
                            <Cirrus.Label>
                              {commonAnswers.AnswerText}
                            </Cirrus.Label>
                            <ul>
                              {data.factorAssessmentResponsesList.map(
                                (factors, factorIdx) => {
                                  if (
                                    factors.answerValue ===
                                    commonAnswers.AnswerId
                                  ) {
                                    return (
                                      <li key={factorIdx}>
                                        {factors.questionTitle
                                          ? factors.questionTitle
                                          : factors.factorName}
                                      </li>
                                    );
                                  }
                                }
                              )}
                            </ul>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Cirrus.Header
              size="h5"
              className="border-bottom pb-2 mb-3 text-start"
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.YourPreviousCareerOptions"
              )}
            </Cirrus.Header>
          </Col>
        </Row>
        {/* {
                        historyData.length === 0 && 
                        <>
                            No Records
                        </>
                    } */}
        {historyData && historyData.length && (
          <Row>
            <Col lg={12} className="text-start career-labels">
              {historyData?.map((data, idx) => {
                return (
                  <div className="mb-4" key={idx}>
                    <Cirrus.Label>{data.CategoryTitle}</Cirrus.Label>
                    <Container fluid className="factors-wrapper py-3">
                      <Row className="d-flex flex-lg-row-reverse flex-md-row-reverse flex-column-reverse">
                        {commonAnswerList?.map((commonAnswers, colIdx) => {
                          return (
                            <Col key={colIdx} className="factor-category">
                              <Cirrus.Label>
                                {commonAnswers.AnswerText}
                              </Cirrus.Label>
                              <ul>
                                {data.factorAssessmentResponsesList.map(
                                  (factors, factorIdx) => {
                                    if (
                                      factors.AnswerValue ===
                                        commonAnswers.AnswerId &&
                                      factors.FactorName == null
                                    ) {
                                      return (
                                        <li key={factorIdx}>
                                          {factors.QuestionTitle}
                                        </li>
                                      );
                                    } else if (factors.FactorName !== null) {
                                      return (
                                        <li key={factorIdx}>
                                          {factors.FactorName}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </ul>
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                  </div>
                );
              })}
            </Col>
          </Row>
        )}
      </Container>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
};

export default LookingAtOptionsResult;
