//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { Row, Col, Container } from "react-bootstrap";
import * as Cirrus from 'manpowergroup-cirrus';

const LAOpdfTemplate = ({ resultData, historyData, historyTitle, commonAnswerList, careerLabels }) => {

    return (
        <>
            <table cellPadding={10} cellSpacing={10} style={{ border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                <tbody>
                    <tr>
                        <td>
                            <Row>
                                <Col lg={12} className='text-start career-labels'>
                                    {
                                        resultData?.map((data, idx) => {
                                            return (
                                                <div className='mb-4' key={idx}>
                                                    <Cirrus.Label>{careerLabels + (idx + 1) + " - " + data.categoryTitle}</Cirrus.Label>
                                                    <Container fluid className='factors-wrapper py-3'>
                                                        <Row className='d-flex flex-row-reverse'>
                                                            {commonAnswerList?.map((commonAnswers, colIdx) => {
                                                                return (
                                                                    <Col key={colIdx} className='factor-category'>
                                                                        <Cirrus.Label>{commonAnswers.AnswerText}</Cirrus.Label>
                                                                        <ul style={{ paddingLeft: '10px' }}>
                                                                            {
                                                                                data.factorAssessmentResponsesList.map((factors, factorIdx) => {
                                                                                    if (factors.answerValue === commonAnswers.AnswerId) {
                                                                                        return (
                                                                                            <li key={factorIdx}>
                                                                                                {factors.questionTitle ? factors.questionTitle : factors.factorName}
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Container>
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cirrus.Header size='h5' className='border-bottom pb-2 mb-3 text-start'>{historyTitle}</Cirrus.Header>
                                </Col>
                            </Row>
                            {/* {
                        historyData.length === 0 && 
                        <>
                            No Records
                        </>
                    } */}
                            <Row>
                                <Col lg={12} className='text-start career-labels'>
                                    {
                                        historyData?.map((data, idx) => {
                                            return (
                                                <div className='mb-4' key={idx}>
                                                    <Cirrus.Label>{data.CategoryTitle}</Cirrus.Label>
                                                    <Container fluid className='factors-wrapper py-3'>
                                                        <Row className='d-flex flex-row-reverse'>
                                                            {commonAnswerList?.map((commonAnswers, colIdx) => {
                                                                return (
                                                                    <Col key={colIdx} className='factor-category'>
                                                                        <Cirrus.Label>{commonAnswers.AnswerText}</Cirrus.Label>
                                                                        <ul style={{ paddingLeft: '10px' }}>
                                                                            {
                                                                                data.factorAssessmentResponsesList.map((factors, factorIdx) => {
                                                                                    if (factors.AnswerValue === commonAnswers.AnswerId && factors.FactorName == null) {
                                                                                        return (
                                                                                            <li key={factorIdx}>{factors.QuestionTitle}</li>
                                                                                        )
                                                                                    } else if (factors.FactorName !== null) {
                                                                                        return (
                                                                                            <li key={factorIdx}>{factors.FactorName}</li>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Container>
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
                <span style={{ fontSize: '1px' }}>.</span>
            </table>
            {/* {</div>} */}
        </>
    )
}

export default LAOpdfTemplate;
