//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { saveAs } from "file-saver";
import { Context } from "../../common/context/Context";
import { MyDocumentsActions } from "./MyDocumentsActions";
import Spinner from "../../common/components/spinner-loader/Spinner";
import MultipleFilesUpload from "../../common/components/uploadFile/MultipleFilesUpload";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";
import { globalDateFormat } from "../../common/helpers/DateTimeHelper";

function MyDocuments() {
  const context = useContext(Context);
  const location = useLocation();
  const [uploadModal, setUploadModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [documentsLists, setDocumentsLists] = useState([]);
  const [documentName, setDocumentName] = useState();
  const [screenLoader, setScreenLoader] = useState(false);
  const [noRecordsMsg, setNoRecordsMsg] = useState("");
  const [cmsMyDocuments, setCmsMyDocuments] = useState({
    title: "",
    pageLabel: [],
    sortOptions: [],
  });
  const [cmsUploadDocuments, setCmsUploadDocuments] = useState({});
  const [embedFile, setEmbedFile] = useState();
  const [embedFileType, setEmbedFileType] = useState();
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [reference, setReference] = useState(null);
  const [viewModalDocID, setViewModalDocID] = useState();
  const [uploadModalFlag, setUploadModalFlag] = useState({
    apiFlag: "DocumentUpload",
    replaceID: "",
    uploadType: "Multiple",
  });

  //this section is for responsive
  const [checkDevice, setCheckDevice] = useState(false);
  const mobileAndTabletCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    setCheckDevice(check);
    return check;
  };
  const appInsights = useAppInsightsContext();

  const [disablePDF_dots, setDisablePDF_dots] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", mobileAndTabletCheck);
    TrackActions.PageViewRecord(appInsights, "MyDocuments");
    TrackActions.PageAction("MyDocuments");
    return () => {
      window.removeEventListener("resize", mobileAndTabletCheck);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCMSContent();
  }, [context.language]);

  useEffect(() => {
    if (cmsMyDocuments.title != "") {
      getAllDocumentsLists();
    }
  }, [cmsMyDocuments]);

  const fetchCMSContent = async () => {
    let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
    // let refCmsViewDocuments = JSON.parse(JSON.stringify(cmsViewDocuments));
    setScreenLoader(true);
    let payload = {
      PageName: "MyDocuments",
    };
    await MyDocumentsActions.GetCMScontent(payload).then(
      (res) => {
        if (res.status === 200) {
          res?.data?.Pages?.map((x) => {
            let sortArray = [];
            refCmsMyDocuments.title = x.Title;
            x.Components?.[
              "MyDocumentsPage.MyDocuments"
            ]?.LOVList[0]?.Values?.map((sort) => {
              let obj = {
                name: sort.Value,
                value: sort.Key,
              };
              sortArray.push(obj);
            });
            refCmsMyDocuments.sortOptions = sortArray;
            refCmsMyDocuments.pageLabel =
              x.Components?.["MyDocumentsPage.MyDocuments"]?.Labels;

            refCmsUploadDocuments["modalTitle"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.Title;
            refCmsUploadDocuments["uploadDescription"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.Description;
            refCmsUploadDocuments["dragDropText"] =
              x.Components?.["MyDocumentsPage.UploadDocuments"]?.RichTexts;
            refCmsUploadDocuments["uploadProgressDescription"] =
              x.Components?.["MyDocumentsPage.UploadProgressView"]?.Description;
            refCmsUploadDocuments["uploadStatusDescription"] =
              x.Components?.["Common.UploadProgressView"]?.Description;
            let labels = [
              ...x.Components?.["MyDocumentsPage.UploadDocuments"]?.Labels,
              ...x.Components?.["Common.UploadProgressView"]?.Labels,
            ];
            refCmsUploadDocuments["labels"] = labels;
          });

          setCmsMyDocuments(refCmsMyDocuments);
          setCmsUploadDocuments(refCmsUploadDocuments);
          // getAllDocumentsLists();
          // setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
    let label = "";
    if (refCmsMyDocuments?.pageLabel) {
      refCmsMyDocuments?.pageLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExitLabel = (key) => {
    let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
    let label = "";
    if (refCmsUploadDocuments?.labels) {
      refCmsUploadDocuments?.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const getAllDocumentsLists = async (fromUploadFlag = false) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    const resumeBuilderstatus = location?.state?.status;
    let payload = {
      ownerId: candidateID,
      IsResumeBuilder: resumeBuilderstatus,
    };
    await MyDocumentsActions.GetAllDocuments(payload).then(
      (res) => {
        if (res.status === 200) {
          let respData = res.data.map((x) => {
            x["name"] = x.documentName;
            x["size"] = convertSize(x.documentSize);
            x["type"] = x.fileFormat;
            x["uploadDate"] = globalDateFormat(x.documentUploadedTimeStamp);
            return x;
          });
          setDocumentsLists(sortByNewest(respData));
          if (!fromUploadFlag) {
            setScreenLoader(false);
          }
          setNoRecordsMsg("");
        } else {
          setNoRecordsMsg(
            fetchLabel("MyDocumentsPage.MyDocuments.NoDocumentsMessage")
          );
          if (!fromUploadFlag) {
            setScreenLoader(false);
          }
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const openUploadModal = () => {
    setUploadModalFlag({
      apiFlag: "DocumentUpload",
      replaceID: "",
      uploadType: "Multiple",
    });
    setUploadModal(true);
  };

  const uploadModalCloseHandler = () => {
    setUploadModal(false);
  };

  const viewModalCloseHandler = () => {
    setViewModal(false);
    setEmbedFile("");
    setEmbedFileType("");
    setDisablePDF_dots(false);
  };

  const deleteHandler = async (id) => {
    let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
    await MyDocumentsActions.DeleteDocument(id).then(
      (res) => {
        if (res.status === 200) {
          let filteredItem = refDocumentsLists.filter((fw) => {
            return fw.id !== id;
          });
          setViewModal(false);
          setDocumentsLists(filteredItem);
          if (refDocumentsLists.length === 1) {
            setNoRecordsMsg(
              fetchLabel("MyDocumentsPage.MyDocuments.NoDocumentsMessage")
            );
          } else {
            setNoRecordsMsg("");
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const downloadHandler = async (id) => {
    let fileName = documentsLists.filter((x) => {
      if (x.id === id) {
        return x.name;
      }
    });
    await MyDocumentsActions.DownloadDocument(id).then(
      (response) => {
        if (response && response.status === 200 && response.data) {
          TrackActions.EventTrack(appInsights, "DownloadDocument", {
            DocumentId: id,
          });
          TrackActions.PageAction("DownloadDocument", { DocumentId: id });
          let contentTypeHeader = response.headers["content-type"];
          var blob = new Blob([response.data], {
            type: contentTypeHeader.toLowerCase(),
            charset: "UTF-8",
          });
          saveAs(blob, fileName[0].name);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const replaceHandler = async (id) => {
    setUploadModal(true);
    setUploadModalFlag({
      apiFlag: "DocumentReplace",
      replaceID: id,
      uploadType: "Single",
    });
    setViewModal(false);
  };

  const viewHandler = async (id) => {
    TrackActions.EventTrack(appInsights, "ViewDocument", { DocumentId: id });
    TrackActions.PageAction("ViewDocument", { DocumentId: id });

    let fileName = documentsLists.filter((x) => {
      if (x.id === id) {
        return x.name;
      }
    });
    if (fileName[0].fileFormat === "pdf" || fileName[0].fileFormat === "docx") {
      setViewModal(true);
      setViewModalDocID(id);
    }
    documentsLists.map((document) => {
      if (id === document.id) {
        setDocumentName(document.documentName);
        // setEmbedFileType(document.fileFormat)
        // console.log(document.fileFormat)
      }
    });
    // console.log("downloadHandler", id);

    await MyDocumentsActions.DownloadDocument(id).then(
      (response) => {
        if (response && response.status === 200 && response.data) {
          let contentTypeHeader = response.headers["content-type"];
          var blob = new Blob([response.data], {
            type: contentTypeHeader.toLowerCase(),
            charset: "UTF-8",
          });
          // console.log(blob)

          // setEmbedFile(URL.createObjectURL(blob))
          // if (contentTypeHeader === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          //     setEmbedFileType('docx');
          // } else if (contentTypeHeader === "application/pdf") {
          //     setEmbedFileType('pdf');
          // } else if (contentTypeHeader === "image/png") {
          //     setEmbedFileType('png')
          // }

          /* embed is working only for pdf and images */
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (contentTypeHeader === "application/pdf") {
              let base64data =
                reader.result + "#toolbar=0&page=1&view=FitH&zoom=100";
              setEmbedFile(
                "data:application/pdf;base64," +
                  base64data.replace("data:", "").replace(/^.+,/, "")
              );
              setEmbedFileType("application/pdf");
            } else if (fileName[0].fileFormat === "docx") {
              let docFile = new File([blob], "document");
              setEmbedFile(docFile);
              let docx = require("../../../node_modules/docx-preview/dist/docx-preview");

              var docxOptions = Object.assign(docx.defaultOptions, {
                useMathMLPolyfill: true,
              });
              //Reference the Container DIV.
              var container = document.querySelector("#word-container");

              //Render the Word Document.
              docx.renderAsync(docFile, container, null, docxOptions);
            } else {
              let docFile = new File([blob], fileName[0].documentName);
              saveAs(docFile);
            }
          };
          /**********************************************/
          // setEmbedFile(URL.createObjectURL(blob))
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const finishUploadHandler = (uploadedFiles) => {
    getAllDocumentsLists(true);
    setUploadModal(false);
  };

  const sortHandler = (event) => {
    let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
    if (event.target.value === "A-Z") {
      refDocumentsLists.sort(function (a, b) {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      setDocumentsLists(refDocumentsLists);
    }
    if (event.target.value === "Type") {
      refDocumentsLists.sort(function (a, b) {
        if (a.type.toUpperCase() < b.type.toUpperCase()) {
          return -1;
        }
        if (a.type.toUpperCase() > b.type.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      setDocumentsLists(refDocumentsLists);
    }
    if (event.target.value === "Newest") {
      setDocumentsLists(sortByNewest(refDocumentsLists));
    }
  };

  const sortByNewest = (data) => {
    data.sort(function (a, b) {
      return new Date(b.createdTimeStamp) - new Date(a.createdTimeStamp);
    });
    return data;
  };

  const convertSize = (bytes) => {
    const sufixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (!bytes && "0 Bytes") ||
      (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i]
    );
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {/* <HeaderWithNavigation /> */}
      <Cirrus.PageBanner
        button={{
          children: fetchLabel(
            "MyDocumentsPage.MyDocuments.UploadAnewDocumentButton"
          ),
          onClick: openUploadModal,
          size: "Large",
        }}
        heading={cmsMyDocuments.title}
        type="large"
      />
      <Cirrus.OneColumnLayout type="wrapped" className="document-table">
        <Cirrus.DocumentsTable
          documents={documentsLists}
          onDelete={deleteHandler}
          onDownload={downloadHandler}
          onReplace={replaceHandler}
          // onView={ viewHandler}
          onView={checkDevice == true ? downloadHandler : viewHandler}
          noRecordsFound={noRecordsMsg}
          sortBySelectProps={{
            id: "SortBy",
            name: "sortby",
            "aria-label": "sortby",
            onChange: sortHandler,
            options: cmsMyDocuments.sortOptions,
          }}
          staticCopy={{
            Delete: fetchLabel("MyDocumentsPage.MyDocuments.DeleteDocument"),
            DocumentActions: "Click here for more options",
            DocumentName: fetchLabel(
              "MyDocumentsPage.MyDocuments.DocumentNameHeader"
            ),
            Download: fetchLabel(
              "MyDocumentsPage.MyDocuments.DownloadDocument"
            ),
            Replace: fetchLabel("MyDocumentsPage.MyDocuments.ReplaceDocument"),
            Size: fetchLabel("MyDocumentsPage.MyDocuments.SizeHeader"),
            Type: fetchLabel("MyDocumentsPage.MyDocuments.TypeHeader"),
            UploadDate: fetchLabel(
              "MyDocumentsPage.MyDocuments.UploadDateHeader"
            ),
          }}
        />
      </Cirrus.OneColumnLayout>
      {/* <Footer isLoggedIn={true} /> */}
      {uploadModal && (
        <Cirrus.Modal
          closeButtonProps={{
            children: fetchExitLabel(
              "MyDocumentsPage.UploadDocuments.DocumentUploadExit"
            ),
            isSecondary: true,
            onClick: uploadModalCloseHandler,
            size: "Small",
            type: "button",
            disabled: context.uploadModalExitDisable,
          }}
          headerProps={{
            children: cmsUploadDocuments?.modalTitle,
            className: "no-margin",
            size: "h5",
          }}
          isScrollable
          role="dialog"
          size="default"
        >
          <MultipleFilesUpload
            cmsContent={cmsUploadDocuments}
            finishUploadHandler={finishUploadHandler}
            uploadModalFlag={uploadModalFlag}
            extension={"Common.UploadProgressView"}
            origin={"MyDocuments"}
          />
        </Cirrus.Modal>
      )}
      {viewModal && (
        <Cirrus.ViewDocumentModal
          className="document-viewer-modal"
          closeButtonProps={{
            children: fetchExitLabel(
              "MyDocumentsPage.UploadDocuments.DocumentUploadExit"
            ),
            onClick: viewModalCloseHandler,
            size: "Small",
            type: "button",
          }}
          headerProps={{
            children: documentName,
            className: "document-name no-margin",
            size: "h5",
          }}
          role="dialog"
          size="default"
          isScrollable
        >
          <Cirrus.IconButton
            icon="more_vert"
            type="button"
            className="moreBtn"
            isSecondary="true"
            onClick={() => setIsPopUpVisible(!isPopUpVisible)}
            ref={setReference}
          />
          {isPopUpVisible && (
            <Cirrus.PopUp
              referenceElement={reference}
              onClose={() => {
                setIsPopUpVisible(false);
              }}
              className="viewPopup"
            >
              <Cirrus.LinkButton
                children={fetchLabel(
                  "MyDocumentsPage.MyDocuments.DownloadDocument"
                )}
                type="button"
                onClick={() => downloadHandler(viewModalDocID)}
                className="viewLinks"
              />
              <Cirrus.LinkButton
                children={fetchLabel(
                  "MyDocumentsPage.MyDocuments.ReplaceDocument"
                )}
                type="button"
                onClick={() => replaceHandler(viewModalDocID)}
                className="viewLinks"
              />
              <Cirrus.LinkButton
                children={fetchLabel(
                  "MyDocumentsPage.MyDocuments.DeleteDocument"
                )}
                type="button"
                onClick={() => deleteHandler(viewModalDocID)}
                className="viewLinks"
              />
            </Cirrus.PopUp>
          )}
          {embedFileType === "application/pdf" && (
            <embed
              src={embedFile}
              type={embedFileType}
              width="100%"
              style={{ minHeight: "450px", maxHeight: "95%", height: "100%" }}
            />
          )}
          {embedFileType !== "application/pdf" && (
            <div id="word-container"></div>
          )}
          {/* <iframe src={'http://view.officeapps.live.com/op/view.aspx?src=' + embedFile} width="100%" style={{ minHeight: '450px', maxHeight: '95%', height: '100%' }} ></iframe> */}
        </Cirrus.ViewDocumentModal>
      )}
    </>
  );
}

export default MyDocuments;
