//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import "manpowergroup-cirrus/index.css";
import { HeaderActions } from "../headerActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { Context } from "../../../common/context/Context";
import { useNavigate } from "react-router-dom";
import { returnLanguage } from "../../../common/helpers/CountryLanguage";

// Images
import LightLogo from "../../../assets/Images/brandnamelight.png";
import DarkLogo from "../../../assets/Images/brandnamedark.png";
import CanFlag from "../../../assets/Images/canadaflag.png";
import USFlag from "../../../assets/Images/usaflag.png";

function Header(props) {
  const context = useContext(Context);
  const navigate = useNavigate();
  const [languageSets, setLanguageSets] = useState({
    selectedCountryFullName: "",
    selectedCountryShortName: "",
    selectedLanguageFullName: "",
    selectedLanguageISOCode: "",
    languageList: [],
  });
  const [headerLabel, setHeaderLabel] = useState([]);
  const [headerLogo, setHeaderLogo] = useState({
    light: "",
    dark: "",
    countryFlag: "",
  });
  const [theme, setTheme] = useState("");

  useEffect(() => {
    let refSelectedtheme = window.sessionStorage.getItem("selectedtheme");
    if (refSelectedtheme !== null) {
      context?.setTheme(refSelectedtheme);
      window.sessionStorage.setItem("selectedtheme", refSelectedtheme);
      setTheme(refSelectedtheme);
    } else {
      context?.setTheme("t-light");
      window.sessionStorage.setItem("selectedtheme", "t-light");
      setTheme("t-light");
    }
    fetchHeaderData();
  }, [context?.language]);

  const fetchHeaderData = () => {
    let refLanguageSets = JSON.parse(JSON.stringify(languageSets));
    let refHeaderCache = JSON.parse(
      window.sessionStorage.getItem("headerCache")
    );
    if (refHeaderCache != null) {
      refHeaderCache?.filter((x) => {
        if (x.Key === "Header") {
          setHeaderLogo({
            light: x?.BrandLogo?.ImageUrl,
            dark: x?.BrandLogoDarkTheme?.ImageUrl,
            countryFlag:
              x?.Components?.LanguageSelectorComponent?.LanguageSelector[0]
                ?.Flag?.ImageUrl,
          });

          let lanComponent = x.Components["LanguageSelectorComponent"];
          setHeaderLabel(lanComponent?.LanguageSelector[0]?.Labels);
          let isLanguageAvailable = JSON.parse(
            window.sessionStorage.getItem("Language")
          );
          if (isLanguageAvailable != null) {
            let arrLanguage = [];
            lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map(
              (item) => {
                arrLanguage.push({
                  name: item?.LanguageName,
                  value: item?.ISOCode,
                });
              }
            );
            let retArr = arrLanguage?.filter((item) => {
              return (
                item.value !== isLanguageAvailable?.selectedLanguageISOCode
              );
            });
            retArr.unshift({
              name: isLanguageAvailable?.selectedLanguageFullName,
              value: isLanguageAvailable?.selectedLanguageISOCode,
            });
            refLanguageSets.selectedCountryShortName =
              isLanguageAvailable.selectedCountryShortName;
            refLanguageSets.selectedCountryFullName =
              isLanguageAvailable.selectedCountryFullName;
            refLanguageSets.selectedLanguageFullName =
              isLanguageAvailable.selectedLanguageFullName;
            refLanguageSets.selectedLanguageISOCode =
              isLanguageAvailable.selectedLanguageISOCode;
            refLanguageSets.languageList = retArr;
          } else {
            let arrLanguage = [];
            lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map(
              (item) => {
                arrLanguage.push({
                  name: item?.LanguageName,
                  value: item?.ISOCode,
                });
              }
            );
            let retArr = arrLanguage?.filter((item) => {
              return item.value !== returnLanguage();
            });
            let retArr2 = arrLanguage?.filter((item) => {
              return item.value === returnLanguage();
            });
            retArr.unshift({
              name: retArr2[0]?.name,
              value: retArr2[0]?.value,
            });
            refLanguageSets.selectedCountryShortName =
              lanComponent?.LanguageSelector[0]?.CountryName;
            refLanguageSets.selectedCountryFullName =
              lanComponent?.LanguageSelector[0]?.CountryFullName;
            refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
            refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
            refLanguageSets.languageList = retArr;
          }
        }
      });
      document.documentElement.setAttribute(
        "lang",
        refLanguageSets.selectedLanguageISOCode.split("-")[0]
      );

      setLanguageSets(refLanguageSets);
    } else {
      let payload = {
        PageName: "Header",
      };
      HeaderActions.headerLabels(payload).then(
        (res) => {
          if (res) {
            res?.data?.filter((x) => {
              if (x.Key === "Header") {
                setHeaderLogo({
                  light: x?.BrandLogo?.ImageUrl,
                  dark: x?.BrandLogoDarkTheme?.ImageUrl,
                  countryFlag:
                    x?.Components?.LanguageSelectorComponent
                      ?.LanguageSelector[0]?.Flag?.ImageUrl,
                });

                let lanComponent = x.Components["LanguageSelectorComponent"];
                setHeaderLabel(lanComponent?.LanguageSelector[0]?.Labels);
                let isLanguageAvailable = JSON.parse(
                  window.sessionStorage.getItem("Language")
                );
                if (isLanguageAvailable != null) {
                  let arrLanguage = [];
                  lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map(
                    (item) => {
                      arrLanguage.push({
                        name: item?.LanguageName,
                        value: item?.ISOCode,
                      });
                    }
                  );
                  let retArr = arrLanguage?.filter((item) => {
                    return (
                      item.value !==
                      isLanguageAvailable?.selectedLanguageISOCode
                    );
                  });
                  retArr.unshift({
                    name: isLanguageAvailable?.selectedLanguageFullName,
                    value: isLanguageAvailable?.selectedLanguageISOCode,
                  });
                  refLanguageSets.selectedCountryShortName =
                    isLanguageAvailable.selectedCountryShortName;
                  refLanguageSets.selectedCountryFullName =
                    isLanguageAvailable.selectedCountryFullName;
                  refLanguageSets.selectedLanguageFullName =
                    isLanguageAvailable.selectedLanguageFullName;
                  refLanguageSets.selectedLanguageISOCode =
                    isLanguageAvailable.selectedLanguageISOCode;
                  refLanguageSets.languageList = retArr;
                } else {
                  let arrLanguage = [];
                  lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map(
                    (item) => {
                      arrLanguage.push({
                        name: item?.LanguageName,
                        value: item?.ISOCode,
                      });
                    }
                  );
                  let retArr = arrLanguage?.filter((item) => {
                    return item.value !== returnLanguage();
                  });
                  let retArr2 = arrLanguage?.filter((item) => {
                    return item.value === returnLanguage();
                  });
                  retArr.unshift({
                    name: retArr2[0]?.name,
                    value: retArr2[0]?.value,
                  });
                  refLanguageSets.selectedCountryShortName =
                    lanComponent?.LanguageSelector[0]?.CountryName;
                  refLanguageSets.selectedCountryFullName =
                    lanComponent?.LanguageSelector[0]?.CountryFullName;
                  refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
                  refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
                  refLanguageSets.languageList = retArr;
                }
              }
            });
            document.documentElement.setAttribute(
              "lang",
              refLanguageSets.selectedLanguageISOCode.split("-")[0]
            );

            setLanguageSets(refLanguageSets);

            window.sessionStorage.setItem(
              "headerCache",
              JSON.stringify(res?.data)
            );
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify(refLanguageSets)
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const fetchHeaderLabel = (key) => {
    let label = "";
    if (headerLabel?.length > 0) {
      headerLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const themeChange = async (value) => {
    let refOnBoardingStatus = JSON.parse(
      window.sessionStorage.getItem("onBoardingStatus")
    );
    if (refOnBoardingStatus !== null && refOnBoardingStatus?.isProfileCreated) {
      let refSetProfileDetails = JSON.parse(
        window.sessionStorage.getItem("setProfileDetails")
      );
      let sUserID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
              "candidateID"
            ];
      const jsonData = {
        id: refSetProfileDetails?.id,
        firstName: refSetProfileDetails?.firstName,
        lastName: refSetProfileDetails?.lastName,
        preferredName: refSetProfileDetails?.preferredName,
        emailAddress: refSetProfileDetails?.emailAddress,
        secondaryEmailAddress: refSetProfileDetails?.secondaryEmailAddress,
        preferredContactNo: refSetProfileDetails?.preferredContactNo,
        preferredLanguageCode: refSetProfileDetails?.preferredLanguageCode,
        cityOfResidence: refSetProfileDetails?.cityOfResidence,
        timeZone: refSetProfileDetails?.timeZone,
        personaId: refSetProfileDetails?.personaId,
        personaName: refSetProfileDetails?.personaName,
        theme: value === "light" ? "t-light" : "t-dark",
      };
      if (refSetProfileDetails?.rightOfficeId !== undefined) {
        jsonData["rightOfficeId"] = refSetProfileDetails?.rightOfficeId;
        jsonData["rightOfficeName"] = refSetProfileDetails?.rightOfficeName;
      }
      console.log("jsonData", jsonData);
      await HeaderActions.SaveCandisdateProfile(sUserID, jsonData).then(
        (res) => {
          if (res.status === 204) {
            setTheme(value === "light" ? "t-light" : "t-dark");
            window.sessionStorage.setItem(
              "selectedtheme",
              value === "light" ? "t-light" : "t-dark"
            );
            context?.setTheme(value === "light" ? "t-light" : "t-dark");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      setTheme(value === "light" ? "t-light" : "t-dark");
      window.sessionStorage.setItem(
        "selectedtheme",
        value === "light" ? "t-light" : "t-dark"
      );
      context?.setTheme(value === "light" ? "t-light" : "t-dark");
    }
  };

  const languageHandler = (asd) => {
    let id = document.getElementById("select").value;
    if (id !== languageSets?.selectedLanguageISOCode) {
      let seleLan = languageSets.languageList.filter(
        (item) => item.value === id
      );
      window.sessionStorage.setItem(
        "Language",
        JSON.stringify({
          selectedCountryFullName: languageSets.selectedCountryFullName,
          selectedCountryShortName: languageSets.selectedCountryShortName,
          selectedLanguageFullName: seleLan[0]?.name,
          selectedLanguageISOCode: seleLan[0]?.value,
          languageList: languageSets?.languageList,
        })
      );

      sessionStorage.removeItem("headerCache");
      sessionStorage.removeItem("footerCache");
      document.documentElement.setAttribute(
        "lang",
        seleLan[0]?.value.split("-")[0]
      );
      context.setLanguageChanged(true);
      context.setLanguage({
        selectedCountryFullName: languageSets.selectedCountryFullName,
        selectedCountryShortName: languageSets.selectedCountryShortName,
        selectedLanguageFullName: seleLan[0]?.name,
        selectedLanguageISOCode: seleLan[0]?.value,
        languageList: languageSets?.languageList,
      });
    }
  };

  return (
    <div className="headerHeight">
      <Cirrus.SimpleHeader
        brandLogoLight={{
          src: headerLogo?.dark,
          altText: "Brand Name",
          onClick: () => {
            context?.setBeforeDashboardLogoRedirection(true);
            navigate("/home");
          },
        }}
        brandLogoDark={{
          src: headerLogo?.light,
          altText: "Brand Name",
          onClick: () => {
            context?.setBeforeDashboardLogoRedirection(true);
            navigate("/home");
          },
        }}
        languageSelector={{
          countryName: languageSets?.selectedCountryFullName,
          countryNameLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorYourCountry"
          ),
          flagImage: {
            altText: "Flag",
            src: headerLogo?.countryFlag,
          },
          label:
            languageSets?.selectedLanguageFullName +
            "(" +
            languageSets?.selectedCountryShortName +
            ")",
          languageSelect: {
            label: fetchHeaderLabel("Common.LanguageSelector.ChooseALanguage"),
            name: "siteLanguage",
            options: languageSets?.languageList,
            id: "select",
            defaultValue: languageSets?.selectedLanguageFullName,
          },
          onChange: languageHandler,
          popUpButtonLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorSaveButton"
          ),
          popUpTitle: fetchHeaderLabel(
            "Common.LanguageSelector.ChooseYourLanguagePreference"
          ),
        }}
        themeSwitcher={{
          accessibilityTexts: {
            darkThemeButton: "Select dark theme",
            lightThemeButton: "Select light theme",
          },
          id: "storybook-theme",
          onThemeChange: (e) => themeChange(e.target.value),
          type: "secondary",
          activeTheme:
            theme === "t-light"
              ? Cirrus.ThemeTypes.Light
              : Cirrus.ThemeTypes.Dark,
        }}
        backButtonProps={{
          label: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorBackButton"
          ),
          onClick: () => window.history.back(),
        }}
        mode={props.mode}
        className={props.className}
      />
    </div>
  );
}

export default Header;
