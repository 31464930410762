//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useEffect, useState } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import './index.css';
import { coachActions } from "./coachActions";
import { globalTimeFormat, globalDateFormat } from '../../common/helpers/DateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

function NoCoachScheduleMeeting({ fetchLabel, cmsContent, closeDialog, ReloadComp, StartDate }) { 

    let today = new Date ();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [arrAlternateDate, setarrAlternateDate] = useState([]);
    const [requestMeetingData, setrequestMeetingData] = useState({
        MeetingTitle: { FieldValue: "", status: "", errorMsg: "" },
        MeetingDuration: { FieldValue: fetchLabel("MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.MeetingDuration", "meetingSchedule_Labels"), status: "", errorMsg: "" },
        PreferredDate: { FieldValue: tomorrow, status: "", errorMsg: "" },
        PreferredTimeSlots: []
    })
    const [requestMeetingAdditonalDates, setrequestMeetingAdditonalDates] = useState({
        AdditionalDates: []
    })
    const [bIsFormInValid, setIsFormInValid] = useState(true)
    const [bDisplayConfirmationDlg, setbDisplayConfirmationDlg] = useState(false)
    const [sConfirmationText, setsConfirmationText] = useState("")
    const [durArray, SetdurArray] = useState([]);

      
   const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

    const closeRequestMeetingPopupWindow = () => {
        closeDialog(false)
        setarrAlternateDate([])
        setrequestMeetingData({
            ...requestMeetingData,
            MeetingTitle: { FieldValue: "", status: "", errorMsg: "" },
            MeetingDuration: { FieldValue: "", status: "", errorMsg: "" },
            PreferredDate: { FieldValue: "", status: "", errorMsg: "" },
            PreferredTimeSlots: []
        })

        setrequestMeetingAdditonalDates({
            ...requestMeetingAdditonalDates,
            AdditionalDates: []
        })
        setIsFormInValid(true)
    };

    const fnIncrementAlternateDateCount = () => {
        if (arrAlternateDate.length <= 2) {
            let arrData = arrAlternateDate
            let nData = ((arrAlternateDate[arrAlternateDate.length - 1]) === undefined ? 0 : arrAlternateDate[arrAlternateDate.length - 1])
            setarrAlternateDate(arrData.concat(nData + 1))
            setIsFormInValid(true)
        }
    }

    const fetchNoCoachLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let label = "";
        if (refCmsContent?.meetingSchedule_Labels.length > 0) {
            refCmsContent?.meetingSchedule_Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const inputsRequestMeetingHandler = (e,flag) => {
        let name='';
        let value='';
        if(flag == undefined) {      
        name = e.target.name;
        value=e.target.value;        
        }
        else {
        name=flag;
        value= e;
        }
        let objData = { FieldValue: value }

        const regexPreferredName = /^[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}\p{Script=Latin}\p{Script=Cyrillic}\p{Script=Greek}\p{Script=Arabic}\p{Script=Hebrew}\p{Script=Thai}\d\s]+$/u ;

        switch (name) {

            case 'MeetingTitle':
                if (value.length > 0) {
                    objData.status = regexPreferredName.test(value) === false ? "error" : ""
                    objData.errorMsg = regexPreferredName.test(value) === false ? fetchNoCoachLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.AllowedCharactersText",
                        "NoCoachRequestMeeting_Labels"
                    ) : ""
                }
                break;
            case "MeetingDuration":
                objData.status = (value.length === 0) ? "error" : ""
                objData.errorMsg = (value.length === 0) ? "Select duration " : ""
                break;
            case "PreferredDate":
                objData.status = (value.length === 0) ? "error" : ""
                objData.errorMsg = (value.length === 0) ? "Select date " : ""
                break;
            default:
                objData['status'] = ''
                objData['errorMsg'] = ''
                break;
        }
        setrequestMeetingData({ ...requestMeetingData, [name]: objData })
    }

    const handlerRequestMeetingPrefferedSlots = (e) => {

        const { name, checked, value } = e.target;
        const { PreferredTimeSlots } = requestMeetingData;

        if (checked === true) {
            setrequestMeetingData({ ...requestMeetingData, PreferredTimeSlots: [...PreferredTimeSlots, value] })
        }
        else {
            setrequestMeetingData({ ...requestMeetingData, PreferredTimeSlots: PreferredTimeSlots.filter((e) => e !== value) })
        }
    }

    /// For Additioanl Dates
    const handlerinputsAdditionalDate = (e, nIndex) => {
        e.preventDefault();
        const { name, value } = e.target;
        let objData = { FieldValue: value }
        const lstAdditionalDates = [...requestMeetingAdditonalDates.AdditionalDates];

        let objTempData = lstAdditionalDates[nIndex]
        if (objTempData === undefined) {
            objTempData = {
                AdditionalPreferredDate: { FieldValue: "", status: "", errorMsg: "" },
                PreferredTimeSlots: [],
            }
            lstAdditionalDates[nIndex] = objTempData
        }

        lstAdditionalDates[nIndex][name] = objData;

        switch (name) {
            case ("AdditionalPreferredDate"):
                objData.status = (value.length === 0) ? "error" : ""
                objData.errorMsg = (value.length === 0) ? "Select date " : ""
                break;
            default:
                objData['status'] = ''
                objData['errorMsg'] = ''
                break;
        }
        setrequestMeetingAdditonalDates({ ...requestMeetingAdditonalDates, AdditionalDates: lstAdditionalDates })
    }

    const handlerAdditionalSlots = (e, nParentIndex, nIndex) => {

        const { checked, value } = e.target;
        const { AdditionalDates } = requestMeetingAdditonalDates;

        let objAdditionalDateData = AdditionalDates[nParentIndex]
        if (objAdditionalDateData === undefined) {
            objAdditionalDateData = {
                AdditionalPreferredDate: { FieldValue: "", status: "", errorMsg: "" },
                PreferredTimeSlots: [],
            }
        }

        let arrTiemSlots = objAdditionalDateData?.PreferredTimeSlots


        if (checked === true) {
            arrTiemSlots.push(value)
            objAdditionalDateData.PreferredTimeSlots = arrTiemSlots
            setrequestMeetingAdditonalDates({ ...requestMeetingAdditonalDates, AdditionalDates: AdditionalDates })
        }
        else {
            arrTiemSlots.push(value)
            let arrNew = objAdditionalDateData.PreferredTimeSlots.filter((e) => e !== value)
            objAdditionalDateData.PreferredTimeSlots = arrNew
            setrequestMeetingAdditonalDates({ ...requestMeetingAdditonalDates, AdditionalDates: AdditionalDates })
        }
    }

    const fnValidRequestMeetingForm = () => {
        if (requestMeetingData.MeetingTitle?.FieldValue?.length === 0 || requestMeetingData.PreferredDate?.FieldValue?.length === 0 || requestMeetingData.PreferredTimeSlots?.length === 0) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        let bFormInValid = fnValidRequestMeetingForm()
        setIsFormInValid(bFormInValid)
    }, [requestMeetingData])

    const fnValidAdditionalDates = () => {
        if (requestMeetingAdditonalDates.AdditionalDates.length > 0) {
            let bFlag = false
            requestMeetingAdditonalDates.AdditionalDates.map((AdditonalDate) => {
                if (AdditonalDate.AdditionalPreferredDate?.FieldValue?.length === 0 || AdditonalDate.PreferredTimeSlots?.length === 0) {
                    bFlag = true
                }
                else {
                    bFlag = false
                }
            })
            return bFlag
        }
        else {
            return true
        }
    }

    useEffect(() => {
        let bFormInValid = fnValidAdditionalDates()
        setIsFormInValid(bFormInValid)
    }, [requestMeetingAdditonalDates])


    const fnSaveRequestMeeting = async (e) => {
        let objData = {}
        objData["title"] = requestMeetingData.MeetingTitle.FieldValue
        objData["duration"] = fetchLabel("MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.MeetingDuration", "NoCoachRequestMeeting_Labels")
        
        let selectedDate = requestMeetingData.PreferredDate.FieldValue
        let formattedRequestDate = selectedDate.getFullYear() + "-" + ('0' + (selectedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (selectedDate.getDate())).slice(-2);
        objData["preferredTimeStamp"] = formattedRequestDate;
        
        let uniqueSlots = [...new Set(requestMeetingData.PreferredTimeSlots)];
        objData["preferredTimeSlot"] = uniqueSlots.toString()
        objData["status"] = "Pending"
        if (requestMeetingAdditonalDates.AdditionalDates.length > 0) {
            requestMeetingAdditonalDates.AdditionalDates.map((ele, index) => {
                if (ele?.AdditionalPreferredDate.FieldValue !== "") {
                    objData["alternateTimeStamp" + (index + 1)] = ele?.AdditionalPreferredDate.FieldValue
                    if (ele?.PreferredTimeSlots.length > 0) {
                        let uniqueSlots = [...new Set(ele?.PreferredTimeSlots)];
                        objData["preferredTimeSlot" + (index + 1)] = uniqueSlots.toString()
                    }
                }
            })
        }

        await coachActions
            .SaveNoCoachMeetingRequest(objData)
            .then(async (res) => {
                if (res.status === 201) {
                    setbDisplayConfirmationDlg(true)
                    setsConfirmationText(fetchLabel("MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.SuccessMessage", "NoCoachRequestMeeting_Labels"
                    ))
                    setrequestMeetingData({
                        ...requestMeetingData,
                        MeetingTitle: { FieldValue: "", status: "", errorMsg: "" },
                        MeetingDuration: { FieldValue: "", status: "", errorMsg: "" },
                        PreferredDate: { FieldValue: "", status: "", errorMsg: "" },
                        PreferredTimeSlots: []
                    })
                    setrequestMeetingAdditonalDates({
                        ...requestMeetingAdditonalDates,
                        AdditionalDates: []
                    })
                }
                else {
                    setbDisplayConfirmationDlg(true)
                    setsConfirmationText(fetchLabel("MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.ErrorMessage", "NoCoachRequestMeeting_Labels"
                    ))
                    setrequestMeetingData({
                        ...requestMeetingData,
                        PreferredTimeSlots: []
                    })
                }
            });
    }

    const fnCloseSaveConfirmBox = () => {
        if (sConfirmationText.includes("There is a problem")) {
            setbDisplayConfirmationDlg(false)
            closeDialog(true)
            setsConfirmationText("")
        }
        else {
            setbDisplayConfirmationDlg(false)
            closeDialog(false)
            ReloadComp((prev) => prev + 1)
        }

    }

    return (
        <>

            {!bDisplayConfirmationDlg && (
                <Cirrus.Modal
                    className="meetingConfirmModal"
                    closeButtonProps={{
                        leadingIcon: "close",
                        isSecondary: false,
                        onClick: () => closeDialog(false),
                        children: "Close",
                        type: "button",
                    }}
                    closeIconButtonProps={{
                        icon: "close",
                        isSecondary: true,
                        onClick: () => closeRequestMeetingPopupWindow(),
                        text: "Cancel",
                        type: "button",
                    }}
                    headerProps={{
                        children: cmsContent.NoCoachRequestMeeting_Title,
                        className: "no-margin no-coach-meeting-header",
                        size: "h5",
                    }}
                    isScrollable
                    role="dialog"
                    size="mobile"
                    style={{ position: "relative" }}
                >

                    <Cirrus.Input
                        id="MeetingTitle"
                        label={fetchLabel(
                            "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.MeetingTitle",
                            "NoCoachRequestMeeting_Labels"
                        )}
                        name="MeetingTitle"
                        placeholder={fetchLabel(
                            "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.MeetingTitlePlaceHolder",
                            "NoCoachRequestMeeting_Labels"
                        )}
                        maxLength={50}
                        onChange={inputsRequestMeetingHandler}
                        state={requestMeetingData.MeetingTitle.status}
                        value={requestMeetingData.MeetingTitle.FieldValue}
                        errorMessage={requestMeetingData.MeetingTitle.errorMsg}
                        required={true}
                        className='mb-3'
                    />
                    <Cirrus.Input
                        errorMessage=""
                        id="MeetingDuration"
                        label={fetchLabel(
                            "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.Duration",
                            "NoCoachRequestMeeting_Labels"
                        )}
                        name="duration"
                        placeholder="30 minutes"
                        disabled={true}
                        value={requestMeetingData.MeetingDuration.FieldValue}
                        className='mb-3'
                    />                   								
                                        <Cirrus.Label htmlFor="Example">
                                            {fetchLabel(
                                              "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.PreferredDate",
                                              "NoCoachRequestMeeting_Labels"
                                            )}
                                        </Cirrus.Label>
                                        <DatePicker
                                            className='mb-3'
                                            onKeyDown={(e) => { e.preventDefault(); }}
                                            id="PreferredDate"
                                            name="PreferredDate"
                                            label="Date"
                                            visiblity={'hidden'}
                                            selected={requestMeetingData.PreferredDate.FieldValue}
                                            filterDate={isWeekday}
                                            showTimeSelect={false}
                                            onChange={(e) => inputsRequestMeetingHandler(e, "PreferredDate")}     
                                            dateFormat={globalDateFormat(requestMeetingData.PreferredDate.FieldValue)}
                                            minDate={tomorrow}
                                            
                                            errorMessage={requestMeetingData.PreferredDate.errorMsg}
                                        />
                                         
                    <div className='mb-3'>
                        <Cirrus.Label>
                            {
                                fetchLabel(
                                    "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.PreferredTimeSlot",
                                    "NoCoachRequestMeeting_Labels"
                                )}
                        </Cirrus.Label>
                        {cmsContent.NoCoachRequestMeeting_TimeSlots.length > 0 &&
                            cmsContent.NoCoachRequestMeeting_TimeSlots.map((timesolt, key) => {

                                return (<Cirrus.Checkbox
                                    label={timesolt.name}
                                    name={"PreferredTimeSlots"}
                                    onChange={(event) => handlerRequestMeetingPrefferedSlots(event)}
                                    value={timesolt.value}
                                    key={key}
                                />
                                )
                            })
                        }
                    </div>
                    {arrAlternateDate.length > 0 && arrAlternateDate.map((ele, index) => {
                        return (<>
                            <hr />
                            <Cirrus.Input
                                id="rescheduledate"
                                label={"Alternate date-" + (index + 1)}
                                name="AdditionalPreferredDate"
                                placeholder="Preferred date"
                                type="date"
                                onChange={(event) => handlerinputsAdditionalDate(event, index)}
                                state={requestMeetingAdditonalDates.AdditionalDates[index]?.AdditionalPreferredDate?.status}
                                defaultValue={requestMeetingAdditonalDates.AdditionalDates[index]?.AdditionalPreferredDate?.FieldValue}
                                errorMessage={requestMeetingAdditonalDates.AdditionalDates[index]?.AdditionalPreferredDate?.errorMsg}
                                key={index}
                                min={StartDate}
                            />
                            <Cirrus.Label>
                                {
                                    fetchLabel(
                                        "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.PreferredTimeSlot",
                                        "NoCoachRequestMeeting_Labels"
                                    )}

                            </Cirrus.Label>
                            {cmsContent.NoCoachRequestMeeting_TimeSlots.length > 0 &&
                                cmsContent.NoCoachRequestMeeting_TimeSlots.map((timesolt, childIndex) => {

                                    return (<Cirrus.Checkbox
                                        label={timesolt.name}
                                        name={"timesslot" + childIndex}
                                        onChange={(event) => handlerAdditionalSlots(event, index, childIndex)}
                                        value={timesolt.value}
                                        key={childIndex}
                                    />
                                    )
                                })
                            }
                        </>
                        )
                    })}

                    <Cirrus.Button
                        size="Large"
                        isSecondary
                        onClick={fnIncrementAlternateDateCount}
                        isFullWidth={true}
                        disabled={arrAlternateDate.length === 3 ? true : bIsFormInValid}
                    >
                        {
                            fetchLabel(
                                "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.AddAnotherDate",
                                "NoCoachRequestMeeting_Labels"
                            )}
                    </Cirrus.Button>

                    <Cirrus.Button
                        className="mt-2"

                        onClick={fnSaveRequestMeeting}
                        isFullWidth={true}
                        disabled={bIsFormInValid}
                    >
                        {
                            fetchLabel(
                                "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.SendMeetingRequest",
                                "NoCoachRequestMeeting_Labels"
                            )}
                    </Cirrus.Button>
                </Cirrus.Modal>
            )}

            {bDisplayConfirmationDlg && (
                <>
                    <Cirrus.AlertModal
                        className="CancelMeeting"
                        closeIconButtonProps={{
                            icon: "close",
                            isSecondary: true,
                            onClick: fnCloseSaveConfirmBox,
                            text: "Cancel",
                            type: "button",
                        }}
                        heading={
                            fetchLabel(
                                "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.Confirmation",
                                "NoCoachRequestMeeting_Labels"
                            )}
                        primaryButton={{
                            children: fetchLabel("MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.DoneText", "NoCoachRequestMeeting_Labels"),
                            onClick: fnCloseSaveConfirmBox,
                            size: "Large",
                            type: "button",
                        }}
                        role="alertdialog"
                        subheading={sConfirmationText}
                    />
                </>
            )}
        </>
    );
}

export default NoCoachScheduleMeeting;
