//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../context/Context";
import { FooterActions } from "./FooterActions";
import Spinner from "../spinner-loader/Spinner";
import CanFlag from "../../../assets/Images/canadaflag.png";
import USFlag from "../../../assets/Images/usaflag.png";
import manPower from "manpowergroup-cirrus/assets/images/manpower/manpower.png";
import experis from "manpowergroup-cirrus/assets/images/manpower/experis.png";
import talentSolutions from "manpowergroup-cirrus/assets/images/manpower/talent-solutions.png";
import manPowerGroup from "manpowergroup-cirrus/assets/images/manpower/manpowergroup.png";

const Footer = ({ isLoggedIn }) => {
  let sUserID = JSON.parse(
    window.sessionStorage.getItem("LoginCandidateToken")
  );
  const context = useContext(Context);
  const navigate = useNavigate();
  const [headerLogo, setHeaderLogo] = useState({
    light: "",
    dark: "",
    countryFlag: "",
  });
  const [languageSets, setLanguageSets] = useState({
    selectedCountryFullName: "",
    selectedCountryShortName: "",
    selectedLanguageFullName: "",
    selectedLanguageISOCode: "",
    languageList: [],
  });
  const [masterNavigation, setMasterNavigation] = useState({
    postLoggedinLinks: [],
    preLoggedInLinks: [],
    preLoggedInDescription: "",
    label: [],
  });
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const [screenLoader, setScreenLoader] = useState(false);

  const handleWindowResize = () => {
    setViewPortWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    fetchFootarData();
  }, [context?.language]);

  const fetchFootarData = () => {
    let refLanguageSets = JSON.parse(JSON.stringify(languageSets));
    let refMasterNavigation = JSON.parse(JSON.stringify(masterNavigation));
    let refFooterCache = JSON.parse(
      window.sessionStorage.getItem("footerCache")
    );
    if (refFooterCache != null) {
      refFooterCache?.filter((x) => {
        setHeaderLogo({
          light: x?.BrandLogo?.ImageUrl,
          dark: x?.BrandLogoDarkTheme?.ImageUrl,
          countryFlag:
            x?.Components?.LanguageSelectorComponent?.SupportedLanguages[0]
              ?.Flag,
        });

        let lanComponent = x.Components["LanguageSelectorComponent"];
        refMasterNavigation.label = lanComponent?.Labels;
        let isLanguageAvailable = JSON.parse(
          window.sessionStorage.getItem("Language")
        );
        let arrLanguage = [];
        lanComponent?.SupportedLanguages[0]?.Supportedlanguage?.map((item) => {
          arrLanguage.push({
            name: item?.LanguageName,
            value: item?.ISOCode,
          });
        });
        let retArr = arrLanguage?.filter((item) => {
          return item.value !== isLanguageAvailable?.selectedLanguageISOCode;
        });
        let retArr2 = arrLanguage?.filter((item) => {
          return item.value === isLanguageAvailable?.selectedLanguageISOCode;
        });
        retArr.unshift({
          name: retArr2[0]?.name,
          value: retArr2[0]?.value,
        });
        refLanguageSets.selectedCountryFullName =
          isLanguageAvailable?.selectedCountryFullName;
        refLanguageSets.selectedCountryShortName =
          isLanguageAvailable?.selectedCountryShortName;
        refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
        refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
        refLanguageSets.languageList = retArr;

        let postFooterList = [];
        x?.Components["PostLoggedinFooter"]?.NavigationItems?.map(
          (mainItem) => {
            let obj = {
              key: mainItem?.Key,
              title: mainItem?.Title,
              isVisible: mainItem?.IsVisible,
              links: [],
            };
            mainItem?.LinkedItems?.map((childItem) => {
              obj.links.push({
                children: childItem?.NavigationTitle,
                isVisible: childItem?.IsVisible,
                onClick: () => {
                  navigate(childItem?.Link?.Url);
                },
              });
            });
            postFooterList.push(obj);
          }
        );
        refMasterNavigation.postLoggedinLinks = postFooterList;

        let perFooterList = [];
        x?.Components["PreLoggedInFooter"]?.LinkList?.map((mainItem) => {
          perFooterList.push({
            title: mainItem?.Link?.Text,
            url: mainItem?.Link?.Url,
            isVisible: mainItem?.IsVisible,
            altText: mainItem?.Link?.AltText,
            target: mainItem?.Link?.Target,
          });
        });
        refMasterNavigation.preLoggedInLinks = perFooterList;

        refMasterNavigation.preLoggedInDescription =
          x?.Components["PreLoggedInFooter"]?.RichTexts[0].Value;
      });
      setMasterNavigation(refMasterNavigation);
      setLanguageSets(refLanguageSets);
    } else {
      let payload = {
        PageName: "Footer",
      };

      FooterActions.FooterLabels(payload).then(
        (res) => {
          if (res.status === 200) {
            window.sessionStorage.setItem(
              "footerCache",
              JSON.stringify(res?.data)
            );
            res?.data?.filter((x) => {
              setHeaderLogo({
                light: x?.BrandLogo?.ImageUrl,
                dark: x?.BrandLogoDarkTheme?.ImageUrl,
                countryFlag:
                  x?.Components?.LanguageSelectorComponent
                    ?.SupportedLanguages[0]?.Flag,
              });

              let lanComponent = x.Components["LanguageSelectorComponent"];
              refMasterNavigation.label = lanComponent?.Labels;
              let isLanguageAvailable = JSON.parse(
                window.sessionStorage.getItem("Language")
              );
              let arrLanguage = [];
              lanComponent?.SupportedLanguages[0]?.Supportedlanguage?.map(
                (item) => {
                  arrLanguage.push({
                    name: item?.LanguageName,
                    value: item?.ISOCode,
                  });
                }
              );
              let retArr = arrLanguage?.filter((item) => {
                return (
                  item.value !== isLanguageAvailable?.selectedLanguageISOCode
                );
              });
              let retArr2 = arrLanguage?.filter((item) => {
                return (
                  item.value === isLanguageAvailable?.selectedLanguageISOCode
                );
              });
              retArr.unshift({
                name: retArr2[0]?.name,
                value: retArr2[0]?.value,
              });
              refLanguageSets.selectedCountryFullName =
                isLanguageAvailable?.selectedCountryFullName;
              refLanguageSets.selectedCountryShortName =
                isLanguageAvailable?.selectedCountryShortName;
              refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
              refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
              refLanguageSets.languageList = retArr;

              let postFooterList = [];
              x?.Components["PostLoggedinFooter"]?.NavigationItems?.map(
                (mainItem) => {
                  let obj = {
                    key: mainItem?.Key,
                    title: mainItem?.Title,
                    isVisible: mainItem?.IsVisible,
                    links: [],
                  };
                  mainItem?.LinkedItems?.map((childItem) => {
                    obj.links.push({
                      children: childItem?.NavigationTitle,
                      isVisible: childItem?.IsVisible,
                      onClick: () => {
                        navigate(childItem?.Link?.Url);
                      },
                    });
                  });
                  postFooterList.push(obj);
                }
              );
              refMasterNavigation.postLoggedinLinks = postFooterList;

              let perFooterList = [];
              x?.Components["PreLoggedInFooter"]?.LinkList?.map((mainItem) => {
                perFooterList.push({
                  title: mainItem?.Link?.Text,
                  url: mainItem?.Link?.Url,
                  isVisible: mainItem?.IsVisible,
                  altText: mainItem?.Link?.AltText,
                  target: mainItem?.Link?.Target,
                });
              });
              refMasterNavigation.preLoggedInLinks = perFooterList;

              refMasterNavigation.preLoggedInDescription =
                x?.Components["PreLoggedInFooter"]?.RichTexts[0].Value;
            });
            setMasterNavigation(refMasterNavigation);
            setLanguageSets(refLanguageSets);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const languageHandler = async (asd) => {
    let id = document.getElementById("select").value;
    if (id !== languageSets?.selectedLanguageISOCode) {
      setScreenLoader(true);
      let refSetProfileDetails = JSON.parse(
        window.sessionStorage.getItem("setProfileDetails")
      );
      let refSetIdTokenClaims = JSON.parse(
        window.sessionStorage.getItem("setIdTokenClaims")
      );
      let refSetProgramDetails = JSON.parse(
        window.sessionStorage.getItem("setProgramDetails")
      );
      let jsonData = {};
      if (refSetProfileDetails === null) {
        jsonData = {
          id: sUserID?.candidateID, //refSetIdTokenClaims?.signupId,
          firstName: refSetIdTokenClaims?.firstName,
          lastName: refSetIdTokenClaims?.lastName,
          // preferredName: refSetProfileDetails?.preferredName,
          emailAddress: refSetIdTokenClaims?.email,
          // secondaryEmailAddress: refSetProfileDetails?.secondaryEmailAddress,
          // preferredContactNo: refSetProfileDetails?.preferredContactNo,
          languageCode: id,
          preferredLanguageCode: id,
          // cityOfResidence: refSetProfileDetails?.cityOfResidence,
          // timeZone: refSetProfileDetails?.timeZone,
          // personaId: refSetProfileDetails?.personaId,
          // personaName: refSetProfileDetails?.personaName,
          rightOfficeId: refSetProgramDetails?.rightOffices?.[0]?.id,
          rightOfficeName: refSetProgramDetails?.rightOffices?.[0]?.name,
        };
      } else {
        jsonData = {
          id: refSetProfileDetails?.id,
          firstName: refSetProfileDetails?.firstName,
          lastName: refSetProfileDetails?.lastName,
          preferredName: refSetProfileDetails?.preferredName,
          emailAddress: refSetProfileDetails?.emailAddress,
          secondaryEmailAddress: refSetProfileDetails?.secondaryEmailAddress,
          preferredContactNo: refSetProfileDetails?.preferredContactNo,
          languageCode: id,
          preferredLanguageCode: id,
          cityOfResidence: refSetProfileDetails?.cityOfResidence,
          timeZone: refSetProfileDetails?.timeZone,
          personaId: refSetProfileDetails?.personaId,
          personaName: refSetProfileDetails?.personaName,
        };
      }

      if (refSetProfileDetails?.rightOfficeId !== undefined) {
        jsonData["rightOfficeId"] = refSetProfileDetails?.rightOfficeId;
        jsonData["rightOfficeName"] = refSetProfileDetails?.rightOfficeName;
      }
      await FooterActions.SaveCandisdateProfile(
        sUserID?.candidateID,
        jsonData
      ).then(
        (res) => {
          if (res.status === 204) {
            let seleLan = languageSets.languageList.filter(
              (item) => item.value === id
            );
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify({
                selectedCountryFullName: languageSets.selectedCountryFullName,
                selectedCountryShortName: languageSets.selectedCountryShortName,
                selectedLanguageFullName: seleLan[0]?.name,
                selectedLanguageISOCode: seleLan[0]?.value,
                languageList: languageSets?.languageList,
              })
            );

            sessionStorage.removeItem("headerCache");
            sessionStorage.removeItem("footerCache");
            document.documentElement.setAttribute(
              "lang",
              seleLan[0]?.value.split("-")[0]
            );
            context.setLanguageChanged(true);
            context.setLanguage({
              selectedCountryFullName: languageSets.selectedCountryFullName,
              selectedCountryShortName: languageSets.selectedCountryShortName,
              selectedLanguageFullName: seleLan[0]?.name,
              selectedLanguageISOCode: seleLan[0]?.value,
              languageList: languageSets?.languageList,
            });
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const fetchHeaderLabel = (key) => {
    let label = "";
    if (masterNavigation.label?.length > 0) {
      masterNavigation.label?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      <Cirrus.LoggedInFooter
        isLoggedIn={isLoggedIn}
        brandLogoLight={{
          altText: "Brand name",
          src: headerLogo?.dark,
          onClick: () => {
            navigate("/dashboard");
          },
        }}
        languageSelectorProps={{
          countryName: languageSets?.selectedCountryFullName,
          countryNameLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorYourCountry"
          ),
          flagImage: {
            altText: "Flag",
            src: headerLogo?.countryFlag,
          },
          label:
            languageSets?.selectedLanguageFullName +
            "(" +
            languageSets?.selectedCountryShortName +
            ")",
          languageSelect: {
            label: fetchHeaderLabel("Common.LanguageSelector.ChooseALanguage"),
            name: "siteLanguage",
            options: languageSets?.languageList,
            id: "select",
            defaultValue: languageSets?.selectedLanguageFullName,
          },
          onChange: languageHandler,
          popUpButtonLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorSaveButton"
          ),
          popUpTitle: fetchHeaderLabel(
            "Common.LanguageSelector.ChooseYourLanguagePreference"
          ),
        }}
        locationValue={languageSets?.selectedCountryFullName}
        locationLabel="Your location"
        navigationColumn={masterNavigation?.postLoggedinLinks}
        corporateLogo={{
          href: "https://www.manpowergroup.com",
          imageProps: {
            altText: "Manpower Group",
            src: manPowerGroup,
          },
          target: "_blank",
          text: "Visit the Manpower Group website",
        }}
        companyLogos={[
          {
            href: "https://www.manpower.com",
            imageProps: {
              altText: "Manpower",
              src: manPower,
            },
            target: "_blank",
            text: "Visit the Manpower website",
          },
          {
            href: "https://www.experis.com",
            imageProps: {
              altText: "Experis",
              src: experis,
            },
            target: "_blank",
            text: "Visit the Experis website",
          },
          {
            href: "https://talentsolutions.manpowergroup.com/",
            imageProps: {
              altText: "Talent Solutions",
              src: talentSolutions,
            },
            target: "_blank",
            text: "Visit the Talent Solutions website",
          },
        ]}
        copyrightText="© 2022 ManpowerGroup All Rights Reserved."
        legalText={masterNavigation?.preLoggedInDescription}
        visitLink={masterNavigation?.preLoggedInLinks}
      />
    </>
  );
};

export default Footer;
