//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { HeaderActions } from "../headerActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { Context } from "../../../common/context/Context";
import DashboardAccordion from "../systemMessage/systemMesasaageAccordian";
import { TrackActions } from "../../../common/utility/LogAction";
import {
  UTCtoLocalTimeWithAMPM,
  UTCtoLocalDateTimeOnlyForCalendar,
  globalDateFormat,
} from "../../../common/helpers/DateTimeHelper";

// Images
import LightLogo from "../../../assets/Images/brandnamelight.png";
import DarkLogo from "../../../assets/Images/brandnamedark.png";
import CanFlag from "../../../assets/Images/canadaflag.png";
import USFlag from "../../../assets/Images/usaflag.png";

function HeaderWithNav() {
  const { instance } = useMsal();
  const context = useContext(Context);
  const navigate = useNavigate();
  const [masterNavigationLink, setMasterNavigationLink] = useState([]);
  const [masterLabel, setMasterLabel] = useState([]);
  const [languageSets, setLanguageSets] = useState({
    selectedCountryFullName: "",
    selectedCountryShortName: "",
    selectedLanguageFullName: "",
    selectedLanguageISOCode: "",
    languageList: [],
  });
  const [bCoachingAvailable, setbCoachingAvailable] = useState();
  const appInsights = useAppInsightsContext();
  const [notification, setNotification] = useState([]);
  const [isReaded, setIsReaded] = useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(16);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isViewd, setIsViewd] = useState(true);
  const [noLoadMore, setNoLoadMore] = useState(true);
  const [loadMoreNotification, setLoadMoreNotification] = useState(false);
  const [systemNotificationData, setSystemNotificationData] = useState([]);
  const [systemNotificationFilterData, setSystemNotificationFilterData] =
    useState([]);
  const [negotiateUrl, setNegotiateUrl] = useState("");
  const [signalRMessage, setSignalRMessage] = useState([]);
  const [headerLogo, setHeaderLogo] = useState({
    light: "",
    dark: "",
    countryFlag: "",
  });
  const [secondaryMenuLink, setSecondaryManuLink] = useState();
  const [readSystemMessageStatus, setReadSystemMessageStatus] = useState(true);
  const [screenLoader, setScreenLoader] = useState(false);
  let sUserID =
    context?.idTokenClaims?.signupId != undefined
      ? context?.idTokenClaims?.signupId
      : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
          "candidateID"
        ];
  const [theme, setTheme] = useState("");
  let programDetails = JSON.parse(
    window.sessionStorage.getItem("setProgramDetails")
  );

  useEffect(() => {
    getNotification();
    getSystemNotifications();
  }, [isReaded, context?.notificationSignalRMessages, context?.language]);

  useEffect(() => {
    // getNegotiateUrl();
    createSignalRConnection();
    isCalenderModuleVisableHandler();
  }, []);

  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  function getFeaturesConfig(featureName) {
    let val;
    featuresConfig?.features?.map((feature) => {
      if (feature.featureName === featureName) {
        val = feature.isActive;
      }
    });
    return val;
  }

  function PageViewHandler() {
    TrackActions.PageViewRecord(appInsights, "Notification");
    TrackActions.PageAction("Notification");
  }

  const isCalenderModuleVisableHandler = () => {
    HeaderActions.CalenderMenu().then(
      (res) => {
        if (res.status === 200) {
          const flag = res?.data;
          window.sessionStorage.setItem(
            "calendarShowFlag",
            JSON.stringify(flag)
          );
          if (flag) {
            setSecondaryManuLink(flag);
          } else {
            setSecondaryManuLink(flag);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  if (document.getElementById("drop")) {
    document.getElementById("drop").onclick = PageViewHandler;
  }

  const onRead = async (id) => {
    try {
      const isRead = [
        {
          notificationId: id,
          isRead: true,
        },
      ];
      await HeaderActions.readNotifications(isRead);

      if (!loadMoreNotification) {
        setIsReaded(!isReaded);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Read system messages
  const onReadSystemMessages = async (id) => {
    try {
      const isRead = {
        notificationId: id,
        isRead: true,
      };
      await HeaderActions.readSystemMessages(isRead);
      // getSystemNotifications();
      getNotification();
    } catch (err) {
      console.log(err);
    }
  };

  function timeSince(value) {
    let date1 = Moment.tz(value, "UTC").format("YYYY-MM-DD HH:mm:ss"); //new Date(value);
    let date2 = Moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm:ss");

    let diffInSeconds = Moment.duration(
      Moment(date2).diff(Moment(date1))
    ).asSeconds(); // Math.abs(date1 - date2) / 1000;
    let days = Math.floor(diffInSeconds / 60 / 60 / 24);
    let diffe = "";
    if (days >= 1) {
      let dateOut = UTCtoLocalDateTimeOnlyForCalendar(value);
      return (diffe = globalDateFormat(dateOut));
    } else {
      let dateOut = globalDateFormat(UTCtoLocalDateTimeOnlyForCalendar(value));
      let timeOut = UTCtoLocalTimeWithAMPM(value);
      return (diffe = `${dateOut + ", " + timeOut}`);
    }
  }

  async function getNotification() {
    let isLanguageAvailable = JSON.parse(
      window.sessionStorage.getItem("Language")
    );
    let locale = JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
    await HeaderActions.getNotifications(
      0,
      limit,
      locale.preferredLanguageCode || locale.languageCode
    )
      .then((res) => {
        if (res?.status === 200) {
          let { notificationDetails, notificationsCount } = res?.data;
          const notifications = notificationDetails?.map((notification) => {
            const obj = {
              id: notification?.notificationId,
              link: {
                content: notification?.notificationMessage,
                onClick: () => onRead(notification?.notificationId),
              },
              seen: notification?.isRead,
              highlightedText: timeSince(notification?.notificationDate),
            };
            return obj;
          });

          const FilterNotification = notifications?.filter(
            (x) => x.notificationType !== "System Messages"
          );

          const sortedNotification = FilterNotification?.sort((a, b) => {
            return a.seen - b.seen;
          });
          const GetSystemMesssage =
            notifications &&
            notifications?.filter(
              (x) => x.notificationType == "System Messages"
            );
          setSystemNotificationFilterData(GetSystemMesssage);
          setNotification(sortedNotification);
          setNotificationCount(notificationsCount);
        }
      })
      .catch((err) => console.log(err));
  }

  const makeAllSeen = async () => {
    try {
      const filterNotifications =
        notification && notification?.filter((x) => x.seen == false);
      const markAllAsRead = filterNotifications?.map((notification) => {
        const readData = {
          notificationId: notification?.id,
          isRead: true,
        };
        return readData;
      });
      await HeaderActions.readNotifications(markAllAsRead);
      setIsReaded(!isReaded);
    } catch (err) {
      console.log(err);
    }
  };

  const viewAll = () => {
    setIsViewd(false);
  };

  const loadMore = async () => {
    let isLanguageAvailable = JSON.parse(
      window.sessionStorage.getItem("Language")
    );
    let locale = JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
    try {
      setOffset((prev) => prev + 1);
      setLoadMoreNotification(true);
      const oldNotifcation = JSON.parse(JSON.stringify(notification));
      await HeaderActions.getNotifications(
        offset,
        limit,
        locale.preferredLanguageCode || locale.languageCode
      ).then((res) => {
        if (res?.status === 200) {
          let { notificationDetails } = res?.data;

          if (notificationDetails.length < 0) {
            setNoLoadMore(false);
            return;
          }
          const loadMoreNotifications =
            notificationDetails.length > 0 &&
            notificationDetails?.map((notification) => {
              const obj = {
                id: notification?.notificationId,
                link: {
                  content: notification?.notificationMessage,
                  onClick: () => onRead(notification?.notificationId),
                },
                seen: notification?.isRead,
                highlightedText: timeSince(notification?.notificationDate),
              };
              return obj;
            });

          let merge;
          if (loadMoreNotifications.length > 0) {
            merge = [...oldNotifcation, ...loadMoreNotifications];
            const FilterNotification = merge?.filter(
              (x) => x.notificationType !== "System Messages"
            );
            const mergeSort = FilterNotification.sort((a, b) => {
              return a.seen - b.seen;
            });
            const GetSystemMesssage =
              merge &&
              merge?.filter((x) => x.notificationType == "System Messages");
            setSystemNotificationFilterData(GetSystemMesssage);
            setNotification(mergeSort);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("setProgramDetails") != null) {
      setbCoachingAvailable(
        JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "isCoachingAvailableForProgram"
        ]
      );
    } else {
      setbCoachingAvailable(false);
    }
    let refSelectedtheme = window.sessionStorage.getItem("selectedtheme");
    if (refSelectedtheme !== null) {
      context?.setTheme(refSelectedtheme);
      window.sessionStorage.setItem("selectedtheme", refSelectedtheme);
      setTheme(refSelectedtheme);
    } else {
      context?.setTheme("t-light");
      window.sessionStorage.setItem("selectedtheme", "t-light");
      setTheme("t-light");
    }
    fetchHeaderData();
  }, [context?.language]);

  const fetchHeaderData = () => {
    let refLanguageSets = JSON.parse(JSON.stringify(languageSets));
    let refHeaderCache = JSON.parse(
      window.sessionStorage.getItem("headerCache")
    );
    if (refHeaderCache != null) {
      let allLink = [];
      let allLabel = [];
      refHeaderCache?.filter((x) => {
        if (x.Key === "Header") {
          setHeaderLogo({
            light: x?.BrandLogo?.ImageUrl,
            dark: x?.BrandLogoDarkTheme?.ImageUrl,
            countryFlag:
              x?.Components?.LanguageSelectorComponent?.LanguageSelector[0]
                ?.Flag?.ImageUrl,
          });
          x.Components["MyProfileNavigation"]?.NavigationItems?.map((item) => {
            allLink.push({
              Key: item?.Key,
              IsVisible: item?.IsVisible,
              Text: item?.Link?.Text,
              Url: item?.Link?.Url,
              AltText: item?.Link?.AltText,
              Target: item?.Link?.Target,
              Roles: item?.Roles,
            });
          });
          x.Components["MainMenu"]?.NavigationItems?.map((item) => {
            allLink.push({
              Key: item?.Key,
              IsVisible: item?.IsVisible,
              Text: item?.Link?.Text,
              Url: item?.Link?.Url,
              AltText: item?.Link?.AltText,
              Target: item?.Link?.Target,
              Roles: item?.Roles,
            });
          });
          x.Components["TopNavigation"]?.NavigationItems?.map((item) => {
            allLink.push({
              Key: item?.Key,
              IsVisible: item?.IsVisible,
              Text: item?.Link?.Text,
              Url: item?.Link?.Url,
              AltText: item?.Link?.AltText,
              Target: item?.Link?.Target,
              Roles: item?.Roles,
            });
          });

          let lanComponent = x.Components["LanguageSelectorComponent"];
          let isLanguageAvailable = JSON.parse(
            window.sessionStorage.getItem("Language")
          );
          let arrLanguage = [];
          lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map((item) => {
            arrLanguage.push({
              name: item?.LanguageName,
              value: item?.ISOCode,
            });
          });
          let retArr = arrLanguage?.filter((item) => {
            return item.value !== isLanguageAvailable?.selectedLanguageISOCode;
          });
          let retArr2 = arrLanguage?.filter((item) => {
            return item.value === isLanguageAvailable?.selectedLanguageISOCode;
          });
          retArr.unshift({
            name: retArr2[0]?.name,
            value: retArr2[0]?.value,
          });
          refLanguageSets.selectedCountryFullName =
            isLanguageAvailable?.selectedCountryFullName;
          refLanguageSets.selectedCountryShortName =
            isLanguageAvailable?.selectedCountryShortName;
          refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
          refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
          refLanguageSets.languageList = retArr;

          allLabel = [
            ...x.Components["MyProfileNavigation"]?.Labels,
            ...x.Components["LanguageSelectorComponent"]?.LanguageSelector[0]
              ?.Labels,
            ...x.Components["MainMenu"]?.Labels,
            ...x.Components["TopNavigation"]?.Labels,
          ];
        }
      });
      setMasterNavigationLink(allLink);
      setMasterLabel(allLabel);
      setLanguageSets(refLanguageSets);
    } else {
      let payload = {
        PageName: "Header",
      };
      HeaderActions.headerLabels(payload).then(
        (res) => {
          if (res) {
            let allLink = [];
            let allLabel = [];
            res?.data?.filter((x) => {
              if (x.Key === "Header") {
                setHeaderLogo({
                  light: x?.BrandLogo?.ImageUrl,
                  dark: x?.BrandLogoDarkTheme?.ImageUrl,
                  countryFlag:
                    x?.Components?.LanguageSelectorComponent
                      ?.LanguageSelector[0]?.Flag?.ImageUrl,
                });
                x.Components["MyProfileNavigation"]?.NavigationItems?.map(
                  (item) => {
                    allLink.push({
                      Key: item?.Key,
                      IsVisible: item?.IsVisible,
                      Text: item?.Link?.Text,
                      Url: item?.Link?.Url,
                      AltText: item?.Link?.AltText,
                      Target: item?.Link?.Target,
                      Roles: item?.Roles,
                    });
                  }
                );
                x.Components["MainMenu"]?.NavigationItems?.map((item) => {
                  allLink.push({
                    Key: item?.Key,
                    IsVisible: item?.IsVisible,
                    Text: item?.Link?.Text,
                    Url: item?.Link?.Url,
                    AltText: item?.Link?.AltText,
                    Target: item?.Link?.Target,
                    Roles: item?.Roles,
                  });
                });
                x.Components["TopNavigation"]?.NavigationItems?.map((item) => {
                  allLink.push({
                    Key: item?.Key,
                    IsVisible: item?.IsVisible,
                    Text: item?.Link?.Text,
                    Url: item?.Link?.Url,
                    AltText: item?.Link?.AltText,
                    Target: item?.Link?.Target,
                    Roles: item?.Roles,
                  });
                });

                let lanComponent = x.Components["LanguageSelectorComponent"];
                let isLanguageAvailable = JSON.parse(
                  window.sessionStorage.getItem("Language")
                );
                let arrLanguage = [];
                lanComponent?.LanguageSelector[0]?.Supportedlanguage?.map(
                  (item) => {
                    arrLanguage.push({
                      name: item?.LanguageName,
                      value: item?.ISOCode,
                    });
                  }
                );
                let retArr = arrLanguage?.filter((item) => {
                  return (
                    item.value !== isLanguageAvailable?.selectedLanguageISOCode
                  );
                });
                let retArr2 = arrLanguage?.filter((item) => {
                  return (
                    item.value === isLanguageAvailable?.selectedLanguageISOCode
                  );
                });
                retArr.unshift({
                  name: retArr2[0]?.name,
                  value: retArr2[0]?.value,
                });
                refLanguageSets.selectedCountryFullName =
                  isLanguageAvailable?.selectedCountryFullName;
                refLanguageSets.selectedCountryShortName =
                  isLanguageAvailable?.selectedCountryShortName;
                refLanguageSets.selectedLanguageFullName = retArr2[0]?.name;
                refLanguageSets.selectedLanguageISOCode = retArr2[0]?.value;
                refLanguageSets.languageList = retArr;

                allLabel = [
                  ...x.Components["MyProfileNavigation"]?.Labels,
                  ...x.Components["LanguageSelectorComponent"]
                    ?.LanguageSelector[0]?.Labels,
                  ...x.Components["MainMenu"]?.Labels,
                  ...x.Components["TopNavigation"]?.Labels,
                ];
              }
            });
            setMasterNavigationLink(allLink);
            setMasterLabel(allLabel);
            setLanguageSets(refLanguageSets);
            window.sessionStorage.setItem(
              "headerCache",
              JSON.stringify(res?.data)
            );
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify(refLanguageSets)
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const themeChange = async (value) => {
    let refSetProfileDetails = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    const jsonData = {
      id: refSetProfileDetails?.id,
      firstName: refSetProfileDetails?.firstName,
      lastName: refSetProfileDetails?.lastName,
      preferredName: refSetProfileDetails?.preferredName,
      emailAddress: refSetProfileDetails?.emailAddress,
      secondaryEmailAddress: refSetProfileDetails?.secondaryEmailAddress,
      preferredContactNo: refSetProfileDetails?.preferredContactNo,
      preferredLanguageCode: refSetProfileDetails?.preferredLanguageCode,
      cityOfResidence: refSetProfileDetails?.cityOfResidence,
      timeZone: refSetProfileDetails?.timeZone,
      personaId: refSetProfileDetails?.personaId,
      personaName: refSetProfileDetails?.personaName,
      theme: value === "light" ? "t-light" : "t-dark",
    };
    if (refSetProfileDetails?.rightOfficeId !== undefined) {
      jsonData["rightOfficeId"] = refSetProfileDetails?.rightOfficeId;
      jsonData["rightOfficeName"] = refSetProfileDetails?.rightOfficeName;
    }
    await HeaderActions.SaveCandisdateProfile(sUserID, jsonData).then(
      (res) => {
        if (res.status === 204) {
          setTheme(value === "light" ? "t-light" : "t-dark");
          window.sessionStorage.setItem(
            "selectedtheme",
            value === "light" ? "t-light" : "t-dark"
          );
          context?.setTheme(value === "light" ? "t-light" : "t-dark");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const signOut = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    sessionStorage.clear();
    localStorage.clear();
  };

  const activeTab = () => {
    const activetabHash = document.location.hash.split("/");
    const activeTabName = activetabHash[1];
    return activeTabName;
  };

  const languageHandler = async (asd) => {
    let id = document.getElementById("select").value;
    if (id !== languageSets?.selectedLanguageISOCode) {
      setScreenLoader(true);
      let refSetProfileDetails = JSON.parse(
        window.sessionStorage.getItem("setProfileDetails")
      );
      const jsonData = {
        id: refSetProfileDetails?.id,
        firstName: refSetProfileDetails?.firstName,
        lastName: refSetProfileDetails?.lastName,
        preferredName: refSetProfileDetails?.preferredName,
        emailAddress: refSetProfileDetails?.emailAddress,
        secondaryEmailAddress: refSetProfileDetails?.secondaryEmailAddress,
        preferredContactNo: refSetProfileDetails?.preferredContactNo,
        languageCode: id,
        preferredLanguageCode: id,
        cityOfResidence: refSetProfileDetails?.cityOfResidence,
        timeZone: refSetProfileDetails?.timeZone,
        personaId: refSetProfileDetails?.personaId,
        personaName: refSetProfileDetails?.personaName,
      };
      if (refSetProfileDetails?.rightOfficeId !== undefined) {
        jsonData["rightOfficeId"] = refSetProfileDetails?.rightOfficeId;
        jsonData["rightOfficeName"] = refSetProfileDetails?.rightOfficeName;
      }
      await HeaderActions.SaveCandisdateProfile(sUserID, jsonData).then(
        (res) => {
          if (res.status === 204) {
            let seleLan = languageSets.languageList.filter(
              (item) => item.value === id
            );
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify({
                selectedCountryFullName: languageSets.selectedCountryFullName,
                selectedCountryShortName: languageSets.selectedCountryShortName,
                selectedLanguageFullName: seleLan[0]?.name,
                selectedLanguageISOCode: seleLan[0]?.value,
                languageList: languageSets?.languageList,
              })
            );

            sessionStorage.removeItem("headerCache");
            sessionStorage.removeItem("footerCache");
            document.documentElement.setAttribute(
              "lang",
              seleLan[0]?.value.split("-")[0]
            );
            context.setLanguageChanged(true);
            context.setLanguage({
              selectedCountryFullName: languageSets.selectedCountryFullName,
              selectedCountryShortName: languageSets.selectedCountryShortName,
              selectedLanguageFullName: seleLan[0]?.name,
              selectedLanguageISOCode: seleLan[0]?.value,
              languageList: languageSets?.languageList,
            });
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const fetchHeaderLabel = (key) => {
    let label = "";
    if (masterLabel?.length > 0) {
      masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };
  const fetchNavigationLink = (key) => {
    if (key === "DiscoverPage") {
      context.setFocusToResource(false);
    }
    let label = "";
    if (masterNavigationLink?.length > 0) {
      masterNavigationLink?.map((x) => {
        if (x.Key === key) {
          label = x.Url;
        }
      });
    }
    return label;
  };
  const fetchNavigationText = (key) => {
    let label = "";

    if (masterNavigationLink?.length > 0) {
      masterNavigationLink?.map((x) => {
        if (x.Key === key) {
          label = x.Text;
        }
      });
    }
    return label;
  };

  // Get system messages
  const getSystemNotifications = async () => {
    let locale = JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
    await HeaderActions.getSystemNotifications(
      locale.preferredLanguageCode || locale.languageCode
    )
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const GetSystemMesssage =
              res &&
              res?.data?.filter((x) => x.notificationType == "System Messages");
            setSystemNotificationData(GetSystemMesssage);
          } else if (res.status === 204) {
            setReadSystemMessageStatus(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get negotiate url
  // const getNegotiateUrl = async () => {
  //     try {
  //         let sUserID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))["candidateID"];
  //         if (sUserID != "") {
  //             const res = await HeaderActions.getNegotiateUrl(sUserID)
  //             if (res.status === 200) {
  //                 setNegotiateUrl(res?.data?.url)
  //             }
  //         }
  //     } catch (error) {
  //         console.log(error)
  //         return {}
  //     }
  // }

  // Signal R integration
  const createSignalRConnection = async () => {
    const apiBaseUrl = process.env.REACT_APP_SIGNAL_R_APP_URL;
    const options = {
      accessTokenFactory: () => window.sessionStorage.getItem("AccessToken"),
    };

    try {
      let connection = new HubConnectionBuilder()
        .withUrl(apiBaseUrl + "?userid=" + sUserID, options)
        .withAutomaticReconnect()
        .build();

      await connection.start().then((result) => {
        console.log("SignalR is now connected");
      });

      connection.on("sendMessage", (message) => {
        const systemMessages = message.filter(
          (message) => message.notificationType === "System Messages"
        );
        setSignalRMessage(systemMessages);

        const notificationSignalRMessages = message.filter(
          (message) => message.notificationType !== "System Messages"
        );
        context?.setNotificationSignalRMessages(notificationSignalRMessages);
      });
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div>
      <Cirrus.HeaderWithNavigation
        brandLogoDark={{
          altText: "Brand Name",
          src: headerLogo?.light,
          onClick: () => {
            navigate("/dashboard");
          },
        }}
        brandLogoLight={{
          altText: "Brand Name",
          src: headerLogo?.dark,
          onClick: () => {
            navigate("/dashboard");
          },
        }}
        languageSelector={{
          countryName: languageSets?.selectedCountryFullName,
          countryNameLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorYourCountry"
          ),
          flagImage: {
            altText: "Flag",
            src: headerLogo?.countryFlag,
          },
          label:
            languageSets?.selectedLanguageFullName +
            "(" +
            languageSets?.selectedCountryShortName +
            ")",
          languageSelect: {
            label: fetchHeaderLabel("Common.LanguageSelector.ChooseALanguage"),
            name: "siteLanguage",
            options: languageSets?.languageList,
            id: "select",
            defaultValue: languageSets?.selectedLanguageFullName,
          },
          onChange: languageHandler,
          popUpButtonLabel: fetchHeaderLabel(
            "Common.LanguageSelector.LanguageSelectorSaveButton"
          ),
          popUpTitle: fetchHeaderLabel(
            "Common.LanguageSelector.ChooseYourLanguagePreference"
          ),
        }}
        menuAccessibilityLabel="Toggle menu"
        myProfileNavigation={{
          links: [
            {
              children: fetchNavigationText("MyProfilePage"),
              onClick: () => {
                navigate(fetchNavigationLink("MyProfilePage"));
              },
              isVisible: true,
            },
            {
              children: fetchNavigationText("AboutMePage"),
              onClick: () => {
                navigate(fetchNavigationLink("AboutMePage"));
              },
              isVisible: featuresConfig?.isOnboardingQuestionsAvailable,
            },
            {
              children: fetchNavigationText("MyDocumentsPage"),
              onClick: () => {
                navigate(fetchNavigationLink("MyDocumentsPage"));
              },
              isVisible: true,
            },
            // {
            //     children: fetchNavigationText("Help"),
            //     onClick: () => { navigate(fetchNavigationLink("Help")) },
            // },
          ],
          signOut: {
            label: fetchHeaderLabel(
              "Header.MyProfileNavigation.MyProfileNavigationSignOut"
            ),
            onClick: () => signOut(),
          },
          label: fetchNavigationText("MyProfilePage"),
        }}
        navigationLinks={[
          {
            children: fetchNavigationText("DashboardPage"),
            onClick: () => {
              navigate(fetchNavigationLink("DashboardPage"));
            },
            active: activeTab() === "dashboard" ? true : false,
            isVisible: true,
          },
          {
            children: fetchNavigationText("DiscoverPage"),
            onClick: () => {
              navigate(fetchNavigationLink("DiscoverPage"));
            },
            active: activeTab() === "discover" ? true : false,
            isVisible: true,
            // isVisible: programDetails?.programClass == "Alumni" ? false : true
          },
          {
            children: fetchNavigationText("Events"),
            onClick: () => {
              navigate(fetchNavigationLink("Events"));
            },
            active: activeTab() === "events" ? true : false,
            isVisible:
              getFeaturesConfig("EventsPage") ||
              featuresConfig?.isOnDemandEventFeatureAvailable,
          },
          {
            children: fetchNavigationText("JobSearch.JobSearch"),
            onClick: () => {
              navigate(fetchNavigationLink("JobSearch.JobSearch"));
            },
            active: activeTab() === "jobsearchPage" ? true : false,
            isVisible:
              featuresConfig?.isJobSearchFeatureAvailable ||
              featuresConfig?.isJobSearchLightFeatureAvailable || featuresConfig?.isJobSearchMaxFeatureAvailable,
          },
          {
            children: fetchNavigationText("MyCoachPage"),
            onClick: () => {
              navigate(fetchNavigationLink("MyCoachPage"));
            },
            active: activeTab() === "mycoach" ? true : false,
            isVisible: bCoachingAvailable,
          },
        ]}
        notificationsDropdown={{
          emptyListText: fetchHeaderLabel(
            "Header.MainMenu.MainMenuNoNewNotifications"
          ),
          hasUnseen: notification?.some((noti) => noti.seen === false)
            ? true
            : false,
          markSeenAll: {
            label: fetchHeaderLabel("Header.MainMenu.MainMenuMarkAllAsRead"),
            onClick: () => makeAllSeen(),
          },
          title: fetchHeaderLabel("Header.MainMenu.MainMenuNotifications"),
          viewAll: {
            label:
              notification?.length >= 10 &&
              fetchHeaderLabel("Header.MainMenu.MainMenuViewAllNotifications"),
            onClick: () => viewAll(),
          },
          onLoad: noLoadMore &&
            notificationCount > notification?.length && {
              label: fetchHeaderLabel("Header.MainMenu.LoadMoreButton"),
              onClick: () => loadMore(),
            },
          notifications: notification,
        }}
        secondaryLinks={[
          // removed as per Dana's comment in ppt
          // {
          //     children: "Articles",
          //     onClick: () => { navigate("/article") },
          // },
          {
            children: fetchNavigationText("BookmarkedHome"),
            onClick: () => {
              navigate(fetchNavigationLink("BookmarkedHome"));
            },
            isVisible: true,
          },
          {
            children: fetchNavigationText("Calendar"),
            onClick: () => {
              navigate(fetchNavigationLink("Calendar"));
            },
            isVisible: secondaryMenuLink,
          },
        ]}
        themeSwitcher={{
          accessibilityTexts: {
            darkThemeButton: "Select dark theme",
            lightThemeButton: "Select light theme",
          },
          id: "storybook-theme",
          onThemeChange: (e) => themeChange(e.target.value),
          type: "secondary",
          activeTheme:
            theme === "t-light"
              ? Cirrus.ThemeTypes.Light
              : Cirrus.ThemeTypes.Dark,
        }}
      />

      <div className="dashboard_message">
        <DashboardAccordion
          systemNotificationData={systemNotificationData}
          onReadSystemMessages={onReadSystemMessages}
          signalRMessage={signalRMessage}
          readSystemMessageStatus={readSystemMessageStatus}
          systemNotificationFilterData={systemNotificationFilterData}
        />
      </div>
    </div>
  );
}

export default HeaderWithNav;
