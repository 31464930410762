//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect, useState, useContext } from "react";
import { Page, Container, PageHeading, AlertModal } from 'manpowergroup-cirrus';
import { useNavigate } from "react-router-dom";
import Header from '../../header/simpleHeader';
import { Context } from '../../../common/context/Context';
import Footer from "../../../common/components/footer";
import { OnBoardingActions } from "../OnBoardingActions";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";


const WelcomeOnboarding = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const [candidateName, setCandidateName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsContent, setCmsContent] = useState({
        thanksPageDescription: '',
        doThisLaterTitle: '',
        doThisLaterDescription: '',
        labels: []
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCMSContent();
        TrackActions.EventTrack(appInsights, "Profilecreation", { "CreationTime": new Date() })
        TrackActions.PageAction("Profilecreation", { "CreationTime": new Date() })
    }, [])

    const nextPageHandler = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        OnBoardingActions.CandidateOnboarding({
            "id": candidateID,
            "isregistrationcompleted": "false",
            "isonboardingcompleted": "true"
        }).then(res => navigate("/questions")).catch(err => console.error(err))
    }
    const handleSaveAndExit = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];

        OnBoardingActions.CandidateOnboarding({
            "id": candidateID,
            "isregistrationcompleted": "true",
            "isonboardingcompleted": "false"
        }).then(
            (res) => {
                OnBoardingActions.GetOnBoardingStatus(candidateID).then(
                    (res) => {
                        if (res.status === 200) {
                            TrackActions.EventTrack(appInsights, "Skipsonboarding", { "SkipTime": new Date() })
                            TrackActions.PageAction("Skipsonboarding", { "SkipTime": new Date() })
                            let resData = res.data;
                            window.sessionStorage.setItem("onBoardingStatus", JSON.stringify(resData))
                            navigate("/dashboard");
                        } else {
                            setScreenLoader(false);
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }

    const fetchCMSContent = async () => {
        setScreenLoader(true);
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let payload = {
            PageName: "OnboardingPages"
        };
        await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
            (res) => {
                if (res.status === 200) {
                    getCandidateProfile();
                    let allLabels = [];
                    res?.data?.Pages?.filter((x) => {
                        if (x.Key === "CandidateOnboardingWelcomePage") {
                            let thanksPageCompName = x.Components['CandidateOnboardingWelcomePage.OnboardingCandidateWelcome']
                            refCmsContent.thanksPageDescription = x.Description;
                            thanksPageCompName?.Labels?.map((lab) => {
                                allLabels.push(lab);
                            })
                            let doThisLaterCompName = x.Components['OnboardingPage.SavePopup']
                            refCmsContent.doThisLaterTitle = doThisLaterCompName.Title;
                            refCmsContent.doThisLaterDescription = doThisLaterCompName.Description;
                            doThisLaterCompName?.Labels?.map((lab) => {
                                allLabels.push(lab);
                            })
                            refCmsContent.labels = allLabels;
                        }
                    })
                    setCmsContent(refCmsContent);
                    setScreenLoader(false);
                    context.setCmsOnBoadingContent(res?.data);
                    window.sessionStorage.setItem("setCmsOnBoadingContent", JSON.stringify(res?.data))
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    }

    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let label = '';
        if (refCmsContent?.labels.length > 0) {
            refCmsContent.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const getCandidateProfile = async () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        await OnBoardingActions.GetcandidateProfile(candidateID).then(
            (res) => {
                if (res.status === 200) {
                    window.sessionStorage.setItem("setProfileDetails", JSON.stringify(res?.data))
                    if (res?.data?.preferredName !== '') {
                        setCandidateName(res?.data?.preferredName)
                    } else {
                        setCandidateName(res?.data?.firstName)
                    }
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
            }
        );

        await OnBoardingActions.GetOnBoardingStatus(candidateID).then(
            (obsResp) => {
                if (obsResp.status === 200) {
                    window.sessionStorage.setItem("onBoardingStatus", JSON.stringify(obsResp.data))
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    };

    let welcomeName = fetchLabel('CandidateOnboardingWelcomePage.OnboardingCandidateWelcome.Thanks');
    if (welcomeName) {
        welcomeName = welcomeName.replace("#NAME", candidateName);
    }

    if (screenLoader) {
        return (<div>
            <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>)
    }

    return (
        <>
            <Container
                isContentCentered
                isFullScreenOnMobile
                primaryButton={{
                    children: fetchLabel('CandidateOnboardingWelcomePage.OnboardingCandidateWelcome.ContinueButton'),
                    isFullWidth: true,
                    onClick: nextPageHandler,
                    size: 'Large',
                    type: 'button'
                }}
                secondaryButton={{
                    children: fetchLabel('CandidateOnboardingWelcomePage.OnboardingCandidateWelcome.DoThisLater'),
                    isFullWidth: true,
                    isSecondary: true,
                    onClick: () => setIsOpen(true),
                    size: 'Large',
                    type: 'button'
                }}
                className="welcome-on-board-screen"
            >
                <div>
                    <PageHeading
                        heading={welcomeName}
                        isSmall
                        subheading={cmsContent.thanksPageDescription}
                    />
                </div>
            </Container>

            {isOpen &&
                <AlertModal
                    closeIconButtonProps={{
                        icon: 'close',
                        isSecondary: true,
                        onClick: () => setIsOpen(false),
                        text: fetchLabel('OnboardingPage.SavePopu.Cancel'),
                        type: 'button'
                    }}
                    heading={cmsContent.doThisLaterTitle}
                    primaryButton={{
                        children: fetchLabel('OnboardingPage.SavePopup.SaveAndExit'),
                        onClick: handleSaveAndExit,
                        size: 'Large',
                        type: 'button'
                    }}
                    role="alertdialog"
                    subheading={cmsContent.doThisLaterDescription}
                />}
        </>
    );
};

export default WelcomeOnboarding;
