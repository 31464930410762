//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../../common/context/Context";
import { AssessmentActions } from "../AssessmentActions";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import { jsPDF } from "jspdf";
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import VandDpdfTemplate from "./pdfTemplate";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { coachActions } from "../../coach/coachActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

const VandDResultPage = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
  const [categories, setCategories] = useState([]);
  const [getResponse, setGetResponse] = useState([]);
  const [assessmentStatus, setAssessmentStatus] = useState();
  const [coachdata, setcoachdata] = useState();
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    getMyCoach();
  }, []);

  useEffect(() => {
    fetchCMSData();
    getAssessmentStatus();
    if (categories.length) {
      renderSavedAssessment();
    }
    window.scrollTo(0, 0);
    TrackActions.PageViewRecord(appInsights, "ValuesAndDriversResult");
    TrackActions.PageAction("ValuesAndDriversResult");
  }, [categories, context.language]);

  const getSavedAssessmentResponses = async () => {
    let assessmentId = context.cmsValuesAndDriversContent.internalAssessmentId;
    let res = await AssessmentActions.GetSavedResponseByAssessmentId(
      assessmentId
    );
    return res.data;
  };

  const fetchCMSData = () => {
    let resData = context.cmsValuesAndDriversContent;
    ActivityAction.SaveActivity(
      resData?.internalAssessmentId,
      "completedassesment",
      resData?.title
    );
    setCmsAssessmentContent(resData);
    setCategories(resData?.commonAnswer);
  };

  const fetchCommonLabel = (key) => {
    let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
    let label = "";
    if (refCmsAssessmentContent?.commonLabels) {
      refCmsAssessmentContent?.commonLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
    let label = "";
    if (refCmsAssessmentContent?.commonResourceLabels) {
      refCmsAssessmentContent?.commonResourceLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const renderSavedAssessment = async () => {
    let savedResponses = await getSavedAssessmentResponses();
    let cmsContent = context.cmsValuesAndDriversContent;
    let categorizedArr = [];
    if (savedResponses.length) {
      savedResponses?.map((savedResponse) => {
        let QuestionId = savedResponse.questionId;
        let AnswerId = savedResponse.answerValue;
        cmsContent?.assessmentQuestionSet.map((assessment) => {
          if (assessment.ID === QuestionId) {
            categories?.length &&
              categories?.map((category) => {
                if (category.AnswerId === AnswerId) {
                  categorizedArr.push({
                    assessmentID: cmsContent?.internalAssessmentId,
                    questionId: assessment.ID,
                    questionText: assessment.Title,
                    questionDescription: assessment.Description,
                    answerId: category.AnswerId,
                    answerText: category.AnswerText,
                  });
                }
              });
          }
        });
      });
      setGetResponse(categorizedArr);
    }
  };

  const downloadPDF = () => {
    let content = document.getElementById("assessment-result-wrapper");

    const pdf = new jsPDF({ orientation: "l", format: "a2", unit: "px" });

    const datetime = new Date();
    const year = datetime.getFullYear();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hours = datetime.getHours();
    const mins = datetime.getMinutes();
    const sec = datetime.getSeconds();
    const newDatetime =
      year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

    pdf.viewerPreferences(
      { FitWindow: true, PrintArea: "MediaBox", PrintClip: "MediaBox" },
      true
    );

    pdf.html(content, {
      margin: [100, 10, 120, 10],
      callback: (pdf) => {
        let pageCount = pdf.internal.getNumberOfPages();

        for (let i = 0; i < pageCount; i++) {
          pdf.setPage(i);

          let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
          pdf.setFontSize(18);

          //header background
          pdf.setFillColor(244, 244, 245);
          pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

          //header
          const header = `${PDFheaderLogo}`;
          pdf.addImage(header, "png", 20, 20, 60, 20);

          //line above footer
          pdf.setDrawColor(236, 236, 236);
          pdf.line(
            20,
            pdf.internal.pageSize.height - 90,
            pdf.internal.pageSize.width - 20,
            pdf.internal.pageSize.height - 90,
            "S"
          );

          // footer
          const footerLeft = `${PDFfooterLogo}`;
          const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`;
          pdf.text(
            footerRight,
            pdf.internal.pageSize.width - 300,
            pdf.internal.pageSize.height - 50
          );
          pdf.addImage(
            footerLeft,
            "png",
            20,
            pdf.internal.pageSize.height - 80,
            160,
            45
          );
        }

        pdf.save(
          context.cmsValuesAndDriversContent.title +
            "-" +
            newDatetime.toLocaleString() +
            ".pdf"
        );
      },
    });
  };

  const getAssessmentStatus = async () => {
    let response = await AssessmentActions.GetAssessmentStatusById(
      context.cmsValuesAndDriversContent.internalAssessmentId
    );
    setAssessmentStatus(response.data);
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus.learningResourceId,
                learningResourceStatus: assessmentStatus.learningResourceStatus,
                learningResourceType:
                  context.cmsValuesAndDriversContent?.resourceCategoryType?.ID,
                resourceTypeId:
                  context.cmsValuesAndDriversContent?.resourceType?.ID,
                isSaved: assessmentStatus.isSaved,
                isLiked: assessmentStatus.isLiked,
                lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: assessmentStatus.isSharedWithCoach,
                resultDocumentId: assessmentStatus.resultDocumentId,
              };
              AssessmentActions.SaveAssessmentStatus(
                context.cmsValuesAndDriversContent.resourceCategoryType?.ID,
                assessmentStatus.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setLoadSpinner(false);
                    getAssessmentStatus();
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            } else {
              setLoadSpinner(false);
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const shareResultWithCoach = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: context.cmsValuesAndDriversContent.title,
      assessmentId: context.cmsValuesAndDriversContent.internalAssessmentId,
      documentId: assessmentStatus.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus.learningResourceId,
            learningResourceStatus: assessmentStatus.learningResourceStatus,
            learningResourceType:
              context.cmsValuesAndDriversContent?.resourceCategoryType?.ID,
            resourceTypeId:
              context.cmsValuesAndDriversContent?.resourceType?.ID,
            isSaved: assessmentStatus.isSaved,
            isLiked: assessmentStatus.isLiked,
            lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
            isDownloaded: assessmentStatus.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStatus.resultDocumentId,
          };
          AssessmentActions.SaveAssessmentStatus(
            context.cmsValuesAndDriversContent.resourceCategoryType?.ID,
            assessmentStatus.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                setLoadSpinner(false);
                getAssessmentStatus();
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  return (
    <>
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <div className="assessment-page">
        <Container className="pt-4 pb-4">
          <Row className="justify-content-between">
            <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
              <Cirrus.Button
                isSecondary
                onClick={() =>
                  navigate("/" + pageOrgin.id + "/values-and-drivers/review")
                }
              >
                {fetchCommonLabel("CommonComponent.CommonBackButton")}
              </Cirrus.Button>
            </Col>
            <Col
              sm={12}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xs={{ order: 3 }}
              className="d-flex"
            >
              {/* <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>Progress {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div> */}
            </Col>
            <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
              <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
                {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
              </Cirrus.Button>
            </Col>
          </Row>
        </Container>
        <Container className="assessment-review-result-wrapper assessment-result-wrapper p-lg-5 p-3 mb-5">
          {loadSpinner && (
            <div className="loader">
              <Cirrus.Spinner size={50} />
            </div>
          )}
          <Row className="mb-5 pb-3 border-bottom">
            <Col lg={5}>
              <Cirrus.Header
                size="h4"
                className="mb-3 text-lg-start text-md-start text-center"
              >
                {cmsAssessmentContent?.resultPage?.Title}
              </Cirrus.Header>
            </Col>
            <Col lg={7} className="download-share-retake">
              <Cirrus.Button
                isSecondary
                className="me-2"
                onClick={() =>
                  downloadDocument(assessmentStatus.resultDocumentId)
                }
              >
                {fetchCommonResourceLabel(
                  "CommonResourceComponent.DownloadAsPDF"
                )}
              </Cirrus.Button>
              <Cirrus.Button
                isSecondary
                className="me-2"
                onClick={() => shareResultWithCoach()}
                disabled={coachdata ? false : true}
              >
                {fetchCommonResourceLabel(
                  "CommonResourceComponent.ShareResultsWithCoach"
                )}
              </Cirrus.Button>
              <Cirrus.Button
                isSecondary
                onClick={() =>
                  navigate("/" + pageOrgin.id + "/values-and-drivers")
                }
              >
                {fetchCommonLabel("CommonComponentCommonRetakeButton")}
              </Cirrus.Button>
            </Col>
          </Row>
          <Row className="mb-5 row-cols-5">
            {categories?.length &&
              categories?.map((category, categoryIndex) => {
                return (
                  <Col key={categoryIndex}>
                    <div className="column-wrapper">
                      <div className="column-title">{category.AnswerText}</div>
                      <div className="column-list" id={category.AnswerId}>
                        {getResponse?.map((res, resIndex) => {
                          if (res.answerId === category.AnswerId) {
                            return (
                              <div
                                className="column-item"
                                key={resIndex}
                                id={res.questionId}
                                assessmentid={getResponse.assessmentID}
                              >
                                <span>{res.questionText}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}

      {/* <div id='assessment-result-wrapper' className='assessment-review-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1' }}>
                <table cellPadding={10} cellSpacing={10} style={{ width: '100%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                    <tbody>
                        <tr>
                            {
                                categories?.length && categories?.map((category, categoryIndex) => {
                                    return (
                                        <>
                                            <td width={'20%'} key={categoryIndex} style={{ verticalAlign: 'top' }}>
                                                <table style={{ border: '0px', background: '#ECEFF5', width: '100%', borderRadius: '5px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ background: '#D9E2FF', color: '#1456FF', padding: '10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', textAlign: 'center' }}>
                                                                {category.AnswerText}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getResponse?.map((res, resIndex) => {
                                                                if (res.answerId === category.AnswerId) {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ padding: '5px 10px 5px 10px' }}>
                                                                                <span style={{ background: '#fff', padding: '5px 10px', display: 'inline-block', borderRadius: '4px' }}>
                                                                                    {res.questionText}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                    <span style={{ fontSize: '1px' }}>.</span>
                </table>
            </div> */}
    </>
  );
};

export default VandDResultPage;
