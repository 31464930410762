//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect, useState, useContext } from 'react';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import { Context } from '../../../common/context/Context';
import HeaderWithNavigation from '../../header/headerWithNavigation';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Footer from "../../../common/components/footer";
import 'bootstrap-icons/font/bootstrap-icons.css'
import { AssessmentActions } from '../AssessmentActions';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import RApdfTemplate from './pdfTemplate';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../../common/utility/LogAction';


function Review() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsMasterData, setCmsMasterData] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState({
        answeredQuestionID: [],
        totalQuestionID: [],
        questionLists: [],
        reviewPage: {},
    });

    const [buttonStatusAndEmptyMsg, setButtonStatusAndEmptyMsg] = useState({
        overAll: false,
        addAnother: true,
        emptyMsg: "You have'n answer any question"
    });
    const [assessmentStatus, setAssessmentStatus] = useState({});
    const [assessmentPercentage, setAssessmentPercentage] = useState(0);
    const appInsights = useAppInsightsContext();

    const userAgent = window.navigator.userAgent;
    let browser = '';
    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    useEffect(() => {
        getAssessmentWithDetails();
        window.scrollTo(0, 0)
        TrackActions.PageViewRecord(appInsights, "ReviewAccomplishmentReview")
        TrackActions.PageAction("ReviewAccomplishmentReview")
    }, [context.language])

    const getAssessmentWithDetails = async () => {
        let cmsQuestion = context.cmsRAchildPagesAndLabel;
        setScreenLoader(true);
        if (Object.keys(cmsQuestion).length !== 0) {
            let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));
            let refButtonStatusAndEmptyMsg = JSON.parse(JSON.stringify(buttonStatusAndEmptyMsg));
            await AssessmentActions.GetAssessmentStatusAndSavedResponseById(cmsQuestion?.masterCMS?.ID).then(res => {
                if (res.status === 200) {
                    const returnName = (key) => {
                        let name = '';
                        if (cmsQuestion?.nameArr) {
                            cmsQuestion?.nameArr.map((x) => {
                                if (x.key === key) {
                                    name = x.name
                                }
                            })
                        }
                        return name;
                    }
                    let arr = [];
                    setAssessmentStatus(res.data);
                    setCmsMasterData(cmsQuestion);

                    let savedResponses = res.data.learningResourceAssessmentResponseModels;
                    if (savedResponses.length > 0) {
                        let setCount = 1;
                        let filterSetCount = [];
                        savedResponses.map((answer) => {
                            let tempSetCount = parseInt(answer?.questionId?.split('~')[2])
                            if (!filterSetCount.includes(tempSetCount)) {
                                filterSetCount.push(tempSetCount);
                            }
                            refMasterQuestionAnswer.answeredQuestionID.push(answer.questionId);
                        })

                        if (filterSetCount.length > 1) {
                            filterSetCount.sort(function (a, b) {
                                return a - b;
                            });
                            if (filterSetCount[filterSetCount.length - 1] !== undefined) {
                                setCount = filterSetCount[filterSetCount.length - 1];
                            }
                        }

                        for (let i = 1; i <= setCount; i++) {
                            let createSet = [];
                            cmsQuestion?.masterCMS?.ChildPages?.map((assessment, index) => {
                                if (assessment.Key === "Assessments.ReviewingAccomplishments.AccomplishmentOverviewPage") {
                                    refMasterQuestionAnswer.reviewPage = assessment;
                                }
                                if (Object.keys(assessment?.Components).length !== 0) {
                                    let comp = assessment?.Components[Object.keys(assessment?.Components)[0]];
                                    refMasterQuestionAnswer.totalQuestionID.push(comp?.QuestionList[0]?.QuestionId + '~set~' + i);
                                    let listsOption = [];
                                    comp?.QuestionList[0]?.AnswerList.forEach(item => {
                                        listsOption.push({
                                            'isSelected': false,
                                            'answerId': item.AnswerId + '~set~' + i,
                                            'itemID': item.ItemID + '~set~' + i,
                                            'answerText': item.AnswerText
                                        })
                                    });
                                    if (comp?.QuestionList[0]?.QuestionTitle !== returnName("Resources.Assessments.ReviewingAccomplishments.AccomplishmentTitle")) {
                                        createSet.push({
                                            'questionId': comp?.QuestionList[0]?.QuestionId + '~set~' + i,
                                            'topTitle': '',
                                            'questionTitle': assessment?.Title,
                                            'description': assessment?.Description,
                                            'questionLabel': comp?.QuestionList[0]?.QuestionTitle,
                                            'questionPlaceholder': fetchLabel("CommonResourceComponent.EnterHere"),
                                            'fieldType': comp?.QuestionList[0]?.OptionType,
                                            'optionsAnswerList': listsOption,
                                            'textAnswerValue': '',
                                            'currentSet': 'set~' + i,
                                        });
                                    }
                                }
                            });
                            arr.push({
                                'accomplishmentTitle': '',
                                'questionAnswerList': createSet
                            })
                        }
                        let mappedQuestionAnswer = arr.map((question) => {
                            question.questionAnswerList.map((qList) => {
                                savedResponses.map((answer) => {
                                    let setNumber = 'set~' + answer?.questionId?.split('~')[2];
                                    if (qList.currentSet === setNumber) {
                                        if (answer.categoryValue === returnName("Assessments.ReviewingAccomplishments.AccomplishmentTitlePage")) {
                                            question.accomplishmentTitle = answer.answerValue;
                                        }
                                    }
                                    if (qList.questionId === answer.questionId) {
                                        if (qList.questionTitle === returnName("Assessments.ReviewingAccomplishments.SkillsPage")) {
                                            let spltValue = answer?.answerValue?.split(",");
                                            qList?.optionsAnswerList?.map((opt) => {
                                                let as = spltValue.filter((spltOpt) => spltOpt === opt.answerId)
                                                if (as.length > 0) {
                                                    opt.isSelected = true;
                                                } else {
                                                    opt.isSelected = false;
                                                }
                                                return opt;
                                            })
                                        } else {
                                            qList.textAnswerValue = answer.answerValue;
                                        }
                                    }
                                })
                                return qList;
                            })
                            return question;
                        })

                        refMasterQuestionAnswer.questionLists = mappedQuestionAnswer;
                        setMasterQuestionAnswer(refMasterQuestionAnswer);

                        refButtonStatusAndEmptyMsg.overAll = true;
                        setButtonStatusAndEmptyMsg(refButtonStatusAndEmptyMsg);
                        setAssessmentPercentage(Math.ceil((refMasterQuestionAnswer?.answeredQuestionID.length / refMasterQuestionAnswer?.totalQuestionID.length) * 100))
                        setScreenLoader(false);
                    } else {
                        cmsQuestion?.masterCMS?.ChildPages?.map((assessment) => {
                            if (assessment.Key === "Assessments.ReviewingAccomplishments.AccomplishmentOverviewPage") {
                                refMasterQuestionAnswer.reviewPage = assessment;
                            }
                        })
                        setMasterQuestionAnswer(refMasterQuestionAnswer);
                        setScreenLoader(false);
                    }
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                setScreenLoader(false);
                console.log(err);
            });
        }
    }

    const completeAssessmentHandler = async (docId) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let statusPayload = {
            "candidateId": candidateID,
            "learningResourceId": cmsMasterData?.masterCMS?.ID,
            "learningResourceType": cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
            "resourceTypeId": cmsMasterData?.masterCMS?.ResourceType?.ID,
            "learningResourceStatus": 'completed',
            "isRetaken": false,
            "lastAnsweredPageId": assessmentStatus?.lastAnsweredPageId,
            "isSaved": assessmentStatus?.isSaved,
            "isLiked": assessmentStatus?.isLiked,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": docId
        }
        await AssessmentActions.SaveAssessmentStatus(cmsMasterData?.masterCMS?.ResourceCategoryType?.ID, cmsMasterData?.masterCMS?.ID, statusPayload).then((res) => {
            if (res.status === 200) {
                navigate("/" + pageOrgin.id + "/reviewing-accomplishments/result")
            }
        }, err => {
            console.log(err);
        })
    }

    const fetchLabel = (key) => {
        let refCmsMasterData = JSON.parse(JSON.stringify(cmsMasterData));
        let label = '';
        if (refCmsMasterData?.masterLabel) {
            refCmsMasterData?.masterLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const downloadPDF = () => {
        let content = document.getElementById('assessment-result-wrapper');
        setScreenLoader(true);

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;

            const pdf = new jsPDF({ orientation: 'l', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', 20, yPosition + 60, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                let file = new File([pdf.output('blob')], context.cmsRAchildPagesAndLabel.masterCMS.Title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");

                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    let docId;
                    res.data.map(doc => {
                        docId = doc.id
                    })
                    completeAssessmentHandler(docId)
                    setScreenLoader(false);
                }, err => {
                    console.log(err);
                    setScreenLoader(false);
                });
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();

                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }

                        let file = new File([pdf.output('blob')], context.cmsRAchildPagesAndLabel.masterCMS.Title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");

                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            let docId;
                            res.data.map(doc => {
                                docId = doc.id
                            })
                            completeAssessmentHandler(docId)
                            setScreenLoader(false);
                        }, err => {
                            console.log(err);
                            setScreenLoader(false);
                        });
                    }
                })
            }
        })
    }

    const returnName2 = (key) => {
        let refCmsRA = JSON.parse(JSON.stringify(cmsMasterData));
        let name = '';
        if (refCmsRA?.nameArr) {
            refCmsRA?.nameArr.map((x) => {
                if (x.key === key) {
                    name = x.name
                }
            })
        }
        return name;
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page">
                <Container className='pt-4 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button isSecondary onClick={() => navigate("/" + pageOrgin.id + "/reviewing-accomplishments/questions" + "/addAnother=false")}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {assessmentPercentage}%</Cirrus.Label>
                                <ProgressBar now={assessmentPercentage} label={`${assessmentPercentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end exit">
                            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-review-result-wrapper assessment-result-wrapper px-4 py-5 p-md-5 mb-5'>
                    <Row className='mb-4'>
                        <Col lg={8} className="m-auto text-start">
                            <Cirrus.Header size='h5' className='mb-4'>{masterQuestionAnswer?.reviewPage?.Title}</Cirrus.Header>
                            {
                                masterQuestionAnswer?.questionLists?.length > 0 ? <>
                                    {
                                        masterQuestionAnswer?.questionLists?.map((set) => {
                                            return (<div className='border-bottom mb-4'>
                                                <Cirrus.Headline size='Small' className='mb-3 text-start'>
                                                    {set?.accomplishmentTitle}
                                                </Cirrus.Headline>
                                                {
                                                    set?.questionAnswerList?.map((qa) => {
                                                        return (
                                                            <>
                                                                <div className='mb-3'>
                                                                    <label className='ra-result-titles'>{qa?.questionLabel}</label>
                                                                    {
                                                                        qa?.questionLabel === returnName2("Assessments.ReviewingAccomplishments.SkillsPage") ? <ul className="optionAnsUl">
                                                                            {
                                                                                qa?.optionsAnswerList?.map((op) => {
                                                                                    if (op?.isSelected) {
                                                                                        return (
                                                                                            <li>{op?.answerText}</li>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul> : <p> {qa?.textAnswerValue} </p>
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>)
                                        })
                                    }
                                </> : <>
                                    <Cirrus.Label>
                                        {buttonStatusAndEmptyMsg.emptyMsg}
                                    </Cirrus.Label>
                                </>
                            }
                        </Col>
                    </Row>
                    {
                        buttonStatusAndEmptyMsg.overAll && <Row>
                            <Col lg={8} className='m-auto text-start'>
                                <Cirrus.Button
                                    isSecondary
                                    className='me-2 mb-lg-0 mb-md-0 mb-3 review-accom_button'
                                    onClick={(e) => {
                                        navigate("/" + pageOrgin.id + "/reviewing-accomplishments/questions" + "/addAnother=true")
                                    }}
                                >
                                    {fetchLabel("CommonResourceComponent.AddAnotherAccomplishment")}
                                </Cirrus.Button>
                                <Cirrus.Button onClick={downloadPDF}>{fetchLabel("CommonComponent.CommonCompleteButton")}</Cirrus.Button>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
            <div style={{ opacity: "0" }}>
                <div id='assessment-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1', display: 'block', width: '1300px' }}>
                    <RApdfTemplate masterQuestionAnswer={masterQuestionAnswer} cms={cmsMasterData.nameArr}></RApdfTemplate>
                </div>
            </div>
        </>
    )
}

export default Review
