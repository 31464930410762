//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from "../../common/helpers/HttpHelper";
import environments from "../../common/environments/environment";
const http = new httpHelper();

export const BookMarkActions = {
  GetCMSBookmarkheaderLabels: GetCMSBookmarkheaderLabels,
  GetCMSBookMarkedCards: GetCMSBookMarkedCards,
  PostSaveJob: postSaveJob,
  GetJobSavedStatus: getJobSavedStatus,
  GetAppliedJobs: getAppliedJobs,
  AppliedJob: appliedJob,
  GetBookmarkedPage: getBookmarkedPage,
  GetResourceData: getResourceData,
  SaveResourceCard:saveResourceCard,
  GetEventsData: getEventsData,
  SaveEventCard: saveEventCard,
  GetIviewToolData: getIviewToolData,
  SaveIviewToolCollectionCard: saveIviewToolCollectionCard,
  SaveIviewToolCard: saveIviewToolCard,
  GetResmeBuilderUrl: getResmeBuilderUrl,
  ExternalToolsRedirectURL: externalToolsRedirectURL
};

function GetCMSBookmarkheaderLabels(payload) {
  return http.CMSpost(environments.GET_BOOKMARK_LABELS, payload);
}
function GetCMSBookMarkedCards(payload) {
  return http.CMSpost(environments.GET_BOOKMARK_CMS, payload);
}
function getJobSavedStatus(id) {
  let url = environments.GET_JOB_SAVED_STATUS.replace('{CandidateId}', id);
  return http.jobGet(url);
}
function postSaveJob(id, payload) {
  let url = environments.CANDIDATE_SAVED_JOB.replace('{candidateId}', id);
  return http.jobPost(url, payload);
}
function getAppliedJobs() {
  return http.jobGet(environments.GET_APPLIED_JOBS);
}
function appliedJob(id, payload) {
  let repCanID = environments.CANDIDATE_APPLIED_JOB.replace('{candidateId}', id);
  return http.jobPost(repCanID, payload);
}
function getBookmarkedPage() {
  let url = environments.GET_BOOKMARKED_PAGE;
  return http.learningResourceGet(url);
}

function getResourceData(payload) {
  let url = environments.ASSESSMENT_CARD_DATA
  return http.learningResourcePost(url, payload);
}

function saveResourceCard(resourceType, id, payload) {
  let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
  return http.learningResourcePut(url, payload);
}

function getEventsData(payload) {
  return http.GetEvents(environments.GET_EVENTS, payload);
}

function saveEventCard(payload, id) {
  let url = environments.UPDATE_EVENT.replace('{id}', id);
  return http.updateEvents(url, payload);
}

function getIviewToolData(payload) {
  return http.IviewVideoGet(environments.IVIEW_ON_DEMAND_VIDEO_INTERVIEW, payload);
}

function saveIviewToolCollectionCard(payload) {
  return http.IviewVideoPost(environments.SAVE_IVIEW_COLLECTION, payload);
}

function saveIviewToolCard(assessmentId, payload) {
  return http.IviewVideoPost(
    environments.SAVE_IVIEW_ASSESMENT_CARD.replace("{id}", assessmentId),
    payload
  );
}

function getResmeBuilderUrl() {
  return http.GetResumeBuilderUrl(environments.GET_RESUME_BUILDER_URL);
}

function externalToolsRedirectURL(id) {
  return http.AssessmentsGet(environments.GET_EXTERNAL_TOOLS_REDIRECT_URL.replace("{toolId}", id));
}
