import React, { useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../common/context/Context";
{
  /* isPopUpVisible - Share results with Coach - confirmation message  */
}

const ConfirmationPopup = () => {
  const context = useContext(Context);
  return (
    <div>
      {context.isPopUpVisible?.status && (
        <Cirrus.AlertModal
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () =>
              context.setIsPopUpVisible({
                status: false,
              }),
            text: "Cancel",
            type: "button",
          }}
          // heading=""
          primaryButton={{
            children: context.isPopUpVisible.closeText,
            onClick: () =>
              context.setIsPopUpVisible({
                status: false,
              }),
            size: "Large",
            type: "button",
          }}
          role="alertdialog"
          subheading={context.isPopUpVisible.message}
        />
      )}
    </div>
  );
};

export default ConfirmationPopup;
