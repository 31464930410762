//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';
import { Row } from "react-bootstrap";

const TPpdfTemplate = ({ questionsSet, answersList, cmsAssessmentContent, cmsTitle }) => {

    const fetchAnswersList = (answers, questionId) => {
        let answer = '';
        let answerSet = answers?.filter((answer => answer.questionId === questionId))
        answer = answerSet && answerSet[0] && answerSet[0].answerValue && answerSet[0].answerValue;
        return answer;
    }

    return (
        <>
            <table cellPadding={10} cellSpacing={10} style={{ width: '90%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div className='question-answer-wrapper'>
                                <Row className='mb-3 text-start'>
                                    <div className='section-heading'>
                                        <Cirrus.Label>
                                            {cmsTitle && cmsTitle.questionsList && cmsTitle.questionsList[0] && cmsTitle.questionsList[0] && cmsTitle.questionsList[0].Title}
                                        </Cirrus.Label>
                                    </div>
                                    {questionsSet?.futureQuestionsSet.map((question => {
                                        return (
                                            <div>
                                                <ul>
                                                    <li size='Large' className='mb-3 text-start question-title'>
                                                        {question?.QuestionTitle}
                                                    </li>
                                                </ul>
                                                <div className='answers-row'>
                                                    <Cirrus.Title>{fetchAnswersList(answersList && answersList, question?.QuestionId)}</Cirrus.Title>
                                                </div>
                                            </div>
                                        )
                                    }
                                    ))}
                                </Row>
                                <Row className='mb-3 text-start'>
                                    <div className='section-heading'>
                                        <Cirrus.Label>
                                            {cmsTitle && cmsTitle.questionsList && cmsTitle.questionsList[2] && cmsTitle.questionsList[2] && cmsTitle.questionsList[2].Title}
                                        </Cirrus.Label>
                                    </div>
                                    {questionsSet?.seeYourselfQuestionsSet.map((question => {
                                        return (
                                            <div>
                                                <ul>
                                                    <li size='Large' className='mb-3 text-start question-title'>
                                                        {question?.QuestionTitle}
                                                    </li>
                                                </ul>
                                                <div className='answers-row'>
                                                    <Cirrus.Title>{fetchAnswersList(answersList && answersList, question?.QuestionId)}</Cirrus.Title>
                                                </div>
                                            </div>
                                        )
                                    }
                                    ))}
                                </Row>
                                <Row className='mb-3 text-start'>
                                    <div className='section-heading'>
                                        <Cirrus.Label>
                                            {cmsTitle && cmsTitle.questionsList && cmsTitle.questionsList[4] && cmsTitle.questionsList[4] && cmsTitle.questionsList[4].Title}
                                        </Cirrus.Label>
                                    </div>
                                    {questionsSet?.othersSeeQuestionsSet.map((question => {
                                        return (
                                            <div>
                                                <ul>
                                                    <li size='Large' className='mb-3 text-start question-title'>
                                                        {question?.QuestionTitle}
                                                    </li>
                                                </ul>
                                                <div className='answers-row'>
                                                    <Cirrus.Title>{fetchAnswersList(answersList && answersList, question?.QuestionId)}</Cirrus.Title>
                                                </div>
                                            </div>
                                        )
                                    }
                                    ))}
                                </Row>
                                <Row className='mb-3 text-start'>
                                    <div className='section-heading'>
                                        <Cirrus.Label>
                                            {cmsTitle && cmsTitle.questionsList && cmsTitle.questionsList[6] && cmsTitle.questionsList[6] && cmsTitle.questionsList[6].Title}
                                        </Cirrus.Label>
                                    </div>
                                    {questionsSet?.importantQuestionsSet.map((question => {
                                        return (
                                            <div>
                                                <ul>
                                                    <li size='Large' className='mb-3 text-start question-title'>
                                                        {question?.QuestionTitle}
                                                    </li>
                                                </ul>
                                                <div className='answers-row'>
                                                    <Cirrus.Title>{fetchAnswersList(answersList && answersList, question?.QuestionId)}</Cirrus.Title>
                                                </div>
                                            </div>
                                        )
                                    }
                                    ))}
                                </Row>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <span style={{ fontSize: '1px' }}>.</span>
            </table>
        </>
    )
}

export default TPpdfTemplate;
