//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Spinner from "../../common/components/spinner-loader/Spinner";
import { Context } from "../../common/context/Context";
import FullCalender from "../../common/components/calender/Calender";
import { CandidateCalenderActions } from "./CandidateCalenderAction";
import CandidateCalenderEventDetails from "./CandidateCalenderEventDetails";
import MeetingScheduleDialog from "../coach/MeetingScheduleDialog";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { UTCtoLocalDateTimeOnlyForCalendar } from "../../common/helpers/DateTimeHelper";

function CandidateCalender() {
  const calenderContext = useContext(Context);
  const userLang = navigator.language || navigator.userLanguage || "en";
  const sInitialLocale = userLang.split("-")[0];

  const [screenLoader, setScreenLoader] = useState(false);
  const [sEventsData, setsEventData] = useState([]);
  const [sInitialDate, setsInitialDate] = useState(new Date());
  const [sInitialView, setInitialView] = useState("dayGridMonth");

  const [displayEventType, setdisplayEventType] = useState(false);
  const [displayEventDlg, setdisplayEventDlg] = useState(false);
  const [displayMeetingSchedule, setdisplayMeetingSchedule] = useState(false);
  const [coachSessionReload, setcoachSessionReload] = useState(0);
  const [mycoachData, setMycoachData] = useState({});
  const [mycoachStatus, setmycoachStatus] = useState();
  const [cmsCalendarPageTemplate, setCmsCalendarPageTemplate] = useState({
    title: "",
    description: "",
    labels: [],
    EventDetails_Title: "",
    EventDetails_Lables: [],
    meetingSchedule_Title: "",
    meetingSchedule_Labels: [],
    meetingSchedule_Duration: [],
    CalendarEventType_Labels: [],
  });
  const [cmsMainContent, setCmsMainContent] = useState({
    title: "",
    description: "",
  });
  const [calenderReload, setcalenderReload] = useState(0);
  const appInsights = useAppInsightsContext();

  const closePopupWindow = () => {
    setdisplayEventType(false);
  };

  const fnFetchDataFromCMS = async () => {
    try {
      let cmscalenderpayload = {
        PageName: "Calendar",
      };

      const res = await CandidateCalenderActions.GetCMSForCandidateCalender(
        cmscalenderpayload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fnLoadCSMData = async () => {
    let objCMSData = fnFetchDataFromCMS();
    objCMSData.then((resData) => {
      if (Object.keys(resData).length > 0) {
        let refcmsCalendarPageTemplate = JSON.parse(
          JSON.stringify(cmsCalendarPageTemplate)
        );
        setScreenLoader(true);
        resData.Pages?.filter((x) => {
          if (x.Key === "Calendar") {
            let compName = x.Components["CalendarPage.CalendarPageTemplate"];
            refcmsCalendarPageTemplate.title = compName.Title;
            refcmsCalendarPageTemplate.description = compName.Description;
            refcmsCalendarPageTemplate.labels = compName.Labels;

            let EventcomName = x.Components["CalendarPage.ViewEventModal"];
            refcmsCalendarPageTemplate.EventDetails_Title = EventcomName.Title;
            refcmsCalendarPageTemplate.EventDetails_Lables =
              EventcomName.Labels;

            // CMS for Schedule a meeting
            refcmsCalendarPageTemplate.meetingSchedule_Title =
              x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Title;

            refcmsCalendarPageTemplate.meetingSchedule_Labels =
              x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

            refcmsCalendarPageTemplate.meetingSchedule_Labels =
              x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

            refcmsCalendarPageTemplate.CalendarEventType_Labels =
              x.Components["CalendarPage.CalendarEventType"]?.Labels;

            refcmsCalendarPageTemplate.CommonLabels =
              x.Components["CommonComponent"]?.Labels;
          }
        });
        setCmsCalendarPageTemplate(refcmsCalendarPageTemplate);
        window.sessionStorage.setItem(
          "CalendarCMS",
          JSON.stringify(refcmsCalendarPageTemplate)
        );

        let refCmsMainContent = JSON.parse(JSON.stringify(cmsMainContent));
        resData.Pages?.filter((x) => {
          if (x.Key === "Calendar") {
            refCmsMainContent.title = x?.Title;
            refCmsMainContent.description = x?.Description;
          }
        });
        setCmsMainContent(refCmsMainContent);
        setScreenLoader(false);
      }
    });
  };

  const fnFetchCalenderEventsfromDB = async (sStartDate, sEndtDate) => {
    try {
      let calEventpayload = {
        startDate: sStartDate?.replace(/[^0-9-]/g, ""),
        endDate: sEndtDate?.replace(/[^0-9-]/g, ""),
      };

      const res = await CandidateCalenderActions.GetCalenderEvents(
        calEventpayload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fnLoadCalenderEvents = async (sStartDate, sEndtDate) => {
    let objCMSData = fnFetchCalenderEventsfromDB(sStartDate, sEndtDate);
    let eventType;
    objCMSData.then((resData) => {
      if (resData.length > 0) {
        let arrData = [];
        resData.map((event) => {
          eventType = event?.location.includes("docebosaas") ? false : true;
          arrData.push({
            title: event.title,
            start: UTCtoLocalDateTimeOnlyForCalendar(event.startTimeStamp),
            end: UTCtoLocalDateTimeOnlyForCalendar(event.endTimeStamp),
            description: event.title,
            location: event.location,
            id: event.id,
            myCoach: event.senderEmailAddress,
            className: eventType == true ? "myMeetings" : "myEvents",
            meetingId: event.meetingId,
            duration: event.duration,
          });
        });
        setsEventData(arrData);
        setScreenLoader(false);
      } else {
        setsEventData([]);
        setScreenLoader(false);
      }
    });
  };

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 0);

    return new Date(date.setDate(diff));
  }

  useEffect(() => {
    setScreenLoader(true);
    window.scrollTo(0, 0);
    fnLoadCSMData();
    let calendarApi = calendarRef.current.getApi();
    let dtToday = new Date(calendarApi.getDate());
    let sStartDate =
      dtToday.getFullYear() +
      "-" +
      ("0" + (dtToday.getMonth() + 1)).slice(-2) +
      "-" +
      "01";
    let sEndDate =
      dtToday.getFullYear() +
      "-" +
      ("0" + (dtToday.getMonth() + 1)).slice(-2) +
      "-" +
      daysInMonth(dtToday.getMonth() + 1, dtToday.getFullYear());
    fnLoadCalenderEvents(sStartDate, sEndDate);
    setdisplayEventDlg(false);
  }, [calenderReload]);

  useEffect(() => {
    getMyCoach();
  }, []);

  useEffect(() => {
    TrackActions.PageViewRecord(appInsights, "MyCalendar");
    TrackActions.PageAction("MyCalendar");
  }, []);

  const calendarRef = useRef();

  const fetchCMSLabel = (key, ssection) => {
    let label = "";
    if (ssection != undefined) {
      let refCmsContent = ssection;
      if (refCmsContent) {
        refCmsContent.map((x) => {
          if (x.Key === key) {
            label = x.Phrase;
          }
        });
      }
    }
    return label;
  };

  const fetchCommonLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsCalendarPageTemplate));
    let label = "";
    if (refCmsContent?.CommonLabels?.length > 0) {
      refCmsContent?.CommonLabels?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchLabel = (key, section) => {
    let refCmsContent = JSON.parse(
      JSON.stringify(cmsCalendarPageTemplate?.meetingSchedule_Labels)
    );
    let label = "";
    if (refCmsContent?.length > 0) {
      refCmsContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchEventTypeLabel = (key, section) => {
    let refCmsContent = JSON.parse(
      JSON.stringify(cmsCalendarPageTemplate?.CalendarEventType_Labels)
    );
    let label = "";
    if (refCmsContent?.length > 0) {
      refCmsContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  // Get my coach data
  const getMyCoach = async () => {
    await CandidateCalenderActions.GetMyCoach()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setMycoachData(res.data && res.data);
            setmycoachStatus(res.status);
            window.sessionStorage.setItem(
              "CoachDetails",
              JSON.stringify(res.data)
            );
          } else {
            setmycoachStatus(204);
            setMycoachData({});
          }
          setScreenLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setmycoachStatus(400);
        setMycoachData({});
        setScreenLoader(false);
      });
  };

  const fnCallNext = () => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi.view.type === "dayGridMonth") {
      setScreenLoader(true);
      let dtToday = new Date(calendarApi.view.currentEnd);
      let sStartDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        "01";
      let sEndDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        daysInMonth(dtToday.getMonth() + 1, dtToday.getFullYear());
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtToday.getFullYear() +
          "-" +
          ("0" + (dtToday.getMonth() + 1)).slice(-2) +
          "-" +
          "01"
      );
      setInitialView("dayGridMonth");
    } else if (calendarApi.view.type === "timeGridWeek") {
      setScreenLoader(true);
      let dtToday = new Date(calendarApi.view.currentEnd);
      let dtEndDate = new Date(dtToday);
      dtEndDate.setDate(dtEndDate.getDate() + 6);
      let sStartDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtToday.getDate()).slice(-2);
      let sEndDate =
        dtEndDate.getFullYear() +
        "-" +
        ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtEndDate.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtToday.getFullYear() +
          "-" +
          ("0" + (dtToday.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtToday.getDate()).slice(-2)
      );
      setInitialView("timeGridWeek");
    } else if (calendarApi.view.type === "timeGridDay") {
      setScreenLoader(true);
      let dtEndDate = new Date(calendarApi.view.currentStart);
      dtEndDate.setDate(dtEndDate.getDate() + 1);
      let sStartDate =
        dtEndDate.getFullYear() +
        "-" +
        ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtEndDate.getDate()).slice(-2);
      let sEndDate =
        dtEndDate.getFullYear() +
        "-" +
        ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtEndDate.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(sEndDate);
      setInitialView("timeGridDay");
    }
  };

  const fnCallPrev = () => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi.view.type === "dayGridMonth") {
      setScreenLoader(true);
      let dtToday = new Date(calendarApi.view.currentEnd);
      dtToday.setMonth(dtToday.getMonth() - 1);

      let getMonth =
        ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2) == "00"
          ? "12"
          : ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2) >
            "00"
          ? ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2)
          : ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2);

      let getYear =
        ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2) == "00"
          ? dtToday.getFullYear() - 1
          : ("0" + dtToday.getMonth().toString().replace("-", "")).slice(-2) >
            "00"
          ? dtToday.getFullYear()
          : "";

      let sStartDate = getYear + "-" + getMonth + "-" + "01";
      let sEndDate =
        getYear +
        "-" +
        getMonth +
        "-" +
        daysInMonth(dtToday.getMonth(), dtToday.getFullYear());

      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(getYear + "-" + getMonth + "-" + "01");
      setInitialView("dayGridMonth");
    } else if (calendarApi.view.type === "timeGridWeek") {
      setScreenLoader(true);
      let dtStart = new Date(calendarApi.view.currentStart);
      let dtEndDate = new Date(dtStart);
      dtStart.setDate(dtStart.getDate() - 1);
      dtEndDate.setDate(dtEndDate.getDate() - 7);
      let sStartDate =
        dtEndDate.getFullYear() +
        "-" +
        ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtEndDate.getDate()).slice(-2);
      let sEndDate =
        dtStart.getFullYear() +
        "-" +
        ("0" + (dtStart.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtStart.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtEndDate.getFullYear() +
          "-" +
          ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtEndDate.getDate()).slice(-2)
      );
      setInitialView("timeGridWeek");
    } else if (calendarApi.view.type === "timeGridDay") {
      setScreenLoader(true);
      let dtStart = new Date(calendarApi.view.currentEnd);
      dtStart.setDate(dtStart.getDate() - 2);
      let sStartDate =
        dtStart.getFullYear() +
        "-" +
        ("0" + (dtStart.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtStart.getDate()).slice(-2);
      let sEndDate =
        dtStart.getFullYear() +
        "-" +
        ("0" + (dtStart.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtStart.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtStart.getFullYear() +
          "-" +
          ("0" + (dtStart.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtStart.getDate()).slice(-2)
      );
      setInitialView("timeGridDay");
    }
  };

  const fnCallToday = () => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi.view.type === "dayGridMonth") {
      setScreenLoader(true);
      let dtToday = new Date();
      let sStartDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        "01";
      let sEndDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        daysInMonth(dtToday.getMonth() - 1, dtToday.getFullYear());
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtToday.getFullYear() +
          "-" +
          ("0" + (dtToday.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtToday.getDate()).slice(-2)
      );
      setInitialView("dayGridMonth");
    } else if (calendarApi.view.type === "timeGridWeek") {
      setScreenLoader(true);
      let dtToday = new Date();
      let dtEndDate = new Date(startOfWeek(dtToday));
      dtEndDate.setDate(dtEndDate.getDate() + 6);
      let sStartDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtToday.getDate()).slice(-2);
      let sEndDate =
        dtEndDate.getFullYear() +
        "-" +
        ("0" + (dtEndDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtEndDate.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtToday.getFullYear() +
          "-" +
          ("0" + (dtToday.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtToday.getDate()).slice(-2)
      );
      setInitialView("timeGridWeek");
    } else if (calendarApi.view.type === "timeGridDay") {
      setScreenLoader(true);
      let dtToday = new Date();
      let sStartDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtToday.getDate()).slice(-2);
      let sEndDate =
        dtToday.getFullYear() +
        "-" +
        ("0" + (dtToday.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtToday.getDate()).slice(-2);
      fnLoadCalenderEvents(sStartDate, sEndDate);
      setsInitialDate(
        dtToday.getFullYear() +
          "-" +
          ("0" + (dtToday.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dtToday.getDate()).slice(-2)
      );
      setInitialView("timeGridDay");
    }
  };

  const [objEventData, setobjEventData] = useState({});

  const fnGetEventData = (objData) => {
    setobjEventData(objData);
  };

  const handleScheduleMeetingPopup = () => {
    setdisplayMeetingSchedule(true);
    setdisplayEventType(false);
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }
  return (
    <>
      {/* <Cirrus.Page
                background='primary'
                backgroundShape="double"
                header={<HeaderWithNavigation />}
                className="myCoach"
            > */}
      <div className="myCoach">
        <Cirrus.PageBanner
          bodyText=""
          heading={cmsMainContent.title}
          button={
            Object.keys(mycoachData).length > 0
              ? {
                  children: fetchCMSLabel(
                    "CalendarPage.CalendarPageTemplate.CreateANewEventButton",
                    cmsCalendarPageTemplate.labels
                  ),
                  onClick: () => {
                    setdisplayEventType(true);
                  },
                  size: "Large",
                }
              : ""
          }
        />
        <Cirrus.OneColumnLayout type="wrapped">
          <FullCalender
            initialLocale={sInitialLocale}
            initialView={sInitialView}
            eventsData={sEventsData}
            prevButtonClick={fnCallPrev}
            todayButtonClick={fnCallToday}
            nextButtonClick={fnCallNext}
            setRef={calendarRef}
            sInitialDate={sInitialDate}
            showEventDetails={setdisplayEventDlg}
            GetEventData={fnGetEventData}
            todayButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.TodayButton"
            )}
            nextButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.NextButton"
            )}
            backButtonLabel={fetchCommonLabel(
              "CommonComponent.CommonBackButton"
            )}
          />
        </Cirrus.OneColumnLayout>
      </div>
      {/* </Cirrus.Page> */}
      {/* /// Below Popup is displayed when used click on Create an event button */}
      {displayEventType && (
        <Cirrus.Modal
          closeButtonProps={{
            children: "X",
            isSecondary: true,
            onClick: closePopupWindow,
            size: "Small",
            type: "button",
          }}
          headerProps={{
            children: "Set up an event",
            className: "no-margin",
            size: "h5",
          }}
          isScrollable
          role="dialog"
          size="default"
        >
          <div>
            <Cirrus.Button
              size="Large"
              onClick={() => handleScheduleMeetingPopup()}
              isFullWidth={true}
            >
              {fetchEventTypeLabel("CalendarPage.CalendarEventType.CoachEvent")}
            </Cirrus.Button>
          </div>
        </Cirrus.Modal>
      )}
      {displayEventDlg && (
        <CandidateCalenderEventDetails
          closeDialog={setdisplayEventDlg}
          cmsContent={cmsCalendarPageTemplate}
          fetchCMSLabel={fetchCMSLabel}
          eventData={objEventData}
          allEventsData={sEventsData}
          ReloadComp={setcalenderReload}
        ></CandidateCalenderEventDetails>
      )}

      {displayMeetingSchedule && (
        <MeetingScheduleDialog
          fetchLabel={fetchLabel}
          cmsContent={cmsCalendarPageTemplate}
          closeDialog={setdisplayMeetingSchedule}
          mycoachData={mycoachData}
          ReloadComp={setcoachSessionReload}
          todayButtonLabel={fetchLabel(
            "CalendarPage.ScheduleAMeetingWithCoach.TodayButton"
          )}
          nextButtonLabel={fetchLabel(
            "CalendarPage.ScheduleAMeetingWithCoach.NextButton"
          )}
          backButtonLabel={fetchCommonLabel("CommonComponent.CommonBackButton")}
        ></MeetingScheduleDialog>
      )}

      {/* <Footer isLoggedIn={true} /> */}
    </>
  );
}

export default CandidateCalender;
