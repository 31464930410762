import { GlobalConstants } from '../utility/GlobalConstants';

export const returnCountryLanguagePersona = () => {
    const personaName = JSON.parse(sessionStorage.getItem("setProfileDetails"))?.personaName;
    const userLangAndCountry = JSON.parse(window.sessionStorage.getItem("Language"));

    let userL = GlobalConstants.defaultCountryLanguage.language;
    let marketN = GlobalConstants.defaultCountryLanguage.country;
    if (userLangAndCountry != undefined || userLangAndCountry != null) {
        userL = userLangAndCountry.selectedLanguageISOCode;
        marketN = userLangAndCountry.selectedCountryShortName;
    } else {
        let browserLang = navigator.language || navigator.userLanguage;
        if(browserLang.includes("-")) {
            browserLang = browserLang.split("-")[1];
        }
        let filteredLang = GlobalConstants.supportedCountryLanguage.filter((lang) => lang.browserCode.toLocaleLowerCase() === browserLang.toLocaleLowerCase())
        if (filteredLang.length > 0) {
            userL = filteredLang[0].language;
            marketN = filteredLang[0].country;
        }
    }

    let returnData = {
        MarketName: marketN,
        language: userL,
        Persona: personaName != undefined ? personaName : 'Job Seeker'
    }
    return returnData;
}

export const returnLanguage = () => {
    let userL = GlobalConstants.defaultCountryLanguage.language;
    let browserLang = navigator.language || navigator.userLanguage;
    if(browserLang.includes("-")) {
        browserLang = browserLang.split("-")[1];
    }
    let filteredLang = GlobalConstants.supportedCountryLanguage.filter((lang) => lang.browserCode.toLocaleLowerCase() === browserLang.toLocaleLowerCase())
    if (filteredLang.length > 0) {
        userL = filteredLang[0].language;
    }
    return userL
}