//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react';
import './spinner.css';
import * as Cirrus from 'manpowergroup-cirrus';


function Spinner({ spinnerTimer = false, screenLoader = true }) {
    return (
        <>
            {screenLoader === true ? <>
                {
                    spinnerTimer && <div className='wrapper'>
                        <div className='spinnerPosition'>
                            <Cirrus.Spinner size={50} />
                        </div>
                    </div>
                }
            </> : <>
                {
                    spinnerTimer && <Cirrus.Spinner size={50} />

                }
            </>

            }
        </>
    )
}

export default Spinner
