//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';
import { Link } from 'react-router-dom';
import sideimage from './sideimage.png';
import { useEffect, useState, useContext } from 'react';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import { Context } from '../../../common/context/Context';
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useNavigate, useParams } from "react-router-dom";
import '../style.css';
import { AssessmentActions } from '../AssessmentActions';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Icon from "../../../assets/fonts/icomoon/Icon";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function QuestionsStartPage() {

    const navigate = useNavigate();
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const pageOrgin = useParams();
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState([]);
    const [screenLoader, setScreenLoader] = useState(false);
    const [assessmentStartPageData, setAssessmentStartPageData] = useState();
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));

    const fetchCMSData = () => {
        setScreenLoader(true);
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsAssessmentContent));
        let payload = {
            "PageName": "ThoughtProvoking"
        };
        AssessmentActions.GetAssessmentCMS(payload).then(res => {
            if (res.status === 200) {
                let resData = res.data;
                ActivityAction.SaveActivity(resData?.ID, "startassessment", resData?.Title)
                TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                TrackActions.PageAction("ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                refCmsAssessmentContent.browserTitle = resData.BrowserTitle;
                refCmsAssessmentContent.metaDescription = resData.MetaDescription;
                refCmsAssessmentContent.metadata = resData.Metadata;
                refCmsAssessmentContent.title = resData.Title;
                refCmsAssessmentContent.largeImage = resData.LargeImage;
                refCmsAssessmentContent.mediumImage = resData.MediumImage;
                refCmsAssessmentContent.smallImage = resData.SmallImage;
                refCmsAssessmentContent.focusArea = resData.FocusArea[0];
                refCmsAssessmentContent.resourceType = resData.ResourceType;
                refCmsAssessmentContent.resourceCategoryType = resData.ResourceCategoryType;
                refCmsAssessmentContent.duration = resData.Duration;
                refCmsAssessmentContent.description = resData.Description;
                refCmsAssessmentContent.shortDescription = resData.ShortDescription;
                refCmsAssessmentContent.subTitle = resData.SubTitle;
                refCmsAssessmentContent.internalAssessmentId = resData.ID;
                refCmsAssessmentContent.externalAssessmentId = resData.ExternalId;
                refCmsAssessmentContent.questionsList = resData.ChildPages.filter(x => x.Key !== "Assessments.ThoughtProvoking.StatementsReview");
                refCmsAssessmentContent.resultPage = resData.ChildPages.find(x => x.Key === "Assessments.ThoughtProvoking.StatementsReview")
                refCmsAssessmentContent.pageContent = resData.Components["CommonComponent"];
                refCmsAssessmentContent.questionPageContent = resData.Components["CommonResourceComponent"];
                setCmsAssessmentContent(refCmsAssessmentContent);
                context.setCmsThoughtQuestionsContent(refCmsAssessmentContent)
                setScreenLoader(false);
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }


    const fetchStartPageData = () => {
        let resourceType = "assessmentsandtasks";

        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }

        AssessmentActions.GetStartPageData(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res?.data && res?.data?.responsemodel;
                    let refData = resData?.filter(x => x.key === "Assessments.ThoughtProvoking")
                    setAssessmentStartPageData(refData && refData[0] && refData[0]);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    const fetchStartPageLabels = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.pageContent?.Labels) {
            refCmsAssessmentContent?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchLearningStatus = (id) => {
        let refCmsAssessmentContent = cmsAssessmentContent && cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.pageContent?.LOVList[0].Values) {
            refCmsAssessmentContent?.pageContent?.LOVList[0].Values.map((x) => {
                if (x.ID === id) {
                    label = x.ID;
                }
            });
        }
        return label;
    }

    const fetchLearningStatusLabel = (id) => {
        let refCmsAssessmentContent = cmsAssessmentContent && cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.pageContent?.LOVList[0].Values) {
            refCmsAssessmentContent?.pageContent?.LOVList[0].Values.map((x) => {
                if (x.ID === id) {
                    label = x.Value;
                }
            });
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.questionPageContent?.Labels) {
            refCmsAssessmentContent?.questionPageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    }

    const updateAssessmentStatus = (assessmentid) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setScreenLoader(true)
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": assessmentid,
            "learningResourceType": cmsAssessmentContent?.resourceCategoryType?.ID,
            "resourceTypeId": cmsAssessmentContent?.resourceType?.ID,
            "learningResourceStatus": fetchLearningStatus("inprogress"),
            "isSaved": assessmentStartPageData?.isSaved,
            "isLiked": assessmentStartPageData?.isLiked,
            "lastAnsweredPageId": context.TPProgress === 100 ? "0" : assessmentStartPageData?.lastAnsweredPageId,
            "isDownloaded": assessmentStartPageData?.isDownloaded,
            "isSharedWithCoach": assessmentStartPageData?.isSharedWithCoach,
            "resultDocumentId": assessmentStartPageData?.resultDocumentId
        }
        if (context.TPProgress === 100) {
            context.setEdit(false)
        }
        AssessmentActions.SaveAssessmentStatus(cmsAssessmentContent?.resourceCategoryType?.ID, assessmentid, payload).then(async (res) => {
            if (res.status === 200) {
                TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsAssessmentContent?.internalAssessmentId, "InternalAssessmentName": cmsAssessmentContent?.title })
                TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsAssessmentContent?.internalAssessmentId, "InternalAssessmentName": cmsAssessmentContent?.title })
                let response = await AssessmentActions.GetAssessmentStatusById(assessmentid)
                setScreenLoader(false)
                navigate("/" + pageOrgin.id + "/thought-provoking-questions/questions");
            } else {
                setScreenLoader(false)
            }
        }, err => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const saveCard = (e, id, type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        e.preventDefault()
        let resourceType = cmsAssessmentContent?.resourceCategoryType?.ID;
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": id,
            "learningResourceType": cmsAssessmentContent?.resourceCategoryType?.ID,
            "resourceTypeId": cmsAssessmentContent?.resourceType?.ID,
            "learningResourceStatus": fetchLearningStatus(assessmentStartPageData?.learningResourceStatus),
            "lastAnsweredPageId": assessmentStartPageData?.lastAnsweredPageId,
            "isSaved": type === "save" ? assessmentStartPageData?.isSaved == true ? false : true : assessmentStartPageData?.isSaved,
            "isLiked": type === "like" ? assessmentStartPageData?.isLiked == true ? false : true : assessmentStartPageData?.isLiked,
            "isDownloaded": assessmentStartPageData?.isDownloaded,
            "isSharedWithCoach": assessmentStartPageData?.isSharedWithCoach,
            "resultDocumentId": assessmentStartPageData?.resultDocumentId
        }

        AssessmentActions.SaveAssessmentCard(resourceType, id, payload).then(res => {
            if (res.status === 200) {
                let resData = res.data;
                fetchStartPageData()
            }
        }, (err) => {
            console.log(err);
        })
    }

    let likesLabel = fetchStartPageLabels('CommonComponent.CommonLikesText')
    let likesCount = assessmentStartPageData?.likeCount > 0 ? assessmentStartPageData?.likeCount + " " + likesLabel : likesLabel

    useEffect(() => {
        if (assessmentStartPageData) fetchCMSData()
        fetchStartPageData()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchCMSData()
    }, [context.language])

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page
                background="secondary"
                className='assessment-page'
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container fluid className='background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20'>
                <Row className='justify-content-between'>
                    <Col md="12" lg="7" className='mb-3 pb-lg-5'>
                        <Cirrus.Button
                            isSecondary
                            onClick={() => { navigate(`/${context.path ? context.path : path}`) }}
                            className="mb-lg-5 mb-3"
                        >
                            {fetchStartPageLabels('CommonComponent.CommonBackButton')}
                        </Cirrus.Button>
                        <Cirrus.Header size='h3' className='assessment-title'>
                            {cmsAssessmentContent && cmsAssessmentContent.title && cmsAssessmentContent.title}
                        </Cirrus.Header>
                        <div className='save-section mt-4 mb-3'>
                            <Cirrus.Button
                                leadingIcon={assessmentStartPageData?.isSaved ? "saved" : "save"}
                                size='small'
                                className='saveBtn'
                                onClick={(e) => {
                                    saveCard(e, cmsAssessmentContent?.internalAssessmentId, "save")
                                }}
                            >
                                <span className='saveBtnText'>
                                    {assessmentStartPageData?.isSaved ? fetchCommonResourceLabel("CommonResourceComponent.Saved") : fetchStartPageLabels('CommonComponent.CommonSaveButton')}
                                </span>
                            </Cirrus.Button>
                            <Cirrus.Label id='category-label'>{cmsAssessmentContent && cmsAssessmentContent.focusArea && cmsAssessmentContent.focusArea.Value && cmsAssessmentContent.focusArea.Value}</Cirrus.Label>
                            <Cirrus.Label id="cardtype">
                                {
                                    assessmentStartPageData?.learningResourceStatus === 'notstarted' &&
                                    <>
                                        {cmsAssessmentContent?.resourceType?.Value} <sup>.</sup>
                                        <span>{cmsAssessmentContent?.duration}</span>
                                    </>
                                    ||
                                    assessmentStartPageData?.learningResourceStatus === 'inprogress' &&
                                    <>
                                        {fetchLearningStatusLabel(assessmentStartPageData?.learningResourceStatus)}
                                    </>
                                    ||
                                    assessmentStartPageData?.learningResourceStatus === 'completed' &&
                                    <>
                                        {fetchLearningStatusLabel(assessmentStartPageData?.learningResourceStatus)}
                                    </>
                                }
                            </Cirrus.Label>
                        </div>
                        <div>
                            <Cirrus.Button
                                size='small'
                                className='likeBtn'
                                onClick={(e) => {
                                    saveCard(e, cmsAssessmentContent?.internalAssessmentId, "like")
                                }}
                            >
                                {assessmentStartPageData?.isLiked
                                    ? <Icon icon="heart_fill" size={20} color="#9D323D" />
                                    : <Icon icon="heart" size={20} color="black" />
                                }
                                <span className='likeBtnText'>
                                    {assessmentStartPageData?.likeCount == 0 ?
                                        fetchStartPageLabels('CommonComponent.CommonLikeText')
                                        : assessmentStartPageData?.likeCount == 1 ?
                                            assessmentStartPageData?.likeCount + " " + fetchStartPageLabels('CommonComponent.CommonLikeText')
                                            : assessmentStartPageData?.likeCount > 1 ?
                                                assessmentStartPageData?.likeCount + " " + fetchStartPageLabels('CommonComponent.CommonLikesText')
                                                : ''
                                    }
                                </span>
                            </Cirrus.Button>
                        </div>
                    </Col>
                    <Col md="12" lg="5" className='position-relative'>
                        <div className='assesment_startpage-image-wrapper'>
                            <img src={cmsAssessmentContent?.largeImage?.ImageUrl} alt='sideimage' className='image-overflow' />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='p-lg-5 pb-lg-0 pb-md-0 p-3'>
                <Row>
                    <Col md="12 " lg="6" className='description-container assessment_second-container-intro'>
                        <Cirrus.Headline size='Medium' className='mb-5 assessment_introHeading'>
                            {cmsAssessmentContent && cmsAssessmentContent.shortDescription && cmsAssessmentContent.shortDescription}
                        </Cirrus.Headline>
                        <Cirrus.Header size='h5' className='mb-3'>
                            {cmsAssessmentContent && cmsAssessmentContent.subTitle && cmsAssessmentContent.subTitle}
                        </Cirrus.Header>
                        <p className='mb-3 instructions'>
                            {cmsAssessmentContent && cmsAssessmentContent.description && cmsAssessmentContent.description}
                        </p>
                        <Cirrus.Button
                            className='my-4'
                            onClick={() => { updateAssessmentStatus(cmsAssessmentContent?.internalAssessmentId) }}
                        >
                            {
                                assessmentStartPageData?.learningResourceStatus === 'notstarted' &&
                                <>
                                    {fetchStartPageLabels("CommonComponent.CommonStartButton")}
                                </>
                                ||
                                assessmentStartPageData?.learningResourceStatus === 'inprogress' &&
                                <>
                                    {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                                </>
                                ||
                                assessmentStartPageData?.learningResourceStatus === 'completed' &&
                                <>
                                    {fetchStartPageLabels("CommonComponentCommonRetakeButton")}
                                </>
                            }
                        </Cirrus.Button>
                    </Col>
                    <Col md="6" lg="6">

                    </Col>
                </Row>
            </Container>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    );
}

export default QuestionsStartPage;
