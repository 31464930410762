//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext } from "react";
import { Page, Container, QuestionRecap } from "manpowergroup-cirrus";
import { React, useNavigate } from "react-router-dom";
import Header from "../../header/simpleHeader";
import { Context } from "../../../common/context/Context";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { OnBoardingActions } from "../OnBoardingActions";
import Footer from "../../../common/components/footer";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";


function Recap() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const clickConfirm = async () => {
    try {
      let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
      OnBoardingActions.updateCandidatePreferences(candidateID).then((res) => {
        if (res.status === 204) {
          OnBoardingActions.GetOnBoardingStatus(candidateID).then(
            (res) => {
              if (res.status === 200) {
                let resData = res.data;
                window.sessionStorage.setItem("onBoardingStatus", JSON.stringify(resData))
                navigate("/dashboard");
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } else {

        }
      });
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  
  let cmsQuestions;
  const [loadQuestions, setLoadQuestions] = useState([]);
  const [screenLoader, setScreenLoader] = useState(false);
  const [recapContent, setRecapContent] = useState({
    title: "",
    Labels: [],
  });
  const [questionsContent, setQuestionsContent] = useState({
    browserTitle: "",
    metaDescription: "",
    metadata: "",
    title: "",
    questionsList: [
      {
        QuestionTitle: "",
        AnswerList: [
          {
            AnswerText: "",
            AnswerId: "",
          },
        ],
      },
    ],
  });
  let autoCompleteSectorAnswers, autoCompleteJobAnswers;

  const fetchCMSContent = async () => {
    TrackActions.EventTrack(appInsights, "OnboardingComplete", { "OnboardingCompleteTime": new Date() })
    TrackActions.PageAction("OnboardingComplete", { "OnboardingCompleteTime": new Date() })
    let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
    let refRecapCmsContent = JSON.parse(JSON.stringify(questionsContent));
    setScreenLoader(true);
    // let resData = context?.cmsOnBoadingContent;
    let resData = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'));
    let programDetails = JSON.parse(sessionStorage.getItem("setProgramDetails"))
    if (Object.keys(resData).length !== 0) {
      resData?.Pages?.filter((x) => {
        if (x.Key === "QueryPage") {
          refCmsContent.browserTitle = x.BrowserTitle;
          refCmsContent.metaDescription = x.MetaDescription;
          refCmsContent.metadata = x.Metadata;
          refCmsContent.title = x.Title;
          refCmsContent.labels = x.Labels;
          refCmsContent.questionsList = [];
          let questionsObj;
          if (
            programDetails?.persona === undefined ||
            programDetails?.persona === null ||
            programDetails?.persona === "Non-Executive"
          ) {
            questionsObj = x.Components["Common.JobSeekerQuestions"];
          } else if (
            programDetails?.persona === "Executive"
          ) {
            questionsObj = x.Components["Common.ExecutiveQuestions"];
          }
          questionsObj.QuestionList.map((newArr, idx) => {
            if (newArr.QuestionTitle) {
              let newanswers = [];
              newArr.AnswerList.map((answers, id) => {
                newanswers.push({
                  ...answers,
                  id: answers.AnswerId,
                  name:
                    newArr.QuestionDesignType == "MultiSelect"
                      ? answers.AnswerText.replace(/ /g, "")
                      : "radio",
                  label: answers.AnswerText,
                  value: answers.AnswerText,
                  labelText: answers.AnswerText,
                });
              });
              let IsMandatory;
              if (newArr.IsMandatory === "") {
                IsMandatory = false;
              } else {
                IsMandatory = true;
              }
              refCmsContent.questionsList.push({
                ...newArr,
                AnswerList: newanswers,
                IsMandatory: IsMandatory,
              });
            }
          });
        }
        if (x.Key === "RecapPage") {
          refRecapCmsContent.browserTitle = x.BrowserTitle;
          refRecapCmsContent.metaDescription = x.MetaDescription;
          refRecapCmsContent.metadata = x.Metadata;
          refRecapCmsContent.title = x.Title;
          refRecapCmsContent.description = x.Description;
        }
      });
      setQuestionsContent(refCmsContent);
      cmsQuestions = refCmsContent;
      setScreenLoader(false);
    }
  };
  const loadSavedQuestions = async (e) => {
    try {
      let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
      let questionsJson = [];
      if (candidateID !== "") {
        await OnBoardingActions.getCandidatePreferences(candidateID).then(async res => {
          if (res.status === 200) {
            questionsJson = res.data;
            let filterSectorQuestion = questionsJson.find(filt => filt?.questionShortName === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn")
            if (filterSectorQuestion) {
              let sectorAnswerIds = filterSectorQuestion?.answerShortName?.split(',')
              if (sectorAnswerIds) {
                autoCompleteSectorAnswers = await getAnsLists(sectorAnswerIds)
              }
            }

            let filterJobsQuestion = questionsJson.find(filt => filt?.questionShortName === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor")
            if (filterJobsQuestion) {
              let jobAnswerIds = filterJobsQuestion.answerShortName.split(',')
              if (jobAnswerIds) {
                autoCompleteJobAnswers = await getAnsLists(jobAnswerIds)
              }
            }
          } else {
            questionsJson = [];
          }
        }, err => {
          console.log(err);
        })
      } else {
        questionsJson = [];
      }
      return questionsJson;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const getAnsLists = async (ids) => {
    let locale = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
    let autoCompleteAnswers;
    try {
      const payload = {
        locale: [locale?.programLanguageCode],
        key: [],
        id: ids
      }
      const res = await OnBoardingActions.GetOnboardingQuestionsList(payload)
      if (res?.data) {
        let resData = res?.data
        let title = [];
        resData?.map(data => {
          data?.items?.map(item => {
            title.push(item.title)
          })
        })
        autoCompleteAnswers = title;
      }
      return autoCompleteAnswers;
    } catch (err) {
      console.log(err)
    }
  }
  const renderQuestions = async () => {
    let savedQuestions = await loadSavedQuestions();
    let questionsArr = [], newQuestionsArr = [];
    if (savedQuestions.length >= 1) {
      cmsQuestions?.questionsList?.map((questions) => {
        savedQuestions.map((result) => {
          if (result.questionShortName === questions.QuestionId) {
            if (questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
              if (autoCompleteSectorAnswers.length > 0) {
                let quest = newQuestionsArr.filter((qid) => qid.QuestionId === questions.QuestionId);
                if (quest.length) {
                  let answerArr = [];
                  quest[0].AnswerList = autoCompleteSectorAnswers;
                  answerArr.push(quest[0].AnswerList);
                } else {
                  newQuestionsArr.push({
                    QuestionTitle: questions.QuestionTitle,
                    AnswerList: autoCompleteSectorAnswers,
                    QuestionId: result.questionShortName,
                  });
                }
              }
            }
            if (questions.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
              if (autoCompleteJobAnswers.length > 0) {
                let quest = newQuestionsArr.filter((qid) => qid.QuestionId === questions.QuestionId);
                if (quest.length) {
                  let answerArr = [];
                  quest[0].AnswerList = autoCompleteJobAnswers;
                  answerArr.push(quest[0].AnswerList);
                } else {
                  newQuestionsArr.push({
                    QuestionTitle: questions.QuestionTitle,
                    AnswerList: autoCompleteJobAnswers,
                    QuestionId: result.questionShortName,
                  });
                }
              }
            }
            if (questions.QuestionId !== "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || questions.QuestionId !== "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
              if (result.answerShortName !== undefined) {

                let answerShortName = result.answerShortName != "" ? result.answerShortName.split(",") : "";

                let answerShort = answerShortName.includes("JobSeekerQuestions.PreferToLearnNewThings.NoPreference") ? answerShortName.filter((val) => (val !== "JobSeekerQuestions.PreferToLearnNewThings.AllOfTheAbove")) : answerShortName

                if (answerShort?.length) {
                  answerShort.map((answerid) => {
                    questions.AnswerList.map((cmsOptions) => {
                      if (answerid === cmsOptions.AnswerId) {
                        let quest = newQuestionsArr.filter((qid) => qid.QuestionId === questions.QuestionId);
                        if (quest.length) {
                          let answerArr = [];
                          quest[0].AnswerList.push(cmsOptions.AnswerText);
                          answerArr.push(quest[0].AnswerList);
                        } else {
                          newQuestionsArr.push({
                            QuestionTitle: questions.QuestionTitle,
                            AnswerList: [cmsOptions.AnswerText],
                            QuestionId: result.questionShortName,
                          });
                        }
                      }
                    });
                  });
                }
              }
            }
          }
        });
        let notAnswered = newQuestionsArr.filter((qid) => qid.QuestionId === questions.QuestionId)
        if (notAnswered.length < 1) {
          newQuestionsArr.push({
            QuestionTitle: questions.QuestionTitle,
            AnswerList: [],
            QuestionId: questions.QuestionId,
          });
        }
      });
    } else {
      cmsQuestions.questionsList.map((questions) => {
        newQuestionsArr.push({
          QuestionTitle: questions.QuestionTitle,
          AnswerList: [],
          QuestionId: questions.QuestionId,
        });
      });
    }
    setScreenLoader(false);
    setLoadQuestions(newQuestionsArr);
  };
  const getCMSContent = async () => {
    let refCmsContent = JSON.parse(JSON.stringify(recapContent));
    // let resData = context?.cmsOnBoadingContent;

    let resData = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'));
    if (Object.keys(resData).length !== 0) {
      resData?.Pages?.filter((x) => {
        if (x.Key === "RecapPage") {
          let compName = x.Components["RecapPage.OnboardingRecap"];
          refCmsContent.title = compName.Title;
          refCmsContent.description = compName.Description;
          refCmsContent.labels = compName.Labels;
        }
      });
      setRecapContent(refCmsContent);
    }
  };
  const fetchLabel = (key) => {
    let refRecapContent = JSON.parse(JSON.stringify(recapContent));
    let label = "";
    if (refRecapContent?.labels?.length > 0) {
      refRecapContent?.labels?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  useEffect(async () => {
    window.scrollTo(0, 0)
    let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
    OnBoardingActions.GetcandidateProfile(candidateID).then(
      (res) => {
        if (res.status === 200) {
          window.sessionStorage.setItem("setProfileDetails", JSON.stringify(res.data))
        }
      },
      (err) => {
        console.log(err);
      }
    );
    await fetchCMSContent();
    await getCMSContent();
    await renderQuestions();
  }, []);

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      <main className="onboarding-recap_screen">
        {loadQuestions.length > 0 && (
          <Container
            heading={recapContent.title}
            primaryButton={{
              children: fetchLabel(
                "RecapPage.OnboardingRecap.RecapConfirmButton"
              ),
              isFullWidth: true,
              size: "Large",
              type: "button",
              onClick: () => clickConfirm(),
            }}
            subheading={fetchLabel(
              "RecapPage.OnboardingRecap.RecapDescription"
            )}
          >
            {loadQuestions?.map((questions, idx) => {
              return (
                <QuestionRecap
                  key={idx}
                  answers={questions?.AnswerList} 
                  editHref={"#/edit/question/" + questions?.QuestionId}
                  editText="Edit answer"
                  noAnswerText={fetchLabel("RecapPage.OnboardingRecap.NoAnswerText")}
                  question={questions?.QuestionTitle}
                />
              );
            })}
          </Container>
        )}
      </main>
    </>
  );
}

export default Recap;
