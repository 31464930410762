//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { ActivityAction } from "../../../common/utility/ActivityActions";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';

const SkillsResultPage = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState();
    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState();
    const [questionLabel, setQuestionLabel] = useState([])

    let content = document.getElementById('assessment-result-wrapper');

    const userAgent = window.navigator.userAgent;
    let browser = '';
    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        let cmsResponse = context.cmsStrengthAnalysis;
        let resData = cmsResponse?.masterCMS
        ActivityAction.SaveActivity(resData?.ID, "completedassesment", resData?.Title)
        setScreenLoader(true)
        if (Object.keys(cmsResponse).length !== 0) {
            setCmsMasterContent(cmsResponse)
            setQuestionLabel(cmsResponse?.assessmentInterests?.subQuestionLabel)
            let modifiedResultSet = [];

            cmsResponse?.assessmentSkills?.Question?.map((resourcePages) => {
                resourcePages?.map((refineQuestion) => {
                    modifiedResultSet.push(refineQuestion)
                })
            })

            await AssessmentActions.GetStrengthsAnalysisResult(cmsResponse.masterCMS.ID).then(resp => {
                if (resp.status === 200) {
                    let isDataSaved = resp.data.filter((item) => item.subAssessmentId === cmsResponse?.assessmentSkills?.ID)
                    modifiedResultSet?.map((refineQuestion) => {
                        isDataSaved[0]?.response?.map((refineAns) => {
                            if (refineAns.questionId === refineQuestion.Skills.ID) {
                                if (parseInt(refineAns.answerValue) === 1) {
                                    refineQuestion.RefinedAnswer = 0;
                                    refineQuestion.SkillsPercentage = 0;
                                } else if (parseInt(refineAns.answerValue) === 2) {
                                    refineQuestion.RefinedAnswer = 25;
                                    refineQuestion.SkillsPercentage = 25;
                                } else if (parseInt(refineAns.answerValue) === 3) {
                                    refineQuestion.RefinedAnswer = 50;
                                    refineQuestion.SkillsPercentage = 50;
                                } else if (parseInt(refineAns.answerValue) === 4) {
                                    refineQuestion.RefinedAnswer = 75;
                                    refineQuestion.SkillsPercentage = 75;
                                } else if (parseInt(refineAns.answerValue) === 5) {
                                    refineQuestion.RefinedAnswer = 100;
                                    refineQuestion.SkillsPercentage = 100;
                                }
                            }
                        })
                        return refineQuestion;
                    })
                    modifiedResultSet.sort(function (a, b) {
                        return parseFloat(a.SkillsPercentage) - parseFloat(b.SkillsPercentage);
                    });
                    setScreenLoader(false)
                    setMasterQuestionAnswer(modifiedResultSet.reverse())
                } else {
                    setScreenLoader(false)
                }
            },
                (err) => {
                    console.log(err);
                    setScreenLoader(false)

                }
            )
        }
    }


    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (Object.keys(refCmsContent).length !== 0) {
            refCmsContent?.masterLabel?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const retakeHandler = async () => {
        await AssessmentActions.StrengthsAnalysisRetakeAssessment(cmsMasterContent?.assessmentSkills?.ID).then(res => {
            if (res.status === 200) {
                navigate('/' + pageOrgin.id + '/strength-analysis/skill-start');
            }
        }, err => {
            console.log(err);
        })
    }

    const downloadPDF = () => {
        let content = document.getElementById('assessment-result-wrapper');

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;

            const pdf = new jsPDF({ orientation: 'l', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', 20, yPosition + 60, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                let file = new File([pdf.output('blob')], `${cmsMasterContent?.masterCMS?.Title + " "}${cmsMasterContent?.assessmentSkills?.ResourceCardTitle + "-"}${newDatetime.toLocaleString() + ".pdf"}`, { type: "application/pdf" })

                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");

                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    if (res.status === 200) {
                        let docId;
                        res.data.map(doc => {
                            docId = doc.id
                        })
                    }
                }, err => {
                    console.log(err);
                });
                context.setGenerateSkillsPdfFlag(false)
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();

                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }

                        let file = new File([pdf.output('blob')], `${cmsMasterContent?.masterCMS?.Title + " "}${cmsMasterContent?.assessmentSkills?.ResourceCardTitle + "-"}${newDatetime.toLocaleString() + ".pdf"}`, { type: "application/pdf" })

                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");

                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            if (res.status === 200) {
                                let docId;
                                res.data.map(doc => {
                                    docId = doc.id
                                })
                            }
                        }, err => {
                            console.log(err);
                        });
                        context.setGenerateSkillsPdfFlag(false)
                    }
                })
            }
        });
    }

    useEffect(() => {
        if (screenLoader === false) {
            if (context.generateSkillsPdfFlag) {
                downloadPDF()
            }
        }
    }, [masterQuestionAnswer])

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page">
                <Container className='pt-lg-4 pt-3 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button isSecondary onClick={(e) => navigate('/' + pageOrgin.id + '/strength-analysis')}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end" onClick={() => navigate("/" + pageOrgin.id)}>
                            <Cirrus.Button>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>

                <Container className='assessment-review-result-wrapper assessment-result-wrapper p-lg-5 p-3 mb-lg-5 mb-3'>
                    <Row className='mb-5 pb-3 border-bottom'>
                        <Col lg={6}>
                            <Cirrus.Header size='h4' className='mb-3 text-lg-start text-md-start text-center'>{cmsMasterContent?.skillsResultsPage?.Title}</Cirrus.Header>
                        </Col>
                        <Col lg={6} className='download-share-retake text-end'>
                            <Cirrus.Button className='me-2' onClick={(e) => { context.setGenerateMatrixPdfFlag(true); navigate('/' + pageOrgin.id + '/strength-analysis') }}>{fetchLabel("Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.ViewSkillsMatrix")}</Cirrus.Button>
                            <Cirrus.Button isSecondary className='me-2' onClick={retakeHandler}>{fetchLabel("CommonComponentCommonRetakeButton")}</Cirrus.Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className='pl-5 mb-3 text-start'>
                            <Cirrus.Headline size='Small'>{fetchLabel("Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.SkillsReviewPageSubHeading")}</Cirrus.Headline>
                            <p>
                                <Cirrus.RichText content={cmsMasterContent?.skillsResultsPage?.Description} />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='p-lg-4 p-3 mb-3'>
                            {
                                masterQuestionAnswer?.map((refineQuestion) => {
                                    return (
                                        <>
                                            <Container className='mb-2 p-lg-4 p-3 result-progress skill-resultpage'>
                                                <Row className='align-items-center gap-3 gap-md-0'>
                                                    <Col lg={4} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }} className="text-start">
                                                        <Cirrus.Label><span className="skill-heading">{refineQuestion.Title}</span></Cirrus.Label>
                                                    </Col>
                                                    <Col lg={{ span: 7, order: 2 }} md={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }} className="mt-md-3 mt-lg-0">
                                                        <Row>
                                                            <Col className='d-flex align-items-center justify-content-md-end justify-content-start text-end skill-result-lables-start' lg={{ order: 1 }} md={{ order: 1 }} sm={{ order: 1 }} xs={{ order: 1 }} >
                                                                <Cirrus.Label className='progress-label'><small>{questionLabel[0]?.AnswerText}</small></Cirrus.Label>
                                                            </Col>
                                                            <Col className='d-flex align-items-center strength-progress-bar' lg={{ order: 2, span: 6 }} md={{ order: 2, span: 8 }} sm={{ order: 3 }} xs={{ span: 12, order: 3 }}>
                                                                <ProgressBar now={refineQuestion.SkillsPercentage} label={`${refineQuestion.SkillsPercentage}%`} visuallyHidden className="progressBar w-100" />
                                                            </Col>
                                                            <Col className='d-flex align-items-center result-lables' lg={{ order: 3 }} md={{ order: 3 }} sm={{ order: 2 }} xs={{ order: 2 }}>
                                                                <Cirrus.Label className='progress-label'><small>{questionLabel[4]?.AnswerText}</small></Cirrus.Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={1} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }} className="text-end">
                                                        <div className='progress-wrapper strength-progress-wrapper'>
                                                            <Cirrus.Label className='progress-label'>{refineQuestion.SkillsPercentage}%</Cirrus.Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
            <div style={{ opacity: "0" }}>
                <div id='assessment-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1', display: 'block', width: '1300px' }}>
                    <table cellPadding={10} cellSpacing={10} style={{ width: '90%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Container style={{ width: '100%' }}>
                                        <Row>
                                            <Col lg={10} className='pl-5 mb-3 text-start'>
                                                <Cirrus.Headline size='Small'>{fetchLabel("Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.SkillsReviewPageSubHeading")}</Cirrus.Headline>
                                                <p>
                                                    <Cirrus.RichText content={cmsMasterContent?.skillsResultsPage?.Description} />
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='pl-5 mb-3'>
                                                {
                                                    masterQuestionAnswer?.map((refineQuestion) => {
                                                        return (
                                                            <>
                                                                <Container className='mb-2 p-lg-4 p-3 result-progress'>
                                                                    <Row>
                                                                        <Col lg={4} className="text-start">
                                                                            <Cirrus.Label>{refineQuestion.Title}</Cirrus.Label>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Row>
                                                                                <Col className='text-end' >
                                                                                    <Cirrus.Label className='progress-label'><small>{questionLabel[0]?.AnswerText}</small></Cirrus.Label>
                                                                                </Col>
                                                                                <Col className='d-flex align-items-center'>
                                                                                    <ProgressBar now={refineQuestion.SkillsPercentage} label={`${refineQuestion.SkillsPercentage}%`} visuallyHidden className="progressBar w-100" />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Cirrus.Label className='progress-label'><small>{questionLabel[4]?.AnswerText}</small></Cirrus.Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col lg={1} className="text-end">
                                                                            <div className='progress-wrapper'>
                                                                                <Cirrus.Label className='progress-label'>{refineQuestion.SkillsPercentage}%</Cirrus.Label>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default SkillsResultPage;
