//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
const InterestQuestionPage = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);

    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState([]);
    const [questionLabel, setQuestionLabel] = useState([])

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [assessmentPercentage, setAssessmentPercentage] = useState(0);
    const [assessmentStatus, setAssessmentStatus] = useState({
        Parrent: {},
        Skills: {},
        Interests: {},
        previousAnsweredPageNumber: 0,
    })
    const [continueCompletedButton, setContinueCompletedButton] = useState(false);

    useEffect(() => {
        identifyYourInterest()
        window.scrollTo(0, 0)
    }, [context.language])
    const identifyYourInterest = async () => {
        let cmsResponse = context.cmsStrengthAnalysis;
        setScreenLoader(true)
        if (Object.keys(cmsResponse).length !== 0) {
            let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
            setCmsMasterContent(cmsResponse)
            setQuestionLabel(cmsResponse?.assessmentInterests?.subQuestionLabel)
            let statusApiCallFlag = false;
            let refineApiCallFlag = false;
            let filterInterested = [];

            await AssessmentActions.GetStrengthsAnalysisIdentifyYourInterest(cmsResponse.masterCMS.ID).then((res) => {
                if (res.status === 200) {
                    let interestedResp = res?.data?.filter((interestAns) => interestAns.isInterested === true);

                    if (interestedResp.length > 0) {
                        cmsResponse?.assessmentInterests?.Question.map((resourcePages) => {
                            interestedResp?.map((inteResp) => {
                                resourcePages.map((interestedSet) => {
                                    if (inteResp.categoryId === interestedSet.Skills.ID) {
                                        filterInterested.push(interestedSet)
                                    }
                                })

                            })
                        })
                        statusApiCallFlag = true;
                    } else {
                        navigate("/" + pageOrgin.id + "/strength-analysis/interest-result");
                    }
                }
            }, err => {
                console.log(err);
            })

            if (statusApiCallFlag) {
                await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(cmsResponse.masterCMS.ID).then(
                    (res) => {
                        if (res.status === 200) {
                            let parent = res?.data.filter((status) => status.subLearningResourceId === undefined)
                            refAssessmentStatus.Parrent = parent[0];
                            cmsResponse.assIds.map((x) => {
                                res?.data.map((status) => {
                                    if (x.id === status.subLearningResourceId) {
                                        refAssessmentStatus[`${x.name}`] = status;
                                    }
                                })
                            })
                            refAssessmentStatus.previousAnsweredPageNumber = parseInt(refAssessmentStatus.Interests.lastAnsweredPageId)
                            setAssessmentStatus(refAssessmentStatus)
                            refineApiCallFlag = true;
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            }

            if (refineApiCallFlag) {
                await AssessmentActions.GetStrengthsAnalysisResult(cmsResponse.masterCMS.ID).then(resp => {
                    if (resp.status === 200) {
                        let isDataSaved = resp.data.filter((item) => item.subAssessmentId === cmsResponse?.assessmentInterests?.ID)

                        filterInterested?.map((interestedSet) => {
                            isDataSaved[0]?.response?.map((refineAns, index) => {
                                interestedSet?.SubQuestion?.map((subQuestion) => {
                                    if (refineAns.questionId === subQuestion.QuestionId) {
                                        subQuestion.RefinedAnswer = parseInt(refineAns.answerValue);
                                    }
                                    return subQuestion;
                                })
                            })
                            return interestedSet;
                        })
                        setMasterQuestionAnswer(filterInterested)

                        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                        setScreenLoader(false)

                        let percentageCalc = Math.ceil((refAssessmentStatus?.previousAnsweredPageNumber / (assessmentQuestion.length + 3)) * 100);
                        setAssessmentPercentage(percentageCalc);

                        setTimeout(() => {
                            assessmentQuestion[0].style.display = "block";
                            setCurrentQuestion(0)
                        }, 100);
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false);
                })
            }
        }
    }


    const saveAnswers = async (event, refineQueId, selectedSet) => {
        let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));
        let answerValue = document.getElementById(refineQueId).value;

        refMasterQuestionAnswer?.map((interestedSet) => {
            if (interestedSet.ID === selectedSet.ID) {
                interestedSet?.SubQuestion.map((subQuestion) => {
                    if (subQuestion.QuestionId === refineQueId) {
                        subQuestion.RefinedAnswer = parseInt(answerValue);
                    }
                    return subQuestion;
                })
            }
            return interestedSet;
        });
        setMasterQuestionAnswer(refMasterQuestionAnswer)

        let savePayload = [{
            "SubAssessmentId": cmsMasterContent?.assessmentInterests?.ID,
            "AssessementType": "Internal",
            "CategoryId": selectedSet.Skills.ID,
            "CategoryValue": selectedSet.Skills.Value,
            "QuestionId": refineQueId,
            "AnswerType": "Id",
            "AnswerValue": answerValue
        }]
        await AssessmentActions.StrengthsAnalysisRefiningYourInterestSkills(cmsMasterContent.masterCMS.ID, savePayload).then(res => {
            if (res.status === 200) {
            }
        }, err => {
            console.log(err);
        })
    }

    const nextPrev = (n, pageNumber, firstSetQuestion) => {
        if (n > 0) {
            showQuestion(currentQuestion + n, n, pageNumber, firstSetQuestion);
        } else {
            showQuestion(currentQuestion + n, n, pageNumber, firstSetQuestion);
        }
    }

    const showQuestion = async (n, NPn, pageNumber, firstSetQuestion) => {
        let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');

        assessmentQuestion[currentQuestion].style.display = "none";
        setCurrentQuestion(currentQuestion + NPn);

        if (assessmentQuestion.length === (pageNumber + 2)) {
            setContinueCompletedButton(true)
        } else {
            setContinueCompletedButton(false)
        }
        if (n == -1) {
            navigate("/" + pageOrgin.id + "/strength-analysis/interest-question");
        } else {
            if (n < assessmentQuestion.length) {
                let page = pageNumber + 1 + 3;
                if (!isNaN(page)) {
                    if (page > assessmentStatus.previousAnsweredPageNumber) {
                        setLoadSpinner(true);
                        let saveStatus = {
                            "SubLearningResourceStatus": "inprogress",
                            "SubLearningResourceId": cmsMasterContent?.assessmentInterests?.ID,
                            "LastAnsweredPageId": page
                        }
                        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, saveStatus).then((res) => {
                            if (res.status === 200) {

                                let pageNumber = page;
                                let percentageCalc = Math.ceil((pageNumber / (assessmentQuestion.length + 3)) * 100);
                                setAssessmentPercentage(percentageCalc);

                                refAssessmentStatus.previousAnsweredPageNumber = pageNumber;
                                setAssessmentStatus(refAssessmentStatus)

                                setTimeout(() => {
                                    assessmentQuestion[n].style.display = "block";
                                    setLoadSpinner(false);
                                }, 100);

                            } else {
                                setLoadSpinner(false);
                            }
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        })
                    } else {
                        assessmentQuestion[n].style.display = "block";
                    }
                } else {
                    assessmentQuestion[n].style.display = "block";
                }
            }
            if (n == assessmentQuestion.length) {
                let page = pageNumber + 1 + 3;
                if (!isNaN(page)) {
                    setLoadSpinner(true);
                    let statusPayload = {
                        "SubLearningResourceStatus": "completed",
                        "SubLearningResourceId": cmsMasterContent?.assessmentInterests?.ID,
                        "LastAnsweredPageId": page
                    }

                    await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, statusPayload).then((res) => {
                        if (res.status === 200) {

                            let pageNumber = page;
                            let percentageCalc = Math.ceil((pageNumber / (assessmentQuestion.length + 3)) * 100);
                            setAssessmentPercentage(percentageCalc);

                            refAssessmentStatus.previousAnsweredPageNumber = pageNumber;
                            setAssessmentStatus(refAssessmentStatus)

                            context.setGenerateInterestsPdfFlag(true)
                            setTimeout(() => {
                                navigate("/" + pageOrgin.id + "/strength-analysis/interest-result");
                                setLoadSpinner(false);
                            }, 100);
                        } else {
                            setLoadSpinner(false);
                        }
                    }, err => {
                        console.log(err);
                        setLoadSpinner(false);
                    })

                    if (assessmentStatus.Skills.subLearningResourceStatus === 'completed') {
                        let payload = {
                            "LearningResourceStatus": "completed"
                        }
                        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, payload).then((res) => {
                            if (res.status === 200) {
                                context.setGenerateInterestsPdfFlag(true)
                                navigate("/" + pageOrgin.id + "/strength-analysis/interest-result");
                            }
                        }, err => {
                            console.log(err);
                        })
                    }
                }
            }
        }
    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (Object.keys(refCmsMasterContent).length !== 0) {
            refCmsMasterContent?.masterLabel?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const disabledContinueButton = (item) => {
        let returnValue = '';

        let questionCount = item?.masterSecondSetQuestion?.length;
        let ansCount = item?.masterSecondSetQuestion?.filter((subQuestion) => subQuestion.secondSetQuestionSelectedStatus !== 0)

        if (questionCount === ansCount.length) {
            returnValue = false
        } else {
            returnValue = true
        }
        return returnValue;
    }



    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }


    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={
                    <HeaderWithNavigation />
                }
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pb-lg-4 pt-3 pb-3'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button isSecondary onClick={(e) => { nextPrev(-1) }}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {assessmentPercentage}%</Cirrus.Label>
                            <ProgressBar now={assessmentPercentage} label={`${assessmentPercentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end" onClick={() => navigate("/" + pageOrgin.id + "/strength-analysis")}>
                        <Cirrus.Button>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                    </Col>
                </Row>
                <p className='text-center pt-3 top-description'>
                    {/* <Cirrus.RichText content={firstSetQuestion?.Description} /> */}
                    {fetchLabel("Resources.Assessments.StrengthAnalysis.StrengthAnalysisComponent.InterestsAssessmentInstruction")}
                </p>
            </Container>
            {

                masterQuestionAnswer?.map((interestedSet, firstSetIndex) => {
                    return (<>

                        {
                            loadSpinner &&
                            <div className='loader'>
                                <Cirrus.Spinner size={50} />
                            </div>
                        }
                        <Container className='assessment-question-wrapper text-start mb-5 p-lg-5 p-4'>
                            {/* <div>{interestedSet?.Title}</div> */}
                            <Row>
                                <Col lg={8} className="m-auto text-start  pb-4">
                                    <Cirrus.Headline size='Small' className='text-center'>{interestedSet?.Title}</Cirrus.Headline>
                                    <p className='text-center'>
                                        <Cirrus.RichText content={interestedSet?.Description} />
                                    </p>
                                </Col>
                            </Row>
                            <Row className='refineQuestion-dekstop'>
                                <Col lg={10} className='px-5 m-auto'>
                                    <Row className='mb-3'>
                                        <Col lg={3}>

                                        </Col>
                                        <Col lg={9}>
                                            <Row className='row-cols-5 col-12'>
                                                {
                                                    questionLabel?.map((label, labelIndex) => {
                                                        return (
                                                            <Col className='text-center text-nowrap strength_bar' key={labelIndex}>
                                                                {label.AnswerText}<br />|
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {
                                interestedSet?.SubQuestion.map((subQuestion, secondIndex) => {
                                    return (<>
                                        {/* <div>{subQuestion.QuestionTitle}</div> */}
                                        <Row key={secondIndex} className='strength_ref-question'>
                                            <Col lg={10} xs={12} className='px-5 m-auto'>
                                                <Row className='mb-3 slider-row align-items-center'>
                                                    <Col lg={3} className='slider-title'>
                                                        <label for="customRange1">{subQuestion?.QuestionTitle}</label>
                                                    </Col>
                                                    <Col lg={9} xs={12} className='slider-column'>
                                                        <input
                                                            aria-label={subQuestion?.QuestionTitle}
                                                            id={subQuestion?.QuestionId}
                                                            name={subQuestion?.QuestionTitle}
                                                            onChange={(event) => saveAnswers(event, subQuestion?.QuestionId, interestedSet, firstSetIndex)}
                                                            type="range"
                                                            className="form-range"
                                                            step="1"
                                                            min="1"
                                                            max="5"
                                                            defaultValue={subQuestion?.RefinedAnswer}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className='refineQuestion-mobile'>
                                            <Row className='refineQuestion-mobile'>
                                                <Col lg={10} className='px-5 m-auto range-bar'>
                                                    <Row className='mb-3'>
                                                        <Col lg={3}>
                                                        </Col>
                                                        <Col lg={9}>
                                                            <Row className='row-cols-5 col-12 refining-strength-bar'>
                                                                {
                                                                    questionLabel?.map((label, labelIndex) => {
                                                                        return (
                                                                            <Col className='text-center strength_bar' key={labelIndex}>
                                                                                |<br /><span>{label.AnswerText}</span>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>)
                                })
                            }
                            <Row>
                                <Col lg={10} className='p-5 m-auto text-center refining-questions'>
                                    <Cirrus.Button
                                        className='w-25'
                                        onClick={(e) => { nextPrev(1, firstSetIndex, interestedSet) }}
                                    >
                                        {continueCompletedButton === true ? <>{fetchLabel("CommonComponent.CommonCompleteButton")}</> : <>{fetchLabel("CommonComponent.CommonContinueButton")}</>}
                                    </Cirrus.Button>
                                </Col>
                            </Row>
                        </Container>
                    </>
                    )
                })
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}
export default InterestQuestionPage;
