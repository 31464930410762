//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useNavigate } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { useEffect, useState, useContext, useRef } from "react";
import Header from "../../header/simpleHeader";
import { OnBoardingActions } from "../OnBoardingActions";
import { MyProfileActions } from "../../myProfile/MyProfileActions";
import { Context } from "../../../common/context/Context";
import Footer from "../../../common/components/footer";
import { Spinner as BusyLoading } from 'react-bootstrap';
import { Row, Col, Container } from "react-bootstrap";
import '../index.css';

function Questionnairee() {
    const heightRef = useRef();
    const fixedHeightRef = useRef();
    const navigate = useNavigate();
    const context = useContext(Context);
    const [screenLoader, setScreenLoader] = useState(false);
    const [questionsContent, setQuestionsContent] = useState({
        browserTitle: "",
        metaDescription: "",
        metadata: "",
        title: "",
        questionsList: [
            {
                QueryTitle: "",
                Answers: [
                    {
                        AnswerTitle: "",
                        AnswerId: "",
                    },
                ],
            },
        ],
    });
    const [cmsQuestions, setCmsQuestions] = useState();
    const [isSkipped, setIsSkipped] = useState(false);
    const [isEmptyAnswers, setIsEmptyAnswers] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [selected, setSelected] = useState([]);
    let [answeredQuestions, setAnsweredQuestions] = useState([]);
    let [selectedOptions, setSelectedOptions] = useState();
    let [selectedQuestion, setSelectedQuestion] = useState();
    let answerShortNameArr = [];
    let [allAnswer, setallAnswer] = useState();
    const [searchOptions, setSearchOptions] = useState();
    const [filteredOptions, setFilteredOptions] = useState();
    let filteredOptionsArr = useState([]);

    const [isAutoCompleteShowSectors, setIsAutoCompleteShowSectors] = useState(false)
    const [isAutoCompleteShowJobs, setIsAutoCompleteShowJobs] = useState(false)
    const [autoPopulateListSectors, setAutoPopulateListSectors] = useState([])
    const [autoPopulateListJobs, setAutoPopulateListJobs] = useState([])
    const [selectedItemSector, setSelectedItemSector] = useState('')
    const [selectedItemJobs, setSelectedItemJobs] = useState('')

    const [selectedSectors, setSelectedSectors] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState([]);

    const [noResponse, setNoResponse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const autopopulateRefSectors = useRef(null);
    const autopopulateRefJobs = useRef(null);

    let programDetails = JSON.parse(sessionStorage.getItem("setProgramDetails"))

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const exitBtnClick = () => {
        setIsModal(true);
    };

    const modalClose = () => {
        setIsModal(false);
    };

    const skipHandler = (e) => {
        setIsSkipped(true);
        nextPrev(e, 1, undefined)
    };

    useEffect(async () => {
        window.scrollTo(0, 0)
        fetchCMSContent();

        let assessmentQuestion = document.getElementsByClassName('questions-container');
        assessmentQuestion[currentQuestion].style.display = "flex";
    }, []);

    const fetchCMSContent = async () => {
        /* Get CMS content */
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        setScreenLoader(true);
        let resData = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'));
        // let resData = context?.cmsOnBoadingContent;
        if (Object.keys(resData).length !== 0) {
            resData?.Pages.filter((x) => {
                if (x.Key === "QueryPage") {
                    refCmsContent.browserTitle = x.BrowserTitle;
                    refCmsContent.metaDescription = x.MetaDescription;
                    refCmsContent.metadata = x.Metadata;
                    refCmsContent.title = x.Title;
                    refCmsContent.questionsList = [];
                    let questionsObj;
                    if (
                        programDetails?.persona === "Executive"
                    ) {
                        questionsObj = x.Components["Common.ExecutiveQuestions"];
                        refCmsContent.labels = x.Components["Common.ExecutiveQuestions"].Labels;
                        refCmsContent.componentKey = x.Components["Common.ExecutiveQuestions"].Key;
                    } else {
                        questionsObj = x.Components["Common.JobSeekerQuestions"];
                        refCmsContent.labels = x.Components["Common.JobSeekerQuestions"].Labels;
                        refCmsContent.componentKey = x.Components["Common.JobSeekerQuestions"].Key;
                    }
                    refCmsContent.savePopup = x.Components["OnboardingPage.SavePopup"];
                    let searchQuestions = [], allQuestions = [];
                    questionsObj.QuestionList.map((newArr, idx) => {
                        if (newArr.QuestionTitle) {
                            let newanswers = [];
                            newArr.AnswerList.map((answers, id) => {
                                newanswers.push({
                                    ...answers,
                                    id: answers.AnswerId,
                                    name: (newArr.QuestionDesignType == "MultiSelect") ? answers.AnswerText.replace(/ /g, "") : newArr.QuestionId,
                                    label: answers.AnswerText,
                                    value: answers.AnswerText,
                                    labelText: answers.AnswerText,
                                    defaultChecked: false
                                });
                            });
                            if (newArr.OptionType === "Search") {
                                searchQuestions.push({ QuestionId: newArr.QuestionId, AnswerList: newanswers })
                            }

                            let IsMandatory;
                            if (newArr.IsMandatory === "") {
                                IsMandatory = false;
                            } else {
                                IsMandatory = true;
                            }
                            refCmsContent.questionsList.push({
                                ...newArr,
                                AnswerList: newanswers,
                                IsMandatory: IsMandatory,
                            });
                        }
                    });
                    setSearchOptions(searchQuestions);
                    setFilteredOptions(searchQuestions);
                }
            });

            let arr = [];
            let example = refCmsContent?.questionsList.filter((ques) => (
                ques?.QuestionTitle === "How do you prefer to learn new things?"
            ))
            example[0]?.AnswerList.map((val) => (
                arr.push(val?.AnswerId)
            ))
            setallAnswer(arr?.filter((val) => val !== 'JobSeekerQuestions.PreferToLearnNewThings.NoPreference'))
            setQuestionsContent(refCmsContent);
            setCmsQuestions(refCmsContent);
            setScreenLoader(false);
        }
    };

    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        let label = "";
        if (refCmsContent?.labels?.length > 0) {
            refCmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const saveExitClick = (e) => {
        setIsModal(false);
        let selectedQnAarray;
        questionsContent.questionsList.map((questions) => {
            if (questions.IsMandatory) {
                if (selectedOptions == "") {
                    setIsEmptyAnswers(true);
                    return false;
                } else {
                    setIsEmptyAnswers(false);
                    selectedQnAarray = [{
                        questionShortName: selectedQuestion,
                        answerShortName: selectedOptions,
                    }];
                }
            } else {
                if (questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
                    selectedQnAarray = [{
                        questionShortName: selectedQuestion,
                        answerShortName: uniqueSelectedListSectors?.map(selectedId => { return selectedId.id }).toString()
                    }]
                } else if (questions.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
                    selectedQnAarray = [{
                        questionShortName: selectedQuestion,
                        answerShortName: uniqueSelectedListJobs?.map(selectedId => { return selectedId.id }).toString()
                    }]
                } else {
                    if (selectedQuestion !== "" && selectedQuestion !== undefined) {
                        selectedQnAarray = [{
                            questionShortName: selectedQuestion,
                            answerShortName: selectedOptions
                        }]
                    }
                }
            }
        });
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        if (selectedQuestion !== "" && selectedQuestion !== undefined) {
            OnBoardingActions.saveCandidatePreferences(candidateID, selectedQnAarray).then((res) => {
                if (res.status == "200") {
                    navigate("/recap");
                } else {

                }
            }, (err) => {
                console.log(err);
            })
        } else {
            navigate("/recap");
        }
    };

    const fetchModalCMSLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent.savePopup));
        let label = "";
        if (refCmsContent?.Labels?.length > 0) {
            refCmsContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    function useAutoCompleteOutsideClickSectors(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideAutoCompleteSectors(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsAutoCompleteShowSectors(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideAutoCompleteSectors);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideAutoCompleteSectors);
            };
        }, [ref]);
    }
    useAutoCompleteOutsideClickSectors(autopopulateRefSectors);
    function useAutoCompleteOutsideClickJobs(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideAutoCompleteJobs(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsAutoCompleteShowJobs(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideAutoCompleteJobs);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideAutoCompleteJobs);
            };
        }, [ref]);
    }
    useAutoCompleteOutsideClickJobs(autopopulateRefJobs);

    const getOnboardingAutoSuggestion = (event, key) => {
        const input = event.target.value
        let payload = {
            "SearchKey": input.toLowerCase()
        }
        if (key === 'industry') {
            setSelectedItemSector(input)
        } else {
            setSelectedItemJobs(input)
        }
        if (input.length >= 3) {
            setIsLoading(true)
            OnBoardingActions.GetOnboardingQuestions(key, payload).then(res => {
                if (res.status === 200) {
                    setNoResponse(false)
                    if (key == 'industry') {
                        setAutoPopulateListSectors(res?.data)
                        setIsAutoCompleteShowSectors(true)
                    } else {
                        setAutoPopulateListJobs(res?.data)
                        setIsAutoCompleteShowJobs(true)
                    }
                    setIsLoading(false)
                } else if (res.status === 204) {
                    setNoResponse(true)
                    setAutoPopulateListSectors("")
                    setAutoPopulateListJobs("")
                    setIsLoading(false)
                    if (key == 'industry') {
                        setIsAutoCompleteShowSectors(true);
                    } else {
                        setIsAutoCompleteShowJobs(true);
                    }
                } else {
                }
            }, err => {
                console.log(err)
            })
        }
    }

    const selectHandlerSector = (e, title, id, questionId) => {
        e.stopPropagation()
        e.preventDefault()
        setSelectedItemSector(title)
        setSelectedSectors([...selectedSectors, { title: title, id: id }])
        setSelectedItemSector('')
        setSelectedQuestion(questionId)
        setIsAutoCompleteShowSectors(false)
    }

    const selectHandlerJobs = (e, title, id, questionId) => {
        e.stopPropagation()
        e.preventDefault()
        setSelectedItemJobs(title)
        setSelectedJobs([...selectedJobs, { title: title, id: id }])
        setSelectedItemJobs('')
        setSelectedQuestion(questionId)
        setIsAutoCompleteShowJobs(false)
    }

    const uniqueSelectedListSectors = [...selectedSectors?.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    const uniqueSelectedListJobs = [...selectedJobs?.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    const removeSectorsnJobs = (index, questionId) => {
        if (questionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
            const filterdSectors = [...uniqueSelectedListSectors].filter((sector, i) => {
                return i !== index
            })
            setSelectedSectors(filterdSectors)
        } else {
            const filterdJobs = [...uniqueSelectedListJobs].filter((job, i) => {
                return i !== index
            })
            setSelectedJobs(filterdJobs)
        }
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('questions-container');
        let backButton = document.getElementsByClassName('onboarding-questions-back-btn');

        if (n < assessmentQuestion.length) {
            assessmentQuestion[n].style.display = "flex";
        }
        if (n == assessmentQuestion.length) {
            navigate("/recap");
        }
        if (n == 0) {
            backButton[0].style.display = "none";
        }
    }
    const nextPrev = (e, n, questionId) => {
        let selectedQnAarray;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName('questions-container');
        let backButton = document.getElementsByClassName('onboarding-questions-back-btn');
        if (n > 0) {
            let persona_find = selectedOptions?.split(".")[0]
            let selectFilter
            if (persona_find == "ExecutiveQuestions") {
                selectFilter = selectedOptions?.includes("ExecutiveQuestions.PreferToLearnNewThings.NoPreference") ? selectedOptions?.split(",").filter((val) => (val == "ExecutiveQuestions.PreferToLearnNewThings.NoPreference")).join(",") : selectedOptions
            } else {
                selectFilter = selectedOptions?.includes("JobSeekerQuestions.PreferToLearnNewThings.NoPreference") ? selectedOptions?.split(",").filter((val) => (val == "JobSeekerQuestions.PreferToLearnNewThings.NoPreference")).join(",") : selectedOptions?.includes('JobSeekerQuestions.PreferToLearnNewThings.AllOfTheAbove') ? allAnswer.join(",") : selectedOptions
            }
            if (questionId) {
                questionsContent.questionsList.map((questions, idx) => {
                    if (questions.IsMandatory) {
                        if (selectedOptions == "") {
                            setIsEmptyAnswers(true);
                            return false;
                        } else {
                            setIsEmptyAnswers(false);
                            selectedQnAarray = [{
                                questionShortName: selectedQuestion,
                                answerShortName: selectedOptions,
                            }];
                        }
                    } else {
                        if (questionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
                            selectedQnAarray = [{
                                questionShortName: selectedQuestion,
                                answerShortName: uniqueSelectedListSectors?.map(selectedId => { return selectedId.id }).toString()
                            }]
                        } else if (questionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
                            selectedQnAarray = [{
                                questionShortName: selectedQuestion,
                                answerShortName: uniqueSelectedListJobs?.map(selectedId => { return selectedId.id }).toString()
                            }]
                        } else {
                            if (selectedQuestion !== "" && selectedQuestion !== undefined) {
                                selectedQnAarray = [{
                                    questionShortName: selectedQuestion,
                                    answerShortName: selectFilter
                                }]
                            }
                        }
                    }
                })
                if (selectedQuestion !== "" && selectedQuestion !== undefined && selectedQnAarray[0].answerShortName !== "") {
                    OnBoardingActions.saveCandidatePreferences(candidateID, selectedQnAarray).then((res) => {
                        if (res.status === 200) {
                            setSelected([]);
                            setSelectedQuestion("");
                            setSelectedOptions("");
                        } else {
                        }
                    }, err => {
                        console.log(err)
                    })
                }
            }

            assessmentQuestion[currentQuestion].style.display = "none";
            backButton[0].style.display = "block";
            setCurrentQuestion(currentQuestion + n);
            if (currentQuestion < 0) {
                setCurrentQuestion(0);
            }
            showQuestion(currentQuestion + n);
        } else {
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }


    return (
        <>
            <main className="onboarding-questions">
                <Cirrus.FullHeightContainerLayout
                    ref={heightRef}
                    footerHeight={0}
                    headerHeight={0}
                    className="onboarding-questions-wrapper"
                >
                    {
                        questionsContent?.questionsList?.map((questions, idx) => {
                            return (
                                <Cirrus.Container key={idx}
                                    ref={fixedHeightRef}
                                    primaryButton={{
                                        children: fetchLabel(questionsContent.componentKey + ".NextButton"),
                                        size: "Large",
                                        type: "button",
                                        onClick: (e) => { nextPrev(e, 1, questions.QuestionId) },
                                    }}
                                    exitButton={{
                                        children: fetchLabel(questionsContent.componentKey + ".Exit"),
                                        isSecondary: true,
                                        onClick: exitBtnClick,
                                        size: "Small",
                                        type: "button",
                                    }}
                                    secondaryButton={{
                                        children: fetchLabel(questionsContent.componentKey + ".SkipButton"),
                                        isSecondary: true,
                                        onClick: skipHandler,
                                        size: "Large",
                                        type: "submit",
                                        disabled: questions.IsMandatory,
                                    }}
                                    heading={questions.QuestionTitle}
                                    subheading={questions.QuestionDesignType === "Single Select" && fetchLabel(questionsContent.componentKey + ".PleaseSelectOneOption") || questions.QuestionDesignType === "MultiSelect" && fetchLabel(questionsContent.componentKey + ".PleaseSelectAllThatApply")}
                                    className={questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? "questions-container sectorQuestion" : questions.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" ? "questions-container jobQuestion" : "questions-container"}
                                >
                                    {
                                        questions.OptionType === "Search" && questions.QuestionDesignType === "MultiSelect" &&
                                        questions.QuestionId !== "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" &&
                                        questions.QuestionId !== "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            {
                                            ...filteredOptionsArr = filteredOptions?.filter(filt => filt.QuestionId === questions.QuestionId)
                                            }
                                            {...filteredOptionsArr = filteredOptionsArr[0]?.AnswerList}
                                            options={
                                                filteredOptionsArr.map(option => {
                                                    return {
                                                        onChange: (e) => {
                                                            let chosen = selected;
                                                            if (e.target.checked) {
                                                                chosen.push(option.AnswerId);
                                                                let updatedOpts;
                                                                setSearchOptions(
                                                                    searchOptions.map(searchQuest => {
                                                                        if (searchQuest.QuestionId === questions.QuestionId) {
                                                                            updatedOpts = searchQuest.AnswerList.map(searchOpts => {
                                                                                if (searchOpts.AnswerId === option.AnswerId) {
                                                                                    return { ...searchOpts, defaultChecked: true }
                                                                                } else {
                                                                                    return searchOpts
                                                                                }
                                                                            })
                                                                            return { ...searchQuest, AnswerList: updatedOpts }
                                                                        } else {
                                                                            return { ...searchQuest }
                                                                        }
                                                                    })
                                                                )
                                                            } else {
                                                                chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                                let updatedOpts;
                                                                setSearchOptions(
                                                                    searchOptions.map(searchQuest => {
                                                                        if (searchQuest.QuestionId === questions.QuestionId) {
                                                                            updatedOpts = searchQuest.AnswerList.map(searchOpts => {
                                                                                if (searchOpts.AnswerId === option.AnswerId) {
                                                                                    return { ...searchOpts, defaultChecked: false }
                                                                                } else {
                                                                                    return searchOpts
                                                                                }
                                                                            })
                                                                            return { ...searchQuest, AnswerList: updatedOpts }
                                                                        } else {
                                                                            return { ...searchQuest }
                                                                        }
                                                                    })
                                                                )
                                                            }
                                                            if (questions.IsMandatory) {
                                                                if (chosen.length) {
                                                                    setIsEmptyAnswers(false);
                                                                } else {
                                                                    setIsEmptyAnswers(true);
                                                                }
                                                            }
                                                            setSelectedOptions(chosen.toString());
                                                            setSelectedQuestion(questions.QuestionId);
                                                        },
                                                        ...option,
                                                    }
                                                })
                                            }
                                            searchInput={{
                                                hideLabel: true,
                                                icon: "search",
                                                id: "search",
                                                isRounded: true,
                                                label: "search",
                                                name: "search",
                                                onChange: (event) => {
                                                    let updatedList = searchOptions.filter(filt => filt.QuestionId === questions.QuestionId)
                                                    updatedList = updatedList[0].AnswerList;
                                                    updatedList = updatedList.filter((filterOption) => {
                                                        return (!event.target.value || filterOption.label.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0);
                                                    });
                                                    if (updatedList.length) {
                                                        setFilteredOptions(
                                                            filteredOptions.map(filtrdQuest => {
                                                                if (filtrdQuest.QuestionId == questions.QuestionId) {
                                                                    return { ...filtrdQuest, AnswerList: updatedList }
                                                                } else {
                                                                    return { ...filtrdQuest }
                                                                }
                                                            })
                                                        )
                                                    } else {
                                                        updatedList = [{
                                                            id: 0,
                                                            label: programDetails?.persona === "Executive" ? fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound") : fetchLabel("Common.ExecutiveQuestions.NoDataAvailable"),
                                                            labelText: programDetails?.persona === "Executive" ? fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound") : fetchLabel("Common.ExecutiveQuestions.NoDataAvailable"),
                                                            name: "noResult",
                                                            value: programDetails?.persona === "Executive" ? fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound") : fetchLabel("Common.ExecutiveQuestions.NoDataAvailable"),
                                                            disabled: true,
                                                        }];
                                                        setFilteredOptions(
                                                            filteredOptions.map(filtrdQuest => {
                                                                if (filtrdQuest.QuestionId == questions.QuestionId) {
                                                                    return { ...filtrdQuest, AnswerList: updatedList }
                                                                } else {
                                                                    return { ...filtrdQuest }
                                                                }
                                                            })
                                                        )
                                                    }
                                                },
                                                placeholder: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.SearchHere") : fetchLabel("Common.JobSeekerQuestions.SearchHereText"),
                                                type: "text",
                                            }}
                                        />
                                    }

                                    {
                                        questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn"
                                        && (
                                            <div className="sector_select">
                                                <Cirrus.Input
                                                    placeholder={questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? fetchLabel("Common.JobSeekerQuestions.PreferredIndustry") : fetchLabel("Common.JobSeekerQuestions.PreferredJob")}
                                                    id="sectors"
                                                    icon="search"
                                                    className="mb-3"
                                                    name="sectors"
                                                    value={selectedItemSector}
                                                    showSearchButton={{
                                                        children: fetchLabel("Common.JobSeekerQuestions.SearchText"),
                                                        type: 'button'
                                                    }}
                                                    isRounded
                                                    onChange={(event) => getOnboardingAutoSuggestion(event, questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? "industry" : "job")}
                                                />
                                                {isAutoCompleteShowSectors && !isLoading &&
                                                    <ul className='onBoardingDropdown' ref={autopopulateRefSectors}>
                                                        {autoPopulateListSectors.length > 0 && autoPopulateListSectors?.map((autoComplete, idx) => {
                                                            return <li key={idx} onClick={(e) => selectHandlerSector(e, autoComplete.title, autoComplete.id, questions.QuestionId)}>
                                                                {autoComplete.title}
                                                            </li>
                                                        })}
                                                        {autoPopulateListSectors.length < 1 && <li>{fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound")}</li>}
                                                    </ul>
                                                }
                                                {isLoading && (
                                                    <div className='loading'>
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                    </div>
                                                )}
                                                <div className="selectedWrapper">
                                                    {uniqueSelectedListSectors.length > 0 && uniqueSelectedListSectors?.map((sectors, i) => {
                                                        return (
                                                            <div className="selectedBox_jobs" key={i}>
                                                                <div className="delete_button" onClick={() => removeSectorsnJobs(i, questions.QuestionId)}>
                                                                    <i className="icon-close "></i>
                                                                </div>
                                                                <span>{sectors.title}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        questions.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor"
                                        && (
                                            <div className="sector_select">
                                                <Cirrus.Input
                                                    placeholder={questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? fetchLabel("Common.JobSeekerQuestions.PreferredIndustry") : fetchLabel("Common.JobSeekerQuestions.PreferredJob")}
                                                    id="jobs"
                                                    icon="search"
                                                    className="mb-3"
                                                    name="jobs"
                                                    value={selectedItemJobs}
                                                    showSearchButton={{
                                                        children: fetchLabel("Common.JobSeekerQuestions.SearchText"),
                                                        type: 'button'
                                                    }}
                                                    isRounded
                                                    onChange={(event) => getOnboardingAutoSuggestion(event, questions.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? "industry" : "job")}
                                                />
                                                {isAutoCompleteShowJobs && !isLoading &&
                                                    <ul className='onBoardingDropdown' ref={autopopulateRefJobs}>
                                                        {autoPopulateListJobs.length > 0 && autoPopulateListJobs?.map((autoComplete, idx) => {
                                                            return <li key={idx} onClick={(e) => selectHandlerJobs(e, autoComplete.title, autoComplete.id, questions.QuestionId)}>
                                                                {autoComplete.title}
                                                            </li>
                                                        })}
                                                        {autoPopulateListJobs.length < 1 && <li>{fetchLabel("Common.JobSeekerQuestions.JobSeekerJobsNotFound")} </li>}
                                                    </ul>
                                                }
                                                {isLoading && (
                                                    <div className='loading'>
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                        <BusyLoading animation="grow" size='sm' variant='primary' />
                                                    </div>
                                                )}
                                                <div className="selectedWrapper">
                                                    {uniqueSelectedListJobs.length > 0 && uniqueSelectedListJobs?.map((jobs, i) => {
                                                        return (
                                                            <div className="selectedBox_jobs" key={i}>
                                                                <div className="delete_button" onClick={() => removeSectorsnJobs(i, questions.QuestionId)}>
                                                                    <i className="icon-close "></i>
                                                                </div>
                                                                <span>{jobs.title}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    }

                                    {questions.OptionType === "Options" && questions.QuestionDesignType === "MultiSelect" &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={
                                                questions.AnswerList.map((option) => {
                                                    return {
                                                        onChange: (e) => {
                                                            let chosen = selected;
                                                            if (e.target.checked) {
                                                                chosen.push(option.AnswerId);
                                                            } else {
                                                                chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                            }
                                                            if (questions.IsMandatory) {
                                                                if (chosen.length) {
                                                                    setIsEmptyAnswers(false);
                                                                } else {
                                                                    setIsEmptyAnswers(true);
                                                                }
                                                            }
                                                            setSelectedOptions(chosen.toString());
                                                            setSelectedQuestion(questions.QuestionId);
                                                        },
                                                        ...option,
                                                    };
                                                })
                                            }
                                        />
                                    }
                                    {questions.QuestionDesignType === "Single Select" &&
                                        <Cirrus.SingleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={
                                                questions.AnswerList.map((option, i) => {
                                                    return {
                                                        onChange: (e) => {
                                                            let chosen = selected;
                                                            if (e.target.checked) {
                                                                chosen = option.AnswerId;
                                                            }
                                                            if (questions.IsMandatory) {
                                                                if (chosen.length) {
                                                                    setIsEmptyAnswers(false);
                                                                } else {
                                                                    setIsEmptyAnswers(true);
                                                                }
                                                            }
                                                            setSelectedOptions(chosen.toString());
                                                            setSelectedQuestion(questions.QuestionId);
                                                        },
                                                        ...option,
                                                    };
                                                })
                                            }
                                        />
                                    }
                                    {isModal && (
                                        <Cirrus.AlertModal
                                            closeIconButtonProps={{
                                                icon: "close",
                                                isSecondary: true,
                                                onClick: modalClose,
                                                text: "Cancel",
                                                type: "button",
                                            }}
                                            heading={questionsContent.savePopup.Title}
                                            primaryButton={{
                                                children: fetchModalCMSLabel("OnboardingPage.SavePopup.SaveAndExit"),
                                                onClick: saveExitClick,
                                                size: "Large",
                                                type: "button",
                                            }}
                                            role="alertdialog"
                                            subheading={questionsContent.savePopup.Description}
                                        />
                                    )}
                                    {isEmptyAnswers && (
                                        <Cirrus.ErrorMessage>
                                            {fetchLabel()}
                                            {programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.MandatoryQuestionsMessage") : fetchLabel("Common.JobSeekerQuestions.MandatoryQuestionsMessage")}
                                        </Cirrus.ErrorMessage>
                                    )}
                                </Cirrus.Container>
                            )
                        })
                    }
                </Cirrus.FullHeightContainerLayout>
            </main>
            <div className="onboarding-questions-back-btn">
                <Cirrus.Button onClick={(e) => { nextPrev(e, -1) }}>back</Cirrus.Button>
            </div>
        </>
    )
}

export default Questionnairee;
