//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { OnBoardingActions } from "../onBoarding/OnBoardingActions";
import { Context } from "../../common/context/Context";
import { MyProfileActions } from "../myProfile/MyProfileActions";
import { AboutMeActions } from "./aboutMekActions";
import { Spinner as BusyLoading } from 'react-bootstrap';
import Spinner from "../../common/components/spinner-loader/Spinner";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";

function AboutMe() {
    const context = useContext(Context);

    const [multiModalProps, setMultiModalProps] = useState(undefined);
    const [singleModalProps, setSingleModalProps] = useState(undefined);
    let cmsQuestions;
    let isFirstTime = true;
    const [screenLoader, setScreenLoader] = useState(false);
    const [selectedState, setSelected] = useState([]);
    let selected = [];
    const [loadQuestions, setLoadQuestions] = useState([]);
    const [aboutMePageHeaderCMSContent, setAboutMePageHeaderCMSContent] =
        useState([]);
    const [questionsContent, setQuestionsContent] = useState({
        browserTitle: "",
        description: "",
        metaDescription: "",
        metadata: "",
        title: "",
        url: "",
        questionsList: [
            {
                QueryTitle: "",
                Answers: [
                    {
                        AnswerTitle: "",
                        AnswerId: "",
                    },
                ],
            },
        ],
        modalExitLabels: [],
    });

    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [performSave, setPerformSave] = useState(false);

    const [isAutoCompleteShow, setIsAutoCompleteShow] = useState(false)
    const [autoPopulateList, setAutoPopulateList] = useState([])
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedSectorsnJobs, setSelectedSectorsnJobs] = useState([]);
    const [noResponse, setNoResponse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [answerJobId, setAnswerJobId] = useState([])
    const autopopulateRef = useRef(null);
    const [answerSectorId, setAnswerSectorID] = useState([])
    const [jobAndIndustry, setJobAndIndustry] = useState(undefined);
    let autoCompleteSectorAnswers, autoCompleteJobAnswers;
    let programDetails = JSON.parse(sessionStorage.getItem("setProgramDetails"))
    let arrJob;
    let arrIndustry;

    const [industyAns, setIndustryAns] = useState([]);
    const [jobAns, setJobAns] = useState([]);
    const [enableSaveBtn, setEnableSaveBtn] = useState(false);
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        if (performSave === true) {
            let selectedQnAarray;
            if(selectedQuestion === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || selectedQuestion === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
                selectedQnAarray = [
                    {
                        questionShortName: selectedQuestion,
                        answerShortName: selectedOptions ? selectedOptions :
                            selectedQuestion === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? arrIndustry.join(",") : arrJob.join(",")
                    },
                ];
            } else {
                selectedQnAarray = [
                    {
                        questionShortName: selectedQuestion,
                        answerShortName: selectedOptions
                    },
                ];
            }
            OnBoardingActions.saveCandidatePreferences(candidateID, selectedQnAarray).then((res) => {
                setSelectedOptions();
                setSelectedQuestion();
                selected = [];
                setPerformSave(false);
                renderQuestions();
                isFirstTime = true;
                setSelectedSectorsnJobs([]);
            }).finally(() => {
                setSelectedSectorsnJobs([]);
                setSelectedOptions();
                setSelectedQuestion();
                selected = [];
                setPerformSave(false);
                isFirstTime = true;
            });
            setSingleModalProps(undefined);
            setMultiModalProps(undefined);
        }
        TrackActions.PageViewRecord(appInsights, "AboutMe")
        TrackActions.PageAction("AboutMe")
    }, [performSave]);

    const fetchCMSContent = async () => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        setScreenLoader(true);

        await OnBoardingActions.GetOnBoardingCMSContent({ PageName: "OnboardingPages" }).then((res) => {
            if (res.status === 200) {
                let resData = res?.data;
                context.setCmsOnBoadingContent(res.data);
                window.sessionStorage.setItem("setCmsOnBoadingContent", JSON.stringify(res.data))
                resData?.Pages?.filter((x) => {
                    if (x.Key === "QueryPage") {
                        refCmsContent.browserTitle = x.BrowserTitle;
                        refCmsContent.metaDescription = x.MetaDescription;
                        refCmsContent.metadata = x.Metadata;
                        refCmsContent.title = x.Title;
                        refCmsContent.questionsList = [];
                        let questionsObj;
                        if (programDetails?.persona === "Non-Executive" || "Executive") {
                            questionsObj = x.Components["CommonComponent"];
                            refCmsContent.commonLabels =
                                x.Components["CommonComponent"].Labels;
                            refCmsContent.componentKey =
                                x.Components["CommonComponent"].Key;
                        }
                        if (
                            programDetails?.persona === undefined ||
                            programDetails?.persona === null ||
                            programDetails?.persona === "Non-Executive"
                        ) {
                            questionsObj = x.Components["Common.JobSeekerQuestions"];
                            refCmsContent.labels =
                                x.Components["Common.JobSeekerQuestions"].Labels;
                            refCmsContent.componentKey =
                                x.Components["Common.JobSeekerQuestions"].Key;
                        } else if (
                            programDetails?.persona === "Executive"
                        ) {
                            questionsObj = x.Components["Common.ExecutiveQuestions"];
                            refCmsContent.labels =
                                x.Components["Common.ExecutiveQuestions"].Labels;
                            refCmsContent.componentKey =
                                x.Components["Common.ExecutiveQuestions"].Key;
                        }
                        questionsObj.QuestionList?.map((newArr, idx) => {
                            if (newArr.QuestionTitle) {
                                let newanswers = [];
                                let labelsArr = [];
                                newArr.AnswerList?.map((answers, id) => {
                                    if (newArr.QuestionDesignType === "MultiSelect") {
                                        newanswers.push({
                                            ...answers,
                                            id: id,
                                            name: answers.AnswerText.replace(/ /g, ""),
                                            label: answers.AnswerText,
                                            value: answers.AnswerText,
                                        });
                                    } else if (newArr.QuestionDesignType === "Single Select") {
                                        newanswers.push({
                                            ...answers,
                                            id: id,
                                            name: "radio",
                                            labelText: answers.AnswerText,
                                            value: answers.AnswerText,
                                        });
                                    }
                                });
                                newArr.Labels?.map((labels) => {
                                    labelsArr.push(labels.Phrase);
                                });
                                refCmsContent.questionsList.push({
                                    ...newArr,
                                    Answers: newanswers,
                                    Labels: labelsArr,
                                });
                            }
                            if (newArr.Title === "Do this later") {
                                refCmsContent.modalExitLabels = newArr;
                            }
                        });
                    }
                });
                setQuestionsContent(refCmsContent);
                setScreenLoader(false)
            } else {
                console.log(res)
                setScreenLoader(false)
            }
        }, err => {
            console.log(err)
            setScreenLoader(false)
        })
    };

    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        let label = "";
        if (refCmsContent?.labels?.length > 0) {
            refCmsContent?.labels?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchCommonLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(questionsContent));
        let label = "";
        if (refCmsContent?.commonLabels?.length > 0) {
            refCmsContent?.commonLabels?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    useEffect(() => {
        if (questionsContent.browserTitle !== "") renderQuestions();
    }, [questionsContent]);

    const loadSavedQuestions = async (e) => {
        try {
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let canID = candidateID ? candidateID : "";
            let questionsJson = [];
            if (canID != "") {
                await OnBoardingActions.getCandidatePreferences(candidateID).then(async res => {
                    if (res.status === 200 && res?.data.length > 0) {
                        questionsJson = res.data;
                        let filterSectorQuestion = questionsJson.find(filt => filt?.questionShortName === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn")
                        if (filterSectorQuestion) {
                            let sectorAnswerIds = filterSectorQuestion?.answerShortName?.split(',')
                            if (sectorAnswerIds) {
                                autoCompleteSectorAnswers = await getAnsLists(sectorAnswerIds)
                            }
                        }
                        let filterJobsQuestion = questionsJson.find(filt => filt?.questionShortName === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor")
                        if (filterJobsQuestion) {
                            let jobAnswerIds = filterJobsQuestion.answerShortName.split(',')
                            if (jobAnswerIds) {
                                autoCompleteJobAnswers = await getAnsLists(jobAnswerIds)
                            }
                        }
                    }
                }, err => {
                    console.log(err);
                })
            } else {
                questionsJson = [];
            }
            return questionsJson;
        } catch (error) {
            console.log(error);
            return {};
        }
    };

    const getAnsLists = async (ids) => {
        let locale = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        let autoCompleteAnswers;
        try {
            const payload = {
                locale: [locale?.programLanguageCode],
                key: [],
                id: ids
            }
            const res = await OnBoardingActions.GetOnboardingQuestionsList(payload)
            if (res?.data) {
                let resData = res?.data
                let title = [];
                let saved = [];
                resData?.map(data => {
                    data?.items?.map(item => {
                        title.push(item.title)
                        saved.push({ title: item.title, id: item.id })
                    })
                })
                autoCompleteAnswers = saved;
            }
            return autoCompleteAnswers;
        } catch (err) {
            console.log(err)
        }
    }

    const fnLoadPersonalInfoData = async () => {
        try {
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let Profilejson = {};
            if (candidateID != "") {
                const res = await MyProfileActions.GetcandidateProfile(candidateID);
                if (res.status === 200) {
                    Profilejson = res.data;
                } else {
                    Profilejson = {};
                }
            } else {
                Profilejson = {};
            }
            return Profilejson;
        } catch (error) {
            return {};
        }
    };

    const renderQuestions = async () => {
        let savedQuestions = await loadSavedQuestions();
        let questionsArr = [];
        if (savedQuestions.length >= 1) {
            let updatedQuestions = questionsContent.questionsList?.map((questions) => {
                let result = savedQuestions.filter((data) => data.questionShortName === questions.QuestionId);
                let answerShortName = result[0]?.answerShortName != null ? result[0]?.answerShortName.split(",") : "";
                if (result.length > 0 && result[0].answerShortName !== "") {
                    answerShortName && answerShortName?.map((answerShortName) => {
                        questions.Answers?.map((options) => {
                            if (answerShortName == options.AnswerId) {
                                let question = questionsArr.filter((qDesc) => qDesc.QueryId == questions.QuestionId);
                                if (question.length) {
                                    let optionsArr = [];
                                    question[0].options.push(options.AnswerText);
                                    optionsArr.push(question[0].options);
                                } else {
                                    questionsArr.push({
                                        desc: questions.QuestionTitle,
                                        options: [options.AnswerText],
                                        questionShortName: result.questionShortName,
                                        QueryId: questions.QuestionId,
                                    });
                                }
                            }
                        });
                    });
                } else {
                    questionsArr.push({
                        desc: questions.QuestionTitle,
                        options: [],
                        questionShortName: questions.QuestionId,
                        QueryId: questions.QuestionId,
                    });
                }

                if (questions?.QuestionId == "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
                    let sectorData = [];
                    autoCompleteSectorAnswers?.map((val) => {
                        answerShortName?.map((value) => {
                            if (value == val.id) {
                                sectorData = [...sectorData, val.title];
                            }
                        })
                    })
                    if (answerShortName?.length > 0) {
                        questionsArr.push(
                            {
                                desc: questions.QuestionTitle,
                                options: sectorData.length > 0 ? sectorData : answerShortName,
                                questionShortName: questions.QuestionId,
                                QueryId: questions.QuestionId
                            }
                        )
                    }

                }

                if (questions?.QuestionId == "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
                    let JobData = [];
                    autoCompleteJobAnswers?.map((val) => {
                        answerShortName?.map((value) => {
                            if (value == val.id) {
                                JobData = [...JobData, val.title];
                            }
                        })
                    })
                    if (answerShortName?.length > 0) {
                        questionsArr.push(
                            {
                                desc: questions.QuestionTitle,
                                options: JobData.length > 0 ? JobData : answerShortName,
                                questionShortName: questions.QuestionId,
                                QueryId: questions.QuestionId
                            }
                        )
                    }
                }

                const ans = questionsArr?.filter(ans => {
                    if (ans.QueryId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn") {
                        const industryAnswers = ans.options?.map(ansInd => {
                            const answers = {
                                title: ansInd.length > 1 ? ansInd : null
                            }
                            return answers
                        })

                        setIndustryAns(industryAnswers)
                    } else if (ans.QueryId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
                        const jobAnswers = ans.options?.map(ansInd => {
                            const answers = {
                                title: ansInd.length > 1 ? ansInd : null
                            }
                            return answers
                        })

                        setJobAns(jobAnswers)
                    }
                })

                return { ...questions, AnswerList: questionsArr };
            });
            let tempArray = [];
            questionsArr.forEach((element) => {
                let answer = questionsContent.questionsList.filter((data) => data.QuestionId === element.QueryId)[0];
                if (answer?.Answers[0]?.labelText) {
                    answer.Answers.forEach((element) => {
                        element["labelText"] = element["labelText"];
                    });
                }
                let Answers = answer.Answers;
                tempArray.push({
                    answers: element.options?.toString().length > 1 ? element.options.filter(x => x != '') : [],
                    deleteButtonText: "Delete",
                    editButtonText: "Edit",
                    isMultipleChoice: answer.QuestionDesignType === "Single Select" ? false : true,
                    label: element.desc,
                    noAnswerText: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.PleaseSelect") : fetchLabel("Common.JobSeekerQuestions.PleaseSelect"),
                    onDeleteAnswerClick: () => { },
                    onEditButtonClick: () => {
                        answer.QuestionDesignType === "Single Select"
                            ? setSingleModalProps({
                                heading: element.desc,
                                subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectOneOption"),
                                savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                options: Answers?.map((option, i) => {
                                    return {
                                        onChange: (e) => {
                                            let chosen = selected;
                                            if (e.target.checked) {
                                                chosen = option.AnswerId;
                                            }
                                            setSelectedOptions(chosen.toString());
                                            setSelectedQuestion(element.QueryId);
                                        },
                                        ...option,
                                        defaultChecked:
                                            element.options.indexOf(option.AnswerText) > -1,
                                    };
                                }),
                                closeButtonProps: {
                                    children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                    size: "large",
                                    type: "button",
                                    isSecondary: true,
                                    onClick: () => {
                                        setSingleModalProps(undefined);
                                    },
                                },
                                onSaveClick: () => {
                                    setPerformSave(true);
                                },
                            })
                            : answer.OptionType === "Search" && answer.QuestionId !== "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" && answer.QuestionId !== "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" ?
                                setMultiModalProps({
                                    heading: element.desc,
                                    subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectAllThatApply"),
                                    savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                    options: Answers?.map((option, i) => {
                                        return {
                                            onChange: (e) => {
                                                let chosen = selected;

                                                if (isFirstTime) {
                                                    if (element.options.length > 0) {
                                                        let answeredQuestions = [];
                                                        element.options.forEach((elementData) => {
                                                            answeredQuestions.push(Answers?.filter((data) => data.AnswerText === elementData)[0]?.AnswerId);
                                                        });
                                                        chosen = answeredQuestions;
                                                    } else {
                                                        chosen = selected;
                                                    }
                                                    isFirstTime = false;
                                                }
                                                if (e.target.checked) {
                                                    if (chosen.indexOf(option.AnswerId) === -1)
                                                        chosen.push(option.AnswerId);
                                                } else {
                                                    chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                }

                                                selected = chosen;
                                                selected = chosen.includes("JobSeekerQuestions.PreferToLearnNewThings.NoPreference") ? chosen.filter((val) => (val !== "JobSeekerQuestions.PreferToLearnNewThings.AllOfTheAbove")) : chosen


                                                setSelectedOptions(selected.toString());

                                                setSelectedQuestion(element.QueryId);
                                            },
                                            ...option,
                                            defaultChecked: element.options.indexOf(option.AnswerText) > -1,
                                        };
                                    }),
                                    searchInput: {
                                        hideLabel: true,
                                        icon: "search",
                                        id: "search",
                                        isRounded: true,
                                        label: "search",
                                        name: "search",
                                        onChange: (event) => {
                                            let updatedList = [...Answers];
                                            updatedList = updatedList.filter((option) => {
                                                return (!event.target.value || option.AnswerText.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0);
                                            });

                                            let elem;
                                            let optionsContainer = document.querySelectorAll(".modal-parent fieldset .option-item")
                                            optionsContainer.forEach(ele => ele.style.display = "none")

                                            if (updatedList.length) {
                                                updatedList?.map((option) => {
                                                    optionsContainer.forEach(ele => {
                                                        elem = ele.querySelector('input')
                                                        if (option.value === elem.value) {
                                                            ele.style.display = "block"
                                                        } else {
                                                            updatedList = [...Answers]
                                                        }
                                                    })
                                                })
                                            }
                                            else {
                                                updatedList = [...Answers]
                                                optionsContainer.forEach(ele => ele.style.display = "block")
                                            }
                                            Answers = updatedList;
                                        },
                                        placeholder: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.SearchHere") : fetchLabel("Common.JobSeekerQuestions.SearchHereText"),
                                        type: "text",
                                    },
                                    closeButtonProps: {
                                        children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                        size: "large",
                                        type: "button",
                                        isSecondary: true,
                                        onClick: () => {
                                            setMultiModalProps(undefined);
                                        },
                                    },
                                    onSaveClick: () => {
                                        setPerformSave(true);
                                    },
                                })
                                : answer.OptionType === "Search" && (answer.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || answer.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") ?
                                    getJobnIndsutryDetails(element?.desc, element?.QueryId)
                                    : setMultiModalProps({
                                        heading: element.desc,
                                        subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectAllThatApply"),
                                        savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                        options: Answers?.map((option, i) => {
                                            return {
                                                onChange: (e) => {
                                                    let chosen = selected;
                                                    if (selected.length <= 0) {
                                                        if (element.options.length > 0) {
                                                            let answeredQuestions = [];
                                                            element.options.forEach((elementData) => {
                                                                answeredQuestions.push(
                                                                    Answers.filter(
                                                                        (data) => data.AnswerText === elementData
                                                                    )[0]?.AnswerId
                                                                );
                                                            });
                                                            selected = answeredQuestions;
                                                            chosen = selected;
                                                        } else {
                                                            chosen = selected;
                                                        }
                                                    }
                                                    if (e.target.checked) {
                                                        if (chosen.indexOf(option.AnswerId) === -1)
                                                            chosen.push(option.AnswerId);
                                                    } else {
                                                        chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                    }

                                                    setSelected(chosen);
                                                    setSelectedOptions(chosen.toString());
                                                    setSelectedQuestion(element.QueryId);
                                                },
                                                ...option,
                                                defaultChecked:
                                                    element.options.indexOf(option.AnswerText) > -1,
                                            };
                                        }),
                                        closeButtonProps: {
                                            children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                            size: "large",
                                            type: "button",
                                            isSecondary: true,
                                            onClick: () => {
                                                setMultiModalProps(undefined);
                                            },
                                        },
                                        onSaveClick: () => {
                                            setPerformSave(true);
                                        },
                                    });
                    },
                });
            });
            let uniqueTempArray = [...tempArray?.reduce((map, obj) => map.set(obj.label, obj), new Map()).values()];
            setLoadQuestions(uniqueTempArray)
        } else {
            questionsContent.questionsList?.map((questions) => {
                if (true) {
                    questionsArr.push({
                        desc: questions.QuestionTitle,
                        options: [],
                        QueryId: questions.QuestionId,
                    });
                }
            });

            let tempArray = [];
            questionsArr.forEach((element, index) => {
                let answer = questionsContent.questionsList.filter(
                    (data) => data.QuestionId === element.QueryId
                )[0];

                let Answers = answer.Answers;
                tempArray.push({
                    answers: element.options?.toString().length > 1 ? element.options : [],
                    deleteButtonText: "Delete",
                    editButtonText: "Edit",
                    isMultipleChoice: answer.QuestionDesignType === "Single Select" ? false : true,
                    label: element.desc,
                    noAnswerText: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.PleaseSelect") : fetchLabel("Common.JobSeekerQuestions.PleaseSelect"),
                    onDeleteAnswerClick: () => { },
                    onEditButtonClick: () => {
                        answer.QuestionDesignType === "Single Select"
                            ? setSingleModalProps({
                                heading: element.desc,
                                subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectOneOption"),
                                savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                options: Answers?.map((option, i) => {
                                    return {
                                        onChange: (e) => {
                                            let chosen = selected;
                                            if (e.target.checked) {
                                                chosen = option.AnswerId;
                                            }
                                            setSelectedOptions(chosen.toString());
                                            setSelectedQuestion(element.QueryId);
                                        },
                                        ...option,
                                        defaultChecked:
                                            element.options.indexOf(option.AnswerText) > -1,
                                    };
                                }),
                                closeButtonProps: {
                                    children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                    size: "large",
                                    type: "button",
                                    isSecondary: true,
                                    onClick: () => {
                                        setSingleModalProps(undefined);
                                    },
                                },
                                onSaveClick: () => {
                                    setPerformSave(true);
                                },
                            })
                            : answer.OptionType === "Search" && answer.QuestionId !== "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" && answer.QuestionId !== "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor"
                                ? setMultiModalProps({
                                    heading: element.desc,
                                    subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectAllThatApply"),
                                    savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                    options: Answers?.map((option, i) => {
                                        return {
                                            onChange: (e) => {
                                                let chosen = selected;
                                                if (selected.length <= 0) {
                                                    if (element.options.length > 0) {
                                                        let answeredQuestions = [];
                                                        element.options.forEach((elementData) => {
                                                            answeredQuestions.push(
                                                                Answers.filter(
                                                                    (data) => data.AnswerText === elementData
                                                                )[0]?.AnswerId
                                                            );
                                                        });
                                                        chosen = answeredQuestions;
                                                    } else {
                                                        chosen = selected;
                                                    }
                                                }
                                                if (e.target.checked) {
                                                    if (chosen.indexOf(option.AnswerId) === -1)
                                                        chosen.push(option.AnswerId);
                                                } else {
                                                    chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                }

                                                setSelected(chosen);
                                                setSelectedOptions(chosen.toString());
                                                setSelectedQuestion(element.QueryId);
                                            },

                                            ...option,
                                            defaultChecked:
                                                element.options.indexOf(option.AnswerText) > -1,
                                        };
                                    }),
                                    searchInput: {
                                        hideLabel: true,
                                        icon: "search",
                                        id: "search",
                                        isRounded: true,
                                        label: "search",
                                        name: "search",
                                        onChange: (event) => {
                                            let updatedList = [...Answers];
                                            updatedList = updatedList.filter((option) => {
                                                return (
                                                    !event.target.value ||
                                                    option.AnswerText.toLowerCase().indexOf(
                                                        event.target.value.toLowerCase()
                                                    ) >= 0
                                                );
                                            });
                                            Answers = updatedList;
                                        },
                                        placeholder: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.SearchHere") : fetchLabel("Common.JobSeekerQuestions.SearchHereText"),
                                        type: "text",
                                    },
                                    closeButtonProps: {
                                        children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                        size: "large",
                                        type: "button",
                                        isSecondary: true,
                                        onClick: () => {
                                            setMultiModalProps(undefined);
                                        },
                                    },
                                    onSaveClick: () => {
                                        setPerformSave(true);
                                    },
                                }) : answer.OptionType === "Search" && answer.QuestionId === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || answer.QuestionId === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" ? getJobnIndsutryDetails(element?.desc, element?.QueryId)
                                    : setMultiModalProps({
                                        heading: element.desc,
                                        subheading: fetchLabel("Common.JobSeekerQuestions.PleaseSelectAllThatApply"),
                                        savebutton: fetchCommonLabel("CommonComponent.CommonSaveButton"),
                                        options: Answers?.map((option, i) => {
                                            return {
                                                onChange: (e) => {
                                                    let chosen = selected;
                                                    if (selected.length <= 0) {
                                                        if (element.options.length > 0) {
                                                            let answeredQuestions = [];
                                                            element.options.forEach((elementData) => {
                                                                answeredQuestions.push(
                                                                    Answers.filter(
                                                                        (data) => data.AnswerText === elementData
                                                                    )[0]?.AnswerId
                                                                );
                                                            });
                                                            chosen = answeredQuestions;
                                                        } else {
                                                            chosen = selected;
                                                        }
                                                    }
                                                    if (e.target.checked) {
                                                        if (chosen.indexOf(option.AnswerId) === -1)
                                                            chosen.push(option.AnswerId);
                                                    } else {
                                                        chosen.splice(chosen.indexOf(option.AnswerId), 1);
                                                    }

                                                    setSelected(chosen);
                                                    setSelectedOptions(chosen.toString());
                                                    setSelectedQuestion(element.QueryId);
                                                },
                                                ...option,
                                                defaultChecked:
                                                    element.options.indexOf(option.AnswerText) > -1,
                                            };
                                        }),
                                        closeButtonProps: {
                                            children: programDetails?.persona === "Executive" ? fetchLabel("Common.ExecutiveQuestions.Exit") : fetchLabel("Common.JobSeekerQuestions.Exit"),
                                            size: "large",
                                            type: "button",
                                            isSecondary: true,
                                            onClick: () => {
                                                setMultiModalProps(undefined);
                                            },
                                        },
                                        onSaveClick: () => {
                                            setPerformSave(true);
                                        },
                                    });
                    },
                });
            });

            setLoadQuestions(tempArray);
        }
        setScreenLoader(false)
    };

    useEffect(async () => {
        window.scrollTo(0, 0)
        fetchCMSContent();
        fnLoadMyProfileCSMData();
    }, [context.language]);

    const fnLoadMyProfileCSMData = async () => {
        try {
            let cmsAboutMepayload = {
                PageName: "AboutMe"
            };

            const res = await AboutMeActions.GetCMSAboutMeHeaderLabels(
                cmsAboutMepayload
            );
            if (res.status === 200) {
                const filtedHeaderData = res?.data?.Pages?.filter(
                    (data) => data.Key === "AboutMePage"
                );
                setAboutMePageHeaderCMSContent(filtedHeaderData);
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    function useAutoCompleteOutsideClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideAutoComplete(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsAutoCompleteShow(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideAutoComplete);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideAutoComplete);
            };
        }, [ref]);
    }
    useAutoCompleteOutsideClick(autopopulateRef);

    const getOnboardingAutoSuggestion = (event, key) => {
        const input = event.target.value
        let payload = {
            "SearchKey": input.toLowerCase()
        }
        setSelectedItem(input)
        if (input.length >= 3) {
            setIsLoading(true)
            OnBoardingActions.GetOnboardingQuestions(key, payload).then(res => {
                if (res.status === 200) {
                    setNoResponse(false)
                    setAutoPopulateList(res?.data)
                    setIsAutoCompleteShow(true)
                    setIsLoading(false)
                } else if (res.status === 204) {
                    setNoResponse(true)
                    setAutoPopulateList("")
                    setIsLoading(false)
                    setIsAutoCompleteShow(true)
                } else {
                    console.log(res)
                }
            }, err => {
                console.log(err)
            })
        }
    }

    const selectHandlerIndustry = (title, id, questionId) => {

        setSelectedItem(title)
        setSelectedSectorsnJobs([...selectedSectorsnJobs, { title: title, id: id }])
        setSelectedItem('');
        setSelectedQuestion(questionId)
        setIsAutoCompleteShow(false);
    }

    let uniqueSelectedList = [...selectedSectorsnJobs?.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];

    arrJob = [...uniqueSelectedList, ...jobAns]?.map((x) => x.title);
    arrIndustry = [...uniqueSelectedList, ...industyAns]?.map((x) => x.title);

    const removeSectorsnJobs = (index) => {
        const filterdJobs = [...uniqueSelectedList].filter((job, i) => {
            return i !== index
        })

        setSelectedSectorsnJobs(filterdJobs)
    }

    const removeJobs = (index) => {
        const filterJobAns = [...jobAns].filter((jobAns, i) => {
            return i !== index;
        })

        setEnableSaveBtn(true)
        setJobAns(filterJobAns)
    }

    const removeSector = (index) => {
        const filterIndustryAns = [...industyAns].filter((indAns, i) => {
            return i !== index;
        })

        setEnableSaveBtn(true)
        setIndustryAns(filterIndustryAns)
    }

    const getJobnIndsutryDetails = (desc, queryID, key) => {
        setJobAndIndustry({
            children: desc,
            size: 'h4',
            className: 'mt-5',
            key: queryID
        })
        setSelectedQuestion(queryID);

        let selectedOpts = uniqueSelectedList?.map(data => { return data.id }).toString()
        if (queryID === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" || queryID === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor") {
            let savePreferences = [{
                "questionShortName": selectedQuestion,
                "answerShortName": selectedOpts
            }];
        }
    }

    const onCloseHandler = () => {
        setJobAndIndustry(undefined)
        uniqueSelectedList = [];
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner spinnerTimer={screenLoader} />
            </div>
        );
    }


    return (
        <>
            {/* <HeaderWithNavigation /> */}
            <Cirrus.PageBanner
                bodyText={aboutMePageHeaderCMSContent[0]?.Description}
                heading={aboutMePageHeaderCMSContent[0]?.Title}
                className='aboutme-banner'
            />
            <Cirrus.OneColumnLayout type="wrapped">
                <>
                    <div style={{ padding: "10px" }}>
                        <Cirrus.AboutMePanel questionProps={loadQuestions} />
                    </div>
                </>
            </Cirrus.OneColumnLayout>
            {multiModalProps != undefined && (
                <div className="modal-parent">
                    <Cirrus.MultipleSelectQuestionsModal {...multiModalProps} />
                </div>
            )}
            {singleModalProps != undefined && (
                <div className="modal-parent single-select">
                    <Cirrus.SingleSelectQuestionsModal {...singleModalProps} className='single-select' />
                </div>
            )}

            {jobAndIndustry !== undefined && (
                <div className="aboutme_jobsnSectorModal">
                    <Cirrus.Modal
                        headerProps={jobAndIndustry}
                        subHeadingProps={fetchLabel("Common.JobSeekerQuestions.PleaseSelectAllThatApply")}
                        className='aboutme_jobModal'
                        closeButtonProps={{
                            children: fetchLabel("Common.JobSeekerQuestions.Exit"),
                            isSecondary: true,
                            onClick: () => { onCloseHandler() },
                            size: 'Small',
                            type: 'button'
                        }}
                    >
                        <div className="sector_select">
                            <Cirrus.Input
                                // placeholder="Search"
                                placeholder={jobAndIndustry.key === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? fetchLabel("Common.JobSeekerQuestions.PreferredIndustry") : fetchLabel("Common.JobSeekerQuestions.PreferredJob")}
                                id="sectors"
                                icon="search"
                                className="mb-3 ms-4"
                                name="sectors"
                                value={selectedItem}
                                showSearchButton={{
                                    children: 'Search',
                                    type: 'button'
                                }}
                                isRounded
                                onChange={(event) => getOnboardingAutoSuggestion(event, jobAndIndustry.key === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? "industry" : "job")}
                            />
                            {isAutoCompleteShow && !isLoading &&
                                <ul className='onBoardingDropdown aboutme_searchlist' ref={autopopulateRef}>
                                    {autoPopulateList.length > 0 && autoPopulateList?.map((autoComplete, idx) => {
                                        return <li key={idx} onClick={() => selectHandlerIndustry(autoComplete.title, autoComplete.id, jobAndIndustry.key)}>
                                            {autoComplete.title}
                                        </li>
                                    })}
                                    {autoPopulateList.length < 1 && <li>{jobAndIndustry.key === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ? fetchLabel("Common.JobSeekerQuestions.JobSeekerNoMatchFound"): fetchLabel("Common.JobSeekerQuestions.JobSeekerJobsNotFound") }</li>}
                                </ul>
                            }
                            {isLoading && (
                                <div className='loading'>
                                    <BusyLoading animation="grow" size='sm' variant='primary' />
                                    <BusyLoading animation="grow" size='sm' variant='primary' />
                                    <BusyLoading animation="grow" size='sm' variant='primary' />
                                </div>
                            )}
                            {/* {isAutoCompleteShow && noResponse && !isLoading && (<span className="norecords" ref={autopopulateRef}>no match found, enter a different key word</span>)} */}
                            <div className="selectedWrapper ms-4">
                                {uniqueSelectedList.length > 0 && uniqueSelectedList?.map((sectors, i) => {
                                    return (
                                        <div className="selectedBox_jobs sectorsNJob" key={i}>
                                            <div className="delete_button" onClick={() => removeSectorsnJobs(i)}>
                                                <i className="icon-close "></i>
                                            </div>
                                            <span>{sectors.title}</span>
                                        </div>
                                    )
                                })}

                                {jobAndIndustry.key === "JobSeekerQuestions.WhatSectorsAreYouInterestedIn" ?
                                    industyAns?.length > 0 && industyAns?.filter(ind => ind.title != null)?.map((indAns, i) => {
                                        return (
                                            <div className="selectedBox_jobs industry" key={i}>
                                                <div className="delete_button" onClick={() => removeSector(i)}>
                                                    <i className="icon-close "></i>
                                                </div>
                                                <span>{indAns.title}</span>
                                            </div>
                                        )
                                    }) : jobAndIndustry.key === "JobSeekerQuestions.WhatTypeOfJobsAreyouLookingFor" ?
                                        jobAns?.length > 0 && jobAns?.filter(job => job.title != null)?.map((jobAns, i) => {
                                            return (
                                                <div className="selectedBox_jobs jobs" key={i}>
                                                    <div className="delete_button" onClick={() => removeJobs(i)}>
                                                        <i className="icon-close "></i>
                                                    </div>
                                                    <span>{jobAns.title}</span>
                                                </div>
                                            )
                                        }) : null
                                }
                            </div>
                            <div className="text-center" onClick={() => setPerformSave(true)}>
                                <Cirrus.Button
                                    className="save_button"
                                    onClick={() => onCloseHandler()}
                                    disabled={uniqueSelectedList?.length > 0 || enableSaveBtn ? false : true}
                                >
                                    {fetchCommonLabel("CommonComponent.CommonSaveButton")}
                                </Cirrus.Button>
                            </div>
                        </div>
                    </Cirrus.Modal>
                </div>
            )}

            {/* <Footer isLoggedIn={true} /> */}
        </>
    );
}

export default AboutMe;
