//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Row, Col, Container } from "react-bootstrap";

import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import sideimage from "./sideimage.png";
import { AssessmentActions } from "../AssessmentActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import "../style.css";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function EvaluateJobOffer() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [startPageData, setStartPageData] = useState();
    const [IYIPStatus, setIYIPStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [assment, getassement] = useState();
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));

    useEffect(() => {
        fetchCMSData();
        window.scrollTo(0, 0)
    }, [context.language]);

    const fetchCMSData = async () => {
        setScreenLoader(true);
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let startPageDataAPICall = false;

        let payload = {
            PageName: "EvaluateAJobOffer"
        };
        await AssessmentActions.GetAssessmentCMS(payload).then(
            (res) => {
                if (res.status === 200) {
                    startPageDataAPICall = true;
                    let resData = res.data;
                    ActivityAction.SaveActivity(resData?.ID, "startassessment", resData?.Title)
                    TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                    TrackActions.PageAction("ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                    let label = [...resData?.Components?.CommonComponent?.Labels];
                    label = [
                        ...label,
                        ...resData?.Components?.CommonResourceComponent?.Labels,
                        ...resData?.Components["Assessments.EvaluateAJobOffer.CommonLabels"]
                            ?.Labels,
                    ];
                    resData?.Components?.CommonComponent?.LOVList[0]?.Values?.map((x) => {
                        let obj = {
                            Key: x.Key,
                            Phrase: x.Value,
                        };
                        label.push(obj);
                    });

                    resData?.ResourcePages?.map((assCard) => {
                        if (assCard.Key === "Assessments.IdentifyYourIdealPosition") {
                            refCmsMasterContent["assessmentIYIP"] = assCard;
                        }
                    });

                    let refactorPage = {
                        ID: resData?.ID,
                        SubTitle: resData.SubTitle,
                        Title: resData?.Title,
                        ShortDescription: resData?.ShortDescription,
                        Description: resData?.Description,
                        FocusArea: resData?.FocusArea[0],
                        ResourceCategoryType: resData?.ResourceCategoryType,
                        ResourceType: resData?.ResourceType,
                        Duration: resData?.Duration,
                        QuestionPages: [],
                        OtherPages: [],
                        LargeImage: resData?.LargeImage?.ImageUrl
                    };
                    resData?.ChildPages?.map((page) => {
                        if (page?.Key === "Assessments.EvaluateAJobOffer.AboutTheRole") {
                            label = [
                                ...label,
                                ...page?.Components[
                                    "Assessments.EvaluateAJobOffer.AboutTheRole"
                                ]?.Labels,
                            ];

                            let qList = [];
                            page?.Components[
                                "Assessments.EvaluateAJobOffer.AboutTheRole"
                            ]?.Labels?.map((item) => {
                                if (
                                    item.Key ===
                                    "Assessments.EvaluateAJobOffer.AboutTheRole.SelectAnAppliedJobFrom"
                                ) {
                                    qList.push({
                                        Key: item.Key,
                                        QuestionTitle: item.Phrase,
                                        IsSelected: "",
                                        ButtonLabel: page?.Components[
                                            "Assessments.EvaluateAJobOffer.AboutTheRole"
                                        ]?.Labels?.filter(
                                            (lebel) =>
                                                lebel.Key ===
                                                "Assessments.EvaluateAJobOffer.AboutTheRole.SelectJob"
                                        ),
                                    });
                                }
                                if (
                                    item.Key ===
                                    "Assessments.EvaluateAJobOffer.AboutTheRole.AboutTheOfferReceived"
                                ) {
                                    qList.push({
                                        Key: item.Key,
                                        QuestionTitle: item.Phrase,
                                        IsSelected: "",
                                        ButtonLabel: page?.Components[
                                            "Assessments.EvaluateAJobOffer.AboutTheRole"
                                        ]?.Labels?.filter(
                                            (lebel) =>
                                                lebel.Key ===
                                                "Assessments.EvaluateAJobOffer.AboutTheRole.FillInDetails"
                                        ),
                                    });
                                }
                            });
                            page["OptionList"] = qList;

                            refactorPage["AboutTheRole"] = page;
                        }
                        if (
                            page?.Key === "Assessments.EvaluateAJobOffer.SelectAnAppliedJob"
                        ) {
                            label = [
                                ...label,
                                ...page?.Components[
                                    "Assessments.EvaluateAJobOffer.SelectAnAppliedJob"
                                ]?.Labels,
                            ];
                            refactorPage["SelectAnAppliedJob"] = page;
                        }
                        if (page?.Key === "Assessments.EvaluateAJobOffer.AboutTheOffer") {
                            let refactorQuestions = {
                                Key: page?.Key,
                                Title: page?.Title,
                                Description: page?.Description,
                                ID: page?.ID,
                                Skills: page?.Skills,
                                questions: [],
                            };
                            page?.Components[
                                "Resources.Assessments.EvaluateAJobOffer.AboutTheOffer"
                            ]?.QuestionList?.map((que) => {
                                refactorQuestions.questions.push({
                                    QuestionTitle: que?.QuestionTitle,
                                    QuestionId: que?.QuestionId,
                                    OptionType: que?.OptionType,
                                    Answer: "",
                                });
                            });
                            refactorPage["AboutTheOffer"] = refactorQuestions;
                        }
                        if (
                            page?.Key ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        ) {
                            let refactorQuestions = {
                                Key: page?.Key,
                                Title: page?.Title,
                                Description: page?.Description,
                                ID: page?.ID,
                                Skills: page?.Skills,
                                questions: [],
                            };
                            page?.Components[
                                "Resources.Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                            ]?.QuestionList?.map((que) => {
                                let question = {
                                    QuestionTitle: que?.QuestionTitle,
                                    QuestionId: que?.QuestionId,
                                    QuestionDesignType: que?.QuestionDesignType,
                                    OptionType: que?.OptionType,
                                    AnswerList: [],
                                };
                                que?.AnswerList?.map((ans) => {
                                    question.AnswerList.push({
                                        AnswerText: ans?.AnswerText,
                                        AnswerId: ans?.AnswerId,
                                        IsSelected: false,
                                        ResultPageTitle: ans?.ResultPageTitle,
                                    });
                                });
                                refactorQuestions.questions.push(question);
                            });
                            refactorPage.QuestionPages.push(refactorQuestions);
                        }
                        if (
                            page.Key ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        ) {
                            let refactorQuestions = {
                                Key: page?.Key,
                                Title: page?.Title,
                                Description: page?.Description,
                                ID: page?.ID,
                                Skills: page?.Skills,
                                questions: [],
                            };
                            page?.Components[
                                "Resources.Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                            ]?.QuestionList?.map((que) => {
                                let question = {
                                    QuestionTitle: que?.QuestionTitle,
                                    QuestionId: que?.QuestionId,
                                    QuestionDesignType: que?.QuestionDesignType,
                                    OptionType: que?.OptionType,
                                    AnswerList: [],
                                };
                                que?.AnswerList?.map((ans) => {
                                    question.AnswerList.push({
                                        AnswerText: ans?.AnswerText,
                                        AnswerId: ans?.AnswerId,
                                        IsSelected: false,
                                        ResultPageTitle: ans?.ResultPageTitle,
                                    });
                                });
                                refactorQuestions.questions.push(question);
                            });
                            refactorPage.QuestionPages.push(refactorQuestions);
                        }
                        if (
                            page.Key === "Assessments.IdentifyYourIdealPosition.Compensation"
                        ) {
                            let refactorQuestions = {
                                Key: page?.Key,
                                Title: page?.Title,
                                Description: page?.Description,
                                ID: page?.ID,
                                Skills: page?.Skills,
                                questions: [],
                            };
                            page?.Components[
                                "Resources.Assessments.IdentifyYourIdealPosition.Compensation"
                            ]?.QuestionList?.map((que) => {
                                let question = {
                                    QuestionTitle: que?.QuestionTitle,
                                    QuestionId: que?.QuestionId,
                                    QuestionDesignType: que?.QuestionDesignType,
                                    OptionType: que?.OptionType,
                                    AnswerList: [],
                                };
                                que?.AnswerList?.map((ans) => {
                                    question.AnswerList.push({
                                        AnswerText: ans?.AnswerText,
                                        AnswerId: ans?.AnswerId,
                                        IsSelected: false,
                                        ResultPageTitle: ans?.ResultPageTitle,
                                    });
                                });
                                refactorQuestions.questions.push(question);
                            });
                            refactorPage.QuestionPages.push(refactorQuestions);
                        }
                        if (
                            page.Key ===
                            "Assessments.IdentifyYourIdealPosition.AssessmentComplete"
                        ) {
                            label = [
                                ...label,
                                ...page?.Components["IdentifyIdealPositionAssessmentComplete"]
                                    ?.Labels,
                            ];
                            refCmsMasterContent["assessmentComplete"] = page;
                        }
                    });

                    refCmsMasterContent["assessmentEJO"] = refactorPage;
                    refCmsMasterContent["masterLabel"] = label;
                    setCmsMasterContent(refCmsMasterContent);


                    context.setCmsEvaluateAJobOffer({
                        masterCMS: resData,
                        masterLabel: label,
                        assessmentEJO: refCmsMasterContent["assessmentEJO"],
                        assessmentIYIP: refCmsMasterContent["assessmentIYIP"],
                        assessmentComplete: refCmsMasterContent["assessmentComplete"],
                    });
                    
                    setScreenLoader(false)
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );

        if (startPageDataAPICall) {
            let payload = {
                learningResourceType: "assessmentsandtasks",
                recommended: "false",
                isAllLearningResources: "true",
                ResourceCategory: "assessmentsandtasks"
            };
            await AssessmentActions.GetStartPageData(payload).then(
                (res) => {
                    if (res.status === 200) {
                        let data = res?.data && res?.data?.responsemodel;

                        let ejoStatus = data?.filter(
                            (x) => x.key === "Assessments.EvaluateAJobOffer"
                        );
                        let iyipStatus = data?.filter(
                            (x) => x.key === "Assessments.IdentifyYourIdealPosition"
                        );
                        setStartPageData(ejoStatus && ejoStatus[0] && ejoStatus[0]);
                        setIYIPStatus(iyipStatus && iyipStatus[0] && iyipStatus[0])
                        startPageDataAPICall = false
                        setScreenLoader(false);
                    } else {
                        setScreenLoader(false);
                    }
                },
                (err) => {
                    console.log(err);
                    setScreenLoader(false);
                }
            );
        }





    };




    const saveAndLikeHandler = async (type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": cmsMasterContent?.assessmentEJO.ID,
            "learningResourceStatus": startPageData?.learningResourceStatus,
            "learningResourceType": cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID,
            "resourceTypeId": cmsMasterContent?.assessmentEJO?.ResourceType?.ID,
            "isSaved":
                type === "save"
                    ? startPageData?.isSaved == true
                        ? false
                        : true
                    : startPageData?.isSaved,
            "isLiked":
                type === "like"
                    ? startPageData?.isLiked == true
                        ? false
                        : true
                    : startPageData?.isLiked,
            "lastAnsweredPageId": startPageData?.lastAnsweredPageId,
            "isDownloaded": startPageData?.isDownloaded,
            "isSharedWithCoach": startPageData?.isSharedWithCoach,
            "resultDocumentId": startPageData?.resultDocumentId
        };
        AssessmentActions.SaveAssessmentStatus(
            cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID,
            cmsMasterContent?.assessmentEJO?.ID,
            payload
        ).then(
            (res) => {
                if (res.status === 200) {
                    AssessmentActions.GetAssessmentStatusAndSavedResponseById(
                        cmsMasterContent?.assessmentEJO?.ID
                    ).then(
                        (res) => {
                            if (res.status === 200) {
                                setIsSaved(res.data.isSaved);
                                setIsLiked(res.data.isLiked);
                                setStartPageData(res.data);
                            }
                        },
                        (err) => {
                            console.log(err);
                            setScreenLoader(false);
                        }
                    );
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    };

    const startRetakContinueHandler = async (assmentFlag) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setScreenLoader(true)
        if (assmentFlag === "IYIP") {
            window.sessionStorage.setItem("EJO-IYIP-Redirection-Flag", true)
            if (IYIPStatus?.learningResourceStatus?.toLowerCase() === fetchLabel("ResourceStatus.Completed").toLowerCase()) {
                await AssessmentActions.RetakeAssessment(cmsMasterContent?.assessmentIYIP?.ID).then(res => {
                    if (res.status === 200) {
                        TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentIYIP?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentIYIP?.Title })
                        TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentIYIP?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentIYIP?.Title })
                        setScreenLoader(false);
                        navigate("/" + pageOrgin.id + "/identify-your-ideal-position");
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false);
                })
            } else {
                let payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsMasterContent?.assessmentIYIP?.ID,
                    "learningResourceStatus": 'inprogress',
                    "learningResourceType": cmsMasterContent?.assessmentIYIP?.ResourceCategoryType?.ID,
                    "resourceTypeId": cmsMasterContent?.assessmentIYIP?.ResourceType?.ID,
                    "isSaved": IYIPStatus?.isSaved,
                    "isLiked": IYIPStatus?.isLiked,
                    "isRetaken": IYIPStatus?.isRetaken,
                    "lastAnsweredPageId": IYIPStatus?.lastAnsweredPageId,

                    "isDownloaded": IYIPStatus?.isDownloaded,
                    "isSharedWithCoach": IYIPStatus?.isSharedWithCoach,
                    "resultDocumentId": IYIPStatus?.resultDocumentId
                }
                await AssessmentActions.SaveAssessmentStatus(cmsMasterContent?.assessmentIYIP?.ResourceCategoryType?.ID, cmsMasterContent?.assessmentIYIP?.ID, payload).then((res) => {
                    if (res.status === 200) {
                        TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentIYIP?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentIYIP?.Title })
                        TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentIYIP?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentIYIP?.Title })
                        setScreenLoader(false)
                        navigate("/" + pageOrgin.id + "/identify-your-ideal-position");
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false)
                })
            }
        } else {
            if (startPageData?.learningResourceStatus?.toLowerCase() === fetchLabel("ResourceStatus.Completed").toLowerCase()) {
                await AssessmentActions.EJORetakeAssessment(cmsMasterContent?.assessmentEJO?.ID).then(res => {
                    if (res.status === 200) {
                        TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentEJO?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentEJO?.Title })
                        TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentEJO?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentEJO?.Title })
                        setScreenLoader(false);
                        navigate("/" + pageOrgin.id + "/evaluate-a-job-offer/start");
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false)
                })
            } else {
                let payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsMasterContent?.assessmentEJO?.ID,
                    "learningResourceStatus": 'inprogress',
                    "learningResourceType": cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID,
                    "resourceTypeId": cmsMasterContent?.assessmentEJO?.ResourceType?.ID,
                    "isSaved": isSaved,
                    "isLiked": isLiked,
                    "isRetaken": startPageData?.isRetaken,
                    "lastAnsweredPageId": startPageData?.lastAnsweredPageId,
                    "isDownloaded": startPageData?.isDownloaded,
                    "isSharedWithCoach": startPageData?.isSharedWithCoach,
                    "resultDocumentId": startPageData?.resultDocumentId
                }
                await AssessmentActions.SaveAssessmentStatus(cmsMasterContent?.assessmentEJO?.ResourceCategoryType?.ID, cmsMasterContent?.assessmentEJO?.ID, payload).then((res) => {
                    if (res.status === 200) {
                        TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentEJO?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentEJO?.Title })
                        TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsMasterContent?.assessmentEJO?.ID, "InternalAssessmentName": cmsMasterContent?.assessmentEJO?.Title })
                        setScreenLoader(false)
                        navigate("/" + pageOrgin.id + "/evaluate-a-job-offer/start");
                    } else {
                        setScreenLoader(false);
                    }
                }, err => {
                    console.log(err);
                    setScreenLoader(false)
                })
            }
        }

    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = "";
        if (refCmsMasterContent?.masterLabel) {
            refCmsMasterContent?.masterLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchButtonStatus = (status) => {
        if (status !== undefined && status !== '') {
            if (status?.toLowerCase() === 'inprogress') {
                return (
                    <>
                        {fetchLabel("CommonComponent.CommonContinueButton")}
                    </>
                )
            } else if (status?.toLowerCase() === 'completed') {
                return (
                    <>
                        {fetchLabel("CommonComponentCommonRetakeButton")}
                    </>
                )
            } else {
                return (
                    <>
                        {fetchLabel("CommonResourceComponent.GetStartedButton")}
                    </>
                )
            }
        } else {
            return (
                <>
                    {fetchLabel("CommonResourceComponent.GetStartedButton")}
                </>
            )
        }
    }

    const isRedirectHandler = () => {
        window.sessionStorage.setItem("EJO-IYIP-Redirection-Flag", true)
        navigate('/' + pageOrgin.id + '/identify-your-ideal-position/result')
    }


    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }


    return (
        <div className="assessment-page">
            {/* <Cirrus.Page
                className="assessment-page"
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            <Container
                fluid
                className="background-White p-md-5 p-lg-5 pb-lg-0 pb-md-0 p-3 custom-mb-20"
            >
                <Row className='justify-content-between'>
                    <Col md="12" lg="7" className="mb-3 pb-lg-5">
                        <Cirrus.Button
                            isSecondary
                            onClick={() => { navigate(`/${context.path ? context.path : path}`) }}
                            className="mb-lg-5 mb-3"
                        >
                            {fetchLabel("CommonComponent.CommonBackButton")}
                        </Cirrus.Button>
                        <Cirrus.Header size="h3">
                            {cmsMasterContent?.assessmentEJO?.Title}
                        </Cirrus.Header>
                        <div className="save-section mt-4 mb-3">
                            <button
                                className="saveBtn"
                                onClick={() => {
                                    saveAndLikeHandler("save");
                                }}
                            >
                                {startPageData?.isSaved === true ? (
                                    <i className="icon-saved"></i>
                                ) : (
                                    <i className="icon-save"></i>
                                )}
                                <span className="saveBtnText">
                                    {startPageData?.isSaved === true
                                        ? fetchLabel("CommonResourceComponent.Saved")
                                        : fetchLabel("CommonComponent.CommonSaveButton")}
                                </span>
                            </button>
                            <Cirrus.Label id="category-label">
                                {cmsMasterContent?.assessmentEJO?.FocusArea?.Value}
                            </Cirrus.Label>
                            <Cirrus.Label id="cardtype">
                                {cmsMasterContent?.assessmentEJO?.ResourceType?.Value}{" "}
                                <sup>. </sup>
                                <span>{parseInt(cmsMasterContent?.assessmentEJO?.Duration.split(" ")[0]) + parseInt(cmsMasterContent?.assessmentIYIP?.Duration.split(" ")[0]) + " " + cmsMasterContent?.assessmentEJO?.Duration.split(" ")[1]}</span>
                            </Cirrus.Label>
                        </div>
                        <div>
                            <Cirrus.Button
                                size="small"
                                className="likeBtn"
                                onClick={() => {
                                    saveAndLikeHandler("like");
                                }}
                            >
                                {startPageData?.isLiked === true ? (
                                    <Icon icon="heart_fill" size={16} color="#9D323D" />
                                ) : (
                                    <Icon icon="heart" size={16} color="#000" />
                                )}
                                <span className="likeBtnText">
                                    {
                                        startPageData?.likeCount === 0 ? " " + fetchLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                            startPageData?.likeCount === 1 ? startPageData?.likeCount + " " + fetchLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                                startPageData?.likeCount + " " + fetchLabel("CommonComponent.CommonLikesText").toUpperCase()
                                    }
                                    {/* {startPageData?.likeCount} */}
                                    {/* {fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()} */}
                                </span>
                            </Cirrus.Button>
                        </div>
                    </Col>
                    <Col md="12" lg="5" className="position-relative">
                        <div className='assesment_startpage-image-wrapper'>
                            <img src={cmsMasterContent.assessmentEJO?.LargeImage} alt='sideimage' className='image-overflow' />
                        </div>
                        {/* <img src={sideimage} alt="sideimage" className="image-overflow" /> */}
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-md-5 p-lg-5 pb-lg-0 pb-md-0 p-3">
                <Row>
                    <Col md="12" lg="6" className="evaluate-Job-container">
                        <Cirrus.Headline size="Medium" className="mb-5">
                            {/* <Cirrus.RichText content={cmsMasterContent?.assessmentEJO?.ShortDescription} /> */}
                            {cmsMasterContent?.assessmentEJO?.ShortDescription}
                        </Cirrus.Headline>
                        {/* <p className='mb-5'>
                                <Cirrus.RichText content={cmsSA?.description} />
                            </p> */}
                    </Col>
                    <Col md="6" lg="6"></Col>
                </Row>
            </Container>

            <Container fluid className="px-lg-5 p-3">
                <Row>
                    <Col lg={6}>
                        <Row className="mb-3 card-row">
                            <Col xs={2} lg={1} className={"card-status" + (IYIPStatus?.learningResourceStatus === undefined ? "" : IYIPStatus?.learningResourceStatus?.toLowerCase() === 'inprogress' ? " inprogress" : IYIPStatus?.learningResourceStatus?.toLowerCase() === 'completed' ? " completed" : "")}>
                                <span>
                                    <i className="icon-check"></i>
                                </span>
                            </Col>
                            <Col xs={10} lg={11}>
                                <div className="card-style">
                                    <div className="title">
                                        {cmsMasterContent?.assessmentIYIP?.Title}
                                    </div>
                                    <p className="mb-2">
                                        <Cirrus.RichText
                                            content={fetchLabel("Assessments.EvaluateAJobOffer.CommonLabels.IdentityIdealPositionDescription")}
                                        />
                                    </p>
                                    <div className="cardFooter">
                                        <div className="button-wrap">
                                            <Cirrus.Button isSecondary className="mb-5" onClick={() => startRetakContinueHandler('IYIP')}>
                                                {fetchButtonStatus(IYIPStatus?.learningResourceStatus)}
                                            </Cirrus.Button>
                                            {
                                                IYIPStatus?.learningResourceStatus?.toLowerCase() === 'completed' && <Cirrus.Button isSecondary className="mb-5" onClick={isRedirectHandler}>{fetchLabel("CommonResourceComponent.ViewResults")}</Cirrus.Button>
                                            }
                                        </div>
                                        <Cirrus.Label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Icon icon="clock-icon" size={20} />
                                                <span style={{ marginLeft: "7px" }}>
                                                    {cmsMasterContent?.assessmentIYIP?.Duration}
                                                </span>
                                            </span>
                                        </Cirrus.Label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3 card-row">
                            <Col xs={2} lg={1} className={"card-status" + (startPageData?.learningResourceStatus === undefined ? "" : startPageData?.learningResourceStatus?.toLowerCase() === 'inprogress' ? " inprogress" : startPageData?.learningResourceStatus?.toLowerCase() === 'completed' ? " completed" : "")}>
                                <span>
                                    <i className="icon-check"></i>
                                </span>
                            </Col>
                            <Col xs={10} lg={11}>
                                <div className={"card-style" + (IYIPStatus?.learningResourceStatus === undefined ? " card-disabled" : IYIPStatus?.learningResourceStatus?.toLowerCase() !== 'completed' ? " card-disabled" : "")}>
                                    <div className="title">
                                        {cmsMasterContent?.assessmentEJO?.Title}
                                    </div>
                                    <p className="mb-2">
                                        <Cirrus.RichText
                                            content={
                                                cmsMasterContent?.assessmentEJO?.ShortDescription
                                            }
                                        />
                                    </p>
                                    <div className="cardFooter">
                                        <div className="button-wrap">
                                            {
                                                IYIPStatus?.learningResourceStatus?.toLowerCase() === 'completed' && <>
                                                    <Cirrus.Button
                                                        isSecondary
                                                        className="mb-5"
                                                        onClick={() => {
                                                            startRetakContinueHandler('EJO')
                                                        }}
                                                    >
                                                        {fetchButtonStatus(startPageData?.learningResourceStatus)}
                                                    </Cirrus.Button>
                                                    {
                                                        startPageData?.learningResourceStatus?.toLowerCase() === 'completed' && <Cirrus.Button isSecondary className="mb-5" onClick={() => { navigate('/' + pageOrgin.id + '/evaluate-a-job-offer/result') }}>{fetchLabel("CommonResourceComponent.ViewResults")}</Cirrus.Button>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <Cirrus.Label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Icon icon="clock-icon" size={20} />
                                                <span style={{ marginLeft: "7px" }}>
                                                    {cmsMasterContent?.assessmentEJO?.Duration}
                                                </span>
                                            </span>
                                        </Cirrus.Label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    );
}

export default EvaluateJobOffer;
