//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import IYIPpdfTemplate from './pdfTemplate';



const IYIPAssessment = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [percentage, setPercentage] = useState(0);
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
    const [assessmentStatus, setAssessmentStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [dropDownDefaultValue, setDropDownDefaultValue] = useState();

    const [pdfResponse, setPdfResponse] = useState([]);

    const userAgent = window.navigator.userAgent;
    let browser = '';
    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    useEffect(() => {
    }, [cmsAssessmentContent])

    useEffect(() => {
        window.scrollTo(0, 0)
        getAssessmentStatusWithAnswers();
    }, [context.language])

    const fetchCMSData = () => {
        let resData = context.cmsIdentifyYourIdealPositionContent;
        let assessments = resData?.assessmentQuestionSet?.map(assessment => {
            let quest = assessment.QuestionList.map(question => {
                let opts = question.AnswerList.map(options => {
                    if (question.OptionType !== "Dropdown") {
                        return ({ ...options, defaultChecked: false })
                    } else {
                        return (options)
                    }
                })
                return ({ ...question, AnswerList: opts })
            })
            return ({ ...assessment, QuestionList: quest })
        })
        Object.keys(resData).forEach(key => {
            if (key === "assessmentQuestionSet") {
                resData[key] = assessments;
            }
        })
        return resData;
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = context.cmsIdentifyYourIdealPositionContent;
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsIdentifyYourIdealPositionContent;
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsIdentifyYourIdealPositionContent;
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const getAssessmentStatusWithAnswers = () => {
        AssessmentActions.GetAssessmentStatusAndSavedResponseById(context.cmsIdentifyYourIdealPositionContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                setAssessmentStatus(res.data);
                setIsSaved(res.data.isSaved);
                setIsLiked(res.data.isLiked);
                let cmsData = fetchCMSData();
                let savedResponses = res.data.learningResourceAssessmentResponseModels;
                if (savedResponses.length) {
                    savedResponses.map(savedResponse => {
                        let QuestionId = savedResponse.questionId;
                        let AnswerId = savedResponse.answerValue != "" ? savedResponse.answerValue.split(",") : "";
                        let updatedOptions;
                        cmsData?.assessmentQuestionSet?.map(assessment => {
                            assessment.QuestionList.map(question => {
                                if (question.QuestionId === QuestionId) {
                                    if (AnswerId.length) {
                                        AnswerId.map(answerid => {
                                            updatedOptions = question.AnswerList.map(options => {
                                                if (options.AnswerId === answerid) {
                                                    if (question.OptionType !== "Dropdown") {
                                                        Object.keys(options).forEach(key => {
                                                            if (key === "defaultChecked") {
                                                                options[key] = true;
                                                            }
                                                        })
                                                    }
                                                    else {
                                                        setDropDownDefaultValue(answerid);
                                                    }
                                                }
                                            })
                                        })
                                    }
                                }
                            })
                        })
                    })
                    setCmsAssessmentContent(cmsData)
                } else {
                    setCmsAssessmentContent(cmsData)
                }
                let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                if (res.data.lastAnsweredPageId) {
                    if (parseInt(res.data.lastAnsweredPageId) < assessmentQuestion.length) {
                        setCurrentQuestion(currentQuestion + parseInt(res.data.lastAnsweredPageId));
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId)].style.display = "block";
                        let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / assessmentQuestion.length) * 100);
                        setPercentage(percentageCalc);
                    } else {
                        setCurrentQuestion(parseInt(res.data.lastAnsweredPageId) - 1);
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId) - 1].style.display = "block";
                        let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / assessmentQuestion.length) * 100);
                        setPercentage(percentageCalc);
                    }
                } else {
                    assessmentQuestion[currentQuestion].style.display = "flex";
                }
            }
        }, err => {
            console.log(err);
        })
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n < assessmentQuestion.length) {
            window.scrollTo(0, 0)
            assessmentQuestion[n].style.display = "block";
        }
        if (n == assessmentQuestion.length) {
            assessmentQuestion[assessmentQuestion.length - 1].style.display = "block";
            renderSavedAssessment()
        }
    }

    const nextPrev = (e, n) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        let payload;
        if (n > 0) {
            setLoadSpinner(true);
            if (currentQuestion === assessmentQuestion.length - 1) {
                payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsAssessmentContent.internalAssessmentId,
                    "learningResourceType": cmsAssessmentContent.resourceType.Value,
                    "learningResourceStatus": fetchStatusID("ResourceStatus.Completed"),
                    "isSaved": isSaved,
                    "isLiked": isLiked,
                    "lastAnsweredPageId": ((currentQuestion + n) <= assessmentQuestion.length ? (currentQuestion + n) : currentQuestion),
                    "isDownloaded": assessmentStatus?.isDownloaded,
                    "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                    "resultDocumentId": assessmentStatus?.resultDocumentId
                }
            } else {
                payload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsAssessmentContent.internalAssessmentId,
                    "learningResourceType": cmsAssessmentContent.resourceCategoryType.ID,
                    "resourceTypeId": cmsAssessmentContent.resourceType.ID,
                    "learningResourceStatus": assessmentStatus.learningResourceStatus,
                    "isSaved": isSaved,
                    "isLiked": isLiked,
                    "lastAnsweredPageId": ((currentQuestion + n) <= assessmentQuestion.length ? (currentQuestion + n) : currentQuestion),
                    "isDownloaded": assessmentStatus?.isDownloaded,
                    "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                    "resultDocumentId": assessmentStatus?.resultDocumentId
                }
            }

            AssessmentActions.SaveAssessmentStatus(cmsAssessmentContent.resourceCategoryType.ID, cmsAssessmentContent.internalAssessmentId, payload).then((res) => {
                if (res.status === 200) {

                    let percentageCalc = Math.ceil(((currentQuestion + n) / assessmentQuestion.length) * 100);
                    if (percentageCalc >= percentage) {
                        setPercentage(percentageCalc);
                    }

                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    if (currentQuestion < 0) {
                        setCurrentQuestion(0);
                    }
                    showQuestion(currentQuestion + n);
                    setLoadSpinner(false);
                } else {
                    setLoadSpinner(false);
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        } else {
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setPercentage(percentage);
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const removePreviousSelection = (e) => {
        let options = e.target.closest('.options').childNodes;
        options.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove("active")
            }
        });
    }

    const saveAnswers = (assessmentId, questionId, answersId, sectionTitle, sectionID, pageNumber) => {
        let payload = [{
            "AssessementId": assessmentId,
            "AssessementType": "Internal",
            "CategoryType": sectionID,
            "CategoryValue": sectionTitle,
            "QuestionId": questionId,
            "AnswerType": "id",
            "AnswerValue": answersId
        }]

        AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {

            }
        }, err => {
            console.log(err);
        })
    }


    // PDF Template functions
    const fetchResultQuestionTitle = (key) => {
        let refCmsAssessmentContent = context.cmsIdentifyYourIdealPositionContent.resultPage.Components.IdentifyIdealPositionAssessmentComplete;
        let label = '';
        if (refCmsAssessmentContent?.Labels) {
            refCmsAssessmentContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const getSavedAssessmentResponses = async () => {
        let assessmentId = context.cmsIdentifyYourIdealPositionContent.internalAssessmentId;
        let res = await AssessmentActions.GetSavedResponseByAssessmentId(assessmentId);
        return res.data;
    }

    const renderSavedAssessment = async () => {
        setLoadSpinner(true);
        let savedResponses = await getSavedAssessmentResponses();
        let cmsContent = context.cmsIdentifyYourIdealPositionContent;
        let arrResponse = [], unSortedOrg = [], unSortedJob = [], unSortedComp = [];
        if (savedResponses.length) {
            savedResponses?.map(savedResponse => {
                let QuestionId = savedResponse.questionId;
                let AnswerId = savedResponse.answerValue != "" ? savedResponse.answerValue.split(",") : "";
                cmsContent?.assessmentQuestionSet.map(assessment => {
                    assessment.QuestionList.map((question, i) => {
                        if (question.QuestionId === QuestionId) {
                            if (AnswerId.length) {
                                AnswerId.map(answerid => {
                                    question.AnswerList.map(options => {
                                        if (options.AnswerId === answerid) {
                                            let quest;
                                            if (assessment.Key === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") {
                                                unSortedOrg.push({ index: i, answertext: options.AnswerText })
                                                let sortedAnswers = unSortedOrg.sort((a, b) => a.index - b.index).map(option => option.answertext)
                                                quest = arrResponse.filter(qID => qID.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation")
                                                if (quest.length) {
                                                    let answer = [];
                                                    quest[0].Answer = sortedAnswers;
                                                    answer.push(quest[0].Answer)
                                                } else {
                                                    arrResponse.push({ Question: assessment.Title, Answer: sortedAnswers, PageId: assessment.Key });
                                                }
                                            }
                                            else if (assessment.Key === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole") {
                                                let sortedAnswers;
                                                if (options.ResultPageTitle !== "" && options.AnswerText !== "Somewhat" && options.AnswerText !== "Not at all") {
                                                    if (options.ResultPageTitle !== "") {
                                                        unSortedJob.push({ index: i, answertext: options.IsQuestionTitleAppended == true ? options.ResultPageTitle + ' ' + question.QuestionTitle : options.ResultPageTitle })

                                                        sortedAnswers = unSortedJob.sort((a, b) => a.index - b.index).map(option => option.answertext)
                                                    }
                                                    quest = arrResponse.filter(qID => qID.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole")
                                                    if (quest.length) {
                                                        let answer = [];
                                                        quest[0].Answer = sortedAnswers;
                                                        answer.push(quest[0].Answer)
                                                    } else {
                                                        if (options.ResultPageTitle !== "") {
                                                            arrResponse.push({
                                                                Question: fetchResultQuestionTitle("IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealJobRole"),
                                                                Answer: sortedAnswers,
                                                                PageId: assessment.Key
                                                            });
                                                        }
                                                    }
                                                }
                                            } else if (assessment.Key === "Assessments.IdentifyYourIdealPosition.Compensation") {
                                                let sortedAnswers;
                                                if (options.AnswerText !== "Not important" && options.AnswerText !== "Nice to have") {
                                                    if (options.ResultPageTitle !== "") {
                                                        unSortedComp.push({ index: i, answertext: options.IsQuestionTitleAppended == true ? options.ResultPageTitle + ' ' + question.QuestionTitle : options.ResultPageTitle })
                                                        sortedAnswers = unSortedComp.sort((a, b) => a.index - b.index).map(option => option.answertext)
                                                    }
                                                    quest = arrResponse.filter(qID => qID.PageId === "Assessments.IdentifyYourIdealPosition.Compensation")
                                                    if (quest.length) {
                                                        let answer = [];
                                                        quest[0].Answer = sortedAnswers;
                                                        answer.push(quest[0].Answer)
                                                    } else {
                                                        arrResponse.push({
                                                            Question: fetchResultQuestionTitle("IdentifyIdealPosition.AssessmentComplete.AssessmentCompleteIdealCompensation"),
                                                            Answer: sortedAnswers,
                                                            PageId: assessment.Key
                                                        });
                                                    }
                                                }

                                            }
                                        }
                                    })
                                })
                            }
                        }
                    })
                })
            })
            let tempArrResponse = []
            if (arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") !== undefined) {
                tempArrResponse.push(arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"))
            }
            if (arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole") !== undefined) {
                tempArrResponse.push(arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"))
            }
            if (arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.Compensation") !== undefined) {
                tempArrResponse.push(arrResponse.find(resp => resp.PageId === "Assessments.IdentifyYourIdealPosition.Compensation"))
            }
            arrResponse = tempArrResponse
            setPdfResponse(arrResponse);
            setLoadSpinner(false);
            downloadPDF();
        }
    }

    const downloadPDF = () => {
        let content = document.getElementById('assessment-result-wrapper');
        setLoadSpinner(true);

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;

            const pdf = new jsPDF({ orientation: 'l', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', 20, yPosition + 60, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                
                let file = new File([pdf.output('blob')], context.cmsIdentifyYourIdealPositionContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");

                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    let docId;
                    res.data.map(doc => {
                        docId = doc.id
                    })
                    updateAssessmentStatus(docId)
                    setLoadSpinner(false);
                }, err => {
                    console.log(err);
                    setLoadSpinner(false);
                });
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();

                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }

                        let file = new File([pdf.output('blob')], context.cmsIdentifyYourIdealPositionContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");

                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            let docId;
                            res.data.map(doc => {
                                docId = doc.id
                            })
                            updateAssessmentStatus(docId)
                            setLoadSpinner(false);
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        });
                    }
                })
            }
        })
    }

    const updateAssessmentStatus = (docId) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": assessmentStatus.learningResourceId,
            "learningResourceStatus": fetchStatusID("ResourceStatus.Completed"),
            "learningResourceType": context.cmsIdentifyYourIdealPositionContent?.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsIdentifyYourIdealPositionContent?.resourceType?.ID,
            "isSaved": isSaved,
            "isLiked": isLiked,
            "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": docId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsIdentifyYourIdealPositionContent.resourceCategoryType?.ID, assessmentStatus.learningResourceId, payload).then((res) => {
            if (res.status === 200) {
                navigate("/" + pageOrgin.id + "/identify-your-ideal-position/result");
            }
        }, err => {
            console.log(err);
        })
    }

    const isRedirectHandler = () => {
        sessionStorage.removeItem("EJO-IYIP-Redirection-Flag");
        navigate("/" + pageOrgin.id + "/evaluate-a-job-offer")
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page iyip-page">
                <Container className='pt-lg-4 pt-2 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            {
                                currentQuestion == 0 ?
                                    <Cirrus.Button isSecondary onClick={(e) => navigate("/" + pageOrgin.id + "/identify-your-ideal-position")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                    :
                                    <Cirrus.Button isSecondary onClick={(e) => { nextPrev(e, -1) }}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                            }
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            {
                                JSON.parse(window.sessionStorage.getItem("EJO-IYIP-Redirection-Flag")) === true ? <>
                                    <Cirrus.Button onClick={isRedirectHandler}>
                                        {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
                                    </Cirrus.Button>
                                </> : <>
                                    <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
                                        {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
                                    </Cirrus.Button>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
                {
                    cmsAssessmentContent?.assessmentQuestionSet?.map((assessment, idx) => {
                        return (
                            <Container className='assessment-question-wrapper mb-5' key={idx}>
                                {
                                    loadSpinner &&
                                    <div className='loader'>
                                        <Cirrus.Spinner size={50} />
                                    </div>
                                }
                                <Row>
                                    <Col lg={12} className="assessment-question mb-3">
                                        <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                        <p className='mb-5 mx-auto about-role_question'>{assessment.Description}</p>
                                        <div className='text-start'>
                                            {assessment.QuestionList.map((question, qIdx) => {
                                                /* Condition for Single and MultiSelect Questions */
                                                let chosen = [];
                                                if (question.OptionType === "Options") {
                                                    return (
                                                        <div
                                                            className={assessment.Key === "Assessments.IdentifyYourIdealPosition.AboutTheJobRole" ?
                                                                "iyip-questionSection mb-2 border-bottom py-3 single-row-qna"
                                                                :
                                                                "iyip-questionSection mb-2 border-bottom py-3"}
                                                        >
                                                            <div className='iyip-questionTitle mb-2' key={qIdx}>
                                                                {question.QuestionTitle}
                                                            </div>
                                                            <div
                                                                className={assessment.Key === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation" ?
                                                                    "iyip-options options equal-width-options px-lg-3 px-0"
                                                                    :
                                                                    "iyip-options options"}
                                                            >
                                                                {question.AnswerList.map((options, optIdx) => {
                                                                    /* For Single Select Radio */
                                                                    if (question.QuestionDesignType === "Single Select") {
                                                                        if (assessment.Key === "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") {
                                                                            return (
                                                                                <Cirrus.RadioButton key={optIdx}
                                                                                    labelText={options.AnswerText}
                                                                                    name={question.QuestionId}
                                                                                    value={options.AnswerId}
                                                                                    defaultChecked={options.defaultChecked}
                                                                                    onChange={(e) => {
                                                                                        chosen = [];
                                                                                        if (e.target.checked) {
                                                                                            chosen.push(options.AnswerId)
                                                                                        }
                                                                                        saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, chosen.toString(), assessment.Title, assessment.ID, idx);
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        /* For Single Select Radio in Button format */
                                                                        else {
                                                                            return (
                                                                                <label key={optIdx}
                                                                                    className={options.defaultChecked === true ? "custom-radio-button active" : "custom-radio-button"}
                                                                                >
                                                                                    <input type="radio"
                                                                                        name={question.QuestionId}
                                                                                        value={options.AnswerText}
                                                                                        assessmentid={cmsAssessmentContent.internalAssessmentId}
                                                                                        questionid={question.QuestionId}
                                                                                        answerid={options.AnswerId}
                                                                                        defaultChecked={options.defaultChecked}
                                                                                        onChange={(e) => {
                                                                                            removePreviousSelection(e)
                                                                                            chosen = [];
                                                                                            if (e.target.checked) {
                                                                                                chosen.push(options.AnswerId)
                                                                                                e.target.parentNode.classList.add("active");
                                                                                            } else {
                                                                                                e.target.parentNode.classList.remove("active");
                                                                                            }
                                                                                            saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, chosen.toString(), assessment.Title, assessment.ID, idx);
                                                                                        }}
                                                                                    />
                                                                                    {options.AnswerText}
                                                                                </label>
                                                                            )
                                                                        }
                                                                    }
                                                                    /* For Multi Select Checkbox */
                                                                    else if (question.QuestionDesignType === "MultiSelect") {
                                                                        return (
                                                                            <Cirrus.Checkbox removeBorder key={optIdx}
                                                                                label={options.AnswerText}
                                                                                name={options.AnswerText}
                                                                                value={options.AnswerId}
                                                                                questionid={question.QuestionId}
                                                                                defaultChecked={options.defaultChecked}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        chosen.push(options.AnswerId)
                                                                                    } else {
                                                                                        chosen.splice(chosen.indexOf(options.AnswerId), 1);
                                                                                    }
                                                                                    saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, chosen.toString(), assessment.Title, assessment.ID, idx);
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                } else { /* For Select Dropdown */
                                                    return (
                                                        <div className='iyip-questionSection mb-2 border-bottom py-3'>
                                                            <div className='iyip-questionTitle mb-2' key={qIdx}>
                                                                {question.QuestionTitle}
                                                            </div>
                                                            <div className='iyip-options'>
                                                                <Cirrus.Select
                                                                    id={question.QuestionId}
                                                                    name={question.QuestionTitle}
                                                                    placeholder="-- Please select --"
                                                                    aria-label="select"
                                                                    defaultValue={dropDownDefaultValue}
                                                                    options={
                                                                        question.AnswerList.map((options, optIdx) => {
                                                                            return { ...options, value: options.AnswerId, name: options.AnswerText }
                                                                        })
                                                                    }
                                                                    onChange={(e) => {
                                                                        saveAnswers(cmsAssessmentContent.internalAssessmentId, question.QuestionId, e.target.value, assessment.Title, assessment.ID, idx);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    document.getElementsByClassName("assessment-question-wrapper").length - 1 !== idx &&
                                    <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>
                                        {fetchCommonLabel("CommonComponent.CommonContinueButton")}
                                    </Cirrus.Button>
                                }
                                {
                                    document.getElementsByClassName("assessment-question-wrapper").length - 1 === idx &&
                                    <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>
                                        {fetchCommonLabel("CommonComponent.CommonCompleteButton")}
                                    </Cirrus.Button>
                                }
                            </Container>
                        )
                    })
                }
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}

            {/* <label className='custom-radio-checkbox'>
                <input type="radio" name={question.QuestionId} />
                <span>
                    <i className='icon-check'></i>
                </span>
                {options.AnswerText}
            </label> */}
            <div style={{ opacity: '0', overflow:'hidden', position: 'relative' }}>
                <div id='assessment-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1', display: 'block', width: '1300px' }}>
                    <IYIPpdfTemplate getResponse={pdfResponse}></IYIPpdfTemplate>
                </div>
            </div>
        </>
    )
}

export default IYIPAssessment;
