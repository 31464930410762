//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../../common/context/Context";
import { AssessmentActions } from "../AssessmentActions";
import { Row, Col, Container } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { coachActions } from "../../coach/coachActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";

const IYIPResult = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [cmsAssessmentContent, setCmsAssessmentContent] = useState({});
  const [getResponse, setGetResponse] = useState([]);
  const [assessmentStatus, setAssessmentStatus] = useState();
  const [coachdata, setcoachdata] = useState();
  const [updatedData, setUpdatedData] = useState();

  useEffect(() => {
    fetchCMSData();
    getMyCoach();
    window.scrollTo(0, 0);
  }, [context.language]);

  const getSavedAssessmentResponses = async (id) => {
    let res = await AssessmentActions.GetSavedResponseByAssessmentId(id);
    return res.data;
  };

  const fetchCMSData = async () => {
    setScreenLoader(true);
    let refCmsAssessmentContent = {};
    let payload = {
      PageName: "IdentifyYourIdealPosition",
    };
    await AssessmentActions.GetAssessmentCMS(payload).then(
      async (res) => {
        if (res.status === 200) {
          let resData = res.data;

          ActivityAction.SaveActivity(
            resData?.ID,
            "completedassesment",
            resData?.Title
          );
          refCmsAssessmentContent.browserTitle = resData.BrowserTitle;
          refCmsAssessmentContent.metaDescription = resData.MetaDescription;
          refCmsAssessmentContent.metadata = resData.Metadata;
          refCmsAssessmentContent.title = resData.Title;
          refCmsAssessmentContent.focusArea = resData.FocusArea[0];
          refCmsAssessmentContent.resourceType = resData.ResourceType;
          refCmsAssessmentContent.resourceCategoryType =
            resData.ResourceCategoryType;
          refCmsAssessmentContent.duration = resData.Duration;
          refCmsAssessmentContent.description = resData.Description;
          refCmsAssessmentContent.shortDescription = resData.ShortDescription;
          refCmsAssessmentContent.subTitle = resData.SubTitle;
          refCmsAssessmentContent.internalAssessmentId = resData.ID;
          refCmsAssessmentContent.externalAssessmentId = resData.ExternalId;
          refCmsAssessmentContent.commonLabels =
            resData.Components.CommonComponent.Labels;
          refCmsAssessmentContent.commonResourceLabels =
            resData.Components.CommonResourceComponent.Labels;
          refCmsAssessmentContent.largeImage = resData.LargeImage.ImageUrl;
          refCmsAssessmentContent.mediumImage = resData.MediumImage;
          refCmsAssessmentContent.smallImage = resData.SmallImage;
          refCmsAssessmentContent.commonStatus =
            resData.Components.CommonComponent.LOVList[0].Values;
          refCmsAssessmentContent.assessmentQuestionSet =
            resData.ChildPages.filter(
              (x) =>
                x.Key !==
                "Assessments.IdentifyYourIdealPosition.AssessmentComplete"
            );

          let questionsArr = [];
          refCmsAssessmentContent.assessmentQuestionSet.map((assessment) => {
            Object.keys(assessment.Components).forEach((key) => {
              if (
                key ===
                "Resources.Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
              ) {
                questionsArr.push({
                  ...assessment,
                  QuestionList: assessment.Components[key].QuestionList,
                });
              }
              if (
                key ===
                "Resources.Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
              ) {
                questionsArr.push({
                  ...assessment,
                  QuestionList: assessment.Components[key].QuestionList,
                });
              }
              if (
                key ===
                "Resources.Assessments.IdentifyYourIdealPosition.Compensation"
              ) {
                questionsArr.push({
                  ...assessment,
                  QuestionList: assessment.Components[key].QuestionList,
                });
              }
            });
          });
          refCmsAssessmentContent.assessmentQuestionSet = questionsArr;

          refCmsAssessmentContent.resultPage = resData.ChildPages.find(
            (x) =>
              x.Key ===
              "Assessments.IdentifyYourIdealPosition.AssessmentComplete"
          );

          setCmsAssessmentContent(refCmsAssessmentContent);
          getAssessmentStatus(resData.ID);
          renderSavedAssessment(resData.ID, refCmsAssessmentContent);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchCommonLabel = (key) => {
    let refCmsAssessmentContent = JSON.parse(
      JSON.stringify(cmsAssessmentContent)
    );
    let label = "";
    if (refCmsAssessmentContent?.commonLabels) {
      refCmsAssessmentContent?.commonLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsAssessmentContent = JSON.parse(
      JSON.stringify(cmsAssessmentContent)
    );
    let label = "";
    if (refCmsAssessmentContent?.commonResourceLabels) {
      refCmsAssessmentContent?.commonResourceLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const renderSavedAssessment = async (id, refCmsAssessmentContent) => {
    let savedResponses = await getSavedAssessmentResponses(id);
    let cmsContent = refCmsAssessmentContent;
    let arrResponse = [],
      unSortedOrg = [],
      unSortedJob = [],
      unSortedComp = [];
    if (savedResponses.length) {
      savedResponses?.map((savedResponse) => {
        let QuestionId = savedResponse.questionId;
        let AnswerId =
          savedResponse.answerValue != ""
            ? savedResponse.answerValue.split(",")
            : "";
        cmsContent?.assessmentQuestionSet.map((assessment) => {
          assessment.QuestionList.map((question, i) => {
            if (question.QuestionId === QuestionId) {
              if (AnswerId.length) {
                AnswerId.map((answerid) => {
                  question.AnswerList.map((options) => {
                    if (options.AnswerId === answerid) {
                      let quest;
                      if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                      ) {
                        unSortedOrg.push({
                          index: i,
                          answertext: options.AnswerText,
                        });
                        let sortedAnswers = unSortedOrg
                          .sort((a, b) => a.index - b.index)
                          .map((option) => option.answertext);
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
                        );
                        if (quest.length) {
                          let answer = [];
                          quest[0].Answer = sortedAnswers;
                          answer.push(quest[0].Answer);
                        } else {
                          arrResponse.push({
                            Question: assessment.Title,
                            Answer: sortedAnswers,
                            PageId: assessment.Key,
                          });
                        }
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                      ) {
                        let sortedAnswers;
                        if (
                          options.ResultPageTitle !== "" &&
                          options.AnswerText !== "Somewhat" &&
                          options.AnswerText !== "Not at all"
                        ) {
                          unSortedJob.push({
                            index: i,
                            answertext:
                              options.IsQuestionTitleAppended == true
                                ? options.ResultPageTitle +
                                  " " +
                                  question.QuestionTitle
                                : options.ResultPageTitle,
                          });

                          sortedAnswers = unSortedJob
                            .sort((a, b) => a.index - b.index)
                            .map((option) => option.answertext);
                        }
                        quest = arrResponse.filter(
                          (qID) =>
                            qID.PageId ===
                            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
                        );
                        if (quest.length) {
                          let answer = [];
                          quest[0].Answer = sortedAnswers;
                          answer.push(quest[0].Answer);
                        } else {
                          if (options.ResultPageTitle !== "") {
                            arrResponse.push({
                              Question: assessment?.Title,
                              Answer: sortedAnswers,
                              PageId: assessment.Key,
                            });
                          }
                        }
                      } else if (
                        assessment.Key ===
                        "Assessments.IdentifyYourIdealPosition.Compensation"
                      ) {
                        let sortedAnswers;
                        if (
                          options.AnswerText !== "Not important" &&
                          options.AnswerText !== "Nice to have"
                        ) {
                          if (options.ResultPageTitle !== "") {
                            unSortedComp.push({
                              index: i,
                              answertext:
                                options.IsQuestionTitleAppended == true
                                  ? options.ResultPageTitle +
                                    " " +
                                    question.QuestionTitle
                                  : options.ResultPageTitle,
                            });
                            sortedAnswers = unSortedComp
                              .sort((a, b) => a.index - b.index)
                              .map((option) => option.answertext);
                          }
                          quest = arrResponse.filter(
                            (qID) =>
                              qID.PageId ===
                              "Assessments.IdentifyYourIdealPosition.Compensation"
                          );
                          if (quest.length) {
                            let answer = [];
                            quest[0].Answer = sortedAnswers;
                            answer.push(quest[0].Answer);
                          } else {
                            arrResponse.push({
                              Question: assessment?.Title,
                              Answer: sortedAnswers,
                              PageId: assessment.Key,
                            });
                          }
                        }
                      }
                    }
                  });
                });
              }
            }
          });
        });
      });
      let tempArrResponse = [];
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheOrganisation"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId ===
            "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.AboutTheJobRole"
          )
        );
      }
      if (
        arrResponse.find(
          (resp) =>
            resp.PageId === "Assessments.IdentifyYourIdealPosition.Compensation"
        ) !== undefined
      ) {
        tempArrResponse.push(
          arrResponse.find(
            (resp) =>
              resp.PageId ===
              "Assessments.IdentifyYourIdealPosition.Compensation"
          )
        );
      }
      arrResponse = tempArrResponse;
      // console.log(arrResponse);
      setGetResponse(arrResponse);
    }
  };

  const getAssessmentStatus = async (id) => {
    let response = await AssessmentActions.GetAssessmentStatusById(id);
    setAssessmentStatus(response.data);
  };

  const getAssesmentUpdate = async () => {
    let AssStatus = await AssessmentActions.GetAssessmentStatusById(
      cmsAssessmentContent.internalAssessmentId
    );
    setUpdatedData(AssStatus.data);
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus.learningResourceId,
                learningResourceStatus: assessmentStatus.learningResourceStatus,
                learningResourceType:
                  cmsAssessmentContent?.resourceCategoryType?.ID,
                resourceTypeId: cmsAssessmentContent?.resourceType?.ID,
                isSaved: assessmentStatus.isSaved,
                isLiked: assessmentStatus.isLiked,
                lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: updatedData?.isSharedWithCoach
                  ? updatedData?.isSharedWithCoach
                  : false,
                resultDocumentId: docId,
              };
              AssessmentActions.SaveAssessmentStatus(
                cmsAssessmentContent.resourceCategoryType?.ID,
                assessmentStatus.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    getAssesmentUpdate();
                    setLoadSpinner(false);
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            } else {
              setLoadSpinner(false);
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const shareResultWithCoach = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: cmsAssessmentContent.title,
      assessmentId: cmsAssessmentContent.internalAssessmentId,
      documentId: assessmentStatus.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus.learningResourceId,
            learningResourceStatus: assessmentStatus.learningResourceStatus,
            learningResourceType:
              cmsAssessmentContent?.resourceCategoryType?.ID,
            resourceTypeId: cmsAssessmentContent?.resourceType?.ID,
            isSaved: assessmentStatus.isSaved,
            isLiked: assessmentStatus.isLiked,
            lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
            isDownloaded: updatedData?.isDownloaded
              ? updatedData?.isDownloaded
              : false,
            isSharedWithCoach: true,
            resultDocumentId: docId,
          };
          AssessmentActions.SaveAssessmentStatus(
            cmsAssessmentContent.resourceCategoryType?.ID,
            assessmentStatus.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                getAssesmentUpdate();
                setLoadSpinner(false);

                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  const isRedirectHandler = () => {
    sessionStorage.removeItem("EJO-IYIP-Redirection-Flag");
    navigate("/" + pageOrgin.id + "/evaluate-a-job-offer");
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }
  return (
    <div className="assessment-page">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

      <Container className="pt-lg-4 pt-2 pb-4">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/identify-your-ideal-position")
              }
            >
              {fetchCommonLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
            {/* <Cirrus.Button isSecondary onClick={() => navigate("/identify-your-ideal-position")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button> */}
          </Col>
          <Col
            sm={12}
            md={{ span: 8, order: 2 }}
            lg={{ span: 8, order: 2 }}
            xs={{ order: 3 }}
            className="d-flex"
          >
            {/* <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>Progress {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div> */}
          </Col>
          <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
            {JSON.parse(
              window.sessionStorage.getItem("EJO-IYIP-Redirection-Flag")
            ) === true ? (
              <>
                <Cirrus.Button onClick={isRedirectHandler}>
                  {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
                </Cirrus.Button>
              </>
            ) : (
              <>
                <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
                  {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
                </Cirrus.Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container className="assessment-review-result-wrapper assessment-result-wrapper p-lg-5 p-3 mb-5">
        <Row className="mb-5 pb-3 border-bottom">
          <Col lg={5}>
            <Cirrus.Header
              size="h4"
              className="mb-3 text-lg-start text-md-start text-center"
            >
              {cmsAssessmentContent?.resultPage?.Title}
            </Cirrus.Header>
          </Col>
          <Col lg={7} className="download-share-retake">
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() =>
                downloadDocument(assessmentStatus.resultDocumentId)
              }
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.DownloadAsPDF"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() =>
                shareResultWithCoach(assessmentStatus.resultDocumentId)
              }
              disabled={coachdata ? false : true}
              // disabled={
              //   coachdata && !assessmentStatus?.isSharedWithCoach ? false : true
              // }
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.ShareResultsWithCoach"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/identify-your-ideal-position")
              }
            >
              {fetchCommonLabel("CommonComponentCommonRetakeButton")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={12}>
            {getResponse.map((res, idx) => {
              return (
                <>
                  <div className="border-bottom text-start mb-4" key={idx}>
                    <Cirrus.Headline className="result-question-title">
                      {res?.Question}
                    </Cirrus.Headline>
                    <div className="result-options">
                      {res?.Answer?.map((answer, index) => {
                        return <label key={index}>{answer}</label>;
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </Col>
        </Row>
      </Container>

      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
};

export default IYIPResult;
