//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { createContext } from 'react';

/** CREW NOTE CONTEXT
 * createContex => this is used to create the Context and Context Value (PROVIDER)
 * useContext => this is used to access the Context Value (CONSUMER)
 */

export const Context = createContext();
