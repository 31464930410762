//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState } from "react";
import { React } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { EventsActions } from "./EventsActions";
import { Row, Col, Container } from "react-bootstrap";
import articleImage from "./article.png";
import Spinner from "../../common/components/spinner-loader/Spinner";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";
import {
  globalDateFormat,
  UTCtoLocalTimeWithAMPM,
} from "../../common/helpers/DateTimeHelper";

const EventDetails = () => {
  const appInsights = useAppInsightsContext();
  const [eventDetails, setEventDetails] = useState({});
  const [enrolledSession, setEnrolledSession] = useState(null);
  const [isSaved, setSaved] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [doceboURL, setDoceboURL] = useState();
  const [cmsSA, setCmsSA] = useState({});
  const [showComeOffWaitlist, setShowComeOffWaitlist] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    setScreenLoader(true);
    window.scrollTo(0, 0);
    fetchCMSData();
    fetchEventDetails();
    getdoceboLink();
  }, []);

  useEffect(() => {
    let enrolled = eventDetails?.sessions?.filter(
      (session) => session.enrolled.is_enrolled && session.id_session
    );
    if (eventDetails?.status == "completed") {
      updateEvents("general", "", true);
    }
    if (enrolled?.length) {
      setEnrolledSession(enrolled[0].id_session);
      eventDetails.is_enrolled = true;
      updateEvents("enroll", enrolled[0], true);
    }
  }, [eventDetails]);

  const fetchCMSData = async () => {
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    setScreenLoader(true);
    let payload = {
      PageName: "EventDetails",
    };
    await EventsActions.GetEventsCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data.Pages[0];
          let label = [...resData?.Components?.CommonComponent?.Labels];
          label = [
            ...label,
            ...resData?.Components?.["Events.EventsDetailPageComponent"].Labels,
          ];
          resData?.Components?.["Events.EventsDetailPageComponent"].LOVList.map(
            (Lovs) => {
              Lovs?.Values?.map((x) => {
                let obj = {
                  Key: x.ID,
                  Phrase: x.Value,
                };
                label.push(obj);
              });
            }
          );
          resData?.Components?.["Events.EventsCourseCard"].LOVList.map(
            (Lovs) => {
              Lovs?.Values?.map((x) => {
                let obj = {
                  Key: x.ID,
                  Phrase: x.Value,
                };
                label.push(obj);
              });
            }
          );
          refCmsSA["masterLabel"] = label;

          setCmsSA(refCmsSA);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };
  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsSA));
    let label = "";
    if (Object.keys(refCmsContent).length !== 0) {
      refCmsContent?.masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchEventDetails = async () => {
    setScreenLoader(true);
    let url = window.location.href.split("/");
    setSaved(url[url.length - 1]);
    // console.log(url[url.length - 1], 'url[url.length - 1]')
    let res = await EventsActions.GetEventDetails(url[url.length - 2]);
    res = res?.data;
    setEventDetails(res?.data);
    TrackActions.EventTrack(appInsights, "ViewCard", {
      "Entity Id": res.data?.id?.toString(),
      "Entity Name": res.data?.name,
      CardType: "Event",
    });
    TrackActions.PageAction("ViewCard", {
      "Entity Id": res.data?.id?.toString(),
      "Entity Name": res.data?.name,
      CardType: "Event",
    });
    setScreenLoader(false);
  };

  const enrollSession = async (session, flag) => {
    setShowComeOffWaitlist(flag);
    setDisableBtn(true);
    let payload = {
      event_id: eventDetails.id,
      session_id: session.id_session,
    };
    let savePayload = {
      EventId: eventDetails.id,
      session_id: session.id_session,
      MeetingType: "LearningEvent", // e1,e2,e3
      Duration: session.dates[0].effective_duration,
      Title: eventDetails?.name,
      StartTimeStamp: session.start_date,
      EndTimeStamp: session.end_date,
      // "Location": doceboURL, // event url
      Location:
        session?.locations?.length > 0
          ? session?.locations[0]?.name + " , " + session?.locations[0]?.address
          : doceboURL,
    };
    await EventsActions.EnrollSession(payload).then(
      (res) => {
        if (res.status === 200) {
          setDisableBtn(false);
          ActivityAction.SaveActivity(
            eventDetails?.id.toString(),
            "event",
            eventDetails?.name
          );
          TrackActions.EventTrack(appInsights, "BookEvent", {
            EventType: eventDetails?.name,
          });
          TrackActions.PageAction("BookEvent", {
            EventType: eventDetails?.name,
          });
          setEnrolledSession(session.id_session);
          updateEvents("enroll", session, true);
          if (!res?.data?.data?.enrolled[0]?.waiting) {
            EventsActions.saveEventCalender(savePayload).then((res) => {
              if (res.status === 200) {
                console.log("Enrolled Event saved in calender");
              }
            });
          }
        } else {
          setScreenLoader(false);
          setDisableBtn(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
        setDisableBtn(false);
      }
    );
  };

  const unEnrollSession = async (session, flag) => {
    setDisableBtn(true);
    let payload = {
      event_id: eventDetails.id,
    };
    let savePayload = {
      EventId: eventDetails.id,
      session_id: session.id_session,
      MeetingType: "LearningEvent", // e1,e2,e3
      Duration: session.dates[0].effective_duration,
      Title: eventDetails?.name,
      StartTimeStamp: session.start_date,
      EndTimeStamp: session.end_date,
      Location: doceboURL, // event url
    };
    await EventsActions.UnEnrollSession(payload).then(
      (res) => {
        if (res.status === 200) {
          setEnrolledSession(null);
          setDisableBtn(false);
          updateEvents("enroll", session, false);
          TrackActions.EventTrack(appInsights, "CancelEvent", {
            EventType: eventDetails?.name,
            EventID: eventDetails.id,
          });
          TrackActions.PageAction("CancelEvent", {
            EventType: eventDetails?.name,
            EventID: eventDetails.id,
          });
          if (flag) {
            EventsActions.saveEventCalender(savePayload).then((res) => {
              if (res.status === 200) {
                console.log("Enrolled Event removed in calender");
              }
            });
          }
        } else {
          setScreenLoader(false);
          setDisableBtn(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
        setDisableBtn(false);
      }
    );
  };
  const isSessionTime = (session) => {
    //const todayDate = new Date("Sun Dec 13 2022 00:10:07 GMT+0530 (India Standard Time)")
    const utcDate = Date.parse(new Date());
    //const tzGuess = moment.tz.guess();
    let sessionGMT = session.dates[0].gmt.substring(
      1,
      session.dates[0].gmt.length - 1
    );
    sessionGMT = sessionGMT.split(" ");
    sessionGMT = sessionGMT[1] + sessionGMT[2].split(":").join("");

    const sessionStartDate =
      session.dates[0].date + "T" + session.dates[0].start_hour + sessionGMT;
    const sessionUTCStartDate = Date.parse(sessionStartDate);
    let startTime_diff = (sessionUTCStartDate - utcDate) / 1000 / 60;

    const sessionEndDate =
      session.dates[0].date + "T" + session.dates[0].end_hour + sessionGMT;
    const sessionUTCEndDate = Date.parse(sessionEndDate);
    let endTime_diff = (sessionUTCEndDate - utcDate) / 1000 / 60;
    let duration = (sessionUTCEndDate - sessionUTCStartDate) / 1000 / 60;

    if (
      Math.abs(Math.round(startTime_diff)) < 15 ||
      (endTime_diff > 0 && endTime_diff < duration)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getdoceboLink = async () => {
    let url = window.location.href.split("/");
    let res = await EventsActions.GetDoceboSessionLink();
    setDoceboURL(res.data + "&id_course=" + url[url.length - 2]);
  };

  const doceboJoinEvent = async () => {
    let url = window.location.href.split("/");
    let CourseId = url[url.length - 2];

    // Docebo Attendance Marking
    let IsAttended = true;
    EventsActions.MarkAttendance({ CourseId, IsAttended }).then(
      (res) => {
        if (res.status === 200) {
        } else {
          console.log("Attendance failed");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    window.open(doceboURL);
  };

  const isValidDate = (session) => {
    const utcDate = Date.parse(new Date());
    let sessionGMT = session.dates[0].gmt.substring(
      1,
      session.dates[0].gmt.length - 1
    );
    sessionGMT = sessionGMT.split(" ");
    sessionGMT = sessionGMT[1] + sessionGMT[2].split(":").join("");

    const sessionStartDate =
      session.dates[0].date + "T" + session.dates[0].start_hour + sessionGMT;
    const sessionUTCStartDate = Date.parse(sessionStartDate);
    let startTime_diff = (sessionUTCStartDate - utcDate) / 1000 / 60;

    const sessionEndDate =
      session.dates[0].date + "T" + session.dates[0].end_hour + sessionGMT;

    const sessionUTCEndDate = Date.parse(sessionEndDate);
    let endTime_diff = (sessionUTCEndDate - utcDate) / 1000 / 60;
    let duration = (sessionUTCEndDate - sessionUTCStartDate) / 1000 / 60;

    if (
      enrolledSession == session.id_session &&
      startTime_diff < 0 &&
      Math.abs(startTime_diff) < duration
    ) {
      return true;
    } else {
      if (startTime_diff > 0) return true;
      else return false;
    }
  };

  const getEventType = (code) => {
    let courceCode =
      code && code[0] == "R" ? code.substring(1, 3) : code.substring(0, 2);
    let eventType =
      courceCode == "E1"
        ? "Virtual"
        : courceCode == "E2"
        ? "InPerson"
        : courceCode == "E3"
        ? "OnDemand"
        : "";
    return eventType;
  };

  const getEnrollButton = (session) => {
    return eventDetails.available_seats[session.id_session] ? (
      <Cirrus.Button
        onClick={() => {
          enrollSession(session, false);
        }}
        disabled={disableBtn}
      >
        {fetchLabel("enroll")}
      </Cirrus.Button>
    ) : JSON.parse(eventDetails.allow_overbooking) ? (
      <Cirrus.Button
        onClick={() => {
          enrollSession(session, true);
        }}
      >
        {fetchLabel("waitlist")}
      </Cirrus.Button>
    ) : (
      <Cirrus.Button disabled>No Seats Available</Cirrus.Button>
    );
  };
  const getUnEnrollButton = (session) => {
    return session?.enrolled?.waiting_list ? (
      <Cirrus.Button
        onClick={() => {
          unEnrollSession(session, false);
        }}
      >
        {fetchLabel("comeoffwaitlist")}
      </Cirrus.Button>
    ) : showComeOffWaitlist ? (
      <Cirrus.Button
        onClick={() => {
          unEnrollSession(session, false);
        }}
      >
        {fetchLabel("comeoffwaitlist")}
      </Cirrus.Button>
    ) : (
      <Cirrus.Button
        onClick={() => {
          unEnrollSession(session, true);
        }}
        disabled={disableBtn}
      >
        {fetchLabel("Events.EventDetailPageComponent.UnEnroll")}
      </Cirrus.Button>
    );
  };
  const updateEvents = async (type, session, enroll) => {
    let payload;
    if (type == "enroll") {
      payload = {
        ResourceType: "External",
        EventType: getEventType(eventDetails?.code),
        EventDate:
          session && session.start_date
            ? session.start_date.split(" ")[0]
            : "00:00",
        STARTTIME: "00:00",
        ENDTIME: "00:00",
        ResourceId: eventDetails?.event_id,
        IsAttended: session?.status,
        IsRegistered: type == "enroll" ? enroll : session?.user_enrolled,
        IsSaved: isSaved,
        Status: eventDetails.status,
        EventName: eventDetails?.name,
        StartDate: session?.start_date,
        EndDate: session?.end_date,
        RegisteredTimeStamp: new Date(),
        SessionId: session?.id_session?.toString(),
        Instructor:
          session?.instructors[0]?.firstname +
          " " +
          session?.instructors[0]?.lastname,
        AttendanceSessionID: session?.dates[0]?.id_date,
        SessionDate: session?.dates[0]?.date,
        SessionTimeZone: session?.dates[0]?.timezone,
        SessionStartHour: session?.dates[0]?.start_hour,
        SessionEndHour: session?.dates[0]?.end_hour,
        SessionGMT: session?.dates[0]?.gmt,
      };
    } else {
      if (session === undefined) {
        let enrolled = eventDetails?.sessions?.filter(
          (session) => session.enrolled.is_enrolled && session.id_session
        );
        session = enrolled[0];
      }
      payload = {
        ResourceType: "External",
        EventType: getEventType(eventDetails?.code),
        EventDate:
          session && session.start_date
            ? session.start_date.split(" ")[0]
            : "00:00",
        STARTTIME: "00:00",
        ENDTIME: "00:00",
        ResourceId: eventDetails?.event_id,
        IsAttended: session?.status,
        IsRegistered: type == "enroll" ? enroll : session?.user_enrolled,
        IsSaved: type == "save" && JSON.parse(isSaved) == true ? false : true,
        Status: eventDetails.status,
        EventName: eventDetails?.name,
        StartDate: session?.start_date,
        EndDate: session?.end_date,
        RegisteredTimeStamp: new Date(),
        SessionId: session?.id_session?.toString(),
        Instructor:
          session?.instructors[0]?.firstname +
          " " +
          session?.instructors[0]?.lastname,
        AttendanceSessionID: session?.dates[0]?.id_date,
        SessionDate: session?.dates[0]?.date,
        SessionTimeZone: session?.dates[0]?.timezone,
        SessionStartHour: session?.dates[0]?.start_hour,
        SessionEndHour: session?.dates[0]?.end_hour,
        SessionGMT: session?.dates[0]?.gmt,
      };
    }

    await EventsActions.UpdateEvent(payload, eventDetails.id).then(
      (res) => {
        if (res.status === 200 && type == "save") {
          console.log("event Saved");
          if (JSON.parse(isSaved) === true) {
            setSaved(false);
            updateUrl(false);
          } else {
            setSaved(true);
            updateUrl(true);
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const updateUrl = (value) => {
    const url = new URL(window.location.href);
    const segments = url.hash.split("/");
    segments[segments.length - 1] = value;
    url.hash = segments.join("/");
    window.history.replaceState(null, "", url);
  };

  return (
    <div className="article-page">
      {screenLoader ? (
        <div>
          <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>
      ) : (
        <Container fluid className="background-White py-5">
          <Container>
            <Row className="mb-4">
              <Col md={2} lg={2} xs={{ span: 6 }}>
                <Cirrus.Button
                  isSecondary
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  {fetchLabel("CommonComponent.CommonBackButton")}
                </Cirrus.Button>
              </Col>
            </Row>
            <Row>
              <Col lg={9} className="mb-4">
                <Cirrus.Header size="h3">{eventDetails?.name}</Cirrus.Header>
                <div className="save-section mt-4 mb-3">
                  <button
                    className="saveBtn"
                    onClick={() => {
                      updateEvents("save");
                    }}
                  >
                    <i
                      className={
                        JSON.parse(isSaved) == true ? "icon-saved" : "icon-save"
                      }
                    ></i>
                    <span className="saveBtnText">
                      {JSON.parse(isSaved) == true
                        ? fetchLabel("CommonComponent.CommonSavedButton")
                        : fetchLabel("CommonComponent.CommonSaveButton")}
                    </span>
                  </button>
                </div>
              </Col>
              <Col lg={12}>
                <img
                  src={
                    eventDetails?.thumbnail
                      ? eventDetails?.thumbnail
                      : articleImage
                  }
                  alt=""
                  className="img-fluid w-100 mb-5 img_article"
                />
              </Col>
            </Row>
          </Container>
          <Container className="article-body">
            <Row>
              <Col lg={11} className="m-auto">
                <Row>
                  <Col lg={9} className="m-auto">
                    {eventDetails?.description && (
                      <>
                        <Cirrus.RichText
                          className="article-rich-text mb-3"
                          content={fetchLabel(
                            "Events.EventDetailPageComponent.ProgramDescription"
                          )}
                        />
                        <Cirrus.RichText
                          className="article-rich-text mb-3"
                          content={eventDetails?.description}
                        />
                        {eventDetails.status == "completed" && (
                          <Cirrus.Button disabled>
                            {fetchLabel("completed")}
                          </Cirrus.Button>
                        )}
                      </>
                    )}
                    {eventDetails?.sessions?.map((session) => {
                      return (
                        <>
                          {isValidDate(session) && (
                            <div className="events-details-page">
                              <>
                                {session?.name && (
                                  <>
                                    <Cirrus.Header size="h3">
                                      {session?.name}
                                    </Cirrus.Header>
                                    <p>
                                      <span>
                                        {fetchLabel(
                                          "Events.EventDetailPageComponent.StartDate"
                                        )}{" "}
                                        :&nbsp;
                                        {globalDateFormat(
                                          session.start_date,
                                          true
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        {fetchLabel(
                                          "Events.EventDetailPageComponent.Timings"
                                        )}{" "}
                                        :&nbsp;
                                        {UTCtoLocalTimeWithAMPM(
                                          session.start_date
                                        )}{" "}
                                        -{" "}
                                        {UTCtoLocalTimeWithAMPM(
                                          session.end_date
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        {fetchLabel(
                                          "Events.EventDetailPageComponent.Duration"
                                        ).replace(
                                          "#Minutes#",
                                          `: ${session.dates[0].effective_duration}`
                                        )}
                                      </span>
                                      <br />
                                      {session.locations.length > 0 && (
                                        <>
                                          <span>
                                            {fetchLabel(
                                              "Events.EventDetailPageComponent.Locations"
                                            )}{" "}
                                            :&nbsp;
                                            {session?.locations[0]?.name +
                                              ", " +
                                              session?.locations[0]?.address}
                                          </span>
                                          <br />
                                        </>
                                      )}
                                      {session?.instructors?.length > 0 && (
                                        <>
                                          <span>
                                            {fetchLabel(
                                              "Events.EventDetailPageComponent.Instructor"
                                            )}{" "}
                                            :&nbsp;
                                            {session.instructors[0]?.firstname}
                                          </span>
                                          <br />
                                        </>
                                      )}
                                    </p>
                                  </>
                                )}
                              </>
                              {session?.description && (
                                <Cirrus.RichText
                                  className="article-rich-text mb-3"
                                  content={session?.description}
                                />
                              )}

                              {enrolledSession != null ? (
                                enrolledSession == session.id_session ? (
                                  session?.enrolled?.waiting_list ? (
                                    getUnEnrollButton(session)
                                  ) : isSessionTime(session) &&
                                    !showComeOffWaitlist ? (
                                    <Cirrus.Button
                                      onClick={() => {
                                        doceboJoinEvent();
                                      }}
                                    >
                                      {fetchLabel(
                                        "Events.EventDetailPageComponent.GoToEvent"
                                      )}
                                    </Cirrus.Button>
                                  ) : (
                                    getUnEnrollButton(session)
                                  )
                                ) : (
                                  <Cirrus.Button disabled>
                                    {fetchLabel("enroll")}
                                  </Cirrus.Button>
                                )
                              ) : eventDetails.status != "completed" ? (
                                !enrolledSession ? (
                                  getEnrollButton(session)
                                ) : (
                                  getUnEnrollButton(session)
                                )
                              ) : (
                                <Cirrus.Button disabled>
                                  {fetchLabel("completed")}
                                </Cirrus.Button>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </div>
  );
};

export default EventDetails;
