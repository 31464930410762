//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState } from "react";
import "manpowergroup-cirrus/index.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { ErrorBoundary } from "react-error-boundary";
import { Context } from "./common/context/Context";
import DashBoard from "./pages/dashBoard";
import Discover from "./pages/discover";
import TPStartPage from "./pages/assessments/thoughtQuestions/thoughtQuestions";
import TPQuestionsPage from "./pages/assessments/thoughtQuestions/questions";
import TPResultspage from "./pages/assessments/thoughtQuestions/results";

import ValuesAndDrivers from "./pages/assessments/valuesAndDrivers/startPage";
import VAndDassessment from "./pages/assessments/valuesAndDrivers/assessment";
import VandDReviewPage from "./pages/assessments/valuesAndDrivers/review";
import VandDReviewPageMobile from "./pages/assessments/valuesAndDrivers/reviewMobile";
import VandDResultPageMobile from "./pages/assessments/valuesAndDrivers/resultMobile";
import VandDResultPage from "./pages/assessments/valuesAndDrivers/result";

import ReviewAccomplishments from "./pages/assessments/reviewAccomplishments/ReviewAccomplishments";
import RAQuestionsPage from "./pages/assessments/reviewAccomplishments/Questions";
import RAReviewPage from "./pages/assessments/reviewAccomplishments/Review";
import RAResultPage from "./pages/assessments/reviewAccomplishments/Result";

import IdentifyYourIdealPosition from "./pages/assessments/identifyYourIdealPosition/startPage";
import IYIPAssessment from "./pages/assessments/identifyYourIdealPosition/assessment";
import IYIPResult from "./pages/assessments/identifyYourIdealPosition/result";

import StrengthAnalysis from "./pages/assessments/strengthAnalysis/StrengthAnalysis";
import InterestStartPage from "./pages/assessments/strengthAnalysis/InterestStartPage";
import InterestQuestionPage from "./pages/assessments/strengthAnalysis/InterestQuestionPage";
import InterestRefiningQuestionPage from "./pages/assessments/strengthAnalysis/InterestRefiningQuestionPage";
import InterestResultPage from "./pages/assessments/strengthAnalysis/InterestResultPage";
import SkillStartPage from "./pages/assessments/strengthAnalysis/SkillsStartPage";
import SkillsQuestionPage from "./pages/assessments/strengthAnalysis/SkillsQuestionPage";
import SkillsResultPage from "./pages/assessments/strengthAnalysis/SkillsResultPage";

import LookingAtOptions from "./pages/assessments/lookingAtOptions/startPage";
import CareerPath from "./pages/assessments/lookingAtOptions/career";
import CareerFactors from "./pages/assessments/lookingAtOptions/factors";
import LookingAtOptionsReview from "./pages/assessments/lookingAtOptions/review";
import LookingAtOptionsResult from "./pages/assessments/lookingAtOptions/result";

import ArticlePage from "./pages/articles/articlePage";
import ArticleCards from "./pages/articles/articleCards";

import EvaluateJobOffer from "./pages/assessments/evaluateAJobOffer/EvaluateJobOffer";
import EJOStartPage from "./pages/assessments/evaluateAJobOffer/StartPage";
import EJOQuestionsPage from "./pages/assessments/evaluateAJobOffer/QuestionsPage";
import EJOResultPage from "./pages/assessments/evaluateAJobOffer/ResultPage";
import PolicyUpdatedSummary from "./common/components/footer/PolicyUpdatedSummary";
import "./styles.css";

import {
  Landing,
  QuestionEdit,
  Questionnaire,
  MultiSelect,
  SearchAndSelect,
  SingleSelect,
  OurPolicy,
  Recap,
  RegisterFirstTimeUser,
  ConfirmDetails,
  Welcome,
  WelcomeOnboarding,
} from "./pages/onBoarding";
import JobDetails from "./pages/bookmarkPage/JobDetails";
import MyProfile from "./pages/myProfile";
import AboutMe from "./pages/aboutMe";
import ErrorHandler from "./pages/errorHandler";
import MyDocuments from "./pages/myDocuments/MyDocuments";
import BookMark from "./pages/bookmarkPage";
import Job from "./pages/jobCleanup/Job";
import Matchsetup from "./pages/jobCleanup/matchsetup";
import Coach from "./pages/coach/coach";
import CandidateCalender from "./pages/calendar/CandidateCalender";
import IViewWelcomePage from "./pages/Iview/IViewWelcomePage";
import IviewInterviewStartPage from "./pages/Iview/IviewStartPage/IviewStartPage";
import IviewInterviewDonePage from "./pages/Iview/IviewDonePage/IviewDonePage";
import IviewCollectionPage from "./pages/Iview/IviewCollectionPage/IviewCollectionPage";
import Events from "./pages/myEvents/Events";
import EventDetails from "./pages/myEvents/eventDetails";
import ResourcesVideo from "./pages/discover/resourcesPage/resourcesVideo";
import ResourcesDocument from "./pages/discover/resourcesPage/resourcesDocument";
import PrepareForAnInterview from "./pages/assessments/prepareInterview/startPage";
import PrepareForAnInterviewResult from "./pages/assessments/prepareInterview/result";
import PrepareForAnInterviewAboutRole from "./pages/assessments/prepareInterview/abouttherole";
import PFAIAssessment from "./pages/assessments/prepareInterview/assessment";

import LearningCollections from "./pages/discover/learningPageTemplates/learningCollections";
import SubjectCategories from "./pages/discover/subjectCategories/subjectCategories";

import "bootstrap/dist/css/bootstrap.min.css";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import PersonalityAssessment from "./pages/assessments/personalityAssessment/PersonalityAssessment";
import PAResultPage from "./pages/assessments/personalityAssessment/ResultPage";
import PrivateRoutes from "./common/helpers/PrivateRoutes";
import PolicyCookie from "./common/components/footer/PolicyCookie";
import PolicyPrivacy from "./common/components/footer/PolicyPrivacy";
import PolicyTC from "./common/components/footer/PolicyTC";
import ConfirmationPopup from "./pages/assessments/Confirmation";

var reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory({ basename: "" });
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHT_KEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

function App({ msalInstance }) {
  const [theme, setTheme] = useState();
  const [language, setLanguage] = useState({});
  const [cmsOnBoadingContent, setCmsOnBoadingContent] = useState({});
  const [idTokenClaims, setIdTokenClaims] = useState();
  const [matchTab, setMatchTab] = useState(true);
  const [jobCount, setJobCount] = useState(0);
  const [searchTab, setSearchTab] = useState(false);
  const [savedJobTab, setSavedJobTab] = useState(false);
  const [clickLoadMore, setClickLoadMore] = useState(false);
  const [programDetails, setProgramDetails] = useState({});
  const [masterTimezoneDetails, setMasterTimezoneDetails] = useState([]);
  const [noAlert, setAlert] = useState(false);
  const [removedJobId, setRemovedJobId] = useState();
  const [jdRemovedData, setJdRemovedData] = useState();
  const [selectedLang, setSelectedLang] = useState("");
  const [savedJobNoResults, setSavedJobNoResults] = useState();
  const [savedJobList, setSavedJobList] = useState();
  const [savedStatusJD, setSavedStatusJD] = useState();
  const [appliedStatusJD, setAppliedStatusJD] = useState();
  const [cmsValuesAndDriversContent, setCmsValuesAndDriversContent] =
    useState();
  const [
    cmsIdentifyYourIdealPositionContent,
    setCmsIdentifyYourIdealPositionContent,
  ] = useState();
  const [cmsStrengthAnalysis, setCmsStrengthAnalysis] = useState();
  const [generateInterestsPdfFlag, setGenerateInterestsPdfFlag] =
    useState(false);
  const [generateSkillsPdfFlag, setGenerateSkillsPdfFlag] = useState(false);
  const [generateMatrixPdfFlag, setGenerateMatrixPdfFlag] = useState(false);
  const [generatePdfFlag, setGeneratePdfFlag] = useState(false);
  const [cmsPrepareForInterviewContent, setCmsPrepareForInterviewContent] =
    useState();
  const [cmsEvaluateAJobOffer, setCmsEvaluateAJobOffer] = useState();
  const [resourceDetails, setResourceDetails] = useState();
  const [cmsLookingAtOptionsContent, setCmsLookingAtOptionsContent] =
    useState();
  const [TPProgress, setTPProgress] = useState();
  const [cmsThoughtQuestionsContent, setCmsThoughtQuestionsContent] =
    useState();
  const [cmsRAchildPagesAndLabel, setCmsRAchildPagesAndLabel] = useState({});
  const [cmsPersonalityAssessment, setCmsPersonalityAssessment] = useState({});
  const [questionCollection, setQuestionCollection] = useState();
  const [edit, setEdit] = useState(false);
  const [resPresent, setResPresent] = useState(false);
  const [beforeDashboardLogoRedirection, setBeforeDashboardLogoRedirection] =
    useState(false);
  const [focusToResource, setFocusToResource] = useState(false);
  const [notificationSignalRMessages, setNotificationSignalRMessages] =
    useState([]);
  const [uploadModalExitDisable, setUploadModalExitDisable] = useState(false);
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [hideResumeUpload, setHideResumeUpload] = useState(false);
  const [isGetStartedClicked, setIsGetStartedClicked] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [newUploadModal, setNewUploadModal] = useState(false);
  const [isParsed, setIsParsed] = useState(false);
  const [isEditExistingClicked, setIsEditExistingClicked] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [documentIdentifier, setDocumentIdentifier] = useState("");
  const [isResumeBuilder, setIsResumeBuilder] = useState(false);
  const [dashboardResumeFlag, setDashboardResumeFlag] = useState(false);
  const [path, setPath] = useState();
  const [countryToSearch, setCountryToSearch] = useState("");
  const [onDemand, setOnDemand] = useState([]);
  const [languageChanged, setLanguageChanged] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState({
    status: false,
    message: "",
    closeText: "",
  });
  const [updatesSummaryDetails, setUpdatesSummaryDetails] = useState({ latestVersion_TC: '', latestVersion_Policy: '', isPolicyUpdated: false });
  let search, location, distance;
  const jdPayload = {};

  const context = {
    language,
    setLanguage,
    cmsOnBoadingContent,
    setCmsOnBoadingContent,
    theme,
    setTheme,
    idTokenClaims,
    setIdTokenClaims,
    matchTab,
    setMatchTab,
    searchTab,
    setSearchTab,
    savedJobTab,
    setSavedJobTab,
    clickLoadMore,
    setClickLoadMore,
    programDetails,
    setProgramDetails,
    masterTimezoneDetails,
    setMasterTimezoneDetails,
    jdPayload,
    setAlert,
    noAlert,
    removedJobId,
    setRemovedJobId,
    jdRemovedData,
    setJdRemovedData,
    search,
    location,
    distance,
    selectedLang,
    setSelectedLang,
    beforeDashboardLogoRedirection,
    setBeforeDashboardLogoRedirection,
    savedJobNoResults,
    setSavedJobNoResults,
    savedJobList,
    setSavedJobList,
    savedStatusJD,
    setAppliedStatusJD,
    appliedStatusJD,
    setSavedStatusJD,
    cmsValuesAndDriversContent,
    setCmsValuesAndDriversContent,
    cmsIdentifyYourIdealPositionContent,
    setCmsIdentifyYourIdealPositionContent,
    cmsStrengthAnalysis,
    setCmsStrengthAnalysis,
    generateInterestsPdfFlag,
    setGenerateInterestsPdfFlag,
    generateSkillsPdfFlag,
    setGenerateSkillsPdfFlag,
    generateMatrixPdfFlag,
    setGenerateMatrixPdfFlag,
    generatePdfFlag,
    setGeneratePdfFlag,
    cmsLookingAtOptionsContent,
    setCmsLookingAtOptionsContent,
    cmsPrepareForInterviewContent,
    setCmsPrepareForInterviewContent,
    cmsThoughtQuestionsContent,
    setCmsThoughtQuestionsContent,
    questionCollection,
    setQuestionCollection,
    cmsRAchildPagesAndLabel,
    setCmsRAchildPagesAndLabel,
    cmsPersonalityAssessment,
    setCmsPersonalityAssessment,
    TPProgress,
    setTPProgress,
    cmsEvaluateAJobOffer,
    setCmsEvaluateAJobOffer,
    edit,
    setEdit,
    jobCount,
    setJobCount,
    resPresent,
    setResPresent,
    resourceDetails,
    setResourceDetails,
    focusToResource,
    setFocusToResource,
    notificationSignalRMessages,
    setNotificationSignalRMessages,

    uploadModalExitDisable,
    setUploadModalExitDisable,
    isResumeUploaded,
    setIsResumeUploaded,
    hideResumeUpload,
    setHideResumeUpload,
    isGetStartedClicked,
    setIsGetStartedClicked,
    uploadModal,
    setUploadModal,
    newUploadModal,
    setNewUploadModal,
    isParsed,
    setIsParsed,
    isEditExistingClicked,
    setIsEditExistingClicked,
    documentId,
    setDocumentId,
    documentIdentifier,
    setDocumentIdentifier,
    isResumeBuilder,
    setIsResumeBuilder,
    dashboardResumeFlag,
    setDashboardResumeFlag,
    path,
    setPath,
    onDemand,
    setOnDemand,
    countryToSearch,
    setCountryToSearch,
    languageChanged,
    setLanguageChanged,
    isPopUpVisible,
    setIsPopUpVisible,
    updatesSummaryDetails,
    setUpdatesSummaryDetails,
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler} key={location}>
      <div className={`main-page ${theme}`}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <MsalProvider instance={msalInstance}>
            <Context.Provider value={context}>
              <HashRouter>
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path="/welcome-on-boarding"
                      element={<WelcomeOnboarding />}
                    />
                    <Route path="/signup-welcome" element={<Welcome />} />
                    <Route
                      path="/signup-confirm-details"
                      element={<ConfirmDetails />}
                    />
                    <Route path="/myprofile" element={<MyProfile />} />
                    <Route path="/questions" element={<Questionnaire />} />
                    <Route path="/question-1" element={<MultiSelect />} />
                    <Route path="/question-2" element={<SingleSelect />} />
                    <Route path="/question-3" element={<SearchAndSelect />} />
                    <Route
                      path="/edit/question/:qid"
                      element={<QuestionEdit />}
                    />
                    <Route path="/recap" element={<Recap />} />
                    <Route path="/about-me" element={<AboutMe />} />
                    <Route path="/discover" element={<Discover />} />
                    <Route path="/jobsearchPage/*" exact element={<Job />} />
                    <Route
                      path="/:origin/job-details/:id"
                      element={<JobDetails />}
                    />
                    <Route path="/dashboard" exact element={<DashBoard />} />
                    <Route path="/my-documents" element={<MyDocuments />} />
                    <Route path="/matchsetup" element={<Matchsetup />} />
                    <Route path="/bookmark" element={<BookMark />} />
                    <Route path="/mycoach" element={<Coach />} />
                    <Route path="/calendar" element={<CandidateCalender />} />
                    <Route
                      path="/iview-welcome"
                      element={<IViewWelcomePage />}
                    />
                    <Route
                      path="/iview-interview-start/:id/:externalId"
                      element={<IviewInterviewStartPage />}
                    />
                    <Route
                      path="/iview-interview-done/:id/:toolId/:token"
                      element={<IviewInterviewDonePage />}
                    />
                    <Route
                      path="/iview-collecion-page/:id"
                      element={<IviewCollectionPage />}
                    />
                    <Route path="/events" element={<Events />} />
                    <Route
                      path="/eventDetails/:id/:isSaved"
                      element={<EventDetails />}
                    />
                    <Route
                      path="/resource-video/:id"
                      element={<ResourcesVideo />}
                    />
                    <Route
                      path="/resource-document/:id"
                      element={<ResourcesDocument />}
                    />
                    <Route path="/article" element={<ArticleCards />} />
                    <Route path="/article-page/:id" element={<ArticlePage />} />

                    {/* Learning Collection and subject categories templates */}
                    <Route
                      path="/learning-collections/:id"
                      element={<LearningCollections />}
                    />
                    <Route
                      path="/subject-categories/:id"
                      element={<SubjectCategories />}
                    />

                    {/* Values and Drivers Assessment */}
                    <Route
                      path="/:id/values-and-drivers"
                      element={<ValuesAndDrivers />}
                    />
                    <Route
                      path="/:id/values-and-drivers/assessment"
                      element={<VAndDassessment />}
                    />
                    <Route
                      path="/:id/values-and-drivers/review"
                      element={<VandDReviewPage />}
                    />
                    <Route
                      path="/:id/values-and-drivers/review-mobile-view"
                      element={<VandDReviewPageMobile />}
                    />
                    <Route
                      path="/:id/values-and-drivers/result-mobile-view"
                      element={<VandDResultPageMobile />}
                    />

                    <Route
                      path="/:id/values-and-drivers/result"
                      element={<VandDResultPage />}
                    />

                    {/* Identify Your Ideal Position Assessment */}
                    <Route
                      path="/:id/identify-your-ideal-position"
                      element={<IdentifyYourIdealPosition />}
                    />
                    <Route
                      path="/:id/identify-your-ideal-position/assessment"
                      element={<IYIPAssessment />}
                    />
                    <Route
                      path="/:id/identify-your-ideal-position/result"
                      element={<IYIPResult />}
                    />

                    {/* Strength Analysis Assessments*/}
                    <Route
                      path="/:id/strength-analysis"
                      element={<StrengthAnalysis />}
                    />
                    <Route
                      path="/:id/strength-analysis/interest-start"
                      element={<InterestStartPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/interest-question"
                      element={<InterestQuestionPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/interest-refining-question"
                      element={<InterestRefiningQuestionPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/interest-result"
                      element={<InterestResultPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/skill-start"
                      element={<SkillStartPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/skill-question"
                      element={<SkillsQuestionPage />}
                    />
                    <Route
                      path="/:id/strength-analysis/skill-result"
                      element={<SkillsResultPage />}
                    />

                    {/* Personality Assessments*/}
                    <Route
                      path="/:id/personality-assessment"
                      element={<PersonalityAssessment />}
                    />
                    <Route
                      path="/:id/personality-assessment/result/:add"
                      element={<PAResultPage />}
                    />

                    {/* Looking at Options Assessments */}
                    <Route
                      path="/:id/looking-at-options"
                      element={<LookingAtOptions />}
                    />
                    <Route
                      path="/:id/looking-at-options/career-path"
                      element={<CareerPath />}
                    />
                    <Route
                      path="/:id/looking-at-options/career-factors"
                      element={<CareerFactors />}
                    />
                    <Route
                      path="/:id/looking-at-options/review"
                      element={<LookingAtOptionsReview />}
                    />
                    <Route
                      path="/:id/looking-at-options/result"
                      element={<LookingAtOptionsResult />}
                    />

                    {/* Prepare for Interview */}
                    <Route
                      path="/:id/prepare-for-an-interview"
                      element={<PrepareForAnInterview />}
                    />
                    <Route
                      path="/:id/prepare-for-an-interview/aboutrole"
                      element={<PrepareForAnInterviewAboutRole />}
                    />
                    <Route
                      path="/:id/prepare-for-an-interview/assessment"
                      element={<PFAIAssessment />}
                    />
                    <Route
                      path="/:id/prepare-for-an-interview/result"
                      element={<PrepareForAnInterviewResult />}
                    />

                    {/* Thought Provoking Assessments */}
                    <Route
                      path="/:id/thought-provoking-questions/questions"
                      element={<TPQuestionsPage />}
                    />
                    <Route
                      path="/:id/thought-provoking-questions/results"
                      element={<TPResultspage />}
                    />
                    <Route
                      path="/:id/thought-provoking-questions"
                      element={<TPStartPage />}
                    />

                    {/* Review Accomplishments Assessments */}
                    <Route
                      path="/:id/reviewing-accomplishments"
                      element={<ReviewAccomplishments />}
                    />
                    <Route
                      path="/:id/reviewing-accomplishments/questions/:add"
                      element={<RAQuestionsPage />}
                    />
                    <Route
                      path="/:id/reviewing-accomplishments/review"
                      element={<RAReviewPage />}
                    />
                    <Route
                      path="/:id/reviewing-accomplishments/result"
                      element={<RAResultPage />}
                    />

                    {/* Evaluate a job offer Assessments */}
                    <Route
                      path="/:id/evaluate-a-job-offer"
                      element={<EvaluateJobOffer />}
                    />
                    <Route
                      path="/:id/evaluate-a-job-offer/start"
                      element={<EJOStartPage />}
                    />
                    <Route
                      path="/:id/evaluate-a-job-offer/questions"
                      element={<EJOQuestionsPage />}
                    />
                    <Route
                      path="/:id/evaluate-a-job-offer/result"
                      element={<EJOResultPage />}
                    />
                    <Route path="*" element={<ErrorHandler />} />
                  </Route>
                  <Route path="/" exact element={<Landing />} />
                  <Route path="/home" exact element={<Landing />} />
                  <Route
                    path="/register-firstTimeUser"
                    element={<RegisterFirstTimeUser />}
                  />
                  <Route path="/our-policy" element={<OurPolicy />} />
                  <Route path="/cookie-policy" element={<PolicyCookie />} />
                  <Route path="/privacy-policy" element={<PolicyPrivacy />} />
                  <Route path="/t&c-policy" element={<PolicyTC />} />
                  <Route path="/policy-summary" element={<PolicyUpdatedSummary />} />
                </Routes>
              </HashRouter>
              <ConfirmationPopup />
            </Context.Provider>
          </MsalProvider>
        </AppInsightsContext.Provider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
