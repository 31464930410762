//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';

const RApdfTemplate = ({ masterQuestionAnswer, cms }) => {

    const returnName2 = (key) => {
        let name = '';
        if (cms) {
            cms.map((x) => {
                if (x.key === key) {
                    name = x.name
                }
            })
        }
        return name;
    }

    return (
        <>
            <table cellPadding={10} cellSpacing={10} style={{ width: '90%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                <tbody>
                    <tr>
                        <td>
                            <Cirrus.Header size='h5' className='mb-4'>{masterQuestionAnswer?.reviewPage?.Title}</Cirrus.Header>
                            {
                                masterQuestionAnswer?.questionLists?.map((set) => {
                                    return (<div className='border-bottom mb-4'>
                                        <Cirrus.Headline size='Small' className='mb-3 text-start'>
                                            {set?.accomplishmentTitle}
                                        </Cirrus.Headline>
                                        {
                                            set?.questionAnswerList?.map((qa) => {
                                                return (
                                                    <>
                                                        <div className='mb-3'>
                                                            <label className='ra-result-titles'>{qa?.questionLabel}</label>
                                                            {
                                                                qa?.questionLabel === returnName2("Assessments.ReviewingAccomplishments.SkillsPage") ? <ul className="optionAnsUl">
                                                                    {
                                                                        qa?.optionsAnswerList?.map((op) => {
                                                                            if (op?.isSelected) {
                                                                                return (
                                                                                    <li>{op?.answerText}</li>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </ul> : <p> {qa?.textAnswerValue} </p>
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>)
                                })
                            }
                        </td>
                    </tr>
                </tbody>
                <span style={{ fontSize: '1px' }}>.</span>
            </table>
        </>
    )
}

export default RApdfTemplate;
