//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";

function JobMatchSetup(jobMatchCMS) {

    const context = useContext(Context);
    const navigate = useNavigate();

    const fetchJobMatchlabels = (key) => {

        let refCmsContent = jobMatchCMS?.jobMatchCMS && jobMatchCMS?.jobMatchCMS;
        let label = "";
        if (refCmsContent?.labels) {
            refCmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    }

    return (
        <>
            <div className="p-1 w-100 rounded-right jobseeker-job-cols"
            >
                <Cirrus.JobMatchSetupCard
                    bodyText={jobMatchCMS?.jobMatchCMS?.description}
                    heading={jobMatchCMS?.jobMatchCMS?.title}
                    isWide
                    primaryButtonProps={{
                        children: fetchJobMatchlabels("DashboardPage.DashboardJobMatchSetup.GetStarted"),
                        onClick: () => { context.setDashboardResumeFlag(true); navigate("/jobsearchPage"); }
                    }}
                />
            </div>
        </>
    );
}
export default JobMatchSetup;
