//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import './job.css';

function EventCard({ data, cmsCardContent, loadMoreJobHandler, savedDataLength, viewAll, saveHandler }) {

  const getDescription = (desc) => {
    return <Cirrus.RichText className='event-card' content={desc} />
  }

  const fetchLabel = (key) => {
    let label = '';
    if (Object.keys(cmsCardContent).length !== 0) {
      cmsCardContent?.masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase
        }
      })
    }
    return label;
  }

  const getEventStatus = (eventDetails) => {
    let statusLabel = '';
    if (eventDetails?.user_enrolled) {
      if (eventDetails?.status === 'Completed') {
        statusLabel = fetchLabel('completed');
      } else {
        statusLabel = fetchLabel('enrolled');
      }
    }
    return statusLabel
  }


  const getTags = (event, isOnDemand) => {
    let programTags = [];
    let recommended = false;
    let tagNames = [];
    if (isOnDemand) {
      programTags = event.focusArea?.map(tag => tag.id);
      recommended = event.isRecommended

    } else {
      programTags = event.program_category?.replace(/ /g, "").match(/.{1,3}/g);
      recommended = event.is_recommended;
    }

    tagNames = programTags && programTags.map((PCcode) => fetchLabel(PCcode))
    if (recommended) {
      if (tagNames) {
        tagNames.push(fetchLabel('Events.EventsCourseCard.Recommended'))
      } else {
        tagNames = [fetchLabel('Events.EventsCourseCard.Recommended')]
      }
    }

    return tagNames;
  }

  return (
    <>
      <ul className="jobListTwoColumn">
        {data?.map(bookmark => {
          if (bookmark && bookmark.event_id) {
            return (
              <li key={bookmark?.event_id}>
                <Cirrus.Card
                  title={bookmark?.event_name}
                  description={getDescription(bookmark?.event_description.split('</p>')[0])}
                  onCardClick={()=>{
                    window.open("#/eventDetails/:id/:isSaved".replace(":id", bookmark?.event_id).replace(":isSaved", bookmark?.isSaved ? bookmark.isSaved : false), "_self");
                  }}
                  tags={getTags(bookmark)}
                  image={{ src: bookmark?.image_thumbnail }}
                  saveButton={{
                    label:  fetchLabel("CommonComponent.CommonSaveButton"),
                    secondaryLabel:  fetchLabel("CommonComponent.CommonSavedButton"),
                    onClick: (e) => { saveHandler(bookmark) }
                  }}
                  isSaved={bookmark && bookmark.isSaved && bookmark.isSaved}
                  eventType={{
                    "eventDetails": [
                      fetchLabel(bookmark?.event_type)
                    ],
                    isSuccess: true,
                    status: getEventStatus(bookmark)
                  }}
                  type='event'
                  layout={'horizontal'}
                />
              </li>
            )
          }
          else {
            return (
              <li key={bookmark?.resourceId}>
                <Cirrus.Card
                  title={bookmark?.title}
                  description={bookmark?.shortDescription}
                  onCardClick={()=>{
                    window.open("#/article-page/:id".replace(":id", bookmark?.id), "_self");
                  }}
                  tags={getTags(bookmark, true)}
                  image={{ src: bookmark?.largeImage?.imageUrl }}
                  saveButton={{
                    label: fetchLabel("CommonComponent.CommonSaveButton"),
                    secondaryLabel: fetchLabel("CommonComponent.CommonSavedButton"),
                    onClick: (e) => { saveHandler(bookmark, true) }

                  }}
                  isSaved={bookmark && bookmark.isSaved && bookmark.isSaved}
                  type='resource'
                  layout="horizontal"
                  resourceType={{
                    name: 'video',
                    label: bookmark?.isAttended ? 'Watch Again' : 'Watch Now',
                    value: ''
                  }}
                />
              </li>
            )
          }
        })}
      </ul>
      {
        viewAll === "Events" && (savedDataLength && savedDataLength > 10 && savedDataLength !== data?.length) ? <>
          {
            <div style={{ paddingTop: '50px', width: '100%' }}>
              <Cirrus.Button
                onClick={loadMoreJobHandler}
              >
                {
                  'Load more'
                }
              </Cirrus.Button>
            </div>
          }
        </> : ''
      }
    </>
  )
}

export default EventCard
