//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import axios from "axios";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

class Interceptor {
  constructor() {}

  

  getInstanse = () => {
    let instanse = axios.create();
    instanse.interceptors.request.use(
      function (_request) {
        let accessToken = window.sessionStorage.getItem("AccessToken");
        if (accessToken !== "") {
          _request.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        _request.headers["Content-Type"] = "application/json";
        // _request.headers['Cache-Control']='No-Store';
        return _request;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instanse.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        
        //***App Insight SetUp***//
        var reactPlugin = new ReactPlugin();
        const browserHistory = createBrowserHistory({ basename: '' });
        var appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: process.env.REACT_APP_INSIGHT_KEY,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true,
            disableAjaxTracking:true,
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory },
            },
          },
        });
        appInsights.loadAppInsights();
        //***till here***//

        //**Payload below**//
        let ProgramDetails = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")):"";
        let ProfileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails")) && JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
        let payload
        if (JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null){
        payload = {
        "CandidateID": JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
        "Severity": SeverityLevel.Error.toString(),
        "Client Name": ProgramDetails?.companyName,
        "Program Class":ProgramDetails?.programClass,
        "Market":ProfileDetails?.preferredLanguageCode,
        "Time": new Date(),
        "Persona": ProfileDetails?.personaName,
        "Language": JSON.parse(window.sessionStorage.getItem("Language")) && JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageFullName']}
        }
        let url = error?.response?.config.url;
        let errorMessage = error?.response?.data?.errorMessage?error?.response?.data?.errorMessage:error?.response?.data?.message?error?.response?.data?.message:url;
        let status = error?.response?.status;
        let xCorrelationId = error?.response?.headers["x-correlation-id"]?error?.response?.headers["x-correlation-id"]: "no-correlation Id found";
        //**till here **//

        //**calling exception track **//
        reactPlugin.trackException({ error: {errorMessage},severityLevel:SeverityLevel.Error,properties:{"url":url,"status":status,"xCorrelationId":xCorrelationId,...payload} })
        //**till here */

        return error.response;
      }
    );
    return instanse;
  };
}

export default Interceptor;
