//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import './index.css';
import { coachActions } from "./coachActions";

function MeetingCancellationDailog({ fetchLabel, cmsContent, closeDialog, cancelMeetingData, ReloadComp, dialogStatus }) {

    const fnSaveCancelMeeting = async () => {
        await coachActions.DeleteMeeting(cancelMeetingData.id).then(async (res) => {
            if (res.status === 200) {
                ReloadComp((prev) => prev + 1)
                dialogStatus(false);
            }
            else {
                console.log(res);
                dialogStatus(false);
            }
        })
    }

    return (
        <>
            <Cirrus.AlertModal
                className="CancelMeeting"
                closeIconButtonProps={{
                    icon: "close",
                    isSecondary: true,
                    onClick: () => closeDialog(false),
                    text: "Cancel",
                    type: "button",
                }}
                heading={cmsContent.cancelMeeting_Title}
                primaryButton={{
                    children: fetchLabel(
                        "MyCoachPage.MyCoachCancelMeeting.CancelMeeting",
                        "cancelMeeting_Labels"
                    ),
                    onClick: () => fnSaveCancelMeeting(),
                    size: "Large",
                    type: "button",
                }}
                role="alertdialog"
                subheading={cmsContent?.cancelMeeting_Description}
            />
        </>
    );
}

export default MeetingCancellationDailog;
