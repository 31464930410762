//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import HeaderWithNavigation from "../header/headerWithNavigation";
import { Context } from "../../common/context/Context";
import { BookMarkActions } from "./bookmarkActions";
import { AssessmentActions } from "../assessments/AssessmentActions";
import Spinner from "../../common/components/spinner-loader/Spinner";
import Icon from "../../assets/fonts/icomoon/Icon";
import Footer from "../../common/components/footer";
import cardVertical from "manpowergroup-cirrus/assets/images/cardVertical.jpg";
import JobCard from "./jobList";
import Moment from "moment";
import "./job.css";
import { Row, Col, Container } from "react-bootstrap";
import Eventsimage from "./Eventsimage.png";
import Resourceimage from "./Resourceimage.png";
import AssessmentCard from "./assessmentList";
import ResourceCard from "./resourceList";
import EventCard from "./eventList";
import ToolCard from "./toolList";
import { DiscoverActions } from "../discover/DiscoverActions";
import StartBookMarkSection from "./startBookmarkSection";
import { TrackActions } from "../../common/utility/LogAction";
import { iViewActions } from "../Iview/iViewActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const BookMark = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const [screenLoader, setScreenLoader] = useState(false);
  const [viewAllPageItem, setViewAllPageItem] = useState("");
  const [savedData, setSavedData] = useState([]);
  const [savedjobs, setSavedjobs] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(1);
  const [loadAssessmentMoreCount, setLoadAssessmentMoreCount] = useState(1);
  const [loadEventMoreCount, setLoadEventMoreCount] = useState(1);
  const [saveLikeSpinner, setSaveLikeSpinner] = useState(false);
  const [loadResourceMoreCount, setLoadResourceMoreCount] = useState(1);
  const [loadToolMoreCount, setLoadToolMoreCount] = useState(1);
  const [savedStatus, setSavedStatus] = useState(false);
  const [allEventData, setAllEventData] = useState([]);
  const [onDemandEventData, setOnDemandEventData] = useState([]);
  const [eventCardData, setEventCardData] = useState([]);
  const [resourceCardData, setResourceCardData] = useState([]);
  const [allToolsData, setAllToolsData] = useState([]);
  const [toolsCardData, setToolsCardData] = useState([]);
  const [iviewToolsCardData, setIviewToolsCardData] = useState([]);
  const [assessmentCardData, setAssessmentCardData] = useState();
  const [tabText, setTabText] = useState([]);

  const [cmsJobCardContent, setCmsJobCardContent] = useState({
    browserTitle: "",
    description: "",
    metadata: "",
    pageContent: {},
  });
  const [paginationState, setPaginationState] = useState({
    desabledLoadMore: false,
    searchCountAndLabel: "",
    totalCounts: 0,
    fetchedCounts: 0,
    shownCounts: 0,
    payload: {},
  });
  const [bookMarkedCardCMS, setBookMarkedCardCMS] = useState({
    browserTitle: "",
    description: "",
    metadata: "",
    pageContent: {},
  });
  const [cmsJobSavedContent, setCmsJobSavedContent] = useState({
    browserTitle: "",
    description: "",
    metadata: "",
    pageContent: {},
  });
  const [cmsEventContent, setCMSEventContent] = useState({});
  const [bookamarkPageHeaderCMSContent, setBookamarkPageHeaderCMSContent] =
    useState({});

  const [jobSecondary, setJobSecondary] = useState(false);
  const [assessmentSecondary, setAssessmentSecondary] = useState(true);
  const [resourceSecondary, setResourceSecondary] = useState(true);
  const [eventSecondary, setEventSecondary] = useState(true);
  const [toolSecondary, setToolSecondary] = useState(true);
  const [collectionCount, setCollectionCount] = useState(0);
  const appInsights = useAppInsightsContext();
  let programDetails = JSON.parse(
    window.sessionStorage.getItem("setProgramDetails")
  );

  const fetchCMSData = () => {
    let refCmsJobcardContent = JSON.parse(JSON.stringify(cmsJobCardContent));
    let refCmsJobSavedContent = JSON.parse(JSON.stringify(cmsJobSavedContent));
    let payload = {
      PageName: "JobSearch Pages",
    };
    BookMarkActions.GetCMSBookmarkheaderLabels(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;

          resData?.Pages.filter((x) => {
            if (x.Key === "JobSearch.JobMatches") {
              refCmsJobcardContent.browserTitle = x.BrowserTitle;
              refCmsJobcardContent.description = x.Description;
              refCmsJobcardContent.metadata = x.Metadata;
              refCmsJobcardContent.pageContent =
                x.Components["JobSearch.JobCard"];
            }
          });
          setCmsJobCardContent(refCmsJobcardContent);

          resData?.Pages.filter((x) => {
            if (x.Key === "JobSearch.SavedJobs") {
              refCmsJobSavedContent.browserTitle = x.BrowserTitle;
              refCmsJobSavedContent.description = x.Description;
              refCmsJobSavedContent.metadata = x.Metadata;
              refCmsJobSavedContent.pageContent = x.Components;
            }
          });
          setCmsJobSavedContent(refCmsJobSavedContent);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchAssessmentData = (resourceType) => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    setScreenLoader(true);
    let payload = {
      learningResourceType: resourceType,
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: resourceType,
      ProgramClassIds:
        resourceType == "resources"
          ? ProgramData?.programClassId
            ? [(ProgramData?.programClassId).toString()]
            : ""
          : null,
    };
    BookMarkActions.GetResourceData(payload).then(
      (res) => {
        if (res.status === 200) {
          let data = res?.data && res?.data?.responsemodel;
          if (resourceType === "all") {
            const assessmentData = data.filter(
              (item) =>
                item.isSaved === true &&
                item?.resourceCategoryType?.id === "assessmentsandtasks"
            );
            setAssessmentCardData(assessmentData);

            const resourceData = data.filter(
              (item) =>
                item.isSaved === true &&
                item?.resourceCategoryType?.id === "resources"
            );
            setResourceCardData(resourceData);

            const toolsData = data.filter(
              (item) =>
                item.isSaved === true &&
                item?.resourceCategoryType?.id === "tools"
            );
            setToolsCardData(toolsData);
          }

          if (resourceType === "assessmentsandtasks") {
            const assessmentData = data.filter((item) => item.isSaved === true);
            setAssessmentCardData(assessmentData);
          }

          if (resourceType === "resources") {
            const resourceData = data.filter((item) => item.isSaved === true);
            setResourceCardData(resourceData);
          }

          if (resourceType === "tools") {
            const toolsData = data.filter((item) => item.isSaved === true);
            setToolsCardData(toolsData);
          }

          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchIviewToolData = async () => {
    let payload = {
      PageName: "OnDemandVideoInterview",
    };
    await BookMarkActions.GetIviewToolData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data;
          setCollectionCount(resData?.asessmentCount);

          let iviewToolsData = [];

          if (resData?.collectionSaved === true) {
            iviewToolsData.push(resData);
          }

          resData?.resourcePages?.forEach((item) => {
            item?.resourcePages?.forEach((subItem) => {
              if (subItem.issaved === true) {
                iviewToolsData.push(subItem);
              }
            });
          });
          setIviewToolsCardData(iviewToolsData);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchOnDemandEvents = async () => {
    setScreenLoader(true);
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    let payload = {
      learningResourceType: "e3",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "events",
      ResourceSubCategory: "E3",
      ProgramClassIds: ProgramData?.programClassId
        ? [ProgramData?.programClassId]
        : ["18"],
    };

    await BookMarkActions.GetEventsData(payload).then(
      (res) => {
        if (res.status === 200) {
          const eventData = res?.data?.filter((item) => item?.isSaved === true);
          setOnDemandEventData(eventData);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchAllEvents = async () => {
    setScreenLoader(true);
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    let payload = {
      learningResourceType: "e1",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "events",
      ResourceSubCategory: "E1",
      ProgramClassIds: ProgramData?.programClassId
        ? [ProgramData?.programClassId]
        : ["18"],
    };

    await BookMarkActions.GetEventsData(payload).then(
      (res) => {
        if (res.status === 200) {
          const eventData = res?.data?.filter((item) => item?.isSaved === true);
          setAllEventData(eventData);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const saveBookmarkedCard = async (e, type, bookmarkItem) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    e.preventDefault();
    let id = bookmarkItem && bookmarkItem.resourceId && bookmarkItem.resourceId;
    let resourceType = bookmarkItem?.resourceCategoryType?.id;
    let savedStatus =
      bookmarkItem && bookmarkItem.isSaved && bookmarkItem.isSaved;
    let likedStatus = bookmarkItem.isLiked && bookmarkItem.isLiked;
    let payload = {
      candidateId: candidateID,
      learningResourceId: id,
      lastAnsweredPageId:
        bookmarkItem.lastAnsweredPageId && bookmarkItem.lastAnsweredPageId,
      learningResourceType: resourceType,
      resourceTypeId: bookmarkItem?.resourceType?.id,
      learningResourceStatus:
        bookmarkItem &&
        bookmarkItem.learningResourceStatus &&
        bookmarkItem.learningResourceStatus,
      isSaved:
        type === "save" ? (savedStatus == true ? false : true) : savedStatus,
      isLiked:
        type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    };

    await BookMarkActions.SaveResourceCard(resourceType, id, payload).then(
      (resp) => {
        if (resp.status === 200) {
          let resData = resp.data;
          fetchAssessmentData(resourceType);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveEventCard = async (eventDetails, onDemand) => {
    let id = eventDetails.event_id
      ? eventDetails.event_id
      : eventDetails.id
      ? eventDetails.id
      : "";
    let payload = {
      ResourceType: "External",
      EventType: eventDetails.event_type ? eventDetails.event_type : "onDemand",
      IsSaved: !eventDetails.isSaved,
      EventName: onDemand ? eventDetails.title : eventDetails.event_name,
      STARTTIME: "00:00",
      ENDTIME: "00:00",
      ResourceId: eventDetails?.id,
      IsAttended: eventDetails?.isAttended,
      IsRegistered: eventDetails?.isRegistered,
      Status: eventDetails?.status,
      StartDate: eventDetails?.start_date,
      EndDate: eventDetails?.end_date,
      EventDate: eventDetails?.eventDate,
    };

    await BookMarkActions.SaveEventCard(payload, id).then(
      (res) => {
        if (res.status === 200) {
          if (onDemand) {
            fetchOnDemandEvents();
          } else {
            fetchAllEvents();
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveIviewCollection = (toolDetails) => {
    let countryCode = JSON.parse(sessionStorage.getItem("setProgramDetails"))[
      "programLanguageCode"
    ];
    const url = window.location.href;
    const env = url.split("#");
    const pay = {
      LearningResourceId: toolDetails?.id,
      LearningResourceStatus: "notstarted",
      CallbackURL: `${env[0]}#/iview-interview-done/${
        toolDetails?.externalId
      }/${toolDetails?.id}/${sessionStorage.getItem("AccessToken")}`,
      Languagecode: countryCode,
    };

    iViewActions.GetIviewInterview(pay, toolDetails?.externalId).then((res) => {
      let response = res?.data;

      let candidateID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ];
      let payload = {
        candidateId: candidateID,
        LearningResourceId: toolDetails?.id,
        LearningResourceType: "iView",
        IsDownloaded: false,
        IsSharedWithCoach: false,
        status: toolDetails?.status ? toolDetails?.status : "notstarted",
        LearningResourceStatus: toolDetails?.status
          ? toolDetails?.status
          : "notstarted",
        isSaved: !toolDetails?.issaved,
        isLiked: toolDetails?.isLiked,
        InterviewId: response?.interviewId,
        InterviewType: "Video",
        IsRetaken: false,
        RedirectUrl: response?.interviewUrl,
        RequisitionId: toolDetails?.externalId,
        Languagecode: countryCode,
      };

      iViewActions.SaveIviewAssessmentCard(toolDetails?.id, payload).then(
        (res) => {
          if (res.status === 200) {
            let resData = res.data;
            fetchIviewToolData();
            // setSaved(true)
          }
        },
        (err) => {
          console.log(err);
        }
      );
    });

    // if (toolDetails && toolDetails.collectionSaved) {
    //   let payload = {
    //     CollectionId: toolDetails?.id,
    //     isSaved: !toolDetails?.collectionSaved,
    //   };

    //   BookMarkActions.SaveIviewToolCollectionCard(payload).then(
    //     (res) => {
    //       if (res.status === 200) {
    //         fetchIviewToolData();
    //       }
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // }
    // else {
    //   let savedStatus = toolDetails && toolDetails.issaved && toolDetails.issaved;
    //   let id = toolDetails?.id;
    //   let type = 'save';
    //   let likedStatus = toolDetails.isLiked && toolDetails.isLiked;
    //   let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
    //   let payload = {
    //     candidateId: candidateID,
    //     LearningResourceId: id,
    //     LearningResourceType: "iView",
    //     LearningResourceStatus: "inprogress",
    //     IsDownloaded: false,
    //     IsSharedWithCoach: false,
    //     isSaved:
    //       type === "save" ? (savedStatus == true ? false : true) : savedStatus,
    //     isLiked:
    //       type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    //     InterviewId: "12345",
    //     InterviewType: "Video",
    //     IsRetaken: false,
    //     RedirectUrl: "test",
    //     RequisitionId: "2322",
    //   };

    //   BookMarkActions.SaveIviewToolCard(id, payload).then(
    //     (res) => {
    //       if (res.status === 200) {
    //         fetchIviewToolData();
    //       }
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // }
  };

  const saveIviewCollectionCard = (e, id, savedStatus) => {
    e.preventDefault();
    let payload = {
      CollectionId: id,
      isSaved: !savedStatus,
    };

    iViewActions.SaveIviewCollection(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          fetchIviewToolData();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveIview = (
    e,
    id,
    savedStatus,
    likedStatus,
    type,
    learningResourceStatus,
    pageNumber,
    resourceSubType,
    resourceCatType,
    origin
  ) => {
    e.preventDefault();
    let resourceType = resourceCatType;
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: id,
      lastAnsweredPageId: pageNumber,
      learningResourceType: resourceCatType,
      resourceTypeId: resourceSubType,
      learningResourceStatus: learningResourceStatus,
      isSaved:
        type === "save" ? (savedStatus == true ? false : true) : savedStatus,
      isLiked:
        type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    };

    DiscoverActions.SaveAssessmentCard(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          setSaveLikeSpinner(true);
          if (origin === "recommended") {
            fetchAssessmentData("assessments", origin);
          } else {
            fetchAssessmentData("tools", "SaveLike");
          }
          updateStatus(resourceType, id, payload);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveActivity2 = (type) => {
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: type.learningResourceId,
      value: type?.title || "null",
    };
    type.isLiked === false
      ? (payload["type"] =
          type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentLike"
            : type.resourceCategoryType.id.toLowerCase() === "tools"
            ? "ToolLike"
            : `${type.resourceType.id + "Like"}`)
      : (payload["type"] =
          type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentUnLike"
            : type.resourceCategoryType.id.toLowerCase() === "tools"
            ? "ToolUnLike"
            : `${type.resourceType.id + "UnLike"}`);
    AssessmentActions.CreateActivity(payload).then(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  };

  const updateStatus = (resourceType, id, payload) => {
    DiscoverActions.UpdateStatus(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          setSaveLikeSpinner(true);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveToolCard = (e, toolDetails, iView, collection) => {
    if (iView) {
      if (collection === "collection") {
        let cardData =
          toolsCardData &&
          toolsCardData.filter((x) => x.key == "Tools.OnDemandVideoInterviews");
        saveIview(
          e,
          cardData?.resourceId,
          cardData?.isSaved,
          cardData?.isLiked,
          "save",
          cardData?.learningResourceStatus,
          cardData?.lastAnsweredPageId,
          cardData?.resourceType?.id,
          cardData?.resourceCategoryType?.id,
          "na"
        );
        saveActivity2(cardData);
      } else {
        saveIviewCollection(toolDetails);
      }
    } else {
      saveBookmarkedCard(e, "save", toolDetails);
    }
  };

  const fetchJobCardLabel = (key) => {
    let refCmsJobCardContent = JSON.parse(JSON.stringify(cmsJobCardContent));
    let label = "";
    if (refCmsJobCardContent?.pageContent?.Labels) {
      refCmsJobCardContent?.pageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchJobSavedLabel = (Key) => {
    if (cmsJobSavedContent.pageContent["JobSearch.SavedList"]) {
      let refJobSavedLabel = JSON.parse(
        JSON.stringify(cmsJobSavedContent.pageContent["JobSearch.SavedList"])
      );
      let label = "";
      if (refJobSavedLabel?.Labels) {
        refJobSavedLabel?.Labels.map((x) => {
          if (x.Key === Key) {
            label = x.Phrase;
          }
        });
      }
      return label;
    }
  };

  const fetchStartBookmarkLabel = (key) => {
    let refCmsCardContent = JSON.parse(JSON.stringify(bookMarkedCardCMS));

    let label = "";
    if (refCmsCardContent?.pageContent?.Labels) {
      refCmsCardContent?.pageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchButtonCommonLabel = (key) => {
    let refCmsCardContent = JSON.parse(JSON.stringify(bookMarkedCardCMS));
    let label = "";
    if (refCmsCardContent?.commonComponent?.Labels) {
      refCmsCardContent?.commonComponent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchRichTexts = (key) => {
    let refCmsCardContent = JSON.parse(JSON.stringify(bookMarkedCardCMS));
    let label = "";
    if (refCmsCardContent?.pageContent?.RichTexts?.length > 0) {
      refCmsCardContent?.pageContent?.RichTexts.map((x) => {
        if (x.Key === key) {
          label = x.Value;
        }
      });
    }
    return label;
  };

  let allSaveJobList = [...savedjobs];

  const fetchJobSavedStatus = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refPaginationState = JSON.parse(JSON.stringify(paginationState));
    BookMarkActions.GetJobSavedStatus(candidateID)
      .then((res) => {
        if (res?.status === 200) {
          setSavedData(
            res.data && res.data.filter((item) => item.isSaved === true)
          );
          console.log(
            res.data && res.data.filter((item) => item.isSaved === true),
            "savedData"
          );
          allSaveJobList =
            res.data && res.data.filter((item) => item.isSaved === true);
          setSavedjobs(
            res.data && res.data.filter((item) => item.isSaved === true)
          );
          refPaginationState.searchCountAndLabel = fetchJobSavedLabel(
            "Job.SavedJobs.SavedList.ShowingResults"
          ).replace("#COUNT", allSaveJobList && allSaveJobList.length);
          setPaginationState(refPaginationState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const staticCopy = {
    CollapseJobInfo: "Hide job info",
    CompanyName: "Company",
    Compare: "Compare",
    ExpandJobInfo: "View job info",
    IsCompared: "Compare this job",
    IsFeatured: "This role is featured",
    IsNotCompared: "Remove this job from comparison",
    IsSaved: "Unsave this job",
    IsUnsaved: "Save this job",
    JobDescription: "Job Description",
    JobInfo: fetchJobCardLabel("JobSearch.JobCard.MoreInfo"),
    JobRole: "Role",
    JobType: "Type:",
    Location: "Location",
    Salary: "Salary:",
    Save: fetchJobCardLabel("JobSearch.JobCard.SavedButton"),
    StartDate: "StartDate",
  };

  const fnLoadMyProfileCSMData = async () => {
    try {
      let refCmsJobContent = JSON.parse(JSON.stringify(bookMarkedCardCMS));
      let refCmsEventContent = JSON.parse(JSON.stringify(cmsEventContent));
      let refCmsBookmarkPageHeaderContent = JSON.parse(
        JSON.stringify(bookamarkPageHeaderCMSContent)
      );
      let cmsBookmarkpayload = {
        PageName: "Bookmarked",
      };

      const res = await BookMarkActions.GetCMSBookmarkheaderLabels(
        cmsBookmarkpayload
      );
      if (res.status === 200) {
        res?.data?.Pages?.map((item) => {
          tabText.push({
            Key: item?.Key,
            TabNavigationTitle: item?.TabNavigationTitle,
          });
        });

        res?.data?.Pages?.filter((data) => {
          if (data.Key === "BookmarkedHome") {
            refCmsBookmarkPageHeaderContent["title"] = data?.Title;
            refCmsBookmarkPageHeaderContent["description"] = data?.Description;
            refCmsBookmarkPageHeaderContent["noBookmarkContent"] =
              data?.Components["Bookmarked.NoBookmarks"];
          }
        });

        res?.data?.Pages?.map((item) => {
          tabText.push({
            Key: item?.Key,
            TabNavigationTitle: item?.TabNavigationTitle,
          });
        });

        res.data?.Pages.filter((x) => {
          if (x.Key === "BookmarkedJobsList") {
            refCmsJobContent.browserTitle = x.BrowserTitle;
            refCmsJobContent.description = x.Description;
            refCmsJobContent.metadata = x.Metadata;
            refCmsJobContent.pageContent =
              x.Components["Bookmarked.StartBookmarking"];
            refCmsJobContent.commonComponent = x.Components["CommonComponent"];
          }
        });

        res.data?.Pages?.filter((data) => {
          if (data.Key === "BookmarkedEventsList") {
            refCmsEventContent.browserTitle = data?.BrowserTitle;
            let label = [...data?.Components?.CommonComponent?.Labels];
            data?.Components?.CommonComponent?.LOVList[0]?.Values?.map((x) => {
              let obj = {
                Key: x.ID,
                Phrase: x.Value,
              };
              label.push(obj);
            });
            data?.Components?.["Events.EventsCourseCard"]?.LOVList.map(
              (Lovs) => {
                Lovs?.Values?.map((x) => {
                  let obj = {
                    Key: x.ID,
                    Phrase: x.Value,
                  };
                  label.push(obj);
                });
              }
            );

            label = [
              ...label,
              ...data?.Components?.["Events.EventsCourseCard"]?.Labels,
            ];

            refCmsEventContent.masterLabel = label;
          }
        });

        setCMSEventContent(refCmsEventContent);
        setBookMarkedCardCMS(refCmsJobContent);
        setBookamarkPageHeaderCMSContent(refCmsBookmarkPageHeaderContent);
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const saveJobHandler = async (job) => {
    let refShownJobs = JSON.parse(JSON.stringify(savedData));
    const savedJobsId = savedjobs && savedjobs.map((job) => job.id);
    const inputJobId = job && job.id && job.id;
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let saveJobPayload = {
      OwnerId: candidateID,
      OwnerType: "Candidate",
      JobId: job.jobId,
      JobTitle: job.jobTitle,
      CompanyName: job.companyName ? job.companyName : "",
      JobPostedTimeStamp: job.postedTimeStamp,
      JobUrl: job.jobUrl,
      IsFeaturedJob: 1,
      IsSaved: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsBookmarked: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsDeleted: 0,
      LastSavedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      JobStartTimeStamp:
        job.jobStartTimeStamp === "Flexible Start"
          ? Moment(new Date()).format("MM-DD-YYYY")
          : job.jobStartTimeStamp,
      CreatedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      CreatedBy: "Admin",
      MatchPercentage: job.matchPercentage ? job.matchPercentage : "",
      JobLocation: job.jobLocation,
      Salary: job.salary,
      EmploymentType: job.employmentType,
      LastUnsavedTimeStamp: savedJobsId.includes(inputJobId)
        ? Moment(new Date()).format("MM-DD-YYYY")
        : null,
      UpdatedBy: candidateID,
      UpdatedTimeStamp: job.lastUpdatedDate,
      JobDescription: job.jobDescription,
    };
    await BookMarkActions.PostSaveJob(candidateID, saveJobPayload).then(
      (resp) => {
        if (resp.status === 200) {
          refShownJobs.map((x) => {
            if (x.id === inputJobId) {
              if (savedJobsId.includes(x.id)) {
                x.isSaved = false;
                var filtered = savedjobs.filter(function (job) {
                  return job.id != x.id;
                });
                setSavedjobs(filtered);
                setSavedStatus(!savedStatus);
              } else {
                x.isSaved = true;
                savedjobs.push(x);
                setSavedjobs(savedjobs);
              }
            }
            return x;
          });
          setSavedData(refShownJobs);
          console.log(refShownJobs, "refshownJobs");
        }
      },
      (err) => {
        console.log(err);
        setSavedData([]);
      }
    );
  };

  const loadMoreJobHandler = () => {
    setLoadMoreCount(loadMoreCount + 1);
  };

  const loadMoreAssessmentHandler = () => {
    setLoadAssessmentMoreCount(loadAssessmentMoreCount + 1);
  };

  const loadMoreResourcesHandler = () => {
    setLoadResourceMoreCount(loadResourceMoreCount + 1);
  };

  const loadMoreEventHandler = () => {
    setLoadEventMoreCount(loadEventMoreCount + 1);
  };

  const loadMoreToolHandler = () => {
    setLoadToolMoreCount(loadToolMoreCount + 1);
  };

  const jobCountLabel = fetchJobSavedLabel(
    "Job.SavedJobs.SavedList.ShowingResults"
  );
  const viewAllLabel = fetchButtonCommonLabel("CommonComponent.CommonViewAll");
  const pageOrgin = useParams();

  useEffect(() => {
    fetchJobSavedStatus();
    fnLoadMyProfileCSMData();
  }, [savedStatus]);

  useEffect(() => {
    fetchCMSData();
    fnLoadMyProfileCSMData();
  }, [context.language]);

  useEffect(() => {
    fetchAssessmentData("assessmentsandtasks");
    fetchAssessmentData("resources");
    fetchAssessmentData("tools");
    fetchOnDemandEvents();
    fetchAllEvents();
    fetchIviewToolData();
    TrackActions.PageViewRecord(appInsights, "Bookmark");
    TrackActions.PageAction("Bookmark");
    context.setPath("bookmark");
    sessionStorage.setItem("setPath", JSON.stringify("bookmark"));
  }, []);

  useEffect(() => {
    setEventCardData([...allEventData, ...onDemandEventData]);
  }, [allEventData, onDemandEventData]);

  useEffect(() => {
    setAllToolsData([...toolsCardData, ...iviewToolsCardData]);
    console.log(toolsCardData, "toolsCardData");
    console.log(iviewToolsCardData, "iviewToolsCardData");
  }, [toolsCardData, iviewToolsCardData]);

  const fetchTabText = (key) => {
    let refTabText = JSON.parse(JSON.stringify(tabText));
    let text = "";
    if (refTabText) {
      refTabText?.map((x) => {
        if (x.Key === key) {
          text = x.TabNavigationTitle;
        }
      });
    }
    return text;
  };

  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  function getFeaturesConfig(featureName) {
    let val;
    featuresConfig?.features?.map((feature) => {
      if (feature.featureName === featureName) {
        val = feature.isActive;
      }
    });
    return val;
  }

  return (
    <div>
      {/* <Cirrus.Page  className='assessment-page' header={<HeaderWithNavigation />} removeFooterPadding> */}
      <div className="bodyText">
        <Cirrus.PageBanner
          bodyText={bookamarkPageHeaderCMSContent?.description}
          heading={bookamarkPageHeaderCMSContent?.title}
          type="large"
        />
      </div>
      {screenLoader ? (
        <div>
          <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>
      ) : (
        <>
          {viewAllPageItem === "" ? (
            <>
              {(savedData && !!savedData.length) ||
              (resourceCardData && !!resourceCardData.length) ||
              (eventCardData && !!eventCardData.length) ||
              (assessmentCardData && !!assessmentCardData.length) ||
              (allToolsData && allToolsData.length) ? (
                <Cirrus.BookmarkedDashboardLayout>
                  {!featuresConfig?.isJobSearchLightFeatureAvailable && (
                    <>
                      {savedData && !!savedData.length ? (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          buttonProps={{
                            children: viewAllLabel,
                            isSecondary: true,
                            size: "Small",
                            onClick: () => {
                              setViewAllPageItem("Jobs");
                              setAssessmentSecondary(true);
                              setResourceSecondary(true);
                              setEventSecondary(true);
                              setJobSecondary(false);
                              setToolSecondary(true);
                              window.scrollTo(0, 0);
                            },
                          }}
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.JobsHeading"
                          )}
                        >
                          <div className="bookmark-landing bookmark-job-sections">
                            <JobCard
                              data={savedData.filter(
                                (data, index) => index < 2
                              )}
                              savedDataLength={savedData && savedData.length}
                              staticCopy={staticCopy}
                              layout={"twocolumn"}
                              saveJobHandler={saveJobHandler}
                              cmsJobCardContent={
                                cmsJobCardContent && cmsJobCardContent
                              }
                              paginationState={
                                jobCountLabel &&
                                jobCountLabel.replace(
                                  "#COUNT",
                                  allSaveJobList && allSaveJobList.length
                                )
                              }
                            />
                          </div>
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      ) : (
                        featuresConfig?.isJobSearchFeatureAvailable && (
                          <Cirrus.BookmarkedDashboardLayout.Section
                            heading={fetchStartBookmarkLabel(
                              "Bookmarked.StartBookmarking.JobsHeading"
                            )}
                          >
                            <StartBookMarkSection
                              title={fetchStartBookmarkLabel(
                                "Bookmarked.StartBookmarking.JobsTitle"
                              )}
                              description={fetchRichTexts(
                                "Bookmarked.StartBookmarking.JobsDescription"
                              )}
                              buttonText={viewAllLabel}
                              navigateUrl="jobsearchPage"
                            />
                          </Cirrus.BookmarkedDashboardLayout.Section>
                        )
                      )}
                    </>
                  )}

                  {(getFeaturesConfig("EventsPage") ||
                    featuresConfig?.isOnDemandEventFeatureAvailable) && (
                    <>
                      {eventCardData && !!eventCardData.length ? (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          buttonProps={{
                            children: viewAllLabel,
                            isSecondary: true,
                            size: "Small",
                            onClick: () => {
                              setViewAllPageItem("Events");
                              setEventSecondary(false);
                              setResourceSecondary(true);
                              setAssessmentSecondary(true);
                              setJobSecondary(true);
                              setToolSecondary(true);
                              window.scrollTo(0, 0);
                            },
                          }}
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.EventsHeading"
                          )}
                        >
                          <div className="bookmark-landing">
                            <EventCard
                              data={eventCardData?.filter(
                                (data, index) => index < 2
                              )}
                              savedDataLength={
                                eventCardData && eventCardData.length
                              }
                              cmsCardContent={
                                cmsEventContent && cmsEventContent
                              }
                              saveHandler={saveEventCard}
                            />
                          </div>
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      ) : (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.EventsHeading"
                          )}
                        >
                          <StartBookMarkSection
                            title={fetchStartBookmarkLabel(
                              "Bookmarked.StartBookmarking.EventsTitle"
                            )}
                            description={fetchRichTexts(
                              "Bookmarked.StartBookmarking.EventsDescription"
                            )}
                            buttonText={viewAllLabel}
                            navigateUrl="events"
                          />
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      )}
                    </>
                  )}

                  {programDetails?.programClass !== "Alumni" && (
                    <>
                      {resourceCardData && !!resourceCardData.length ? (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          buttonProps={{
                            children: viewAllLabel,
                            isSecondary: true,
                            size: "Small",
                            onClick: () => {
                              setViewAllPageItem("Resources");
                              setResourceSecondary(false);
                              setAssessmentSecondary(true);
                              setJobSecondary(true);
                              setEventSecondary(true);
                              setToolSecondary(true);
                              window.scrollTo(0, 0);
                            },
                          }}
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.ResourcesHeading"
                          )}
                        >
                          <div className="bookmark-landing">
                            <ResourceCard
                              data={resourceCardData?.filter(
                                (data, index) => index < 2
                              )}
                              savedDataLength={
                                resourceCardData && resourceCardData.length
                              }
                              cmsCardContent={
                                bookMarkedCardCMS && bookMarkedCardCMS
                              }
                              saveHandler={saveBookmarkedCard}
                            />
                          </div>
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      ) : (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.ResourcesHeading"
                          )}
                        >
                          <StartBookMarkSection
                            title={fetchStartBookmarkLabel(
                              "Bookmarked.StartBookmarking.ResourcesTitle"
                            )}
                            description={fetchRichTexts(
                              "Bookmarked.StartBookmarking.ResourcesDescription"
                            )}
                            buttonText={viewAllLabel}
                            navigateUrl="discover"
                          />
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      )}

                      {assessmentCardData && !!assessmentCardData.length ? (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          buttonProps={{
                            children: viewAllLabel,
                            isSecondary: true,
                            size: "Small",
                            onClick: () => {
                              setViewAllPageItem("Assessment");
                              setAssessmentSecondary(false);
                              setJobSecondary(true);
                              setResourceSecondary(true);
                              setEventSecondary(true);
                              setToolSecondary(true);
                              window.scrollTo(0, 0);
                            },
                          }}
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.AssessmentsTasksHeading"
                          )}
                        >
                          <div className="bookmark-landing">
                            <AssessmentCard
                              data={assessmentCardData?.filter(
                                (data, index) => index < 2
                              )}
                              savedDataLength={
                                assessmentCardData && assessmentCardData.length
                              }
                              cmsCardContent={
                                bookMarkedCardCMS && bookMarkedCardCMS
                              }
                              saveHandler={saveBookmarkedCard}
                            />
                          </div>
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      ) : (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.AssessmentsTasksHeading"
                          )}
                        >
                          <StartBookMarkSection
                            title={fetchStartBookmarkLabel(
                              "Bookmarked.StartBookmarking.AssessmentsTitle"
                            )}
                            description={fetchRichTexts(
                              "Bookmarked.StartBookmarking.AssessmentsDescription"
                            )}
                            buttonText={viewAllLabel}
                            navigateUrl="discover"
                          />
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      )}

                      {allToolsData && !!allToolsData.length ? (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          buttonProps={{
                            children: viewAllLabel,
                            isSecondary: true,
                            size: "Small",
                            onClick: () => {
                              setViewAllPageItem("Tools");
                              setToolSecondary(false);
                              setAssessmentSecondary(true);
                              setJobSecondary(true);
                              setResourceSecondary(true);
                              setEventSecondary(true);
                              window.scrollTo(0, 0);
                            },
                          }}
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.ToolsHeading"
                          )}
                        >
                          <div className="bookmark-landing">
                            <ToolCard
                              data={allToolsData?.filter(
                                (data, index) => index < 2
                              )}
                              savedDataLength={
                                allToolsData && allToolsData.length
                              }
                              cmsCardContent={
                                bookMarkedCardCMS && bookMarkedCardCMS
                              }
                              saveHandler={saveToolCard}
                              collectionCount={collectionCount}
                            />
                          </div>
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      ) : (
                        <Cirrus.BookmarkedDashboardLayout.Section
                          heading={fetchStartBookmarkLabel(
                            "Bookmarked.StartBookmarking.ToolsHeading"
                          )}
                        >
                          <StartBookMarkSection
                            title={fetchStartBookmarkLabel(
                              "Bookmarked.StartBookmarking.ToolsTitle"
                            )}
                            description={fetchRichTexts(
                              "Bookmarked.StartBookmarking.ToolsDescription"
                            )}
                            buttonText={viewAllLabel}
                            navigateUrl="discover"
                          />
                        </Cirrus.BookmarkedDashboardLayout.Section>
                      )}
                    </>
                  )}
                </Cirrus.BookmarkedDashboardLayout>
              ) : (
                <Cirrus.BookmarkedDashboardLayout>
                  <div className="start-bookmarking assessment-page">
                    <Container
                      fluid
                      className="assessment-review-result-wrapper assessment-result-wrapper text-center mb-5 p-5"
                    >
                      <Row>
                        <Col lg={12} className="m-auto">
                          <Cirrus.Headline size="Medium">
                            {
                              bookamarkPageHeaderCMSContent?.noBookmarkContent
                                ?.Title
                            }{" "}
                          </Cirrus.Headline>
                          <p>
                            {
                              bookamarkPageHeaderCMSContent?.noBookmarkContent
                                ?.Description
                            }
                          </p>
                          <div className="stack-buttons-mobile col-12 col-md-6 col-lg-12 m-auto">
                            {(featuresConfig?.isJobSearchFeatureAvailable ||
                              featuresConfig?.isJobSearchLightFeatureAvailable) && (
                              <Cirrus.Button
                                className="m-2"
                                isSecondary
                                onClick={() => {
                                  navigate("/jobsearchPage");
                                }}
                              >
                                {fetchStartBookmarkLabel(
                                  "Bookmarked.StartBookmarking.JobsHeading"
                                )}
                              </Cirrus.Button>
                            )}
                            <Cirrus.Button
                              className="m-2"
                              isSecondary
                              onClick={() => {
                                navigate("/events");
                              }}
                            >
                              {fetchStartBookmarkLabel(
                                "Bookmarked.StartBookmarking.EventsHeading"
                              )}
                            </Cirrus.Button>
                            {/* {programDetails?.programClass !== "Alumni" && <> */}
                            <Cirrus.Button
                              className="m-2"
                              isSecondary
                              onClick={() => {
                                navigate("/discover");
                              }}
                            >
                              {fetchStartBookmarkLabel(
                                "Bookmarked.StartBookmarking.ResourcesHeading"
                              )}
                            </Cirrus.Button>
                            <Cirrus.Button
                              className="m-2"
                              isSecondary
                              onClick={() => {
                                navigate("/discover");
                              }}
                            >
                              {fetchStartBookmarkLabel(
                                "Bookmarked.StartBookmarking.AssessmentsTasksHeading"
                              )}
                            </Cirrus.Button>
                            <Cirrus.Button
                              className="m-2"
                              isSecondary
                              onClick={() => {
                                navigate("/discover");
                              }}
                            >
                              {fetchStartBookmarkLabel(
                                "Bookmarked.StartBookmarking.ToolsHeading"
                              )}
                            </Cirrus.Button>
                            {/* </>} */}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Cirrus.BookmarkedDashboardLayout>
              )}
            </>
          ) : (
            <>
              <Cirrus.BookmarkedDashboardLayout>
                <div className="text-left w-100 mb-5 d-none d-lg-block">
                  <Cirrus.Button
                    className="m-2"
                    size={Cirrus.ButtonSizes.Large}
                    isAlt={true}
                    isSecondary={true}
                    onClick={() => {
                      setViewAllPageItem("");
                    }}
                  >
                    <Cirrus.Label>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icon icon="Vector" size={12} />
                      </span>
                    </Cirrus.Label>
                    <span style={{ marginLeft: "7px" }}>
                      {fetchTabText("BookmarkedHome")}
                    </span>
                  </Cirrus.Button>
                  {/* {programDetails?.programClass !== "Alumni" && <> */}
                  <Cirrus.Button
                    className="m-2"
                    size={Cirrus.ButtonSizes.Large}
                    isAlt={true}
                    isSecondary={assessmentSecondary}
                    onClick={() => {
                      setViewAllPageItem("Assessment");
                      setAssessmentSecondary(false);
                      setJobSecondary(true);
                      setResourceSecondary(true);
                      setEventSecondary(true);
                      setToolSecondary(true);
                    }}
                  >
                    {fetchTabText("BookmarkedAssessmentsList")}
                  </Cirrus.Button>
                  <Cirrus.Button
                    className="m-2"
                    size={Cirrus.ButtonSizes.Large}
                    isAlt={true}
                    isSecondary={resourceSecondary}
                    onClick={() => {
                      setViewAllPageItem("Resources");
                      setResourceSecondary(false);
                      setAssessmentSecondary(true);
                      setJobSecondary(true);
                      setEventSecondary(true);
                      setToolSecondary(true);
                    }}
                  >
                    {fetchTabText("BookmarkedResourcesList")}
                  </Cirrus.Button>
                  {/* </>} */}
                  {(getFeaturesConfig("EventsPage") ||
                    featuresConfig?.isOnDemandEventFeatureAvailable) && (
                    <Cirrus.Button
                      className="m-2"
                      size={Cirrus.ButtonSizes.Large}
                      isAlt={true}
                      isSecondary={eventSecondary}
                      onClick={() => {
                        setViewAllPageItem("Events");
                        setEventSecondary(false);
                        setResourceSecondary(true);
                        setAssessmentSecondary(true);
                        setJobSecondary(true);
                        setToolSecondary(true);
                      }}
                    >
                      {fetchTabText("BookmarkedEventsList")}
                    </Cirrus.Button>
                  )}
                  {!featuresConfig?.isJobSearchLightFeatureAvailable && (
                    <Cirrus.Button
                      className="m-2"
                      size={Cirrus.ButtonSizes.Large}
                      isAlt={true}
                      isSecondary={jobSecondary}
                      onClick={() => {
                        setViewAllPageItem("Jobs");
                        setAssessmentSecondary(true);
                        setResourceSecondary(true);
                        setEventSecondary(true);
                        setJobSecondary(false);
                        setToolSecondary(true);
                      }}
                    >
                      {fetchTabText("BookmarkedJobsList")}
                    </Cirrus.Button>
                  )}
                  {/* {programDetails?.programClass !== "Alumni" && <> */}
                  <Cirrus.Button
                    className="m-2"
                    size={Cirrus.ButtonSizes.Large}
                    isAlt={true}
                    isSecondary={toolSecondary}
                    onClick={() => {
                      setViewAllPageItem("Tools");
                      setAssessmentSecondary(true);
                      setResourceSecondary(true);
                      setEventSecondary(true);
                      setJobSecondary(true);
                      setToolSecondary(false);
                    }}
                  >
                    {fetchTabText("BookmarkedToolsList")}
                  </Cirrus.Button>
                  {/* </>} */}
                </div>
                <div className="d-block d-lg-none m-auto mb-3">
                  <Cirrus.Select
                    id="bookmark-filter"
                    label="Filter Bookmarks by"
                    name="bookmarkFilter"
                    className="text-center"
                    options={
                      !featuresConfig?.isJobSearchLightFeatureAvailable
                        ? [
                            {
                              name: fetchTabText("BookmarkedHome"),
                              value: fetchTabText("BookmarkedHome"),
                            },
                            {
                              name: fetchTabText("BookmarkedAssessmentsList"),
                              value: fetchTabText("BookmarkedAssessmentsList"),
                            },
                            {
                              name: fetchTabText("BookmarkedResourcesList"),
                              value: fetchTabText("BookmarkedResourcesList"),
                            },
                            {
                              name: fetchTabText("BookmarkedEventsList"),
                              value: fetchTabText("BookmarkedEventsList"),
                            },
                            {
                              name: fetchTabText("BookmarkedJobsList"),
                              value: fetchTabText("BookmarkedJobsList"),
                            },
                            {
                              name: fetchTabText("BookmarkedToolsList"),
                              value: fetchTabText("BookmarkedToolsList"),
                            },
                          ]
                        : [
                            {
                              name: fetchTabText("BookmarkedHome"),
                              value: fetchTabText("BookmarkedHome"),
                            },
                            {
                              name: fetchTabText("BookmarkedAssessmentsList"),
                              value: fetchTabText("BookmarkedAssessmentsList"),
                            },
                            {
                              name: fetchTabText("BookmarkedResourcesList"),
                              value: fetchTabText("BookmarkedResourcesList"),
                            },
                            {
                              name: fetchTabText("BookmarkedEventsList"),
                              value: fetchTabText("BookmarkedEventsList"),
                            },
                            {
                              name: fetchTabText("BookmarkedToolsList"),
                              value: fetchTabText("BookmarkedToolsList"),
                            },
                          ]
                    }
                    onChange={(e) => {
                      if (
                        e.target.value ===
                        fetchTabText("BookmarkedAssessmentsList")
                      ) {
                        setViewAllPageItem("Assessment");
                        setAssessmentSecondary(false);
                        setJobSecondary(true);
                        setResourceSecondary(true);
                        setEventSecondary(true);
                        setToolSecondary(true);
                      }
                      if (
                        e.target.value ===
                        fetchTabText("BookmarkedResourcesList")
                      ) {
                        setViewAllPageItem("Resources");
                        setResourceSecondary(false);
                        setAssessmentSecondary(true);
                        setJobSecondary(true);
                        setEventSecondary(true);
                        setToolSecondary(true);
                      }
                      if (
                        e.target.value === fetchTabText("BookmarkedEventsList")
                      ) {
                        setViewAllPageItem("Events");
                        setEventSecondary(false);
                        setResourceSecondary(true);
                        setAssessmentSecondary(true);
                        setJobSecondary(true);
                        setToolSecondary(true);
                      }
                      if (
                        e.target.value === fetchTabText("BookmarkedJobsList")
                      ) {
                        setViewAllPageItem("Jobs");
                        setAssessmentSecondary(true);
                        setResourceSecondary(true);
                        setEventSecondary(true);
                        setJobSecondary(false);
                        setToolSecondary(true);
                      }
                      if (
                        e.target.value === fetchTabText("BookmarkedToolsList")
                      ) {
                        setViewAllPageItem("Tools");
                        setAssessmentSecondary(true);
                        setResourceSecondary(true);
                        setEventSecondary(true);
                        setJobSecondary(true);
                        setToolSecondary(false);
                      }
                      if (e.target.value === fetchTabText("BookmarkedHome")) {
                        setViewAllPageItem("");
                      }
                    }}
                  ></Cirrus.Select>
                </div>
                {viewAllPageItem === "Jobs" && loadMoreCount == 1 ? (
                  <JobCard
                    data={savedData.filter((data, index) => index < 10)}
                    staticCopy={staticCopy}
                    layout={"twocolumn"}
                    viewAll={viewAllPageItem}
                    savedDataLength={savedData && savedData.length}
                    saveJobHandler={saveJobHandler}
                    paginationState={
                      jobCountLabel &&
                      jobCountLabel.replace(
                        "#COUNT",
                        allSaveJobList && allSaveJobList.length
                      )
                    }
                    cmsJobCardContent={cmsJobCardContent && cmsJobCardContent}
                    loadMoreJobHandler={loadMoreJobHandler}
                  />
                ) : viewAllPageItem === "Jobs" && loadMoreCount > 1 ? (
                  <JobCard
                    data={savedData.filter(
                      (data, index) => index < loadMoreCount * 10
                    )}
                    staticCopy={staticCopy}
                    layout={"twocolumn"}
                    viewAll={viewAllPageItem}
                    savedDataLength={savedData && savedData.length}
                    paginationState={
                      jobCountLabel &&
                      jobCountLabel.replace(
                        "#COUNT",
                        allSaveJobList && allSaveJobList.length
                      )
                    }
                    saveJobHandler={saveJobHandler}
                    cmsJobCardContent={cmsJobCardContent && cmsJobCardContent}
                    loadMoreJobHandler={loadMoreJobHandler}
                  />
                ) : (
                  ""
                )}
                {viewAllPageItem === "Resources" &&
                loadResourceMoreCount == 1 ? (
                  <ResourceCard
                    data={resourceCardData?.filter((data, index) => index < 10)}
                    savedDataLength={
                      resourceCardData && resourceCardData.length
                    }
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveBookmarkedCard}
                    loadMoreJobHandler={loadMoreResourcesHandler}
                  />
                ) : loadResourceMoreCount > 1 ? (
                  <ResourceCard
                    data={resourceCardData?.filter(
                      (data, index) => index < loadResourceMoreCount * 10
                    )}
                    savedDataLength={
                      resourceCardData && resourceCardData.length
                    }
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveBookmarkedCard}
                    loadMoreJobHandler={loadMoreResourcesHandler}
                  />
                ) : (
                  ""
                )}
                {viewAllPageItem === "Events" && loadEventMoreCount == 1 ? (
                  <EventCard
                    data={eventCardData?.filter((data, index) => index < 10)}
                    savedDataLength={eventCardData && eventCardData.length}
                    cmsCardContent={cmsEventContent && cmsEventContent}
                    viewAll={viewAllPageItem}
                    saveHandler={saveEventCard}
                    loadMoreJobHandler={loadMoreEventHandler}
                  />
                ) : loadEventMoreCount > 1 ? (
                  <EventCard
                    data={eventCardData?.filter(
                      (data, index) => index < loadEventMoreCount * 10
                    )}
                    savedDataLength={eventCardData && eventCardData.length}
                    cmsCardContent={cmsEventContent && cmsEventContent}
                    viewAll={viewAllPageItem}
                    saveHandler={saveEventCard}
                    loadMoreJobHandler={loadMoreEventHandler}
                  />
                ) : (
                  ""
                )}
                {viewAllPageItem === "Assessment" &&
                loadAssessmentMoreCount == 1 ? (
                  <AssessmentCard
                    data={assessmentCardData?.filter(
                      (data, index) => index < 10
                    )}
                    savedDataLength={
                      assessmentCardData && assessmentCardData.length
                    }
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveBookmarkedCard}
                    loadMoreJobHandler={loadMoreAssessmentHandler}
                  />
                ) : loadAssessmentMoreCount > 1 ? (
                  <AssessmentCard
                    data={assessmentCardData?.filter(
                      (data, index) => index < loadAssessmentMoreCount * 10
                    )}
                    savedDataLength={
                      assessmentCardData && assessmentCardData.length
                    }
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveBookmarkedCard}
                    loadMoreJobHandler={loadMoreAssessmentHandler}
                  />
                ) : (
                  ""
                )}
                {viewAllPageItem === "Tools" && loadToolMoreCount == 1 ? (
                  <ToolCard
                    data={allToolsData?.filter((data, index) => index < 10)}
                    savedDataLength={allToolsData && allToolsData.length}
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveToolCard}
                    loadMoreJobHandler={loadMoreToolHandler}
                    collectionCount={collectionCount}
                  />
                ) : loadToolMoreCount > 1 ? (
                  <ToolCard
                    data={allToolsData?.filter(
                      (data, index) => index < loadToolMoreCount * 10
                    )}
                    savedDataLength={allToolsData && allToolsData.length}
                    cmsCardContent={bookMarkedCardCMS && bookMarkedCardCMS}
                    viewAll={viewAllPageItem}
                    saveHandler={saveToolCard}
                    loadMoreJobHandler={loadMoreToolHandler}
                    collectionCount={collectionCount}
                  />
                ) : (
                  ""
                )}
                {/* <Cirrus.Button
                onClick={loadMoreJobHandler}
                className='loadMore'
                style={{ paddingTop: '50px', width: '100%' }}
              >
                {
                  'Load more'
                }
              </Cirrus.Button> */}
              </Cirrus.BookmarkedDashboardLayout>
            </>
          )}
        </>
      )}
      {/* </Cirrus.Page>
      <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
};

export default BookMark;
