//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useEffect, useState, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './index.css';
import { coachActions } from "./coachActions";
import { Context } from "../../common/context/Context";
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function NoCoachAssignment({ fetchLabel, cmsData, openNoCoachMeetingRequestDialog, compReload, cmsDataCoaching }) {
    const [coachMatchingstatus, setCoachMatchingstatus] = useState(false);
    const [meetingRescheduleStatus, setmeetingRescheduleStatus] = useState(false);
    const [meetingRescheduleData, setmeetingRescheduleData] = useState({});
    const context = useContext(Context);

    const getMeetingInvites = async () => {
        let payload = {
            "Status": "PendingInvites"
        }
        await coachActions.GetCoachingSessions(payload).then(res => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    res.data.map((ele) => {
                        if (ele.scheduledBy === "Support") {
                            setmeetingRescheduleData(ele)
                            return setmeetingRescheduleStatus(true)
                        }
                    })
                }
                else {
                    return setmeetingRescheduleStatus(false)
                }
            }
        })
    }
    const getCoachMatchingStatus = async () => {
        await coachActions
            .GetMyCoachmatching()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data === true) {
                        setCoachMatchingstatus(res.data)
                        setTimeout(() => {
                            getMeetingInvites()
                        }, 4000)
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getNoCoachSectionHeaderText = (sectionName) => {
        let key = "";
        switch (sectionName) {

            case "candidateName":
                key = "MyCoachPage.MyCoachCoachingSessions.CandidateName";
                break;
            case "accept":
                key = "MyCoachPage.MyCoachCoachingSessions.Accept";
                break;
            case "reschedule":
                key = "MyCoachPage.MyCoachCoachingSessions.Reschedule";
                break;
        }
        let header = cmsDataCoaching.Labels.filter((data) => data.Key === key);
        return header[0];
    };

    return (
        <>
            {(coachMatchingstatus === false && meetingRescheduleStatus === false) &&
                <Container fluid className="nocoach-container">
                    <Row className="coachContainerRow">
                        <Col xs="12" lg="2" className='noCoach-container'>
                            <img src={require("./Support.png")} alt='Coach Profile Image' className="coachProfileImage" />
                        </Col>
                        <Col md="xs" lg="7" className='ps-lg-4 ps-0'>
                            <div className="no-coach-title">{cmsData && cmsData?.noCoachTitle}</div>
                            <div className="no-coach-description">{cmsData && cmsData?.noCoachDescription}</div>
                        </Col>
                        <Col md="xs" lg="3" className="nocoach-request_meeting d-flex align-items-center justify-content-start justify-content-lg-center">
                            <Cirrus.Button
                                onClick={() => { openNoCoachMeetingRequestDialog(true, 0, {}) }}
                                className='request-meeting-button'
                            >
                                {fetchLabel("MyCoachPage.NoSpecificCoach.RequestMeeting", "noCoachLabels")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
            }

            {/* {(coachMatchingstatus === true && meetingRescheduleStatus === false) &&
                <Container fluid>
                    <Row className="coachContainerRow">
                        <Col xs="12" lg="2">
                            <img src={require("./Support.png")} alt='Coach Profile Image' className="coachProfileImage" />
                        </Col>
                        <Col md="xs" lg="7">
                            <div className="no-coach-title">{cmsData && cmsData?.awatingCoachTitle}</div>
                            <div className="no-coach-description">{cmsData && cmsData?.awatingCoachDescription}</div>
                        </Col>
                    </Row>
                </Container>
            } */}

            {/* {(meetingRescheduleStatus === true) &&
                <Container fluid>
                    <Row className="coachMeetingRequestContainerRow">
                      
                        <Col md="xs" lg="9">
                            <div className="no-coach-title-Events"> {getNoCoachSectionHeaderText("candidateName")?.Phrase.replace("#NAME", context?.idTokenClaims?.firstName)}</div>
                            <div className="no-coach-description-Events"><i class="fa fa-calendar" aria-hidden="true"></i>{startdate} {sStartTime} -  {sEndTime}</div>
                        </Col>
                        <Col md="xs" lg="3">


                            <Cirrus.Button
                                onClick={() => { openNoCoachMeetingRequestDialog(true,1,meetingRescheduleData) }}
                                className='request-meeting-button'
                            >
                                {getNoCoachSectionHeaderText("reschedule")?.Phrase}
                            </Cirrus.Button> &nbsp;
                            <Cirrus.Button
                                onClick={() => { }}
                                className='request-meeting-button'
                                disabled={true}
                            >
                                {getNoCoachSectionHeaderText("accept")?.Phrase}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
            } */}
        </>
    );
}

export default NoCoachAssignment;
