//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

const PrepareForAnInterview = () => {
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsPrepareForInterviewContent, setCmsPrepareForInterviewContent] = useState();
    const [startPageData, setStartPageData] = useState()
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));
    const [assessmentStatus, setAssessmentStatus] = useState()
    const [isLiked, setIsLiked] = useState(false)
    const [isSaved, setIsSaved] = useState(false)

    useEffect(() => {
        fetchCMSData()
        fetchStartPageData();
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        setScreenLoader(true);
        let refCmsPrepareForInterviewContent = {};
        let payload = {
            PageName: "PrepareForAnInterview"
        };
        await AssessmentActions.GetAssessmentCMS(payload).then(async (res) => {
            if (res.status === 200) {
                let resData = res.data;
                refCmsPrepareForInterviewContent.browserTitle = resData.BrowserTitle;
                refCmsPrepareForInterviewContent.metaDescription = resData.MetaDescription;
                refCmsPrepareForInterviewContent.metadata = resData.Metadata;
                refCmsPrepareForInterviewContent.title = resData.Title;
                refCmsPrepareForInterviewContent.focusArea = resData.FocusArea[0];
                refCmsPrepareForInterviewContent.resourceType = resData.ResourceType;
                refCmsPrepareForInterviewContent.resourceCategoryType = resData.ResourceCategoryType;
                refCmsPrepareForInterviewContent.duration = resData.Duration;
                refCmsPrepareForInterviewContent.description = resData.Description;
                refCmsPrepareForInterviewContent.shortDescription = resData.ShortDescription;
                refCmsPrepareForInterviewContent.subTitle = resData.SubTitle;
                refCmsPrepareForInterviewContent.internalAssessmentId = resData.ID;
                refCmsPrepareForInterviewContent.externalAssessmentId = resData.ExternalId;
                refCmsPrepareForInterviewContent.commonLabels = resData.Components.CommonComponent.Labels;
                refCmsPrepareForInterviewContent.commonResourceLabels = resData.Components.CommonResourceComponent.Labels;
                refCmsPrepareForInterviewContent.commonResourceImages = resData.Components.CommonResourceComponent.ImageList;
                refCmsPrepareForInterviewContent.prepareForAnInterviewLabels = resData.Components?.PrepareForAnInterview_Common_Labels;
                refCmsPrepareForInterviewContent.prepareForAnInterviewCommonLabels = resData.Components?.PrepareForAnInterview_Common_Labels?.Labels;
                refCmsPrepareForInterviewContent.largeImage = resData.LargeImage.ImageUrl;
                refCmsPrepareForInterviewContent.mediumImage = resData.MediumImage;
                refCmsPrepareForInterviewContent.smallImage = resData.SmallImage;
                refCmsPrepareForInterviewContent.commonStatus = resData.Components.CommonComponent.LOVList[0].Values;
                refCmsPrepareForInterviewContent.assessmentQuestionSet = resData.ChildPages.filter(x => x.Key !== "Assessments.PrepareForAnInterview.ResultsPage");


                let questionsArr = [];
                refCmsPrepareForInterviewContent.assessmentQuestionSet.map(assessment => {
                    Object.keys(assessment.Components).forEach(key => {
                        if (key === "Resources.Assessments.PrepareForAnInterview.AboutTheRole") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                            refCmsPrepareForInterviewContent.aboutTheRoleLabels = assessment.Components[key].Labels
                            refCmsPrepareForInterviewContent.uploadModalTitle = assessment?.Components["Resources.Assessments.PrepareForAnInterview.UploadAJob"]?.Title;
                        }
                        if (key === "Resources.Assessments.PrepareForAnInterview.UploadAJob") {
                            refCmsPrepareForInterviewContent.uploadAJob = assessment.Components[key]
                        }
                        if (key === "Resources.Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                        if (key === "PrepareForAnInterview_Rationale") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                        if (key === "PrepareForAnInterview_Questions") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                    })
                    if (assessment.Key === "Assessments.PrepareForAnInterview.SelectAnAppliedJob") {
                        questionsArr.push(assessment)
                    }
                });

                refCmsPrepareForInterviewContent.assessmentQuestionSet = questionsArr;

                refCmsPrepareForInterviewContent.assessmentQuestionSet.map(assessment => {
                    if (assessment.Key === "Assessments.PrepareForAnInterview.AboutTheOrganisation") {
                        let questionList = assessment.QuestionList;
                        let mergedQuestions;
                        Object.keys(assessment.Components).forEach(key => {
                            if (key === "PrepareForAnInterview_AboutTheOrganisation") {
                                mergedQuestions = ([...questionList, ...assessment.Components[key].QuestionList])
                            }
                        });
                        questionsArr?.map(questionList => {
                            if (questionList.Key === "Assessments.PrepareForAnInterview.AboutTheOrganisation") {
                                Object.keys(questionList).forEach(key => {
                                    if (key === "QuestionList") {
                                        questionList[key] = mergedQuestions
                                    }
                                })
                            }
                        })
                    }
                });


                refCmsPrepareForInterviewContent.selectAnAppliedJob = resData.ChildPages.filter(x => x.Key === "Assessments.PrepareForAnInterview.SelectAnAppliedJob")[0];
                refCmsPrepareForInterviewContent.resultPage = resData.ChildPages.find(x => x.Key === "Assessments.PrepareForAnInterview.ResultsPage")

                ActivityAction.SaveActivity(refCmsPrepareForInterviewContent?.internalAssessmentId, "startassessment", refCmsPrepareForInterviewContent?.title)
                TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": refCmsPrepareForInterviewContent?.internalAssessmentId, "Entity Name": refCmsPrepareForInterviewContent?.title, "CardType": "Assessment" })
                TrackActions.PageAction("ViewCard", { "Entity Id": refCmsPrepareForInterviewContent?.internalAssessmentId, "Entity Name": refCmsPrepareForInterviewContent?.title, "CardType": "Assessment" })
                setCmsPrepareForInterviewContent(refCmsPrepareForInterviewContent);
                context.setCmsPrepareForInterviewContent(refCmsPrepareForInterviewContent);
                setScreenLoader(false);
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }


    const fetchStartPageData = async () => {
        let resourceType = "assessmentsandtasks";
        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }
        await AssessmentActions.GetStartPageData(payload).then((res) => {
            if (res.status === 200) {
                let resData = res?.data && res?.data?.responsemodel;
                let refData = resData?.filter(x => x.key === "Assessments.PrepareForAnInterview")
                setStartPageData(refData && refData[0] && refData[0]);
            }
        }, (err) => {
            console.log(err);
        })
    }

    const fetchCommonLabel = (key) => {
        let refCmsPrepareForInterviewContent = JSON.parse(JSON.stringify(cmsPrepareForInterviewContent));
        let label = '';
        if (refCmsPrepareForInterviewContent?.commonLabels) {
            refCmsPrepareForInterviewContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsPrepareForInterviewContent = JSON.parse(JSON.stringify(cmsPrepareForInterviewContent));
        let label = '';
        if (refCmsPrepareForInterviewContent?.commonResourceLabels) {
            refCmsPrepareForInterviewContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsPrepareForInterviewContent = JSON.parse(JSON.stringify(cmsPrepareForInterviewContent));
        let id = '';
        if (refCmsPrepareForInterviewContent?.commonStatus) {
            refCmsPrepareForInterviewContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const fetchCommonStatus = (key) => {
        let refCmsPrepareForInterviewContent = JSON.parse(JSON.stringify(cmsPrepareForInterviewContent));
        let label = '';
        if (refCmsPrepareForInterviewContent?.commonStatus) {
            refCmsPrepareForInterviewContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const saveAndLikeHandler = (type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": cmsPrepareForInterviewContent.internalAssessmentId,
            "learningResourceStatus": startPageData.learningResourceStatus,
            "learningResourceType": cmsPrepareForInterviewContent.resourceCategoryType?.ID,
            "resourceTypeId": cmsPrepareForInterviewContent.resourceType?.ID,
            "isSaved": type === "save" ? startPageData.isSaved == true ? false : true : startPageData.isSaved,
            "isLiked": type === "like" ? startPageData.isLiked == true ? false : true : startPageData.isLiked,
            "lastAnsweredPageId": startPageData.lastAnsweredPageId,
            "isDownloaded": startPageData?.isDownloaded,
            "isSharedWithCoach": startPageData?.isSharedWithCoach,
            "resultDocumentId": startPageData?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(cmsPrepareForInterviewContent.resourceCategoryType?.ID, cmsPrepareForInterviewContent.internalAssessmentId, payload).then(res => {
            if (res.status === 200) {

                AssessmentActions.GetAssessmentStatusAndSavedResponseById(cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
                    if (res.status === 200) {
                        setAssessmentStatus(res.data)
                        setIsSaved(res.data.isSaved)
                        setIsLiked(res.data.isLiked)
                        setStartPageData(res.data)
                    }
                }, err => {
                    console.log(err)
                })
            }
        }, err => {
            console.log(err);
        })
    }

    const updateAssessmentStatus = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setScreenLoader(true)
        if (startPageData.learningResourceStatus === fetchStatusID("ResourceStatus.Completed")) {
            AssessmentActions.GetJobRole(context.cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    MyDocumentsActions.DeleteDocument(res.data.documentId).then(res => {
                        if (res.status === 200) {
                        } else {
                            console.log(res)
                        }
                    }, err => {
                        console.log(err)
                    })
                }
            }, err => {
                console.log(err)
            })
            AssessmentActions.RetakePrepareInterview(cmsPrepareForInterviewContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsPrepareForInterviewContent?.internalAssessmentId, "InternalAssessmentName": cmsPrepareForInterviewContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsPrepareForInterviewContent?.internalAssessmentId, "InternalAssessmentName": cmsPrepareForInterviewContent?.title })
                    setScreenLoader(false);
                    navigate("/" + pageOrgin.id + "/prepare-for-an-interview/assessment");
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })

        } else {
            let payload = {
                "candidateId": candidateID,
                "learningResourceId": cmsPrepareForInterviewContent.internalAssessmentId,
                "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
                "learningResourceType": cmsPrepareForInterviewContent.resourceCategoryType?.ID,
                "resourceTypeId": cmsPrepareForInterviewContent.resourceType?.ID,
                "isSaved": startPageData.isSaved,
                "isLiked": startPageData.isLiked,
                "lastAnsweredPageId": startPageData.lastAnsweredPageId,
                "isDownloaded": startPageData?.isDownloaded,
                "isSharedWithCoach": startPageData?.isSharedWithCoach,
                "resultDocumentId": startPageData?.resultDocumentId
            }
            AssessmentActions.SaveAssessmentStatus(cmsPrepareForInterviewContent.resourceCategoryType?.ID, cmsPrepareForInterviewContent.internalAssessmentId, payload).then(async (res) => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsPrepareForInterviewContent?.internalAssessmentId, "InternalAssessmentName": cmsPrepareForInterviewContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsPrepareForInterviewContent?.internalAssessmentId, "InternalAssessmentName": cmsPrepareForInterviewContent?.title })
                    setScreenLoader(false)
                    navigate("/" + pageOrgin.id + "/prepare-for-an-interview/assessment")
                } else {
                    setScreenLoader(false)
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        }
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            {
                cmsPrepareForInterviewContent &&
                <>
                    <Container fluid className='background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20'>
                        <Row className='justify-content-between'>
                            <Col md="12" lg="7" className='mb-3 pb-lg-5'>
                                <Cirrus.Button isSecondary onClick={() => { navigate(`/${context.path ? context.path : path}`) }} className="mb-lg-5 mb-3">{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                <Cirrus.Header size='h3' className='assessment-title'>{cmsPrepareForInterviewContent?.title}</Cirrus.Header>
                                <div className='save-section mt-4 mb-3'>
                                    <button className='saveBtn' onClick={() => { saveAndLikeHandler('save') }}>
                                        {startPageData?.isSaved === true ? <i className='icon-saved'></i> : <i className='icon-save'></i>}
                                        <span className='saveBtnText'>
                                            {startPageData?.isSaved === true ? fetchCommonResourceLabel("CommonResourceComponent.Saved") : fetchCommonLabel("CommonComponent.CommonSaveButton")}
                                        </span>
                                    </button>
                                    <Cirrus.Label id='category-label'>{cmsPrepareForInterviewContent?.focusArea?.Value}</Cirrus.Label>
                                    <Cirrus.Label id="cardtype">
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                            <>
                                                {cmsPrepareForInterviewContent?.resourceType?.Value} <sup>. </sup>
                                                <span>{cmsPrepareForInterviewContent?.duration}</span>
                                            </>
                                        }

                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.InProgress")}
                                            </>
                                        }
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.Completed")}
                                            </>
                                        }
                                    </Cirrus.Label>
                                </div>
                                <div>
                                    <Cirrus.Button size='small' className='likeBtn' onClick={() => { saveAndLikeHandler('like') }}>
                                        {startPageData?.isLiked === true ? <Icon icon="heart_fill" size={16} color="#9D323D" /> : <Icon icon="heart" size={16} color="#000" />}
                                        <span className='likeBtnText'>
                                            {/* {startPageData?.likeCount <= 1 ? startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() : startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()} */}
                                            {
                                                startPageData?.likeCount == 0 ? fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                                    startPageData?.likeCount == 1 ? startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() :
                                                        startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()
                                            }
                                        </span>
                                    </Cirrus.Button>
                                </div>
                            </Col>
                            <Col md="12" lg="5" className='position-relative'>
                                <div className='assesment_startpage-image-wrapper'>
                                    <img src={cmsPrepareForInterviewContent.largeImage} alt='sideimage' className='image-overflow' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='p-lg-5 p-3 pb-0'>
                        <Row>
                            <Col md="12" lg="6" className='assessment_second-container-intro'>
                                <Cirrus.Headline size='Medium' className='mb-5  assessment_introHeading'>{cmsPrepareForInterviewContent?.shortDescription}</Cirrus.Headline>
                                <Cirrus.Header size='h5' className='mb-3'>{cmsPrepareForInterviewContent?.subTitle}</Cirrus.Header>
                                <p className='mb-3'>
                                    <Cirrus.RichText content={cmsPrepareForInterviewContent?.description} className="instructions" />
                                </p>

                                <Cirrus.Button className='my-4' onClick={() => { updateAssessmentStatus(cmsPrepareForInterviewContent?.internalAssessmentId) }}>
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonStartButton")}
                                        </>
                                    }

                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonContinueButton")}
                                        </>
                                    }
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                        <>
                                            {fetchCommonLabel("CommonComponentCommonRetakeButton")}
                                        </>
                                    }
                                </Cirrus.Button>

                            </Col>
                            <Col md="6" lg="6">

                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default PrepareForAnInterview;
