//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './index.css';

function CoachList({ fetchLabel, mycoachData, fetchImage }) {
    const [displayCoachBio, setDisplayCoachBio] = useState(false);
    const [socialLink, setSocialLink] = useState({
        "linkedin": "",
        "twitter": ""
    });
    
    
    const handleChangeBio = (e) => {
        e.preventDefault();
        e?.stopPropagation();
        setDisplayCoachBio((prevCount) => !prevCount);
    }

    if(mycoachData?.socialIdDetails !== "") {
        let socialLinkSpltVal = mycoachData?.socialIdDetails?.toLowerCase().split(",");
        if(socialLinkSpltVal !== undefined && socialLinkSpltVal.length > 0 && socialLink?.linkedin === "" && socialLink?.twitter === "") {
            let refSocialLink = JSON.parse(JSON.stringify(socialLink));
            socialLinkSpltVal.map((item)=> {
                if(item.includes("linkedin")) {
                    refSocialLink.linkedin = item.trim();
                } else if(item.includes("twitter")) {
                    refSocialLink.twitter = item.trim();
                } else if(item.includes("null")) {
                    refSocialLink = ""
                }
            })
            setSocialLink(refSocialLink)
        }
    }

    const openLink = (link) => {
        if(link.includes('http://') || link.includes('https://')) {
            window.open(link, '_blank')
        } else {
            window.open('http://' + link, '_blank')
        }
    }


    return (
        <>
            <Container fluid className="coach-session p-4 mb-4">
                <Row>
                    <Col lg='4'>
                        <Row>
                            {/* <Col lg='5' md='4' xs={{ span: 'auto' }}>
                                <img src={require("./Rectangle.png")} alt='Coach Profile Image' className="coachProfileImage img-fluid" />
                            </Col> */}
                            <Col lg='12' md='12' xs={{ span: 'auto' }}>
                                <div className="coachTitle">{fetchLabel("MyCoachPage.MyCoachCoachAssigned.MyCoach", "labels")}</div>
                                <div className="coachName font-weight-bold">{mycoachData?.firstName + " " + mycoachData?.lastName}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='8'>
                        <Row>
                            <Col lg='5'>
                                <div className="coachTitle">{fetchLabel("MyCoachPage.MyCoachCoachAssigned.Connect", "labels")}</div>
                                <div className="coachName"><a href={"mailto:" + mycoachData?.emailAddress} className="emailLink">{mycoachData?.emailAddress}</a></div>
                            </Col>
                            <Col lg='4'>
                                <div className="coachTitle">{fetchLabel("MyCoachPage.MyCoachCoachAssigned.Expertise", "labels")}</div>
                                <div className="coachName">{mycoachData?.expertise}</div>
                            </Col>
                            <Col lg='3'>
                                <div className="coachTitle">{fetchLabel("MyCoachPage.MyCoachCoachAssigned.Languages", "labels")}</div>
                                <div className="coachName">{mycoachData?.language}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="coach-bio-section">
                    {mycoachData?.bio || mycoachData?.coachMessage ?
                        <Col lg={{ order: '1', span: '4' }} xs={{ order: '1', span: '7' }}>
                            <Row>
                                {/* <Col lg='5'></Col> */}
                                <Col lg='7' className="coach-bio-btn">

                                    <Cirrus.Button size="Large"
                                        type="button"
                                        className={displayCoachBio ? "coachActivButton" : "CoachBioInactive"}
                                        isSecondary={!displayCoachBio ? true : false}
                                        onClick={handleChangeBio}>
                                        {!displayCoachBio ? fetchLabel("MyCoachPage.MyCoachCoachAssigned.ViewFullBio", "labels") : fetchLabel("MyCoachPage.MyCoachCoachAssigned.CloseBio", "labels")}
                                        {!displayCoachBio &&
                                            <span className="coachBiobuttonCollapse">
                                            </span>
                                        }
                                        &nbsp;
                                        {displayCoachBio &&
                                            <span className="closeCoachBio" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        }
                                    </Cirrus.Button>

                                </Col>
                            </Row>
                        </Col>
                        : ""}
                    <Col lg={{ order: '2', span: '8' }} xs={{ order: '2', span: mycoachData?.bio || mycoachData?.coachMessage ? '5' : '12' }} className={mycoachData?.bio || mycoachData?.coachMessage ? "" : "ms-auto"}>
                        <Row>
                            <Col lg='12'>
                                <div
                                    className={mycoachData?.bio || mycoachData?.coachMessage ? "social-icon text-end text-lg-start" : "social-icon"}
                                >
                                    {socialLink.linkedin !== "" ? <div onClick={()=> openLink(socialLink.linkedin)}><img src={require("./linkidin.png")} alt='Linkidin Icon Image' /></div> : ''}
                                    {socialLink.twitter !== "" ? <div onClick={()=> openLink(socialLink.twitter)}><img src={require("./twitter.png")} alt='Twitter Icon Image' /></div> : ''}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {displayCoachBio &&
                        <Col lg={{ order: '3', span: '12' }} xs={{ order: '3' }} >
                            <>
                                {mycoachData?.bio &&
                                    <Row className="expand-bio">
                                        {/* <Col lg='2'>
                                        </Col> */}
                                        <Col lg="10" className="coach-bio-message-position">
                                            <div className="myCoachHeading mb-2">
                                                {fetchLabel("MyCoachPage.MyCoachCoachAssigned.CoachBio", "labels")}
                                            </div>
                                            <div className="myCoachSubcontent">
                                                {mycoachData?.bio}
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {mycoachData?.coachMessage &&
                                    <Row>
                                        {/* <Col lg='2'>
                                        </Col> */}
                                        <Col lg="10" className="coach-bio-message-position">
                                            <div className="myCoachHeading mb-2">
                                                {fetchLabel("MyCoachPage.MyCoachCoachAssigned.CoachMessage", "labels")}
                                            </div>
                                            <div className="myCoachSubcontent">
                                                {mycoachData?.coachMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </>
                        </Col>
                    }
                </Row>
            </Container>
        </>
    );
}

export default CoachList;
