//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import "./index.css";
import { ErrorActions } from "./errorActions";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";


function ErrorHandler() {
	const [errorPageContent, setErrorPageContent] = useState({
		title: "",
		description: "",
		Labels: "",
		link: ""
	});


	useEffect(() => {
		fetchErrorCMSData();
	}, [])

	const fetchErrorCMSData = async () => {
		let refCmsContent = JSON.parse(JSON.stringify(errorPageContent));
		let payload = {
			PageName: "ErrorPages",
		};
		await ErrorActions.GetCMSData(payload).then(
			(res) => {
				if (res.status === 200) {
					let resData = res.data;
					// window.sessionStorage.setItem("ErrorPagesCache", JSON.stringify(resData?.Pages));
					resData?.Pages?.filter((x) => {
						if (x.Key === "ErrorPages.404ErrorPage") {
							refCmsContent.title = x?.Title;
							refCmsContent.description = x?.Description;
							if (Object.keys(x.Components)?.length !== 0) {
								refCmsContent.Labels = x.Components["ErrorPages.404PageComponent"]?.Labels[0]?.Phrase;
								refCmsContent.link = x.Components["ErrorPages.404PageComponent"]?.Labels[0]?.Link?.Url;
							}
						}
						setErrorPageContent(refCmsContent);
					});

					// Start For AppInsight Log
					var reactPlugin = new ReactPlugin();
					const browserHistory = createBrowserHistory({ basename: '' });
					var appInsights = new ApplicationInsights({
					  config: {
						instrumentationKey: process.env.REACT_APP_INSIGHT_KEY,
						extensions: [reactPlugin],
						enableAutoRouteTracking: true,
						disableAjaxTracking:true,
						extensionConfig: {
						  [reactPlugin.identifier]: { history: browserHistory },
						},
					  },
					});
					appInsights.loadAppInsights();
					const measurements = { 'Severity': SeverityLevel.Information }
					let ProgramDetails = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : "";
					let ProfileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails")) && JSON.parse(window.sessionStorage.getItem("setProfileDetails"));
					let noData = "Not Available";
    
					let payload = {
						"CandidateID": JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
						"Severity": SeverityLevel.Information.toString(),
						"Client Name": ProgramDetails?.companyName ? ProgramDetails?.companyName : noData,
						"Program Class": ProgramDetails?.programClass ? ProgramDetails?.programClass : noData,
						"Market": ProfileDetails?.preferredLanguageCode ? ProfileDetails?.preferredLanguageCode : noData,
						"Time": new Date(),
						"Persona": ProfileDetails?.personaName ? ProfileDetails?.personaName : noData,
						"EmailAddress": ProfileDetails?.emailAddress ? ProfileDetails?.emailAddress : noData,
						"Language": JSON.parse(window.sessionStorage.getItem("Language")) && JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageFullName'],
						"Name": "ErrorPage",
					}

					appInsights.trackPageView({ name: "ErrorPage", properties: payload, measurements: measurements })
					// TrackActions.PageViewRecord(appInsights, "ErrorPage", property)

					// End AppInsight Log
				} else {
					console.log("api not succeeded");
				}
			},
			(err) => {
				console.log(err);
			}
		);
	};


	return (
		<div className="containerErrorHandler">
			<div className="bodyContainer">
				<h1 className="headerText">{errorPageContent?.title}</h1>
				<p className="definition">
					{errorPageContent?.description}
				</p>
				<button
					onClick={() => {
						window.location = errorPageContent?.link;
						window.location.reload();
					}}
					className="goBackbutton"
				>
					{errorPageContent?.Labels}
				</button>
			</div>
		</div>
	);
};

export default ErrorHandler;
