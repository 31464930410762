//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./index.css";
import { Context } from "../../common/context/Context";
import { coachActions } from "./coachActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  globalTimeFormat,
  globalDateFormat,
} from "../../common/helpers/DateTimeHelper";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function CoachingSessions(props) {
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [meetingInvites, setMeetingInvites] = useState([]);
  const [meetingsInvitesStored, setmeetingsInvitesStored] = useState([]);
  const [showInvitesCards, setshowInvitesCards] = useState(4);

  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [upcomingMeetingsStored, setupcomingMeetingsStored] = useState([]);
  const [showupcomingCards, setshownupcomingCards] = useState(4);

  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [completedMeetingsStored, setcompletedMeetingsStored] = useState([]);
  const [showcompletedCards, setshowcompletedCards] = useState(4);

  const [sCols, setCols] = useState(4);
  const context = useContext(Context);
  const [bDataAvialable, setbDataAvialable] = useState(false);

  useEffect(() => {
    if (props?.calledFrom === "dashboard") {
      getMeetingInvite();
      getUpcomingMeeting();
    } else {
      getMeetingInvites();
      getUpcomingMeetings();
      if (!props?.removeCompltedEvent) getCompletedMeetings();
    }
  }, [props.coachSessionReload]);

  const getMeetingInvites = async () => {
    let payload = {
      Status: "PendingInvites",
    };
    await coachActions.GetCoachingSessions(payload).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setCols(4);
          setmeetingsInvitesStored(res.data);
          setMeetingInvites(res.data.slice(0, showInvitesCards));
          res.data.sort((a, b) => {
            let keyA = new Date(a.startTimeStamp),
              keyB = new Date(b.startTimeStamp);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          setbDataAvialable(true);
        } else {
          setMeetingInvites([]);
          props?.showUpcomingEventsInFullWidth ? setCols(11) : setCols(6);
        }
      } else {
        setMeetingInvites([]);
      }
    });
  };

  const getMeetingInvite = async () => {
    let res = props.meetingInvite;

    if (res?.length > 0) {
      setCols(4);
      setmeetingsInvitesStored(res);
      setMeetingInvites(res?.slice(0, showInvitesCards));
      res?.sort((a, b) => {
        let keyA = new Date(a.startTimeStamp),
          keyB = new Date(b.startTimeStamp);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      setbDataAvialable(true);
    } else {
      setMeetingInvites([]);
      props?.showUpcomingEventsInFullWidth ? setCols(11) : setCols(6);
    }
  };

  const getUpcomingMeetings = async () => {
    let refUpcomingMeetings = JSON.parse(JSON.stringify(upcomingMeetings));
    let payload = {
      Status: "Upcoming",
    };
    await coachActions.GetCoachingSessions(payload).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setupcomingMeetingsStored(res.data);
          refUpcomingMeetings.data = res.data.slice(0, showupcomingCards);
          refUpcomingMeetings.noUpcomingLabel =
            getSectionHeaderText("noUpcomingLabel")?.Phrase;

          res.data.sort((a, b) => {
            let keyA = new Date(a.startTimeStamp),
              keyB = new Date(b.startTimeStamp);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          setUpcomingMeetings(refUpcomingMeetings);
          setbDataAvialable(true);
        } else {
          refUpcomingMeetings.data = [];
          refUpcomingMeetings.noUpcomingLabel =
            getSectionHeaderText("noUpcomingLabel")?.Phrase;
          setUpcomingMeetings(refUpcomingMeetings);
        }
      } else if (res.status === 204) {
        refUpcomingMeetings.data = res.data;
        refUpcomingMeetings.noUpcomingLabel =
          getSectionHeaderText("noUpcomingLabel")?.Phrase;
        setUpcomingMeetings(refUpcomingMeetings);
      }
    });
  };

  const isValidDate = (sessionDateString) => {
    //let myDateString = "2022-12-12T13:00:00+00:00";
    //let programEndDate = new Date('Fri aug 10 2022 18:23:32 GMT+0530 (India Standard Time)');
    let sessionDate = Date.parse(new Date(sessionDateString));
    const todayDate = Date.parse(new Date());

    let time_difference = sessionDate - todayDate;
    let minute_difference = time_difference / 1000 / 60;
    if (Math.round(minute_difference) > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getUpcomingMeeting = async () => {
    let refUpcomingMeetings = JSON.parse(JSON.stringify(upcomingMeetings));
    let res = props.upcomingMeeting;
    let onlyUpcomingEvents = res.filter((upcomingEvent) =>
      isValidDate(
        upcomingEvent.endTimeStamp
          ? upcomingEvent.endTimeStamp
          : upcomingEvent.startTimeStamp
      )
    );
    if (res?.length > 0) {
      setupcomingMeetingsStored(onlyUpcomingEvents);
      refUpcomingMeetings.data = onlyUpcomingEvents?.slice(
        0,
        showupcomingCards
      );
      refUpcomingMeetings.noUpcomingLabel =
        getSectionHeaderText("noUpcomingLabel")?.Phrase;

      onlyUpcomingEvents?.sort((a, b) => {
        let keyA = new Date(a.startTimeStamp),
          keyB = new Date(b.startTimeStamp);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      setUpcomingMeetings(refUpcomingMeetings);
      setbDataAvialable(true);
    } else {
      refUpcomingMeetings.data = [];
      refUpcomingMeetings.noUpcomingLabel =
        getSectionHeaderText("noUpcomingLabel")?.Phrase;
      setUpcomingMeetings(refUpcomingMeetings);
    }
  };

  const getCompletedMeetings = async () => {
    let refCompletedMeetings = JSON.parse(JSON.stringify(completedMeetings));
    let payload = {
      Status: "Completed",
    };
    await coachActions.GetCoachingSessions(payload).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setcompletedMeetingsStored(res.data);
          refCompletedMeetings.data = res.data.slice(0, showcompletedCards);
          refCompletedMeetings.noCompletedLabel =
            getSectionHeaderText("noCompletedLabel")?.Phrase;

          res.data.sort((a, b) => {
            let keyA = new Date(a.startTimeStamp),
              keyB = new Date(b.startTimeStamp);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          setCompletedMeetings(refCompletedMeetings);
          setbDataAvialable(true);
        } else {
          refCompletedMeetings.data = [];
          refCompletedMeetings.noCompletedLabel =
            getSectionHeaderText("noCompletedLabel")?.Phrase;
          setCompletedMeetings(refCompletedMeetings);
        }
      } else {
        refCompletedMeetings.data = [];
        refCompletedMeetings.noCompletedLabel =
          getSectionHeaderText("noCompletedLabel")?.Phrase;
        setCompletedMeetings(refCompletedMeetings);
      }
    });
  };

  const getSectionHeaderText = (sectionName) => {
    let key = "";
    let objData = props?.cmsData;
    switch (sectionName) {
      case "invitation":
        key = "MyCoachPage.MyCoachCoachingSessions.Invitations";
        break;
      case "upcoming":
        key = "MyCoachPage.MyCoachCoachingSessions.Upcoming";
        break;
      case "completed":
        key = "MyCoachPage.MyCoachCoachingSessions.Completed";
        break;
      case "clock":
        key = "MyCoachPage.MyCoachCoachingSessions.Clock";
        break;
      case "candidateName":
        key = "MyCoachPage.MyCoachCoachingSessions.CandidateName";
        break;
      case "accept":
        key = "MyCoachPage.MyCoachCoachingSessions.Accept";
        break;
      case "reschedule":
        key = "MyCoachPage.MyCoachCoachingSessions.Reschedule";
        break;
      case "cancel":
        key = "MyCoachPage.MyCoachCoachingSessions.Accept";
        break;
      case "noUpcomingLabel":
        key = "MyCoachPage.MyCoachCoachingSessions.NoUpcomingSession";
        break;
      case "noCompletedLabel":
        key = "MyCoachPage.MyCoachCoachingSessions.NoCompletedSession";
        break;
      case "view":
        key =
          objData?.Key == "DashboardPage.DashboardMyCalendar"
            ? "DashboardPage.DashboardMyCalendar.View"
            : "MyCoachPage.MyCoachCoachingSessions.View";
        break;
      case "pastevent":
        key = "MyCoachPage.MyCoachCoachingSessions.PastEvent";
        break;
    }

    let header = { Phrase: sectionName };
    if (objData && Object.keys(objData).length > 0) {
      header = objData.Labels.filter((data) => data.Key === key);
      return header[0];
    }
    return header;
  };

  const ShowAll = (nflag) => {
    if (nflag === 1) {
      let refchunkedData = JSON.parse(JSON.stringify(meetingsInvitesStored));
      setshowInvitesCards(refchunkedData.length);
      let refchunkedDataFinal = {};
      refchunkedDataFinal =
        refchunkedData && refchunkedData.slice(0, refchunkedData.length);
      setMeetingInvites(refchunkedDataFinal);
    } else if (nflag === 2) {
      let refchunkedData = JSON.parse(JSON.stringify(upcomingMeetingsStored));
      setshownupcomingCards(refchunkedData.length);
      let refchunkedDataFinal = {};
      refchunkedDataFinal["data"] =
        refchunkedData && refchunkedData.slice(0, refchunkedData.length);
      setUpcomingMeetings(refchunkedDataFinal);
    } else if (nflag === 3) {
      let refchunkedData = JSON.parse(JSON.stringify(completedMeetingsStored));
      setshowcompletedCards(refchunkedData.length);
      let refchunkedDataFinal = {};
      refchunkedDataFinal["data"] =
        refchunkedData && refchunkedData.slice(0, refchunkedData.length);
      setCompletedMeetings(refchunkedDataFinal);
    }
  };

  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  function getFeaturesConfig(featureName) {
    let val;
    featuresConfig?.features?.map((feature) => {
      if (feature.featureName === featureName) {
        val = feature.isActive;
      }
    });
    return val;
  }

  return (
    <>
      <Container fluid>
        <Row
          className={`${
            props?.calledFrom !== "dashboardNoCoach" &&
            props?.calledFrom !== "dashboardNoMeeting"
              ? "coachContainerRow"
              : "buttonDash"
          }`}
        >
          <div className="containerHeader">
            {props?.removeCompltedEvent
              ? props?.myCalanderTitle
              : props?.cmsData?.Title}
          </div>

          {bDataAvialable && meetingInvites && meetingInvites?.length > 0 && (
            <Col xs="12" lg="4">
              <div className="coachTitle">
                {getSectionHeaderText("invitation")?.Phrase}
              </div>
              <div>
                <InvitationCard
                  candidateName={getSectionHeaderText(
                    "candidateName"
                  )?.Phrase.replace("#NAME", context?.idTokenClaims?.firstName)}
                  openConfirmation={props.openConfirmation}
                  openReschdule={props.openReschedule}
                  openCancelMeeting={props.cancelMeeting}
                  acceptButtontext={getSectionHeaderText("accept")?.Phrase}
                  rescheduleButtontext={
                    getSectionHeaderText("reschedule")?.Phrase
                  }
                  cancelButtontext={"Cancel"}
                  meetingInvites={meetingInvites}
                  reloadInvitesData={getMeetingInvites}
                  reloadUpcomingData={getUpcomingMeetings}
                  GetConfirmMeetingData={props.GetConfirmMeetingData}
                  GetReshceduleMeetingData={props.GetReshceduleMeetingData}
                  GetCancelMeetingData={props.GetCancelMeetingData}
                  meetingsInvitesStored={meetingsInvitesStored}
                  showAllInvites={ShowAll}
                  showAllButtonText={props.CommomCompViewAllButtonText}
                />
              </div>
            </Col>
          )}

          {(bDataAvialable ||
            (upcomingMeetings && upcomingMeetings?.data?.length > 0)) && (
            <Col md="xs" lg={sCols} className="mb-3">
              <div className="coachTitle">
                {getSectionHeaderText("upcoming")?.Phrase}
              </div>
              <div className="col-xs-6">
                <SessionCard
                  theme="dark"
                  buttonText={getSectionHeaderText("view")?.Phrase}
                  clockText={getSectionHeaderText("clock")?.Phrase}
                  upcomingMeetings={upcomingMeetings}
                  upcomingMeetingsStored={upcomingMeetingsStored}
                  showAllUpcomimng={ShowAll}
                  openUpcomingEventDetailDlg={props.openUpcomingEventDetailDlg}
                  GetUpcomingMeetingData={props.GetUpcomingMeetingData}
                  showAllButtonText={props.CommomCompViewAllButtonText}
                />
                {/* <SessionCard theme="dark" buttonText="View" /> */}
              </div>
            </Col>
          )}

          {((bDataAvialable && !props?.removeCompltedEvent) ||
            (completedMeetings && completedMeetings?.data?.length > 0)) && (
            <Col md="xs" lg={sCols}>
              <div className="coachTitle">
                {" "}
                {getSectionHeaderText("completed")?.Phrase}
              </div>
              <div>
                <CompletedCard
                  theme="light"
                  errorTheme="dark"
                  buttonText={getSectionHeaderText("pastevent")?.Phrase}
                  clockText={getSectionHeaderText("clock")?.Phrase}
                  completedMeetings={completedMeetings}
                  completedMeetingsStored={completedMeetingsStored}
                  ShowAllCompleted={ShowAll}
                  OpenCompletedEventDetailDlg={
                    props.openCompletedEventDetailDlg
                  }
                  GetCompletedMeetingData={props.GetCompletedMeetingData}
                  showAllButtonText={props.CommomCompViewAllButtonText}
                />
              </div>
            </Col>
          )}

          {props.calledFrom == "dashboardNoCoach" && (
            <Row>
              <Col md="xs" lg="9">
                <div className="mycalender_desctiption">
                  {/* {props.cmsData?.Description} */}
                  {props?.myCalanderDescription}
                </div>
              </Col>
              <Col md="xs" lg="2">
                {(getFeaturesConfig("EventsPage") ||
                  featuresConfig?.isOnDemandEventFeatureAvailable) && (
                  <Cirrus.Button
                    isSecondary={true}
                    onClick={() => navigate("/events")}
                  >
                    {props?.myCalanderLabel &&
                      props?.myCalanderLabel?.Labels?.map((val) => {
                        if (
                          val.Key ==
                          "DashboardPage.DashboardMyCalendar.BookAnEvent"
                        ) {
                          return val.Phrase;
                        }
                      })}
                  </Cirrus.Button>
                )}
              </Col>
            </Row>
          )}

          {props?.calledFrom == "dashboardNoMeeting" &&
            bDataAvialable === false && (
              <Row>
                <Col md="xs" lg="5">
                  <div>{props?.cmsData?.Description}</div>
                </Col>
                <Col
                  md="xs"
                  lg="6"
                  className="dashboardnocoach-mycalendar-btn d-lg-flex align-items-lg-center justify-content-lg-end gap-2"
                >
                  <Cirrus.Button
                    isSecondary={true}
                    onClick={() => navigate("/mycoach")}
                    // className="buttonEve"
                  >
                    {props?.cmsData &&
                      props?.cmsData?.Labels?.map((val) => {
                        if (
                          val.Key ==
                          "DashboardPage.DashboardMyCalendar.ScheduleCoachMeeting"
                        ) {
                          return val.Phrase;
                        }
                      })}
                  </Cirrus.Button>
                  {(getFeaturesConfig("EventsPage") ||
                    featuresConfig?.isOnDemandEventFeatureAvailable) && (
                    <Cirrus.Button
                      isSecondary={true}
                      onClick={() => navigate("/events")}
                    >
                      {props?.cmsData &&
                        props?.cmsData?.Labels?.map((val) => {
                          if (
                            val.Key ==
                            "DashboardPage.DashboardMyCalendar.BookAnEvent"
                          ) {
                            return val.Phrase;
                          }
                        })}
                    </Cirrus.Button>
                  )}
                  {/* <Cirrus.Button
                            isSecondary = {true}
                            onClick = {()=>navigate("/events")}
                            >
                              {props.cmsData && props.cmsData?.Labels?.map((val)=>{
                                if(val.Key == "DashboardPage.DashboardMyCalendar.BookAnEvent"){
                                  return val.Phrase
                                }
                              })}
                            </Cirrus.Button> */}
                </Col>
              </Row>
            )}
        </Row>
      </Container>
    </>
  );
}

function CompletedCard(props) {
  const fnOpenEventDetailsDlg = async (objMeeting) => {
    props.GetCompletedMeetingData(objMeeting);
    props.OpenCompletedEventDetailDlg(true);
  };
  return (
    <>
      {props?.completedMeetings?.data?.length > 0 ? (
        props?.completedMeetings?.data?.map((completedMeeting) => {
          return (
            <div
              className="cardBody"
              style={{
                background: props.theme === "light" ? "transparent" : "#ECEFF5",
              }}
            >
              <Row key={completedMeeting.id}>
                <Col xs="7" lg="7">
                  <div style={{ fontSize: "18px" }} className="text-truncate">
                    {completedMeeting.title}
                  </div>
                  <div style={{ fontSize: "14px" }} className="date-time-color">
                    {globalDateFormat(completedMeeting.startTimeStamp)} .{" "}
                    {globalTimeFormat(completedMeeting.startTimeStamp)} -{" "}
                    {globalTimeFormat(completedMeeting.endTimeStamp) +
                      " " +
                      (props?.clockText || " ")}
                  </div>
                </Col>
                <Col
                  xs="5"
                  lg="5"
                  className="d-flex align-items-center justify-content-end text-nowrap"
                >
                  <Cirrus.Button
                    isSecondary
                    onClick={() => fnOpenEventDetailsDlg(completedMeeting)}
                  >
                    {props?.buttonText}
                  </Cirrus.Button>
                </Col>
              </Row>
            </div>
          );
        })
      ) : (
        <div className="cardBody mb-2 cardBodyLight">
          <Row>
            <div className="coachTitle">
              {props?.completedMeetings?.noCompletedLabel}
            </div>
          </Row>
        </div>
      )}
      {props?.completedMeetingsStored &&
        props?.completedMeetingsStored?.length >= 4 && (
          <Cirrus.Button
            className="mt-3"
            onClick={() => props.ShowAllCompleted(3)}
            isFullWidth={false}
          >
            {props?.showAllButtonText}
          </Cirrus.Button>
        )}
    </>
  );
}

function SessionCard(props) {
  const fnOpenEventDetailsDlg = async (objMeeting) => {
    props.GetUpcomingMeetingData(objMeeting);
    props.openUpcomingEventDetailDlg(true);
  };
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      {props?.upcomingMeetings?.data?.length > 0 ? (
        props?.upcomingMeetings?.data?.map((upcomingMeeting) => {
          return (
            <div
              className={
                props.theme === "light"
                  ? "cardBody cardBodyDark"
                  : "cardBody cardBodyLight"
              }
            >
              <Row key={upcomingMeeting.id}>
                <Col xs="7" lg="7">
                  <div style={{ fontSize: "18px" }} className="text-truncate">
                    {upcomingMeeting.title}
                  </div>
                  <div style={{ fontSize: "14px" }} className="date-time-color">
                    {globalDateFormat(upcomingMeeting.startTimeStamp)} .{" "}
                    {globalTimeFormat(upcomingMeeting.startTimeStamp)} -{" "}
                    {globalTimeFormat(upcomingMeeting.endTimeStamp) +
                      " " +
                      (props?.clockText || " ")}
                  </div>
                </Col>
                {!props.removeCompltedEvent ? (
                  <Col
                    xs={5}
                    lg="3"
                    className="d-flex align-items-center justify-content-end text-nowrap"
                  >
                    <Cirrus.Button
                      isSecondary
                      onClick={() => fnOpenEventDetailsDlg(upcomingMeeting)}
                    >
                      {props?.buttonText}
                    </Cirrus.Button>
                  </Col>
                ) : (
                  <Col
                    xs="5"
                    lg="5"
                    className="d-flex align-items-center justify-content-end text-nowrap"
                  >
                    <Cirrus.Button
                      isSecondary
                      onClick={() => fnOpenEventDetailsDlg(upcomingMeeting)}
                    >
                      {props?.buttonText}
                    </Cirrus.Button>
                  </Col>
                )}
              </Row>
            </div>
          );
        })
      ) : (
        <div
          className={
            props.theme === "light"
              ? "cardBody cardBodyDark mb-2"
              : "cardBody cardBodyLight mb-2"
          }
        >
          <Row>
            <div className="coachTitle">
              {props?.upcomingMeetings?.noUpcomingLabel}
            </div>
          </Row>
        </div>
      )}
      {props?.upcomingMeetingsStored &&
        props?.upcomingMeetingsStored?.length > 4 &&
        !showAll && (
          <Cirrus.Button
            className="mt-2"
            onClick={() => {
              props.showAllUpcomimng(2);
              setShowAll(true);
            }}
            isFullWidth={false}
          >
            {props?.showAllButtonText}
          </Cirrus.Button>
        )}
    </>
  );
}

function InvitationCard(props) {
  const fnUpdateConfirmationMeeting = async (objMeeting) => {
    let objData = {};
    objData["Date"] = globalDateFormat(objMeeting.startTimeStamp);
    objData["Time"] =
      globalTimeFormat(objMeeting.startTimeStamp) +
      " - " +
      globalTimeFormat(objMeeting.endTimeStamp);

    props.GetConfirmMeetingData(objData);
    props.openConfirmation(true);

    let sMeetingID = objMeeting.id;
    let payload = {
      Status: "Accept",
    };
    await coachActions
      .SaveMeetingConfirmedStatus(sMeetingID, payload)
      .then((res) => {
        if (res.status === 200) {
          props.reloadInvitesData();
          props.reloadUpcomingData();
          props.openConfirmation(true);
        }
      });
  };

  const fnUpdateReschuldeMeeting = async (objMeeting) => {
    let objData = {
      Title: objMeeting.title,
      id: objMeeting.id,
      startDate: objMeeting.startTimeStamp,
      endDate: objMeeting.endTimeStamp,
    };

    props.GetReshceduleMeetingData(objData);
    props.openReschdule(true);
  };

  const fnCancelScheduleMeeting = async (objMeeting) => {
    props.GetCancelMeetingData(objMeeting);
    props.openCancelMeeting(true);
  };

  return (
    <>
      {props?.meetingInvites.length === 0 && <h2>No Invitations</h2>}
      {props?.meetingInvites.length > 0 &&
        props?.meetingInvites?.map((meetingInvite) => {
          return (
            <div className="invitationCard">
              <div className="invitation-title">{props?.candidateName}</div>
              {/* <div>17th Nov 2022 9am - 10am</div> */}
              <div className="invitation-card-timings">
                <i className="icon-calendar" />{" "}
                {globalDateFormat(meetingInvite.startTimeStamp)}
                <i className="icon-watch_later" />
                {globalTimeFormat(meetingInvite.startTimeStamp)} -{" "}
                {globalTimeFormat(meetingInvite.endTimeStamp)}
              </div>
              <div className="invitationButtonsContainer">
                <Cirrus.Button
                  onClick={() => fnUpdateConfirmationMeeting(meetingInvite)}
                  isFullWidth={true}
                >
                  {props?.acceptButtontext}
                </Cirrus.Button>
                <Cirrus.Button
                  className="invitationButtons"
                  isSecondary
                  onClick={() => fnUpdateReschuldeMeeting(meetingInvite)}
                  isFullWidth={true}
                >
                  {props?.rescheduleButtontext}
                </Cirrus.Button>
                <Cirrus.Button
                  className="invitationButtons"
                  isSecondary
                  onClick={() => fnCancelScheduleMeeting(meetingInvite)}
                  isFullWidth={true}
                >
                  {props?.cancelButtontext}
                </Cirrus.Button>
              </div>
            </div>
          );
        })}
      {props?.meetingsInvitesStored &&
        props?.meetingsInvitesStored?.length > 4 && (
          <Cirrus.Button
            className="mt-2"
            onClick={() => props.showAllInvites(1)}
            isFullWidth={false}
          >
            {props?.showAllButtonText}
          </Cirrus.Button>
        )}
    </>
  );
}

export default CoachingSessions;
