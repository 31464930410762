//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Link } from "react-router-dom";
import { Context } from "../../common/context/Context";
import HeaderWithNavigation from "../header/headerWithNavigation";
import Footer from "../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../common/components/spinner-loader/Spinner";
import sideimage from "./sideimage.png";
import { DiscoverActions } from "../discover/DiscoverActions";
import { iViewActions } from "./iViewActions";
import "bootstrap-icons/font/bootstrap-icons.css";
import Icon from "../../assets/fonts/icomoon/Icon";
import "./style.css";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";

function IviewWelcomePage() {
  const navigate = useNavigate();
  const context = useContext(Context);
  const appInsights = useAppInsightsContext();
  const [cmsContent, setCmsContent] = useState({});
  const [screenLoader, setScreenLoader] = useState(false);
  const [saveLikeSpinner, setSaveLikeSpinner] = useState(false);
  const [iviewCollectionData, setIviewCollectionData] = useState({
    data: []
  });
  const [label, setLabels] = useState([]);
  let path = JSON.parse(window.sessionStorage.getItem("setPath"));


  const iviewWelcomePageContent = async () => {
    setScreenLoader(true);
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let payload = {
      PageName: "OnDemandVideoInterview"
    };
    await iViewActions.GetIviewContent(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          ActivityAction.SaveActivity(resData?.id, "Tool", resData?.title)
          TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resData?.id, "Entity Name": resData?.title, "CardType": "Tool" })
          TrackActions.PageAction("ViewCard", { "Entity Id": resData?.id, "Entity Name": resData?.title, "CardType": "Tool" })
          setLabels(resData?.components)
          let arrVideoCollectionCards = [];
          refCmsContent.Title = resData.title;
          refCmsContent.Description =
            resData.components["iView.VideoCollections"]?.Description;
          refCmsContent.ShortDescription = resData.shortDescription;
          refCmsContent.PersonalBranding = resData.focusArea[0]?.value;
          refCmsContent.ResourceType = resData.resourceType.value;
          refCmsContent.asessmentCount = resData.asessmentCount;
          refCmsContent.bannerImage = resData.largeImage.imageUrl;
          refCmsContent.iviewId = resData.id;
          refCmsContent.iviewStatus = resData.collectionSaved;

          refCmsContent.videoCollection =
            resData.components["iView.VideoCollections"]?.LOVList[0].Values;
          resData.resourcePages.map((item) => {
            let objResourceCard = {};
            objResourceCard["Title"] = item.title;
            objResourceCard["ShortDescription"] = item.shortDescription;
            objResourceCard["AssessmentType"] = item.assessmentType.value;
            objResourceCard["AssessmentType"] = item.assessmentType.value;
            objResourceCard["AssessmentId"] = item.id;
            objResourceCard["externalId"] = item.externalId;
            // objResourceCard['asessmentCount'] = item.asessmentCount;
            objResourceCard["asessmentCount"] = item.asessmentCount;
            let arrCardCollection = [];
            item.resourcePages.map((card) => {
              let arrFocusArea = [];
              let objCardCollection = {
                Title: card.title,
                ShortDescription: card.shortDescription,
                ResourceType: card.resourceType.value,
                LearningResourceStatus: card?.status ? card?.status : "notstarted",
                Duration: card.duration,
                issaved: card.issaved,
                isLiked: card.isLiked,
                status: card.status,
                totalLikedCount: card.totalLikedCount,
                id: card.id,
                assessmentType: card.assessmentType.value,
                asessmentCount: card.asessmentCount,
                externalId: card.externalId,
              };
              card.focusArea.map((focus) => {
                arrFocusArea.push(focus.value);
              });
              objCardCollection["FocusArea"] = arrFocusArea;
              arrCardCollection.push(objCardCollection);
            });
            objResourceCard["resourcePages"] = arrCardCollection;
            arrVideoCollectionCards.push(objResourceCard);
          });
          refCmsContent.ResourceCardList = arrVideoCollectionCards;
          setCmsContent(refCmsContent);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };


  const saveAssessmentCard = (e, id, savedStatus, likedStatus, status, type, externalID) => {
    e.preventDefault();
    let countryCode = JSON.parse(sessionStorage.getItem("setProgramDetails"))['programLanguageCode']
    const url = window.location.href;
    const env = url.split("#");
    const pay = {
      LearningResourceId: id,
      LearningResourceStatus: "notstarted",
      CallbackURL: `${env[0]
        }#/iview-interview-done/${externalID}/${id}/${sessionStorage.getItem(
          "AccessToken"
        )}`,
      Languagecode: countryCode,
    };
    iViewActions.GetIviewInterview(pay, externalID)
      .then((res) => {
        let response = res?.data


        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
          candidateId: candidateID,
          LearningResourceId: id,
          LearningResourceType: "iView",
          IsDownloaded: false,
          IsSharedWithCoach: false,
          status: status ? status : "notstarted",
          LearningResourceStatus: status ? status : "notstarted",
          isSaved:
            type === "save" ? (savedStatus == true ? false : true) : savedStatus,
          isLiked:
            type === "like" ? (likedStatus == true ? false : true) : likedStatus,
          InterviewId: response?.interviewId,
          InterviewType: "Video",
          IsRetaken: false,
          RedirectUrl: response?.interviewUrl,
          RequisitionId: externalID,
          Languagecode: countryCode,
        };

        iViewActions.SaveIviewAssessmentCard(id, payload).then(
          (res) => {
            if (res.status === 200) {
              let resData = res.data;
              iviewWelcomePageContent();
              // setSaved(true)
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
  };

  const fetchAssessmentData = (type, origin) => {

    let refIviewCollectionData = JSON.parse(JSON.stringify(iviewCollectionData));
    if (saveLikeSpinner === true) {
      setScreenLoader(false);
    } else {
      setScreenLoader(true);
    }
    let resourceType = type;
    let resourceCategory = type;

    let payload = {
      learningResourceType: resourceType,
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: resourceCategory,
    };

    DiscoverActions.GetDiscoverData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data && res?.data?.responsemodel;
          resData = resData && resData.filter((x) => x.key == "Tools.OnDemandVideoInterviews")
          refIviewCollectionData.data = resData && resData;
          setIviewCollectionData(refIviewCollectionData);
          setSaveLikeSpinner(true);
          setScreenLoader(false);
        } else {
          console.log("api not succeeded");
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveIviewCollectionCard = (
    e,
    id,
    savedStatus,
    likedStatus,
    type,
    learningResourceStatus,
    pageNumber,
    resourceSubType,
    resourceCatType,
    origin
  ) => {
    e.preventDefault();
    let resourceType = resourceCatType;
    let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
    let payload = {
      candidateId: candidateID,
      learningResourceId: id,
      lastAnsweredPageId: pageNumber,
      learningResourceType: resourceCatType,
      resourceTypeId: resourceSubType,
      learningResourceStatus: learningResourceStatus,
      isSaved:
        type === "save" ? (savedStatus == true ? false : true) : savedStatus,
      isLiked:
        type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    };

    DiscoverActions.SaveAssessmentCard(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          setSaveLikeSpinner(true);
          if (origin === "recommended") {
            fetchAssessmentData("assessments", origin);
          } else {
            fetchAssessmentData("tools", "SaveLike");
          }
          updateStatus(resourceType, id, payload);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const updateStatus = (resourceType, id, payload) => {
    DiscoverActions.UpdateStatus(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          setSaveLikeSpinner(true);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(async () => {
    iviewWelcomePageContent();
    fetchAssessmentData("tools")
    window.scrollTo(0, 0);
  }, [context.language]);

  const scrollToSection = (element) => {
    if (element !== null && element !== undefined) {
      let scroll_to_bottom = document.getElementById(element);
      // scroll_to_bottom.scrollIntoView();
      window.scrollTo({
        top: scroll_to_bottom.getBoundingClientRect().top + window.pageYOffset - 45,
        behavior: "smooth"
      })
    }
  };

  const fetchLabel = (key) => {
    let labelData = label?.commonComponent?.Labels;
    let labels = "";
    if (labelData) {
      labelData?.map((x) => {
        if (x.Key === key) {
          labels = x.Phrase;
        }
      });
    }
    return labels;
  }

  const fetchResourceLabel = (key) => {
    let labelData = label?.commonResourceComponent?.Labels;
    let labels = "";
    if (labelData) {
      labelData?.map((x) => {
        if (x.Key === key) {
          labels = x.Phrase;
        }
      });
    }
    return labels;
  }

  if (screenLoader) {
    return (
      <div>
        <Spinner spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="iview-welcome">
      <Container fluid className="p-lg-5 p-3 background-White">
        <Row>
          <Col md="12" lg="7" className="mb-3">
            <Cirrus.Button
              isSecondary
              onClick={() => navigate(`/${context.path ? context.path : path}`)}
              className="mb-3 mb-lg-5"
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
            <Cirrus.Header size="h3" className='assessment-title'>{cmsContent.Title}</Cirrus.Header>
            <div className="d-flex align-items-center mt-4 mb-3 iview-header">
              <button
                className="saveBtn"
                onClick={(e) => {
                  saveIviewCollectionCard(
                    e,
                    iviewCollectionData?.data[0]?.resourceId,
                    iviewCollectionData?.data[0]?.isSaved,
                    iviewCollectionData?.data[0]?.isLiked,
                    "save",
                    iviewCollectionData?.data[0]?.learningResourceStatus,
                    iviewCollectionData?.data[0]?.lastAnsweredPageId,
                    iviewCollectionData?.data[0]?.resourceType?.id,
                    iviewCollectionData?.data[0]?.resourceCategoryType?.id,
                    "na"
                  );
                }}
              >
                {iviewCollectionData?.data.length > 0 && iviewCollectionData?.data[0].isSaved === true ? (
                  <i className="icon-saved"></i>
                ) : (
                  <i className="icon-save"></i>
                )}
                <span className="saveBtnText">
                  {iviewCollectionData?.data.length > 0 && iviewCollectionData?.data[0].isSaved === true ? fetchLabel("CommonComponent.CommonSavedButton") : fetchLabel("CommonComponent.CommonSaveButton")}
                </span>
              </button>
              <Cirrus.Label id="category-label">
                {cmsContent && cmsContent?.PersonalBranding}
              </Cirrus.Label>
              <Cirrus.Label id="cardtype">
                <span>
                  {cmsContent && cmsContent?.ResourceType} .{" "}
                  <span className="iview-assesment-count">
                    {cmsContent && cmsContent?.asessmentCount}
                  </span>
                </span>
              </Cirrus.Label>
            </div>
          </Col>
          <Col md="12" lg="5">
            <div className="imagebanner position-relative">
              <div className='assesment_startpage-image-wrapper'>
                <img
                  src={cmsContent?.bannerImage}
                  alt="sideimage"
                  className="image-overflow img-fluid w-100"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-lg-5 p-3 mt-lg-0 mt-md-5 mt-5">
        <Row>
          <Col md="12" lg="6">
            <Cirrus.Headline size="Medium" className="mb-5 mt-lg-0 mt-md-5 mt-5">
              {cmsContent && cmsContent?.ShortDescription}
            </Cirrus.Headline>
            <Cirrus.Header size="h5" className="mb-3">
              {cmsContent && cmsContent?.Title}
            </Cirrus.Header>
            <div className="iview-view-collection-subheading">
              {cmsContent && cmsContent?.Description}
            </div>

            <Row className="videoCollectionListRow">
              <Col md="12" lg="12">
                <ul className="videocollectionList twoCloumn">
                  {cmsContent &&
                    cmsContent?.videoCollection?.map((x, index) => {
                      return (
                        <li
                          key={index}
                          className="iview-view-collection-subheading"
                        >
                          <Icon icon="arrow_down" size={16} color={context?.theme === "t-light" ? "var(--colour-action-feature)" : "var(--colour-action-primary)"} />
                          <span
                            className="iview-library-item"
                            id="iview-library-items"
                            onClick={() =>
                              scrollToSection(x.ID)
                            }
                          >
                            {x.Value}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {cmsContent &&
        cmsContent?.ResourceCardList &&
        cmsContent?.ResourceCardList?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                "background-" + ((index + 1) % 2 == 0 ? "light" : "dark")
              }
              id={"card_" + index}
            >
              <Container
                fluid
                className="p-lg-5 p-3"
                id={item.AssessmentId}
              >
                <Row className="position-relative">
                  <Col md="12" lg="5">
                    <span
                      className={
                        "iview-collection-title-" +
                        ((index + 1) % 2 == 0 ? "light" : "dark")
                      }
                    >
                      {item.Title}
                    </span>
                    <Row>
                      <Col md="12" lg="12">
                        <span
                          className={
                            "iview-collection-subtitle-" +
                            ((index + 1) % 2 == 0 ? "light" : "dark")
                          }
                        >
                          {item?.AssessmentType} ·{" "}
                          {item?.asessmentCount?.toUpperCase()}
                          {/* {item.ResourceCards.length} */}
                        </span>
                      </Col>
                    </Row>
                    <Row className="common-margin-from-top">
                      <Col md="12" lg="12">
                        <span
                          className={
                            "iview-collection-description-" +
                            ((index + 1) % 2 == 0 ? "light" : "dark")
                          }
                        >
                          {item.ShortDescription}
                        </span>
                      </Col>
                    </Row>
                    <Row className="common-margin-from-top">
                      <Col md="12" lg="12">
                        <Cirrus.Button
                          onClick={() => {
                            navigate(
                              `/iview-collecion-page/${item?.AssessmentId}`
                            );
                          }}
                          className={
                            "background-button-" +
                            ((index + 1) % 2 == 0 ? "light" : "dark")
                          }
                        >
                          {fetchResourceLabel("CommonResourceComponent.ViewFullCollectionButton")}
                        </Cirrus.Button>
                      </Col>
                    </Row>
                  </Col>
                  {item.resourcePages.length > 0 && (
                    <Col md="12" lg="7" className="d-flex iviewCards">
                      <Cirrus.IviewCarousel
                        nextIconButtonProps={{
                          icon:
                            item.resourcePages.length > 2
                              ? "arrow_forward"
                              : "",
                          onClick: () => { },
                          text: item.resourcePages.length > 2 ? "Next" : "",
                        }}
                        previousIconButtonProps={{
                          icon:
                            item.resourcePages.length > 2 ? "arrow_back" : "",
                          onClick: function noRefCheck() { },
                          text:
                            item.resourcePages.length > 2 ? "Previous" : "",
                          type: "button",
                        }}
                      >
                        {item &&
                          item.resourcePages?.map((card, index) => {
                            return (
                              <Cirrus.Placeholder
                                style={{
                                  display: "inline-block",
                                  marginRight: "1rem",
                                  width: "300px",
                                  marginBottom: "30px",
                                }}
                                className="iview-card-remove-padding"
                              >
                                <Cirrus.IviewCard
                                  title={card.Title}
                                  description={card.ShortDescription}
                                  link={{
                                    url: `/#/iview-interview-start/${card?.id}/${card?.externalId}`,
                                  }}
                                  tags={card.FocusArea}
                                  saveButton={{
                                    label: fetchLabel("CommonComponent.CommonSaveButton"),
                                    secondaryLabel: fetchLabel("CommonComponent.CommonSavedButton"),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        card && card.id && card.id,
                                        card && card.issaved && card.issaved,
                                        card.isLiked && card.isLiked,
                                        card.LearningResourceStatus && card.LearningResourceStatus,
                                        "save",
                                        card && card.externalId
                                      );
                                    },
                                  }}
                                  status={card.LearningResourceStatus == "notstarted" ? {
                                    name: "new",
                                    label: card && card.ResourceType,
                                    value: card && card.Duration,
                                  } : {
                                    name: "new",
                                    label: card && card?.ResourceType,
                                    value: card && card?.LearningResourceStatus === "Completed" ? fetchResourceLabel("CommonResourceComponent.Completed") : card?.LearningResourceStatus,
                                  }}
                                  type="assessment"
                                  layout="horizontal"
                                  likeText={
                                    card && card.totalLikedCount
                                      ? card.totalLikedCount +
                                      " " +
                                      `${card.totalLikedCount > 1
                                        ? fetchLabel("CommonComponent.CommonLikesText")
                                        : fetchLabel("CommonComponent.CommonLikeText")
                                      }`
                                      : "0 " + fetchLabel("CommonComponent.CommonLikeText")
                                  }
                                  onLikeButtonClick={(e) =>
                                    saveAssessmentCard(
                                      e,
                                      card && card.id && card.id,
                                      card.issaved && card.issaved,
                                      card && card.isLiked && card.isLiked,
                                      card.LearningResourceStatus && card.LearningResourceStatus,
                                      "like",
                                      card && card.externalId
                                    )
                                  }
                                  isLiked={card && card.isLiked}
                                  isSaved={card && card.issaved}
                                  className="title-and-description iview-carousel-cards"
                                />
                              </Cirrus.Placeholder>
                            );
                          })}
                      </Cirrus.IviewCarousel>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          );
        })}
      {/* </Cirrus.Page>
      <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}

export default IviewWelcomePage;
