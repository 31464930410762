//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import "../index.css";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { DiscoverActions } from "../DiscoverActions";
import { AssessmentActions } from "../../assessments/AssessmentActions";
import { EventsActions } from "../../myEvents/EventsActions";
import { Context } from "../../../common/context/Context";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { filter } from "domutils";
// import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import {
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

Array.prototype.chunk = function (n) {
  if (!this.length) {
    return [];
  }
  return [this.slice(0, n)].concat(this.slice(n).chunk(n));
};

function SubjectCategories() {
  const context = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const [commonImage, setCommonImage] = useState({});
  const [cmsSA, setCmsSA] = useState([]);
  const [liveEventsData, setLiveEventsData] = useState([]);
  const [liveEventItems, setLiveEventItems] = useState([]);
  const [onDemandEventItems, setOnDemandEventItems] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [sortByArea, setSortByArea] = useState([]);
  const [sortByProgress, setSortByProgress] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [enableSearch, setEnableSearch] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [assessmentCardData, setAssessmentCardData] = useState();
  const [assessmentSecondary, setAssessmentSecondary] = useState(false);
  const [resourcesSecondary, setResourcesSecondary] = useState(true);
  const [toolsSecondary, setToolsSecondary] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const [chunkedData, setChunkedData] = useState([]);
  const [sortByAreaData, setSortByAreaData] = useState("");
  const [sortByProgressData, setSortByProgressData] = useState("");
  const [filterStatus, setFilterStatus] = useState(true);
  const [LCFilterLabels, setLCFilterLabels] = useState({
    labels: [],
    lovList: [],
  });
  const [LCPages, setLCPages] = useState([]);
  const [resFilterd, setResFilterd] = useState(false);
  const [mobileFilter, setMobileFilter] = useState(false);

  const [filterResultForm, setFilterResultForm] = useState({
    isFilterApplied: false,
    applyButtonStatus: true,
    sortByArea: {
      name: "sortByArea",
      concatValue: "",
      value: [],
    },
    sortByProgress: {
      name: "sortByProgress",
      concatValue: "",
      value: "",
    },
  });
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [shownCards, setShownCards] = useState(4);
  const [assessmentData, setAssessmentData] = useState([]);
  const [toolsData, setToolsData] = useState([]);
  const [resourcesData, setResourcesData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [lcCMS, setLCCms] = useState({
    title: "",
    description: "",
    pageContent: [],
    imageUrl: "",
    commonComponentLabels: [],
    commonComponentLOVList: [],
    subjectCategory: "",
  });
  const [LCEvents, setLCEvents] = useState({
    imageURL: [],
    labels: [],
  });
  const [LCLabels, setLCLabels] = useState({
    labels: [],
  });
  const [saveLikeSpinner, setSaveLikeSpinner] = useState(false);

  const appInsights = useAppInsightsContext();

  const fetchCMSDiscoverData = (tabName, hash) => {
    setScreenLoader(true);
    let refLCCms = JSON.parse(JSON.stringify(lcCMS));
    let refLCLabels = JSON.parse(JSON.stringify(LCLabels));
    let refLCEvents = JSON.parse(JSON.stringify(LCEvents));
    let refFilterLabels = JSON.parse(JSON.stringify(LCFilterLabels));

    let payload = {
      PageName: "SubjectCategories",
    };
    console.log("refFilterLabels", refFilterLabels);
    DiscoverActions.GetDiscoverCMSData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData;
          let responseData = res.data && res.data;
          let pagesData = responseData?.Pages;
          // fetchCategoryList(pagesData && pagesData);

          const list =
            pagesData &&
            pagesData.map(({ ID, BrowserTitle }) => ({ ID, BrowserTitle }));
          let tab = hash && hash[2].replaceAll("-", " ");
          setSubjectData(list && list);
          fetchCMSData(tab, list && list);

          resData =
            responseData?.Pages &&
            responseData?.Pages.filter(
              (x) => (x?.PageUrl?.Url).toLowerCase() == tabName.toLowerCase()
            );
          resData = resData && resData[0];
          setLCPages(
            res.data.Pages.map((page) => {
              return {
                ...page,
                heading: page.TabNavigationTitle,
                id: page?.PageUrl?.Url.split("/")[2],
                url: page?.PageUrl?.Url,
                isVisible: true,
                onClick: () => {
                  navigate(`${page?.PageUrl?.Url}`);
                },
              };
            })
          );

          let commonData =
            responseData?.Pages &&
            responseData?.Pages.filter((x) => x?.Key == "Discover.Onboarding");
          commonData = commonData && commonData[0];

          refLCCms.title = resData?.Title;
          refLCCms.description = resData?.Description;
          // refLCCms.imageUrl = commonData?.LargeImage?.ImageUrl;
          refLCCms.imageUrl = resData?.LargeImage?.ImageUrl;
          refLCCms.subjectCategory = resData?.ID;

          refLCCms.commonComponentLabels =
            commonData?.Components["CommonComponent"]?.Labels;
          refLCCms.commonResourceLabels =
            commonData?.Components["CommonResourceComponent"]?.Labels;
          refLCCms.commonComponentLOVList =
            commonData?.Components["CommonComponent"]?.LOVList;
          setLCCms(refLCCms);
          fetchAssessmentData("assessments", resData?.ID);
          fetchCardCount(resData?.ID);

          let filterLabels =
            commonData &&
            commonData?.Components[
              "Discover.DiscoveryPage.AllLearningResourceFilter"
            ];
          refFilterLabels.labels = filterLabels?.Labels;
          refFilterLabels.lovList = filterLabels?.LOVList;
          setLCFilterLabels(refFilterLabels);

          let LCLabelsSection =
            commonData &&
            commonData?.Components["Discover.DiscoverCommonLabels"];
          refLCLabels.labels = LCLabelsSection?.Labels;
          setLCLabels(refLCLabels);

          let eventsSection =
            commonData && commonData?.Components["Events.EventsCourseCard"];
          refLCEvents.labels = eventsSection?.Labels;
          refLCEvents.imageURL = eventsSection?.MediaData?.LargeImage?.ImageUrl;
          setLCEvents(refLCEvents);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setIsMobile(window.innerWidth < Cirrus.devices.LargeTablet);
      }, 250);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash && window.location.hash.split("/");
    const pageCollection = `/${hash[1]}/${hash[2]}`;
    fetchCMSDiscoverData(pageCollection, hash);
    TrackActions.PageViewRecord(appInsights, pageCollection);
    TrackActions.PageAction(pageCollection);
    setActiveTab(hash[2]);
  }, []);

  useEffect(() => {
    let sortByArea = [];
    let sorbyProgress = [];
    LCFilterLabels?.lovList?.filter((x) => {
      if (x.Key === "Discover.SortByArea") {
        x.Values.map((ct) => {
          let obj = {
            label: ct.Value,
            value: ct.Key,
          };
          sortByArea.push(obj);
        });
        setSortByArea(sortByArea);
      }
      if (x.Key === "Discover.SortbyProgress") {
        x.Values.map((ct) => {
          let obj = {
            label: ct.Value,
            value: ct.Key,
          };
          sorbyProgress.push(obj);
        });
        setSortByProgress(sorbyProgress);
      }
    });
  }, [LCFilterLabels]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash && window.location.hash.split("/");
    const pageCollection = `/${hash[1]}/${hash[2]}`;
    fetchCMSDiscoverData(pageCollection, hash);
    setActiveTab(hash[2]);
  }, [context.language, window.location.hash]);

  //Live and OnDemand Events to show based on Subcategory Starts
  useEffect(async () => {
    getSubCategoryEvents();
  }, [liveEventItems, onDemandEventItems]);

  const getSubCategoryEvents = () => {
    setLiveEventsData([...liveEventItems, ...onDemandEventItems]);
  };
  //Live and OnDemand Events to show based on Subcategory Ends

  const fetchCMSData = async (tabName, subjectList) => {
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let payload = {
      PageName: "Events",
    };
    await EventsActions.GetEventsCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data.Pages[0];
          refCmsSA["title"] = resData?.Title;
          refCmsSA["shortDescription"] = resData?.Description;
          // refCmsRA["subTitle"] = resData?.SubTitle;

          let label = [...resData?.Components?.CommonComponent?.Labels];
          resData?.Components?.CommonComponent?.LOVList[0]?.Values?.map((x) => {
            let obj = {
              Key: x.ID,
              Phrase: x.Value,
            };
            label.push(obj);
          });
          resData?.Components?.["Events.EventsCourseCard"].LOVList.map(
            (Lovs) => {
              Lovs?.Values?.map((x) => {
                let obj = {
                  Key: x.ID,
                  Phrase: x.Value,
                };
                label.push(obj);
              });
            }
          );

          label = [
            ...label,
            ...resData?.Components?.["Events.EventsCommonData"].Labels,
          ];
          label = [
            ...label,
            ...resData?.Components?.["Events.EventsCourseCard"].Labels,
          ];
          label = [
            ...label,
            ...resData?.Components?.["Events.EventsRecommendedEvents"].Labels,
          ];
          setCommonImage(
            resData?.Components?.["Events.EventsCourseCard"].MediaData
          );
          refCmsSA["masterLabel"] = label;
          setCmsSA(refCmsSA?.masterLabel);
          fetchLiveEvents(false, subjectList, tabName);
          fetchOnDemandEvents(subjectList, tabName);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsSA && cmsSA));
    let label = "";
    if (refCmsContent.length > 0) {
      refCmsContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchTabId = (key, subjectList) => {
    let refCmsContent = subjectList && subjectList;
    let label = "";
    if (refCmsContent.length > 0) {
      refCmsContent?.map((x) => {
        //if (x.BrowserTitle.toLowerCase().replaceAll("-", " ") === key) {
        if (x.BrowserTitle.toLowerCase().replace(/[\s-]/g, "") === key) {
          label = x.ID;
        }
      });
    }
    return label;
  };

  const fetchTabName = (id, subjectList) => {
    let refCmsContent = subjectList && subjectList;
    let label = "";
    if (refCmsContent.length > 0) {
      refCmsContent?.map((x) => {
        if (x.ID === id) {
          label = x.BrowserTitle;
        }
      });
    }
    return label;
  };

  const fetchLiveEvents = async (saveClick, subjectList, tabName) => {
    if (saveClick) {
      setScreenLoader(false);
    } else {
      setScreenLoader(true);
    }

    let payload = {
      cource_type: "classroom",
    };

    await EventsActions.GetEvents(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data;
          const reformedData = reformResponse(resData && resData);
          let filterTab = fetchTabId(tabName, subjectList);
          filterTab = filterTab + "";
          const liveData =
            filterTab &&
            filterTab !== "" &&
            reformedData &&
            reformedData.filter((x) => {
              return x.subject_category.includes(filterTab);
            });
          //setLiveEventsData(liveData && liveData);
          setLiveEventItems(liveData && liveData);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        setScreenLoader(false);
        // navigate('/');
      }
    );
  };

  const fetchOnDemandEvents = async (subjectList, tabName) => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    //setScreenLoader(true);
    let filterTab = fetchTabId(tabName, subjectList);
    filterTab = filterTab + "";
    let payload = {
      ResourceCategory: "events",
      ResourceSubCategory: "E3",
      learningResourceType: "e3",
      recommended: "false",
      isAllLearningResources: "true",
      ProgramClassIds: ProgramData?.programClassId
        ? [ProgramData?.programClassId]
        : ["18"],
      SubjectCategory: filterTab,
    };
    await EventsActions.GetEvents(payload).then(
      (res) => {
        if (res.status === 200) {
          let onDemandEvents = res.data;
          onDemandEvents = onDemandEvents.map((ondemandEvent) => {
            let eventObj = {
              event_name: ondemandEvent.title,
              event_description: ondemandEvent.shortDescription,
              event_id: ondemandEvent.id,
              isSaved: ondemandEvent.isSaved,
              image_thumbnail: ondemandEvent?.largeImage?.imageUrl,
              event_type: "E3",
            };
            return eventObj;
          });
          setOnDemandEventItems(onDemandEvents && onDemandEvents);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const reformResponse = (data) => {
    const resultWithProgram = data && data.filter((x) => x.subject_category);
    const resultWithoutNull =
      resultWithProgram &&
      resultWithProgram.filter((x) => x.subject_category !== " ");
    const result =
      resultWithoutNull &&
      resultWithoutNull.map((ele) => {
        return {
          ...ele,
          subject_category: ele.subject_category
            .replace(/ /g, "")
            .match(/.{1,4}/g),
        };
      });
    return result;
  };

  const getTags = (event) => {
    let tabName = activeTab && activeTab.replaceAll("-", " ");
    let filterTab = fetchTabId(tabName, subjectData && subjectData);

    let recommended = false;
    let tagNames = [];
    recommended = event.is_recommended;

    tagNames = fetchTabName(filterTab, subjectData);
    tagNames = [tagNames];
    if (recommended) {
      if (tagNames) {
        tagNames.push(fetchLabel("Events.EventsCourseCard.Recommended"));
      } else {
        tagNames = [fetchLabel("Events.EventsCourseCard.Recommended")];
      }
    }
    return tagNames;
  };

  const getEventStatus = (eventDetails) => {
    let statusLabel = "";
    if (eventDetails.isRegistered || eventDetails.user_enrolled) {
      if (eventDetails?.status?.toLowerCase() === "Completed".toLowerCase()) {
        statusLabel = fetchLabel("completed");
      } else if (eventDetails.status === "waiting") {
        statusLabel = fetchLabel("waitlisted");
      } else {
        statusLabel = fetchLabel("enrolled");
      }
    }

    return statusLabel;
  };

  const getDescription = (desc) => {
    let tempDesc = `<p>${desc}</p>`;
    return <Cirrus.RichText content={tempDesc} />;
  };

  const fetchLCLabels = (key) => {
    let refLRContent = LCLabels && LCLabels?.labels;
    let label = "";
    if (refLRContent) {
      refLRContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchLCFiltersLabels = (key) => {
    let refLRContent = LCFilterLabels && LCFilterLabels?.labels;
    let label = "";
    if (refLRContent) {
      refLRContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchAssessmentData = (type, areaofFocus) => {
    if (saveLikeSpinner === true) {
      setScreenLoader(false);
    }
    let refShownCards = JSON.parse(JSON.stringify(shownCards));
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    setShowLoadMore(true);
    let resourceType =
      type === "assessments"
        ? "assessmentsandtasks"
        : type === "tools"
        ? "tools"
        : type === "resources"
        ? "resources"
        : "";
    let resourceCategory =
      type === "assessments"
        ? "assessmentsandtasks"
        : type === "tools"
        ? "tools"
        : type === "resources"
        ? "resources"
        : "";
    let payload = {
      learningResourceType: resourceType,
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: resourceCategory,
      subjectCategory: areaofFocus,
      ProgramClassIds:
        resourceCategory == "resources"
          ? ProgramData?.programClassId
            ? [(ProgramData?.programClassId).toString()]
            : ["18"]
          : null,
    };
    DiscoverActions.GetDiscoverData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data && res?.data?.responsemodel;
          if (type === "assessments") {
            setAssessmentData(resData && resData);
          } else if (type === "tools") {
            setToolsData(resData && resData);
          } else if (type === "resources") {
            setResourcesData(resData && resData);
          }
          refShownCards = 4;
          setShownCards(refShownCards);
          setAssessmentCardData(resData);
          fetchSortedData(resData);
          setSaveLikeSpinner(true);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        refShownCards = 4;
        setShownCards(refShownCards);
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchCardCount = async (areaOfFocus) => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";

    let resourcesPayload = {
      learningResourceType: "resources",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "resources",
      subjectCategory: areaOfFocus,
      ProgramClassIds: ProgramData?.programClassId
        ? [(ProgramData?.programClassId).toString()]
        : "",
    };

    let assessPayload = {
      learningResourceType: "assessmentsandtasks",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "assessmentsandtasks",
      subjectCategory: areaOfFocus,
    };

    let toolsPayload = {
      learningResourceType: "tools",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "tools",
      subjectCategory: areaOfFocus,
    };

    await DiscoverActions.GetDiscoverData(toolsPayload).then(
      (res) => {
        if (res.status === 200) {
          let countData = res?.data && res?.data?.learningResourceCount;
          let resData = res?.data && res?.data?.responsemodel;
          window.sessionStorage.setItem(
            "ToolsCountSC",
            countData?.tools ? countData?.tools : 0
          );
          setToolsData(resData && resData);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    await DiscoverActions.GetDiscoverData(assessPayload).then(
      (res) => {
        if (res.status === 200) {
          let countData = res?.data && res?.data?.learningResourceCount;
          let resData = res?.data && res?.data?.responsemodel;
          window.sessionStorage.setItem(
            "AssessmentCountSC",
            countData?.assessments ? countData?.assessments : 0
          );
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    await DiscoverActions.GetDiscoverData(resourcesPayload).then(
      (res) => {
        if (res.status === 200) {
          let countData = res?.data && res?.data?.learningResourceCount;
          let resData = res?.data && res?.data?.responsemodel;
          window.sessionStorage.setItem(
            "ResourcesCountSC",
            countData?.resources ? countData?.resources : 0
          );
          setResourcesData(resData && resData);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const searchCard = (keyword) => {
    setSearchKeyword(keyword);
    if (keyword.length >= 2) {
      setEnableSearch(false);
    } else if (keyword.length > 0) {
      setSearchKeyword("");
      fetchSortedData(assessmentCardData, null, null, "");
      setEnableSearch(true);
    }
  };

  const searchHandler = (event) => {
    setEnableSearch(true);
    fetchSortedData(assessmentCardData);
  };

  const fetchSortedData = (data, sortByArea, sortByProgress) => {
    const notStartedArray =
      data &&
      data.filter(
        (x) =>
          x.learningResourceStatus === "notstarted" ||
          x.learningResourceStatus === ""
      );
    const NSSortedArray = notStartedArray.sort(
      (a, b) => b.likeCount - a.likeCount
    );

    const inProgressArray =
      data && data.filter((x) => x.learningResourceStatus === "inprogress");

    const completedArray =
      data && data.filter((x) => x.learningResourceStatus === "completed");
    const sortedArray = completedArray.sort(
      (a, b) => b.likeCount - a.likeCount
    );

    let refsortedData = JSON.parse(JSON.stringify(sortedData));
    refsortedData = [...NSSortedArray, ...inProgressArray, ...sortedArray];
    setSortedData(refsortedData);

    let filterResult = [];

    if (sortByArea) {
      let filterDataByArea =
        refsortedData &&
        refsortedData.filter((x) =>
          x?.focusArea?.some((y) => y?.value == sortByArea)
        );
      filterResult = [...filterDataByArea];
    }
    if (sortByProgress) {
      let sortyProgressData = sortByProgress.replace(/\s+/g, "").toLowerCase();
      let filterDataByProgress =
        refsortedData &&
        refsortedData.filter(
          (x) => x.learningResourceStatus == sortyProgressData
        );
      filterResult = [...filterDataByProgress];
    }

    let refchunkedData = JSON.parse(JSON.stringify(chunkedData));
    if (sortByArea || sortByProgress) {
      refchunkedData = filterResult && filterResult.slice(0, 4);
    } else {
      refchunkedData = refsortedData && refsortedData.slice(0, 4);
    }

    if (searchKeyword && searchKeyword) {
      let searchData;
      let filteredData;

      let searchTitleData =
        refsortedData &&
        refsortedData.filter((x) =>
          x?.title?.toLowerCase().includes(searchKeyword.toLowerCase())
        );

      let searchDescriptionData =
        refsortedData &&
        refsortedData.filter((x) =>
          x?.shortDescription
            ?.toLowerCase()
            .includes(searchKeyword.toLowerCase())
        );

      let searchFocusAreaData =
        refsortedData &&
        refsortedData.filter((x) =>
          x?.focusArea?.some((y) =>
            y?.value?.toLowerCase().includes(searchKeyword.toLowerCase())
          )
        );

      let subjectCategoryData =
        refsortedData &&
        refsortedData.filter((x) =>
          x?.subjectCategory?.some((y) =>
            y?.value?.toLowerCase().includes(searchKeyword.toLowerCase())
          )
        );

      filteredData = [
        ...searchTitleData,
        ...searchDescriptionData,
        ...searchFocusAreaData,
        ...subjectCategoryData,
      ];

      searchData = filteredData.reduce((unique, o) => {
        if (!unique.some((obj) => obj.resourceId === o.resourceId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      refchunkedData = searchData && searchData.slice(0, 4);
    }

    setChunkedData(refchunkedData);
  };

  const displayLoadMore = (load) => {
    return (
      assessmentCardData &&
      assessmentCardData.length > 4 &&
      load && (
        <Cirrus.Button
          onClick={() => {
            loadMore();
          }}
        >
          {fetchLikeLabel("CommonComponent.CommonLoadMore")}
        </Cirrus.Button>
      )
    );
  };

  const loadMore = () => {
    let refchunkedData = JSON.parse(JSON.stringify(chunkedData));
    let refshownCards = JSON.parse(JSON.stringify(shownCards));
    refshownCards = shownCards + 4;
    setShownCards(refshownCards);
    refchunkedData = sortedData && sortedData.slice(0, refshownCards);
    setChunkedData(refchunkedData);
    if (assessmentCardData.length == refchunkedData.length) {
      setShowLoadMore(false);
      displayLoadMore(false);
    }
  };

  const saveAssessmentCard = (
    e,
    id,
    savedStatus,
    likedStatus,
    type,
    learningResourceStatus,
    pageNumber,
    resourceSubType,
    resourceCatType
  ) => {
    e.preventDefault();
    let resourceType = resourceCatType;
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: id,
      lastAnsweredPageId: pageNumber,
      learningResourceType: resourceCatType,
      resourceTypeId: resourceSubType,
      learningResourceStatus: learningResourceStatus,
      isSaved:
        type === "save" ? (savedStatus == true ? false : true) : savedStatus,
      isLiked:
        type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    };

    DiscoverActions.SaveAssessmentCard(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          setSaveLikeSpinner(true);
          fetchAssessmentData(
            resourceType === "assessmentsandtasks"
              ? "assessments"
              : resourceType === "tools"
              ? "tools"
              : resourceType === "resources"
              ? "resources"
              : "",
            lcCMS?.subjectCategory
          );
          updateStatus(resourceType, id, payload);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
        setSaveLikeSpinner(true);
      }
    );
  };

  const saveActivity2 = (type) => {
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: type.learningResourceId,
      value: type?.title || "null",
    };
    type.isLiked === false
      ? (payload["type"] =
          type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentLike"
            : type.resourceCategoryType.id.toLowerCase() === "tools"
            ? "ToolLike"
            : `${type.resourceType.id + "Like"}`)
      : (payload["type"] =
          type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentUnLike"
            : type.resourceCategoryType.id.toLowerCase() === "tools"
            ? "ToolUnLike"
            : `${type.resourceType.id + "UnLike"}`);
    AssessmentActions.CreateActivity(payload).then(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  };
  const updateStatus = (resourceType, id, payload) => {
    DiscoverActions.UpdateStatus(resourceType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          setSaveLikeSpinner(true);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
        setSaveLikeSpinner(true);
      }
    );
  };

  const fetchLikeLabel = (id) => {
    let refCmsContent = lcCMS && lcCMS?.commonComponentLabels;
    let label = "";
    if (refCmsContent && refCmsContent) {
      refCmsContent.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsContent = lcCMS && lcCMS?.commonResourceLabels;
    let label = "";
    if (refCmsContent && refCmsContent) {
      refCmsContent.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchDBhoverURL = () => {
    let date = new Date();
    let param1 = Math.floor(Math.random() * 10).toString();
    let param2 = (
      date.getDate()?.toString()?.length < 2
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    )
      .substring(1, 2)
      .toString();
    let param3 = (
      (date.getMonth() + 1)?.toString()?.length < 2
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString()
    )
      .substring(0, 1)
      .toString();
    let param4 = (
      date.getFullYear()?.toString()?.length < 2
        ? date.getFullYear().toString()
        : date.getFullYear().toString()
    )
      .substring(3, 4)
      .toString();
    let param5 = Math.floor(Math.random() * 10).toString();
    let param6 = Math.floor(Math.random() * 10).toString();
    let param7 = (
      (date.getMonth() + 1)?.toString()?.length < 2
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString()
    )
      .substring(1, 2)
      .toString();
    let param8 = (
      date.getDate()?.toString()?.length < 2
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    )
      .substring(0, 1)
      .toString();
    let param9 = Math.floor(Math.random() * 10).toString();

    let st =
      param1 +
      param2 +
      param3 +
      param4 +
      param5 +
      param6 +
      param7 +
      param8 +
      param9;
    let userInfo = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    let programCountry = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    let URL = `http://signon.oneSource.com?LogonUserID=${userInfo.emailAddress}&ST=${st}&AT=917360db07a6e0cdbbde3ff088b0e70d&Fname=${userInfo.firstName}&Lname=${userInfo.lastName}&Country=${programCountry.programCountryCode}`;
    window.open(URL);
  };

  const fetchLesEchosURL = async (id) => {
    await DiscoverActions.ExternalToolsRedirectURL(id).then(
      (res) => {
        if (res.status === 302) {
          window.open(res.data);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const cardRedirectHandler = (item) => {
    if (
      item?.resourceType?.id === "article" ||
      item?.resourceType?.id === "video" ||
      item?.resourceType?.id === "document" ||
      item?.resourceType?.id === "E3"
    ) {
      window.open(`#/article-page/${item?.resourceId}`, "_self");
    } else {
      if (item?.key === "Tools.DnBHover") {
        fetchDBhoverURL();
      } else if (item?.key === "Tools.OnDemandVideoInterviews") {
        window.open(`#/iview-welcome`, "_self");
      } else if (item?.key === "Tools.30SecondCommercial") {
        window.open(
          `#/iview-interview-start/${item?.resourceId}/${item?.externalId}`,
          "_self"
        );
      } else if (item?.key === "Tools.Cobra") {
        window.open("https://rightmanagement.cobwebinfo.com/");
      } else if (item?.key === "Tools.FirstEco") {
        window.open("https://www.right.firsteco.fr/");
      } else if (
        item?.key === "Tools.LesEchos" ||
        item?.key === "Tools.Diane" ||
        item?.key === "Tools.Corporama" ||
        item?.key === "Tools.Executives"
      ) {
        fetchLesEchosURL(item?.resourceId);
      } else if (item?.key === "Tools.AbintegroCareerCentre") {
        window.open(process.env.REACT_APP_ABINTEGRO_TOOL_URL);
      } else {
        navigate(item?.resourceUrl?.url);
      }
    }
  };

  const fetchLearningStatus = (id) => {
    let refCmsAssessmentContent = lcCMS && lcCMS?.commonComponentLOVList;
    let label = "";
    if (refCmsAssessmentContent[0]?.Values) {
      refCmsAssessmentContent[0]?.Values.map((x) => {
        if (x.ID === id) {
          label = x.Value;
        }
      });
    }
    return label;
  };

  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  function getFeaturesConfig(featureName) {
    let val;
    featuresConfig?.features?.map((feature) => {
      if (feature.featureName === featureName) {
        val = feature.isActive;
      }
    });
    return val;
  }

  if (screenLoader) {
    return (
      <div>
        <Spinner spinnerTimer={screenLoader} />
      </div>
    );
  }

  const updateEvents = async (eventDetails) => {
    let tabName = activeTab && activeTab.replaceAll("-", " ");

    let id = eventDetails.event_id
      ? eventDetails.event_id
      : eventDetails.id
      ? eventDetails.id
      : "";
    let payload = {
      ResourceType: "External",
      EventType:
        eventDetails?.event_type == "E3"
          ? "onDemand"
          : fetchLabel(eventDetails.event_type),
      EventName: eventDetails?.event_name,
      IsSaved: !eventDetails.isSaved,
      Status: eventDetails.status,
      IsRegistered: eventDetails?.isRegistered,
      RegisteredTimeStamp: eventDetails?.RegisteredTimeStamp,
      EventDate: eventDetails?.eventDate,
      StartTime: eventDetails?.StartTime,
      EndTime: eventDetails?.Endtime,
    };

    await EventsActions.UpdateEvent(payload, id).then(
      (res) => {
        if (res.status === 200) {
          if (eventDetails?.event_type == "E3") {
            fetchOnDemandEvents(subjectData, tabName);
          } else {
            fetchLiveEvents(true, subjectData, tabName);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const clearAllFilterHandler = () => {
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    refFilterResultForm.sortByArea.value = [];
    refFilterResultForm.sortByProgress.value = [];
    setFilterResultForm(refFilterResultForm);
    fetchSortedData(assessmentCardData);
  };

  const filterFormHandler = (event, sortby) => {
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));

    if (sortby === "sortByArea") {
      refFilterResultForm.sortByArea.value = event;
      setFilterResultForm(refFilterResultForm);
    }

    if (sortby === "sortByProgress") {
      refFilterResultForm.sortByProgress.value = event;
      setFilterResultForm(refFilterResultForm);
    }

    setSortByAreaData(sortby === "sortByArea" && event[0].label);
    setSortByProgressData(sortby === "sortByProgress" && event[0].label);
  };

  const applyFilterHandler = (event) => {
    setResFilterd(!resFilterd);
    setMobileFilter(false);
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    refFilterResultForm.isFilterApplied = true;
    fetchSortedData(assessmentCardData, sortByAreaData, sortByProgressData);
  };

  const searchFilterFormProps = {
    salarySelectProps: {
      id: filterResultForm.sortByArea.name,
      label: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.FilterByArea"
      ),
      name: filterResultForm.sortByArea.name,
      placeholder: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.AllAreas"
      ),
      options: sortByArea,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.sortByArea.name),
      selectedValue: filterResultForm.sortByArea.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    filterName: isMobile ? "Filters" : "",
    contractSelectProps: {
      id: filterResultForm.sortByProgress.name,
      label: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.FilterByProgress"
      ),
      name: filterResultForm.sortByProgress.name,
      placeholder: fetchLikeLabel("CommonComponent.CommonSelectPlaceHolder"),
      options: sortByProgress,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.sortByProgress.name),
      selectedValue: filterResultForm.sortByProgress.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    applyFiltersButtonProps: {
      children: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.ApplyButton"
      ),
      size: "Large",
      type: "button",
      onClick: (event) =>
        applyFilterHandler(
          event,
          filterResultForm.sortByArea.value != ""
            ? filterResultForm.sortByArea.name
            : "",
          filterResultForm.sortByProgress.value != ""
            ? filterResultForm.sortByProgress.name
            : ""
        ),
      disabled: "",
    },
    resetFiltersLinkButtonProps: {
      children: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.ClearAllFilter"
      ),
      type: "reset",
      onClick: (event) => clearAllFilterHandler(),
    },
    onSubmit: (event) => {
      alert("form submitted");
    },
  };

  const searchPropsForToolsResources = {
    salarySelectProps: {
      id: filterResultForm.sortByArea.name,
      label: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.FilterByArea"
      ),
      name: filterResultForm.sortByArea.name,
      placeholder: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.AllAreas"
      ),
      options: sortByArea,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.sortByArea.name),
      selectedValue: filterResultForm.sortByArea.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    filterName: isMobile ? "Filters" : "",
    applyFiltersButtonProps: {
      children: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.ApplyButton"
      ),
      size: "Large",
      type: "button",
      onClick: (event) =>
        applyFilterHandler(
          event,
          filterResultForm.sortByArea.value != ""
            ? filterResultForm.sortByArea.name
            : "",
          filterResultForm.sortByProgress.value != ""
            ? filterResultForm.sortByProgress.name
            : ""
        ),
      disabled: "",
    },
    resetFiltersLinkButtonProps: {
      children: fetchLCFiltersLabels(
        "DiscoveryPage.AllLearningResourcesFilter.ClearAllFilter"
      ),
      type: "reset",
      onClick: (event) => clearAllFilterHandler(),
    },
    onSubmit: (event) => {
      alert("form submitted");
    },
  };

  let eventsTitle = fetchLCLabels(
    "Discover.DiscoverCommonLabels.RelatedUpcomingEvents"
  );
  eventsTitle =
    eventsTitle && eventsTitle.replace("#AreaOfFocus#", lcCMS && lcCMS?.title);

  let learningCenterTitle = fetchLCLabels(
    "Discover.DiscoverCommonLabels.LearningResourcesText"
  );
  learningCenterTitle =
    learningCenterTitle &&
    learningCenterTitle.replace("#AreaOfFocus#", lcCMS && lcCMS?.title);

  const input =
    document &&
    document.getElementById("search") &&
    document.getElementById("search");
  input &&
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        searchHandler();
      }
    });

  function getLabel(key) {
    let labelList =
      LCFilterLabels &&
      LCFilterLabels.lovList &&
      LCFilterLabels.lovList[0]?.Values;
    let val = labelList?.filter((x) => x.Key == key);
    return val && val[0].Value;
  }

  return (
    <div className="discover-welcome subject-categories-page">
      <Cirrus.Page>
        <Cirrus.PageBanner
          bodyText={lcCMS && lcCMS?.description}
          heading={lcCMS && lcCMS?.title}
          maskImageSource={lcCMS && lcCMS?.imageUrl}
          button={{
            children: fetchLikeLabel("CommonComponent.CommonBackButton"),
            isSecondary: true,
            className: "back-button discover-subpage-back",
            onClick: () => {
              window.history.back();
            },
          }}
          className="subject-category-collection"
        />
        <Cirrus.OneColumnLayout type="wrapped">
          <div className="tabs">
            <Cirrus.TabbedNavigation
              defaultSelected={activeTab && activeTab}
              heading={fetchLCLabels(
                "Discover.DiscoverCommonLabels.SubjectCategoryText"
              )}
              onChange={(selectedTab) => {
                LCPages?.map((pageId) => {
                  if (selectedTab === pageId.id) {
                    navigate(`${pageId?.PageUrl?.Url}`);
                  }
                });
              }}
              tabs={LCPages}
            />
          </div>
          {getFeaturesConfig("EventsPage") && (
            <Container fluid className="self-discovery-recommnded">
              <div className="recommended-section">
                <div className="carousel discover-carousel-buttons">
                  {liveEventsData && liveEventsData.length > 0 ? (
                    <>
                      <Cirrus.Carousel
                        nextIconButtonProps={{
                          icon: "arrow_forward",
                          onClick: () => {},
                          text: "Next",
                          type: "button",
                        }}
                        heading={eventsTitle}
                        previousIconButtonProps={{
                          icon: "arrow_back",
                          onClick: function noRefCheck() {},
                          text: "Previous",
                          type: "button",
                        }}
                      >
                        {liveEventsData &&
                          liveEventsData?.map((allEvents) => {
                            return (
                              <Cirrus.RecommndedResourcesCard
                                className="card"
                                title={allEvents.event_name}
                                description={getDescription(
                                  allEvents.event_description.split("</p>")[0]
                                )}
                                onCardClick={() => {
                                  if (allEvents?.event_type == "E3") {
                                    window.open(
                                      `#/article-page/${allEvents?.event_id}`,
                                      "_self"
                                    );
                                  } else {
                                    window.open(
                                      `#/eventDetails/${allEvents?.event_id}/${
                                        allEvents?.isSaved
                                          ? allEvents.isSaved
                                          : false
                                      }`,
                                      "_self"
                                    );
                                  }
                                }}
                                tags={
                                  getTags(allEvents)?.toString().length === 0
                                    ? ""
                                    : getTags(allEvents)
                                }
                                image={
                                  allEvents.image_thumbnail
                                    ? { src: allEvents.image_thumbnail }
                                    : {
                                        src:
                                          Object.keys(commonImage).length !== 0
                                            ? commonImage?.LargeImage?.ImageUrl
                                            : "",
                                      }
                                }
                                saveButton={{
                                  label: allEvents?.isSaved
                                    ? fetchLabel(
                                        "CommonComponent.CommonSavedButton"
                                      )
                                    : fetchLabel(
                                        "CommonComponent.CommonSaveButton"
                                      ),
                                  secondaryLabel: allEvents?.isSaved
                                    ? fetchLabel(
                                        "CommonComponent.CommonSavedButton"
                                      )
                                    : fetchLabel(
                                        "CommonComponent.CommonSaveButton"
                                      ),
                                  onClick: (e) => {
                                    updateEvents(allEvents);
                                  },
                                }}
                                status={{
                                  name: "new",
                                  label: "Assessment",
                                  value: "40 min",
                                }}
                                type="event"
                                layout="horizontal"
                                eventType={{
                                  eventDetails: [
                                    fetchLabel(allEvents.event_type),
                                  ],
                                  isSuccess: true,
                                  status: getEventStatus(allEvents),
                                }}
                                isSaved={allEvents?.isSaved ? true : false}
                              />
                            );
                          })}
                      </Cirrus.Carousel>
                      {liveEventsData && liveEventsData.length > 4 && (
                        <Cirrus.Button
                          isSecondary
                          onClick={() => {
                            navigate("/events");
                          }}
                          className="view-events"
                        >
                          {fetchLCLabels(
                            "Discover.DiscoverCommonLabels.ViewAllEventsButton"
                          )}
                        </Cirrus.Button>
                      )}
                    </>
                  ) : (
                    <Cirrus.Label>
                      {fetchLabel(
                        "Events.EventsCommonData.NoCoursesFoundMessage"
                      )}
                    </Cirrus.Label>
                  )}
                </div>
              </div>
            </Container>
          )}
          <Container fluid className="w-auto learning-collections">
            <Row>
              <Cirrus.Header className="heading" size="h5">
                {" "}
                {learningCenterTitle}{" "}
              </Cirrus.Header>
              <div className="nav-section">
                <div className="button-container">
                  <div>
                    <Cirrus.Button
                      isSecondary={assessmentSecondary}
                      onClick={() => {
                        setAssessmentSecondary(false);
                        assessmentData &&
                          assessmentData.length > 0 &&
                          setAssessmentCardData(
                            assessmentData && assessmentData
                          );
                        assessmentData && assessmentData.length <= 0
                          ? fetchAssessmentData(
                              "assessments",
                              lcCMS?.subjectCategory
                            )
                          : fetchSortedData(assessmentData && assessmentData);
                        setToolsSecondary(true);
                        setFilterStatus(true);
                        setResourcesSecondary(true);
                      }}
                      trailingNumber={window.sessionStorage.getItem(
                        "AssessmentCountSC"
                      )}
                    >
                      {getLabel("LearningResourceTypes.AssessmentsandTasks")}
                    </Cirrus.Button>
                  </div>
                  <div>
                    <Cirrus.Button
                      isSecondary={toolsSecondary}
                      onClick={() => {
                        setToolsSecondary(false);
                        toolsData &&
                          toolsData.length > 0 &&
                          setAssessmentCardData(toolsData && toolsData);
                        context.languageChanged ||
                        (toolsData && toolsData.length <= 0)
                          ? fetchAssessmentData("tools", lcCMS?.subjectCategory)
                          : fetchSortedData(toolsData && toolsData);
                        setAssessmentSecondary(true);
                        setFilterStatus(false);
                        setResourcesSecondary(true);
                      }}
                      trailingNumber={window.sessionStorage.getItem(
                        "ToolsCountSC"
                      )}
                    >
                      {getLabel("LearningResourceTypes.Tools")}
                    </Cirrus.Button>
                  </div>
                  <div>
                    <Cirrus.Button
                      isSecondary={resourcesSecondary}
                      onClick={() => {
                        setResourcesSecondary(false);
                        resourcesData &&
                          resourcesData.length > 0 &&
                          setAssessmentCardData(resourcesData && resourcesData);
                        context.languageChanged ||
                        (resourcesData && resourcesData.length <= 0)
                          ? fetchAssessmentData(
                              "resources",
                              lcCMS?.subjectCategory
                            )
                          : fetchSortedData(resourcesData && resourcesData);
                        setToolsSecondary(true);
                        setFilterStatus(false);
                        setAssessmentSecondary(true);
                      }}
                      trailingNumber={window.sessionStorage.getItem(
                        "ResourcesCountSC"
                      )}
                    >
                      {getLabel("LearningResourceTypes.Resources")}
                    </Cirrus.Button>
                  </div>
                </div>
                <div className="search-filters">
                  <Cirrus.Input
                    icon="search"
                    id="search"
                    isRounded
                    onChange={(event) => searchCard(event.target.value)}
                    searchButtonProps={{
                      children: "Search",
                      size: "large",
                      type: "button",
                      onClick: () => searchHandler(),
                      disabled: enableSearch,
                    }}
                    showSearchButton
                    placeholder={fetchLCFiltersLabels(
                      "DiscoveryPage.AllLearningResourcesFilter.KeywordSearchText"
                    )}
                  />
                </div>
                <div>
                  <Cirrus.Button
                    leadingIcon={isFilterOpen ? "close" : "filter_alt"}
                    isAlt={true}
                    isSecondary={true}
                    onClick={() => {
                      setIsFilterOpen(!isFilterOpen);
                    }}
                    className={
                      isFilterOpen
                        ? "filter-button filter-button_filter is-active"
                        : "filter-button filter-button_filter"
                    }
                  >
                    {fetchLCFiltersLabels(
                      "DiscoveryPage.AllLearningResourcesFilter.FiltersButton"
                    )}
                  </Cirrus.Button>
                </div>
              </div>
              <div className="filter-model">
                {isFilterOpen &&
                  !isMobile &&
                  (filterStatus ? (
                    <Cirrus.ResourceSearchFiltersPopup
                      className="filterResultResources"
                      resourceSearchFiltersFormProps={searchFilterFormProps}
                    />
                  ) : (
                    <Cirrus.ResourceSearchFilterPopup
                      className="filterResultResources"
                      resourceSearchFilterFormProps={
                        searchPropsForToolsResources
                      }
                    />
                  ))}
              </div>

              <div className="responsive mb-3">
                <Cirrus.Select
                  placeholder="All Areas"
                  options={[
                    {
                      name: getLabel(
                        "LearningResourceTypes.AssessmentsandTasks"
                      ),
                      value: "assessments",
                    },
                    {
                      name: getLabel("LearningResourceTypes.Tools"),
                      value: "tools",
                    },
                    {
                      name: getLabel("LearningResourceTypes.Resources"),
                      value: "resources",
                    },
                  ]}
                  onChange={(e) => {
                    // fetchAssessmentData(e.target.value)
                    // if (e.target.value === 'tools' || e.target.value === 'resources') {
                    //   setFilterStatus(false)
                    // } else {
                    //   setFilterStatus(true)
                    // }

                    if (e.target.value === "assessments") {
                      setAssessmentSecondary(false);
                      assessmentData &&
                        assessmentData.length > 0 &&
                        setAssessmentCardData(assessmentData && assessmentData);
                      assessmentData && assessmentData.length <= 0
                        ? fetchAssessmentData(
                            "assessments",
                            lcCMS?.subjectCategory
                          )
                        : fetchSortedData(assessmentData && assessmentData);
                      setToolsSecondary(true);
                      setFilterStatus(true);
                      setResourcesSecondary(true);
                    } else if (e.target.value === "tools") {
                      setToolsSecondary(false);
                      toolsData &&
                        toolsData.length > 0 &&
                        setAssessmentCardData(toolsData && toolsData);
                      context.languageChanged ||
                      (toolsData && toolsData.length <= 0)
                        ? fetchAssessmentData("tools", lcCMS?.subjectCategory)
                        : fetchSortedData(toolsData && toolsData);
                      setAssessmentSecondary(true);
                      setFilterStatus(false);
                      setResourcesSecondary(true);
                    } else {
                      setResourcesSecondary(false);
                      resourcesData &&
                        resourcesData.length > 0 &&
                        setAssessmentCardData(resourcesData && resourcesData);
                      context.languageChanged ||
                      (resourcesData && resourcesData.length <= 0)
                        ? fetchAssessmentData(
                            "resources",
                            lcCMS?.subjectCategory
                          )
                        : fetchSortedData(resourcesData && resourcesData);
                      setToolsSecondary(true);
                      setFilterStatus(false);
                      setAssessmentSecondary(true);
                    }
                  }}
                />
              </div>

              {isMobile && (
                <div className="responsive_search mb-4">
                  <Cirrus.Button
                    children={"Search & Filters"}
                    onClick={() => {
                      setResFilterd(!resFilterd);
                      setMobileFilter(true);
                    }}
                  />
                </div>
              )}

              <div className="assessment-card-block">
                {chunkedData && chunkedData.length >= 1 ? (
                  chunkedData.map((item, idx) => (
                    <div
                      className={
                        chunkedData && chunkedData.length == 1
                          ? "assessment-card1"
                          : "assessment-card"
                      }
                    >
                      <Cirrus.Card
                        title={item && item.title && item.title}
                        description={
                          item && item.shortDescription && item.shortDescription
                        }
                        onCardClick={() => {
                          cardRedirectHandler(item);
                        }}
                        tags={item?.focusArea?.map((item) => item.value)}
                        image={{
                          src:
                            item &&
                            item.largeImage &&
                            item.largeImage.imageUrl &&
                            item.largeImage.imageUrl,
                        }}
                        saveButton={{
                          label: "Save",
                          secondaryLabel: "Saved",
                          onClick: (e) => {
                            saveAssessmentCard(
                              e,
                              item && item.resourceId && item.resourceId,
                              item && item.isSaved && item.isSaved,
                              item.isLiked && item.isLiked,
                              "save",
                              item &&
                                item.learningResourceStatus &&
                                item.learningResourceStatus,
                              item.lastAnsweredPageId &&
                                item.lastAnsweredPageId,
                              item?.resourceType?.id,
                              item?.resourceCategoryType?.id
                            );
                          },
                        }}
                        isSaved={item && item.isSaved && item.isSaved}
                        status={{
                          name: "new",
                          label:
                            item && item.resourceType && item.resourceType.value
                              ? item.resourceType.value
                              : "",
                          value:
                            item &&
                            item.learningResourceStatus === "notstarted" &&
                            item &&
                            item.resourceType &&
                            item.resourceType.value !== "Tool"
                              ? item && item.duration && item.duration
                              : item &&
                                item.learningResourceStatus === "notstarted" &&
                                item &&
                                item.resourceType &&
                                item.resourceType.value === "Tool"
                              ? "Interactive"
                              : item &&
                                item.resourceType &&
                                item.resourceCategoryType.value === "Resources"
                              ? item?.duration
                              : fetchLearningStatus(
                                  item && item.learningResourceStatus
                                ),
                        }}
                        likeText={
                          item?.likeCount == 0
                            ? fetchLikeLabel("CommonComponent.CommonLikeText")
                            : item?.likeCount == 1
                            ? item?.likeCount +
                              " " +
                              fetchLikeLabel("CommonComponent.CommonLikeText")
                            : item?.likeCount > 1
                            ? item?.likeCount +
                              " " +
                              fetchLikeLabel("CommonComponent.CommonLikesText")
                            : ""
                        }
                        onLikeButtonClick={(e) => {
                          saveAssessmentCard(
                            e,
                            item && item.resourceId && item.resourceId,
                            item.isSaved && item.isSaved,
                            item && item.isLiked && item.isLiked,
                            "like",
                            item &&
                              item.learningResourceStatus &&
                              item.learningResourceStatus,
                            item.lastAnsweredPageId && item.lastAnsweredPageId,
                            item?.resourceType?.id,
                            item?.resourceCategoryType?.id
                          );
                          saveActivity2(item);
                        }}
                        isLiked={item && item.isLiked && item.isLiked}
                        type="assessment"
                        layout="horizontal"
                      />
                    </div>
                  ))
                ) : (
                  <Cirrus.Label>
                    {fetchCommonResourceLabel(
                      "CommonResourceComponent.LearningResourcesNotFound"
                    )}
                  </Cirrus.Label>
                )}
              </div>
            </Row>
            {displayLoadMore(showLoadMore)}
          </Container>
        </Cirrus.OneColumnLayout>
      </Cirrus.Page>

      {mobileFilter && (
        <Cirrus.Modal
          size="mobile"
          className="responsive-filter-modal"
          closeButtonProps={{
            type: "button",
            isSecondary: false,
            size: "small",
            children: "X  Close",
            onClick: () => {
              setMobileFilter(false);
              setResFilterd(!resFilterd);
            },
          }}
        >
          <div className="responsive_filter-model">
            <div className="responsive_search-filters mb-4 m-3">
              <Cirrus.Input
                icon="search"
                id="search"
                onChange={(event) => searchCard(event.target.value)}
                placeholder={fetchLCFiltersLabels(
                  "DiscoveryPage.AllLearningResourcesFilter.KeywordSearchText"
                )}
              />
            </div>
            <div className="m-3">
              {filterStatus ? (
                <Cirrus.ResourceSearchFiltersPopup
                  className="responsive_filterResultResources"
                  resourceSearchFiltersFormProps={searchFilterFormProps}
                />
              ) : (
                <Cirrus.ResourceSearchFilterPopup
                  className="responsive_filterResultResources"
                  resourceSearchFilterFormProps={searchPropsForToolsResources}
                />
              )}
            </div>
          </div>
        </Cirrus.Modal>
      )}
      {/* <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}

export default SubjectCategories;
