//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from '../../header/headerWithNavigation';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import TPpdfTemplate from './pdfTemplate';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../../common/utility/LogAction';
import { saveAs } from 'file-saver';

const Questions = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState([]);
    const [futureQuestions, setFutureQuestions] = useState([])
    const [seeYourselfQuestions, setSeeYourselfQuestions] = useState([])
    const [othersSeeQuestions, setOthersSeeQuestions] = useState([])
    const [importantQuestions, setImportantQuestions] = useState([])
    const [futureQuestionsSet2, setFutureQuestionsSet2] = useState([])
    const [seeYourselfQuestionsSet2, setSeeYourselfQuestionsSet2] = useState([])
    const [othersSeeQuestionsSet2, setOthersSeeQuestionsSet2] = useState([])
    const [importantQuestionsSet2, setImportantQuestionsSet2] = useState([])
    const [answersList, setAnswersList] = useState();
    const [learningStatus, setLearningStatus] = useState()
    const [assessmentStartPageData, setAssessmentStartPageData] = useState()
    const [questionSet, setQuestionSet] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [questionsSet, setQuestionsSet] = useState("Assessments.FuturePlansAndNeedsQuestions")
    const [assessmentId, setAssessmentId] = useState(0)
    const [pdfResponse, setPdfResponse] = useState([])
    const appInsights = useAppInsightsContext()

    const userAgent = window.navigator.userAgent;
    let browser = '';

    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    const fetchCMSData = () => {
        let refCmsAssessmentContent = context.cmsThoughtQuestionsContent;
        setCmsAssessmentContent(refCmsAssessmentContent)
        fetchQuestionList(refCmsAssessmentContent)
        fetchAnswers(refCmsAssessmentContent?.internalAssessmentId)
        setAssessmentId(refCmsAssessmentContent?.internalAssessmentId)
    }

    const fetchLearningStatus = (id) => {
        let refCmsAssessmentContent = cmsAssessmentContent && cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.pageContent?.LOVList[0].Values) {
            refCmsAssessmentContent?.pageContent?.LOVList[0].Values.map((x) => {
                if (x.ID === id) {
                    label = x.ID;
                }
            });
        }
        return label;
    }

    const fetchAnswers = (id) => {

        AssessmentActions.GetSavedResponseByAssessmentId(id).then(res => {
            if (res.status === 200) {
                let resData = res.data;
                let refAnswerList = resData && resData
                setAnswersList(refAnswerList && refAnswerList)
                setPercentage(refAnswerList && refAnswerList.length * 5)
                context.setTPProgress(refAnswerList && refAnswerList.length * 5)
            }
        }, (err) => {
            console.log(err);
        })
    }

    const fetchStartPageLabels = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.pageContent?.Labels) {
            refCmsAssessmentContent?.pageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchQuestionsLabels = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent;
        let label = "";
        if (refCmsAssessmentContent?.questionPageContent?.Labels) {
            refCmsAssessmentContent?.questionPageContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if ((n < assessmentQuestion.length && n > 0) || n === 0) {
            assessmentQuestion[n].style.display = "flex";
        }
        if (n == assessmentQuestion.length) {
            setTimeout(() => {
                fetchAnswers(assessmentId && assessmentId)
                setTimeout(() => {
                    generatePDF()
                }, 1000);
            }, 1000);
        }
        if (n < 0) {
            navigate("/" + pageOrgin.id + "/thought-provoking-questions")
        }
    }

    const nextPrev = (e, n) => {
        if (n === 1) {
            setScreenLoader(true)
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            let nextQuestion = currentQuestion + n
            setCurrentQuestion(nextQuestion);
            showQuestion(nextQuestion);
            updateStatus(cmsAssessmentContent?.resourceType?.ID, cmsAssessmentContent.internalAssessmentId, currentQuestion + n, assessmentQuestion, n)
            setScreenLoader(false)
            window.scrollTo(0, 0)
        } else if (n === -1) {
            setScreenLoader(true)
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            let prevQuestion = currentQuestion + n
            setCurrentQuestion(prevQuestion);
            showQuestion(prevQuestion);
            setScreenLoader(false)
            window.scrollTo(0, 0)
        }
    }

    const fetchStartPageData = () => {
        let resourceType = "assessmentsandtasks";

        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }

        AssessmentActions.GetStartPageData(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res?.data && res?.data?.responsemodel;
                    let refData = resData?.filter(x => x.key === "Assessments.ThoughtProvoking")
                    setAssessmentStartPageData(refData && refData[0] && refData[0]);
                    let TPData = refData && refData[0] && refData[0];
                    setLearningStatus(TPData?.learningResourceStatus)
                    let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                    if (TPData && TPData.lastAnsweredPageId) {
                        if (parseInt(TPData.lastAnsweredPageId) < assessmentQuestion.length) {
                            let pageNumber = parseInt(TPData.lastAnsweredPageId);
                            setCurrentQuestion(pageNumber);
                            showQuestion(pageNumber)

                        }
                    }
                    setScreenLoader(false);
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    }

    const updateStatus = (resourceType, id, pageNumber, assessmentQuestion, n) => {

        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": id,
            "learningResourceType": cmsAssessmentContent?.resourceCategoryType?.ID,
            "resourceTypeId": cmsAssessmentContent?.resourceType?.ID,
            "learningResourceStatus": (context.TPProgress === 95 || context.TPProgress === 100) ? fetchLearningStatus("completed") : fetchLearningStatus(learningStatus && learningStatus),
            "isSaved": assessmentStartPageData?.isSaved,
            "isLiked": assessmentStartPageData?.isLiked,
            "lastAnsweredPageId": pageNumber,
            "isDownloaded": assessmentStartPageData?.isDownloaded,
            "isSharedWithCoach": assessmentStartPageData?.isSharedWithCoach,
            "resultDocumentId": assessmentStartPageData?.resultDocumentId
        }

        AssessmentActions.SaveAssessmentStatus(resourceType, id, payload).then(
            (res) => {
                if (res.status === 204) {
                    let resData = res.data;

                    let percentageCalc = Math.ceil(((pageNumber) / assessmentQuestion.length) * 100);

                    if (percentageCalc >= percentage) {
                        setPercentage(percentageCalc);
                        context.setTPProgress(percentageCalc)
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    const saveAnswer = (e, id, questionid, pageNumber, CategoryValue, CategoryType) => {

        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        const answer = document.getElementById(id).value

        let payload = [{
            "AssessementId": cmsAssessmentContent.internalAssessmentId,
            "AssessementType": "Internal",
            "CategoryType": CategoryType,
            "CategoryValue": CategoryValue,
            "QuestionId": questionid,
            "AnswerType": "text",
            "AnswerValue": answer,
        }]

        AssessmentActions.SaveAssessment(payload).then(res => {
            if (res.status === 200) {
                fetchAnswers(assessmentId && assessmentId)
                // let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                // if ((parseInt(pageNumber) + 1) == assessmentQuestion.length) {
                //     generatePDF()
                // }
            } else {
                console.log("error");
            }
        }, err => {
            console.log(err);
        })
    }

    const fetchPageNumber = async () => {
        let resourceType = "assessmentsandtasks";

        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }

        await AssessmentActions.GetStartPageData(payload).then(res => {
            if (res.status === 200) {
                if (context.edit === true) {
                    let resData = res?.data && res?.data?.responsemodel;
                    let refData = resData?.filter(x => x.key === "Assessments.ThoughtProvoking")
                    let TPData = refData && refData[0] && refData[0];
                    setLearningStatus(TPData?.learningResourceStatus)
                    let questionList = [...futureQuestions, ...futureQuestionsSet2,
                    ...seeYourselfQuestions, ...seeYourselfQuestionsSet2,
                    ...othersSeeQuestions, ...othersSeeQuestionsSet2,
                    ...importantQuestions, ...importantQuestionsSet2]

                    let questionArr = questionList && questionList.map((x => x.QuestionId))
                    let question = []
                    let j = questionArr && questionArr.length
                    for (let i = 0; i < j; i++) {
                        const b = {
                            id: questionArr && questionArr[i],
                            idx: i,
                        };
                        question.push(b);
                    }

                    let answer = answersList && answersList;
                    let answerArr = answer && answer.map((x => x.questionId))
                    let nonAnswered = question.filter((page1) => !answerArr.find(page2 => page1.id === page2))

                    const unArr = nonAnswered && nonAnswered.map((x => x.idx))
                    let minPage = Math.min(...unArr)

                    let unAnsweredPage = 0;
                    if ([1, 2, 3].includes(minPage)) {
                        unAnsweredPage = 0
                    }
                    else if ([4, 5].includes(minPage)) {
                        unAnsweredPage = 1
                    }
                    else if ([6, 7, 8].includes(minPage)) {
                        unAnsweredPage = 2
                    }
                    else if ([9, 10].includes(minPage)) {
                        unAnsweredPage = 3
                    }
                    else if ([11, 12, 13].includes(minPage)) {
                        unAnsweredPage = 4
                    }
                    else if ([14, 15].includes(minPage)) {
                        unAnsweredPage = 5
                    }
                    else if ([16, 17, 18].includes(minPage)) {
                        unAnsweredPage = 6
                    }
                    else if ([19, 20].includes(minPage)) {
                        unAnsweredPage = 7
                    }
                    let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                    assessmentQuestion[unAnsweredPage].style.display = "flex";
                }
            }
        }, err => {
            console.log(err);
        })
    }


    useEffect(() => {
        fetchCMSData()
        fetchStartPageData()
        window.scrollTo(0, 0)
        TrackActions.PageViewRecord(appInsights, "ThoughtQuestions")
        TrackActions.PageAction("ThoughtQuestions")
    }, [questionsSet])

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language, questionsSet])

    useEffect(() => {
        setTimeout(() => {
            if (cmsAssessmentContent && cmsAssessmentContent.questionsList && answersList) {
                fetchPageNumber()
            }
        }, "2000")
    }, [cmsAssessmentContent.questionsList, answersList])

    const fetchQuestionList = (cmsAssessmentContent) => {



        if (cmsAssessmentContent && cmsAssessmentContent.questionsList) {

            let questionsSet1 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.FuturePlansAndNeedsQuestionsSet1']))
            let futureQuestionsList = questionsSet1 && questionsSet1[0] && questionsSet1[0].QuestionList && questionsSet1[0].QuestionList
            setFutureQuestions(futureQuestionsList)

            let questionsSet5 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.FuturePlansAndNeedsQuestionsSet2']))
            let futureQuestionsList2 = questionsSet5 && questionsSet5[1] && questionsSet5[1].QuestionList && questionsSet5[1].QuestionList
            setFutureQuestionsSet2(futureQuestionsList2)

            let questionsSet2 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.HowYouSeeYourselfQuestionsSet1']))
            let seeYourselfQuestionList = questionsSet2 && questionsSet2[2] && questionsSet2[2].QuestionList && questionsSet2[2].QuestionList
            setSeeYourselfQuestions(seeYourselfQuestionList)

            let questionsSet6 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.HowYouSeeYourselfQuestionsSet2']))
            let seeYourselfQuestionList2 = questionsSet6 && questionsSet6[3] && questionsSet6[3].QuestionList && questionsSet6[3].QuestionList
            setSeeYourselfQuestionsSet2(seeYourselfQuestionList2)

            let questionsSet3 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.HowOthersSeeYouQuestionsSet1']))
            let othersSeeQuestionsList = questionsSet3 && questionsSet3[4] && questionsSet3[4].QuestionList && questionsSet3[4].QuestionList
            setOthersSeeQuestions(othersSeeQuestionsList)

            let questionsSet7 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.HowOthersSeeYouQuestionsSet2']))
            let othersSeeQuestionsList2 = questionsSet7 && questionsSet7[5] && questionsSet7[5].QuestionList && questionsSet7[5].QuestionList
            setOthersSeeQuestionsSet2(othersSeeQuestionsList2)

            let questionsSet4 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.WhatIsImportantToYouQuestionsSet1']))
            let importantQuestionsList = questionsSet4 && questionsSet4[6] && questionsSet4[6].QuestionList && questionsSet4[6].QuestionList
            setImportantQuestions(importantQuestionsList)

            let questionsSet8 = cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent && cmsAssessmentContent.questionsList.map((x => x.Components['Assessments.WhatIsImportantToYouQuestionsSet2']))
            let importantQuestionsList2 = questionsSet8 && questionsSet8[7] && questionsSet8[7].QuestionList && questionsSet8[7].QuestionList
            setImportantQuestionsSet2(importantQuestionsList2)
            let questionsSet = {
                futureQuestionsList, futureQuestionsList2,
                seeYourselfQuestionList, seeYourselfQuestionList2,
                othersSeeQuestionsList, othersSeeQuestionsList2,
                importantQuestionsList, importantQuestionsList2
            }
            questions(questionsSet)
            setQuestionSet(questionSet && questionSet)

        }
    }

    const fetchAnswersList = (questionId) => {

        let answer = '';
        let answerSet = answersList?.filter((answer => answer.questionId === questionId))
        answer = answerSet && answerSet[0] && answerSet[0].answerValue && answerSet[0].answerValue;
        return answer;
    }

    const questions = (questionsSet) => {

        let refQuestionCollection = {}

        refQuestionCollection.futureQuestionsSet = [...questionsSet?.futureQuestionsList, ...questionsSet?.futureQuestionsList2]
        refQuestionCollection.seeYourselfQuestionsSet = [...questionsSet?.seeYourselfQuestionList, ...questionsSet?.seeYourselfQuestionList2]
        refQuestionCollection.othersSeeQuestionsSet = [...questionsSet?.othersSeeQuestionsList, ...questionsSet?.othersSeeQuestionsList2]
        refQuestionCollection.importantQuestionsSet = [...questionsSet?.importantQuestionsList, ...questionsSet?.importantQuestionsList2]
        context.setQuestionCollection(refQuestionCollection)

        setPdfResponse(refQuestionCollection)
    }



    const updateAssessmentStatus = (docId) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": assessmentStartPageData.learningResourceId,
            "learningResourceStatus": fetchLearningStatus("completed"),
            "learningResourceType": cmsAssessmentContent?.resourceCategoryType?.ID,
            "resourceTypeId": cmsAssessmentContent?.resourceType?.ID,
            "isSaved": assessmentStartPageData.isSaved,
            "isLiked": assessmentStartPageData.isLiked,
            "lastAnsweredPageId": assessmentStartPageData.lastAnsweredPageId,
            "isDownloaded": true,
            "isSharedWithCoach": assessmentStartPageData?.isSharedWithCoach,
            "resultDocumentId": docId
        }
        AssessmentActions.SaveAssessmentStatus(cmsAssessmentContent.resourceCategoryType?.ID, assessmentStartPageData.learningResourceId, payload).then((res) => {
            if (res.status === 200) {
                navigate("/" + pageOrgin.id + "/thought-provoking-questions/results")
            }
        }, err => {
            console.log(err);
        })
    }

    const generatePDF = () => {
        let content = document.getElementById('assessment-result-wrapper');

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            setScreenLoader(true);
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;
            const pdf = new jsPDF({ orientation: 'l', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', -50, yPosition + 50, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                let file = new File([pdf.output('blob')], cmsAssessmentContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");
                // setScreenLoader(false);
                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    let docId;
                    res.data.map(doc => {
                        docId = doc.id
                    })
                    updateAssessmentStatus(docId)
                    setScreenLoader(false);
                }, err => {
                    console.log(err);
                    setScreenLoader(false);
                });
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();

                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }
                        let file = new File([pdf.output('blob')], cmsAssessmentContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");
                        // setScreenLoader(false);
                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            let docId;
                            res.data.map(doc => {
                                docId = doc.id
                            })
                            updateAssessmentStatus(docId)
                            setScreenLoader(false);
                        }, err => {
                            console.log(err);
                            setScreenLoader(false);
                        });
                    }
                })
            }
        })
    }


    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page">
                <Container className='pt-4 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button
                                className='back-btn'
                                isSecondary
                                onClick={(e) => {
                                    nextPrev(e, -1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonBackButton")}
                            </Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchQuestionsLabels("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            <Cirrus.Button
                                onClick={(e) => {
                                    navigate("/" + pageOrgin.id)
                                }}
                            >
                                {fetchQuestionsLabels("CommonResourceComponent.Exit")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-1">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {futureQuestions && futureQuestions[0] && futureQuestions[0].QuestionTitle && futureQuestions[0].QuestionTitle}
                                            </Cirrus.Label>
                                        </div>
                                        <textarea
                                            id='answer1'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            readOnly={false}
                                            defaultValue={fetchAnswersList(futureQuestions && futureQuestions[0] && futureQuestions[0].QuestionId && futureQuestions[0].QuestionId) && fetchAnswersList(futureQuestions && futureQuestions[0] && futureQuestions[0].QuestionId && futureQuestions[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer1',
                                                futureQuestions && futureQuestions[0] && futureQuestions[0].QuestionId && futureQuestions[0].QuestionId,
                                                "0",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {futureQuestions && futureQuestions[1] && futureQuestions[1].QuestionTitle && futureQuestions[1].QuestionTitle}
                                            </Cirrus.Label>
                                        </div>
                                        <textarea
                                            id='answer2'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(futureQuestions && futureQuestions[1] && futureQuestions[1].QuestionId && futureQuestions[1].QuestionId) && fetchAnswersList(futureQuestions && futureQuestions[1] && futureQuestions[1].QuestionId && futureQuestions[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer2',
                                                futureQuestions && futureQuestions[1] && futureQuestions[1].QuestionId && futureQuestions[1].QuestionId,
                                                "0",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {futureQuestions && futureQuestions[2] && futureQuestions[2].QuestionTitle && futureQuestions[2].QuestionTitle}
                                            </Cirrus.Label>
                                        </div>
                                        <textarea
                                            id='answer3'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(futureQuestions && futureQuestions[2] && futureQuestions[2].QuestionId && futureQuestions[2].QuestionId) && fetchAnswersList(futureQuestions && futureQuestions[2] && futureQuestions[2].QuestionId && futureQuestions[2].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer3',
                                                futureQuestions && futureQuestions[2] && futureQuestions[2].QuestionId && futureQuestions[2].QuestionId,
                                                "0",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-2">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[1] && cmsAssessmentContent.questionsList[1] && cmsAssessmentContent.questionsList[1].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {futureQuestionsSet2 && futureQuestionsSet2[0] && futureQuestionsSet2[0].QuestionTitle && futureQuestionsSet2[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer4'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(futureQuestionsSet2 && futureQuestionsSet2[0] && futureQuestionsSet2[0].QuestionId && futureQuestionsSet2[0].QuestionId) && fetchAnswersList(futureQuestionsSet2 && futureQuestionsSet2[0] && futureQuestionsSet2[0].QuestionId && futureQuestionsSet2[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer4',
                                                futureQuestionsSet2 && futureQuestionsSet2[0] && futureQuestionsSet2[0].QuestionId && futureQuestionsSet2[0].QuestionId,
                                                "1",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {futureQuestionsSet2 && futureQuestionsSet2[1] && futureQuestionsSet2[1].QuestionTitle && futureQuestionsSet2[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer5'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(futureQuestionsSet2 && futureQuestionsSet2[1] && futureQuestionsSet2[1].QuestionId && futureQuestionsSet2[1].QuestionId) && fetchAnswersList(futureQuestionsSet2 && futureQuestionsSet2[1] && futureQuestionsSet2[1].QuestionId && futureQuestionsSet2[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer5',
                                                futureQuestionsSet2 && futureQuestionsSet2[1] && futureQuestionsSet2[1].QuestionId && futureQuestionsSet2[1].QuestionId,
                                                "1",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0] && cmsAssessmentContent.questionsList[0].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='text-md-center'>
                                <Cirrus.Button
                                    className='continue-btn'
                                    onClick={(e) => {
                                        nextPrev(e, 1)
                                    }}
                                >
                                    {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                                </Cirrus.Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-3">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {seeYourselfQuestions && seeYourselfQuestions[0] && seeYourselfQuestions[0].QuestionTitle && seeYourselfQuestions[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer6'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[0] && seeYourselfQuestions[0].QuestionId && seeYourselfQuestions[0].QuestionId) && fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[0] && seeYourselfQuestions[0].QuestionId && seeYourselfQuestions[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer6',
                                                seeYourselfQuestions && seeYourselfQuestions[0] && seeYourselfQuestions[0].QuestionId && seeYourselfQuestions[0].QuestionId,
                                                "2",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {seeYourselfQuestions && seeYourselfQuestions[1] && seeYourselfQuestions[1].QuestionTitle && seeYourselfQuestions[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer7'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[1] && seeYourselfQuestions[1].QuestionId && seeYourselfQuestions[1].QuestionId) && fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[1] && seeYourselfQuestions[1].QuestionId && seeYourselfQuestions[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer7',
                                                seeYourselfQuestions && seeYourselfQuestions[1] && seeYourselfQuestions[1].QuestionId && seeYourselfQuestions[1].QuestionId,
                                                "2",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {seeYourselfQuestions && seeYourselfQuestions[2] && seeYourselfQuestions[2].QuestionTitle && seeYourselfQuestions[2].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer8'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[2] && seeYourselfQuestions[2].QuestionId && seeYourselfQuestions[2].QuestionId) && fetchAnswersList(seeYourselfQuestions && seeYourselfQuestions[2] && seeYourselfQuestions[2].QuestionId && seeYourselfQuestions[2].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer8',
                                                seeYourselfQuestions && seeYourselfQuestions[2] && seeYourselfQuestions[2].QuestionId && seeYourselfQuestions[2].QuestionId,
                                                "2",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-4">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[3] && cmsAssessmentContent.questionsList[3] && cmsAssessmentContent.questionsList[3].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[0] && seeYourselfQuestionsSet2[0].QuestionTitle && seeYourselfQuestionsSet2[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer9'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[0] && seeYourselfQuestionsSet2[0].QuestionId && seeYourselfQuestionsSet2[0].QuestionId) && fetchAnswersList(seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[0] && seeYourselfQuestionsSet2[0].QuestionId && seeYourselfQuestionsSet2[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer9',
                                                seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[0] && seeYourselfQuestionsSet2[0].QuestionId && seeYourselfQuestionsSet2[0].QuestionId,
                                                "3",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[1] && seeYourselfQuestionsSet2[1].QuestionTitle && seeYourselfQuestionsSet2[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer10'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[1] && seeYourselfQuestionsSet2[1].QuestionId && seeYourselfQuestionsSet2[1].QuestionId) && fetchAnswersList(seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[1] && seeYourselfQuestionsSet2[1].QuestionId && seeYourselfQuestionsSet2[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer10',
                                                seeYourselfQuestionsSet2 && seeYourselfQuestionsSet2[1] && seeYourselfQuestionsSet2[1].QuestionId && seeYourselfQuestionsSet2[1].QuestionId,
                                                "3",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2] && cmsAssessmentContent.questionsList[2].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-5">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {othersSeeQuestions && othersSeeQuestions[0] && othersSeeQuestions[0].QuestionTitle && othersSeeQuestions[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer11'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(othersSeeQuestions && othersSeeQuestions[0] && othersSeeQuestions[0].QuestionId && othersSeeQuestions[0].QuestionId) && fetchAnswersList(othersSeeQuestions && othersSeeQuestions[0] && othersSeeQuestions[0].QuestionId && othersSeeQuestions[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer11',
                                                othersSeeQuestions && othersSeeQuestions[0] && othersSeeQuestions[0].QuestionId && othersSeeQuestions[0].QuestionId,
                                                "4",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {othersSeeQuestions && othersSeeQuestions[1] && othersSeeQuestions[1].QuestionTitle && othersSeeQuestions[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer12'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(othersSeeQuestions && othersSeeQuestions[1] && othersSeeQuestions[1].QuestionId && othersSeeQuestions[1].QuestionId) && fetchAnswersList(othersSeeQuestions && othersSeeQuestions[1] && othersSeeQuestions[1].QuestionId && othersSeeQuestions[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer12',
                                                othersSeeQuestions && othersSeeQuestions[1] && othersSeeQuestions[1].QuestionId && othersSeeQuestions[1].QuestionId,
                                                "4",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {othersSeeQuestions && othersSeeQuestions[2] && othersSeeQuestions[2].QuestionTitle && othersSeeQuestions[2].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer13'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(othersSeeQuestions && othersSeeQuestions[2] && othersSeeQuestions[2].QuestionId && othersSeeQuestions[2].QuestionId) && fetchAnswersList(othersSeeQuestions && othersSeeQuestions[2] && othersSeeQuestions[2].QuestionId && othersSeeQuestions[2].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer13',
                                                othersSeeQuestions && othersSeeQuestions[2] && othersSeeQuestions[2].QuestionId && othersSeeQuestions[2].QuestionId,
                                                "4",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-6">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[5] && cmsAssessmentContent.questionsList[5] && cmsAssessmentContent.questionsList[5].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {othersSeeQuestionsSet2 && othersSeeQuestionsSet2[0] && othersSeeQuestionsSet2[0].QuestionTitle && othersSeeQuestionsSet2[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer14'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(othersSeeQuestionsSet2 && othersSeeQuestionsSet2[0] && othersSeeQuestionsSet2[0].QuestionId && othersSeeQuestionsSet2[0].QuestionId) && fetchAnswersList(othersSeeQuestionsSet2 && othersSeeQuestionsSet2[0] && othersSeeQuestionsSet2[0].QuestionId && othersSeeQuestionsSet2[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer14',
                                                othersSeeQuestionsSet2 && othersSeeQuestionsSet2[0] && othersSeeQuestionsSet2[0].QuestionId && othersSeeQuestionsSet2[0].QuestionId,
                                                "5",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {othersSeeQuestionsSet2 && othersSeeQuestionsSet2[1] && othersSeeQuestionsSet2[1].QuestionTitle && othersSeeQuestionsSet2[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer15'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(othersSeeQuestionsSet2 && othersSeeQuestionsSet2[1] && othersSeeQuestionsSet2[1].QuestionId && othersSeeQuestionsSet2[1].QuestionId) && fetchAnswersList(othersSeeQuestionsSet2 && othersSeeQuestionsSet2[1] && othersSeeQuestionsSet2[1].QuestionId && othersSeeQuestionsSet2[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer15',
                                                othersSeeQuestionsSet2 && othersSeeQuestionsSet2[1] && othersSeeQuestionsSet2[1].QuestionId && othersSeeQuestionsSet2[1].QuestionId,
                                                "5",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4] && cmsAssessmentContent.questionsList[4].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-7">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {importantQuestions && importantQuestions[0] && importantQuestions[0].QuestionTitle && importantQuestions[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer16'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(importantQuestions && importantQuestions[0] && importantQuestions[0].QuestionId && importantQuestions[0].QuestionId) && fetchAnswersList(importantQuestions && importantQuestions[0] && importantQuestions[0].QuestionId && importantQuestions[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer16',
                                                importantQuestions && importantQuestions[0] && importantQuestions[0].QuestionId && importantQuestions[0].QuestionId,
                                                "6",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {importantQuestions && importantQuestions[1] && importantQuestions[1].QuestionTitle && importantQuestions[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer17'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(importantQuestions && importantQuestions[1] && importantQuestions[1].QuestionId && importantQuestions[1].QuestionId) && fetchAnswersList(importantQuestions && importantQuestions[1] && importantQuestions[1].QuestionId && importantQuestions[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer17',
                                                importantQuestions && importantQuestions[1] && importantQuestions[1].QuestionId && importantQuestions[1].QuestionId,
                                                "6",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {importantQuestions && importantQuestions[2] && importantQuestions[2].QuestionTitle && importantQuestions[2].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer18'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(importantQuestions && importantQuestions[2] && importantQuestions[2].QuestionId && importantQuestions[2].QuestionId) && fetchAnswersList(importantQuestions && importantQuestions[2] && importantQuestions[2].QuestionId && importantQuestions[2].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer18',
                                                importantQuestions && importantQuestions[2] && importantQuestions[2].QuestionId && importantQuestions[2].QuestionId,
                                                "6",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonContinueButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-question-wrapper mb-5'>
                    <Row>
                        <Col lg={12} className="assessment-question section-8">
                            <Cirrus.Headline size='Small' className='mb-4 question-label'>
                                {cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[7] && cmsAssessmentContent.questionsList[7] && cmsAssessmentContent.questionsList[7].Title}
                            </Cirrus.Headline>
                            <div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {importantQuestionsSet2 && importantQuestionsSet2[0] && importantQuestionsSet2[0].QuestionTitle && importantQuestionsSet2[0].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer19'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(importantQuestionsSet2 && importantQuestionsSet2[0] && importantQuestionsSet2[0].QuestionId && importantQuestionsSet2[0].QuestionId) && fetchAnswersList(importantQuestionsSet2 && importantQuestionsSet2[0] && importantQuestionsSet2[0].QuestionId && importantQuestionsSet2[0].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer19',
                                                importantQuestionsSet2 && importantQuestionsSet2[0] && importantQuestionsSet2[0].QuestionId && importantQuestionsSet2[0].QuestionId,
                                                "7",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].ID
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-3 d-block">
                                        <div className="question">
                                            <Cirrus.Label>
                                                {importantQuestionsSet2 && importantQuestionsSet2[1] && importantQuestionsSet2[1].QuestionTitle && importantQuestionsSet2[1].QuestionTitle}
                                            </Cirrus.Label></div>
                                        <textarea
                                            id='answer20'
                                            placeholder={fetchQuestionsLabels("CommonResourceComponent.EnterHere")}
                                            maxLength={256}
                                            cols="120"
                                            rows="10"
                                            defaultValue={fetchAnswersList(importantQuestionsSet2 && importantQuestionsSet2[1] && importantQuestionsSet2[1].QuestionId && importantQuestionsSet2[1].QuestionId) && fetchAnswersList(importantQuestionsSet2 && importantQuestionsSet2[1] && importantQuestionsSet2[1].QuestionId && importantQuestionsSet2[1].QuestionId)}
                                            onBlur={(e) => saveAnswer(
                                                e,
                                                'answer20',
                                                importantQuestionsSet2 && importantQuestionsSet2[1] && importantQuestionsSet2[1].QuestionId && importantQuestionsSet2[1].QuestionId,
                                                "7",
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].Title,
                                                cmsAssessmentContent && cmsAssessmentContent.questionsList && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6] && cmsAssessmentContent.questionsList[6].ID
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Cirrus.Button
                                className='continue-btn'
                                onClick={(e) => {
                                    nextPrev(e, 1)
                                }}
                            >
                                {fetchStartPageLabels("CommonComponent.CommonCompleteButton")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
            <div style={{ opacity: "0" }}>
                <div id='assessment-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1', display: 'block' }}>
                    {
                        pdfResponse && answersList &&
                        <TPpdfTemplate questionsSet={pdfResponse} answersList={answersList} cmsAssessmentContent={cmsAssessmentContent} cmsTitle={context.cmsThoughtQuestionsContent}></TPpdfTemplate>
                    }
                </div>
            </div>
        </>
    )
}

export default Questions;
