//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const MyDocumentsActions = {
    GetCMScontent: getCMScontent,
    GetAllDocuments:getAllDocuments,
    GetDocument:getDocument,
    UploadDocuments:uploadDocuments,
    DownloadDocument:downloadDocument,
    DeleteDocument:deleteDocument,
    UpdateDocument:updateDocument
}

function getCMScontent(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function getAllDocuments(payload) {
    return http.documentsPost(environments.GET_ALL_DOCUMENTS, payload);
}

function getDocument(id) {
    let savePreferences = environments.GET_DOCUMENT.replace('{id}', id);
    return http.documentsGet(savePreferences);
}

function uploadDocuments(payload) {
    return http.documentsPost(environments.UPLOAD_DOCUMENTS, payload);
}

function downloadDocument(id) {
    let savePreferences = environments.DOWNLOAD_DOCUMENT.replace('{id}', id);
    return http.documentsDownloadGet(savePreferences);
}

function deleteDocument(id) {
    let savePreferences = environments.DELETE_DOCUMENT.replace('{id}', id);
    return http.documentsDelete(savePreferences);
}

function updateDocument(id, payload) {
    let savePreferences = environments.UPDATE_DOCUMENT.replace('{id}', id);
    return http.documentsPut(savePreferences, payload);
}
