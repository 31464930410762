//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';

const CareerFactors = () => {
    const context = useContext(Context)
    const navigate = useNavigate()
    const pageOrgin = useParams()
    const [percentage, setPercentage] = useState(0)
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState()
    const [assessmentStatus, setAssessmentStatus] = useState()
    const [isLiked, setIsLiked] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [screenLoader, setScreenLoader] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isFactorModal, setIsFactorModal] = useState(false)
    const [hidden, setHidden] = useState({});
    const [careerPaths, setCareerPaths] = useState()
    let cmsCareerPathFields = context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(career => career.Key === "Assessments.LookingAtOptions.TellUsAboutYourCareerPath").Components
    const [commonCareerLabels, setCommonCareerLabels] = useState({
        careerHeading: (cmsCareerPathFields?.find(heading => heading.careerHeading).careerHeading).replace(/[0-9]/g, ''),
        careerID: '',
        pathTitleID: '',
        pathTitle: cmsCareerPathFields?.find(title => title.pathTitle).pathTitle,
        pathValue: '',
        aboutPathTitleID: '',
        aboutPathTitle: cmsCareerPathFields?.find(about => about.aboutPathTitle).aboutPathTitle,
        aboutPathValue: ''
    })
    const [commonCareerFactors, setCommonCareerFactors] = useState(context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(factors => factors.Key === "Assessments.LookingAtOptions.CareerFactors"))
    const [newFactor, setNewFactor] = useState()

    useEffect(() => {
        fetchCMSData()
    }, [cmsAssessmentContent])

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllNewFactors()
        getAssessmentStatusWithAnswers()
    }, [context.language])

    const fetchCMSData = () => {
        setLoadSpinner(true)
        let resData = context.cmsLookingAtOptionsContent && context.cmsLookingAtOptionsContent;
        setCmsAssessmentContent(resData)
        setLoadSpinner(false)
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent && cmsAssessmentContent
        let label = ''
        if (refCmsAssessmentContent && refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent && refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let label = ''
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }
    const fetchLabel = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let label = ''
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let id = ''
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id
    }

    const fetchFactorsLabels = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let label = ''
        if (refCmsAssessmentContent?.addNewFactorLabels) {
            refCmsAssessmentContent?.addNewFactorLabels?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }

    const getAllNewFactors = () => {
        AssessmentActions.GetAllNewFactors(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            let resData = res.data
            let commonAnswers = commonCareerFactors.QuestionList.find(options => options.AnswerList).AnswerList

            if (resData?.factorDetails?.length) {
                resData?.factorDetails?.map(newAddedfactor => {
                    let QuestionId = newAddedfactor.id
                    let QuestionTitle = newAddedfactor.factorName
                    let newFactorObj = { QuestionId: QuestionId, QuestionTitle: QuestionTitle, AnswerList: commonAnswers, NewFactorID: QuestionId }
                    commonCareerFactors.QuestionList = [...commonCareerFactors.QuestionList, newFactorObj]
                })
            }
            let obj = {}
            let arr = []
            let questions = commonCareerFactors.QuestionList
            for (let i in questions) {
                if (questions[i]["QuestionId"]) {
                    let objID = questions[i]["QuestionId"]
                    obj[objID] = questions[i]
                }
            }
            for (let i in obj) {
                arr.push(obj[i]);
            }
            commonCareerFactors.QuestionList = arr
        }, err => {
            console.log(err)
        })
    }

    const getAssessmentStatusWithAnswers = () => {
        AssessmentActions.GetAllCareerPathWithFactors(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                let resData = res.data.careerFactorsResponse
                let setFactorsForCareerPath, savedQuestions;
                if (resData.length) {
                    setFactorsForCareerPath = resData?.map(resp => {
                        let tempCommonFactors = commonCareerFactors
                        resp["factors"] = tempCommonFactors
                        resp["factors"] = { ...resp.factors, categoryId: resp.categoryId, Title: resp.categoryTitle }

                        resp.factorAssessmentResponsesList.map(factorsResp => {
                            if (resp.factors.categoryId === resp.categoryId) {
                                savedQuestions = resp.factors.QuestionList.map(questions => {
                                    if (questions.QuestionId === factorsResp.questionId) {
                                        let opts = questions.AnswerList.map(options => {
                                            if (options.AnswerId === factorsResp.answerValue) {
                                                return ({ ...options, defaultChecked: true })
                                            } else {
                                                return (options)
                                            }
                                        })
                                        return ({ ...questions, AnswerList: opts })
                                    } else {
                                        return (questions)
                                    }
                                })
                                resp.factors.QuestionList = savedQuestions
                            }
                        })
                        return ({ ...resp })
                    })
                }
                setCareerPaths(setFactorsForCareerPath)

                AssessmentActions.GetAssessmentStatusAndSavedResponseById(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                    if (res.status === 200) {
                        setAssessmentStatus(res.data)
                        setIsSaved(res.data.isSaved)
                        setIsLiked(res.data.isLiked)
                        let lastAnsweredPageId = parseInt(res.data.lastAnsweredPageId)
                        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                        setCurrentQuestion(lastAnsweredPageId)
                        if (lastAnsweredPageId === setFactorsForCareerPath.length) {
                            setCurrentQuestion(lastAnsweredPageId - 1);
                            assessmentQuestion[lastAnsweredPageId - 1].style.display = "block";
                        }
                        else {
                            setCurrentQuestion(lastAnsweredPageId);
                            assessmentQuestion[lastAnsweredPageId].style.display = "block";
                        }
                        let percentageCalc = Math.ceil(((lastAnsweredPageId + 1) / (assessmentQuestion.length + 1)) * 100);
                        setPercentage(percentageCalc);
                    }
                }, err => {
                    console.log(err)
                })

            } else {
                console.log(res.data)
            }
        }, err => {
            console.log(err)
        })
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper')
        if (n < assessmentQuestion.length) {
            window.scrollTo(0, 0)
            assessmentQuestion[n].style.display = "block";
        }
        if (n == assessmentQuestion.length) {
            navigate("/" + pageOrgin.id + "/looking-at-options/review");
        }
    }

    const nextPrev = (e, n) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        let payload;
        if (n > 0) {
            setLoadSpinner(true);

            payload = {
                "candidateId": candidateID,
                "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
                "learningResourceStatus": assessmentStatus.learningResourceStatus,
                "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType.ID,
                "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType.ID,
                "isSaved": assessmentStatus.isSaved,
                "isLiked": assessmentStatus.isLiked,
                "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId > currentQuestion + n ? assessmentStatus.lastAnsweredPageId : currentQuestion + n,
                "isDownloaded": assessmentStatus?.isDownloaded,
                "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                "resultDocumentId": assessmentStatus?.resultDocumentId
            }

            AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then((res) => {
                if (res.status === 200) {

                    let percentageCalc = Math.ceil(((currentQuestion + n + 1) / (assessmentQuestion.length + 1)) * 100);
                    if (percentageCalc >= percentage) {
                        setPercentage(percentageCalc);
                    }

                    if (currentQuestion === 0) {
                        let percentageCalc = Math.ceil(((currentQuestion + n + 1) / (assessmentQuestion.length + 1)) * 100);
                        if (percentageCalc >= percentage) {
                            setPercentage(percentageCalc);
                        }
                    }

                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    if (currentQuestion < 0) {
                        setCurrentQuestion(0);
                    }
                    showQuestion(currentQuestion + n);
                    setLoadSpinner(false);
                } else {
                    setLoadSpinner(false);
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        } else {
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setPercentage(percentage);
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const removePreviousSelection = (e) => {
        let options = e.target.closest('.options').childNodes;
        options.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove("active")
            }
        });
    }

    const saveAnswers = (categoryId, CategoryValue, questionId, answerId) => {
        let payload = [{
            "AssessementType": "Internal",
            "CategoryId": categoryId,
            "CategoryValue": CategoryValue,
            "QuestionId": questionId,
            "AnswerType": "id",
            "AnswerValue": answerId
        }]

        AssessmentActions.SaveCareerFactors(payload, context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
            } else {
                console.log(res.data)
            }
        }, err => {
            console.log(err)
        })
    }

    const factorModalOpen = () => {
        setIsFactorModal(true)
    }

    const factorModalClose = () => {
        setIsFactorModal(false)
    }

    const addNewFactor = () => {
        setLoadSpinner(true)
        let payload = {
            "factorName": newFactor
        }
        AssessmentActions.AddNewFactor(payload, context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                let resData = res.data
                let commonAnswers = commonCareerFactors.QuestionList.find(options => options.AnswerList).AnswerList

                if (resData?.factorDetails?.length) {
                    resData?.factorDetails?.map(newAddedfactor => {
                        let QuestionId = newAddedfactor.id
                        let QuestionTitle = newAddedfactor.factorName
                        let newFactorObj = { QuestionId: QuestionId, QuestionTitle: QuestionTitle, AnswerList: commonAnswers, NewFactorID: QuestionId }
                        commonCareerFactors.QuestionList = [...commonCareerFactors.QuestionList, newFactorObj]
                    })
                }
                let obj = {}
                let arr = []
                let questions = commonCareerFactors.QuestionList
                for (let i in questions) {
                    if (questions[i]["QuestionId"]) {
                        let objID = questions[i]["QuestionId"]
                        obj[objID] = questions[i]
                    }
                }
                for (let i in obj) {
                    arr.push(obj[i]);
                }
                commonCareerFactors.QuestionList = arr
                getAssessmentStatusWithAnswers()
                setLoadSpinner(false)
            } else {
                setLoadSpinner(false)
            }
        }, err => {
            console.log(err)
            setLoadSpinner(false)
        })
        setIsFactorModal(false)
    }

    const handleOnchange = (e) => {
        if (e.target.value.length > 2) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
        setNewFactor(e.target.value)
    }

    const toggleHide = index => {
        setHidden({ ...hidden, [index]: !hidden[index] });
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            <Container className='pt-4 pb-4'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        {
                            currentQuestion == 0 ?
                                <Cirrus.Button isSecondary onClick={(e) => navigate("/" + pageOrgin.id + "/looking-at-options/career-path")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                :
                                <Cirrus.Button isSecondary onClick={(e) => { nextPrev(e, -1) }}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        }
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label' aria-label='progressbar'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                            <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" aria-label='progressbar' />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonLabel("CommonComponent.CommonExitButton")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {
                careerPaths?.map((career, idx) => {
                    return (
                        <Container className='assessment-question-wrapper mb-5' key={idx}>
                            {
                                loadSpinner &&
                                <div className='loader'>
                                    <Cirrus.Spinner size={50} />
                                </div>
                            }
                            <Row>
                                <Col lg={12} className="assessment-question mb-3">
                                    <Container>
                                        <Row>
                                            <Col lg={9} className='m-auto'>
                                                <Cirrus.Headline size='Small'>
                                                    {commonCareerLabels?.careerHeading + " " + (idx + 1)}
                                                </Cirrus.Headline>
                                                <p className='mb-3'>{career.factors.Description}</p>
                                            </Col>
                                        </Row>
                                    </Container>

                                    <div className='text-start px-3 mb-4 collapsible-career-path' key={idx}>
                                        <div className='d-flex justify-content-between career-path-header py-3 pe-2' onClick={() => toggleHide(idx)}>
                                            <label>{career.categoryTitle}</label>
                                            <div>
                                                {/* {!!hidden[idx] && <span>{fetchLabel("CommonComponent.CommonClose")}</span> || <span>{fetchCommonResourceLabel("CommonResourceComponent.SeeDescription")}</span>} */}
                                                {!!hidden[idx] && <i className='icon-button-arrow rotate-arrow'></i> || <i className='icon-button-arrow'></i>}
                                            </div>
                                        </div>
                                        {
                                            !!hidden[idx] &&
                                            <div className='careerPathDesc py-3'>
                                                {career.categoryDescription}
                                            </div>

                                        }
                                    </div>
                                    {career?.factors?.QuestionList?.map((question, qIdx) => {
                                        return (
                                            <div className='iyip-questionSection mb-2 border-bottom py-3 single-row-qna lao-single-row-qna' key={qIdx}>
                                                <div className='iyip-questionTitle mb-2'>
                                                    {question.QuestionTitle}
                                                </div>
                                                <div className='iyip-options options'>
                                                    {question.AnswerList.map((options, optIdx) => {
                                                        return (
                                                            <label
                                                                key={optIdx}
                                                                className={options.defaultChecked === true ? "custom-radio-button active" : "custom-radio-button"}
                                                            >
                                                                <input type="radio"
                                                                    name={question.QuestionId}
                                                                    value={options.AnswerText}
                                                                    defaultChecked={options.defaultChecked}
                                                                    onChange={(e) => {
                                                                        removePreviousSelection(e)
                                                                        let chosen = [];
                                                                        if (e.target.checked) {
                                                                            chosen.push(options.AnswerId)
                                                                            e.target.parentNode.classList.add("active");
                                                                        } else {
                                                                            e.target.parentNode.classList.remove("active");
                                                                        }
                                                                        saveAnswers(career.categoryId, career.categoryTitle, question.QuestionId, chosen.toString());
                                                                    }}
                                                                />
                                                                {options.AnswerText}
                                                            </label>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className='d-flex flex-md-row flex-column justify-content-between gap-3 loa-buttons'>
                                    <Cirrus.Button isSecondary onClick={() => factorModalOpen()}>{cmsAssessmentContent?.addNewFactorTitle}</Cirrus.Button>
                                    <Cirrus.Button onClick={(e) => nextPrev(e, 1)}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                                </Col>
                            </Row>
                        </Container>
                    )
                })
            }
            {
                isFactorModal &&
                <Cirrus.Modal
                    headerProps={{
                        children: cmsAssessmentContent?.addNewFactorTitle,
                        className: 'no-margin',
                        size: 'h5'
                    }}
                    isScrollable
                >
                    <Cirrus.Input
                        id='factor'
                        label={fetchFactorsLabels("Resources.Assessments.LookingAtOptions.AddNewFactors.Factor")}
                        name="factor"
                        maxLength={30}
                        className='new-factor-input mb-3'
                        value={newFactor}
                        defaultValue=''
                        onChange={(e) => handleOnchange(e)}
                    />
                    <div className='text-center'>
                        <Cirrus.Button isFullWidth className='mb-3' disabled={isDisabled} onClick={() => addNewFactor()}>{fetchFactorsLabels("Resources.Assessments.LookingAtOptions.AddNewFactors.Add")}</Cirrus.Button>
                        <Cirrus.Button isSecondary isFullWidth className='mb-3' onClick={() => factorModalClose()}>{fetchCommonLabel("CommonComponent.CommonCancel")}</Cirrus.Button>
                    </div>
                </Cirrus.Modal>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default CareerFactors;
