//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../common/context/Context";
import "./Job.css";
import Moment from "moment";
import { JobActions } from "./JobActions";
import JobCard from "./JobCard";
import Spinner from "../../common/components/spinner-loader/Spinner";
import JobDetails from "./JobDetails";
import { ActivityAction } from "../../common/utility/ActivityActions";
import countryToCurrency from "country-to-currency";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { globalDateFormat } from "../../common/helpers/DateTimeHelper";
import JobCompareModal from "./jobCompare/JobCompareModal";
function JobSearchTab({
  JDshowHide,
  masterCountryLists,
  quickSearchObj,
  searchCMSData,
  jobCardCMSData,
  saveUnsaveJobId,
}) {
  const context = useContext(Context);
  const [isMobile, setIsMobile] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isSaveSearchOpen, setIsSaveSearchOpen] = useState(false);
  const [isCompareJobsOpen, setIsCompareJobsOpen] = useState(false);
  const [jobLoader, setJobLoader] = useState(false);
  const [saveSearchReferenceElement, setSaveSearchReferenceElement] =
    useState(null);
  const [compareJobsButtonRef, setCompareJobsButtonRef] = useState(null);
  const [allCountryLists, setAllCountryLists] = useState([]);
  const [masterContractType, setMasterContractType] = useState([]);
  const [masterEmploymentType, setMasterEmploymentType] = useState([]);
  const [masterSalaryRange, setMasterSalaryRange] = useState([]);
  const [masterDistance, setMasterDistance] = useState([]);
  const appInsights = useAppInsightsContext();
  const [sortByValue, setSortByValue] = useState("_score");
  const [sortByValues, setSortByValues] = useState([]);
  const [isSortOpen, setIsSortOpen] = useState(false);
  //const [defaultSortOption, setDefaultSortOption] = useState('Relevance');

  const [searchJobsForm, setSearchJobsForm] = useState({
    isSearchClicked: false,
    searchButtonStatus: true,
    keyWord: "",
    country: {
      name: "",
      value: "",
    },
    location: {
      name: "",
      value: "",
    },
    distanceFromLocation: {
      name: "",
      value: "",
    },
  });
  const [jobSearchCountryLists, setJobSearchCountryLists] = useState([]);
  const [jobSearchCityInput, setJobSearchCityInput] = useState("");
  const [jobSearchCityLists, setJobSearchCityLists] = useState({
    value: "",
    suggestions: [],
    noData: false,
  });
  const [cityListError, setCityListError] = useState({
    errorMessage: "",
    state: "",
  });

  const [
    deleteSaveSearchConfirmationPopup,
    setDeleteSaveSearchConfirmationPopup,
  ] = useState(false);
  const [storeDeleteSaveSearchID, setStoreDeleteSaveSearchID] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [editSavedSearchForm, setEditSavedSearchForm] = useState({
    saveButtonStatus: false,
    selectedSearchId: "",
    keywords: {
      name: "keywords",
      value: "",
    },
    country: {
      name: "country",
      value: "",
    },
    location: {
      name: "location",
      value: "",
    },
    distance: {
      name: "distance",
      value: "",
    },
    salary: {
      name: "salary",
      concatValue: "",
      value: [],
    },
    employmentType: {
      name: "employmentType",
      concatValue: "",
      value: [],
    },
    company: {
      name: "company",
      value: "",
    },
    contractType: {
      name: "contractType",
      concatValue: "",
      value: [],
    },
    title: {
      name: "title",
      value: "",
    },
  });
  const [editSearchCountryLists, setEditSearchCountryLists] = useState([]);
  const [editSearchCityLists, setEditSearchCityLists] = useState({
    value: "",
    suggestions: [],
    noData: false,
  });
  const [editSearchCityListsError, setEditSearchCityListsError] = useState({
    errorMessage: "",
    state: "",
  });
  const [editSearchContractType, setEditSearchContractType] = useState([]);
  const [editSearchEmploymentType, setEditSearchEmploymentType] = useState([]);
  const [editSearchSalaryRange, setEditSearchSalaryRange] = useState([]);
  const [editSearchDistance, setEditSearchDistance] = useState([]);

  const [saveThisSearchForm, setSaveThisSearchForm] = useState({
    value: "",
    state: "",
    errorMessage: "",
    disabledSaveButton: true,
    minLetters: false,
    nameExists: false,
    isError: "",
  });
  const [countryCodeData, setCountryCodeData] = useState("");

  const [filterResultForm, setFilterResultForm] = useState({
    isFilterApplied: false,
    applyButtonStatus: true,
    salary: {
      name: "salary",
      concatValue: "",
      value: [],
    },
    company: {
      name: "company",
      concatValue: "",
      value: "",
    },
    contractType: {
      name: "contractType",
      concatValue: "",
      value: [],
    },
    employmentType: {
      name: "employmentType",
      concatValue: "",
      value: [],
    },
  });
  const [refSearchQuery, setRefSearchQuery] = useState({});

  const [savedSearchLists, setSavedSearchLists] = useState([]);
  const [savedSearchSearchButton, setSavedSearchSearchButton] = useState(true);
  const [editSaveSearchModal, setEditSaveSearchModal] = useState(false);
  const [quickSearchFlag, setQuickSearchFlag] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const toggleDropdown = () => {
    setIsSortOpen(!isSortOpen);
    setDropdownOpen(!dropdownOpen);
  };
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsSortOpen(false);
    setDropdownOpen(false); // Close dropdown after selection
    sortByFilter(option);
  };

  const fetchCMSData = (key1, key2) => {
    let data =
      searchCMSData &&
      searchCMSData.pageContent &&
      searchCMSData.pageContent[key1];

    let label = "";
    if (data?.Labels) {
      data?.Labels.map((x) => {
        if (x.Key === key2) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchJobCardCMSData = (key) => {
    let label = "";
    if (jobCardCMSData?.pageContent?.Labels) {
      jobCardCMSData?.pageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSortOpen(false);
      setDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (saveUnsaveJobId && saveUnsaveJobId.jobId !== "") {
      let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
      const jobIdList = refShownJobs && refShownJobs.map((job) => job.id);
      if (jobIdList && jobIdList.includes(saveUnsaveJobId.jobId)) {
        const objIndex = refShownJobs.findIndex(
          (obj) => obj.id == saveUnsaveJobId.jobId
        );
        const updated = refShownJobs
          ? (refShownJobs[objIndex].isSaved = false)
          : "";
        setShownJobs(refShownJobs);
      }
    }
  }, [saveUnsaveJobId]);

  const [savedjobs, setSavedjobs] = useState([]);
  const [appliedjobs, setAppliedjobs] = useState([]);
  const [paginationState, setPaginationState] = useState({
    desabledLoadMore: false,
    searchCountAndLabel: "",
    totalCounts: 0,
    fetchedCounts: 0,
    shownCounts: 0,
    payload: {},
  });
  const [fetchedJobs, setFetchedJobs] = useState([]);
  const [shownJobs, setShownJobs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setTimeout(() => {
        setIsMobile(window.innerWidth < Cirrus.devices.Desktop);
      }, 250);
    };
    TrackActions.PageViewRecord(appInsights, "Job Search");
    TrackActions.PageViewRecord(appInsights, "Job Match");
    TrackActions.PageAction("Job Search");
    TrackActions.PageAction("Job Match");
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (quickSearchObj?.keyword !== "") {
      setQuickSearchFlag(true);
      let candidateID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ];
      let payload = {
        QueryString:
          quickSearchObj?.keyword +
          " jf_source_country:" +
          JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
            "programCountryCode"
          ].toUpperCase() +
          " documentdate:>=today-42",
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        SearchAfterScore: "",
        SearchAfter: "",
        SearchAfterDocid: "",
        SearchAfter2: "",
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
        OwnerId: candidateID,
        field: sortByValue,
        order: "DESCENDING",
      };

      let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
      refSearchJobsForm.isSearchClicked = true;
      refSearchJobsForm.searchButtonStatus = true;
      refSearchJobsForm.keyWord = quickSearchObj?.keyword;
      setSearchJobsForm(refSearchJobsForm);
      setJobSearchCityLists({
        value: "",
        suggestions: [],
        noData: false,
      });

      getAllSearchedjobs(
        payload,
        false,
        JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ]
      );
      setTimeout(() => {
        setQuickSearchFlag(false);
      }, 100);
    }
  }, [quickSearchObj]);

  useEffect(() => {
    if (Object.keys(searchCMSData.pageContent).length !== 0) {
      let cType = [];
      let eType = [];
      let sRange = [];
      let lDistance = [];
      let sType = [];
      searchCMSData?.pageContent?.["JobSearch.RefineSearch"]?.LOVList?.filter(
        (x) => {
          if (x.Key === "JobSearch.RefineSearch.SortBy") {
            //console.log(x.Values);
            x.Values.map((ct) => {
              //console.log(ct);
              let obj = {
                name: ct.Value,
                value: ct.Key,
              };
              sType.push(obj);
            });
            setSortByValues(sType);
            //console.log(sType);
            setSelectedOption(sType[0]);
          }
          if (x.Key === "JobSearch.RefineSearch.ContractType") {
            x.Values.map((ct) => {
              let obj = {
                label: ct.Value,
                value: ct.ID,
              };
              cType.push(obj);
            });
            setMasterContractType(cType);
          }
          if (x.Key === "JobSearch.RefineSearch.EmploymentType") {
            x.Values.map((ct) => {
              let obj = {
                label: ct.Value,
                value: ct.ID,
              };
              eType.push(obj);
            });
            setMasterEmploymentType(eType);
          }
          if (x.Key === "JobSearch.RefineSearch.SalaryRange") {
            x.Values.map((ct) => {
              if (ct.Value.includes("-")) {
                let splt = ct.Value.replace(/ /g, "").split("-");
                let spltStart =
                  searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                  splt[0];
                let spltEnd =
                  searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                  splt[1];
                let obj = {
                  label: spltStart + " - " + spltEnd,
                  value: ct.ID,
                };
                sRange.push(obj);
              } else {
                let above = "";
                if (ct.Value.includes("Above")) {
                  let splt = ct.Value.split(" ");
                  above =
                    splt[0] +
                    " " +
                    searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                    splt[1];
                } else {
                  let splt = ct.Value.split(" ");
                  if(context?.language?.selectedLanguageISOCode?.split('-')[0] == 'fr') //for French Language
                  { 
                    //Moins de 1000 >>>> Moins de €1000
                    above = splt[0] + " " + splt[1] + " " + searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                    splt[2];
                  }
                  else {
                  above =
                    splt[0] +
                    " " +
                    searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                    splt[1];
                  }
                }
                let obj = {
                  label: above,
                  value: ct.ID,
                };
                sRange.push(obj);
              }
            });
            setMasterSalaryRange(sRange);
          }
        }
      );
      searchCMSData?.pageContent?.[
        "JobSearch.EditSavedSearch"
      ]?.LOVList?.filter((x) => {
        if (x.Key === "LocationDistance") {
          x.Values.map((ct) => {
            let obj = {
              name: ct.Value,
              value: ct.ID,
            };
            lDistance.push(obj);
          });
          lDistance.unshift({
            name: fetchCMSData(
              "JobSearch.SearchJobBox",
              "Job.JobSearch.SearchJobBox.Select"
            ),
            value: "",
          });
          setMasterDistance(lDistance);
        }
      });
    }
  }, [searchCMSData]);

  // Start search for jobs form
  useEffect(() => {
    if (masterCountryLists.length > 0) {
      setAllCountryLists(masterCountryLists);
      let pgCode = JSON.parse(
        window.sessionStorage.getItem("setProgramDetails")
      );
      let reCountry = returnCountry(
        masterCountryLists,
        pgCode.programCountryCode?.toLowerCase()
      );
      setJobSearchCountryLists(reCountry);
      let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
      refSearchJobsForm.country.name = reCountry[0].name;
      refSearchJobsForm.country.value = reCountry[0].value;
      setSearchJobsForm(refSearchJobsForm);
    }
  }, [masterCountryLists]);
  const searchInputHandler = (event) => {
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    setSearchInput(event.target.value);
    if (event.target.value !== "") {
      refSearchJobsForm.searchButtonStatus = false;
      refSearchJobsForm.keyWord = event.target.value;
      setSearchJobsForm(refSearchJobsForm);
    } else {
      refSearchJobsForm.searchButtonStatus = true;
      refSearchJobsForm.keyWord = "";
      setSearchJobsForm(refSearchJobsForm);
    }
  };
  const countrySelectHandler = (event) => {
    let refJobSearchCountryLists = JSON.parse(
      JSON.stringify(jobSearchCountryLists)
    );
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    let selectFilter = refJobSearchCountryLists.filter((ct) => {
      return ct.value === event.target.value;
    });
    refSearchJobsForm.country.name = selectFilter[0].name;
    refSearchJobsForm.country.value = selectFilter[0].value;
    setSearchJobsForm(refSearchJobsForm);
  };
  const getAllLocationLists = (event) => {
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    const value = event.target.value;
    let suggestions = [];
    setJobSearchCityInput(value);
    if (value === "") {
      setJobSearchCityLists({ suggestions, value: "", noData: false });
      refSearchJobsForm.location.name = "";
      refSearchJobsForm.location.value = "";
      setSearchJobsForm(refSearchJobsForm);
      setCityListError({
        errorMessage: "",
        state: "",
      });
    }
    if (value.length > 0) {
      setJobSearchCityLists({ suggestions, value: value, noData: false });
      setCityListError({
        errorMessage: "",
        state: "",
      });
    }
    if (value.length >= 3) {
      const payload = {
        field_to_query: "city",
        input_text: refSearchJobsForm.country.value.toUpperCase() + " " + value,
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
      };
      JobActions.LocationAutoComplete(payload)
        .then((res) => {
          if (res.status === 200) {
            res?.data?.auto_suggest?.map((item) => {
              let location = item.item.split(" ");
              location.shift();
              let joinArrLocation = [...location];
              let joinLocation = joinArrLocation.join(" ");

              let obj = {
                ID: item.item,
                Name: item.item,
                Value: joinLocation,
              };
              if (item?.item) {
                suggestions.push(obj);
              }
            });
            if (suggestions.length > 0) {
              setJobSearchCityLists({
                suggestions,
                value: value,
                noData: false,
              });
              setCityListError({
                errorMessage: "",
                state: "",
              });
            } else {
              setJobSearchCityLists({
                suggestions,
                value: value,
                noData: true,
              });
              setCityListError({
                errorMessage: fetchCMSData(
                  "JobSearch.SearchJobBox",
                  "Job.JobSearch.SearchJobBox.NoMatchFoundValidationMessage"
                ),
                state: "error",
              });
            }
          }
        })
        .catch((err) => {
          setCityListError({
            errorMessage: fetchCMSData(
              "JobSearch.SearchJobBox",
              "Job.JobSearch.SearchJobBox.CountryNotSupportedValidationMessage"
            ),
            state: "error",
          });
        });
    }
  };
  const locationSelectHandler = (event, item) => {
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    setJobSearchCityInput(item.Value);
    refSearchJobsForm.location.name = item.Name;
    refSearchJobsForm.location.value = item.Value;
    setSearchJobsForm(refSearchJobsForm);
    setJobSearchCityLists({
      value: item.Value,
      suggestions: [],
      noData: false,
    });
  };
  const distanceSelectHandler = (event) => {
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    if (event.target.value !== "") {
      let selectFilter = masterDistance.filter((ct) => {
        return ct.value === event.target.value;
      });
      refSearchJobsForm.distanceFromLocation.name = selectFilter[0].name;
      refSearchJobsForm.distanceFromLocation.value = selectFilter[0].value;
      setSearchJobsForm(refSearchJobsForm);
    } else {
      refSearchJobsForm.distanceFromLocation.name = fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.Select"
      );
      refSearchJobsForm.distanceFromLocation.value = "";
      setSearchJobsForm(refSearchJobsForm);
    }
  };
  const searchHandler = (event) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    event?.preventDefault();
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    refSearchJobsForm.isSearchClicked = true;
    setSearchJobsForm(refSearchJobsForm);
    ActivityAction.SaveActivity("null", "jobsearch", refSearchJobsForm.keyWord);
    //setIsFilterOpen(false);
    setIsFilterOpen(isFilterOpen);
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    refFilterResultForm.company.value = "";
    refFilterResultForm.company.concatValue = "";
    refFilterResultForm.salary.value = [];
    refFilterResultForm.salary.concatValue = "";
    refFilterResultForm.contractType.value = [];
    refFilterResultForm.contractType.concatValue = "";
    refFilterResultForm.employmentType.value = [];
    refFilterResultForm.employmentType.concatValue = "";
    refFilterResultForm.applyButtonStatus = true;
    refFilterResultForm.isFilterApplied = false;
    setFilterResultForm(refFilterResultForm);

    let keyWord =
      refSearchJobsForm.keyWord !== "" ? refSearchJobsForm.keyWord : "";
    let country =
      refSearchJobsForm.country.value !== ""
        ? " jf_source_country:" + refSearchJobsForm.country.value.toUpperCase()
        : "";
    setCountryCodeData(country && country);
    let location =
      refSearchJobsForm.location.value !== ""
        ? " city:" + '"' + refSearchJobsForm.location.value + '"'
        : "";
    let distanceFromLocation =
      refSearchJobsForm.distanceFromLocation.value !== ""
        ? "+" + refSearchJobsForm.distanceFromLocation.value.split("m")[0]
        : "";
    setRefSearchQuery({
      keyWord: keyWord,
      country: country,
      location: location,
      distanceFromLocation: distanceFromLocation,
    });

    let payload = {
      QueryString:
        keyWord +
        country +
        location +
        distanceFromLocation +
        " documentdate:>=today-42",
      country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programCountryCode"
      ],
      SearchAfterScore: "",
      SearchAfter: "",
      SearchAfterDocid: "",
      SearchAfter2: "",
      language: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programLanguageCode"
      ],
      OwnerId: candidateID,
      field: sortByValue,
      order: "DESCENDING",
    };

    getAllSearchedjobs(
      payload,
      false,
      refSearchJobsForm.country.value.toUpperCase()
    );
  };
  // End search for jobs form

  // Start Save this search
  const saveSearchInputHandler = (event) => {
    let refSaveThisSearchForm = JSON.parse(JSON.stringify(saveThisSearchForm));
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    if (event.target.value !== "") {
      if (refSearchJobsForm.keyWord === "") {
        refSaveThisSearchForm.disabledSaveButton = true;
        setSaveThisSearchForm(refSaveThisSearchForm);
        return;
      }
      refSaveThisSearchForm.value = event.target.value;
      refSaveThisSearchForm.disabledSaveButton = false;
      setSaveThisSearchForm(refSaveThisSearchForm);
    } else {
      refSaveThisSearchForm.disabledSaveButton = true;
      setSaveThisSearchForm(refSaveThisSearchForm);
    }
  };
  const saveThisSearchHandler = async (event, value) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    event.preventDefault();
    let refSaveThisSearchForm = JSON.parse(JSON.stringify(saveThisSearchForm));
    let refSearchJobsForm = JSON.parse(JSON.stringify(searchJobsForm));
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    if (value.length < 3) {
      refSaveThisSearchForm.minLetters = true;
      refSaveThisSearchForm.nameExists = false;
      refSaveThisSearchForm.isError = "error";
      setSaveThisSearchForm(refSaveThisSearchForm);
    } else if (value.length >= 3) {
      let saveThisSearchPayload = {
        ownerId: candidateID,
        ownerType: "Candidate",
        searchName: refSaveThisSearchForm.value,
        keywords: refSearchJobsForm.keyWord,
        country: refSearchJobsForm.country.value,
        jobLocation:
          refSearchJobsForm.location.value !== ""
            ? refSearchJobsForm.location.value
            : "",
        distanceFromLocation:
          refSearchJobsForm.distanceFromLocation.value !== ""
            ? refSearchJobsForm.distanceFromLocation.value.split("m")[0]
            : 0,
        companyName: refFilterResultForm.company.value,
        salary: refFilterResultForm.salary.value,
        employmentType: refFilterResultForm.employmentType.value,
        contractType: refFilterResultForm.contractType.value,    
      };
      await JobActions.SaveSearchWithName(saveThisSearchPayload).then(
        (resp) => {
          if (resp.status === 201) {
            getAllSavedSearch();
            refSaveThisSearchForm.isError = " ";
            setSaveThisSearchForm(refSaveThisSearchForm);
            setIsSaveSearchOpen(!isSaveSearchOpen);
          } else if (resp.status === 400) {
            refSaveThisSearchForm.minLetters = false;
            refSaveThisSearchForm.nameExists = true;
            refSaveThisSearchForm.isError = "error";
            setSaveThisSearchForm(refSaveThisSearchForm);
          }
        },
        (err) => {
          console.log(err);
          setJobLoader(false);
        }
      );
    }
  };
  useEffect(() => {
    getAllSavedSearch();
  }, []);

  useEffect(() => {
    if (searchInput !== "") searchHandler();
  }, [sortByValue]);

  const getAllSavedSearch = async () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    await JobActions.GetSavedSearchList({ ownerId: candidateID }).then(
      (resp) => {
        if (resp.status === 200) {
          let listOfItem = [];
          resp?.data?.map((list) => {
            listOfItem.push({
              title: list.searchName,
              id: list.id,
              data: list,
            });
          });
          setSavedSearchLists(listOfItem);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  // End Save this search

  // start Save search search job functionality
  const onChangeSaveSearchDropdownHandler = (event, savedSearch) => {
    if (savedSearch.data.keywords !== "") {
      setSavedSearchSearchButton(false);
    } else {
      setSavedSearchSearchButton(true);
    }
  };
  const savedSearchEditHandler = (event, savedSearch) => {
    let refEditSavedSearchForm = JSON.parse(
      JSON.stringify(editSavedSearchForm)
    );
    refEditSavedSearchForm.selectedSearchId = savedSearch.data.id;
    refEditSavedSearchForm.keywords.value = savedSearch.data.keywords;
    let reCountry = returnCountry(
      allCountryLists,
      savedSearch.data.country.toLowerCase()
    );
    setEditSearchCountryLists(reCountry);
    refEditSavedSearchForm.country.value = reCountry[0].value;
    refEditSavedSearchForm.location.value = savedSearch.data.jobLocation;
    setEditSearchCityLists({
      suggestions: [],
      value: savedSearch.data.jobLocation,
      noData: false,
    });

    if (savedSearch.data.distanceFromLocation === 0) {
      refEditSavedSearchForm.distance.value = 0;
      setEditSearchDistance(masterDistance);
    } else {
      let reValue = returnDistanceValues(
        masterDistance,
        savedSearch.data.distanceFromLocation.toString()
      );
      refEditSavedSearchForm.distance.value = reValue[0].value;
      setEditSearchDistance(reValue);
    }

    if (savedSearch.data.salary === "" || savedSearch.data.salary === null) {
      refEditSavedSearchForm.salary.value = "";
    } else {
      let reValue = returnMultiSelectValues(
        masterSalaryRange,
        savedSearch.data.salary
      );
      refEditSavedSearchForm.salary.value = savedSearch.data.salary;
      setEditSearchSalaryRange(reValue);
    }

    if (
      savedSearch.data.employmentType === "" ||
      savedSearch.data.employmentType === null
    ) {
      refEditSavedSearchForm.employmentType.value = "";
    } else {
      let reValue = returnMultiSelectValues(
        masterEmploymentType,
        savedSearch.data.employmentType
      );
      refEditSavedSearchForm.employmentType.value =
        savedSearch.data.employmentType;
      setEditSearchEmploymentType(reValue);
    }

    refEditSavedSearchForm.company.value = savedSearch.data.companyName;

    if (
      savedSearch.data.contractType === "" ||
      savedSearch.data.contractType === null
    ) {
      refEditSavedSearchForm.contractType.value = "";
    } else {
      let reValue = returnMultiSelectValues(
        masterContractType,
        savedSearch.data.contractType
      );
      refEditSavedSearchForm.contractType.value = savedSearch.data.contractType;
      setEditSearchContractType(reValue);
    }

    refEditSavedSearchForm.title.value = savedSearch.data.searchName;
    setEditSavedSearchForm(refEditSavedSearchForm);
    setEditSaveSearchModal(true);
  };
  const fromSaveSearchSearchJobHandler = (event, savedSearch) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    event.preventDefault();
    if (savedSearch !== undefined) {
      let getTheItem = savedSearchLists.filter((fltr) => {
        return fltr.id === savedSearch.data.id;
      });

      let keywords =
        getTheItem[0]?.data?.keywords !== ""
          ? getTheItem[0]?.data?.keywords
          : "";
      let country =
        getTheItem[0]?.data?.country !== ""
          ? " jf_source_country:" + getTheItem[0]?.data?.country.toUpperCase()
          : "";
      setCountryCodeData(country && country);
      let jobLocation =
        getTheItem[0]?.data?.jobLocation !== ""
          ? " city:" + '"' + getTheItem[0]?.data?.jobLocation + '"'
          : "";
      let distanceFromLocation =
        getTheItem[0]?.data?.distanceFromLocation !== 0
          ? "+" + getTheItem[0]?.data?.distanceFromLocation
          : "";
      let companyName =
        getTheItem[0]?.data?.companyName !== "" &&
        getTheItem[0]?.data?.companyName !== null &&
        getTheItem[0]?.data?.companyName !== undefined
          ? " " + "org_normalized_name:" + savedSearch.data.companyName
          : "";
      let contractType = "";
      let employmentType = "";
      let salaryRange = "";
      if (
        getTheItem[0]?.data?.contractType !== "" &&
        getTheItem[0]?.data?.contractType !== null &&
        getTheItem[0]?.data?.contractType !== undefined
      ) {
        contractType = returnMultiSelectValuesWithPrefix(
          masterContractType,
          getTheItem[0]?.data?.contractType,
          "contractType"
        );
      }
      if (
        getTheItem[0]?.data?.employmentType !== "" &&
        getTheItem[0]?.data?.employmentType !== null &&
        getTheItem[0]?.data?.employmentType !== undefined
      ) {
        employmentType = returnMultiSelectValuesWithPrefix(
          masterEmploymentType,
          getTheItem[0]?.data?.employmentType,
          "employmentType"
        );
      }
      if (
        getTheItem[0]?.data?.salary !== "" &&
        getTheItem[0]?.data?.salary !== null &&
        getTheItem[0]?.data?.salary !== undefined
      ) {
        salaryRange = returnMultiSelectValuesWithPrefix(
          masterSalaryRange,
          getTheItem[0]?.data?.salary,
          "salary"
        );
      }

      setRefSearchQuery({
        keyWord: keywords,
        country: country,
        location: jobLocation,
        distanceFromLocation: distanceFromLocation,
        companyName: companyName,
        contractType: contractType,
        employmentType: employmentType,
        salaryRange: salaryRange,
      });

      //setIsFilterOpen(false);
      setIsFilterOpen(isFilterOpen);
      let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
      refFilterResultForm.company.value = "";
      refFilterResultForm.company.concatValue = "";
      refFilterResultForm.salary.value = [];
      refFilterResultForm.salary.concatValue = "";
      refFilterResultForm.contractType.value = [];
      refFilterResultForm.contractType.concatValue = "";
      refFilterResultForm.employmentType.value = [];
      refFilterResultForm.employmentType.concatValue = "";
      refFilterResultForm.applyButtonStatus = true;
      refFilterResultForm.isFilterApplied = false;
      setFilterResultForm(refFilterResultForm);

      let payload = {
        QueryString:
          keywords +
          country +
          jobLocation +
          distanceFromLocation +
          companyName +
          salaryRange +
          contractType +
          employmentType +
          " documentdate:>=today-42",
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        SearchAfterScore: "",
        SearchAfter: "",
        SearchAfterDocid: "",
        SearchAfter2: "",
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
        OwnerId: candidateID,
        isFlagsRequired: true,
        field: sortByValue,
        order: "DESCENDING",
      };
      getAllSearchedjobs(
        payload,
        false,
        getTheItem[0]?.data?.country.toUpperCase()
      );
    }
  };
  // End Save search search job functionality

  // Start Edit Delete Saved Search
  const editSavedSearchFormHandler = (event, flag) => {
    let refEditSavedSearchForm = JSON.parse(
      JSON.stringify(editSavedSearchForm)
    );
    if (flag === refEditSavedSearchForm.keywords.name) {
      if (event.target.value !== "") {
        refEditSavedSearchForm.keywords.value = event.target.value;
        refEditSavedSearchForm.saveButtonStatus = false;
        setEditSavedSearchForm(refEditSavedSearchForm);
      } else {
        refEditSavedSearchForm.keywords.value = "";
        refEditSavedSearchForm.saveButtonStatus = true;
        setEditSavedSearchForm(refEditSavedSearchForm);
      }
    }
    if (flag === refEditSavedSearchForm.country.name) {
      refEditSavedSearchForm.country.value = event.target.value;
      setEditSavedSearchForm(refEditSavedSearchForm);
    }
    if (flag === refEditSavedSearchForm.distance.name) {
      if (event.target.value !== "") {
        let ds = event.target.value.split("m")[0];
        refEditSavedSearchForm.distance.value = ds;
        setEditSavedSearchForm(refEditSavedSearchForm);
      } else {
        refEditSavedSearchForm.distance.value = 0;
        setEditSavedSearchForm(refEditSavedSearchForm);
      }
    }
    if (flag === refEditSavedSearchForm.salary.name) {
      if (event.length === 1) {
        refEditSavedSearchForm.salary.value = event[0].value;
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchSalaryRange(event);
      } else if (event.length > 1) {
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + x.value + ",";
        });
        refEditSavedSearchForm.salary.value = stringValue.replace(/.$/, "");
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchSalaryRange(event);
      } else {
        refEditSavedSearchForm.salary.value = "";
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchSalaryRange(event);
      }
    }
    if (flag === refEditSavedSearchForm.employmentType.name) {
      if (event.length === 1) {
        refEditSavedSearchForm.employmentType.value = event[0].value;
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchEmploymentType(event);
      } else if (event.length > 1) {
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + x.value + ",";
        });
        refEditSavedSearchForm.employmentType.value = stringValue.replace(
          /.$/,
          ""
        );
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchEmploymentType(event);
      } else {
        refEditSavedSearchForm.employmentType.value = "";
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchEmploymentType(event);
      }
    }
    if (flag === refEditSavedSearchForm.company.name) {
      if (event.target.value !== "") {
        refEditSavedSearchForm.company.value = event.target.value;
        setEditSavedSearchForm(refEditSavedSearchForm);
      } else {
        refEditSavedSearchForm.company.value = "";
        setEditSavedSearchForm(refEditSavedSearchForm);
      }
    }
    if (flag === refEditSavedSearchForm.contractType.name) {
      if (event.length === 1) {
        refEditSavedSearchForm.contractType.value = event[0].value;
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchContractType(event);
      } else if (event.length > 1) {
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + x.value + ",";
        });
        refEditSavedSearchForm.contractType.value = stringValue.replace(
          /.$/,
          ""
        );
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchContractType(event);
      } else {
        refEditSavedSearchForm.contractType.value = "";
        setEditSavedSearchForm(refEditSavedSearchForm);
        setEditSearchContractType(event);
      }
    }
    if (flag === refEditSavedSearchForm.title.name) {
      if (event.target.value !== "") {
        refEditSavedSearchForm.title.value = event.target.value;
        setEditSavedSearchForm(refEditSavedSearchForm);
      } else {
        refEditSavedSearchForm.title.value = "";
        setEditSavedSearchForm(refEditSavedSearchForm);
      }
    }
  };
  const saveTheEditedSearch = async (event) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    event.preventDefault();
    let refEditSavedSearchForm = JSON.parse(
      JSON.stringify(editSavedSearchForm)
    );
    let editedSearchPayload = {
      id: refEditSavedSearchForm.selectedSearchId,
      keywords: refEditSavedSearchForm.keywords.value,
      country: refEditSavedSearchForm.country.value,
      jobLocation: refEditSavedSearchForm.location.value,
      distanceFromLocation: refEditSavedSearchForm.distance.value,
      salary: refEditSavedSearchForm.salary.value, // NEED CLARIFICATION
      employmentType: refEditSavedSearchForm.employmentType.value, // NEED CLARIFICATION
      companyName: refEditSavedSearchForm.company.value,
      contractType: refEditSavedSearchForm.contractType.value, // NEED CLARIFICATION
      searchName: refEditSavedSearchForm.title.value,
      ownerId: candidateID,
      ownerType: "Candidate",
    };
    await JobActions.UpdateSavedSearchListById(
      refEditSavedSearchForm.selectedSearchId,
      editedSearchPayload
    ).then(
      (resp) => {
        if (resp.status === 204) {
          getAllSavedSearch();
          setEditSaveSearchModal(false);
        } else {
          setJobLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
  };
  const confirmDeleteTheSavedSearchHandler = (id) => {
    setStoreDeleteSaveSearchID(id);
    setDeleteSaveSearchConfirmationPopup(true);
  };
  const deleteTheSavedSearch = async () => {
    await JobActions.DeleteSavedSearchListById(storeDeleteSaveSearchID).then(
      (resp) => {
        if (resp.status === 204) {
          getAllSavedSearch();
          setEditSaveSearchModal(false);
          setDeleteSaveSearchConfirmationPopup(false);
        } else {
          setJobLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
  };

  const getEditSearchCityLists = (event) => {
    let refEditSavedSearchForm = JSON.parse(
      JSON.stringify(editSavedSearchForm)
    );
    const value = event.target.value;
    let suggestions = [];
    if (value === "") {
      setEditSearchCityLists({ suggestions, value: "", noData: false });
      refEditSavedSearchForm.location.name = "";
      refEditSavedSearchForm.location.value = "";
      setEditSavedSearchForm(refEditSavedSearchForm);
      setEditSearchCityListsError({
        errorMessage: "",
        state: "",
      });
    }
    if (value.length > 0) {
      setEditSearchCityLists({ suggestions, value: value, noData: false });
      refEditSavedSearchForm.location.value = event.target.value;
      setEditSavedSearchForm(refEditSavedSearchForm);
      setEditSearchCityListsError({
        errorMessage: "",
        state: "",
      });
    }
    if (value.length >= 3) {
      const payload = {
        field_to_query: "city",
        input_text:
          refEditSavedSearchForm.country.value.toUpperCase() + " " + value,
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
      };
      JobActions.LocationAutoComplete(payload)
        .then((res) => {
          if (res.status === 200) {
            res?.data?.auto_suggest?.map((item) => {
              let location = item.item.split(" ");
              location.shift();
              let joinArrLocation = [...location];
              let joinLocation = joinArrLocation.join(" ");

              let obj = {
                ID: item.item,
                Name: item.item,
                Value: joinLocation,
              };
              if (item?.item) {
                suggestions.push(obj);
              }
            });
            if (suggestions.length > 0) {
              setEditSearchCityLists({
                suggestions,
                value: value,
                noData: false,
              });
              setEditSearchCityListsError({
                errorMessage: "",
                state: "",
              });
            } else {
              setEditSearchCityLists({
                suggestions,
                value: value,
                noData: true,
              });
              setEditSearchCityListsError({
                errorMessage: "No match found",
                state: "error",
              });
            }
          }
        })
        .catch((err) => {
          setEditSearchCityListsError({
            errorMessage: "This Country is not supported",
            state: "error",
          });
        });
    }
  };

  const editSearchCitySelectHandler = (event, item) => {
    let refEditSavedSearchForm = JSON.parse(
      JSON.stringify(editSavedSearchForm)
    );
    refEditSavedSearchForm.location.name = item.Name;
    refEditSavedSearchForm.location.value = item.Value;
    setEditSavedSearchForm(refEditSavedSearchForm);
    setEditSearchCityLists({
      value: item.Value,
      suggestions: [],
      noData: false,
    });
  };
  // End Edit Delete Saved Search

  // Start Filter result
  const filterFormHandler = (event, flag) => {
    let salArray = [];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    if (flag === refFilterResultForm.company.name) {
      if (event.target.value !== "") {
        refFilterResultForm.company.value = event.target.value;
        refFilterResultForm.company.concatValue =
          " " + "org_normalized_name:" + event.target.value;
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.company.value = "";
        refFilterResultForm.company.concatValue = "";
        setFilterResultForm(refFilterResultForm);
      }
    }
    if (flag === refFilterResultForm.salary.name) {
      if (event.length === 1) {
        refFilterResultForm.salary.value = event[0].value;
        setFilterResultForm(refFilterResultForm);
        setEditSearchSalaryRange(event);
      } else if (event.length > 1) {
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + x.value + ",";
        });
        refFilterResultForm.salary.value = stringValue.replace(/.$/, "");
        setFilterResultForm(refFilterResultForm);
        setEditSearchSalaryRange(event);
      } else {
        refFilterResultForm.salary.value = "";
        setFilterResultForm(refFilterResultForm);
        setEditSearchSalaryRange(event);
      }
      if (event.length > 0) {
        //refFilterResultForm.salary.value = event;
        let stringValue = "";
        event.map((x) => {
          if (x.value.includes("-")) {
            let splt = x.value.replace(/ /g, "").split("-");
            salArray = [...salArray, ...splt];
            let sortedInc = salArray.sort(function (a, b) {
              return a - b;
            });
            let intialValMin = event?.length == 1 ? 0 : sortedInc[0];
            let secondValMin = event?.length == 1 ? sortedInc[0] : sortedInc[1];
            stringValue =
              " " +
              "salary_minimum:" +
              intialValMin +
              ".." +
              secondValMin +
              " " +
              "salary_maximum:" +
              secondValMin +
              ".." +
              sortedInc[sortedInc?.length - 1];
          } else {
            if (x.value.includes("Above")) {
              let rep = x.value.replace("Above", "");
              stringValue = " " + "salary_minimum:>=" + rep;
            } else {
              let rep = x.value.replace("Below", "");
              stringValue = " " + "salary_maximum:" + "0..." + rep;
            }
          }
        });
        refFilterResultForm.salary.concatValue = stringValue;
        setFilterResultForm(refFilterResultForm);
        setEditSearchSalaryRange(event);
      } else {
        refFilterResultForm.salary.value = "";
        refFilterResultForm.salary.concatValue = "";
        setFilterResultForm(refFilterResultForm);
        setEditSearchSalaryRange(event);
      }
    }
    if (flag === refFilterResultForm.contractType.name) {
      if (event.length === 1) {
        refFilterResultForm.contractType.value = event[0].value;
        setFilterResultForm(refFilterResultForm);
        setEditSearchContractType(event);
      }
      else if (event.length > 1) {
          let stringValue = "";
          event.map((x) => {
            stringValue = stringValue + x.value + ",";
          });
          refFilterResultForm.contractType.value = stringValue.replace(
            /.$/,
            ""
          );
          setFilterResultForm(refFilterResultForm);
          setEditSearchContractType(event);
        }
      if (event.length > 0) {
        //refFilterResultForm.contractType.value = event;
        setEditSearchContractType(event);
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + " " + `\"${x.value}\"`;
        });
        refFilterResultForm.contractType.concatValue =
          " contract_type_code:" + "(" + stringValue + ")";
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.contractType.value = "";
        refFilterResultForm.contractType.concatValue = "";
        setFilterResultForm(refFilterResultForm);
        setEditSearchContractType(event);
      }
    }
    if (flag === refFilterResultForm.employmentType.name) {
      if (event.length === 1) {
        refFilterResultForm.employmentType.value = event[0].value;
        setFilterResultForm(refFilterResultForm);
        setEditSearchEmploymentType(event);
      } else if (event.length > 1) {
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + x.value + ",";
        });
        refFilterResultForm.employmentType.value = stringValue.replace(
          /.$/,
          ""
        );
        setFilterResultForm(refFilterResultForm);
        setEditSearchEmploymentType(event);
      }
      if (event.length > 0) {
        //refFilterResultForm.employmentType.value = event;
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + " " + x.value;
        });
        refFilterResultForm.employmentType.concatValue =
          " employment_type_code:" + "(" + stringValue + ")";
        setFilterResultForm(refFilterResultForm);
        setEditSearchEmploymentType(event);
      } else {
        refFilterResultForm.employmentType.value = "";
        refFilterResultForm.employmentType.concatValue = "";
        setFilterResultForm(refFilterResultForm);
        setEditSearchEmploymentType(event);
      }
    }

    if (
      refFilterResultForm.company.concatValue !== "" ||
      refFilterResultForm.salary.concatValue !== "" ||
      refFilterResultForm.contractType.concatValue !== "" ||
      refFilterResultForm.employmentType.concatValue !== ""
    ) {
      refFilterResultForm.applyButtonStatus = false;
      setFilterResultForm(refFilterResultForm);
    } else {
      refFilterResultForm.applyButtonStatus = true;
      setFilterResultForm(refFilterResultForm);
      clearAllFilterHandler();
    }
  };
  const applyFilterHandler = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    refFilterResultForm.isFilterApplied = true;
    setFilterResultForm(refFilterResultForm);

    if (isMobile) {
      setIsFilterOpen(!isFilterOpen);
    }

    let keywords =
      refSearchQuery.keyWord !== "" && refSearchQuery.keyWord !== undefined
        ? refSearchQuery.keyWord
        : "";
    let country =
      refSearchQuery.country !== "" && refSearchQuery.country !== undefined
        ? refSearchQuery.country
        : "";
    let jobLocation =
      refSearchQuery.location !== "" && refSearchQuery.location !== undefined
        ? refSearchQuery.location
        : "";
    let distanceFromLocation =
      refSearchQuery.distanceFromLocation !== "" &&
      refSearchQuery.distanceFromLocation !== undefined
        ? refSearchQuery.distanceFromLocation
        : "";
    let companyName =
      refFilterResultForm.company.concatValue === ""
        ? refSearchQuery.companyName !== "" &&
          refSearchQuery.companyName !== undefined
          ? refSearchQuery.companyName
          : ""
        : refFilterResultForm.company.concatValue;
    let contractType =
      refFilterResultForm.contractType.concatValue === ""
        ? refSearchQuery.contractType !== "" &&
          refSearchQuery.contractType !== undefined
          ? refSearchQuery.contractType
          : ""
        : refFilterResultForm.contractType.concatValue;
    let employmentType =
      refFilterResultForm.employmentType.concatValue === ""
        ? refSearchQuery.employmentType !== "" &&
          refSearchQuery.employmentType !== undefined
          ? refSearchQuery.employmentType
          : ""
        : refFilterResultForm.employmentType.concatValue;
    let salaryRange =
      refFilterResultForm.salary.concatValue === ""
        ? refSearchQuery.salaryRange !== "" &&
          refSearchQuery.salaryRange !== undefined
          ? refSearchQuery.salaryRange
          : ""
        : refFilterResultForm.salary.concatValue;
    let payload2 = {
      QueryString:
        keywords +
        country +
        jobLocation +
        distanceFromLocation +
        companyName +
        salaryRange +
        contractType +
        employmentType +
        " documentdate:>=today-42",
      country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programCountryCode"
      ],
      SearchAfterScore: "",
      SearchAfter: "",
      SearchAfterDocid: "",
      SearchAfter2: "",
      language: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programLanguageCode"
      ],
      OwnerId: candidateID,
      isFlagsRequired: true,
      field: sortByValue,
      order: "DESCENDING",
    };

    let selectedCountry = refSearchQuery.country.split(":")[1];
    getAllSearchedjobs(payload2, false, selectedCountry);
  };

  const clearAllFilterHandler = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));

    refFilterResultForm.company.value = "";
    refFilterResultForm.company.concatValue = "";
    refFilterResultForm.salary.value = [];
    refFilterResultForm.salary.concatValue = "";
    refFilterResultForm.contractType.value = [];
    refFilterResultForm.contractType.concatValue = "";
    refFilterResultForm.employmentType.value = [];
    refFilterResultForm.employmentType.concatValue = "";
    refFilterResultForm.applyButtonStatus = true;
    setFilterResultForm(refFilterResultForm);

    if (refFilterResultForm.isFilterApplied) {
      refFilterResultForm.applyButtonStatus = true;
      refFilterResultForm.isFilterApplied = false;
      setFilterResultForm(refFilterResultForm);
      let keywords =
        refSearchQuery.keyWord !== "" && refSearchQuery.keyWord !== undefined
          ? refSearchQuery.keyWord
          : "";
      let country =
        refSearchQuery.country !== "" && refSearchQuery.country !== undefined
          ? refSearchQuery.country
          : "";
      let jobLocation =
        refSearchQuery.location !== "" && refSearchQuery.location !== undefined
          ? refSearchQuery.location
          : "";
      let distanceFromLocation =
        refSearchQuery.distanceFromLocation !== "" &&
        refSearchQuery.distanceFromLocation !== undefined
          ? refSearchQuery.distanceFromLocation
          : "";
      let companyName =
        refSearchQuery.companyName !== "" &&
        refSearchQuery.companyName !== undefined
          ? refSearchQuery.companyName
          : "";
      let contractType =
        refSearchQuery.contractType !== "" &&
        refSearchQuery.contractType !== undefined
          ? refSearchQuery.contractType
          : "";
      let employmentType =
        refSearchQuery.employmentType !== "" &&
        refSearchQuery.employmentType !== undefined
          ? refSearchQuery.employmentType
          : "";
      let salaryRange =
        refSearchQuery.salaryRange !== "" &&
        refSearchQuery.salaryRange !== undefined
          ? refSearchQuery.salaryRange
          : "";
      let payload = {
        QueryString:
          keywords +
          country +
          jobLocation +
          distanceFromLocation +
          companyName +
          salaryRange +
          contractType +
          employmentType +
          " documentdate:>=today-42",
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        SearchAfterScore: "",
        SearchAfter: "",
        SearchAfterDocid: "",
        SearchAfter2: "",
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
        OwnerId: candidateID,
        isFlagsRequired: true,
        field: sortByValue,
        order: "DESCENDING",
      };
      let selectedCountry = refSearchQuery.country.split(":")[1];
      getAllSearchedjobs(payload, false, selectedCountry);
    }
  };
  // End Filter result

  // Start Some common functionality
  const getManpowerLogo = (key) => {
    let imageList =
      jobCardCMSData &&
      jobCardCMSData.pageContent &&
      jobCardCMSData.pageContent.ImageList &&
      jobCardCMSData.pageContent.ImageList;
    let imageURL = "";
    if (imageList) {
      imageList.map((x) => {
        if (x.Key === key) {
          imageURL = x.ImageURL;
        }
      });
    }
    return imageURL;
  };

  const getSalary = (salary, frequency, country) => {
    let currencyCode = countryToCurrency[country];
    const currencySymbol = getCurrencySymbol(country, currencyCode);
    let formattedSalary =
      salary && frequency ? currencySymbol + salary + " " + frequency : "";
    return formattedSalary;
  };

  function getCurrencySymbol(locale, currency) {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }

  const getAllSearchedjobs = async (payload, loadMoreFlag, country) => {
    if (country === "UK") {
      country = "GB";
    }
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refPaginationState = JSON.parse(JSON.stringify(paginationState));
    if (!loadMoreFlag) {
      setJobLoader(true);
    }
    let allJobsList = [];
    let allSaveJobList = [...savedjobs];
    let allAppliedJobList = [...appliedjobs];
    let paginationPayload = {};
    await JobActions.GetSerchedjobs(payload).then(
      (res) => {
        if (res.status === 200) {
          if (res?.data[0].search_info.result_count > 0) {
            res?.data?.map((job) => {
              const matchPercent = job?.score?.score * 100;
              let compName = job?.organisation?.organisation_name;
              let jobObj = {
                companyName: job?.organisation?.organisation_name,
                contractType: job?.contract_type?.contract_type,
                description: job?.job_overview?.job_description,
                id: job?.job_overview?.jobid,
                isComparable: true,
                isFeaturedText: "Featured listing",
                isMatchText: fetchJobCardCMSData(
                  "JobSearch.JobCard.StrongMatch"
                ),
                isSaveable: true,
                isSaved: false,
                jobRole: job?.job_overview?.jobtitle,
                location: job?.job_location?.city,
                onClick: {},
                onComparingButtonClick: {},
                onSaveButtonClick: {},
                posted: convertPostedDate(job?.job_overview?.job_posted_date),
                postedTimeStamp: job?.job_overview?.job_posted_date,
                // postedTimeStamp: globalDateFormat(job?.job_overview?.job_posted_date),
                jobUrl: job?.job_overview?.job_url,
                salary:
                  job?.salary?.maximum_value !== undefined &&
                  job?.salary?.maximum_value !== ""
                    ? getSalary(
                        job?.salary?.maximum_value,
                        job?.salary?.frequency,
                        country
                      )
                    : "",
                lastUpdatedDate:
                  job && job.job_overview && job.job_overview.job_last_updated
                    ? job.job_overview.job_last_updated
                    : null,
                applicationDeadline:
                  job &&
                  job.job_overview &&
                  job.job_overview.application_deadline
                    ? globalDateFormat(job.job_overview.application_deadline)
                    : null,
                startDate:
                  job && job.job_overview && job.job_overview.job_start_date
                    ? globalDateFormat(job.job_overview.job_start_date)
                    : "Flexible Start",
                matchPercentage: job?.score?.score,
                staticCopy: {
                  CollapseJobInfo: "Hide job info",
                  CompanyName: "Company",
                  Compare: fetchCMSData(
                    "JobSearch.CompareJobs",
                    "Job.JobSearch.CompareJobs.Compare"
                  ),
                  ExpandJobInfo: "View job info",
                  IsCompared: "Compare this job",
                  IsFeatured: "This role is featured",
                  IsNotCompared: "Remove this job from comparison",
                  IsSaved: "Unsave this job",
                  IsUnsaved: "Save this job",
                  JobDescription: "Job Description",
                  JobInfo: fetchJobCardCMSData(
                    "JobSearch.JobCard.JobCardMoreInfo"
                  ),
                  JobRole: "Role",
                  JobType: "Type:",
                  Location: "Location",
                  Salary: "Salary:",
                  Save: fetchJobCardCMSData("JobSearch.JobCard.Save"),
                  StartDate: "StartDate",
                },
              };
              if (matchPercent >= 70) {
                jobObj = {
                  ...jobObj,
                  matchPercentage: matchPercent,
                };
              }
              if (compName && compName.includes("Manpower")) {
                jobObj = {
                  ...jobObj,
                  companyLogo: {
                    src: getManpowerLogo("Manpower Logo"),
                    altText: "",
                  },
                };
              }
              allJobsList.push(jobObj);
            });

            //when "field": "documentdate", then search_after_2 = search_after
            //when "field": "_score", then search_after_2 = search_after_score
            let searchAfter2 = "";
            if (sortByValue == "_score")
              searchAfter2 = res?.data[0].search_info.search_after_score;
            else if (sortByValue == "documentdate")
              searchAfter2 = res?.data[0].search_info.search_after;
            console.log(
              "getAllSearchedjobs, searchAfter2 and sortByValue : " +
                searchAfter2 +
                " , " +
                sortByValue
            );

            paginationPayload = {
              QueryString: payload.QueryString,
              country: payload.country,
              SearchAfterScore: res?.data[0].search_info.search_after_score,
              SearchAfter: res?.data[0].search_info.search_after,
              SearchAfterDocid: res?.data[0].search_info.search_after_docid,
              SearchAfter2: searchAfter2,
              language: payload.language,
              OwnerId: payload.OwnerId,
              field: sortByValue,
              order: "DESCENDING",
            };
            let searchKeywork = payload?.QueryString.split(" ")[0];
            refPaginationState.searchCountAndLabel = fetchCMSData(
              "JobSearch.SearchList",
              "Job.JobSearch.SearchList.ShowingResults"
            );
            const jobResultCount = res?.data[0].search_info.result_count;
            refPaginationState.searchCountAndLabel =
              refPaginationState.searchCountAndLabel
                .replace("#COUNT#", numberWithCommas(jobResultCount))
                .replace("#JOB", searchKeywork);
            refPaginationState.totalCounts =
              res?.data[0].search_info.result_count;
          } else {
            allJobsList = [];
            setFetchedJobs([]);
            let searchKeywork = payload?.QueryString.split(" ")[0];
            refPaginationState.searchCountAndLabel = fetchCMSData(
              "JobSearch.SearchList",
              "Job.JobSearch.SearchList.ShowingResults"
            );
            const jobResultCount = res?.data[0].search_info.result_count;
            refPaginationState.searchCountAndLabel =
              refPaginationState.searchCountAndLabel
                .replace("#COUNT#", numberWithCommas(jobResultCount))
                .replace("#JOB", searchKeywork);
            refPaginationState.totalCounts =
              res?.data[0].search_info.result_count;
          }
        } else {
          setJobLoader(false);
          paginationPayload = {
            QueryString: payload.QueryString,
            country: payload.country,
            SearchAfterScore: "",
            SearchAfter: "",
            SearchAfterDocid: "",
            SearchAfter2: "",
            language: payload.language,
            OwnerId: payload.OwnerId,
            field: sortByValue,
            order: "DESCENDING",
          };
          refPaginationState.payload = paginationPayload;
          setPaginationState(refPaginationState);
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
    if (allJobsList.length > 0 && !loadMoreFlag) {
      await JobActions.GetSavedJobs(candidateID).then(
        (resp) => {
          if (resp.status === 200) {
            resp?.data.map((ele1) => {
              ele1["id"] = ele1.jobId;
            });
            allSaveJobList = resp?.data;
            setSavedjobs(resp?.data);
          } else {
            setJobLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setJobLoader(false);
        }
      );
      await JobActions.GetAppliedJobs().then(
        (resp) => {
          if (resp.status === 200) {
            resp?.data.map((ele1) => {
              ele1["id"] = ele1.jobId;
            });
            allAppliedJobList = resp?.data;
            setAppliedjobs(resp?.data);
          } else {
            setJobLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setJobLoader(false);
        }
      );
    }

    let jobsWithSaveStatus = [];
    let allSavedJobsID = [];
    allSaveJobList?.map((allSaved) => {
      allSavedJobsID.push(allSaved.id);
    });
    jobsWithSaveStatus = allJobsList.map((ele1) => {
      if (allSavedJobsID.includes(ele1.id)) {
        ele1.isSaved = true;
        ele1.staticCopy.Save = fetchJobCardCMSData(
          "JobSearch.JobCard.SavedButton"
        );
      }
      return ele1;
    });

    let allAppliedJobsID = [];
    allAppliedJobList?.map((allApplied) => {
      allAppliedJobsID.push(allApplied.id);
    });
    jobsWithSaveStatus = allJobsList.map((ele1) => {
      if (allAppliedJobsID.includes(ele1.id)) {
        ele1["isApplied"] = true;
      } else {
        ele1["isApplied"] = false;
      }
      return ele1;
    });

    if (loadMoreFlag) {
      refPaginationState.fetchedCounts =
        refPaginationState.fetchedCounts + jobsWithSaveStatus.length;
      refPaginationState.shownCounts =
        jobsWithSaveStatus.length > 0 ? refPaginationState.shownCounts + 5 : 0;
      refPaginationState.payload =
        jobsWithSaveStatus.length > 0 ? paginationPayload : {};
      setPaginationState(refPaginationState);
      setFetchedJobs(
        jobsWithSaveStatus.length > 0
          ? [...fetchedJobs, ...jobsWithSaveStatus]
          : []
      );
      let sc =
        jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus.slice(0, 5) : [];
      setShownJobs(jobsWithSaveStatus.length > 0 ? [...shownJobs, ...sc] : []);
    } else {
      refPaginationState.fetchedCounts = jobsWithSaveStatus.length;
      refPaginationState.shownCounts = jobsWithSaveStatus.length > 0 ? 5 : 0;
      refPaginationState.payload =
        jobsWithSaveStatus.length > 0 ? paginationPayload : {};
      setPaginationState(refPaginationState);
      setFetchedJobs(jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus : []);
      let sc =
        jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus.slice(0, 5) : [];
      setShownJobs(jobsWithSaveStatus.length > 0 ? sc : []);
    }
    setJobLoader(false);
    setSavedSearchSearchButton(false);
  };

  const loadMoreJobHandler = () => {
    let refPaginationState = JSON.parse(JSON.stringify(paginationState));
    if (refPaginationState.shownCounts >= refPaginationState.fetchedCounts) {
      //when "field": "documentdate", then search_after_2 = search_after
      //when "field": "_score", then search_after_2 = search_after_score
      let searchAfter2 = "";
      if (sortByValue == "_score")
        searchAfter2 = refPaginationState.payload.SearchAfterScore;
      else if (sortByValue == "documentdate")
        searchAfter2 = refPaginationState.payload.SearchAfter;
      console.log(
        "loadMoreJobHandler, searchAfter2 and sortByValue : " +
          searchAfter2 +
          " , " +
          sortByValue
      );

      let payload = {
        QueryString: refPaginationState.payload.QueryString,
        country: refPaginationState.payload.country,
        SearchAfterScore: refPaginationState.payload.SearchAfterScore,
        SearchAfter: refPaginationState.payload.SearchAfter,
        SearchAfterDocid: refPaginationState.payload.SearchAfterDocid,
        SearchAfter2: searchAfter2,
        language: refPaginationState.payload.language,
        OwnerId: refPaginationState.payload.OwnerId,
        field: sortByValue,
        order: "DESCENDING",
      };
      refPaginationState.desabledLoadMore = true;
      setPaginationState(refPaginationState);

      let spltVal = refPaginationState.payload.QueryString.split(" ");
      let spltValString = spltVal
        .filter((item) => item.includes("jf_source_country"))
        .toString();
      let selectedCountry = spltValString.split(":")[1];
      getAllSearchedjobs(payload, true, selectedCountry);
    } else {
      let refFetchedJobs = JSON.parse(JSON.stringify(fetchedJobs));
      let sc = refFetchedJobs.slice(
        refPaginationState.shownCounts,
        refPaginationState.shownCounts + 5
      );
      setShownJobs([...shownJobs, ...sc]);
      refPaginationState.shownCounts = refPaginationState.shownCounts + 5;
      setPaginationState(refPaginationState);
    }
  };

  const saveJobHandler = async (job) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    const savedJobsId = savedjobs && savedjobs.map((job) => job.id);
    const inputJobId = job && job.id && job.id;
    let saveJobPayload = {
      OwnerId: candidateID,
      OwnerType: "Candidate",
      JobId: job?.id,
      JobTitle: job?.jobRole != undefined ? job?.jobRole : "",
      CompanyName: job?.companyName != undefined ? job?.companyName : "",
      JobUrl: job?.jobUrl != undefined ? job?.jobUrl : "",
      IsFeaturedJob: 1,
      IsSaved: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsBookmarked: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsDeleted: 0,
      CreatedBy: "Admin",
      MatchPercentage: job?.matchPercent != undefined ? job?.matchPercent : "",
      JobLocation: job?.location != undefined ? job?.location : "",
      Salary: job?.salary != undefined ? job?.salary : "",
      EmploymentType: job?.contractType != undefined ? job?.contractType : "",
      UpdatedBy: "",
      JobDescription: job?.description != undefined ? job?.description : "",

      JobPostedTimeStamp:
        job?.postedTimeStamp != undefined
          ? Moment(job?.postedTimeStamp).format("MM-DD-YYYY")
          : "",
      LastSavedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      // "JobStartTimeStamp": job?.startDate === "Flexible Start" ? null : job?.startDate,
      CreatedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      LastUnsavedTimeStamp: savedJobsId.includes(inputJobId)
        ? Moment(new Date()).format("MM-DD-YYYY")
        : null,
      UpdatedTimeStamp:
        job?.lastUpdatedDate != undefined
          ? Moment(job?.lastUpdatedDate).format("MM-DD-YYYY")
          : "",
    };
    await JobActions.PostSaveJob(candidateID, saveJobPayload).then(
      (resp) => {
        if (resp.status === 200) {
          refShownJobs.map((x) => {
            if (x.id === inputJobId) {
              if (savedJobsId.includes(x.id)) {
                x.isSaved = false;
                x.staticCopy.Save = fetchJobCardCMSData(
                  "JobSearch.JobCard.Save"
                );
                var filtered = savedjobs.filter(function (job) {
                  return job.id != x.id;
                });
                setSavedjobs(filtered);
              } else {
                x.isSaved = true;
                x.staticCopy.Save = fetchJobCardCMSData(
                  "JobSearch.JobCard.SavedButton"
                );
                savedjobs.push(x);
                setSavedjobs(savedjobs);
              }
            }
            return x;
          });
          setShownJobs(refShownJobs);
        } else {
          setJobLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
  };
  const convertPostedDate = (date) => {
    let postDate = date && date;
    let todayDate = new Date();
    postDate = Moment(postDate).format("MM-DD-YYYY");
    todayDate = Moment(todayDate).format("MM-DD-YYYY");
    function parseDate(str) {
      var date = str && str.split("-");
      return new Date(date && date[2], date && date[0] - 1, date && date[1]);
    }
    function dateDiff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    let posted_Date = dateDiff(parseDate(postDate), parseDate(todayDate));
    let postedDate;
    if (posted_Date < 1) {
      // postedDate = fetchJobCardCMSData("JobSearch.JobCard.Hoursago").replace(
      //   "#TIME#",
      //   posted_Date
      // );
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.Today");
    } else if (posted_Date >= 1 && posted_Date <= 30) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.Day").replace(
        "#TIME#",
        posted_Date
      );
    } else if (posted_Date > 30 && posted_Date <= 60) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.1Month").replace(
        "#TIME#",
        posted_Date - 30
      );
    } else if (posted_Date > 60 && posted_Date <= 90) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.2Month").replace(
        "#TIME#",
        posted_Date - 60
      );
    } else if (posted_Date > 90 && posted_Date <= 180) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.3Month");
    } else if (posted_Date > 180) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.6Month");
    }
    postDate =
      fetchJobCardCMSData("JobSearch.JobCard.Posted") + " " + postedDate;
    return postDate;
  };
  const returnCountry = (country, defaultISOcode) => {
    if (country.length < 0) {
      return;
    }
    let refCountry = country;
    let extractProgramCountryFromCountryLists = {
      name: "",
      value: "",
    };
    if (defaultISOcode === "uk") {
      defaultISOcode = "gb";
    }
    refCountry.filter((pc) => {
      if (pc.value === defaultISOcode) {
        extractProgramCountryFromCountryLists = {
          name: pc.name,
          value: pc.value,
        };
      }
    });
    let a = refCountry.filter((ft) => ft.value !== defaultISOcode);
    a.unshift(extractProgramCountryFromCountryLists);
    return a;
  };
  const returnDistanceValues = (gList, gValue) => {
    let refValue = gList;
    let extractProgramCountryFromCountryLists = {
      name: "",
      value: "",
    };
    refValue.filter((pc) => {
      if (pc.value === gValue) {
        extractProgramCountryFromCountryLists = {
          name: pc.name,
          value: pc.value,
        };
      }
    });
    let a = refValue.filter((ft) => ft.value !== gValue);
    a.unshift(extractProgramCountryFromCountryLists);
    return a;
  };

  const returnMultiSelectValues = (gList, gValue) => {
    let refValue = gList;
    let returnValue = [];
    let splt = gValue.split(",");

    splt.map((gval) => {
      if (gval !== "") {
        refValue.map((rVal) => {
          if (gval.replace(" ", "") === rVal.value) {
            returnValue.push(rVal);
          }
        });
      }
    });
    return returnValue;
  };

  const returnMultiSelectValuesWithPrefix = (gList, gValue, flag) => {
    let refValue = gList;
    let returnValue = "";
    let splt = gValue.split(",");

    splt.map((gval) => {
      if (gval !== "") {
        refValue.map((rVal) => {
          if (gval.replace(" ", "") === rVal.value) {
            if (flag === "salary") {
              if (rVal.value.includes("-")) {
                let splt = rVal.value.replace(/ /g, "").split("-");
                returnValue =
                  returnValue +
                  " " +
                  "^salary_minimum:" +
                  splt[0] +
                  " " +
                  "^salary_maximum:" +
                  splt[1];
              } else {
                if (rVal.value.includes("Above")) {
                  let rep = rVal.value.replace("Above", "");
                  returnValue = returnValue + " " + "^salary_minimum:" + rep;
                } else {
                  let rep = rVal.value.replace("Below", "");
                  returnValue = returnValue + " " + "^salary_maximum:" + rep;
                }
              }
            }
            if (flag === "employmentType") {
              returnValue =
                returnValue +
                " " +
                "^employment_type_code:" +
                '"' +
                rVal.value +
                '"';
            }
            if (flag === "contractType") {
              returnValue =
                returnValue +
                " " +
                "^contract_type_code:" +
                '"' +
                rVal.value +
                '"';
            }
          }
        });
      }
    });
    return returnValue;
  };
  // End Some common functionality

  // Start search for jobs props
  const jobSearchProps = {
    heading: fetchCMSData(
      "JobSearch.SearchJobBox",
      "Job.JobSearch.SearchJobBox.SearchForJobs"
    ),
    ariaLabel: "On this page",
    searchButtonProps: {
      children: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.SearchBtn"
      ),
      size: "Small",
      type: "submit",
      disabled: searchJobsForm.searchButtonStatus,
    },
    searchInputProps: {
      hideLabel: true,
      label: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.SearchBtn"
      ),
      name: "search",
      type: "text",
      placeholder: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchjobBox.SearchBoxPlaceholder"
      ),
      icon: "search",
      id: "search",
      onChange: (event) => searchInputHandler(event),
    },

    countrySelectProps: {
      label: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.Country"
      ),
      name: "Country",
      id: "country",
      options: jobSearchCountryLists,
      defaultValue: jobSearchCountryLists[0],
      onChange: (event) => countrySelectHandler(event),
    },
    locationInputProps: {
      errorMessage: cityListError.errorMessage,
      state: cityListError.state,
      inputId: "location",
      inputLabel: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.Location"
      ),
      inputName: "location",
      inputValue: jobSearchCityInput,
      inputPlaceholder: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.Select"
      ),
      suggestionsItem: jobSearchCityLists,
      onChangeHandler: getAllLocationLists,
      selectItemHandler: locationSelectHandler,
    },
    distanceSelectProps: {
      label: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.DistanceFromLocation"
      ),
      name: "distance",
      id: "distance",
      options: masterDistance,
      placeholder: fetchCMSData(
        "JobSearch.SearchJobBox",
        "Job.JobSearch.SearchJobBox.Select"
      ),
      onChange: (event) => distanceSelectHandler(event),
    },
    onSubmit: (event) => searchHandler(event),
  };
  // End search for jobs props

  // Start Use your saved wigets
  const savedSearchProps = {
    heading: fetchCMSData(
      "JobSearch.YourSavedSearch",
      "Job.JobSearch.YourSavedSearch.SavedSearch"
    ),
    bodyText: fetchCMSData(
      "JobSearch.YourSavedSearch",
      "Job.JobSearch.YourSavedSearch.SearchResult"
    ),
    unselectedSearchOptionText: fetchCMSData(
      "JobSearch.YourSavedSearch",
      "Job.JobSearch.YourSavedSearch.Select"
    ),
    editOptionText: "Edit this saved search",
    searchButtonProps: {
      children: fetchCMSData(
        "JobSearch.YourSavedSearch",
        "Job.JobSearch.YourSavedSearch.SearchBtn"
      ),
      size: "Small",
      type: "submit",
      isSecondary: true,
      isAlt: true,
      disabled: savedSearchSearchButton,
    },
    searchOptions: savedSearchLists.length > 0 ? savedSearchLists : [],
    onSavedSearchSelectChanged: (event, savedSearch) =>
      onChangeSaveSearchDropdownHandler(event, savedSearch),
    onSavedSearchEdit: (event, savedSearch) =>
      savedSearchEditHandler(event, savedSearch),
    onFormSubmit: (event, savedSearch) =>
      fromSaveSearchSearchJobHandler(event, savedSearch),
  };
  // End Use your saved wigets

  const searchFilterFormProps = {
    salarySelectProps: {
      id: filterResultForm.salary.name,
      name: filterResultForm.salary.name,
      placeholder: !isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.SalaryRange"
          )
        : "",
      options: masterSalaryRange,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.salary.name),
      //selectedValue: filterResultForm.salary.value,
      selectedValue: editSearchSalaryRange,
      disableSearch: true,
      hasSelectAll: false,
      label: isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.SalaryRange"
          )
        : "",
    },
    companySearchSelectProps: {
      name: filterResultForm.company.name,
      id: filterResultForm.company.name,
      placeholder: !isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.Company"
          )
        : "",
      value: filterResultForm.company.value,
      label: isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.Company"
          )
        : "",
      onChange: (event) =>
        filterFormHandler(event, filterResultForm.company.name),
    },
    contractSelectProps: {
      id: filterResultForm.contractType.name,
      name: filterResultForm.contractType.name,
      placeholder: !isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.ContractType"
          )
        : "",
      label: isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.ContractType"
          )
        : "",
      options: masterContractType,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.contractType.name),
      //selectedValue: filterResultForm.contractType.value,
      selectedValue: editSearchContractType,
      disableSearch: true,
      hasSelectAll: false,
    },
    timeSelectProps: {
      id: filterResultForm.employmentType.name,
      name: filterResultForm.employmentType.name,
      placeholder: !isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.EmploymentType"
          )
        : "",
      label: isMobile
        ? fetchCMSData(
            "JobSearch.RefineSearch",
            "Job.JobSearch.RefineSearch.EmploymentType"
          )
        : "",
      options: masterEmploymentType,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.employmentType.name),
      //selectedValue: filterResultForm.employmentType.value,
      selectedValue: editSearchEmploymentType,
      disableSearch: true,
      hasSelectAll: false,
    },
    applyFiltersButtonProps: {
      children: fetchCMSData(
        "JobSearch.RefineSearch",
        "Job.JobSearch.RefineSearch.ApplyButton"
      ),
      size: "Large",
      type: "button",
      onClick: applyFilterHandler,
      disabled: filterResultForm.applyButtonStatus,
    },
    resetFiltersLinkButtonProps: {
      children: fetchCMSData(
        "JobSearch.RefineSearch",
        "Job.JobSearch.RefineSearch.ClearAllFilters"
      ),
      type: "reset",
      onClick: clearAllFilterHandler,
    },
    onSubmit: (event) => {
      alert("form submitted");
    },
  };

  // Start Save this search props
  const saveSearchFormProps = {
    heading: fetchCMSData(
      "JobSearch.SaveJobSearch",
      "Job.JobSearch.SaveJobSearch.SaveSearch"
    ),
    nameInputProps: {
      label: fetchCMSData(
        "JobSearch.SaveJobSearch",
        "Job.JobSearch.SaveJobSearch.NameYourSearch"
      ),
      name: "SearchName",
      id: "search-name",
      type: "text",
      maxLength: 20,
      state: saveThisSearchForm?.isError,
      errorMessage: saveThisSearchForm?.minLetters
        ? fetchCMSData(
            "JobSearch.SaveJobSearch",
            "Job.JobSearch.SaveJobSearch.MinCharError"
          )
        : saveThisSearchForm?.nameExists
        ? fetchCMSData(
            "JobSearch.SaveJobSearch",
            "Job.JobSearch.SaveJobSearch.JobTitleValidation"
          )
        : "",
      onChange: saveSearchInputHandler,
    },
    submitButtonProps: {
      children: fetchCMSData(
        "JobSearch.SaveJobSearch",
        "Job.JobSearch.SaveJobSearch.Save"
      ),
      size: "Large",
      type: "submit",
      disabled: saveThisSearchForm.disabledSaveButton,
      onClick: (event) => {
        saveThisSearchHandler(
          event,
          document.getElementById("search-name").value,
          event
        );
      },
    },
    cancelButtonProps: {
      children: fetchCMSData(
        "JobSearch.SaveJobSearch",
        "Job.JobSearch.SaveJobSearch.Cancel"
      ),
      size: "Large",
      isSecondary: true,
      isAlt: true,
      type: "button",
      onClick: () => {
        setIsSaveSearchOpen(!isSaveSearchOpen);
      },
    },
  };
  // End Save this search props

  // Start for showing the JD
  const [isJobDetailsHide, setIsJobDetailsHide] = useState(false);
  const [selectJobForDetails, setSelectJobForDetails] = useState([]);
  const JDclickedId = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    let selectForJD = refShownJobs.filter((item) => {
      return item.id === id;
    });
    setSelectJobForDetails(selectForJD);
    window.scrollTo(0, 0);
    setIsJobDetailsHide(true);
    JDshowHide(true);
  };
  const jobSaveFromJDpageFlag = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    const savedJobsId = savedjobs && savedjobs.map((job) => job.id);
    refShownJobs.map((x) => {
      if (x.id === id) {
        if (savedJobsId.includes(id)) {
          x.isSaved = false;
          x.staticCopy.Save = fetchJobCardCMSData("JobSearch.JobCard.Save");
          var filtered = savedjobs.filter(function (job) {
            return job.id != x.id;
          });
          setSavedjobs(filtered);
        } else {
          x.isSaved = true;
          x.staticCopy.Save = fetchJobCardCMSData(
            "JobSearch.JobCard.SavedButton"
          );
          savedjobs.push(x);
          setSavedjobs(savedjobs);
        }
      }
      return x;
    });
    setShownJobs(refShownJobs);
  };

  const appliedJobFromJDpageFlag = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    refShownJobs?.map((ele1) => {
      if (ele1.id === id) {
        ele1.isApplied = true;
      }
      return ele1;
    });
    setShownJobs(refShownJobs);
  };

  const jobDetailsBackFlag = () => {
    setIsJobDetailsHide(false);
    JDshowHide(false);
  };
  // End for showing the JD

  /*Start Compare jobs */
  const [isJobCompareModalVisible, setIsJobCompareModalVisible] =
    useState(false);
  const [selectedCompareJobs, setSelectedCompareJobs] = useState([]);
  const [isCompareJobCardChecked, setIsCompareJobCardChecked] = useState(false);
  const [jobCardID, setJobCardID] = useState();
  let arrSelectedCompareJobs = selectedCompareJobs;

  const closeCompareModal = () => {
    setIsJobCompareModalVisible(false);
  };
  const compareJobHandler = (job) => {
    setJobCardID(job.id);
    setIsCompareJobCardChecked(true);
    let compareDescription = [
      {
        sectionBody: "",
        sectionHeading: "",
      },
    ];
    if (arrSelectedCompareJobs.length) {
      if (arrSelectedCompareJobs.find((x) => x.id === job.id)) {
        arrSelectedCompareJobs = arrSelectedCompareJobs.filter(
          (filt) => filt.id !== job.id
        );
      } else {
        if (arrSelectedCompareJobs.length <= 4) {
          arrSelectedCompareJobs.push(job);

          let updateCompare = arrSelectedCompareJobs.map((selectedJobs) => {
            compareDescription[0].sectionBody = selectedJobs?.description
              ? selectedJobs?.description
              : "";
            compareDescription[0].sectionHeading =
              selectedJobs.staticCopy.JobDescription;

            return {
              ...selectedJobs,
              description: selectedJobs?.description,
              location: selectedJobs.location,
              salary: selectedJobs.salary,
              datePosted: selectedJobs.postedTimeStamp,
              languageSkills: selectedJobs.languageSkills,
              experience: selectedJobs.experience,
              education: selectedJobs.education,
              startDate: selectedJobs.startDate,
              deadline: selectedJobs.applicationDeadline,
            };
          });
          arrSelectedCompareJobs = updateCompare;
        }
      }
    } else {
      arrSelectedCompareJobs.push(job);
    }
    setSelectedCompareJobs(arrSelectedCompareJobs);
  };

  const handleClearCompare = () => {
    setIsCompareJobCardChecked(false);
    setSelectedCompareJobs([]);
  };

  const maxCompareCard = fetchCMSData(
    "JobSearch.CompareJobs",
    "Job.JobSearch.CompareJobs.MaximumJobsToCompare"
  ).replace("$JobCount$", 5);
  const selectedCompareCards = fetchCMSData(
    "JobSearch.CompareJobs",
    "Job.JobSearch.CompareJobs.CountOfJobsSelected"
  )
    .replace("$JobCount$", 5)
    .replace("$SelectedJobs$", arrSelectedCompareJobs.length);
  /* End Compare jobs */

  // tushar onchange method
  const sortByFilter = (option) => {
    //console.log('inside sortByFilter');
    //console.log(event.target.value);
    //setDefaultSortOption(event.target.value);
    //console.log(event.value + ' , ' + event.label);
    if (option.value === "Relevance") setSortByValue("_score");
    else if (option.value === "Newest") setSortByValue("documentdate");
    //console.log('end of sortByFilter, value of sortByValue : '+sortByValue);
    //alert('end of sortfilter, value of sortByValue : '+sortByValue);
    //searchHandler(event);
  };
  return (
    <div className="searchTab">
      <div
        style={
          isJobDetailsHide === true
            ? { height: 0, overflow: "hidden" }
            : { height: "100%", overflow: "visible" }
        }
      >
        <Cirrus.OneColumnLayout type="wrapped">
          <Cirrus.JobSearchLayout>
            <Cirrus.JobSearchLayout.GridCell gridArea="search">
              {quickSearchFlag === true ? (
                ""
              ) : (
                <Cirrus.JobSearch {...jobSearchProps} className="search-box" />
              )}
            </Cirrus.JobSearchLayout.GridCell>
            <Cirrus.JobSearchLayout.GridCell gridArea="sidebar">
              <Cirrus.SavedSearch {...savedSearchProps} />
            </Cirrus.JobSearchLayout.GridCell>
            <Cirrus.JobSearchLayout.GridCell gridArea="filters">
              <div className="filter-actions">
                <Cirrus.Button
                  size="Large"
                  leadingIcon={isFilterOpen ? "close" : "filter_alt"}
                  isAlt={true}
                  isSecondary={true}
                  onClick={() => {
                    setIsSaveSearchOpen(false);
                    setIsFilterOpen(!isFilterOpen);
                  }}
                  className={
                    isFilterOpen
                      ? "filter-button filter-button_filter is-active"
                      : "filter-button filter-button_filter"
                  }
                >
                  {fetchCMSData(
                    "JobSearch.RefineSearch",
                    "Job.JobSearch.RefineSearch.Filters"
                  )}
                </Cirrus.Button>
                <Cirrus.Button
                  size="Large"
                  leadingIcon={isSaveSearchOpen ? "close" : "save"}
                  isAlt={true}
                  isSecondary={true}
                  onClick={() => {
                    setIsFilterOpen(false);
                    setIsSaveSearchOpen(!isSaveSearchOpen);
                  }}
                  className={
                    isSaveSearchOpen
                      ? "filter-button is-active"
                      : "filter-button"
                  }
                  ref={setSaveSearchReferenceElement}
                >
                  {fetchCMSData(
                    "JobSearch.SaveJobSearch",
                    "Job.JobSearch.SaveJobSearch.SaveThisSearch"
                  )}
                </Cirrus.Button>
                <Cirrus.Button
                  size="Large"
                  leadingIcon={isCompareJobsOpen ? "close" : "compare"}
                  isAlt={true}
                  isSecondary={true}
                  onClick={() => {
                    setIsCompareJobsOpen(!isCompareJobsOpen);
                  }}
                  className={
                    isCompareJobsOpen
                      ? "filter-button is-active"
                      : "filter-button"
                  }
                  ref={setCompareJobsButtonRef}
                >
                  {fetchCMSData(
                    "JobSearch.CompareJobs",
                    "Job.JobSearch.CompareJobs.CompareJobs"
                  )}
                </Cirrus.Button>
                <div className="dropdown-button" ref={dropdownRef}>
                  <Cirrus.Button
                    size="Large"
                    leadingIcon={isSortOpen ? "close" : "filter_list"}
                    isAlt={true}
                    isSecondary={true}
                    // onClick={() => {
                    //   setIsSortOpen(!isSortOpen);
                    // }}
                    onClick={toggleDropdown}
                    className={
                      isSortOpen
                        ? "filter-button filter-button_filter is-active"
                        : "filter-button filter-button_filter"
                    }
                  >
                    {/* {fetchCMSData(
                        "JobSearch.RefineSearch",
                        "Job.JobSearch.RefineSearch.Filters"
                      )} */}
                    {selectedOption?.name}
                  </Cirrus.Button>
                  <div
                    className={`drop-down-content ${
                      dropdownOpen ? "open" : ""
                    }`}
                  >
                    {sortByValues.map((option) => (
                      <button
                        key={option.value}
                        className="dropdown-item"
                        onClick={() => handleSelect(option)}
                      >
                        {option.name}{" "}
                        {selectedOption.value === option.value && (
                          <span>✓</span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {/* {isSortOpen && (
                  <div
                  style={{
                    padding: '0'
                  }}
                >
                    <Cirrus.Select
                      onChange= {sortByFilter}
                      options= {sortByValues}
                      value={defaultSortOption}
                      //placeholder="-- Please select --"
                    />
                </div>
                  )} */}

              {isFilterOpen && !isMobile && (
                <Cirrus.SearchFiltersPopup
                  className="filterResult job-filterResult"
                  searchFiltersFormProps={searchFilterFormProps}
                />
              )}

              {isFilterOpen && isMobile && (
                <Cirrus.Modal
                  size="mobile"
                  className="jobSearch-filter_mobile"
                  closeButtonProps={{
                    type: "button",
                    isSecondary: false,
                    size: "small",
                    //children: "X Close Filters",
                    children: "X " + fetchCMSData(
                      "JobSearch.RefineSearch",
                      "Job.JobSearch.RefineSearch.CloseFilters"
                    ),
                    onClick: () => setIsFilterOpen(!isFilterOpen),
                  }}
                >
                  <Cirrus.SearchFiltersPopup
                    className="filterResult job-filterResult job-filterResult-mobile"
                    searchFiltersFormProps={searchFilterFormProps}
                  />
                </Cirrus.Modal>
              )}

              {isSaveSearchOpen && (
                <Cirrus.PopUp
                  referenceElement={saveSearchReferenceElement}
                  className="popup-savesearch"
                  theme={Cirrus.PopupThemes.Featured}
                >
                  <Cirrus.SaveSearchForm {...saveSearchFormProps} />
                </Cirrus.PopUp>
              )}

              {isCompareJobsOpen && (
                <div className="compare-jobs">
                  <Cirrus.CompareJobsPopup
                    descriptiveCopy={maxCompareCard}
                    selectionCopy={selectedCompareCards}
                    buttonProps={{
                      children: fetchCMSData(
                        "JobSearch.CompareJobs",
                        "Job.JobSearch.CompareJobs.CompareSelectedJobs"
                      ),
                      size: "Large",
                      onClick: () => {
                        setIsJobCompareModalVisible(true);
                      },
                      type: "button",
                      disabled: selectedCompareJobs.length > 1 ? false : true,
                    }}
                    secondaryButtonProps={{
                      children: fetchCMSData(
                        "JobSearch.CompareJobs",
                        "Job.JobSearch.CompareJobs.ClearAll"
                      ),
                      size: Cirrus.ButtonSizes.Small,
                      isSecondary: true,
                      type: "button",
                      onClick: handleClearCompare,
                    }}
                    referenceElement={compareJobsButtonRef}
                  />
                </div>
              )}
            </Cirrus.JobSearchLayout.GridCell>
            <Cirrus.JobSearchLayout.GridCell gridArea="results">
              {jobLoader === true ? (
                <div className="spinner-center">
                  <Spinner screenLoader={false} spinnerTimer={jobLoader} />
                  <br />
                  <Cirrus.Label>
                    {fetchCMSData(
                      "JobSearch.SearchJobBox",
                      "Job.JobSearch.SearchJobBox.SearchingJobs"
                    )}
                  </Cirrus.Label>
                </div>
              ) : (
                <>
                  {shownJobs.length > 0 ? (
                    <JobCard
                      JDclickedId={JDclickedId}
                      saveJobHandler={saveJobHandler}
                      isCompareJobsOpen={isCompareJobsOpen}
                      compareJobHandler={compareJobHandler}
                      jobCardID={jobCardID}
                      isCompareJobCardChecked={isCompareJobCardChecked}
                      shownJobs={shownJobs}
                      paginationState={paginationState}
                      loadMoreJobHandler={loadMoreJobHandler}
                      selectedCompareJobs={selectedCompareJobs}
                      layout={"onecolumn"}
                      jobCardCMSData={jobCardCMSData}
                    />
                  ) : (
                    <>
                      {searchJobsForm.isSearchClicked === true ? (
                        <Cirrus.Label>
                          {fetchCMSData(
                            "JobSearch.SearchList",
                            "Job.JobSearch.SearchList.NoJobCardsAvailable"
                          )}
                        </Cirrus.Label>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
            </Cirrus.JobSearchLayout.GridCell>
          </Cirrus.JobSearchLayout>
        </Cirrus.OneColumnLayout>
      </div>

      {editSaveSearchModal && (
        <Cirrus.EditSavedSearch
          className="editSaveSearch"
          heading={fetchCMSData(
            "JobSearch.EditSavedSearch",
            "Job.JobSearch.EditSavedSearch.Heading"
          )}
          keywordsInputProps={{
            id: editSavedSearchForm.keywords.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.JobTitle"
            ),
            name: editSavedSearchForm.keywords.name,
            type: "text",
            value: editSavedSearchForm.keywords.value,
            onChange: (event) => {
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.keywords.name
              );
            },
          }}
          countrySelectProps={{
            id:
              editSavedSearchForm.country.name.charAt(0).toUpperCase() +
              editSavedSearchForm.country.name.slice(1),
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Country"
            ),
            name: editSavedSearchForm.country.name,
            options: editSearchCountryLists,
            defaultValue: editSearchCountryLists[0],
            onChange: (event) => {
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.country.name
              );
            },
          }}
          locationSelectProps={{
            errorMessage: editSearchCityListsError.errorMessage,
            state: editSearchCityListsError.state,
            inputId: "location2",
            inputLabel: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Location"
            ),
            inputName: "location2",
            inputPlaceholder: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Select"
            ),
            suggestionsItem: editSearchCityLists,
            inputValue: editSavedSearchForm.location.value,
            onChangeHandler: getEditSearchCityLists,
            selectItemHandler: editSearchCitySelectHandler,
          }}
          distanceSelectProps={{
            id:
              editSavedSearchForm.distance.name.charAt(0).toUpperCase() +
              editSavedSearchForm.distance.name.slice(1),
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.DistanceFromLocation"
            ),
            name: editSavedSearchForm.distance.name,
            options: editSearchDistance,
            defaultValue: editSearchDistance[0],
            onChange: (event) => {
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.distance.name
              );
            },
          }}
          salarySelectProps={{
            id: editSavedSearchForm.salary.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Salary"
            ),
            name: editSavedSearchForm.salary.name,
            placeholder: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Select"
            ),
            options: masterSalaryRange,
            onChangeHandler: (event) =>
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.salary.name
              ),
            selectedValue: editSearchSalaryRange,
            disableSearch: true,
            hasSelectAll: false,
          }}
          employmentTypeSelectProps={{
            id: editSavedSearchForm.employmentType.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.FullTimeOrPartTime"
            ),
            name: editSavedSearchForm.employmentType.name,
            placeholder: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Select"
            ),
            options: masterEmploymentType,
            onChangeHandler: (event) =>
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.employmentType.name
              ),
            selectedValue: editSearchEmploymentType,
            disableSearch: true,
            hasSelectAll: false,
          }}
          companySelectProps={{
            name: editSavedSearchForm.company.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Company"
            ),
            id: editSavedSearchForm.company.name,
            placeholder: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.EnterCompanyName"
            ),
            value: editSavedSearchForm.company.value,
            onChange: (event) =>
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.company.name
              ),
          }}
          contractTypeSelectProps={{
            id: editSavedSearchForm.contractType.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.ContractType"
            ),
            name: editSavedSearchForm.contractType.name,
            placeholder: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Select"
            ),
            options: masterContractType,
            onChangeHandler: (event) =>
              editSavedSearchFormHandler(
                event,
                editSavedSearchForm.contractType.name
              ),
            selectedValue: editSearchContractType,
            disableSearch: true,
            hasSelectAll: false,
          }}
          savedSearchTitleInputProps={{
            id: editSavedSearchForm.title.name,
            label: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.TitleOfSavedSearch"
            ),
            name: editSavedSearchForm.title.name,
            type: "text",
            value: editSavedSearchForm.title.value,
            onChange: (event) => {
              editSavedSearchFormHandler(event, editSavedSearchForm.title.name);
            },
          }}
          onFormSubmit={function noRefCheck() {}}
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () => {
              setEditSaveSearchModal(false);
            },
            text: "Close",
            type: "button",
          }}
          closeButtonProps={{
            children: "Close",
            leadingIcon: "close",
            onClick: () => {
              setEditSaveSearchModal(false);
            },
            size: "Small",
            type: "button",
          }}
          savedButtonProps={{
            children: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Save"
            ),
            onClick: saveTheEditedSearch,
            size: "Small",
            type: "submit",
            disabled: editSavedSearchForm.saveButtonStatus,
          }}
          cancelButtonProps={{
            children: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Cancel"
            ),
            isSecondary: true,
            onClick: () => {
              setEditSaveSearchModal(false);
            },
            size: "Small",
            type: "button",
          }}
          deleteButtonProps={{
            children: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.DeleteSearch"
            ),
            isSecondary: true,
            onClick: () => {
              confirmDeleteTheSavedSearchHandler(
                editSavedSearchForm.selectedSearchId
              );
            },
            size: "Small",
            type: "button",
          }}
        />
      )}

      {deleteSaveSearchConfirmationPopup && (
        <Cirrus.AlertModal
          className="saveSearchDelete"
          heading={fetchCMSData(
            "JobSearch.EditSavedSearch",
            "Job.JobSearch.EditSavedSearch.WarningPopUp"
          )}
          primaryButton={{
            children: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.ConfirmButton"
            ),
            onClick: deleteTheSavedSearch,
            size: "Large",
            type: "button",
          }}
          secondaryButton={{
            children: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.ConfirmButton"
            ),
            onClick: deleteTheSavedSearch,
            size: "Large",
            type: "button",
          }}
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () => setDeleteSaveSearchConfirmationPopup(false),
            text: fetchCMSData(
              "JobSearch.EditSavedSearch",
              "Job.JobSearch.EditSavedSearch.Cancel"
            ),
            type: "button",
          }}
          role="alert"
          subheading={fetchCMSData(
            "JobSearch.EditSavedSearch",
            "Job.JobSearch.EditSavedSearch.WarningMessage"
          )}
        />
      )}

      <div
        style={
          isJobDetailsHide === true ? { display: "block" } : { display: "none" }
        }
      >
        <JobDetails
          selectJobForDetails={selectJobForDetails}
          jobDetailsBackFlag={jobDetailsBackFlag}
          jobSaveFromJDpageFlag={jobSaveFromJDpageFlag}
          appliedJobFromJDpageFlag={appliedJobFromJDpageFlag}
        />
      </div>

      {/* {isJobCompareModalVisible && (
        <Cirrus.CompareJobsModal
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: closeCompareModal,
            text: "Stop comparing jobs",
            type: "button",
          }}
          heading={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Compare"
          )}
          jobs={selectedCompareJobs}
          staticCopy={{
            Company: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.Company"
            ),
            ContractType: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.ContractType"
            ),
            Deadline: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.DeadLineToApply"
            ),
            Location: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.Location"
            ),
            Salary: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.Salary"
            ),
            StartDate: fetchCMSData(
              "JobSearch.CompareJobs",
              "Job.JobSearch.CompareJobs.StartDate"
            ),
            RelatedVacanciesHeading: "You may be interested in...",
            ComputerSkills: "Computer skills",
            Education: "Education level",
            Experience: "Years of experience",
            LanguageSkills: "Language skills",
          }}
        />
      )} */}
      {isJobCompareModalVisible && (
        <JobCompareModal
          heading={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Compare"
          )}
          Deadline={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.DeadLineToApply"
          )}
          //   StartDate={fetchCMSData(
          //     "JobSearch.CompareJobs",
          //     "Job.JobSearch.CompareJobs.StartDate"
          //   )}
          DatePosted={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.DatePosted"
          )}
          Location={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Location"
          )}
          Company={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Company"
          )}
          JobTitle={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.JobTitle"
          )}
          Salary={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Salary"
          )}
          EmploymentType={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.EmploymentType"
          )}
          ContractType={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.ContractType"
          )}
          JobDescription={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.JobDescription"
          )}
          jobs={selectedCompareJobs}
          closeModal={closeCompareModal}
        />
      )}
    </div>
  );
}

export default JobSearchTab;
