//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from "manpowergroup-cirrus";
import "./IviewInterviewAssesmentCard.css";
import { useNavigate } from "react-router-dom";

function IviewInterviewAssesmentCard(props) {
  const navigate = useNavigate();
  let arrFocusArea = [];
  props.focusArea.map((focus) => {
    arrFocusArea.push(focus.value);
  });
  return (
    <Cirrus.Placeholder
      className="iview-card-remove-padding"
    >
      <Cirrus.IviewCard
        title={props?.Title}
        subtitle={props?.SubTitle}
        description={props?.ShortDescription}
        link={{
          url: `/#/iview-interview-start/${props.id}/${props.externalId}`,
        }}
        eventType={[]}
        tags={arrFocusArea && arrFocusArea}
        saveButton={{
          label: "Save",
          secondaryLabel: "Saved",
          onClick: (e) => {
            props.saveAssessmentCard(
              e,
              props && props.id && props.id,
              props && props.issaved && props.issaved,
              props.isLiked && props.isLiked,
              props.LearningResourceStatus && props.LearningResourceStatus,
              "save",
              props.externalId && props.externalId
            );
          },
        }}
        status={props.LearningResourceStatus == "notstarted" || props.LearningResourceStatus == undefined ? {
          name: "new",
          label: props && props?.ResourceType,
          value: props && props?.Duration,
        } : {
          name: "new",
          label: props && props?.ResourceType,
          value: props && props?.LearningResourceStatus
        }
        }
        type="assessment"
        layout="horizontal"
        likeText={
          props && props?.totalLikedCount
            ? props?.totalLikedCount +
            " " +
            `${props?.totalLikedCount > 1 ? "LIKES" : "LIKE"}`
            : "0 LIKE"
        }
        onLikeButtonClick={(e) =>
          props.saveAssessmentCard(
            e,
            props && props.id && props.id,
            props.issaved && props.issaved,
            props && props.isLiked && props.isLiked,
            props.LearningResourceStatus && props.LearningResourceStatus,
            "like",
            props.externalId && props.externalId
          )
        }
        isLiked={props && props?.isLiked}
        isSaved={props && props?.issaved}
        className="title-and-description"
      />
    </Cirrus.Placeholder>
  );
}

export default IviewInterviewAssesmentCard;
