//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react'
import * as Cirrus from "manpowergroup-cirrus";
import { useEffect, useState } from 'react';
import Header from '../../../pages/header/simpleHeader';
import htmlParser from 'html-react-parser';
import Footer from '.';
import { FooterActions } from './FooterActions';
import Spinner from '../spinner-loader/Spinner';
import "../../../index.css";

const PolicyCookie = () => {
    const [data, setData] = useState();
    const [screenLoader, setScreenLoader] = useState(true);

    useEffect(async () => {
        const userLangAndCountry = JSON.parse(window.sessionStorage.getItem("Language"));
        window.scrollTo(0, 0)
        await getPolicyData()
        window.OneTrust?.initializeCookiePolicyHtml()
        window.OneTrust?.changeLanguage(userLangAndCountry?.selectedLanguageISOCode)
    }, [])

    let auth = {
        'token': sessionStorage.getItem("setIdTokenClaims") ? true : false
    }

    const getPolicyData = async () => {
        await FooterActions.GetCookieNPolicies({
            "pagename": "CookiePolicy"
        }).then((res) => {
            if (res.status == 200) {
                setData(res?.data && res?.data?.Pages[0])
                setScreenLoader(false)
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const replaceCookieBtn = () => {
        let textArr = document.querySelectorAll('span')
        let btn = document.getElementById("ot-sdk-btn")
        textArr.forEach((txt) => {
            if (txt?.firstChild?.textContent == "#ONETRUST_BUTTON#") {
                console.log(txt)
                txt.parentNode.replaceChild(btn, txt)
            }
        })
    }

    useEffect(() => {
        replaceCookieBtn();
    })

    return (
        <>
            <Cirrus.Page
                background='secondary'
                backgroundShape='singleDesktop'
                header={<Header mode="back" />}
                className="ourPolicy cookie-policy"
            >
                {
                    screenLoader === true ? <div>
                        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
                    </div> : <Cirrus.Container heading={data && data?.Title} className="cookie-content">
                        {
                            htmlParser(data ? data?.Description.replace('#ONETRUST_TABLE#', '<div id="ot-sdk-cookie-policy"></div>') : "")
                        }
                    </Cirrus.Container>
                }
            </Cirrus.Page>
            <div style={{ display: 'none' }}>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings" onClick={() => window.OneTrust.ToggleInfoDisplay()}>Cookie Settings</button>
            </div>
            {
                auth.token ? <Footer isLoggedIn={true} /> : <Footer isLoggedIn={false} />
            }
        </>
    )
}

export default PolicyCookie
