//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useEffect, useState, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Moment from "moment";
import { coachActions } from "./coachActions";
import FullCalendar, { formatDate, parse } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "../../common/components/spinner-loader/Spinner";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import {
  UTCtoLocalTimeWithAMPM,
  globalDateFormat,
} from "../../common/helpers/DateTimeHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

function MeetingScheduleDialog({
  fetchLabel,
  cmsContent,
  mycoachData,
  closeDialog,
  ReloadComp,
  backButtonLabel,
  nextButtonLabel,
  todayButtonLabel,
}) {
  //5034
  //SenderEmail will Coach email
  //Receipitent Email -== Candidate Email
  //ScheduleBy == Will be handled by .Net
  let dtToday = new Date();
  let sStartDate =
    dtToday.getFullYear() +
    "-" +
    ("0" + (dtToday.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dtToday.getDate()).slice(-2);
  const appInsights = useAppInsightsContext();
  const calendarRef = useRef();
  const userLang = navigator.language || navigator.userLanguage || "en";
  const sInitialLocale = userLang.split("-")[0];
  const [sInitialView, setInitialView] = useState("timeGridThreeDay");

  const [requestMeetingData, setrequestMeetingData] = useState({
    MeetingTitle: { FieldValue: "", status: "", errorMsg: "" },
    MeetingDuration: {
      FieldValue: fetchLabel(
        "CalendarPage.ScheduleAMeetingWithCoach.MeetingDuration",
        "meetingSchedule_Labels"
      ),
      status: "",
      errorMsg: "",
    },
    PreferredDate: { FieldValue: new Date(), status: "", errorMsg: "" },
    AvailableTime: { FieldValue: "", status: "", errorMsg: "" },
  });

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  let sMDuration = fetchLabel(
    "CalendarPage.ScheduleAMeetingWithCoach.MeetingDuration",
    "meetingSchedule_Labels"
  );
  let sFinalCalDuration = "00:" + sMDuration.split(" ")[0] + ":00";
  const [sCalDuration, setsCalDuration] = useState(sFinalCalDuration);
  const [bIsFormInValid, setIsFormInValid] = useState(true);
  const [bAdvanceCalenderView, setbAdvanceCalenderView] = useState(false);
  const [availabilityTimeSlots, setavailabilityTimeSlots] = useState([]);
  const [meetingSlotsMessages, setMeetingSlotsMessages] = useState("");
  const [cronofyData, setcronofyData] = useState([]);
  const [sCalEventsData, setsCalEventsData] = useState([]);
  const [sCalLastDate, setsCalLastDate] = useState();
  const [sCalFirstDate, setsCalFirstDate] = useState();
  const [sButtonText, setsButtonText] = useState(
    fetchLabel(
      "CalendarPage.ScheduleAMeetingWithCoach.AdvancedCalendarViewButton",
      "meetingSchedule_Labels"
    )
  );
  const [screenLoader, setScreenLoader] = useState(false);
  const candidate = JSON.parse(sessionStorage.getItem("setProfileDetails"));

  const inputsRequestMeetingHandler = (e, flag) => {
    let name = "";
    let value = "";
    if (flag == undefined) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = flag;
      value = e;
    }
    let objData = { FieldValue: value };

    const regexPreferredName =
      /^[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}\p{Script=Latin}\p{Script=Cyrillic}\p{Script=Greek}\p{Script=Arabic}\p{Script=Hebrew}\p{Script=Thai}\d\s]+$/u;
    switch (name) {
      case "MeetingTitle":
        if (value.length > 0) {
          objData.status =
            regexPreferredName.test(value) === false ? "error" : "";
          objData.errorMsg =
            regexPreferredName.test(value) === false
              ? fetchLabel(
                  "CalendarPage.ScheduleAMeetingWithCoach.AllowedCharactersText",
                  "meetingSchedule_Labels"
                )
              : "";
        }
        break;
      case "PreferredDate":
        objData.status = value.length === 0 ? "error" : "";
        objData.errorMsg = value.length === 0 ? "Select date " : "";
        break;
      case "AvailableTime":
        objData.status = value.length === 0 ? "error" : "";
        objData.errorMsg = value.length === 0 ? "Select time slot " : "";
        break;
      default:
        objData["status"] = "";
        objData["errorMsg"] = "";
        break;
    }
    setrequestMeetingData({ ...requestMeetingData, [name]: objData });
  };

  const fnCloseMeetingDialog = () => {
    setrequestMeetingData({
      ...requestMeetingData,
      MeetingTitle: { FieldValue: "", status: "", errorMsg: "" },
      MeetingDuration: { FieldValue: "", status: "", errorMsg: "" },
      PreferredDate: { FieldValue: "", status: "", errorMsg: "" },
      AvailableTime: { FieldValue: "", status: "", errorMsg: "" },
    });
    closeDialog(false);
  };

  const fnValidRequestMeetingForm = () => {
    if (
      requestMeetingData.MeetingTitle?.FieldValue?.length === 0 ||
      requestMeetingData.MeetingDuration?.FieldValue?.length === 0 ||
      requestMeetingData.PreferredDate?.FieldValue?.length === 0 ||
      requestMeetingData.AvailableTime?.FieldValue?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const fnSetAdvanceCalendarButton = (bFlag) => {
    setbAdvanceCalenderView(!bFlag);
    if (!bFlag) {
      setsButtonText(
        fetchLabel(
          "CalendarPage.ScheduleAMeetingWithCoach.BasicViewButton",
          "meetingSchedule_Labels"
        )
      );
    } else {
      setsButtonText(
        fetchLabel(
          "CalendarPage.ScheduleAMeetingWithCoach.AdvancedCalendarViewButton",
          "meetingSchedule_Labels"
        )
      );
    }
  };
  const fnOpenAdvanceView = () => {
    setbAdvanceCalenderView((prev) => {
      fnSetAdvanceCalendarButton(prev);
    });

    let arrData = [];
    let busyArrayData = [];
    let tentativeArrayData = [];
    cronofyData.map((ele) => {
      ele.free_Busy_Slots.map((eleFreeBusy) => {
        if (eleFreeBusy["free_Busy_Status"] === "busy") {
          busyArrayData.push({
            title: "",
            start: eleFreeBusy.startDateTime,
            end: eleFreeBusy.endDateTime,
          });
        }
        if (eleFreeBusy["free_Busy_Status"] === "tentative") {
          tentativeArrayData.push({
            title: "",
            start: eleFreeBusy.startDateTime,
            end: eleFreeBusy.endDateTime,
          });
        }

        arrData = [...busyArrayData, ...tentativeArrayData];
      });
    });
    setsCalEventsData(arrData);
  };

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(requestMeetingData.PreferredDate.FieldValue);
  }, [cronofyData]);

  useEffect(() => {
    let bFormInValid = fnValidRequestMeetingForm();
    setIsFormInValid(bFormInValid);
  }, [requestMeetingData]);

  useEffect(async () => {
    if (requestMeetingData.PreferredDate != "") {
      setavailabilityTimeSlots([]);
      setMeetingSlotsMessages(
        fetchLabel(
          "CalendarPage.ScheduleAMeetingWithCoach.FetchingTimeSlots",
          "meetingSchedule_Labels"
        )
      );

      let scheduleDate = Moment(
        requestMeetingData.PreferredDate.FieldValue
      ).format("YYYY-MM-DD");
      let dtTomorrow = new Date(requestMeetingData.PreferredDate.FieldValue);
      let dtDayAfterTomorrow = new Date(
        requestMeetingData.PreferredDate.FieldValue
      );

      dtTomorrow.setDate(dtTomorrow.getDate() + 1);
      dtDayAfterTomorrow.setDate(dtDayAfterTomorrow.getDate() + 2);
      let sTomorrow =
        dtTomorrow.getFullYear() +
        "-" +
        ("0" + (dtTomorrow.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtTomorrow.getDate()).slice(-2);
      let sDayAfterTomorrow =
        dtDayAfterTomorrow.getFullYear() +
        "-" +
        ("0" + (dtDayAfterTomorrow.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtDayAfterTomorrow.getDate()).slice(-2);
      let sMeetingDuration = fetchLabel(
        "CalendarPage.ScheduleAMeetingWithCoach.MeetingDuration",
        "meetingSchedule_Labels"
      );
      setsCalLastDate(sDayAfterTomorrow);
      setsCalFirstDate(scheduleDate);
      let objpayload = {
        TimeZoneId: "UTC",
        // Dates: [scheduleDate, sTomorrow, sDayAfterTomorrow],
        Dates: bAdvanceCalenderView
          ? [scheduleDate, sTomorrow, sDayAfterTomorrow]
          : [scheduleDate],
        Duration: sMeetingDuration && parseInt(sMeetingDuration),
      };
      await coachActions
        .GetMeetingRequestCronofyAvailabilitySlots(objpayload)
        .then(async (res) => {
          if (res.status === 200) {
            let arrData = res.data;
            arrData.map((ele, indx) => {
              let dtTempDate = new Date(ele["date"]);
              let sTempDate =
                dtTempDate.getFullYear() +
                "-" +
                ("0" + (dtTempDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + dtTempDate.getDate()).slice(-2);
              ele["date"] = sTempDate;
              ele.availability_Slots.map((eleDates) => {
                eleDates["name"] =
                  UTCtoLocalTimeWithAMPM(eleDates.startDateTime) +
                  "-" +
                  UTCtoLocalTimeWithAMPM(eleDates.endDateTime);
                eleDates["value"] =
                  eleDates.startDateTime + "~~~" + eleDates.endDateTime;
              });
            });
            if (arrData[0].availability_Slots.length === 0) {
              setMeetingSlotsMessages(
                fetchLabel(
                  "CalendarPage.ScheduleAMeetingWithCoach.NoAvailableTimeMessage",
                  "meetingSchedule_Labels"
                )
              );
            }
            setcronofyData(arrData && arrData);
            setavailabilityTimeSlots(arrData[0].availability_Slots);

            let arrData1 = [];
            let busyArrayData = [];
            let tentativeArrayData = [];
            arrData.map((ele) => {
              ele.free_Busy_Slots.map((eleFreeBusy) => {
                if (eleFreeBusy["free_Busy_Status"] === "busy") {
                  busyArrayData.push({
                    title: "",
                    start: eleFreeBusy.startDateTime,
                    end: eleFreeBusy.endDateTime,
                  });
                }
                if (eleFreeBusy["free_Busy_Status"] === "tentative") {
                  tentativeArrayData.push({
                    title: "",
                    start: eleFreeBusy.startDateTime,
                    end: eleFreeBusy.endDateTime,
                  });
                }

                arrData1 = [...busyArrayData, ...tentativeArrayData];
              });
            });
            setsCalEventsData(arrData1);
          } else if (res.status === 400) {
            setavailabilityTimeSlots([]);
            setMeetingSlotsMessages(
              fetchLabel(
                "CalendarPage.ScheduleAMeetingWithCoach.ValidateTheProgramEndDate",
                "meetingSchedule_Labels"
              )
            );
          } else {
            setavailabilityTimeSlots([]);
            setMeetingSlotsMessages(
              fetchLabel(
                "CalendarPage.ScheduleAMeetingWithCoach.NoAvailableTimeMessage",
                "meetingSchedule_Labels"
              )
            );
          }
        })
        .catch(() => {
          setavailabilityTimeSlots([]);
          setMeetingSlotsMessages(
            fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.NoAvailableTimeMessage",
              "meetingSchedule_Labels"
            )
          );
        });
    }
    let objTitle = document.getElementsByClassName("fc-toolbar-title");

    let selectedDate = requestMeetingData.PreferredDate.FieldValue;
    let formattedSelectedDate =
      selectedDate.getFullYear() +
      "-" +
      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + selectedDate.getDate()).slice(-2);
    let arrDate = formattedSelectedDate.split("-");

    objTitle[0].textContent =
      fetchLabel(
        "CalendarPage.ScheduleAMeetingWithCoach.3DayWeekText",
        "meetingSchedule_Labels"
      ) +
      ": " +
      arrDate[1] +
      "/" +
      arrDate[2] +
      "/" +
      arrDate[0];
  }, [requestMeetingData.PreferredDate, bAdvanceCalenderView]);

  const fnSaveMeetingRequest = async () => {
    setIsFormInValid(true);
    setScreenLoader(true);
    let objAddEvent = {};
    let objCandidate = JSON.parse(
      sessionStorage.getItem("LoginCandidateToken")
    );

    objAddEvent["TimeZoneId"] = "UTC";
    objAddEvent["SenderEmailAddress"] = mycoachData.emailAddress;
    objAddEvent["RecipientEmailAddress"] = objCandidate.email;
    objAddEvent["MeetingType"] = "MeetingEvent";
    objAddEvent["Title"] = requestMeetingData.MeetingTitle.FieldValue;
    objAddEvent["StartTimeStamp"] =
      requestMeetingData.AvailableTime.FieldValue.split("~~~")[0];
    objAddEvent["EndTimeStamp"] =
      requestMeetingData.AvailableTime.FieldValue.split("~~~")[1];
    await coachActions.SaveRescheduleMeeting(objAddEvent).then((res) => {
      if (res.status === 201) {
        TrackActions.EventTrack(appInsights, "BookEvent", {
          EventType: "Coaching",
        });
        TrackActions.PageAction("BookEvent", { EventType: "Coaching" });
        ReloadComp((prev) => prev + 1);
        closeDialog(false);
      } else {
        setScreenLoader(false);
      }
    });
    setScreenLoader(false);
  };

  const fnSetPreferredDate = (objEventData) => {
    let dtISTDate = new Date(objEventData.dateStr);
    let sISTDate =
      dtISTDate.getFullYear() +
      "-" +
      ("0" + (dtISTDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dtISTDate.getDate()).slice(-2);

    let dtStartdate = new Date(objEventData.dateStr);
    let dtEnddate = new Date(objEventData.dateStr);

    let sDuration = requestMeetingData.MeetingDuration.FieldValue;
    let mDuration = parseInt(sDuration.split(" ")[0]);
    dtEnddate.setMinutes(dtEnddate.getMinutes() + parseInt(mDuration));

    let objPreferredDateData = {};
    objPreferredDateData["FieldValue"] = sISTDate;
    objPreferredDateData["status"] = "";
    objPreferredDateData["errorMsg"] = "";

    let objAvailableTimeData = {};
    objAvailableTimeData["FieldValue"] =
      dtStartdate.toISOString().replace(/.\d+Z$/g, "Z") +
      "~~~" +
      dtEnddate.toISOString().replace(/.\d+Z$/g, "Z");
    objAvailableTimeData["status"] = "";
    objAvailableTimeData["errorMsg"] = "";

    // setrequestMeetingData({ ...requestMeetingData, ["PreferredDate"]: objPreferredDateData, ["AvailableTime"]: objAvailableTimeData })
  };

  const fnCallToday = () => {
    let dtToday = new Date();

    let sStartDate =
      dtToday.getFullYear() +
      "-" +
      ("0" + (dtToday.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dtToday.getDate()).slice(-2);

    let objPreferredDateData = {};
    objPreferredDateData["FieldValue"] = new Date(sStartDate);
    objPreferredDateData["status"] = "";
    objPreferredDateData["errorMsg"] = "";

    setrequestMeetingData({
      ...requestMeetingData,
      ["PreferredDate"]: objPreferredDateData,
    });
    let objEle = document.getElementsByClassName("fc-BackButton-button");
    objEle[0].setAttribute("disabled", true);
  };

  const fnCallNext = () => {
    let objEle = document.getElementsByClassName("fc-BackButton-button");
    objEle[0].removeAttribute("disabled");
    let dtTempDate = new Date(sCalLastDate);
    dtTempDate.setDate(dtTempDate.getDate() + 1);
    let sStartDate =
      dtTempDate.getFullYear() +
      "-" +
      ("0" + (dtTempDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dtTempDate.getDate()).slice(-2);

    let objPreferredDateData = {};
    objPreferredDateData["FieldValue"] = new Date(sStartDate);
    objPreferredDateData["status"] = "";
    objPreferredDateData["errorMsg"] = "";

    setrequestMeetingData({
      ...requestMeetingData,
      ["PreferredDate"]: objPreferredDateData,
    });
  };

  const fnCallBack = () => {
    let dtToday = new Date();
    let dtTempDate = new Date(sCalFirstDate);
    dtToday.setHours(0, 0, 0, 0);
    dtTempDate.setHours(0, 0, 0, 0);

    if (dtTempDate > dtToday) {
      dtTempDate.setDate(dtTempDate.getDate() - 3);
      let sStartDate =
        dtTempDate.getFullYear() +
        "-" +
        ("0" + (dtTempDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dtTempDate.getDate()).slice(-2);
      let objPreferredDateData = {};
      objPreferredDateData["FieldValue"] = new Date(sStartDate);
      objPreferredDateData["status"] = "";
      objPreferredDateData["errorMsg"] = "";

      setrequestMeetingData({
        ...requestMeetingData,
        ["PreferredDate"]: objPreferredDateData,
      });
    }

    if (dtTempDate.toString() === dtToday.toString()) {
      let objEle = document.getElementsByClassName("fc-BackButton-button");
      objEle[0].setAttribute("disabled", true);
    }
  };

  useEffect(() => {
    if (bAdvanceCalenderView === true) {
      let objEle = document.getElementsByClassName("fc-BackButton-button");
      objEle[0].setAttribute("disabled", true);

      let objTitle = document.getElementsByClassName("fc-toolbar-title");
      let selectedDate = requestMeetingData.PreferredDate.FieldValue;
      let formattedSelectedDate =
        selectedDate.getFullYear() +
        "-" +
        ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + selectedDate.getDate()).slice(-2);

      let arrDate = formattedSelectedDate.split("-");
      objTitle[0].textContent =
        fetchLabel(
          "CalendarPage.ScheduleAMeetingWithCoach.3DayWeekText",
          "meetingSchedule_Labels"
        ) +
        ": " +
        arrDate[1] +
        "/" +
        arrDate[2] +
        "/" +
        arrDate[0];
    }
  }, [bAdvanceCalenderView]);

  if (screenLoader) {
    return (
      <div>
        <Spinner spinnerTimer={screenLoader} screenLoader={true} />
      </div>
    );
  }
  return (
    <>
      <Cirrus.Modal
        className="meetingScheduleModal"
        closeIconButtonProps={{
          icon: "close",
          isSecondary: true,
          onClick: () => fnCloseMeetingDialog(),
          text: "Cancel",
          type: "button",
        }}
        headerProps={{
          children: cmsContent.meetingSchedule_Title,
          className: "no-margin",
          size: "h5",
        }}
        isScrollable
        role="dialog"
        size="mobile"
      >
        <div>
          <Row>
            <Col
              lg={bAdvanceCalenderView === true ? 8 : 0}
              className={
                bAdvanceCalenderView === true
                  ? "d-block overflow-hidden mb-4 mb-lg-0 px-0 px-lg-3"
                  : "d-none"
              }
            >
              <FullCalendar
                plugins={[
                  momentTimezonePlugin,
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
                initialView={sInitialView}
                customButtons={{
                  BackButton: {
                    // text: 'Back',
                    text: backButtonLabel,
                    click: () => {
                      fnCallBack();
                    },
                  },
                  NextButton: {
                    // text: 'Next',
                    text: nextButtonLabel,
                    click: () => {
                      fnCallNext();
                    },
                  },
                  TodayButton: {
                    // text: 'Today',
                    text: todayButtonLabel,
                    click: () => {
                      fnCallToday();
                    },
                  },
                }}
                headerToolbar={{
                  left: "TodayButton,BackButton,NextButton",
                  center: "title",
                  right: "",
                }}
                buttonIcons={false}
                locales={allLocales}
                locale={sInitialLocale}
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: true,
                }}
                fixedWeekCount={false}
                selectable={true}
                initialDate={requestMeetingData.PreferredDate.FieldValue}
                displayEventEnd={true}
                views={{
                  timeGridThreeDay: {
                    type: "timeGrid",
                    duration: { days: 3 },
                  },
                }}
                events={sCalEventsData}
                slotDuration={sCalDuration}
                dateClick={function (info) {
                  fnSetPreferredDate(info);
                }}
                ref={calendarRef}
              />
            </Col>

            <Col lg={bAdvanceCalenderView === false ? 12 : 4}>
              <div className="scheduler-column">
                <Row className="mb-3">
                  <Col md="xs" lg="12">
                    <Cirrus.Label htmlFor="Example">
                      {fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.InviteesText",
                        "meetingSchedule_Labels"
                      )}
                    </Cirrus.Label>
                  </Col>
                  <Col md="xs" lg="12">
                    <Cirrus.Label style={{ marginTop: "10px !important" }}>
                      <i className="icon-person" /> &nbsp;{" "}
                      {mycoachData?.firstName} {mycoachData?.lastName + "."}
                    </Cirrus.Label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="xs" lg="12">
                    <Cirrus.Input
                      id="MeetingTitle"
                      className="scheduleMeetingTitle"
                      label={fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.MeetingTitleText",
                        "meetingSchedule_Labels"
                      )}
                      name="MeetingTitle"
                      // placeholder={`${candidate?.firstName} & ${mycoachData?.firstName}`}
                      placeholder={fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.MeetingTitlePlaceHolder",
                        "meetingSchedule_Labels"
                      )
                        .replace("#CandidateName#", candidate?.firstName)
                        .replace("#CoachName#", mycoachData?.firstName)}
                      maxLength={50}
                      onChange={inputsRequestMeetingHandler}
                      state={requestMeetingData.MeetingTitle.status}
                      value={requestMeetingData.MeetingTitle.FieldValue}
                      errorMessage={requestMeetingData.MeetingTitle.errorMsg}
                      required={true}
                      title=""
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="xs" lg="12">
                    <Cirrus.Input
                      errorMessage=""
                      id="MeetingDuration"
                      label={fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.DurationText",
                        "meetingSchedule_Labels"
                      )}
                      name="duration"
                      placeholder="60 minutes"
                      disabled={true}
                      value={requestMeetingData.MeetingDuration.FieldValue}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="xs" lg="12">
                    <Cirrus.Label htmlFor="Example">
                      {fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.DateText",
                        "meetingSchedule_Labels"
                      )}
                    </Cirrus.Label>
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      id="PreferredDate"
                      name="PreferredDate"
                      label="Date"
                      visiblity={"hidden"}
                      selected={requestMeetingData.PreferredDate.FieldValue}
                      filterDate={isWeekday}
                      showTimeSelect={false}
                      onChange={(e) =>
                        inputsRequestMeetingHandler(e, "PreferredDate")
                      }
                      dateFormat={globalDateFormat(
                        requestMeetingData.PreferredDate.FieldValue
                      )}
                      minDate={new Date()}
                      errorMessage={requestMeetingData.PreferredDate.errorMsg}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="xs" lg="12">
                    <Cirrus.Label>
                      {fetchLabel(
                        "MyCoachPage.MyCoachRescheduleMeeting.AvailableTimes",
                        "meetingReschedule_Labels"
                      )}
                    </Cirrus.Label>
                    {availabilityTimeSlots.length > 0 ? (
                      <>
                        {availabilityTimeSlots.map((ele, indx) => {
                          return (
                            <>
                              <Cirrus.RadioButton
                                labelText={ele.name}
                                name="AvailableTime"
                                onChange={inputsRequestMeetingHandler}
                                value={ele.value}
                                defaultChecked={
                                  requestMeetingData.AvailableTime
                                    .FieldValue === ele.value
                                    ? true
                                    : false
                                }
                                key={indx}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <h4 className="meetingNoTimeSlotAvailable">
                          {meetingSlotsMessages}
                        </h4>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="xs" lg="12">
                    <Cirrus.Button
                      size="Large"
                      onClick={fnOpenAdvanceView}
                      isFullWidth={true}
                      isSecondary
                      disabled={cronofyData.length === 0 ? true : false}
                    >
                      {sButtonText}
                    </Cirrus.Button>

                    <Cirrus.Button
                      className="mt-2"
                      disabled={bIsFormInValid}
                      onClick={fnSaveMeetingRequest}
                      isFullWidth={true}
                    >
                      {fetchLabel(
                        "CalendarPage.ScheduleAMeetingWithCoach.SendMeetingRequestButton",
                        "meetingSchedule_Labels"
                      )}
                    </Cirrus.Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Cirrus.Modal>
    </>
  );
}

export default MeetingScheduleDialog;
