//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect, useState, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import Footer from "../../../common/components/footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AssessmentActions } from "../AssessmentActions";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { coachActions } from "../../coach/coachActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function Result() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState(false);
  const [cmsMasterData, setCmsMasterData] = useState({});
  const [masterQuestionAnswer, setMasterQuestionAnswer] = useState({
    questionLists: [],
    reviewPage: {},
    resultPage: {},
  });
  const [assessmentStatus, setAssessmentStatus] = useState({});
  const [coachdata, setcoachdata] = useState();
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    getAssessmentWithDetails();
    getMyCoach();
    window.scrollTo(0, 0);
    TrackActions.PageViewRecord(appInsights, "ReviewAccomplishmentsResult");
    TrackActions.PageAction("ReviewAccomplishmentsResult");
  }, [context.language]);

  const getAssessmentWithDetails = async () => {
    let cmsQuestion = context.cmsRAchildPagesAndLabel;
    setScreenLoader(true);
    if (Object.keys(cmsQuestion).length !== 0) {
      let refMasterQuestionAnswer = JSON.parse(
        JSON.stringify(masterQuestionAnswer)
      );
      await AssessmentActions.GetAssessmentStatusAndSavedResponseById(
        cmsQuestion?.masterCMS?.ID
      ).then(
        (res) => {
          if (res.status === 200) {
            const returnName = (key) => {
              let name = "";
              if (cmsQuestion?.nameArr) {
                cmsQuestion?.nameArr.map((x) => {
                  if (x.key === key) {
                    name = x.name;
                  }
                });
              }
              return name;
            };
            let arr = [];
            setAssessmentStatus(res.data);
            setCmsMasterData(cmsQuestion);
            let resData = cmsQuestion.masterCMS;
            ActivityAction.SaveActivity(
              resData?.ID,
              "completedassesment",
              resData?.Title
            );
            let savedResponses =
              res.data.learningResourceAssessmentResponseModels;
            if (savedResponses.length > 0) {
              let setCount = 1;
              let filterSetCount = [];
              savedResponses.map((answer) => {
                let tempSetCount = parseInt(answer?.questionId?.split("~")[2]);
                if (!filterSetCount.includes(tempSetCount)) {
                  filterSetCount.push(tempSetCount);
                }
              });

              if (filterSetCount.length > 1) {
                filterSetCount.sort(function (a, b) {
                  return a - b;
                });
                if (filterSetCount[filterSetCount.length - 1] !== undefined) {
                  setCount = filterSetCount[filterSetCount.length - 1];
                }
              }

              for (let i = 1; i <= setCount; i++) {
                let createSet = [];
                cmsQuestion?.masterCMS?.ChildPages?.map((assessment, index) => {
                  if (
                    assessment.Key ===
                    "Assessments.ReviewingAccomplishments.AccomplishmentOverviewPage"
                  ) {
                    refMasterQuestionAnswer.reviewPage = assessment;
                  }
                  if (
                    assessment.Key ===
                    "Assessments.ReviewingAccomplishments.AssessmentReviewPage"
                  ) {
                    refMasterQuestionAnswer.resultPage = assessment;
                  }
                  if (Object.keys(assessment?.Components).length !== 0) {
                    let comp =
                      assessment?.Components[
                        Object.keys(assessment?.Components)[0]
                      ];
                    let listsOption = [];
                    comp?.QuestionList[0]?.AnswerList.forEach((item) => {
                      listsOption.push({
                        isSelected: false,
                        answerId: item.AnswerId + "~set~" + i,
                        itemID: item.ItemID + "~set~" + i,
                        answerText: item.AnswerText,
                      });
                    });
                    if (
                      comp?.QuestionList[0]?.QuestionTitle !==
                      returnName(
                        "Resources.Assessments.ReviewingAccomplishments.AccomplishmentTitle"
                      )
                    ) {
                      createSet.push({
                        questionId:
                          comp?.QuestionList[0]?.QuestionId + "~set~" + i,
                        topTitle: "",
                        questionTitle: assessment?.Title,
                        description: assessment?.Description,
                        questionLabel: comp?.QuestionList[0]?.QuestionTitle,
                        questionPlaceholder: "Enter here",
                        fieldType: comp?.QuestionList[0]?.OptionType,
                        optionsAnswerList: listsOption,
                        textAnswerValue: "",
                        currentSet: "set~" + i,
                      });
                    }
                  }
                });
                arr.push({
                  accomplishmentTitle: "",
                  questionAnswerList: createSet,
                });
              }
              let mappedQuestionAnswer = arr.map((question) => {
                question.questionAnswerList.map((qList) => {
                  savedResponses.map((answer) => {
                    let setNumber = "set~" + answer?.questionId?.split("~")[2];
                    if (qList.currentSet === setNumber) {
                      if (
                        answer.categoryValue ===
                        returnName(
                          "Assessments.ReviewingAccomplishments.AccomplishmentTitlePage"
                        )
                      ) {
                        question.accomplishmentTitle = answer.answerValue;
                      }
                    }
                    if (qList.questionId === answer.questionId) {
                      if (
                        qList.questionTitle ===
                        returnName(
                          "Assessments.ReviewingAccomplishments.SkillsPage"
                        )
                      ) {
                        let spltValue = answer?.answerValue?.split(",");
                        qList?.optionsAnswerList?.map((opt) => {
                          let as = spltValue.filter(
                            (spltOpt) => spltOpt === opt.answerId
                          );
                          if (as.length > 0) {
                            opt.isSelected = true;
                          } else {
                            opt.isSelected = false;
                          }
                          return opt;
                        });
                      } else {
                        qList.textAnswerValue = answer.answerValue;
                      }
                    }
                  });
                  return qList;
                });
                return question;
              });

              refMasterQuestionAnswer.questionLists = mappedQuestionAnswer;
              setMasterQuestionAnswer(refMasterQuestionAnswer);
              setScreenLoader(false);
            } else {
              cmsQuestion?.masterCMS?.ChildPages?.map((assessment) => {
                if (
                  assessment.Key ===
                  "Assessments.ReviewingAccomplishments.AccomplishmentOverviewPage"
                ) {
                  refMasterQuestionAnswer.reviewPage = assessment;
                }
                if (
                  assessment.Key ===
                  "Assessments.ReviewingAccomplishments.AssessmentReviewPage"
                ) {
                  refMasterQuestionAnswer.resultPage = assessment;
                }
              });
              setMasterQuestionAnswer(refMasterQuestionAnswer);
              setScreenLoader(false);
            }
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          setScreenLoader(false);
          console.log(err);
        }
      );
    }
  };

  const fetchLabel = (key) => {
    let refCmsMasterData = JSON.parse(JSON.stringify(cmsMasterData));
    let label = "";
    if (refCmsMasterData?.masterLabel) {
      refCmsMasterData?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus?.learningResourceId,
                learningResourceStatus:
                  assessmentStatus?.learningResourceStatus,
                learningResourceType:
                  cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
                resourceTypeId: cmsMasterData?.masterCMS?.ResourceType?.ID,
                isSaved: assessmentStatus?.isSaved,
                isLiked: assessmentStatus?.isLiked,
                lastAnsweredPageId: assessmentStatus?.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: assessmentStatus?.isSharedWithCoach,
                resultDocumentId: docId,
              };
              AssessmentActions.SaveAssessmentStatus(
                cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
                assessmentStatus?.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    AssessmentActions.GetAssessmentStatusAndSavedResponseById(
                      assessmentStatus?.learningResourceId
                    ).then(
                      (gasasriResp) => {
                        if (gasasriResp.status === 200) {
                          setAssessmentStatus(gasasriResp.data);
                        }
                      },
                      (err) => {
                        console.log(err);
                      }
                    );
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const shareResultWithCoach = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setScreenLoader(true);
    let payload = {
      assessmentName: cmsMasterData?.masterCMS?.Title,
      assessmentId: cmsMasterData?.masterCMS?.ID,
      documentId: assessmentStatus?.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (srwcResp) => {
        if (srwcResp.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus?.learningResourceId,
            learningResourceStatus: assessmentStatus?.learningResourceStatus,
            learningResourceType:
              cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
            resourceTypeId: cmsMasterData?.masterCMS?.ResourceType?.ID,
            isSaved: assessmentStatus?.isSaved,
            isLiked: assessmentStatus?.isLiked,
            lastAnsweredPageId: assessmentStatus?.lastAnsweredPageId,
            isDownloaded: assessmentStatus?.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStatus?.resultDocumentId,
          };
          AssessmentActions.SaveAssessmentStatus(
            cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
            assessmentStatus?.learningResourceId,
            payload
          ).then(
            (sasResp) => {
              if (sasResp.status === 200) {
                setScreenLoader(false);
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchLabel("CommonComponent.CommonClose"),
                });
                AssessmentActions.GetAssessmentStatusAndSavedResponseById(
                  assessmentStatus?.learningResourceId
                ).then(
                  (gasasriResp) => {
                    if (gasasriResp.status === 200) {
                      setAssessmentStatus(gasasriResp.data);
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              } else {
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
              }
            },
            (err) => {
              console.log(err);
              setScreenLoader(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  const returnName2 = (key) => {
    let refCmsRA = JSON.parse(JSON.stringify(cmsMasterData));
    let name = "";
    if (refCmsRA?.nameArr) {
      refCmsRA?.nameArr.map((x) => {
        if (x.key === key) {
          name = x.name;
        }
      });
    }
    return name;
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="assessment-page">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <Container className="pt-4 pb-4">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/reviewing-accomplishments")
              }
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
          </Col>
          <Col
            md={2}
            lg={2}
            xs={{ span: 6, order: 2 }}
            className="text-end exit"
          >
            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
              {fetchLabel("CommonResourceComponent.Exit")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>
      <Container className="assessment-review-result-wrapper assessment-result-wrapper px-4 py-5 p-md-5 mb-5">
        <Row className="mb-5 pb-3 border-bottom">
          <Col lg={5}>
            <Cirrus.Header size="h4" className="mb-3 text-start">
              {masterQuestionAnswer?.resultPage?.Title}
            </Cirrus.Header>
          </Col>
          <Col lg={7} className="download-share-retake">
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() =>
                downloadDocument(assessmentStatus.resultDocumentId)
              }
            >
              {fetchLabel("CommonResourceComponent.DownloadAsPDF")}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() => shareResultWithCoach()}
              disabled={coachdata ? false : true}
            >
              {fetchLabel("CommonResourceComponent.ShareResultsWithCoach")}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              onClick={() => {
                navigate("/" + pageOrgin.id + "/reviewing-accomplishments");
              }}
            >
              {fetchLabel("CommonComponentCommonRetakeButton")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={8} className="m-auto text-start">
            <Cirrus.Header size="h5" className="mb-4">
              {masterQuestionAnswer?.reviewPage?.Title}
            </Cirrus.Header>
            {masterQuestionAnswer?.questionLists?.map((set) => {
              return (
                <div className="border-bottom mb-4">
                  <Cirrus.Headline size="Small" className="mb-3 text-start">
                    {set?.accomplishmentTitle}
                  </Cirrus.Headline>
                  {set?.questionAnswerList?.map((qa) => {
                    return (
                      <>
                        <div className="mb-3">
                          <label className="ra-result-titles">
                            {qa?.questionLabel}
                          </label>
                          {qa?.questionLabel ===
                          returnName2(
                            "Assessments.ReviewingAccomplishments.SkillsPage"
                          ) ? (
                            <ul className="optionAnsUl">
                              {qa?.optionsAnswerList?.map((op) => {
                                if (op?.isSelected) {
                                  return <li>{op?.answerText}</li>;
                                }
                              })}
                            </ul>
                          ) : (
                            <p> {qa?.textAnswerValue} </p>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}

export default Result;
