//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';

const CareerPath = () => {
    const context = useContext(Context)
    const navigate = useNavigate()
    const pageOrgin = useParams()
    const [percentage, setPercentage] = useState(0)
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState()
    const [assessmentStatus, setAssessmentStatus] = useState()
    const [isLiked, setIsLiked] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [screenLoader, setScreenLoader] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [careerPathFields, setCareerPathFields] = useState()
    let cmsCareerPathFields = context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(career => career.Key === "Assessments.LookingAtOptions.TellUsAboutYourCareerPath").Components
    const [commonCareerLabels, setCommonCareerLabels] = useState({
        careerHeading: (cmsCareerPathFields?.find(heading => heading.careerHeading).careerHeading).replace(/[0-9]/g, ''),
        careerID: '',
        pathTitleID: '',
        pathTitle: cmsCareerPathFields?.find(title => title.pathTitle).pathTitle,
        pathValue: '',
        aboutPathTitleID: '',
        aboutPathTitle: cmsCareerPathFields?.find(about => about.aboutPathTitle).aboutPathTitle,
        aboutPathValue: ''
    })
    const [careerPathIdx, setCareerPathIdx] = useState();
    const [careerCategoryId, setCareerCategoryId] = useState();
    let addAnotherClicked = false;
    let isSavingProgress = false;

    useEffect(() => {
        window.scrollTo(0, 0)
        getAssessmentStatusWithAnswers()
    }, [context.language])

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = cmsAssessmentContent && cmsAssessmentContent
        let label = ''
        if (refCmsAssessmentContent && refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent && refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let label = ''
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent
        let id = ''
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id
    }

    const getAssessmentStatusWithAnswers = () => {
        setLoadSpinner(true)
        AssessmentActions.GetAllCareerpath(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                let cmsData = context.cmsLookingAtOptionsContent && context.cmsLookingAtOptionsContent;
                setCmsAssessmentContent(cmsData)

                let resData = res.data
                let savedData = [];
                if (resData.length) {
                    resData.map(resp => {
                        cmsCareerPathFields?.map(career => {
                            savedData.push({ ...career, pathValue: resp.categoryTitle, aboutPathValue: resp.categoryDescription, careerID: resp.categoryId })
                        })
                    })
                    setCareerPathFields(savedData)
                    if (addAnotherClicked && isSavingProgress) {
                        document.querySelector('.addCareerBtn').click();
                    }
                    addAnotherClicked = false;
                    isSavingProgress = false;
                    validateFields();
                }
                else {
                    let cmsData = context.cmsLookingAtOptionsContent && context.cmsLookingAtOptionsContent;
                    setCmsAssessmentContent(cmsData)
                    setCareerPathFields(cmsCareerPathFields)
                }

                AssessmentActions.GetAssessmentStatusAndSavedResponseById(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                    if (res.status === 200) {
                        setAssessmentStatus(res.data)
                        setIsSaved(res.data.isSaved)
                        setIsLiked(res.data.isLiked)

                        let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / (savedData.length * 2)) * 100);
                        setPercentage(percentageCalc);
                    }
                }, err => {
                    console.log(err)
                })
            }
            else {
                let cmsData = context.cmsLookingAtOptionsContent && context.cmsLookingAtOptionsContent;
                setCmsAssessmentContent(cmsData)
                setCareerPathFields(cmsCareerPathFields)
            }
            setLoadSpinner(false)
        }, err => {
            setLoadSpinner(false)
            console.log(err)
        })
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n < assessmentQuestion.length) {
            window.scrollTo(0, 0)
            assessmentQuestion[n].style.display = "block";
        }
        if (n == assessmentQuestion.length) {
            navigate("/" + pageOrgin.id + "/looking-at-options/result");
        }
    }

    const nextPrev = (e, n) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        let payload;
        if (n > 0) {
            setLoadSpinner(true);

            payload = {
                "candidateId": candidateID,
                "learningResourceId": cmsAssessmentContent.internalAssessmentId,
                "learningResourceType": cmsAssessmentContent.resourceCategoryType.ID,
                "resourceTypeId": cmsAssessmentContent.resourceType.ID,
                "learningResourceStatus": assessmentStatus.learningResourceStatus,
                "isSaved": assessmentStatus.isSaved,
                "isLiked": assessmentStatus.isLiked,
                "lastAnsweredPageId": currentQuestion + n,
                "isDownloaded": assessmentStatus?.isDownloaded,
                "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                "resultDocumentId": assessmentStatus?.resultDocumentId
            }

            AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then((res) => {
                if (res.status === 200) {

                    let percentageCalc = Math.ceil(((currentQuestion + n) / assessmentQuestion.length) * 100);
                    if (percentageCalc >= percentage) {
                        setPercentage(percentageCalc);
                    }

                    assessmentQuestion[currentQuestion].style.display = "none";
                    setCurrentQuestion(currentQuestion + n);
                    if (currentQuestion < 0) {
                        setCurrentQuestion(0);
                    }
                    showQuestion(currentQuestion + n);
                    setLoadSpinner(false);
                } else {
                    setLoadSpinner(false);
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        } else {
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setPercentage(percentage);
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const updateAssessmentStatus = () => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        setLoadSpinner(true)
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
            "learningResourceStatus": assessmentStatus.learningResourceStatus,
            "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType?.ID,
            "isSaved": assessmentStatus.isSaved,
            "isLiked": assessmentStatus.isLiked,
            "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId === "0" ? "0" : assessmentStatus.lastAnsweredPageId,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": assessmentStatus?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
            if (res.status === 200) {
                setLoadSpinner(false)
                setTimeout(() => {
                    navigate("/" + pageOrgin.id + "/looking-at-options/career-factors")
                }, 500);
            } else {
                setLoadSpinner(false)
            }
        }, err => {
            console.log(err);
            setLoadSpinner(false);
        })
    }

    const saveCareerPath = async () => {
        isSavingProgress = true;
        let payload
        let arrCareerPaths = []
        careerPathFields?.map(career => {
            arrCareerPaths.push({ "categoryId": career.careerID, "categoryTitle": career.pathValue, "categoryDescription": career.aboutPathValue })
        })
        payload = arrCareerPaths

        if (!isDisabled) {
            await AssessmentActions.SaveCareerPath(payload, context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    let resData = res.data
                    getAssessmentStatusWithAnswers()
                }
            }, err => {
                console.log(err);
            })
        }
    }

    const addCareer = () => {
        setCareerPathFields([...careerPathFields, {
            careerHeading: commonCareerLabels.careerHeading + 1,
            careerID: '',
            pathTitleID: '',
            pathTitle: commonCareerLabels.pathTitle,
            pathValue: '',
            aboutPathTitleID: '',
            aboutPathTitle: commonCareerLabels.aboutPathTitle,
            aboutPathValue: ''
        }])
        setTimeout(() => {
            validateFields()
        })
    }

    const deleteModalOpen = (categoryId, pathIdx) => {
        setCareerPathIdx(pathIdx)
        setCareerCategoryId(categoryId)
        setIsDeleteModal(true)
    }

    const deleteModalClose = () => {
        setIsDeleteModal(false)
    }

    const deleteCareer = (categoryId, index) => {
        if (categoryId !== "") {
            AssessmentActions.DeleteCareerPath(categoryId, context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    const paths = [...careerPathFields]
                    paths.splice(index, 1)
                    setCareerPathFields(paths)
                    setIsDeleteModal(false)
                    let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                    let payload = {
                        "candidateId": candidateID,
                        "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
                        "learningResourceStatus": assessmentStatus.learningResourceStatus,
                        "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
                        "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType?.ID,
                        "isSaved": assessmentStatus.isSaved,
                        "isLiked": assessmentStatus.isLiked,
                        "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId === "0" ? "0" : assessmentStatus.lastAnsweredPageId - 1,
                        "isDownloaded": assessmentStatus?.isDownloaded,
                        "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                        "resultDocumentId": assessmentStatus?.resultDocumentId
                    }
                    AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
                        if (res.status === 200) {

                        }
                    }, err => {
                        console.log(err);
                    })

                    setTimeout(() => {
                        validateFields()
                        getAssessmentStatusWithAnswers()
                    }, 200)
                } else {
                    const paths = [...careerPathFields]
                    paths.splice(index, 1)
                    setCareerPathFields(paths)
                    setIsDeleteModal(false)
                    setTimeout(() => {
                        validateFields()
                    })
                }
            }, err => {
                console.log(err)
            })
        } else {
            const paths = [...careerPathFields]
            paths.splice(index, 1)
            setCareerPathFields(paths)
            setIsDeleteModal(false)
            setTimeout(() => {
                validateFields()
            })
        }
    }

    const handleOnchange = (e, idx) => {
        validateFields();
        const paths = [...careerPathFields]
        if (e.target.attributes.pathvalue) {
            paths[idx]['pathValue'] = e.target.value
        } else {
            paths[idx]['aboutPathValue'] = e.target.value
        }
        setCareerPathFields(paths)
    }

    const validateFields = () => {
        const inputFields = document.querySelectorAll('.text-input')
        const textareaFields = document.querySelectorAll('.assessment-page textarea')
        inputFields.forEach(ele => {
            if (ele.value.length > 2) {
                textareaFields.forEach(e => {
                    if (e.value.length > 2) {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                })
            } else {
                setIsDisabled(true)
            }
        })
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            <Container className='pt-4 pb-4'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        {
                            currentQuestion == 0 ?
                                <Cirrus.Button isSecondary onClick={(e) => navigate("/" + pageOrgin.id + "/looking-at-options")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                :
                                <Cirrus.Button isSecondary onClick={(e) => { nextPrev(e, -1) }}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        }
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                            <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonLabel("CommonComponent.CommonExitButton")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {
                cmsAssessmentContent?.assessmentQuestionSet?.map((assessment, idx) => {
                    if (assessment.Key === "Assessments.LookingAtOptions.TellUsAboutYourCareerPath") {
                        return (
                            <Container className='assessment-question-wrapper mb-5' key={idx} style={{ display: 'block' }}>
                                {
                                    loadSpinner &&
                                    <div className='loader'>
                                        <Cirrus.Spinner size={50} />
                                    </div>
                                }
                                <Row>
                                    <Col lg={12} className="assessment-question mb-3">
                                        <Cirrus.Headline size='Small'>{assessment.Title}</Cirrus.Headline>
                                        <p className='mb-5'>{fetchCommonResourceLabel("CommonResourceComponent.YouCanCreateUpTo4")}</p>
                                        {
                                            careerPathFields?.map((path, pathIdx) => {
                                                return (
                                                    <div className='text-start mb-3' key={pathIdx}>
                                                        <div className='d-flex justify-content-between'>
                                                            <Cirrus.Header size='h5' id={path.careerID}>
                                                                {(path.careerHeading).replace(/[0-9]/g, '') + " " + (pathIdx + 1)}
                                                            </Cirrus.Header>
                                                            {
                                                                careerPathFields?.length > 1 &&
                                                                <Cirrus.Button isSecondary onClick={() => deleteModalOpen(path.careerID, pathIdx)}>
                                                                    {fetchCommonLabel("CommonComponent.CommonDeleteButton")}
                                                                </Cirrus.Button>
                                                            }
                                                        </div>
                                                        <label>{path.pathTitle}</label>
                                                        <input
                                                            id={path.pathTitleID}
                                                            label={path.pathTitle}
                                                            name={"titleCareer" + (pathIdx + 1)}
                                                            placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                            className='text-input mb-3'
                                                            maxLength={100}
                                                            type='text'
                                                            pathvalue={path.pathValue}
                                                            value={path.pathValue}
                                                            onChange={(e) => handleOnchange(e, pathIdx)}
                                                            onBlur={() => saveCareerPath()}
                                                        />
                                                        <label>{path.aboutPathTitle}</label>
                                                        <textarea
                                                            id={path.aboutPathTitleID}
                                                            placeholder={fetchCommonResourceLabel("CommonResourceComponent.EnterHere")}
                                                            name={"aboutCareer" + (pathIdx + 1)}
                                                            maxLength={250}
                                                            rows="10"
                                                            aboutpathvalue={path.aboutPathValue}
                                                            value={path.aboutPathValue}
                                                            onChange={(e) => handleOnchange(e, pathIdx)}
                                                            onBlur={() => saveCareerPath()}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className='d-flex justify-content-center justify-content-md-end gap-3'>
                                        {careerPathFields?.length < 4 &&
                                            <>
                                                <Cirrus.Button isSecondary
                                                    onClick={() => {
                                                        if (!isSavingProgress) {
                                                            document.querySelector('.addCareerBtn').click();
                                                        }
                                                        addAnotherClicked = true
                                                    }}
                                                >{fetchCommonResourceLabel("CommonResourceComponent.AddAnother")}</Cirrus.Button>
                                                <Cirrus.Button isSecondary className='d-none addCareerBtn'
                                                    onClick={() => {
                                                        addCareer()
                                                    }}
                                                >...</Cirrus.Button>
                                            </>
                                        }
                                        <Cirrus.Button disabled={isDisabled} onClick={() => updateAssessmentStatus()}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                })
            }
            {
                isDeleteModal &&
                <Cirrus.Modal className='delete-modal'
                    headerProps={{
                        children: fetchCommonResourceLabel("CommonResourceComponent.ConfirmDelete"),
                        className: 'no-margin',
                        size: 'h5'
                    }}
                    isScrollable
                >
                    <div className='text-center'>
                        <p className='mb-4'>
                            {fetchCommonResourceLabel("CommonResourceComponent.AllTheSavedDataWillBeDeleted")}
                        </p>
                        <Cirrus.Button className='me-3' onClick={() => deleteCareer(careerCategoryId, careerPathIdx)}>{fetchCommonLabel("CommonComponent.CommonDeleteButton")}</Cirrus.Button>
                        <Cirrus.Button isSecondary onClick={() => deleteModalClose()}>{fetchCommonLabel("CommonComponent.CommonCancel")}</Cirrus.Button>
                    </div>
                </Cirrus.Modal>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}


export default CareerPath;
