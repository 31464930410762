//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';

const IYIPpdfTemplate = ({ categories, getResponse }) => {

    return (
        <>
            <table cellPadding={10} cellSpacing={10} style={{ width: '90%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                <tbody>
                    <tr>
                        <td>
                            {
                                getResponse?.map((res, idx) => {
                                    return (
                                        <>
                                            <div className='border-bottom text-start mb-4' key={idx}>
                                                <Cirrus.Headline className='result-question-title'>{res?.Question}</Cirrus.Headline>
                                                <div className='result-options'>
                                                    {res?.Answer?.map((answer, index) => {
                                                        return (
                                                            <label>{answer}</label>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </td>
                    </tr>
                </tbody>
                <span style={{ fontSize: '1px' }}>.</span>
            </table>
        </>
    )
}

export default IYIPpdfTemplate;
