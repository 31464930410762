//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from "../../common/helpers/HttpHelper";
import environments from "../../common/environments/environment";
const http = new httpHelper();

export const iViewActions = {
  GetIviewContent: getIviewContent,
  SaveIviewAssessmentCard: saveIviewAssessmentCard,
  SaveIviewCollection: saveIviewCollection,
  GetIviewInterview: getIviewInterview,
  GetIviewReview: getIviewReview,
  ResetIview: resetIview,
  GetMyCoach: getMyCoach,
  ShareResultWithCoach: shareResultWithCoach,
  Get_30_secondCommercialCMS: get_30_secondCommercialCMS,
  GetLearningresourceToolsData:getLearningresourceToolsData,
};

function getIviewContent(payload) {
  return http.IviewVideoGet(
    environments.IVIEW_ON_DEMAND_VIDEO_INTERVIEW,
    payload
  );
}

function saveIviewAssessmentCard(assessmentId, payload) {
  return http.IviewVideoPost(
    environments.SAVE_IVIEW_ASSESMENT_CARD.replace("{id}", assessmentId),
    payload
  );
}

function saveIviewCollection(payload) {
  return http.IviewVideoPost(environments.SAVE_IVIEW_COLLECTION, payload);
}

function getIviewInterview(payload, id) {
  return http.IviewInterviewURLPut(
    environments.IVIEW_GET_INTERVIEW_URL.replace("{id}", id),
    payload
  );
}

function getIviewReview(id) {
  return http.IviewReviewURLPost(
    environments.IVIEW_GET_REVIEW_URL.replace("{id}", id)
  );
}

function resetIview(id) {
  return http.IviewResetPut(environments.IVIEW_RESET.replace("{id}", id));
}

function getMyCoach() {
  return http.get(environments.GET_MY_COACH);
}

function shareResultWithCoach(payload) {
  return http.put(environments.SHARE_RESULT_WITH_COACH, payload);
}

function get_30_secondCommercialCMS(payload) {
  return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function getLearningresourceToolsData(payload) {
  let url = environments.ASSESSMENT_CARD_DATA
  return http.learningResourcePost(url, payload);
}
