//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useState } from 'react'
import { Container } from 'react-bootstrap'
import './systemMesasaageAccordian.css'
import * as Cirrus from "manpowergroup-cirrus";

const DashboardAccordion = (props) => {
    const [isActive, setIsActive] = useState({})
    const [isFloat, setIsFloat] = useState(true)
    const [accordianActive, setAccordianActive] = useState({})
    const [closeMessage, setCloseMesage] = useState(true);
    const getSystemMessagess = props?.systemNotificationData;
    const getsystemNotificationFilterData = props?.systemNotificationFilterData;
    const signalRMessage = props?.signalRMessage;
    const getSystemMessageStatus = props?.readSystemMessageStatus;

    const handleAcordian = (index) => {
        setIsActive({ ...isActive, [index]: !isActive[index] });
    }

    const handleSignalRMessages = (index) => {
        setAccordianActive({ ...accordianActive, [index]: !accordianActive[index] });
    }

    const removesystemMessages = (systemMessage, index) => {
        props?.onReadSystemMessages(systemMessage?.notificationId);
        setCloseMesage({ ...closeMessage, [index]: !closeMessage[index] });
    }

    return (
        <>
            {getSystemMessagess && getSystemMessagess?.map((systemMessage, i) => {
                return (
                    <>
                        {!closeMessage[i] &&
                            <Container className='accordion' key={i}>
                                <div className="accordion_heading">
                                    <div className="container">
                                        <p className='accordion_title'>{systemMessage?.systemMessage?.title}</p>
                                        <div className='container_buttons'>
                                            <span className='arrow notification-arrow' onClick={() => handleAcordian(i)}>
                                                <Cirrus.Icon size='medium' className={isActive[i] ? 'notification-up-arrow' : 'notification-down-arrow'} icon='button-arrow'></Cirrus.Icon>
                                            </span>
                                            <span className='cross' onClick={() => removesystemMessages(systemMessage, i)}>
                                                <i className="icon-close"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={!isActive[i] ? "accordion_content" : ''}>
                                    <div className="accordion_content_container">
                                        <span>
                                            {systemMessage?.systemMessage?.description}
                                        </span>
                                    </div>
                                </div>
                            </Container>
                        }
                    </>
                )
            })}

            {/* {getsystemNotificationFilterData && getsystemNotificationFilterData?.map((systemMessage, i) => {
                return (
                    <>
                        {getSystemMessageStatus &&
                            <Container className='accordion' key={i}>
                                <div className="accordion_heading">
                                    <div className="container">
                                        <p className='accordion_title'>{systemMessage?.systemMessage?.title}</p>
                                        <div className='container_buttons'>
                                            <span className='arrow notification-arrow' onClick={() => handleAcordian(i)}>
                                                <Cirrus.Icon size='medium' className={isActive[i] ? 'notification-up-arrow' : 'notification-down-arrow'} icon='button-arrow'></Cirrus.Icon>
                                            </span>
                                            <span className='cross' onClick={() => props?.onReadSystemMessages(systemMessage?.notificationId)}>
                                                <i className="icon-close"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={!isActive[i] ? "accordion_content" : ''}>
                                    <div className="accordion_content_container">
                                        <span>
                                            {systemMessage?.systemMessage?.description}
                                        </span>
                                    </div>
                                </div>
                            </Container>
                        }
                    </>
                )
            })} */}

            {signalRMessage && signalRMessage?.map((systemMessage, i) => {
                return (
                    <>
                        {!closeMessage[i] &&
                            <Container className='accordion' key={i}>
                                <div className="accordion_heading">
                                    <div className="container">
                                        <p className='accordion_title'>{systemMessage?.systemMessage?.title}</p>
                                        <div className='container_buttons'>
                                            <span className='arrow notification-arrow' onClick={() => handleAcordian(i)}>
                                                <Cirrus.Icon size='medium' className={isActive[i] ? 'notification-up-arrow' : 'notification-down-arrow'} icon='button-arrow'></Cirrus.Icon>
                                            </span>
                                            <span className='cross' onClick={() => removesystemMessages(systemMessage, i)}>
                                                <i className="icon-close"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={!isActive[i] ? "accordion_content" : ''}>
                                    <div className="accordion_content_container">
                                        <span>
                                            {systemMessage?.systemMessage?.description}
                                        </span>
                                    </div>
                                </div>
                            </Container>
                        }
                    </>
                )
            })}
        </>
    )
}


export default DashboardAccordion;
