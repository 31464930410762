//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import HeaderWithNavigation from "../header/headerWithNavigation";
import Footer from "../../common/components/footer";
import { Context } from "../../common/context/Context";
import { coachActions } from "./coachActions";
import Spinner from "../../common/components/spinner-loader/Spinner";
import CoachList from "./coachList";
import NoCoachAssignment from "./NoCoachAssignment";
import NoCoachScheduleMeeting from "./NoCoachScheduleMeeting";
import NoCoachReScheduleMeeting from "./NoCoachReScheduleMeeting";
import CoachingSessions from "./coachingSessions";
import "./index.css";
import { TrackActions } from "../../common/utility/LogAction";

import CoachMeetingRequest from "./coachMeetingRequest"
import MeetingConfirmtionDialog from "./MeetingConfirmationDialog";
import MeetingCancellationDailog from "./MeetingCancellationDailog";
import MeetingRescheduleDialog from "./MeetingRescheduleDailog";
import MeetingScheduleDialog from "./MeetingScheduleDialog";
import UpcomigEventDetails from "./UpcomingEventdetails"
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

function Coach() {
    const context = useContext(Context);
    const [cmsContent, setCmsContent] = useState({
        labels: [],
        ImageList: [],
        page_Title: "",
        page_Desc: "",
        meetingConfirm_Title: "",
        meetingConfirm_Labels: [],

        meetingReschedule_Title: "",
        meetingReschedule_Labels: [],
        meetingReschedule_Duration: [],

        meetingSchedule_Title: "",
        meetingSchedule_Labels: [],
        meetingSchedule_Duration: [],

        cancelMeeting_Labels: [],
        cancelMeeting_Title: "",
        cancelMeeting_Description: "",

        commonComponents_Labels: [],

        coachingSessions: {},

        noCoachLabels: [],
        noCoachTitle: "",
        noCoachDescription: "",

        awatingCoachTitle: "",
        awatingCoachDescription: "",

        NoCoachRequestMeeting_Title: "",
        NoCoachRequestMeeting_Labels: [],
        NoCoachRequestMeeting_TimeSlots: [],

        NoCoachRescheduletMeeting_Title: "",
        NoCoachRescheduletMeeting_Labels: [],
        NoCoachRescheduletMeeting_TimeSlots: [],

        ScheduleCoachingSession_Title: "",
        ScheduleCoachingSession_Labels: [],

        SupportedTimeFormat: ""
    });
    const [screenLoader, setScreenLoader] = useState(false);
    const [mycoachData, setMycoachData] = useState({});
    const [mycoachStatus, setmycoachStatus] = useState();
    const appInsights = useAppInsightsContext();

    useEffect(() => {
        fetchCMSContent();
    }, [context?.language]);

    useEffect(() => {
        setScreenLoader(true);
        window.scrollTo(0,0);
        getMyCoach();
        TrackActions.PageViewRecord(appInsights, "Coach")
        TrackActions.PageAction("Coach")
    }, []);

    const fetchCMSContent = async () => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));

        let payload = {
            PageName: "MyCoach"
        };
        await coachActions.GetCMSJobLabels(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    resData.Pages.filter((x) => {
                        if (x.Key === "MyCoachPage") {
                            refCmsContent.labels =
                                x.Components["MyCoachPage.MyCoachCoachAssigned"]?.Labels;

                            refCmsContent.pageBannerImage =
                                x.Components["MyCoachPage.MyCoach"]?.MediaData?.LargeImage?.ImageUrl;

                            refCmsContent.ImageList =
                                x.Components["MyCoachPage.MyCoachCoachAssigned"]?.ImageList;

                            refCmsContent.page_Title = x?.Title;
                            refCmsContent.page_Desc = x?.Description;

                            // CMS for meeting Confirmation in Coaching session
                            refCmsContent.meetingConfirm_Title =
                                x.Components["MyCoachPage.MyCoachMeetingConfirmation"]?.Title;

                            refCmsContent.meetingConfirm_Labels =
                                x.Components["MyCoachPage.MyCoachMeetingConfirmation"]?.Labels;

                            // CMS for Schedule a meeting
                            refCmsContent.meetingSchedule_Title =
                                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Title;

                            refCmsContent.meetingSchedule_Labels =
                                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

                            // CMS for Re-Schedule a meeting in Coaching session
                            refCmsContent.meetingReschedule_Title =
                                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Title;

                            refCmsContent.meetingReschedule_Labels =
                                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Labels;

                            let arrReScheduleMeetingDuration = []
                            x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.LOVList.filter((eleLOV) => {
                                if (eleLOV.Key === "MyCoachPage.MyCoachRescheduleMeeting.Duration") {
                                    eleLOV?.Values?.map((eleVal => {
                                        arrReScheduleMeetingDuration.push({
                                            "name": eleVal.Value,
                                            "value": eleVal.ID
                                        })
                                    }))
                                }
                            })
                            arrReScheduleMeetingDuration.unshift({ name: "Select", value: "" })
                            refCmsContent.meetingReschedule_Duration = arrReScheduleMeetingDuration

                            // CMS for Common Component
                            refCmsContent.commonComponents_Labels =
                                x.Components["CommonComponent"]?.Labels;

                            refCmsContent.cancelMeeting_Labels =
                                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;

                            refCmsContent.cancelMeeting_Title =
                                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Title;

                            refCmsContent.cancelMeeting_Description =
                                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Description;

                            refCmsContent.coachingSessions =
                                x.Components["MyCoachPage.MyCoachCoachingSessions"];

                            refCmsContent.noCoachLabels =
                                x.Components["MyCoachPage.NoSpecificCoach"]?.Labels;
                            refCmsContent.noCoachTitle =
                                x.Components["MyCoachPage.NoSpecificCoach"]?.Title;
                            refCmsContent.noCoachDescription =
                                x.Components["MyCoachPage.NoSpecificCoach"]?.Description;
                            refCmsContent.awatingCoachTitle =
                                x.Components["MyCoachPage.AwaitingCoachAssignment"]?.Title;
                            refCmsContent.awatingCoachDescription =
                                x.Components["MyCoachPage.AwaitingCoachAssignment"]?.Description;

                            /// No Coach Request Meeting CMS
                            refCmsContent.NoCoachRequestMeeting_Title =
                                x.Components["MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"]?.Title;
                            refCmsContent.NoCoachRequestMeeting_Labels =
                                x.Components["MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"]?.Labels;
                            let arrTimeSolts = []
                            x.Components["MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"]?.LOVList.filter((eleLOV) => {
                                if (eleLOV.Key === "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.TimeSlot") {
                                    eleLOV?.Values?.map((eleVal => {
                                        arrTimeSolts.push({
                                            "name": eleVal.Value,
                                            "value": eleVal.Value
                                        })
                                    }))
                                }
                            });
                            refCmsContent.NoCoachRequestMeeting_TimeSlots = arrTimeSolts
                            let arrTimeDur = []
                            x.Components["MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"]?.LOVList.filter((eleLOV) => {
                                if (eleLOV.Key === "MyCoachPage.MyCoachRequestMeeting.Duration") {
                                    eleLOV?.Values?.map((eleVal => {
                                        arrTimeDur.push({
                                            "name": eleVal.Value,
                                            "value": eleVal.Value
                                        })
                                    }))
                                }
                            })

                            refCmsContent.NoCoachRequestMeeting_TimeDur = arrTimeDur

                            ///No Coach ReSchdeule Meeting CMS
                            refCmsContent.NoCoachRescheduletMeeting_Title =
                                x.Components["MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"]?.Title;
                            refCmsContent.NoCoachRescheduletMeeting_Labels =
                                x.Components["MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"]?.Labels;
                            let arrReScheduleTimeSolts = []
                            x.Components["MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"]?.LOVList.filter((eleLOV) => {
                                if (eleLOV.Key === "MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned.TimeSlot") {
                                    eleLOV?.Values?.map((eleVal => {
                                        arrReScheduleTimeSolts.push({
                                            "name": eleVal.Value,
                                            "value": eleVal.Value
                                        })
                                    }))
                                }
                            })
                            refCmsContent.NoCoachRescheduletMeeting_TimeSlots = arrReScheduleTimeSolts

                            /// for user story 5508 Schedule a coaching session component 
                            refCmsContent.ScheduleCoachingSession_Title =
                                x.Components["MyCoachPage.MyCoachScheduleCoachingSession"]?.Title;
                            refCmsContent.ScheduleCoachingSession_Labels =
                                x.Components["MyCoachPage.MyCoachScheduleCoachingSession"]?.Labels;
                        }
                    });

                    resData.MarketDetails.filter((ele) => {
                        refCmsContent.SupportedTimeFormat = ele.SupportedTimeFormat["Value"]
                    })
                    setCmsContent(refCmsContent);
                    window.sessionStorage.setItem("CoachCMS", JSON.stringify(refCmsContent))
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const fetchLabel = (key, section) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let label = "";
        if (refCmsContent?.[section].length > 0) {
            refCmsContent[section].map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchImage = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let ImageURL = "";
        if (refCmsContent?.ImageList?.length > 0) {
            refCmsContent.ImageList.map((x) => {
                if (x.Key === key) {
                    ImageURL = x.ImageURL;
                }
            });
        }
        return ImageURL;
    };

    const getMyCoach = async () => {
        await coachActions
            .GetMyCoach()
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        setMycoachData(res.data && res.data);
                        setmycoachStatus(res.status)
                        window.sessionStorage.setItem("CoachDetails", JSON.stringify(res.data))
                    }
                    else {
                        setmycoachStatus(204)
                        setMycoachData({});
                    }
                    setScreenLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setmycoachStatus(400)
                setMycoachData({});
                setScreenLoader(false);
            });
    };
    /// Below UseState for popup screen Meeting confirmation (5703)
    const [coachSessionReload, setcoachSessionReload] = useState(0);

    const [displayMeetingConfirm, setdisplayMeetingConfirm] = useState(false);
    const [displayMeetingReschedule, setdisplayMeetingReschedule] = useState(false);
    const [displayMeetingCancel, setdisplayMeetingCancel] = useState(false);
    const [displayMeetingSchedule, setdisplayMeetingSchedule] = useState(false);

    const [myConfirmMeetingData, setmyConfirmMeetingData] = useState({
        date: "",
        time: "",
    });
    const [myRescheduleMeetingData, setmyRescheduleMeetingData] = useState({});
    const [myCancelMeetingData, setmyCancelMeetingData] = useState({})
    const [closeMeetingRescheduleConfirmation, setcloseMeetingRescheduleConfirmation] = useState(false);
    const [reshceduleMeetingDataNoCoachData, setreshceduleMeetingDataNoCoachData] = useState({})

    const fnGetConfirmMeetingData = (objMeeting) => {
        setmyConfirmMeetingData({
            date: objMeeting["Date"],
            time: objMeeting["Time"],
        });
    };

    const fnGetReshceduleMeetingData = (objMeeting) => {
        setmyRescheduleMeetingData(objMeeting);
    };

    const fnGetCancelMeetingData = (objMeeting) => {
        setmyCancelMeetingData(objMeeting)
    }

    const fnGetReshceduleMeetingDataNoCoach = (objMeeting) => {
        setreshceduleMeetingDataNoCoachData(objMeeting)
    }

    const fnCloseRescheduleDailog = (nflag, prescheduleTime, prescheduleDate) => {
        if (prescheduleTime !== "" || prescheduleDate !== "") {
            setcloseMeetingRescheduleConfirmation(true);
        } else {
            setdisplayMeetingReschedule(nflag);
        }
    }

    const [displayNoCoachRequestMeeting, setdisplayNoCoachRequestMeeting] = useState(false);
    const [displayNoCoachReScheduleMeeting, setdisplayNoCoachReScheduleMeeting] = useState(false);
    const [sStartDate, setsStartDate] = useState("");
    const [noCoachReload, setnoCoachReload] = useState(0);

    const [displayUpcomingEventDetails, setdisplayUpcomingEventDetails] = useState(false);
    const [upcomingMeetingData, setupcomingMeetingData] = useState({})

    const fnGetUpcomingMeetingData = (objMeeting) => {
        setupcomingMeetingData(objMeeting);
    };

    const [displayCompletedEventDetails, setdisplayCompletedEventDetails] = useState(false);
    const [CompletedMeetingData, setCompletedMeetingData] = useState({})

    const fnGeCompletedMeetingData = (objMeeting) => {
        setCompletedMeetingData(objMeeting);
    };

    const openNoCoachRequestMeetingPopupWindow = (bStatus, nflag, objData) => {
        if (nflag === 0) {
            setdisplayNoCoachRequestMeeting(bStatus)
        }
        else {
            setdisplayNoCoachReScheduleMeeting(bStatus)
            setreshceduleMeetingDataNoCoachData(objData)
        }
        let dtToday = (new Date())
        let sStartDate = dtToday.getFullYear() + "-" + ('0' + (dtToday.getMonth() + 1)).slice(-2) + "-" + ('0' + (dtToday.getDate() + 1)).slice(-2)
        setsStartDate(sStartDate)
    }


    if (screenLoader) {
        return (
            <div>
                <Spinner spinnerTimer={screenLoader} screenLoader={true} />
            </div>
        );
    }

    return (
        <>
            {/* <Cirrus.Page
                background="primary"
                // backgroundShape="double"
                header={<HeaderWithNavigation />}
                className="myCoach"
            > */}
            <div className="myCoach">
                <Cirrus.PageBanner
                    bodyText={cmsContent?.page_Desc}
                    heading={cmsContent?.page_Title}
                    maskImageSource={cmsContent?.pageBannerImage}
                />
                {(Object.keys(mycoachData).length > 0 && mycoachStatus === 200) &&
                    <Cirrus.OneColumnLayout type="wrapped" className="coach-column-layout">
                        <CoachList
                            fetchLabel={fetchLabel}
                            fetchImage={fetchImage}
                            mycoachData={mycoachData}
                        />
                        {Object.keys(cmsContent?.coachingSessions).length > 0 && (
                            <CoachingSessions
                                openConfirmation={setdisplayMeetingConfirm}
                                openReschedule={setdisplayMeetingReschedule}
                                cancelMeeting={setdisplayMeetingCancel}
                                cmsData={cmsContent && cmsContent?.coachingSessions}
                                GetConfirmMeetingData={fnGetConfirmMeetingData}
                                GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                                GetCancelMeetingData={fnGetCancelMeetingData}
                                coachSessionReload={coachSessionReload}
                                openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                                GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                                openCompletedEventDetailDlg={setdisplayCompletedEventDetails}
                                GetCompletedMeetingData={fnGeCompletedMeetingData}
                                CommomCompViewAllButtonText={fetchLabel("CommonComponent.CommonViewAll", "commonComponents_Labels")}
                                calledFrom={"coach"}
                                removeCompltedEvent={false}
                            />
                        )}
                        <CoachMeetingRequest
                            openMeetingRequestDialog={setdisplayMeetingSchedule}
                            cmsData={cmsContent}
                            fetchLabel={fetchLabel}
                        ></CoachMeetingRequest>
                    </Cirrus.OneColumnLayout>
                }
                {(Object.keys(mycoachData).length === 0 && mycoachStatus === 204) &&
                    <Cirrus.OneColumnLayout type="wrapped">
                        <NoCoachAssignment
                            fetchLabel={fetchLabel}
                            fetchImage={fetchImage}
                            cmsData={cmsContent}
                            openNoCoachMeetingRequestDialog={openNoCoachRequestMeetingPopupWindow}
                            compReload={noCoachReload}
                            GetMeetingData={fnGetReshceduleMeetingDataNoCoach}
                            cmsDataCoaching={cmsContent && cmsContent?.coachingSessions}
                        />
                    </Cirrus.OneColumnLayout>
                }
            </div>
            {/* </Cirrus.Page> */}

            {displayMeetingSchedule && (
                <MeetingScheduleDialog
                    fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    closeDialog={setdisplayMeetingSchedule}
                    mycoachData={mycoachData}
                    ReloadComp={setcoachSessionReload}
                    todayButtonLabel={fetchLabel("CalendarPage.ScheduleAMeetingWithCoach.TodayButton", "meetingSchedule_Labels")}
                    nextButtonLabel={fetchLabel("CalendarPage.ScheduleAMeetingWithCoach.NextButton", "meetingSchedule_Labels")}
                    backButtonLabel={fetchLabel("CommonComponent.CommonBackButton", "commonComponents_Labels")}
                >
                </MeetingScheduleDialog>
            )}

            {/* below for Coaching session */}
            {displayMeetingConfirm && (
                <MeetingConfirmtionDialog
                    fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    mycoachData={mycoachData}
                    myConfirmMeetingData={myConfirmMeetingData}
                    closeDialog={setdisplayMeetingConfirm}
                ></MeetingConfirmtionDialog>
            )}

            {displayMeetingReschedule && (
                <MeetingRescheduleDialog
                    fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    mycoachData={mycoachData}
                    dialogStatus={setdisplayMeetingReschedule}
                    closeDialog={fnCloseRescheduleDailog}
                    confirmDialogStatus={closeMeetingRescheduleConfirmation}
                    closeconfirmDialog={setcloseMeetingRescheduleConfirmation}
                    rescheduleMeetingData={myRescheduleMeetingData}
                    ReloadComp={setcoachSessionReload}
                    todayButtonLabel={fetchLabel("CalendarPage.ScheduleAMeetingWithCoach.TodayButton", "meetingSchedule_Labels")}
                    nextButtonLabel={fetchLabel("CalendarPage.ScheduleAMeetingWithCoach.NextButton", "meetingSchedule_Labels")}
                    backButtonLabel={fetchLabel("CommonComponent.CommonBackButton", "commonComponents_Labels")}
                >
                </MeetingRescheduleDialog>
            )}

            {displayMeetingCancel && (
                <MeetingCancellationDailog fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    mycoachData={mycoachData}
                    closeDialog={setdisplayMeetingCancel}
                    cancelMeetingData={myCancelMeetingData}
                    ReloadComp={setcoachSessionReload}
                    dialogStatus={setdisplayMeetingCancel}
                >
                </MeetingCancellationDailog>
            )}

            {/* below for NoCoach */}
            {displayNoCoachRequestMeeting && (
                <NoCoachScheduleMeeting
                    fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    closeDialog={setdisplayNoCoachRequestMeeting}
                    ReloadComp={setnoCoachReload}
                    StartDate={sStartDate}
                ></NoCoachScheduleMeeting>
            )}

            {displayNoCoachReScheduleMeeting && (
                <NoCoachReScheduleMeeting
                    fetchLabel={fetchLabel}
                    cmsContent={cmsContent}
                    closeDialog={setdisplayNoCoachReScheduleMeeting}
                    ReloadComp={setnoCoachReload}
                    StartDate={sStartDate}
                    meetingData={reshceduleMeetingDataNoCoachData}
                ></NoCoachReScheduleMeeting>
            )}

            {displayUpcomingEventDetails && (<UpcomigEventDetails
                mycoachData={mycoachData}
                cmsCoachContent={cmsContent}
                eventData={upcomingMeetingData}
                fetchLabel={fetchLabel}
                closeDialog={setdisplayUpcomingEventDetails}
                ReloadComp={setcoachSessionReload}
                disableButtons={false}>
            </UpcomigEventDetails>
            )
            }

            {displayCompletedEventDetails && (<UpcomigEventDetails
                mycoachData={mycoachData}
                cmsCoachContent={cmsContent}
                eventData={CompletedMeetingData}
                fetchLabel={fetchLabel}
                closeDialog={setdisplayCompletedEventDetails}
                ReloadComp={setcoachSessionReload}
                disableButtons={true}>
            </UpcomigEventDetails>
            )
            }

            {/* <Footer isLoggedIn={true} /> */}
        </>
    );
}

export default Coach;
