//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from 'manpowergroup-cirrus';
import { Link } from 'react-router-dom';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import { ResourcesActions } from './resourcesActions';
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import cardVertical from "manpowergroup-cirrus/assets/images/cardVertical.jpg";
import './style.css';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function ResourcesVideo() {
    const context = useContext(Context);
    const resourceId = useParams();
    const navigate = useNavigate();
    const [screenLoader, setScreenLoader] = useState(false);
    const [resourceData, setResourceData] = useState([]);
    const [cmsResources, setCmsResources] = useState([]);
    const [saveLikeSpinner, setSaveLikeSpinner] = useState(false);
    const [relatedResourcesData, setRelatedResourcesData] = useState([])
    const resourceDetails = context.resourceDetails;
    const appInsights = useAppInsightsContext();
    let StartTime


    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCMS();
        fetchResourceAssessmentData()
        StartTime = new Date();
    }, [context.language])

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCMS();
    }, [relatedResourcesData, context.language])

    useEffect(() => {
        return () => {
            console.log("Leaving")
            TrackActions.TimeRecord(StartTime, JSON.parse(sessionStorage.data), appInsights)

        }
    }, [])

    let additionalProperties = { "ResourceType": "Article", "ResourceID": resourceData?.resourceId };
    sessionStorage.setItem('data', JSON.stringify(additionalProperties))

    const fetchResourceAssessmentData = () => {
        setScreenLoader(true)
        console.log(resourceDetails)
        // if (resourceDetails) {
        //     setResourceData(resourceDetails)
        //     console.log(resourceDetails)
        // } else {
        //     if (saveLikeSpinner === true) {
        //         setScreenLoader(false)
        //     } else {
        //         setScreenLoader(true)
        //     }
        let ProgramData = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : "";

        let resourceType = "resources"
        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType,
            "ProgramClassIds": ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : ""
        }
        ResourcesActions.GetResourceData(payload).then(
            (res) => {
                if (res.status === 200) {
                    console.log(payload)
                    let resData = res?.data && res?.data?.responsemodel;
                    let resourceData = resData && resData.filter((item, idx) => item.resourceId === resourceId.id);
                    console.log(resourceData && resourceData[0] && resourceData[0], "assess");
                    ActivityAction.SaveActivity(resourceData && resourceData[0]?.resourceId, resourceData && resourceData[0]?.resourceType?.id, resourceData && resourceData[0]?.title)
                    TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resourceData && resourceData[0]?.resourceId, "Entity Name": resourceData && resourceData[0]?.title, "CardType": "Resource" })
                    TrackActions.PageAction("ViewCard", { "Entity Id": resourceData && resourceData[0]?.resourceId, "Entity Name": resourceData && resourceData[0]?.title, "CardType": "Resource" })
                    setResourceData(resourceData && resourceData[0] && resourceData[0])
                    let additionalProperties = { "ResourceType": "Article", "ResourceID": resourceData[0]?.resourceId };
                    TrackActions.EventTrack(appInsights, "ViewResource", additionalProperties)
                    TrackActions.PageAction("ViewResource", additionalProperties)
                    updateCompletedStatus("status", resourceData && resourceData[0] && resourceData[0])
                    fetchRelatedResourcesData(resourceData && resourceData[0] && resourceData[0])

                } else {
                    setScreenLoader(false)
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false)
            }
        );

    };



    const updateEvents = async (e, saving) => {
        if (!resourceDetails) {
            if (saving) {
                saveLikeArticle(e, 'save');
            } else {
                fetchResourceAssessmentData();
            }
        } else {
            let id = resourceDetails.id;
            let payload = {
                "ResourceType": "External",
                "EventType": 'onDemand',
                // "EventDate": '03-08-22',
                "IsSaved": saving ? !resourceDetails.isSaved : resourceDetails.isSaved,
                "Status": resourceDetails.status,
                "IsAttended": saving ? resourceDetails.isAttended : true,
                // "EndTime": "00:00"
            }

            await ResourcesActions.UpdateEvent(payload, id).then((res) => {
                if (res.status === 200) {
                    console.log('event completed');
                    if (saving) {
                        resourceDetails.isSaved = !resourceDetails.isSaved;
                        setResourceData(resourceDetails)
                    }
                }
            }, (err) => {
                console.log(err);

            })
        }
    }

    const updateCompletedStatus = (
        type,
        data
    ) => {
        let resourceType = data && data?.resourceCategoryType?.id;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            candidateId: candidateID,
            learningResourceId: data && data?.resourceId,
            lastAnsweredPageId: data && data?.lastAnsweredPageId,
            learningResourceType: data && data?.resourceCategoryType?.id,
            resourceTypeId: data && data?.resourceType?.id,
            learningResourceStatus: type === "status" && "completed",
            isSaved:
                type === "save" ? (data && data?.isSaved == true ? false : true) : data && data?.isSaved,
            isLiked:
                type === "like" ? (data && data?.isLiked == true ? false : true) : data && data?.isLiked,
        };

        ResourcesActions.SaveResourceAssessmentCard(resourceType, data && data?.resourceId, payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    console.log(resData);
                    updateStatus(resourceType, data && data?.resourceId, payload)
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    const updateStatus = (
        resourceType, id, payload
    ) => {
        ResourcesActions.UpdateStatus(resourceType, id, payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res;
                } else {
                    console.log("api not succeeded")
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const saveAssessmentCard = (

        e,
        id,
        savedStatus,
        learningResourceStatus,
        pageNumber,
        resourceSubType
    ) => {
        setScreenLoader(true)
        e.preventDefault();
        let resourceType = resourceData && resourceData?.resourceCategoryType?.id;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            candidateId: candidateID,
            learningResourceId: id,
            lastAnsweredPageId: pageNumber,
            learningResourceType: resourceData && resourceData?.resourceCategoryType?.id,
            resourceTypeId: resourceData && resourceData?.resourceType?.id,
            learningResourceStatus: "completed",
            isSaved: savedStatus == true ? false : true
        };
        ResourcesActions.SaveResourceAssessmentCard(resourceType, id, payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    console.log(resData, "saveAssessmentCard");
                    fetchRelatedResourcesData(resourceData && resourceData);
                    updateStatus(resourceType, id, payload)
                } else {
                    setScreenLoader(true)
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(true)
            }
        );
    };

    const fetchRelatedResourcesData = (data) => {
        let ProgramData = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : "";

        let payload = {
            "focusArea": data && data?.focusArea?.map((item) => item.id),
            "ResourceCategory": data && data?.resourceCategoryType?.id,
            "resourceSubCategory": data && data?.resourceType?.id,
            "learningResourceType": data && data?.resourceCategoryType?.id,
            "isAllLearningResources": "true",
            "recommended": "false",
            "ProgramClassIds": data && data?.resourceCategoryType?.id == "resources" ? ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : "" : null
        }
        ResourcesActions.GetResourceData(payload).then(
            (res) => {
                if (res.status === 200) {
                    console.log(payload)
                    let resData = res?.data && res?.data?.responsemodel;
                    setRelatedResourcesData(resData)
                    setScreenLoader(false)
                } else {
                    setScreenLoader(false)
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false)
            }
        );
    }

    const fetchCMS = async () => {
        let refCmsResources = JSON.parse(JSON.stringify(cmsResources));
        let payload = {
            Ids: [resourceId.id]
        };
        await ResourcesActions.GetResourcesCMS(payload).then(res => {
            if (res.status === 200) {
                refCmsResources.browserTitle = res.data.PageProperties.MetaInformation.BrowserTitle
                refCmsResources.metaDescription = res.data.PageProperties.MetaInformation.MetaDescription
                refCmsResources.metadata = res.data.PageProperties.MetaInformation.Metadata
                refCmsResources.title = res.data.PageProperties.MetaInformation.BrowserTitle

                refCmsResources.focusArea = res.data.PageProperties.AreaofFocus[0]
                refCmsResources.resourceType = res.data.PageProperties.ResourceType
                refCmsResources.resourceCategoryType = res.data.PageProperties.ResourceCategoryType
                refCmsResources.duration = res.data.PageProperties.Duration

                refCmsResources.azureBlobDomain = res.data.PageProperties.AzureBlobDomain
                refCmsResources.articleId = res.data.PageProperties.Id
                if (resourceDetails && refCmsResources.resourceCategoryType.Value === "Events") {
                    refCmsResources.largeImage = resourceDetails.largeImage.imageUrl
                    refCmsResources.mediumImage = resourceDetails.mediumImage.imageUrl
                    refCmsResources.smallImage = resourceDetails.smallImage.iageUrl

                    refCmsResources.articleTitle = resourceDetails.title
                    refCmsResources.description = resourceDetails.shortDescription
                    refCmsResources.videoUrl = resourceDetails.mediaUrl
                } else {

                    refCmsResources.largeImage = res.data.PageProperties.MediaBase.LargeImage.ImageUrl
                    refCmsResources.mediumImage = res.data.PageProperties.MediaBase.MediumImage.ImageUrl
                    refCmsResources.smallImage = res.data.PageProperties.MediaBase.SmallImage.ImageUrl

                    refCmsResources.articleTitle = res.data.PageProperties.CirrusPageData.Title
                    refCmsResources.description = res.data.PageProperties.CirrusPageData.Description
                    refCmsResources.videoUrl = res.data.PageProperties.MediaUrl
                }

                Object.keys(res.data.Components).forEach(key => {
                    if (key !== "Common Resource Component") {
                        refCmsResources.articlePage = res.data.Components[key]
                    } else {
                        res.data.Components[key].find(item => {
                            if (item.Key === "CommonResourceComponent") {
                                refCmsResources.commonResourceLabels = item.Labels
                            }
                        })
                    }
                })

                console.log(refCmsResources, 'refCmsResourcesrefCmsResources');
                setCmsResources(refCmsResources);
            } else {
                console.log(res)
            }
        }, err => {
            console.log(err);
        })
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsArticles = JSON.parse(JSON.stringify(cmsResources));
        let label = '';
        if (refCmsArticles?.commonResourceLabels) {
            refCmsArticles?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const saveLikeArticle = (
        e,
        type
    ) => {
        setScreenLoader(false)
        e.preventDefault();
        let resourceType = resourceData && resourceData?.resourceCategoryType?.id;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            candidateId: candidateID,
            learningResourceId: resourceData && resourceData?.resourceId,
            lastAnsweredPageId: resourceData && resourceData?.lastAnsweredPageId,
            learningResourceType: resourceData && resourceData?.resourceCategoryType?.id,
            resourceTypeId: resourceData && resourceData?.resourceType?.id,
            learningResourceStatus: resourceData && resourceData?.learningResourceStatus,
            isSaved:
                type === "save" ? (resourceData && resourceData?.isSaved == true ? false : true) : resourceData && resourceData?.isSaved,
            isLiked:
                type === "like" ? (resourceData && resourceData?.isLiked == true ? false : true) : resourceData && resourceData?.isLiked,
        };

        ResourcesActions.SaveResourceAssessmentCard(resourceType, resourceData && resourceData?.resourceId, payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    setSaveLikeSpinner(true);
                    fetchResourceAssessmentData()
                    updateStatus(resourceType, resourceData && resourceData?.resourceId, payload)
                }
            },
            (err) => {
                console.log(err);
                setSaveLikeSpinner(true);
            }
        );
    };

    const fetchDBhoverURL = () => {
        let date = new Date();
        let param1 = Math.floor(Math.random() * 10).toString();
        let param2 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(1, 2).toString();
        let param3 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(0, 1).toString();
        let param4 = (date.getFullYear()?.toString()?.length < 2 ? date.getFullYear().toString() : date.getFullYear().toString()).substring(3, 4).toString();
        let param5 = Math.floor(Math.random() * 10).toString();
        let param6 = Math.floor(Math.random() * 10).toString();
        let param7 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(1, 2).toString();
        let param8 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(0, 1).toString();
        let param9 = Math.floor(Math.random() * 10).toString();

        let st = param1 + param2 + param3 + param4 + param5 + param6 + param7 + param8 + param9;
        let userInfo = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
        let programCountry = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        let URL = `http://signon.oneSource.com?LogonUserID=${userInfo.emailAddress}&ST=${st}&AT=917360db07a6e0cdbbde3ff088b0e70d&Fname=${userInfo.firstName}&Lname=${userInfo.lastName}&Country=${programCountry.programCountryCode}`
        window.open(URL);
    };

    const fetchLesEchosURL = async (id) => {
        await ResourcesActions.ExternalToolsRedirectURL(id).then((res) => {
            if (res.status === 302) {
                window.open(res.data);
            } else {
                console.log("api not succeeded");
            }
        },
            (err) => {
                console.log(err);
            }
        );
    }

    const getResumeBuilderUrl = async () => {
        await ResourcesActions.GetResmeBuilderUrl().then(
            (res) => {
                if (res.status === 200) {
                    window.open(res?.data?.url);
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    const cardRedirectHandler = (item) => {
        if (item?.resourceType?.id === "article" || item?.resourceType?.id === "video" || item?.resourceType?.id === "document" || item?.resourceType?.id === "E3") {
            window.open(`#/article-page/${item?.resourceId}`, "_self");
        } else {
            if (item?.key === "Tools.DnBHover") {
                fetchDBhoverURL()
            } else if (item?.key === "Tools.SmartResumeEditor") {
                getResumeBuilderUrl();
            } else if (item?.key === "Tools.OnDemandVideoInterviews") {
                window.open(`#/iview-welcome`, "_self");
            } else if (item?.key === "Tools.30SecondCommercial") {
                window.open(`#/iview-interview-start/${item?.resourceId}/${item?.externalId}`, "_self");
            } else if (item?.key === "Tools.Cobra") {
                window.open(process.env.REACT_APP_COBRA_TOOL_URL);
            } else if (item?.key === "Tools.FirstEco") {
                window.open(process.env.REACT_APP_FIRSTECO_TOOL_URL);
            } else if (item?.key === "Tools.LesEchos" || item?.key === "Tools.Diane" || item?.key === "Tools.Corporama" || item?.key === "Tools.Executives") {
                fetchLesEchosURL(item?.resourceId)
            } else if (item?.key === "Tools.AbintegroCareerCentre") {
                window.open(process.env.REACT_APP_ABINTEGRO_TOOL_URL);
            }
            else {
                navigate(item?.resourceUrl?.url);
            }
        }
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="resources-page">
            {/* <Cirrus.Page className='resources-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            <>
                <Container fluid className='background-White py-5 resource-page-container'>
                    <Row>
                        <Col md="9" lg="9" className='mb-3 pb-5'>
                            <Cirrus.Button isSecondary onClick={() => { navigate(window.history.back()) }} className="mb-5">
                                Back
                            </Cirrus.Button>
                            <Cirrus.Header size='h3' className='assessment-title'>{cmsResources?.articleTitle}</Cirrus.Header>
                            <div className='d-flex align-items-center mt-4 mb-3'>
                                <button className='saveBtn' onClick={(e) => { updateEvents(e, true) }}>
                                    {resourceData && resourceData?.isSaved === true ? <i className='icon-saved'></i> : <i className='icon-save'></i>}
                                    <span className='saveBtnText'>
                                        {resourceData && resourceData?.isSaved === true ? "Saved" : "Save"}
                                    </span>
                                </button>
                                <Cirrus.Label id='category-label'>{cmsResources?.focusArea}</Cirrus.Label>
                                <Cirrus.Label id="cardtype">
                                    {cmsResources?.resourceCategoryType?.Value} <sup>.</sup> {cmsResources?.resourceType?.Value} <sup>.</sup> <span className='duration'>
                                        {cmsResources?.resourceType?.Value == "Document" || cmsResources?.resourceType?.Value == "Article"
                                            ? fetchCommonResourceLabel("CommonResourceComponent.ReadIn")
                                            : cmsResources?.resourceType?.Value == "Video" || cmsResources?.resourceType?.ID == "E3"
                                                ? fetchCommonResourceLabel("CommonResourceComponent.WatchIn") : ""
                                        } <sup> </sup> {cmsResources?.duration}
                                    </span>
                                </Cirrus.Label>
                            </div>
                            <div>
                                {<Cirrus.Button size='small' className='likeBtn' onClick={(e) => { saveLikeArticle(e, "like") }}>
                                    {resourceData && resourceData?.isLiked === true ? <Icon icon="heart_fill" size={16} color="#9D323D" /> : <Icon icon="heart" size={16} color="#000" />}
                                    <span className='likeBtnText'>
                                        <sup> </sup>
                                        {resourceData && resourceData?.likeCount === 0 ? " " + "LIKE"
                                            : resourceData && resourceData?.likeCount === 1
                                                ? resourceData && resourceData?.likeCount + " " + "LIKE"
                                                : resourceData && resourceData?.likeCount > 1
                                                    ? resourceData && resourceData?.likeCount + " " + "LIKES" : ""}
                                    </span>
                                    {console.log(resourceData)}
                                </Cirrus.Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='background-White'>
                    <Row>
                        <Col md="12" lg="12">
                            <hr />
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='background-White p-5 pb-0'>
                    <Container>
                        <p className='resources-content'>
                            {cmsResources?.description}
                        </p>
                        <div className='document-embed'>
                            <Cirrus.ResolurcesVideoLightbox
                                closeButtonText="Close lightbox"
                                controls='true'
                                mp4Src={cmsResources?.videoUrl}
                                onClose={() => { }}
                                onPlay={() => updateEvents(false)}
                                playButtonText="Play video"
                                posterImageUrl={cmsResources?.largeImage}
                            />
                        </div>
                    </Container>
                </Container>
            </>
            {
                relatedResourcesData?.length !== 0 &&
                <div className="related-resources">
                    <Container fluid>
                        <div className="carousel">
                            {relatedResourcesData &&
                                <Cirrus.Carousel
                                    nextIconButtonProps={{
                                        icon: 'arrow_forward',
                                        onClick: () => { },
                                        text: 'Next',
                                        type: 'button'
                                    }}
                                    heading={fetchCommonResourceLabel("CommonResourceComponent.RealtedResourcesTitle")}
                                    subheading={fetchCommonResourceLabel("CommonResourceComponent.ReleatedResourcesDescription")}
                                    className='carousel'
                                    previousIconButtonProps={{
                                        icon: 'arrow_back',
                                        onClick: function noRefCheck() { },
                                        text: 'Previous',
                                        type: 'button'
                                    }}
                                >
                                    {relatedResourcesData && relatedResourcesData.map((item) => (
                                        <Cirrus.RelatedResourcesCard
                                            className="card"
                                            title={item?.title}
                                            description={item?.shortDescription}
                                            onCardClick={()=>{cardRedirectHandler(item)}}
                                            tags={item?.focusArea?.map((item) => item.value)}
                                            image={{
                                                src:
                                                    item &&
                                                    item.largeImage &&
                                                    item.largeImage.imageUrl &&
                                                    item.largeImage.imageUrl,
                                            }}
                                            isSaved={item?.isSaved}
                                            saveButton={{
                                                label: "Save",
                                                secondaryLabel: "Saved",
                                                onClick: (e) => {
                                                    saveAssessmentCard(
                                                        e,
                                                        item && item.resourceId && item.resourceId,
                                                        item && item.isSaved && item.isSaved,
                                                        item &&
                                                        item.learningResourceStatus &&
                                                        item.learningResourceStatus,
                                                        item.lastAnsweredPageId && item.lastAnsweredPageId,
                                                        item?.resourceType?.id
                                                    );
                                                },
                                            }}
                                            status={{
                                                name: "new",
                                                label: item?.resourceType?.value,
                                                value: item?.duration,
                                            }}
                                            type="assessment"
                                            layout="horizontal"
                                        />
                                    ))}
                                </Cirrus.Carousel>
                            }
                        </div>
                    </Container>
                </div>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    );
}

export default ResourcesVideo;
