//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "../style.css";

const EJOTemplate = ({ getResponse, getResponseEJO, fetchLabel, getrole, iyipOrg, ejoOrg, iyipRole, ejoRole, iyipCompen, ejoCompen, iyipRoleQ, ejoRoleQ }) => {
  return (
    <>
      <table
        cellPadding={5}
        cellSpacing={5}
        style={{
          width: "100%",
          border: "0px",
          borderRadius: "5px",
          textAlign: "left",
          fontSize: "3px",
        }}
      >
        <tbody>
          <tr>
            <Container>
              <Row>
                <Col lg={12}>
                  <td>
                    <div className="resultHead">
                      <Row>
                        <Col lg={12}>
                          <Row className="JobRoleHeading">
                            <Col>
                              <p className="ResultResponsHide">
                                {fetchLabel(
                                  "Assessments.EvaluateAJobOffer.CommonLabels.YourIdealPosition"
                                )}
                              </p>
                            </Col>
                            <Col>
                              <p className="ResultResponsHide">{getrole}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div
                        className="resultBack"
                        style={{ paddingRight: "30px" }}
                      >
                        <Row style={{ paddingBottom: "100px" }}>
                          <Col
                            lg={12}
                            className="JobRoleHeaderUncolored lineB"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Row className="resultBackSm">
                              <Col style={{ paddingTop: "10px" }}>
                                <p className="ResultResponsHide HiddenResponse">
                                  {fetchLabel(
                                    "Assessments.EvaluateAJobOffer.CommonLabels.AboutTheOrganisation"
                                  ).toUpperCase()}
                                </p>

                                {iyipOrg?.map(
                                  (answer, index) => {
                                    return (
                                      <span
                                        className={`Resultpointer ${getResponseEJO[0]?.Answer?.includes(
                                          answer
                                        )
                                          ? "highlgt"
                                          : "check"
                                          }`}
                                      >
                                        <p key={index}>{answer}</p>
                                      </span>
                                    );
                                  }
                                )}
                              </Col>

                              <Col style={{ paddingRight: "0px" }}>
                                <div className="JobRoleHeaderPDF">
                                  <p className="ResultResponsHide HiddenResponse">
                                    {fetchLabel(
                                      "Assessments.EvaluateAJobOffer.CommonLabels.AboutTheOrganisation"
                                    ).toUpperCase()}
                                  </p>

                                  {ejoOrg?.map(
                                    (answer, index) => {
                                      return (
                                        <span
                                          className={`Resultpointer ${getResponse[0]?.Answer?.includes(
                                            answer
                                          )
                                            ? "highlgt"
                                            : "checkWh"
                                            }`}
                                        >
                                          <p key={index}>{answer}</p>
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row style={{ paddingBottom: "150px" }}>
                          <Col
                            lg={12}
                            className="JobRoleHeaderUncolored lineB"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Row className="resultBackSm">
                              <Col style={{ paddingTop: "10px" }}>
                                <p className="ResultResponsHide HiddenResponse">
                                  {fetchLabel(
                                    "Assessments.EvaluateAJobOffer.CommonLabels.IdealJobRole"
                                  ).toUpperCase()}
                                </p>

                                {iyipRole?.map(
                                  (answer, index) => {
                                    return (
                                      <span
                                        className={`Resultpointer ${iyipRoleQ[index] == ejoRoleQ[index] ? "highlgt" :
                                          "checkWh"
                                          }`}
                                      >
                                        <p key={index}>{answer}</p>
                                      </span>
                                    );
                                  }
                                )}
                              </Col>
                              <Col style={{ paddingRight: "0px" }}>
                                <div className="JobRoleHeaderPDF">
                                  <p className="ResultResponsHide HiddenResponse">
                                    {fetchLabel(
                                      "Assessments.EvaluateAJobOffer.CommonLabels.IdealJobRole"
                                    )
                                      .substring(5)
                                      .toUpperCase()}
                                  </p>

                                  {ejoRole?.map(
                                    (answer, index) => {
                                      return (
                                        <span
                                          className={`Resultpointer ${iyipRoleQ[index] == ejoRoleQ[index] ? "highlgt" :
                                            "checkWh"
                                            }`}
                                        >
                                          <p key={index}>{answer}</p>
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: "100px" }}>
                          <Col
                            lg={12}
                            className="JobRoleHeaderUncolored "
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Row className="resultBackSm">
                              <Col style={{ paddingTop: "10px" }}>
                                <p className="ResultResponsHide HiddenResponse">
                                  {fetchLabel(
                                    "Assessments.EvaluateAJobOffer.CommonLabels.IdealCompensation"
                                  ).toUpperCase()}
                                </p>

                                {iyipCompen?.map(
                                  (answer, index) => {
                                    return (
                                      <span
                                        className={`Resultpointer ${getResponseEJO[2]?.Answer?.includes(
                                          answer
                                        )
                                          ? "highlgt"
                                          : "check"
                                          }`}
                                      >
                                        <p key={index}>{answer}</p>
                                      </span>
                                    );
                                  }
                                )}
                              </Col>
                              <Col style={{ paddingRight: "0px" }}>
                                <div className="JobRoleHeaderPDF">
                                  <p className="ResultResponsHide HiddenResponse">
                                    {fetchLabel(
                                      "Assessments.EvaluateAJobOffer.CommonLabels.IdealCompensation"
                                    )
                                      .substring(5)
                                      .toUpperCase()}
                                  </p>

                                  {ejoCompen?.map(
                                    (answer, index) => {
                                      return (
                                        <span
                                          className={`Resultpointer ${getResponse[2]?.Answer?.includes(
                                            answer
                                          )
                                            ? "highlgt"
                                            : "checkWh"
                                            }`}
                                        >
                                          <p key={index}>{answer}</p>
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </td>
                </Col>
              </Row>
            </Container>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EJOTemplate;
