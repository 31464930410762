//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../../environments/environment';
import { returnCountryLanguagePersona } from '../../helpers/CountryLanguage';
const http = new httpHelper();

export const FooterActions = {
    FooterLabels: footerLabels,
    GetCookieNPolicies: getCookieNPolicies,
    SaveCandisdateProfile: saveCandisdateProfile,
    SaveUserConsent:saveUserConsent,
    getCmsData_TC: getCmsData_TC,
    getCmsData_PP: getCmsData_PP,
    getCandidateConsentDetails: getCandidateConsentDetails,
    isUpdatesSummaryAvailable: isUpdatesSummaryAvailable
}

function footerLabels(payload) {
    return http.CMSpost(environments.FOOTER_CMS, payload);
}

function getCookieNPolicies(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload)
}

function saveCandisdateProfile(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_PROFILE.replace('{id}', id);
    return http.put(relpaceId, payload);
}

function getCMScontent(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function saveUserConsent( payload) {
    let userConsent = environments.CANDIDATE_CONSENT.replace('{candidateId}', payload.candidateId);
    return http.post(userConsent, payload);
}

function getCandidateConsentVersion(payload, candidateId) {
    const getUserConsent = environments.GET_CANDIDATE_CONSENT_VERSION.replace('{candidateId}', candidateId);
    return http.post(getUserConsent, payload);
}
function isUpdatesSummaryAvailable(latestVersion_TC, latestVersion_Policy, acceptedPolicyDetails) {
    if (parseInt(latestVersion_TC) > parseInt(acceptedPolicyDetails.tcAcceptedVersion) || parseInt(latestVersion_Policy) > parseInt(acceptedPolicyDetails.pcAcceptedVersion)) {
        return true;
    }
    return false;
}
// function for getting saved T&C and policy version
async function getCandidateConsentDetails(candidateID) {
    try {
        const genericData = returnCountryLanguagePersona();
        const body = {
            Country: genericData.MarketName
        }
        const res = await getCandidateConsentVersion(body, candidateID);
        if (res.status === 200) {
            return res.data;
        }
        else {
            throw new Error(`Unexpected response status: ${res.status}`);
        }
    }
    catch (err) {
        console.error("Error fetching saved version:", err);
        throw err;
    }
}
//function for get latest version for T&C
async function getCmsData_TC() {
    try {
        const res = await getCMScontent({ pagename: 'TermsOfUse' });
        if (res.status === 200) {
            return res.data
        }
        else {
            throw new Error(`Unexpected response status: ${res.status}`);
        }
    }
    catch (err) {
        console.error("Error fetching TC latest version:", err);
        throw err;
    }
}
// function for get latest version for Privacy Policy
async function getCmsData_PP() {
    try {
        const res = await getCMScontent({ pagename: 'PrivacyPolicy' });
        if (res.status === 200) {
            return res.data
        }
        else {
            throw new Error(`Unexpected response status: ${res.status}`);
        }
    }
    catch (err) {
        console.error("Error fetching TC latest version:", err);
        throw err;
    }
}