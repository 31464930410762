//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react';
import { useNavigate } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import './job.css';
import { BookMarkActions } from "./bookmarkActions";

function ToolCard({ data, cmsCardContent, loadMoreJobHandler, savedDataLength, viewAll, saveHandler, collectionCount }) {
  const navigate = useNavigate();
  const fetchLearningStatus = (id) => {
    let refCmsAssessmentContent = cmsCardContent && cmsCardContent?.commonComponent;
    let label = "";
    if (refCmsAssessmentContent?.LOVList[0]?.Values) {
      refCmsAssessmentContent?.LOVList[0]?.Values.map((x) => {
        if (x.ID === id) {
          label = x.Value;
        }
      });
    }
    return label;
  }

  const fetchSavedLabel = (id) => {
    let refCmsSavedContent = cmsCardContent && cmsCardContent?.commonComponent;
    let label = "";
    if (refCmsSavedContent?.Labels) {
      refCmsSavedContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };


  const fetchDBhoverURL = () => {
    let date = new Date();
    let param1 = Math.floor(Math.random() * 10).toString();
    let param2 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(1, 2).toString();
    let param3 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(0, 1).toString();
    let param4 = (date.getFullYear()?.toString()?.length < 2 ? date.getFullYear().toString() : date.getFullYear().toString()).substring(3, 4).toString();
    let param5 = Math.floor(Math.random() * 10).toString();
    let param6 = Math.floor(Math.random() * 10).toString();
    let param7 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(1, 2).toString();
    let param8 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(0, 1).toString();
    let param9 = Math.floor(Math.random() * 10).toString();

    let st = param1 + param2 + param3 + param4 + param5 + param6 + param7 + param8 + param9;
    let userInfo = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
    let programCountry = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
    let URL = `http://signon.oneSource.com?LogonUserID=${userInfo.emailAddress}&ST=${st}&AT=917360db07a6e0cdbbde3ff088b0e70d&Fname=${userInfo.firstName}&Lname=${userInfo.lastName}&Country=${programCountry.programCountryCode}`
    window.open(URL);
  };

  const getResumeBuilderUrl = async () => {
    await BookMarkActions.GetResmeBuilderUrl().then(
      (res) => {
        if (res.status === 200) {
          window.open(res?.data?.url);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const fetchLesEchosURL = async(id) => {
    await BookMarkActions.ExternalToolsRedirectURL(id).then((res) => {
      if (res.status === 302) {
        window.open(res.data);
      } else {
        console.log("api not succeeded");
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  const cardRedirectHandler = (item)=> {
    console.log(item)
    if(item?.resourceType?.id === "article" || item?.resourceType?.id === "video" || item?.resourceType?.id === "document" || item?.resourceType?.id === "E3") {
      window.open(`#/article-page/${item?.resourceId}`, "_self");
    } else {
      if(item?.key === "Tools.DnBHover") {
        fetchDBhoverURL();
      } else if (item?.key === "Tools.SmartResumeEditor") {
        getResumeBuilderUrl();
      } else if (item?.key === "Tools.OnDemandVideoInterviews") {
        window.open(`#/iview-welcome`, "_self");
      } else if (item?.key === "Tools.30SecondCommercial") {
        window.open(`#/iview-interview-start/${item?.resourceId}/${item?.externalId}`, "_self");
      } else if (item?.key === "Tools.Cobra") {
        window.open(process.env.REACT_APP_COBRA_TOOL_URL);
      } else if (item?.key === "Tools.FirstEco") {
        window.open(process.env.REACT_APP_FIRSTECO_TOOL_URL);
      } else if (item?.key === "Tools.LesEchos" || item?.key === "Tools.Diane" || item?.key === "Tools.Corporama" || item?.key === "Tools.Executives") {
        fetchLesEchosURL(item?.resourceId)
      } else if (item?.key === "Tools.AbintegroCareerCentre") {
        window.open(process.env.REACT_APP_ABINTEGRO_TOOL_URL);
      } 
      else {
        navigate(item?.resourceUrl?.url);
      }
    }
  }


  return (
    <>
      <ul className="jobListTwoColumn">
        {data?.map(bookmark => {
          if (bookmark && bookmark.learningResourceId) {
            return (
              <li key={bookmark?.resourceId}>
                <Cirrus.Card className=""
                  title={bookmark.title}
                  description={bookmark.shortDescription}
                  onCardClick={()=>{cardRedirectHandler(bookmark)}}
                  tags={bookmark?.focusArea?.map((item) => item.value)}
                  image={{ src: bookmark?.largeImage?.imageUrl }}
                  saveButton={{
                    label: fetchSavedLabel("CommonComponent.CommonSaveButton"),
                    secondaryLabel: fetchSavedLabel("CommonComponent.CommonSavedButton"),
                    onClick: (e) => { saveHandler(e, bookmark) }
                  }}
                  isSaved={bookmark && bookmark.isSaved && bookmark.isSaved}
                  status={{
                    name: 'new',
                    label: bookmark?.resourceType?.value,
                    value: (bookmark && bookmark.learningResourceStatus === "notstarted") && (bookmark && bookmark.resourceType && bookmark.resourceType.value === "Tool")
                      ? bookmark && bookmark.key == "Tools.OnDemandVideoInterviews" 
                      ? collectionCount
                      : "Interactive"
                      : fetchLearningStatus(bookmark && bookmark.learningResourceStatus)
                  }}

                  type='assessment'
                  layout={'horizontal'}
                />
              </li>
            )
          }
          else {
            return (
              <li key={bookmark?.resourceId}>
                <Cirrus.Card className=""
                  title={bookmark?.title}
                  description={bookmark?.shortDescription}
                  onCardClick={()=>{
                    bookmark && bookmark.collectionSaved ? window.open(`#/iview-welcome`, "_self") : window.open(`#/iview-interview-start/${bookmark?.id}/${bookmark?.externalId}`, "_self")
                  }}
                  tags={bookmark?.focusArea?.map((item) => item.value)}
                  image={{ src: bookmark?.largeImage?.imageUrl }}
                  saveButton={{
                    // label: "Save",
                    // secondaryLabel: "Saved",
                    label: fetchSavedLabel("CommonComponent.CommonSaveButton"),
                    secondaryLabel: fetchSavedLabel("CommonComponent.CommonSavedButton"),
                    onClick: (e) => { saveHandler(e, bookmark, true, bookmark?.key === "Tools.OnDemandVideoInterviews" ? "collection" : null) }
                  }}
                  isSaved={bookmark && bookmark.issaved && bookmark.issaved || bookmark && bookmark.collectionSaved && bookmark.collectionSaved}
                  status={{
                    name: 'new',
                    label: bookmark?.resourceType?.value,
                    value: bookmark && bookmark.collectionSaved ?
                      bookmark && bookmark.asessmentCount && bookmark.asessmentCount
                      : bookmark && bookmark.duration && bookmark.duration
                  }}

                  type='assessment'
                  layout={'horizontal'}
                />
              </li>
            )
          }
        })}
      </ul>
      {
        viewAll === "Tools" && (savedDataLength && savedDataLength > 10 && savedDataLength !== data?.length) ? <>
          {
            <div style={{ paddingTop: '50px', width: '100%' }}>
              <Cirrus.Button
                onClick={loadMoreJobHandler}
              >
                {
                  'Load more'
                }
              </Cirrus.Button>
            </div>
          }
        </> : ''
      }
    </>
  )
}

export default ToolCard
