//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import LAOpdfTemplate from './pdfTemplate';

const LookingAtOptionsReview = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [percentage, setPercentage] = useState(100)
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
    const [assessmentStatus, setAssessmentStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [screenLoader, setScreenLoader] = useState(false);
    const [startPageData, setStartPageData] = useState();
    const [reviewData, setReviewData] = useState();
    const [commonAnswerList, setCommonAnswerList] = useState(
        context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(factors => factors.Key === "Assessments.LookingAtOptions.CareerFactors").QuestionList.find(question => question.AnswerList).AnswerList
    );
    const [commonCareerFactors, setCommonCareerFactors] = useState(context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(factors => factors.Key === "Assessments.LookingAtOptions.CareerFactors").QuestionList)

    const careerLabels = context.cmsLookingAtOptionsContent.assessmentQuestionSet.find(career => career.Key === "Assessments.LookingAtOptions.TellUsAboutYourCareerPath").Components.find(filt => filt.careerHeading).careerHeading.replace(/[0-9]/g, '')
    const [resultData, setResultData] = useState();
    const [historyData, setHistoryData] = useState();

    const userAgent = window.navigator.userAgent;
    let browser = '';
    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCMSData()
        getAssessmentStatus()
    }, [context.language])

    const fetchCMSData = () => {
        let resData = context.cmsLookingAtOptionsContent;
        setCmsAssessmentContent(resData);
    }

    const getAssessmentStatus = async () => {
        let response = await AssessmentActions.GetAssessmentStatusById(context.cmsLookingAtOptionsContent.internalAssessmentId)
        setAssessmentStatus(response.data)
        setIsSaved(response.data.isSaved)
        setIsLiked(response.data.isLiked)

        AssessmentActions.GetAllCareerPathWithFactors(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                let resData = res.data.careerFactorsResponse
                resData.map(resp => {
                    resp.factorAssessmentResponsesList.map(factorQuest => {
                        commonCareerFactors.map(question => {
                            if (factorQuest.questionId === question.QuestionId) {
                                question.AnswerList.map(options => {
                                    if (options.AnswerId === factorQuest.answerValue) {
                                        Object.keys(factorQuest).forEach(key => {
                                            factorQuest["questionTitle"] = question.QuestionTitle
                                        })
                                    }
                                })
                            }
                        })
                    })
                })
                setReviewData(resData)
            } else {
                console.log(res)
            }
            AssessmentActions.GetAssessmentHistory(context.cmsLookingAtOptionsContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    let responses = res.data
                    responses.map(history => {
                        let resData = JSON.parse(history.previousOptionsResponse)

                        resData.map(resp => {
                            resp.factorAssessmentResponsesList.map(factorQuest => {
                                commonCareerFactors.map(question => {
                                    if (factorQuest.QuestionId === question.QuestionId) {
                                        question.AnswerList.map(options => {
                                            if (options.AnswerId === factorQuest.AnswerValue) {
                                                Object.keys(factorQuest).forEach(key => {
                                                    factorQuest["QuestionTitle"] = question.QuestionTitle
                                                })
                                            } else {
                                            }
                                        })
                                    }
                                })
                            })
                        })
                        setHistoryData(resData)
                    })
                } else {
                    console.log(res)
                }
            }, err => {
                console.log(err)
            })
        }, err => {
            console.log(err)
        })
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent;
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent;
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsLookingAtOptionsContent;
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const updateAssessmentStatus = (docId) => {
        setScreenLoader(true)
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
            "learningResourceStatus": fetchStatusID("ResourceStatus.Completed"),
            "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType?.ID,
            "isSaved": isSaved,
            "isLiked": isLiked,
            "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": docId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
            if (res.status === 200) {
                setScreenLoader(false)
                navigate("/" + pageOrgin.id + "/looking-at-options/result")
            } else {
                setScreenLoader(false)
            }
        }, err => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const editCareerPage = () => {
        setScreenLoader(true)
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
            "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
            "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType?.ID,
            "isSaved": isSaved,
            "isLiked": isLiked,
            "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": assessmentStatus?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
            if (res.status === 200) {
                setScreenLoader(false)
                navigate("/" + pageOrgin.id + "/looking-at-options/career-path")
            } else {
                setScreenLoader(false)
            }
        }, err => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const editFactorPage = (pageId) => {
        setScreenLoader(true)
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": context.cmsLookingAtOptionsContent.internalAssessmentId,
            "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
            "learningResourceType": context.cmsLookingAtOptionsContent.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsLookingAtOptionsContent.resourceType?.ID,
            "isSaved": isSaved,
            "isLiked": isLiked,
            "lastAnsweredPageId": pageId - 1,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": assessmentStatus?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsLookingAtOptionsContent.resourceCategoryType?.ID, context.cmsLookingAtOptionsContent.internalAssessmentId, payload).then(async (res) => {
            if (res.status === 200) {
                setScreenLoader(false)
                navigate("/" + pageOrgin.id + "/looking-at-options/career-factors")
            } else {
                setScreenLoader(false)
            }
        }, err => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    const generatePDF = () => {
        setLoadSpinner(true);
        let content = document.getElementById('assessment-result-wrapper');

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;

            const pdf = new jsPDF({ orientation: 'p', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', 20, yPosition + 60, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                
                let file = new File([pdf.output('blob')], context.cmsLookingAtOptionsContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");

                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    let docId;
                    res.data.map(doc => {
                        docId = doc.id
                    })
                    updateAssessmentStatus(docId)
                    setLoadSpinner(false);
                }, err => {
                    console.log(err);
                    setLoadSpinner(false);
                });
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();

                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }

                        let file = new File([pdf.output('blob')], context.cmsLookingAtOptionsContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })

                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");

                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            let docId;
                            res.data.map(doc => {
                                docId = doc.id
                            })
                            updateAssessmentStatus(docId)
                            setLoadSpinner(false);
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        });
                    }
                })
            }
        })
    }
    useEffect(() => {
        document.querySelector(".progress-bar")?.setAttribute("aria-label", "progress bar")
    })

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page">
                <Container className='pt-4 pb-4'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button isSecondary onClick={() => navigate("/" + pageOrgin.id + "/looking-at-options/career-factors")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div>
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonResourceLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-review-result-wrapper px-4 py-5 p-md-5 mb-5'>
                    {
                        loadSpinner &&
                        <div className='loader'>
                            <Cirrus.Spinner size={50} />
                        </div>
                    }
                    <Row>
                        <Col lg={12} className='text-start career-labels'>
                            <div className='border-bottom pb-2 mb-3'>
                                <Cirrus.Header size='h4'>
                                    {cmsAssessmentContent?.reviewPage?.Title}
                                </Cirrus.Header>
                            </div>

                            {
                                reviewData?.map((data, idx) => {
                                    return (
                                        <div className='mb-5' key={idx}>
                                            <div className='border-bottom mb-3'>
                                                <div className='d-flex justify-content-between'>
                                                    <Cirrus.Label>{fetchCommonResourceLabel("CommonResourceComponent.Title")}</Cirrus.Label>
                                                    <Cirrus.Link onClick={() => editCareerPage()}>
                                                        <i className='icon-edit edit-career-title'></i>
                                                    </Cirrus.Link>
                                                </div>
                                                <p>{data.categoryTitle}</p>
                                                <Cirrus.Label>{fetchCommonResourceLabel("CommonResourceComponent.Description")}</Cirrus.Label>
                                                <p>{data.categoryDescription}</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <Cirrus.Label><span className='font-18'>{fetchCommonResourceLabel("CommonResourceComponent.Factors")}</span></Cirrus.Label>
                                                <Cirrus.Link onClick={() => editFactorPage(idx + 1)}>
                                                    <i className='icon-edit edit-career-title'></i>
                                                </Cirrus.Link>
                                            </div>
                                            <Container fluid className='factors-wrapper py-3 my-2'>
                                                <Row className='d-flex lao-review-category flex-lg-row-reverse flex-md-row-reverse flex-column-reverse'>
                                                    {commonAnswerList?.map((commonAnswers, colIdx) => {
                                                        return (
                                                            <Col key={colIdx} className='factor-category'>
                                                                <Cirrus.Label>{commonAnswers.AnswerText}</Cirrus.Label>
                                                                <ul>
                                                                    {
                                                                        data.factorAssessmentResponsesList.map((factors, factorIdx) => {
                                                                            if (factors.answerValue === commonAnswers.AnswerId) {
                                                                                return (
                                                                                    <li key={factorIdx}>{factors.questionTitle ? factors.questionTitle : factors.factorName}</li>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Container>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-lg-end justify-content-md-end justify-content-center'>
                        <Cirrus.Button onClick={() => generatePDF()}>{fetchCommonLabel("CommonComponent.CommonCompleteButton")}</Cirrus.Button>
                    </div>
                </Container>
            </div>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
            <div style={{ opacity: "0" }}>
                <div id='assessment-result-wrapper' style={{ position: 'absolute', left: '0', bottom: '0', zIndex: '-1', display: 'block', width: '1300px' }}>
                    <LAOpdfTemplate careerLabels={careerLabels} commonAnswerList={commonAnswerList} resultData={reviewData} historyData={historyData} historyTitle={fetchCommonResourceLabel("CommonResourceComponent.YourPreviousCareerOptions")}></LAOpdfTemplate>
                </div>
            </div>
        </>
    )
}


export default LookingAtOptionsReview;
