//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useContext, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Row, Col, Container, ProgressBar } from "react-bootstrap";

import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from '../../header/headerWithNavigation';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Footer from "../../../common/components/footer";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../../common/utility/LogAction';


function Questions() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsMasterData, setCmsMasterData] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState({
        questionLists: [],
        answeredPage: [],
        answeredQuestionID: []
    });
    const [assessmentPercentage, setAssessmentPercentage] = useState(0);
    const [assessmentStatus, setAssessmentStatus] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [disableBackBtn, setDisableBackBtn] = useState(true);
    const appInsights = useAppInsightsContext()

    useEffect(() => {
        getAssessmentWithDetails();
        window.scrollTo(0, 0)
        TrackActions.PageViewRecord(appInsights, "ReviewAccomplishmentsQuestions")
        TrackActions.PageAction("ReviewAccomplishmentsQuestions")
    }, [context.language])

    const getAssessmentWithDetails = async () => {
        let cmsQuestion = context.cmsRAchildPagesAndLabel;
        setScreenLoader(true);
        if (Object.keys(cmsQuestion).length !== 0) {
            let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));
            await AssessmentActions.GetAssessmentStatusAndSavedResponseById(cmsQuestion?.masterCMS?.ID).then(res => {
                if (res.status === 200) {
                    let arr = [];
                    setAssessmentStatus(res.data);
                    setCmsMasterData(cmsQuestion);

                    let savedResponses = res.data.learningResourceAssessmentResponseModels;
                    if (savedResponses.length > 0) {
                        let setCount = 1;
                        let filterSetCount = [];
                        savedResponses.map((answer) => {
                            let tempSetCount = parseInt(answer?.questionId?.split('~')[2])
                            if (!filterSetCount.includes(tempSetCount)) {
                                filterSetCount.push(tempSetCount);
                            }
                            refMasterQuestionAnswer.answeredQuestionID.push(answer.questionId);
                        })

                        if (filterSetCount.length > 1) {
                            filterSetCount.sort(function (a, b) {
                                return a - b;
                            });
                            if (filterSetCount[filterSetCount.length - 1] !== undefined) {
                                setCount = filterSetCount[filterSetCount.length - 1];
                            }
                        }

                        for (let i = 1; i <= setCount; i++) {
                            cmsQuestion?.masterCMS?.ChildPages?.map((assessment, index) => {
                                if (Object.keys(assessment?.Components).length !== 0) {
                                    let comp = assessment?.Components[Object.keys(assessment?.Components)[0]];
                                    let listsOption = [];
                                    comp?.QuestionList[0]?.AnswerList.forEach(item => {
                                        listsOption.push({
                                            'isSelected': false,
                                            'answerId': item.AnswerId + '~set~' + i,
                                            'itemID': item.ItemID + '~set~' + i,
                                            'answerText': item.AnswerText
                                        })
                                    });
                                    arr.push({
                                        'questionId': comp?.QuestionList[0]?.QuestionId + '~set~' + i,
                                        'topTitle': '',
                                        'questionTitle': assessment?.Title,
                                        'description': assessment?.Description,
                                        'questionLabel': comp?.QuestionList[0]?.QuestionTitle,
                                        'questionPlaceholder': fetchLabel("CommonResourceComponent.EnterHere"),
                                        'fieldType': comp?.QuestionList[0]?.OptionType,
                                        'optionsAnswerList': listsOption,
                                        'textAnswerValue': '',
                                        'currentSet': 'set~' + i,
                                    });
                                }
                            });
                        }

                        const returnName = (key) => {
                            let name = '';
                            if (cmsQuestion?.nameArr) {
                                cmsQuestion?.nameArr.map((x) => {
                                    if (x.key === key) {
                                        name = x.name
                                    }
                                })
                            }
                            return name;
                        }

                        let mappedQuestionAnswer = arr.map((question) => {
                            savedResponses.map((answer) => {
                                let setNumber = 'set~' + answer?.questionId?.split('~')[2];
                                if (question.currentSet === setNumber) {
                                    if (answer.categoryValue === returnName("Assessments.ReviewingAccomplishments.AccomplishmentTitlePage")) {
                                        question.topTitle = answer.answerValue;
                                    }
                                }
                                if (question.questionId === answer.questionId) {
                                    if (question.questionTitle === returnName("Assessments.ReviewingAccomplishments.SkillsPage")) {
                                        let spltValue = answer?.answerValue?.split(",");
                                        question?.optionsAnswerList?.map((opt) => {
                                            let as = spltValue.filter((spltOpt) => spltOpt === opt.answerId)
                                            if (as.length > 0) {
                                                opt.isSelected = true;
                                            } else {
                                                opt.isSelected = false;
                                            }
                                            return opt;
                                        })
                                    } else {
                                        question.textAnswerValue = answer.answerValue;
                                    }
                                }
                            })
                            return question;
                        })

                        if (pageOrgin.add.includes("true")) {
                            let refDynamicDataSet = JSON.parse(JSON.stringify(mappedQuestionAnswer.slice(-5)));
                            refDynamicDataSet.map((question) => {
                                let qID = question?.questionId?.split('~')[0];
                                let setNumber = parseInt(question?.questionId?.split('~')[2]) + 1;

                                question.currentSet = 'set~' + setNumber;
                                question.questionId = qID + '~set~' + setNumber;
                                question.topTitle = '';
                                question.textAnswerValue = '';
                                question.optionsAnswerList.map((ansList) => {
                                    let ansID = ansList?.answerId?.split('~')[0];
                                    let itmID = ansList?.itemID?.split('~')[0];
                                    ansList['isSelected'] = false;
                                    ansList.answerId = ansID + '~set~' + setNumber;
                                    ansList.itemID = itmID + '~set~' + setNumber;

                                    return ansList;
                                });
                                return question;
                            });
                            let arr2 = [...mappedQuestionAnswer, ...refDynamicDataSet]
                            refMasterQuestionAnswer.questionLists = arr2
                            setMasterQuestionAnswer(refMasterQuestionAnswer);
                            setAssessmentPercentage(Math.ceil((refMasterQuestionAnswer.answeredQuestionID.length / arr2.length) * 100))
                        } else {
                            refMasterQuestionAnswer.questionLists = mappedQuestionAnswer;
                            setDisableBackBtn(mappedQuestionAnswer?.questionLists?.length > 5 ? true : false)
                            setMasterQuestionAnswer(refMasterQuestionAnswer);
                            setAssessmentPercentage(Math.ceil((refMasterQuestionAnswer.answeredQuestionID.length / mappedQuestionAnswer.length) * 100))
                        }
                    } else {
                        cmsQuestion?.masterCMS?.ChildPages?.map((assessment, index) => {
                            if (Object.keys(assessment?.Components).length !== 0) {
                                let comp = assessment?.Components[Object.keys(assessment?.Components)[0]];
                                let listsOption = [];
                                comp?.QuestionList[0]?.AnswerList.forEach(item => {
                                    listsOption.push({
                                        'isSelected': false,
                                        'answerId': item.AnswerId + '~set~' + 1,
                                        'itemID': item.ItemID + '~set~' + 1,
                                        'answerText': item.AnswerText
                                    })
                                });
                                arr.push({
                                    'questionId': comp?.QuestionList[0]?.QuestionId + '~set~' + 1,
                                    'topTitle': '',
                                    'questionTitle': assessment?.Title,
                                    'description': assessment?.Description,
                                    'questionLabel': comp?.QuestionList[0]?.QuestionTitle,
                                    'questionPlaceholder': fetchLabel("CommonResourceComponent.EnterHere"),
                                    'fieldType': comp?.QuestionList[0]?.OptionType,
                                    'optionsAnswerList': listsOption,
                                    'textAnswerValue': '',
                                    'currentSet': 'set~' + 1,
                                });
                            }
                        });
                        refMasterQuestionAnswer.questionLists = arr;
                        setDisableBackBtn(false)
                        setMasterQuestionAnswer(refMasterQuestionAnswer);
                    }

                    let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');

                    if (refMasterQuestionAnswer.questionLists.length === refMasterQuestionAnswer.answeredQuestionID.length) {
                        let pgID = res.data.lastAnsweredPageId.split(",");
                        let a = [];
                        pgID.map((x) => {
                            a.push(parseInt(x))
                            refMasterQuestionAnswer.answeredPage.push(parseInt(x))
                        })
                        setMasterQuestionAnswer(refMasterQuestionAnswer);
                        setScreenLoader(false)
                        assessmentQuestion[currentQuestion].style.display = "block";
                    } else {
                        if (res.data.lastAnsweredPageId !== '0') {
                            let pgID = res.data.lastAnsweredPageId.split(",");
                            let a = [];
                            pgID.map((x) => {
                                a.push(parseInt(x))
                                refMasterQuestionAnswer.answeredPage.push(parseInt(x))
                            })
                            setMasterQuestionAnswer(refMasterQuestionAnswer);

                            let redirectPage;
                            if (pageOrgin.add.includes("true")) {
                                redirectPage = refMasterQuestionAnswer.questionLists.length - 5;
                            } else {
                                let count = refMasterQuestionAnswer.questionLists.length;
                                let missing = new Array();
                                for (var i = 1; i <= count; i++) {
                                    if (a.indexOf(i) == -1) {
                                        missing.push(i);
                                    }
                                }
                                redirectPage = missing[0] - 1;
                            }



                            setScreenLoader(false)
                            assessmentQuestion[redirectPage].style.display = "block";
                            setCurrentQuestion(redirectPage);
                        } else {
                            setScreenLoader(false)
                            assessmentQuestion[currentQuestion].style.display = "block";
                        }
                    }
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                setScreenLoader(false);
                console.log(err);
            });
        }
    }

    const inputHandler = (event, questionTitle) => {
        let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));
        let { name, value } = event.target;
        let setNumber = 'set~' + name?.split('~')[2];

        refMasterQuestionAnswer?.questionLists?.map((x) => {
            if (x.currentSet === setNumber) {
                if (x.questionTitle === questionTitle) {
                    x.textAnswerValue = value;
                }
                x.topTitle = value;
                return x;
            }
        })
        setMasterQuestionAnswer(refMasterQuestionAnswer)
    }

    const saveAnswer = async (event, questionId, questionTitle, pageNumber, fieldType) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));
        let answerValue = '';
        let savedFlag = true;
        let percentage;

        if (fieldType === "Options") {
            let { name, value } = event.target;
            let selectItems = [];
            refMasterQuestionAnswer?.questionLists?.map((x) => {
                if (questionId === x.questionId) {
                    x?.optionsAnswerList?.map((y) => {
                        if (name === y.answerId) {
                            if (y.isSelected) {
                                y.isSelected = false;
                            } else {
                                y.isSelected = true;
                            }
                        }
                        if (y.isSelected) {
                            selectItems.push(y.answerId);
                        }
                        return y;
                    })
                    x.textAnswerValue = selectItems.toString();
                    return x;
                }
            })
            answerValue = selectItems.toString();
            setMasterQuestionAnswer(refMasterQuestionAnswer)
        } else {
            answerValue = document.getElementById(questionId).value;
            refMasterQuestionAnswer?.questionLists?.map((x) => {
                if (questionId === x.questionId) {
                    x.textAnswerValue = answerValue;
                    return x;
                }
            })
            setMasterQuestionAnswer(refMasterQuestionAnswer)
        }

        if (answerValue !== "") {
            let savePayload = [{
                "AssessementId": cmsMasterData?.masterCMS?.ID,
                "candidateId": candidateID,
                "AssessementType": "Internal",
                "CategoryValue": questionTitle,
                "QuestionId": questionId,
                "AnswerType": fieldType === "Options" ? 'id' : 'text',
                "AnswerValue": answerValue,
            }]
            await AssessmentActions.SaveAssessment(savePayload).then(res => {
                if (res.status === 200) {
                    if (!refMasterQuestionAnswer.answeredQuestionID.includes(questionId)) {
                        let a = refMasterQuestionAnswer.answeredQuestionID.length + 1;
                        let percentageCalc = Math.ceil((parseInt(a) / refMasterQuestionAnswer.questionLists.length) * 100);
                        refMasterQuestionAnswer.answeredQuestionID.push(questionId)
                        setAssessmentPercentage(percentageCalc)
                    }

                    if (!refMasterQuestionAnswer.answeredPage.includes(pageNumber + 1)) {
                        refMasterQuestionAnswer.answeredPage.push(pageNumber + 1)
                    }
                    setMasterQuestionAnswer(refMasterQuestionAnswer)
                    savedFlag = true;
                }
            }, err => {
                console.log(err);
            })

            if (savedFlag) {
                let statusPayload = {
                    "candidateId": candidateID,
                    "learningResourceId": cmsMasterData?.masterCMS?.ID,
                    "learningResourceType": cmsMasterData?.masterCMS?.ResourceCategoryType?.ID,
                    "resourceTypeId": cmsMasterData?.masterCMS?.ResourceType?.ID,
                    "learningResourceStatus": 'inprogress',
                    "isRetaken": false,
                    "lastAnsweredPageId": refMasterQuestionAnswer?.answeredPage.toString(),
                    "isSaved": assessmentStatus?.isSaved,
                    "isLiked": assessmentStatus?.isLiked,
                    "isDownloaded": assessmentStatus?.isDownloaded,
                    "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                    "resultDocumentId": assessmentStatus?.resultDocumentId
                }
                await AssessmentActions.SaveAssessmentStatus(cmsMasterData?.masterCMS?.ResourceCategoryType?.ID, cmsMasterData?.masterCMS?.ID, statusPayload).then((res) => {
                    if (res.status === 200) {
                        savedFlag = false;
                    }
                }, err => {
                    console.log(err);
                })
            }
        }
    }

    const nextPrev = (n) => {
        let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
        if (n > 0) {
            assessmentQuestion[currentQuestion].style.display = "none";
            setCurrentQuestion(currentQuestion + n);
            showQuestion(currentQuestion + n);
            setDisableBackBtn(false)
        } else {
            if ((masterQuestionAnswer.questionLists.length - 5) === currentQuestion) {
                setDisableBackBtn(true)
                assessmentQuestion[currentQuestion].style.display = "none";
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            } else {
                if (masterQuestionAnswer.questionLists.length > 5) {
                    if (masterQuestionAnswer.questionLists.length - 4 === currentQuestion) {
                        setDisableBackBtn(true)
                    } else {
                        setDisableBackBtn(false)
                    }
                }
                assessmentQuestion[currentQuestion].style.display = "none";
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n == -1) {
            navigate("/" + pageOrgin.id + "/reviewing-accomplishments");
        } else {
            if (n < assessmentQuestion.length) {
                assessmentQuestion[n].style.display = "block";
            }
            if (n == assessmentQuestion.length) {
                navigate("/" + pageOrgin.id + "/reviewing-accomplishments/review");
            }
        }
    }

    const fetchLabel = (key) => {
        let refCmsMasterData = JSON.parse(JSON.stringify(cmsMasterData));
        let label = '';
        if (refCmsMasterData?.masterLabel) {
            refCmsMasterData?.masterLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const returnField = (type, index) => {
        if (type.fieldType === "TextArea") {
            return (
                <textarea
                    id={type.questionId}
                    placeholder={type.questionPlaceholder}
                    name={type.questionId}
                    maxLength={1050}
                    rows="10"
                    readOnly={false}
                    defaultValue={type.textAnswerValue}
                    onBlur={(event) => saveAnswer(event, type.questionId, type.questionTitle, index, type.fieldType)}
                />
            )
        }
        if (type.fieldType === "TextBox") {
            return (
                <Cirrus.Input
                    id={type.questionId}
                    name={type.questionId}
                    onChange={(event) => inputHandler(event, type.questionTitle)}
                    onBlur={(event) => saveAnswer(event, type.questionId, type.questionTitle, index, type.fieldType)}
                    placeholder={type.questionPlaceholder}
                    value={type.textAnswerValue}
                    maxLength={100}
                />
            )
        }
        if (type.fieldType === "Options") {
            let optionList = [];
            type.optionsAnswerList.map((o, idx) => {
                optionList.push(
                    <li key={idx}><Cirrus.Checkbox
                        id={o.answerId}
                        label={o.answerText}
                        name={o.answerId}
                        value={o.answerId}
                        defaultChecked={o.isSelected}
                        onChange={(event) => saveAnswer(event, type.questionId, type.questionTitle, index, type.fieldType)}
                    />
                    </li>
                )
            })
            return <ul className='questionOption'>{optionList}</ul>;
        }
    }

    const returnName2 = (key) => {
        let refCmsRA = JSON.parse(JSON.stringify(cmsMasterData));
        let name = '';
        if (refCmsRA?.nameArr) {
            refCmsRA?.nameArr.map((x) => {
                if (x.key === key) {
                    name = x.name
                }
            })
        }
        return name;
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page RA_question-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pt-3 pb-4'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button
                            className='back-btn'
                            isSecondary
                            onClick={(e) => { nextPrev(-1) }}
                            disabled={disableBackBtn}
                        >
                            {fetchLabel("CommonComponent.CommonBackButton")}
                        </Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {assessmentPercentage}%</Cirrus.Label>
                            <ProgressBar now={assessmentPercentage} label={`${assessmentPercentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button
                            onClick={(e) => {
                                navigate("/" + pageOrgin.id)
                            }}
                        >
                            {fetchLabel("CommonResourceComponent.Exit")}
                        </Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {
                masterQuestionAnswer?.questionLists?.map((q, index) => {
                    return (
                        <Container key={index} className='assessment-question-wrapper mb-5'>
                            <Row>
                                <Col lg={12} className="assessment-question">
                                    <div className="mb-3 d-block">
                                        <Cirrus.Label>
                                            {returnName2("Resources.Assessments.ReviewingAccomplishments.AccomplishmentTitle")} {q.topTitle}
                                        </Cirrus.Label>
                                    </div>

                                    <Cirrus.Headline size='Small' className='mb-2'>
                                        {q.questionTitle}
                                    </Cirrus.Headline>
                                    <div className="mb-3 d-block">
                                        <Cirrus.Label>
                                            <Cirrus.RichText content={q.description} />
                                        </Cirrus.Label>
                                    </div>
                                    <div className="mb-3 d-block">
                                        {
                                            q.fieldType === 'Options' ? '' : <div className="question">
                                                <Cirrus.Label>
                                                    {q.questionLabel}
                                                </Cirrus.Label>
                                            </div>
                                        }

                                        {
                                            returnField(q, index)
                                        }
                                    </div>
                                    <div className='mt-5'>
                                        <Cirrus.Button
                                            onClick={(e) => { nextPrev(1) }}
                                        >
                                            {fetchLabel("CommonComponent.CommonContinueButton")}
                                        </Cirrus.Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )
                })
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default Questions
