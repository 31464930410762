//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';

const SkillsQuestionPage = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);

    const [cmsMasterContent, setCmsMasterContent] = useState({});
    const [masterQuestionAnswer, setMasterQuestionAnswer] = useState([]);
    const [questionLabel, setQuestionLabel] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [assessmentPercentage, setAssessmentPercentage] = useState(0);

    const [assessmentStatus, setAssessmentStatus] = useState({
        Parrent: {},
        Skills: {},
        Interests: {},
        answeredPageNumber: 0
    })
    const [continueCompletedButton, setContinueCompletedButton] = useState(false);

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        let cmsResponse = context.cmsStrengthAnalysis;
        setScreenLoader(true)
        if (Object.keys(cmsResponse).length !== 0) {
            let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
            setCmsMasterContent(cmsResponse);
            setQuestionLabel(cmsResponse?.assessmentSkills?.questionLabel)

            await AssessmentActions.GetStrengthsAnalysisResult(cmsResponse.masterCMS.ID).then(resp => {
                if (resp.status === 200) {
                    let isDataSaved = resp.data.filter((item) => item.subAssessmentId === cmsResponse?.assessmentSkills?.ID)

                    let filteredRefineSet = cmsResponse?.assessmentSkills?.Question.map((resourcePages) => {
                        resourcePages?.map((refineQuestion) => {
                            isDataSaved[0]?.response?.map((refineAns) => {
                                if (refineAns.questionId === refineQuestion.Skills.ID) {
                                    refineQuestion.RefinedAnswer = parseInt(refineAns.answerValue);
                                }
                            })
                            return refineQuestion;
                        })
                        return resourcePages;
                    })
                    setMasterQuestionAnswer(filteredRefineSet)
                }
            })

            await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(cmsResponse.masterCMS.ID).then(
                (res) => {
                    if (res.status === 200) {
                        let parent = res?.data.filter((status) => status.subLearningResourceId === undefined)
                        refAssessmentStatus.Parrent = parent[0];
                        cmsResponse.assIds.map((x) => {
                            res?.data.map((status) => {
                                if (x.id === status.subLearningResourceId) {
                                    refAssessmentStatus[`${x.name}`] = status;
                                }
                            })
                        })
                        setAssessmentStatus(refAssessmentStatus)
                    } else {
                        setLoadSpinner(false)
                    }
                },
                (err) => {
                    console.log(err);
                    setLoadSpinner(false);
                }
            );

            let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
            setScreenLoader(false)
            let pageNumber = parseInt(refAssessmentStatus?.Skills?.lastAnsweredPageId)
            if (pageNumber) {
                if (assessmentQuestion.length === (pageNumber + 1)) {
                    setContinueCompletedButton(true)
                }
                if (pageNumber < assessmentQuestion.length) {
                    assessmentQuestion[pageNumber].style.display = "block";
                    setCurrentQuestion(currentQuestion + pageNumber);

                    refAssessmentStatus.answeredPageNumber = pageNumber;
                    setAssessmentStatus(refAssessmentStatus)

                    let percentageCalc = Math.ceil((pageNumber / assessmentQuestion.length) * 100);
                    setAssessmentPercentage(percentageCalc);
                } else {
                    assessmentQuestion[pageNumber - 1].style.display = "block";
                    setCurrentQuestion(pageNumber - 1);

                    refAssessmentStatus.answeredPageNumber = pageNumber;
                    setAssessmentStatus(refAssessmentStatus)

                    let percentageCalc = Math.ceil((pageNumber / assessmentQuestion.length) * 100);
                    setAssessmentPercentage(percentageCalc);
                }
            } else {
                assessmentQuestion[currentQuestion].style.display = "block";
            }
        }
    }

    const saveAnswer = (event, selectedSet) => {
        let refMasterQuestionAnswer = JSON.parse(JSON.stringify(masterQuestionAnswer));

        let answerValue = document.getElementById(selectedSet.ID).value;
        refMasterQuestionAnswer?.map((resourcePages) => {
            resourcePages?.map((refineQuestion) => {
                if (refineQuestion.Skills.ID === selectedSet.Skills.ID) {
                    refineQuestion.RefinedAnswer = +answerValue;
                }
                return refineQuestion;
            })
            return resourcePages;
        });
        setMasterQuestionAnswer(refMasterQuestionAnswer)

        let savePayload = [{
            "SubAssessmentId": cmsMasterContent?.assessmentSkills?.ID,
            "AssessementType": "Internal",
            "CategoryValue": selectedSet.Skills.Value,
            "QuestionId": selectedSet.Skills.ID,
            "AnswerType": "Id",
            "AnswerValue": answerValue
        }]

        AssessmentActions.StrengthsAnalysisRefiningYourInterestSkills(cmsMasterContent.masterCMS.ID, savePayload).then(res => {
            if (res.status === 200) {
            }
        }, err => {
            console.log(err);
        })
    }

    const nextPrev = (n, pageNumber) => {
        if (n > 0) {
            showQuestion(currentQuestion + n, n, pageNumber);
        } else {
            showQuestion(currentQuestion + n, n, pageNumber);
        }
    }

    const showQuestion = async (n, NPn, pageNumber) => {
        let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');

        assessmentQuestion[currentQuestion].style.display = "none";
        setCurrentQuestion(currentQuestion + NPn);

        if (assessmentQuestion.length === (pageNumber + 2)) {
            setContinueCompletedButton(true)
        } else {
            setContinueCompletedButton(false)
        }
        if (n == -1) {
            navigate("/" + pageOrgin.id + "/strength-analysis/skill-start");
        } else {
            if (n < assessmentQuestion.length) {
                let page = pageNumber + 1;
                if (!isNaN(page)) {
                    if (page > assessmentStatus.answeredPageNumber) {
                        setLoadSpinner(true);
                        let saveStatus = {
                            "SubLearningResourceStatus": "inprogress",
                            "SubLearningResourceId": cmsMasterContent?.assessmentSkills?.ID,
                            "LastAnsweredPageId": page
                        }
                        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, saveStatus).then((res) => {
                            if (res.status === 200) {
                                refAssessmentStatus.answeredPageNumber = page;
                                setAssessmentStatus(refAssessmentStatus)

                                let percentageCalc = Math.ceil((page / assessmentQuestion.length) * 100);
                                setAssessmentPercentage(percentageCalc);

                                setTimeout(() => {
                                    assessmentQuestion[n].style.display = "block";
                                    setLoadSpinner(false);
                                }, 100);

                            } else {
                                setLoadSpinner(false);
                            }
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        })
                    } else {
                        assessmentQuestion[n].style.display = "block";
                    }
                } else {
                    assessmentQuestion[n].style.display = "block";
                }
            }
            if (n == assessmentQuestion.length) {
                let page = pageNumber + 1;
                let assCompletedFlag = false;
                if (!isNaN(page)) {
                    setLoadSpinner(true);
                    let payload = {
                        "SubLearningResourceStatus": "completed",
                        "SubLearningResourceId": cmsMasterContent?.assessmentSkills?.ID,
                        "LastAnsweredPageId": page
                    }
                    await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, payload).then((res) => {
                        if (res.status === 200) {
                            assCompletedFlag = true;
                        }
                    }, err => {
                        console.log(err);
                    })

                    if (assCompletedFlag) {
                        let statusPayload = {
                            "LearningResourceStatus": "completed"
                        }
                        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(cmsMasterContent.masterCMS.ID, statusPayload).then((res) => {
                            if (res.status === 200) {

                                let percentageCalc = Math.ceil((page / assessmentQuestion.length) * 100);
                                setAssessmentPercentage(percentageCalc);
                                context.setGenerateSkillsPdfFlag(true)
                                setTimeout(() => {
                                    navigate("/" + pageOrgin.id + "/strength-analysis/skill-result");
                                    setLoadSpinner(false);
                                }, 100);

                            } else {
                                setLoadSpinner(false);
                            }
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        })
                    }
                }
            }
        }
    }

    const fetchLabel = (key) => {
        let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
        let label = '';
        if (Object.keys(refCmsMasterContent).length !== 0) {
            refCmsMasterContent?.masterLabel?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pt-3 pb-lg-4 pb-3'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button isSecondary onClick={(e) => { nextPrev(-1) }}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                    </Col>

                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchLabel("CommonResourceComponent.Progress")} {assessmentPercentage}%</Cirrus.Label>
                            <ProgressBar now={assessmentPercentage} label={`${assessmentPercentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end" onClick={() => navigate("/" + pageOrgin.id + "/strength-analysis")}>
                        <Cirrus.Button>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>

            {
                masterQuestionAnswer?.map((resourcePages, resoIndex) => {
                    return (
                        <>
                            {
                                loadSpinner &&
                                <div className='loader'>
                                    <Cirrus.Spinner size={50} />
                                </div>
                            }
                            <Container className='assessment-question-wrapper text-start mb-lg-5 mb-3 p-lg-5 p-3' key={resoIndex}>
                                <Row>
                                    <Col lg={8} className="m-auto text-start  pb-4">
                                        <Cirrus.Headline size='Small' className='text-center'>{cmsMasterContent?.assessmentSkills?.ResourceCardTitle}</Cirrus.Headline>
                                        {/* <p className='text-center'>
                                            <Cirrus.RichText content={firstSetQuestion?.Description} />
                                        </p> */}
                                    </Col>
                                </Row>
                                <Row className='refineQuestion-dekstop'>
                                    <Col lg={10} className='px-5 m-auto'>
                                        <Row className='mb-3'>
                                            <Col lg={3}>

                                            </Col>
                                            <Col lg={9}>
                                                <Row className='row-cols-5'>
                                                    {
                                                        questionLabel.map((label) => {
                                                            return (
                                                                <Col className='text-center text-nowrap d-flex justify-content-center'>
                                                                    {label.AnswerText}<br />|
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    resourcePages?.map((filterQuestion, index) => {
                                        return (<>
                                            <Row key={index}>
                                                <Col lg={10} className='px-5 m-auto skill-questions-slider'>
                                                    <Row className='mb-3 slider-row align-items-center'>
                                                        <Col lg={3} className='slider-title'>
                                                            <label for="customRange1">{filterQuestion.Skills.Value}</label>
                                                        </Col>
                                                        <Col lg={9} className='slider-column'>
                                                            <input
                                                                aria-label={filterQuestion.Skills.Value}
                                                                id={filterQuestion?.ID}
                                                                name={filterQuestion.Skills.Value}
                                                                onChange={(event) => saveAnswer(event, filterQuestion)}
                                                                type="range"
                                                                className="form-range"
                                                                step="1"
                                                                min="1"
                                                                max="5"
                                                                defaultValue={filterQuestion.RefinedAnswer}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='refineQuestion-mobile'>
                                                <Col lg={10} xs={12} className='px-5 m-auto range-bar'>
                                                    <Row className='mb-3'>
                                                        <Col lg={3}>

                                                        </Col>
                                                        <Col lg={9}>
                                                            <Row className='row-cols-5 range-bar-text'>
                                                                {
                                                                    questionLabel.map((label) => {
                                                                        return (
                                                                            <Col className='text-center'>
                                                                                |<br /><span>{label.AnswerText}</span>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                        )
                                    })
                                }
                                <Row>
                                    <Col lg={10} className='p-5 m-auto text-center skills-question'>
                                        <Cirrus.Button
                                            className='w-25'
                                            onClick={(e) => { nextPrev(1, resoIndex) }}
                                        >
                                            {continueCompletedButton === true ? <>{fetchLabel("CommonComponent.CommonCompleteButton")}</> : <>{fetchLabel("CommonComponent.CommonContinueButton")}</>}
                                        </Cirrus.Button>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
                })
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default SkillsQuestionPage;
