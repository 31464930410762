import React, { useEffect, useState, useCallback, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import Header from '../../../pages/header/simpleHeader';
import htmlParser from 'html-react-parser';
import Footer from './index';
import { FooterActions } from "./FooterActions";
import Spinner from '../spinner-loader/Spinner';
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import { returnCountryLanguagePersona } from "../../helpers/CountryLanguage";
import { useMsal } from "@azure/msal-react";

const PolicyUpdatedSummary = () => {
    const { instance } = useMsal();
    const [cmsData, setCmsData] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const navigate = useNavigate();
    const context = useContext(Context);
    //to get T&C and privacy summary updates data
    const getPrivacySummaryData = async () => {
        try {
            const res = await FooterActions.GetCookieNPolicies({ "PageName": "PolicyUpdateSummary" });
            if (res.status === 200) {
                setCmsData(res?.data);
            }
            setScreenLoader(false)
        }
        catch (err) {
            setScreenLoader(false);
            console.log('error fetching privacy summary data', err);
        }
    }
    const getPrivacySummaryLabels = useCallback((key, componentName) => {
        if (cmsData?.Pages && cmsData?.Pages.length > 0) {
            const labels = cmsData?.Pages[0]?.Components[componentName]?.Labels;
            return labels.find(label => label.Key === key)?.Phrase || '';
        }
        return '';
    }, [cmsData]);
    const returnSaveCurrentVersionBody = (genericData) => {
        return {
            candidateId: JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
            isTermsAndConditionsAccepted: true,
            isPrivacyConsentAccepted: true,
            Country: genericData.MarketName,
            Language: genericData.language,
            TcAcceptedVersion: parseInt(context.updatesSummaryDetails?.latestVersion_TC),
            PcAcceptedVersion: parseInt(context.updatesSummaryDetails?.latestVersion_Policy)
        }
    };
    //to save current version of TC and Policy to next DB
    const saveCurrentVersion = async () => {
        try {
            const genericData = returnCountryLanguagePersona();
            const body = returnSaveCurrentVersionBody(genericData);
            const res = await FooterActions.SaveUserConsent(body);
            if (res.status === 200 || res.status === 204) {
                context.setUpdatesSummaryDetails((prev) => ({ ...prev, isPolicyUpdated: false }));//to update the state to show the updated policy
                navigate('/dashboard'); 
            }
            else {
                throw new Error(`Unexpected response status: ${res.status}`);
            }
        }
        catch (err) {
            console.error("Error saving current version:", err);
        }
    }
    const handleSignOut = () => {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        sessionStorage.clear();
        localStorage.clear();
    }
    const handleOkToContinue = () => {
        saveCurrentVersion();
    }
    useEffect(() => {
        getPrivacySummaryData();
    }, [])
    return (<>
        <Cirrus.Page
            background='secondary'
            backgroundShape='singleDesktop'
            header={<Header mode="minimal" />}
            className="ourPolicy privacy-policy"
        >
            {screenLoader === true ? <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div> : <Cirrus.Container>
                <Cirrus.PolicyUpdateSummary
                    title={cmsData?.Pages && cmsData?.Pages[0]?.Title}
                    updateSummaryLabel={getPrivacySummaryLabels('PoliciesUpdateSummaryData.UpdatesSummarySubTitle', 'PoliciesUpdateSummaryData')}
                    summaryContent={htmlParser(cmsData?.Pages && cmsData?.Pages[0]?.Description ? cmsData?.Pages[0]?.Description : "")}
                    signOutButtonProps={{
                        children: getPrivacySummaryLabels('CommonComponent.CommonSignoutButton', 'CommonComponent'),
                        isSecondary: true,
                        onClick: () => {
                            handleSignOut();
                        },
                        size: "Small",
                        type: "button",
                    }}
                    okToContinueButtonProps={{
                        children: getPrivacySummaryLabels('PoliciesUpdateSummaryData.OkToContinueButton', 'PoliciesUpdateSummaryData'),
                        isSecondary: false,
                        onClick: () => {
                            handleOkToContinue();
                        },
                        size: "Small",
                        type: "button",
                    }}
                    readTCLinkProps={{
                        label: getPrivacySummaryLabels('PoliciesUpdateSummaryData.ReadFullTermsofUse', 'PoliciesUpdateSummaryData'),
                        onClick: () => {
                            navigate('/t&c-policy'); 
                        },
                    }}
                    readPPLinkProps={{
                        label: getPrivacySummaryLabels('PoliciesUpdateSummaryData.ReadFullPrivacyPolicy', 'PoliciesUpdateSummaryData'),
                        onClick: () => {
                            navigate('/privacy-policy'); 
                        }
                    }} /> </Cirrus.Container>}
        </Cirrus.Page>
        <Footer isLoggedIn={false} />
    </>);
}
export default PolicyUpdatedSummary;