//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { useEffect, useState, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { Context } from "../../../common/context/Context"
import { b2cPolicies } from "../../../common/helpers/AuthService";
import Header from '../../header/simpleHeader'
import Spinner from '../../../common/components/spinner-loader/Spinner';
import htmlParser from 'html-react-parser';
import Footer from "../../../common/components/footer";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { OnBoardingActions } from "../OnBoardingActions";

function OurPolicy() {
    const context = useContext(Context);
    const { instance } = useMsal();
    const [screenLoader, setScreenLoader] = useState(false);
    const [policyContent, setPolicyContent] = useState({
        title: '',
        description: '',
        labels: [],
        richTexts: [],
        isCheckedTC: false,
        isCheckedPP: false,
        isBothChecked: true
    });
    const appInsights = useAppInsightsContext()
    
    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCMSContent();
        TrackActions.PageViewRecord(appInsights, "OurPolicy")
        TrackActions.PageAction("OurPolicy")
    }, [context?.language])

    const fetchCMSContent = async () => {
        let refAcceptedPolicy = JSON.parse(window.sessionStorage.getItem("AcceptedPolicy"))
        let refPolicyContent = JSON.parse(JSON.stringify(policyContent));

        setScreenLoader(true);
        let payload = {
            PageName: "OnboardingPages"
        };
        await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
            (res) => {
                if (res.status === 200) {
                    window.sessionStorage.setItem('setCmsOnBoadingContent', JSON.stringify(res.data))
                    res?.data?.Pages?.filter((x) => {
                        if (x.Key === "OurPolicies") {
                            let compName = x.Components['OurPolicies.OnboardingOurPolicies'];
                            refPolicyContent.title = compName.Title;
                            refPolicyContent.description = compName.Description;
                            refPolicyContent.labels = compName.Labels;
                            refPolicyContent.richTexts = compName.RichTexts;
                        }
                    })
                    if (refAcceptedPolicy !== null) {
                        refPolicyContent.isCheckedTC = true;
                        refPolicyContent.isCheckedPP = true;
                        refPolicyContent.isBothChecked = false
                    }
                    setPolicyContent(refPolicyContent);
                    setScreenLoader(false);
                } else {
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    }

    const fetchLabel = (key) => {
        let refPolicyContent = JSON.parse(JSON.stringify(policyContent));
        let label = '';
        if (refPolicyContent?.labels.length > 0) {
            refPolicyContent.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchRichTexts = (key) => {
        let refPolicyContent = JSON.parse(JSON.stringify(policyContent));
        let label = '';
        if (refPolicyContent?.richTexts.length > 0) {
            refPolicyContent.richTexts.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const checkHandler = (flag) => {
        let refPolicyContent = JSON.parse(JSON.stringify(policyContent));
        if (flag === "TC") {
            refPolicyContent.isCheckedTC = !refPolicyContent.isCheckedTC;
        } else {
            refPolicyContent.isCheckedPP = !refPolicyContent.isCheckedPP;
        }
        if (refPolicyContent.isCheckedTC && refPolicyContent.isCheckedPP) {
            refPolicyContent.isBothChecked = false
        } else {
            refPolicyContent.isBothChecked = true
        }
        setPolicyContent(refPolicyContent)
    }

    const submitPolicyConsent = async (event) => {
        window.sessionStorage.setItem("AcceptedPolicy", JSON.stringify({
            "isTermsAndConditionsAccepted": true,
            "isPrivacyConsentAccepted": true
        }))
        event.preventDefault();
        setScreenLoader(true);
        b2cPolicies.authorities.signUp.extraQueryParameters.refCode = JSON.parse(window.sessionStorage.getItem("ValidatedRegCode"));
        instance.loginRedirect(b2cPolicies.authorities.signUp)
    }

    const backHandler = ()=> {
        sessionStorage.removeItem("Language");
        sessionStorage.removeItem("headerCache");
        sessionStorage.removeItem("footerCache");
        window.history.back()
    }

    if (screenLoader) {
        return (<div>
            <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>)
    }
    return (
        <>
            <Cirrus.Page
                background="secondary"
                backgroundShape="singleDesktop"
                // header={<Header mode="back" />}
                header={<Header mode="default" className="background-none" />}
                className="ourPolicy"
            >
                <Cirrus.Container
                    heading={policyContent?.title}
                    primaryButton={{
                        children: fetchLabel('OurPolicies.OnboardingOurPolicies.ContinueButton'),
                        isFullWidth: true,
                        onClick: submitPolicyConsent,
                        size: 'Large',
                        type: 'button',
                        disabled: policyContent?.isBothChecked
                    }}
                    exitButton={{
                        children: fetchLabel('OurPolicies.OnboardingOurPolicies.BackButton'),
                        isSecondary: true,
                        onClick: backHandler,
                        size: "Large",
                        type: "submit",
                    }}
                    subheading={policyContent?.description}
                    className="our-policy_container"
                >
                    <Cirrus.PolicyHighlightsConsentBox
                        consentCheckbox={{
                            label: fetchLabel('OurPolicies.OnboardingOurPolicies.TickBoxForTermsAndConditions'),
                            name: 'TandCs',
                            removeBorder: true,
                            value: policyContent?.isCheckedTC,
                            defaultChecked: policyContent?.isCheckedTC,
                            checked: policyContent?.isCheckedTC,
                            onChange: () => { checkHandler("TC") },
                        }}
                        heading={fetchLabel('OurPolicies.OnboardingOurPolicies.HighlightsTermAndConditions')}
                        policyLink={{
                            children: fetchLabel('OurPolicies.OnboardingOurPolicies.ReadFullTermAndCondition'),
                            url: '#/t&c-policy'
                        }}
                    >
                        {htmlParser(fetchRichTexts('HighLightsTermsandCondition'))}
                    </Cirrus.PolicyHighlightsConsentBox>
                    <Cirrus.PolicyHighlightsConsentBox
                        consentCheckbox={{
                            label: fetchLabel('OurPolicies.OnboardingOurPolicies.TickBoxForPrivacyPolicy'),
                            name: 'PrivacyPolicy',
                            removeBorder: true,
                            value: policyContent?.isCheckedPP,
                            defaultChecked: policyContent?.isCheckedPP,
                            checked: policyContent?.isCheckedPP,
                            onChange: () => { checkHandler("PP") }
                        }}
                        heading={fetchLabel('OurPolicies.OnboardingOurPolicies.HighlightsPrivacyPolicy')}
                        policyLink={{
                            children: fetchLabel('OurPolicies.OnboardingOurPolicies.ReadFullPrivacyPolicy'),
                            url: '#/privacy-policy'
                        }}
                    >
                        {htmlParser(fetchRichTexts('HighlightsPrivacyPolicy'))}
                    </Cirrus.PolicyHighlightsConsentBox>
                </Cirrus.Container>
            </Cirrus.Page>
            <Footer isLoggedIn={false} />
        </>
    )
}

export default OurPolicy;
