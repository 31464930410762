//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const CandidateCalenderActions = {
    GetCMSForCandidateCalender: GetCMSForCandidateCalender,
    GetCalenderEvents: GetCalenderEvents,
    GetMyCoach: getMyCoach,
    CaptureActivity: captureActivity,
}

function GetCMSForCandidateCalender(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function GetCalenderEvents(payload) {
    return http.CalenderPost(environments.GET_CALENDER_EVENTS, payload);
}

function getMyCoach() {
    return http.get(environments.GET_MY_COACH);
}

function captureActivity(payload) {
    return http.post(environments.CreateCandidateActivities, payload);
}
