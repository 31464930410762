//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import { AssessmentActions } from "../AssessmentActions";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import "../style.css";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import PFAIpdfTemplate from "./pdfTemplate";
import { coachActions } from "../../coach/coachActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function PrepareForAnInterviewResult() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [loadSpinner, setLoadSpinner] = useState();
  const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
  const [assessmentStatus, setAssessmentStatus] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [resultData, setResultData] = useState();
  const [jobRoleAnswer, setJobRoleAnswer] = useState("");
  const [dropDownDefaultValue, setDropDownDefaultValue] = useState();
  const [screenLoader, setScreenLoader] = useState();
  const [coachdata, setcoachdata] = useState();
  const appInsights = useAppInsightsContext();

  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (/Chrome/.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browser = "Safari";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Microsoft Edge";
  } else {
    browser = "Unknown Browser";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCMSData();
    getAssessmentStatus();
    getMyCoach();
    TrackActions.PageViewRecord(appInsights, "PrepareInterviewResult");
    TrackActions.PageAction("PrepareInterviewResult");
  }, [context.language]);

  const fetchCMSData = () => {
    let resData = context.cmsPrepareForInterviewContent;
    ActivityAction.SaveActivity(
      resData?.internalAssessmentId,
      "completedassesment",
      resData?.title
    );
    setCmsAssessmentContent(resData);
  };

  const getAssessmentStatus = async () => {
    setScreenLoader(true);
    let response = await AssessmentActions.GetAssessmentStatusById(
      context.cmsPrepareForInterviewContent.internalAssessmentId
    );
    setAssessmentStatus(response.data);
    setIsSaved(response.data.isSaved);
    setIsLiked(response.data.isLiked);

    let jobRole = await AssessmentActions.GetJobRole(
      context.cmsPrepareForInterviewContent.internalAssessmentId
    );
    setJobRoleAnswer(jobRole.data.jobRole);

    let res = await AssessmentActions.GetAssessmentStatusAndSavedResponseById(
      context.cmsPrepareForInterviewContent.internalAssessmentId
    );
    let cmsData = context.cmsPrepareForInterviewContent;
    let savedResponses = res.data.learningResourceAssessmentResponseModels;
    if (savedResponses.length) {
      savedResponses.map((savedResponse) => {
        let AnswerId;
        let QuestionId = savedResponse.questionId;
        if (savedResponse.answerType === "id") {
          AnswerId =
            savedResponse.answerValue != ""
              ? savedResponse.answerValue.split(",")
              : "";
        } else {
          AnswerId = savedResponse.answerValue;
        }
        let updatedOptions;
        cmsData?.assessmentQuestionSet?.map((assessment) => {
          if (
            assessment.Key ===
            "Assessments.PrepareForAnInterview.AboutTheOrganisation"
          ) {
            assessment.QuestionList.map((question) => {
              if (question.QuestionId === QuestionId) {
                if (AnswerId.length) {
                  if (Array.isArray(AnswerId)) {
                    AnswerId?.map((answerid) => {
                      question.AnswerList.map((options) => {
                        if (options.AnswerId === answerid) {
                          if (question.OptionType !== "Dropdown") {
                            Object.keys(options).forEach((key) => {
                              if (key === "defaultChecked") {
                                options[key] = true;
                              }
                            });
                          } else {
                            setDropDownDefaultValue(answerid);
                          }
                        }
                      });
                    });
                  }
                }
                if (question.OptionType === "TextArea") {
                  Object.keys(question).forEach((key) => {
                    if (key === "AnswerList") {
                      question[key] = savedResponse.answerValue;
                    }
                  });
                }
              }
            });
          }
          if (
            assessment.Key === "Assessments.PrepareForAnInterview.Rationale"
          ) {
            assessment.QuestionList.map((question) => {
              if (question.QuestionId === QuestionId) {
                Object.keys(question).forEach((key) => {
                  if (key === "AnswerList") {
                    question[key] = savedResponse.answerValue;
                  }
                });
              }
            });
          }
          if (
            assessment.Key === "Assessments.PrepareForAnInterview.Questions"
          ) {
            assessment.QuestionList.map((question) => {
              if (question.QuestionId === QuestionId) {
                Object.keys(question).forEach((key) => {
                  if (key === "AnswerList") {
                    question[key] = savedResponse.answerValue;
                  }
                });
              }
            });
          }
        });
      });
    }
    setResultData(cmsData);
    setScreenLoader(false);
  };

  const fetchCommonLabel = (key) => {
    let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
    let label = "";
    if (refCmsAssessmentContent?.commonLabels) {
      refCmsAssessmentContent?.commonLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
    let label = "";
    if (refCmsAssessmentContent?.commonResourceLabels) {
      refCmsAssessmentContent?.commonResourceLabels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchStatusID = (key) => {
    let refCmsAssessmentContent = context.cmsPrepareForInterviewContent;
    let id = "";
    if (refCmsAssessmentContent?.commonStatus) {
      refCmsAssessmentContent?.commonStatus.map((x) => {
        if (x.Key === key) {
          id = x.ID;
        }
      });
    }
    return id;
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus.learningResourceId,
                learningResourceStatus: assessmentStatus.learningResourceStatus,
                learningResourceType:
                  context.cmsPrepareForInterviewContent?.resourceCategoryType
                    ?.ID,
                resourceTypeId:
                  context.cmsPrepareForInterviewContent?.resourceType?.ID,
                isSaved: assessmentStatus.isSaved,
                isLiked: assessmentStatus.isLiked,
                lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: assessmentStatus?.isSharedWithCoach,
                resultDocumentId: assessmentStatus?.resultDocumentId,
              };
              AssessmentActions.SaveAssessmentStatus(
                context.cmsPrepareForInterviewContent.resourceCategoryType?.ID,
                assessmentStatus.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setLoadSpinner(false);
                    let response = AssessmentActions.GetAssessmentStatusById(
                      context.cmsPrepareForInterviewContent.internalAssessmentId
                    );
                    setAssessmentStatus(response.data);
                    getAssessmentStatus();
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const updateAssessmentStatus = async (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: assessmentStatus.learningResourceId,
      learningResourceStatus: assessmentStatus.learningResourceStatus,
      learningResourceType:
        context.cmsPrepareForInterviewContent?.resourceCategoryType?.ID,
      resourceTypeId: context.cmsPrepareForInterviewContent?.resourceType?.ID,
      isSaved: assessmentStatus.isSaved,
      isLiked: assessmentStatus.isLiked,
      lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
      isDownloaded: assessmentStatus?.isDownloaded,
      isSharedWithCoach: assessmentStatus?.isSharedWithCoach,
      resultDocumentId: docId,
    };
    await AssessmentActions.SaveAssessmentStatus(
      context.cmsPrepareForInterviewContent.resourceCategoryType?.ID,
      assessmentStatus.learningResourceId,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          AssessmentActions.GetAssessmentStatusById(
            context.cmsPrepareForInterviewContent.internalAssessmentId
          ).then((response) => {
            if (response.status === 200) {
              setAssessmentStatus(response.data);
              setIsSaved(response.data.isSaved);
              setIsLiked(response.data.isLiked);
            }
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const generatePDF = () => {
    let content = document.getElementById("assessment-result-wrapper");
    setLoadSpinner(true);

    const datetime = new Date();
    const year = datetime.getFullYear();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hours = datetime.getHours();
    const mins = datetime.getMinutes();
    const sec = datetime.getSeconds();
    const newDatetime =
      year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

    let img = new Image();
    html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        img.src = imgData;

        const pdf = new jsPDF({ orientation: "l", format: "a2", unit: "px" });
        pdf.viewerPreferences(
          { FitWindow: true, PrintArea: "MediaBox", PrintClip: "MediaBox" },
          true
        );
        console.log(browser);
        if (browser == "Safari") {
          let totalPages = Math.ceil(
            canvas.height / pdf.internal.pageSize.height
          );
          for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
              pdf.addPage();
            }
            let pageHeight = pdf.internal.pageSize.height;
            let pageWidth = pdf.internal.pageSize.width;
            let yPosition = -(i * pageHeight);

            // Add the canvas image to the PDF
            pdf.addImage(
              canvas,
              "JPEG",
              20,
              yPosition + 60,
              canvas.width,
              canvas.height
            );

            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
            pdf.setFontSize(18);
            //header background
            pdf.setFillColor(244, 244, 245);
            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

            //header
            const header = `${PDFheaderLogo}`;
            pdf.addImage(header, "png", 20, 20, 60, 20);

            //line above footer
            pdf.setDrawColor(236, 236, 236);
            pdf.line(
              20,
              pdf.internal.pageSize.height - 90,
              pdf.internal.pageSize.width - 20,
              pdf.internal.pageSize.height - 90,
              "S"
            );

            // footer
            const footerLeft = `${PDFfooterLogo}`;
            const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`;
            pdf.text(
              footerRight,
              pdf.internal.pageSize.width - 300,
              pdf.internal.pageSize.height - 50
            );
            pdf.addImage(
              footerLeft,
              "png",
              20,
              pdf.internal.pageSize.height - 80,
              160,
              45
            );
          }

          let file = new File(
            [pdf.output("blob")],
            context.cmsPrepareForInterviewContent.title +
              "-" +
              newDatetime.toLocaleString() +
              ".pdf",
            { type: "application/pdf" }
          );

          let formData = new FormData();
          formData.append(file.name, file);
          let candidateID =
            context?.idTokenClaims?.signupId != undefined
              ? context?.idTokenClaims?.signupId
              : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
                  "signupId"
                ];
          formData.append("OwnerId", candidateID);
          formData.append("OwnerType", "Candidate");

          MyDocumentsActions.UploadDocuments(formData).then(
            (res) => {
              let docId;
              res.data.map((doc) => {
                docId = doc.id;
              });
              updateAssessmentStatus(docId);
              context.setGeneratePdfFlag(false);
              setLoadSpinner(false);
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
            }
          );
        } else {
          pdf.html(img, {
            margin: [100, 10, 120, 10],
            callback: (pdf) => {
              let pageCount = pdf.internal.getNumberOfPages();

              for (let i = 0; i < pageCount; i++) {
                pdf.setPage(i);

                let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                pdf.setFontSize(18);

                //header background
                pdf.setFillColor(244, 244, 245);
                pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

                //header
                const header = `${PDFheaderLogo}`;
                pdf.addImage(header, "png", 20, 20, 60, 20);

                //line above footer
                pdf.setDrawColor(236, 236, 236);
                pdf.line(
                  20,
                  pdf.internal.pageSize.height - 90,
                  pdf.internal.pageSize.width - 20,
                  pdf.internal.pageSize.height - 90,
                  "S"
                );

                // footer
                const footerLeft = `${PDFfooterLogo}`;
                const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`;
                pdf.text(
                  footerRight,
                  pdf.internal.pageSize.width - 300,
                  pdf.internal.pageSize.height - 50
                );
                pdf.addImage(
                  footerLeft,
                  "png",
                  20,
                  pdf.internal.pageSize.height - 80,
                  160,
                  45
                );
              }

              let file = new File(
                [pdf.output("blob")],
                context.cmsPrepareForInterviewContent.title +
                  "-" +
                  newDatetime.toLocaleString() +
                  ".pdf",
                { type: "application/pdf" }
              );

              let formData = new FormData();
              formData.append(file.name, file);
              let candidateID =
                context?.idTokenClaims?.signupId != undefined
                  ? context?.idTokenClaims?.signupId
                  : JSON.parse(
                      window.sessionStorage.getItem("setIdTokenClaims")
                    )["signupId"];
              formData.append("OwnerId", candidateID);
              formData.append("OwnerType", "Candidate");

              MyDocumentsActions.UploadDocuments(formData).then(
                (res) => {
                  let docId;
                  res.data.map((doc) => {
                    docId = doc.id;
                  });
                  updateAssessmentStatus(docId);
                  context.setGeneratePdfFlag(false);
                  setLoadSpinner(false);
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            },
          });
        }
      }
    );
  };

  const shareResultWithCoach = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: context.cmsPrepareForInterviewContent.title,
      assessmentId: context.cmsPrepareForInterviewContent.internalAssessmentId,
      documentId: assessmentStatus?.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus.learningResourceId,
            learningResourceStatus: assessmentStatus.learningResourceStatus,
            learningResourceType:
              context.cmsPrepareForInterviewContent?.resourceCategoryType?.ID,
            resourceTypeId:
              context.cmsPrepareForInterviewContent?.resourceType?.ID,
            isSaved: assessmentStatus.isSaved,
            isLiked: assessmentStatus.isLiked,
            lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
            isDownloaded: assessmentStatus?.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStatus?.resultDocumentId,
          };
          AssessmentActions.SaveAssessmentStatus(
            context.cmsPrepareForInterviewContent.resourceCategoryType?.ID,
            assessmentStatus.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                setLoadSpinner(false);
                let response = AssessmentActions.GetAssessmentStatusById(
                  context.cmsPrepareForInterviewContent.internalAssessmentId
                );
                setAssessmentStatus(response.data);
                getAssessmentStatus();
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  useEffect(() => {
    if (screenLoader === false) {
      if (context.generatePdfFlag) {
        generatePDF();
      }
    }
  }, [screenLoader]);

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <div className="assessment-page">
        <Container className="pt-lg-4 pt-3 pb-4">
          <Row className="justify-content-between">
            <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
              <Cirrus.Button
                isSecondary
                onClick={() =>
                  navigate("/" + pageOrgin.id + "/prepare-for-an-interview")
                }
              >
                {fetchCommonLabel("CommonComponent.CommonBackButton")}
              </Cirrus.Button>
            </Col>
            <Col
              sm={12}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xs={{ order: 3 }}
              className="d-flex"
            >
              {/* <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>Progress {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div> */}
            </Col>
            <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
              <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
                {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
              </Cirrus.Button>
            </Col>
          </Row>
        </Container>
        <Container className="assessment-review-result-wrapper assessment-result-wrapper text-start mb-lg-5 mb-3 p-lg-5 p-4">
          {loadSpinner && (
            <div className="loader">
              <Cirrus.Spinner size={50} />
            </div>
          )}
          <Row className="mb-5 pb-3 border-bottom">
            <Col lg={5}>
              <Cirrus.Header
                size="h4"
                className="mb-3 text-lg-start text-md-start text-center"
              >
                {cmsAssessmentContent?.resultPage?.Title}
              </Cirrus.Header>
            </Col>
            <Col lg={7} className="download-share-retake">
              <Cirrus.Button
                isSecondary
                className="me-2"
                onClick={() =>
                  downloadDocument(assessmentStatus?.resultDocumentId)
                }
              >
                {fetchCommonResourceLabel(
                  "CommonResourceComponent.DownloadAsPDF"
                )}
              </Cirrus.Button>
              <Cirrus.Button
                isSecondary
                className="me-2"
                onClick={() => shareResultWithCoach()}
                disabled={coachdata ? false : true}
              >
                {fetchCommonResourceLabel(
                  "CommonResourceComponent.ShareResultsWithCoach"
                )}
              </Cirrus.Button>
              <Cirrus.Button
                isSecondary
                onClick={() =>
                  navigate("/" + pageOrgin.id + "/prepare-for-an-interview")
                }
              >
                {fetchCommonLabel("CommonComponentCommonRetakeButton")}
              </Cirrus.Button>
            </Col>
          </Row>
          <Row>
            <Col lg={10} className="p-lg-5 p-3 m-auto">
              <Cirrus.Headline size="Small">
                {jobRoleAnswer && jobRoleAnswer}
              </Cirrus.Headline>
              {resultData?.assessmentQuestionSet?.map((assessment, idx) => {
                if (
                  assessment.Key ===
                  "Assessments.PrepareForAnInterview.Rationale"
                ) {
                  return (
                    <>
                      <Cirrus.Title size="large" key={idx}>
                        {assessment.Title}
                      </Cirrus.Title>
                      <ul>
                        {assessment?.QuestionList?.map((question, qIdx) => {
                          if (question.AnswerList.length) {
                            return (
                              <>
                                <li className="mb-3">{question.AnswerList}</li>
                              </>
                            );
                          } else {
                            return <p>&nbsp;</p>;
                          }
                        })}
                      </ul>
                    </>
                  );
                }
                if (
                  assessment.Key ===
                  "Assessments.PrepareForAnInterview.Questions"
                ) {
                  return (
                    <>
                      <Cirrus.Title size="large" key={idx}>
                        {assessment.Title}
                      </Cirrus.Title>
                      <ul>
                        {assessment?.QuestionList?.map((question, qIdx) => {
                          if (question.AnswerList.length) {
                            return (
                              <>
                                <li className="mb-3">{question.AnswerList}</li>
                              </>
                            );
                          } else {
                            return <p>&nbsp;</p>;
                          }
                        })}
                      </ul>
                    </>
                  );
                }
              })}
            </Col>
          </Row>
        </Container>
      </div>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}

      <div style={{ opacity: "0" }}>
        <div
          id="assessment-result-wrapper"
          className="assessment-review-result-wrapper"
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            zIndex: "-1",
            display: "block",
            width: "1300px",
          }}
        >
          <table
            cellPadding={10}
            cellSpacing={10}
            style={{
              width: "100%",
              border: "0px",
              borderRadius: "5px",
              textAlign: "left",
              fontSize: "13px",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <div style={{ width: "70%", paddingLeft: "100px" }}>
                    <Cirrus.Headline size="Small">
                      {jobRoleAnswer && jobRoleAnswer}
                    </Cirrus.Headline>
                    {resultData?.assessmentQuestionSet?.map(
                      (assessment, idx) => {
                        if (
                          assessment.Key ===
                          "Assessments.PrepareForAnInterview.Rationale"
                        ) {
                          return (
                            <>
                              <Cirrus.Title size="large" key={idx}>
                                {assessment.Title}
                              </Cirrus.Title>
                              <ul>
                                {assessment?.QuestionList?.map(
                                  (question, qIdx) => {
                                    return (
                                      <>
                                        <li className="mb-3">
                                          {question.AnswerList}
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </>
                          );
                        }
                        if (
                          assessment.Key ===
                          "Assessments.PrepareForAnInterview.Questions"
                        ) {
                          return (
                            <>
                              <Cirrus.Title size="large" key={idx}>
                                {assessment.Title}
                              </Cirrus.Title>
                              <ul>
                                {assessment?.QuestionList?.map(
                                  (question, qIdx) => {
                                    return (
                                      <>
                                        <li className="mb-3">
                                          {question.AnswerList}
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </>
                          );
                        }
                      }
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default PrepareForAnInterviewResult;
