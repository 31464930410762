//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { ArticleActions } from './articleActions';
import Header from '../header/simpleHeader';
import Footer from '../../common/components/footer';
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../assets/fonts/icomoon/Icon";
import Spinner from '../../common/components/spinner-loader/Spinner';
import './style.css'


const ArticleCards = () => {
    const pageOrgin = useParams();
    const context = useContext(Context);
    const navigate = useNavigate();

    return (
        <div className="article-page">
            {/* <Cirrus.Page className='article-page'
                background="secondary"
                header={<Header mode="back" />}
                removeFooterPadding
            > */}
            <Container fluid className='background-White'>
                <Row>
                    <Col lg={6} className='m-auto'>
                        <p className='mb-0'>Article ID's</p>
                        <ul className='mb-5'>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => navigate("/article-page/A6043F87-07F0-4250-89A6-820CEC639913")}>A6043F87-07F0-4250-89A6-820CEC639913</a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => navigate("/article-page/2F09C5C4-2B72-4A65-982F-444B2FF21C46")}>2F09C5C4-2B72-4A65-982F-444B2FF21C46</a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => navigate("/article-page/0655A8BD-9A7A-4F52-B8D4-9197739C54A9")}>0655A8BD-9A7A-4F52-B8D4-9197739C54A9</a>
                            </li>
                            <li>
                                <a style={{ cursor: 'pointer' }} onClick={() => navigate("/article-page/0BADB67E-3F11-42F1-A718-F3F62F056D61")}>0BADB67E-3F11-42F1-A718-F3F62F056D61</a>
                            </li>
                        </ul>
                        <Cirrus.Input
                            errorMessage="Please enter valid Article ID"
                            id="test-input"
                            label="Enter Article ID"
                            name="ArticleId"
                            placeholder="Please enter Article ID"
                            className='mb-3'
                        />
                        <Cirrus.Button
                            onClick={() => {
                                let articleId = document.getElementById('test-input').value
                                navigate("/article-page/" + articleId)
                            }}
                        >Submit</Cirrus.Button>
                    </Col>
                </Row>
            </Container>


            {/* </Cirrus.Page>
            <Footer isLoggedIn={false} /> */}
        </div>
    )
}

export default ArticleCards;
