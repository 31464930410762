//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import '../style.css';

function PrepareForAnInterviewAboutRole() {

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container className='pt-4 pb-4'>
                <Row>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button isSecondary>Back</Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>0%</Cirrus.Label>
                            <Cirrus.ProgressBar now={25} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button>Exit</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            <Container className='assessment-review-result-wrapper assessment-result-wrapper mb-5 p-5'>
                <Row>
                    <Col lg={10} className='p-5 m-auto'>
                        <Cirrus.Headline size='Small'>About the role</Cirrus.Headline>
                        <body size='medium'>Let us know everything about this role so we can set up this assessment. If you don’t have a job description to upload, answer a few questions about the role instead.  </body>
                        <div className="text-start mt-4">
                            <Cirrus.Input
                                errorMessage="Please enter a first name"
                                id="test-input"
                                label="Enter role title (required)"
                                name="FirstName"
                                onBlur={() => { }}
                                onChange={function noRefCheck() { }}
                                onFocus={function noRefCheck() { }}
                                onKeyDown={function noRefCheck() { }}
                                placeholder="Enter here"
                            />
                            <div className="mt-5 border-bottom d-flex justify-content-between">
                                <Cirrus.Title size="large">Select an applied job from (platform name) </Cirrus.Title>
                                <Cirrus.Button className="mb-4" onClick={() => { }}>
                                    Select job
                                </Cirrus.Button>
                            </div>
                            <div className="mt-5 border-bottom d-flex justify-content-between">
                                <Cirrus.Title size="large">Or, upload a job description </Cirrus.Title>
                                <Cirrus.Button className="mb-4" onClick={() => { }}>
                                    Upload a job description
                                </Cirrus.Button>
                            </div>
                            <div className="mt-5 text-end">
                                <Cirrus.Title size="large">Skip if you don’t need to tell us about the role <Cirrus.Button className="text-end" isSecondary onClick={() => { }}>
                                    Skip
                                </Cirrus.Button></Cirrus.Title>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default PrepareForAnInterviewAboutRole;
