//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState } from 'react';
import * as Cirrus from 'manpowergroup-cirrus';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all';
import './Style.css'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';


function FullCalender({ initialView, caltimeZone, prevButtonClick, nextButtonClick, todayButtonClick, eventsData, initialLocale, setRef, sInitialDate, showEventDetails, GetEventData, backButtonLabel, nextButtonLabel, todayButtonLabel }) {
    let refTimeFormat = JSON.parse(sessionStorage.getItem("setProgramDetails"))["timeFormat"];

    const openEventDetailsDlg = (objEventData) => {
        GetEventData(objEventData)
        showEventDetails(true)
    }

    return (
        <>
            <FullCalendar
                plugins={[momentTimezonePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                eventClassNames={eventsData.className}
                initialView={initialView}
                // timeZone={caltimeZone}
                customButtons={{
                    PreviousButton: {
                        // text: 'Back',
                        text: backButtonLabel,
                        click: prevButtonClick
                    },
                    "NextButton": {
                        // text: 'Next',
                        text: nextButtonLabel,
                        click: nextButtonClick
                    },
                    "TodayButton": {
                        // text: 'Today',
                        text: todayButtonLabel,
                        click: todayButtonClick
                    }
                }}
                headerToolbar={{
                    left: 'TodayButton,PreviousButton,NextButton',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                buttonIcons={false}
                events={eventsData}
                locales={allLocales}
                locale={initialLocale}
                dayMaxEventRows={2}
                eventMaxStack={1}
                eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: refTimeFormat == 12 ? true : false }}
                // eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: true }}
                eventClick={(info) => {
                    openEventDetailsDlg(info.event)
                }}
                fixedWeekCount={false}
                selectable={true}
                aspectRatio={3}
                windowResize={function (ev) { }}
                ref={setRef}
                initialDate={sInitialDate}
                displayEventEnd={true}
            />
        </>)
}

export default FullCalender;
