//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./common/helpers/AuthService";
import "./index.css"
import 'manpowergroup-cirrus/index.css';
import './fonts.css'

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <App msalInstance={msalInstance}/>
  </React.StrictMode>,
  document.getElementById('root')
);

