//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AssessmentActions } from "../AssessmentActions";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { saveAs } from "file-saver";
import { coachActions } from "../../coach/coachActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

const VandDResultPage = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
  const [questionsSet, setQuestionsSet] = useState();
  const [answersList, setAnswersList] = useState();
  const [cmsTitle, setCmsTitle] = useState();
  const [coachdata, setcoachdata] = useState();

  const [assessmentStatus, setAssessmentStatus] = useState();
  const appInsights = useAppInsightsContext();

  const fetchCMSData = () => {
    let resData = context.cmsThoughtQuestionsContent;
    ActivityAction.SaveActivity(
      resData?.internalAssessmentId,
      "completedassesment",
      resData?.title
    );
    setCmsAssessmentContent(resData);
  };

  const fetchCommonLabel = (key) => {
    let refCmsAssessmentContent = context.cmsThoughtQuestionsContent;
    let label = "";
    if (refCmsAssessmentContent?.pageContent?.Labels) {
      refCmsAssessmentContent?.pageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCommonResourceLabel = (key) => {
    let refCmsAssessmentContent = cmsAssessmentContent;
    let label = "";
    if (refCmsAssessmentContent?.questionPageContent?.Labels) {
      refCmsAssessmentContent?.questionPageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchAnswers = (id) => {
    AssessmentActions.GetSavedResponseByAssessmentId(id).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          let refAnswerList;
          refAnswerList = res.data;
          setAnswersList(refAnswerList);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchAnswersList = (answers, questionId) => {
    let answer = "";
    let answerSet = answers?.filter(
      (answer) => answer.questionId === questionId
    );
    answer =
      answerSet &&
      answerSet[0] &&
      answerSet[0].answerValue &&
      answerSet[0].answerValue;
    return answer;
  };

  useEffect(() => {
    fetchCMSData();
    getMyCoach();
    getAssessmentStatus();
    let refQuestionSet = context.questionCollection;
    setQuestionsSet(refQuestionSet);
    fetchAnswers(context.cmsThoughtQuestionsContent?.internalAssessmentId);
    let titleSet = context.cmsThoughtQuestionsContent;
    setCmsTitle(titleSet);
    window.scrollTo(0, 0);
    TrackActions.PageViewRecord(appInsights, "ThoughtQuestionsResult");
    TrackActions.PageAction("ThoughtQuestionsResult");
  }, [context.language]);

  const getAssessmentStatus = async () => {
    let response = await AssessmentActions.GetAssessmentStatusById(
      context.cmsThoughtQuestionsContent.internalAssessmentId
    );
    setAssessmentStatus(response.data);
  };

  const downloadDocument = (docId) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let fileName;
    let payload = {
      ownerId: candidateID,
    };
    MyDocumentsActions.GetAllDocuments(payload).then(
      (response) => {
        fileName = response.data.find((x) => {
          if (x.id === docId) {
            return x.documentName;
          }
        }).documentName;

        MyDocumentsActions.DownloadDocument(docId).then(
          (response) => {
            if (response && response.status === 200 && response.data) {
              let contentTypeHeader = response.headers["content-type"];
              var blob = new Blob([response.data], {
                type: contentTypeHeader.toLowerCase(),
                charset: "UTF-8",
              });
              saveAs(blob, fileName);

              let payload = {
                candidateId: candidateID,
                learningResourceId: assessmentStatus.learningResourceId,
                learningResourceStatus: assessmentStatus.learningResourceStatus,
                learningResourceType:
                  cmsAssessmentContent?.resourceCategoryType?.ID,
                resourceTypeId: cmsAssessmentContent?.resourceType?.ID,
                isSaved: assessmentStatus.isSaved,
                isLiked: assessmentStatus.isLiked,
                lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
                isDownloaded: true,
                isSharedWithCoach: assessmentStatus?.isSharedWithCoach,
                resultDocumentId: assessmentStatus?.resultDocumentId,
              };
              AssessmentActions.SaveAssessmentStatus(
                cmsAssessmentContent?.resourceCategoryType?.ID,
                assessmentStatus.learningResourceId,
                payload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setLoadSpinner(false);
                    getAssessmentStatus();
                  } else {
                    setLoadSpinner(false);
                  }
                },
                (err) => {
                  console.log(err);
                  setLoadSpinner(false);
                }
              );
            } else {
              setLoadSpinner(false);
            }
          },
          (err) => {
            console.log(err);
            setLoadSpinner(false);
          }
        );
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const shareResultWithCoach = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    setLoadSpinner(true);
    let payload = {
      assessmentName: context.cmsThoughtQuestionsContent.title,
      assessmentId: context.cmsThoughtQuestionsContent.internalAssessmentId,
      documentId: assessmentStatus.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: assessmentStatus.learningResourceId,
            learningResourceStatus: assessmentStatus.learningResourceStatus,
            learningResourceType:
              context.cmsThoughtQuestionsContent?.resourceCategoryType?.ID,
            resourceTypeId:
              context.cmsThoughtQuestionsContent?.resourceType?.ID,
            isSaved: assessmentStatus.isSaved,
            isLiked: assessmentStatus.isLiked,
            lastAnsweredPageId: assessmentStatus.lastAnsweredPageId,
            isDownloaded: assessmentStatus?.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStatus?.resultDocumentId,
          };
          AssessmentActions.SaveAssessmentStatus(
            context.cmsThoughtQuestionsContent?.resourceCategoryType?.ID,
            assessmentStatus.learningResourceId,
            payload
          ).then(
            (res) => {
              if (res.status === 200) {
                setLoadSpinner(false);
                getAssessmentStatus();
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchCommonResourceLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchCommonLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  return (
    <div className="assessment-page thought-provoking-result">
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <Container className="pt-4 pb-4">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/thought-provoking-questions")
              }
            >
              {fetchCommonLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
          </Col>
          <Col
            md={2}
            lg={2}
            xs={{ span: 6, order: 2 }}
            className="text-end exit"
          >
            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
              {fetchCommonResourceLabel("CommonResourceComponent.Exit")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>
      <Container className="assessment-review-result-wrapper assessment-result-wrapper">
        {loadSpinner && (
          <div className="loader">
            <Cirrus.Spinner size={50} />
          </div>
        )}
        <Row className="mb-5 pb-3 border-bottom top-nav">
          <Col lg={5}>
            <Cirrus.Header size="h4" className="mb-3 text-center text-md-start">
              {cmsAssessmentContent?.resultPage?.Title}
            </Cirrus.Header>
          </Col>
          <Col lg={7} className="download-share-retake">
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() => {
                downloadDocument(assessmentStatus?.resultDocumentId);
              }}
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.DownloadAsPDF"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              className="me-2"
              onClick={() => shareResultWithCoach()}
              disabled={coachdata ? false : true}
            >
              {fetchCommonResourceLabel(
                "CommonResourceComponent.ShareResultsWithCoach"
              )}
            </Cirrus.Button>
            <Cirrus.Button
              isSecondary
              onClick={() => {
                context.setEdit(true);
                navigate(
                  context.TPProgress == 100
                    ? "/" + pageOrgin.id + "/thought-provoking-questions"
                    : "/" +
                        pageOrgin.id +
                        "/thought-provoking-questions/questions"
                );
              }}
            >
              {fetchCommonLabel("CommonComponentCommonRetakeButton")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row>
          <Cirrus.Headline
            size="Small"
            className="mb-3 text-start section-title"
          >
            {cmsAssessmentContent?.resultPage?.Description}
          </Cirrus.Headline>
        </Row>
        <div className="question-answer-wrapper">
          <Row className="mb-3 text-start">
            <div className="section-heading">
              <Cirrus.Label>
                {cmsTitle &&
                  cmsTitle.questionsList &&
                  cmsTitle.questionsList[0] &&
                  cmsTitle.questionsList[0] &&
                  cmsTitle.questionsList[0].Title}
              </Cirrus.Label>
            </div>
            {questionsSet?.futureQuestionsSet.map((question) => {
              return (
                <div>
                  <ul>
                    <li size="Large" className="mb-3 text-start question-title">
                      {question?.QuestionTitle}
                    </li>
                  </ul>
                  <div className="answers-row">
                    <Cirrus.Title>
                      {fetchAnswersList(
                        answersList && answersList,
                        question?.QuestionId
                      )}
                    </Cirrus.Title>
                  </div>
                </div>
              );
            })}
          </Row>
          <Row className="mb-3 text-start">
            <div className="section-heading">
              <Cirrus.Label>
                {cmsTitle &&
                  cmsTitle.questionsList &&
                  cmsTitle.questionsList[2] &&
                  cmsTitle.questionsList[2] &&
                  cmsTitle.questionsList[2].Title}
              </Cirrus.Label>
            </div>
            {questionsSet?.seeYourselfQuestionsSet.map((question) => {
              return (
                <div>
                  <ul>
                    <li size="Large" className="mb-3 text-start question-title">
                      {question?.QuestionTitle}
                    </li>
                  </ul>
                  <div className="answers-row">
                    <Cirrus.Title>
                      {fetchAnswersList(
                        answersList && answersList,
                        question?.QuestionId
                      )}
                    </Cirrus.Title>
                  </div>
                </div>
              );
            })}
          </Row>
          <Row className="mb-3 text-start">
            <div className="section-heading">
              <Cirrus.Label>
                {cmsTitle &&
                  cmsTitle.questionsList &&
                  cmsTitle.questionsList[4] &&
                  cmsTitle.questionsList[4] &&
                  cmsTitle.questionsList[4].Title}
              </Cirrus.Label>
            </div>
            {questionsSet?.othersSeeQuestionsSet.map((question) => {
              return (
                <div>
                  <ul>
                    <li size="Large" className="mb-3 text-start question-title">
                      {question?.QuestionTitle}
                    </li>
                  </ul>
                  <div className="answers-row">
                    <Cirrus.Title>
                      {fetchAnswersList(
                        answersList && answersList,
                        question?.QuestionId
                      )}
                    </Cirrus.Title>
                  </div>
                </div>
              );
            })}
          </Row>
          <Row className="mb-3 text-start">
            <div className="section-heading">
              <Cirrus.Label>
                {cmsTitle &&
                  cmsTitle.questionsList &&
                  cmsTitle.questionsList[6] &&
                  cmsTitle.questionsList[6] &&
                  cmsTitle.questionsList[6].Title}
              </Cirrus.Label>
            </div>
            {questionsSet?.importantQuestionsSet.map((question) => {
              return (
                <div>
                  <ul>
                    <li size="Large" className="mb-3 text-start question-title">
                      {question?.QuestionTitle}
                    </li>
                  </ul>
                  <div className="answers-row">
                    <Cirrus.Title>
                      {fetchAnswersList(
                        answersList && answersList,
                        question?.QuestionId
                      )}
                    </Cirrus.Title>
                  </div>
                </div>
              );
            })}
          </Row>
        </div>
      </Container>
      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
};

export default VandDResultPage;
