//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

const VandDpdfTemplate = ({ categories, getResponse }) => {

    return (
        <>
            <table cellPadding={10} cellSpacing={10} style={{ width: '90%', border: '0px', borderRadius: '5px', textAlign: 'left', fontSize: '13px' }}>
                <tbody>
                    <tr>
                        {
                            categories?.length && categories?.map((category, categoryIndex) => {
                                return (
                                    <>
                                        <td width={'20%'} key={categoryIndex} style={{ verticalAlign: 'top' }}>
                                            <table style={{ border: '0px', background: '#ECEFF5', width: '100%', borderRadius: '5px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ background: '#D9E2FF', color: '#1456FF', padding: '10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', textAlign: 'center' }}>
                                                            {category.AnswerText}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        getResponse?.map((res, resIndex) => {
                                                            if (res.answerId === category.AnswerId) {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ padding: '5px 10px 5px 10px' }}>
                                                                            <span style={{ background: '#fff', padding: '5px 10px', display: 'inline-block', borderRadius: '4px' }}>
                                                                                {res.questionText}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </>
                                )
                            })
                        }
                    </tr>
                </tbody>
                <span style={{ fontSize: '1px' }}>.</span>
            </table>
        </>
    )
}

export default VandDpdfTemplate;
