//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useNavigate } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { useEffect, useState, useContext, useRef } from 'react';
import Header from '../../header/simpleHeader';
import { OnBoardingActions } from "../OnBoardingActions";
import { Context } from '../../../common/context/Context';
import Footer from "../../../common/components/footer";
import Spinner from '../../../common/components/spinner-loader/Spinner';

function MultiSelect() {
    const heightRef = useRef();
    const fixedHeightRef = useRef();
    const [screenLoader, setScreenLoader] = useState(false);
    const [questionShortName, setQuestionShortName] = useState();
    const [answerShortName, setAnswerShortName] = useState([]);
    const [isEmptyAnswers, setIsEmptyAnswers] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("/question-2");
    }
    const exitBtnClick = () => {
        setIsModal(true);
    }
    const modalClose = () => {
        setIsModal(false);
    }
    const saveExitClick = () => {
        setIsModal(false);
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        if (answerShortName == "") {
            setIsEmptyAnswers(true);
            return false;
        } else {
            savePreferences = {
                "questionShortName": questionShortName,
                "answerShortName": answerShortName.toString()
            }
        }
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigate("/myprofile");
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    let answerShortNameArr = [];
    const checkedAnswers = (e) => {
        onBoardingQuestions.map((questions) => {
            let options = questions.options.map((options) => {
                if (options.id == e.target.id) {
                    if (e.target.checked) {
                        answerShortNameArr.push(e.target.name);
                        setQuestionShortName(questions.questionShortName);
                        setAnswerShortName(answerShortNameArr);
                    } else {
                        const unchecked = answerShortNameArr.indexOf(e.target.name);
                        if (unchecked > -1) {
                            answerShortNameArr.splice(unchecked, 1);
                        }
                        setAnswerShortName(answerShortNameArr);
                    }
                }
            })
        })
    }
    const [onBoardingQuestions, setOnBoardingQuestions] = useState([
        {
            "desc": "What are sectors interested?",
            "questionShortName": "sector",
            "isoptional": true,
            "multiselect": true,
            "enablesearch": true,
            "options": [
                {
                    id: "Finance ID",
                    label: "Finance Label",
                    name: "Finance Name",
                    value: 'finance Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Manufacturing ID",
                    label: "Manufacturing Label",
                    name: "Manufacturing Name",
                    value: 'Manufacturing Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Banking ID",
                    label: "Banking Label",
                    name: "Banking Name",
                    value: 'Banking Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Automation ID",
                    label: "Automation Label",
                    name: "Automation Name",
                    value: 'Automation Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                }
            ]
        },
        {
            "desc": "What best describe you?",
            "questionShortName": "describe",
            "isoptional": false,
            "multiselect": true,
            "enablesearch": false,
            "options": [
                {
                    id: "Reason_Job",
                    label: "Find a new job quickly",
                    name: "Reason_Job",
                    value: 'newjob',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Career",
                    label: "Progress my career",
                    name: "Reason_Career",
                    value: 'career',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Retire",
                    label: "Help with retirement",
                    name: "Reason_Retire",
                    value: 'retire',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Business",
                    label: "Start my own business",
                    name: "Reason_Business",
                    value: 'business',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
            ]
        },
        {
            "desc": "What is your level of experience?",
            "questionShortName": "experience",
            "isoptional": false,
            "multiselect": false,
            "enablesearch": false,
            "options": [
                {
                    id: '2_5_years',
                    labelText: '2-5 years',
                    name: 'expYears',
                    value: '2_5_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '5_10_years',
                    labelText: '5-10 years',
                    name: 'expYears',
                    value: '5_10_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '10_15_years',
                    labelText: '10-15 years',
                    name: 'expYears',
                    value: '10_15_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '15_20_years',
                    labelText: '15-20 years',
                    name: 'expYears',
                    value: '15_20_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                }
            ]
        }
    ]);
    const nextHandler = async (e) => {
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        if (answerShortName == "") {
            setIsEmptyAnswers(true);
            return false;
        } else {
            savePreferences = {
                "questionShortName": questionShortName,
                "answerShortName": answerShortName.toString()
            }
        }
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigateTo();
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    const skipHandler = async (e) => {
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        onBoardingQuestions.map((questions) => {
            if (questions.enablesearch) {
                savePreferences = {
                    "questionShortName": questions.questionShortName,
                    "answerShortName": ""
                }
            }
        })
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigateTo();
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    const onFormSubmit = (event, submittedQuestionsId) => {
        event.preventDefault();
        return true;
    }
    const backHandler = (event, submittedQuestionsId) => {
        event.preventDefault();
    }
    return (
        <>
            <Cirrus.FullHeightContainerLayout
                ref={heightRef}
                footerHeight={0}
                headerHeight={0}
            >
                <Cirrus.QuestionsMultiStepForm
                    continueButtonProps={{
                        children: 'Next',
                        size: Cirrus.ButtonSizes.Large,
                        type: 'submit'
                    }}
                    onSubmit={onFormSubmit}
                >
                    {onBoardingQuestions.map((questions, index) => {
                        return (
                            <Cirrus.QuestionsMultiStepForm.Step
                                title={questions.desc}
                                stepId={questions.questionShortName}
                                key={index}
                            >
                                <Cirrus.Container key={index}
                                    ref={fixedHeightRef}
                                    exitButton={{
                                        children: 'Exit',
                                        isSecondary: true,
                                        onClick: exitBtnClick,
                                        size: 'Small',
                                        type: 'button'
                                    }}
                                    heading={questions.desc}
                                    primaryButton={{
                                        children: 'Next',
                                        size: 'Large',
                                        type: 'submit'
                                    }}
                                    secondaryButton={{
                                        children: 'Skip',
                                        isSecondary: true,
                                        size: 'Large',
                                        type: 'button',
                                        disabled: true
                                    }}
                                    subheading="Please select all that apply"
                                >
                                    <Cirrus.Button children="back" onClick={backHandler} type="submit" />
                                    {questions.multiselect === true && questions.enablesearch === true &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={questions.options}

                                        />
                                    }
                                    {questions.multiselect === true && questions.enablesearch === false &&
                                        <Cirrus.MultipleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={questions.options}
                                        />
                                    }
                                    {questions.multiselect === false && questions.enablesearch === false &&
                                        <Cirrus.SingleSelectQuestion
                                            heightReference={heightRef}
                                            fixedHeightReference={fixedHeightRef}
                                            options={questions.options}
                                        />
                                    }
                                    {isModal &&
                                        <Cirrus.AlertModal
                                            closeIconButtonProps={{
                                                icon: 'close',
                                                isSecondary: true,
                                                text: 'Cancel',
                                                type: 'button'
                                            }}
                                            heading="Exit Page"
                                            primaryButton={{
                                                children: 'Save and exit',
                                                size: 'Large',
                                                type: 'button'
                                            }}
                                            role="alertdialog"
                                            subheading="Are you sure you want to leave building your profile? we will save your answers and You can always complete or edit your answers later on in your profile section."
                                        />}
                                    {isEmptyAnswers &&
                                        <Cirrus.ErrorMessage>
                                            This is a required question to build your profile
                                        </Cirrus.ErrorMessage>
                                    }
                                </Cirrus.Container>

                            </Cirrus.QuestionsMultiStepForm.Step>
                        )
                    })
                    }
                </Cirrus.QuestionsMultiStepForm>

            </Cirrus.FullHeightContainerLayout>
        </>
    )
}

export default MultiSelect;
