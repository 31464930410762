//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const DiscoverActions = {
    GetDiscoverData:getDiscoverData,
	SaveAssessmentCard: saveAssessmentCard,
    GetAssessmentStatusAndSavedResponseById: getAssessmentStatusAndSavedResponseById,
    GetIviewToolContent: getIviewToolContent,
    SaveIviewToolCollection: saveIviewToolCollection,
    GetDiscoverCMSData: getDiscoverCMSData, 
    UpdateStatus: updateStatus,
    GetDoceboLink: getDoceboLink,
    Get_30_secondCommercialCMS: get_30_secondCommercialCMS,
    GetResmeBuilderUrl: getResmeBuilderUrl,
    GetFeaturedetails:getFeaturedetails,
    ExternalToolsRedirectURL: externalToolsRedirectURL
}

/*function getDiscoverData(resourceType, recommended) {
    return http.post(environments.ASSESSMENT_CARD_DATA, resourceType, recommended);
}*/

function getDiscoverCMSData(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function getDiscoverData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function getDoceboLink() {
    return http.GetDoceboUrl(environments.GET_DOCEBO_LINK);
}
function saveAssessmentCard(resourceType, id, payload) {
    let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
    return http.learningResourcePut(url, payload);
}

function getAssessmentStatusAndSavedResponseById(assessmentId) {
    return http.AssessmentsGet(environments.GET_ASSESSMENT_AND_RESPONSES_BY_ASSESSMENT_ID.replace('{id}', assessmentId));
}

function getFeaturedetails(){
    return http.AssessmentsGet(environments.GET_Featuredetails)
}

function getIviewToolContent(payload) {
    return http.IviewVideoGet(environments.IVIEW_ON_DEMAND_VIDEO_INTERVIEW, payload);
}

function saveIviewToolCollection(payload) {
    return http.IviewVideoPost(environments.SAVE_IVIEW_COLLECTION, payload);
}

function updateStatus(resourceType, id, payload) {
    return http.AssessmentsPut(environments.SAVE_ASSESSMENT_CARD.replace('{resourcetype}', resourceType).replace('{id}', id), payload);
}

function get_30_secondCommercialCMS(payload) {
    return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function getResmeBuilderUrl() {
    return http.GetResumeBuilderUrl(environments.GET_RESUME_BUILDER_URL);
}

function externalToolsRedirectURL(id) {
    return http.AssessmentsGet(environments.GET_EXTERNAL_TOOLS_REDIRECT_URL.replace("{toolId}", id));
}
