//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from '../../header/headerWithNavigation';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from '../../../common/utility/LogAction';

const VAndDassessment = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [percentage, setPercentage] = useState(0);
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
    const [assessmentStatus, setAssessmentStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const appInsights = useAppInsightsContext()

    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setViewPortWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
        TrackActions.PageViewRecord(appInsights, "ValuesAndDriversAssessment")
        TrackActions.PageAction("ValuesAndDriversAssessment")
    }, [cmsAssessmentContent, context.language])

    useEffect(() => {
        getAssessmentStatusWithAnswers();
    }, [])

    const fetchCMSData = () => {
        let resData = context.cmsValuesAndDriversContent;
        setCmsAssessmentContent(resData);
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const getAssessmentStatusWithAnswers = async () => {
        await AssessmentActions.GetAssessmentStatusAndSavedResponseById(context.cmsValuesAndDriversContent.internalAssessmentId).then(res => {
            if (res.status === 200) {
                setAssessmentStatus(res.data);
                setIsSaved(res.data.isSaved);
                setIsLiked(res.data.isLiked);
                if (res.data.learningResourceAssessmentResponseModels?.length) {
                    context.cmsValuesAndDriversContent.assessmentQuestionSet.map(assessment => {
                        res.data.learningResourceAssessmentResponseModels.map(answered => {
                            if (assessment.ID === answered.questionId) {
                                let elements = document.querySelectorAll('input[questionid="' + answered.questionId + '"][answerid="' + answered.answerValue + '"]');
                                elements.forEach(ele => {
                                    ele.setAttribute("defaultChecked", "true")
                                    ele.parentNode.classList.add('active')
                                })
                            }
                        })
                    })
                }
                let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
                if (res.data.lastAnsweredPageId) {
                    if (parseInt(res.data.lastAnsweredPageId) < assessmentQuestion.length) {
                        setCurrentQuestion(currentQuestion + parseInt(res.data.lastAnsweredPageId));
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId)].style.display = "flex";
                        let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / assessmentQuestion.length) * 100);
                        setPercentage(percentageCalc);
                    } else {
                        setCurrentQuestion(parseInt(res.data.lastAnsweredPageId) - 1);
                        assessmentQuestion[parseInt(res.data.lastAnsweredPageId) - 1].style.display = "flex";
                        let percentageCalc = Math.ceil((parseInt(res.data.lastAnsweredPageId) / assessmentQuestion.length) * 100);
                        setPercentage(percentageCalc);
                    }
                } else {
                    assessmentQuestion[currentQuestion].style.display = "flex";
                }
            }
        }, err => {
            console.log(err);
        })
    }

    const showQuestion = (n) => {
        let assessmentQuestion = document.getElementsByClassName('assessment-question-wrapper');
        if (n < assessmentQuestion.length) {
            assessmentQuestion[n].style.display = "flex";
        }
        if (n == assessmentQuestion.length) {
            if (viewPortWidth <= 992) {
                navigate("/" + pageOrgin.id + "/values-and-drivers/review-mobile-view")
            } else {
                navigate("/" + pageOrgin.id + "/values-and-drivers/review")
            }
        }
    }

    const nextPrev = (e, n) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        if (n > 0) {
            setLoadSpinner(true);
            let payload = [{
                "AssessementId": e.target.attributes.assessmentid.value,
                "AssessementType": "Internal",
                "CategoryType": null,
                "CategoryValue": null,
                "QuestionId": e.target.attributes.questionid.value,
                "AnswerType": "id",
                "AnswerValue": e.target.attributes.answerid.value
            }]
            AssessmentActions.SaveAssessment(payload).then(res => {
                if (res.status === 200) {
                    let payload = {
                        "candidateId": candidateID,
                        "learningResourceId": assessmentStatus.learningResourceId,
                        "learningResourceType": cmsAssessmentContent.resourceCategoryType.ID,
                        "resourceTypeId": cmsAssessmentContent.resourceType.ID,
                        "learningResourceStatus": assessmentStatus.learningResourceStatus,
                        "isSaved": isSaved,
                        "isLiked": isLiked,
                        "lastAnsweredPageId": currentQuestion + n,
                        "isDownloaded": assessmentStatus?.isDownloaded,
                        "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
                        "resultDocumentId": assessmentStatus?.resultDocumentId
                    }
                    AssessmentActions.SaveAssessmentStatus(cmsAssessmentContent.resourceCategoryType.ID, assessmentStatus.learningResourceId, payload).then((res) => {
                        if (res.status === 200) {
                            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");

                            let percentageCalc = Math.ceil(((currentQuestion + n) / assessmentQuestion.length) * 100);
                            if (percentageCalc >= percentage) {
                                setPercentage(percentageCalc);
                            }

                            assessmentQuestion[currentQuestion].style.display = "none";
                            setCurrentQuestion(currentQuestion + n);
                            if (currentQuestion < 0) {
                                setCurrentQuestion(0);
                            }
                            showQuestion(currentQuestion + n);
                            setLoadSpinner(false);
                        } else {
                            setLoadSpinner(false);
                        }
                    }, err => {
                        console.log(err);
                        setLoadSpinner(false);
                    })
                } else {
                    setLoadSpinner(false);
                }
            }, err => {
                console.log(err);
                setLoadSpinner(false);
            })
        } else {
            let assessmentQuestion = document.getElementsByClassName("assessment-question-wrapper");
            assessmentQuestion[currentQuestion].style.display = "none";
            if (currentQuestion + n < 0) {
                setCurrentQuestion(0);
                showQuestion(0);
            } else {
                setPercentage(percentage);
                setCurrentQuestion(currentQuestion + n);
                showQuestion(currentQuestion + n);
            }
        }
    }

    const removePreviousSelection = (e) => {
        let options = e.target.closest('.options').childNodes;
        options.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove("active")
            }
        });
    }

    return (
        <div className="assessment-page vd_assesment-qpage">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pt-2 pb-4'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        {
                            currentQuestion == 0 ?
                                <Cirrus.Button isSecondary
                                    onClick={(e) => navigate("/" + pageOrgin.id + "/values-and-drivers")}
                                >
                                    {fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                :
                                <Cirrus.Button isSecondary onClick={(e) => { nextPrev(e, -1) }}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        }
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                        <div className='progress-wrapper'>
                            <Cirrus.Label className='progress-label'>{fetchCommonResourceLabel("CommonResourceComponent.Progress")} {percentage}%</Cirrus.Label>
                            <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                        </div>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                        <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonResourceLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {
                cmsAssessmentContent?.assessmentQuestionSet?.map((assessment, idx) => {
                    return (
                        <Container className='assessment-question-wrapper mb-5' key={idx}>
                            {
                                loadSpinner &&
                                <div className='loader'>
                                    <Cirrus.Spinner size={50} />
                                </div>
                            }
                            <Row className='vd_assesment-questions'>
                                <Col lg={12} className="assessment-question">
                                    <Cirrus.Headline size='Small' className='mb-2'>
                                        {/* <small style={{ fontSize: '12px' }}>For unit testing: Q{idx + 1} (will be removed later)<br /></small> */}
                                        {assessment?.Title}
                                    </Cirrus.Headline>
                                    <div className="mb-4 mb-lg-3 d-block">
                                        <Cirrus.Label>
                                            {assessment?.Description}
                                        </Cirrus.Label>
                                    </div>
                                    <div className='options'>
                                        {
                                            cmsAssessmentContent?.commonAnswer?.map((option, index) => {
                                                return (
                                                    <label key={index} className="custom-radio-button">
                                                        <input type="radio"
                                                            name={assessment.Key}
                                                            value={option.AnswerText}
                                                            onClick={(e) => nextPrev(e, 1)}
                                                            assessmentid={cmsAssessmentContent.internalAssessmentId}
                                                            questionid={assessment.ID}
                                                            answerid={option.AnswerId}
                                                            onChange={(e) => {
                                                                setCurrentQuestion(idx + 1);
                                                                removePreviousSelection(e)
                                                                if (e.target.checked) {
                                                                    e.target.parentNode.classList.add("active");
                                                                } else {
                                                                    e.target.parentNode.classList.remove("active");
                                                                }
                                                            }}
                                                        />
                                                        {option.AnswerText}
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )
                })
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default VAndDassessment;
