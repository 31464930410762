//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { iViewActions } from ".././iViewActions";
import "bootstrap-icons/font/bootstrap-icons.css";
import Icon from "../../../assets/fonts/icomoon/Icon";
import "./IviewCollectionPage.css";
import sideimage from "../sideimage.png";
import IviewInterviewAssesmentCard from "../IviewInterviewAssesmentCard/IviewInterviewAssesmentCard";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function IviewCollectionPage() {
  const navigate = useNavigate();
  const context = useContext(Context);
  const [cmsContent, setCmsContent] = useState({});
  const [apiContent, setApiContent] = useState();
  const [screenLoader, setScreenLoader] = useState(false);
  const [label, setLabels] = useState([]);
  const appInsights = useAppInsightsContext()
  let { id } = useParams();

  const iviewWelcomePageContent = async () => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let payload = {
      PageName: "OnDemandVideoInterview"
    };
    await iViewActions.GetIviewContent(payload).then((res) => {
      if (res.status === 200) {
        let collectionData = res?.data?.resourcePages.filter((data) => data.id == id)[0];
        ActivityAction.SaveActivity(collectionData?.id, "Tool", collectionData?.title)
        setApiContent(collectionData);
        setLabels(res?.data?.components)
        let resData = res.data;
        let arrVideoCollectionCards = [];
        refCmsContent.Title = resData.components["iView.VideoCollections"]?.Title;
        refCmsContent.Description = resData.components["iView.VideoCollections"]?.Description;
        refCmsContent.ShortDescription = resData.shortDescription;
        refCmsContent.PersonalBranding = resData.focusArea[0].value;
        refCmsContent.ResourceType = resData.resourceType.value;
        refCmsContent.asessmentCount = resData.asessmentCount;
        refCmsContent.bannerImage = resData.largeImage.imageUrl;
        refCmsContent.iviewId = resData.id;
        refCmsContent.iviewStatus = resData.issaved;

        refCmsContent.videoCollection = resData.components["iView.VideoCollections"]?.LOVList[0].Values;
        resData.resourcePages.map((item) => {
          let objResourceCard = {};
          objResourceCard["Title"] = item.title;
          objResourceCard["ShortDescription"] = item.shortDescription;
          objResourceCard["AssessmentType"] = item.assessmentType.value;
          // objResourceCard['asessmentCount'] = item.asessmentCount;
          let arrCardCollection = [];
          item.resourcePages.map((card) => {
            let arrFocusArea = [];
            let objCardCollection = {
              Title: card.title,
              ShortDescription: card.shortDescription,
              ResourceType: card.resourceType.value,
              LearningResourceStatus: card?.status ? card?.status : "notstarted",
              Duration: card.duration,
              issaved: card.issaved,
              isLiked: card.isLiked,
              totalLikedCount: card.totalLikedCount,
              id: card.id,
              assessmentType: card.assessmentType.value,
            };
            card.focusArea.map((focus) => {
              arrFocusArea.push(focus.value);
            });
            objCardCollection["FocusArea"] = arrFocusArea;
            arrCardCollection.push(objCardCollection);
          });
          objResourceCard["resourcePages"] = arrCardCollection;
          arrVideoCollectionCards.push(objResourceCard);
        });
        refCmsContent.ResourceCardList = arrVideoCollectionCards;
        console.log(refCmsContent.videoCollection)
        refCmsContent.assessmentType = resData.assessmentType.value;
        setCmsContent(refCmsContent);
      }
    }, (err) => {
      console.log(err);
    });
  };



  const saveAssessmentCard = (e, id, savedStatus, likedStatus, status, type, externalId) => {
    e.preventDefault();
    let countryCode = JSON.parse(sessionStorage.getItem("setProgramDetails"))['programLanguageCode'];
    const url = window.location.href;
    const env = url.split("#");

    const pay = {
      LearningResourceId: id,
      LearningResourceStatus: "notstarted",
      CallbackURL: `${env[0]
        }#/iview-interview-done/${externalId}/${id}/${sessionStorage.getItem(
          "AccessToken"
        )}`,
      Languagecode: countryCode,
    };
    iViewActions.GetIviewInterview(pay, externalId)
      .then((res) => {
        let response = res?.data
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
          candidateId: candidateID,
          LearningResourceId: id,
          LearningResourceType: "iView",
          LearningResourceStatus: status ? status : "notstarted",
          IsDownloaded: false,
          IsSharedWithCoach: false,
          status: status ? status : "notstarted",
          isSaved:
            type === "save" ? (savedStatus == true ? false : true) : savedStatus,
          isLiked:
            type === "like" ? (likedStatus == true ? false : true) : likedStatus,
          InterviewId: response?.interviewId,
          InterviewType: "Video",
          IsRetaken: false,
          RedirectUrl: response?.interviewUrl,
          RequisitionId: externalId,
          Languagecode: countryCode,
        };

        iViewActions.SaveIviewAssessmentCard(id, payload).then(
          (res) => {
            if (res.status === 200) {
              let resData = res.data;
              iviewWelcomePageContent();
              // setSaved(true)
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
  };

  useEffect(async () => {
    iviewWelcomePageContent();
    window.scrollTo(0, 0);
    TrackActions.PageViewRecord(appInsights, "IviewCollections")
    TrackActions.PageAction("IviewCollections")
  }, [context.language]);

  const fetchLabel = (key) => {
    let labelData = label?.commonComponent?.Labels;
    let labels = "";
    if (labelData) {
      labelData?.map((x) => {
        if (x.Key === key) {
          labels = x.Phrase;
        }
      });
    }
    return labels;
  }

  const fetchResourceLabel = (key) => {
    let labelData = label?.commonResourceComponent?.Labels;
    let labels = "";
    if (labelData) {
      labelData?.map((x) => {
        if (x.Key === key) {
          labels = x.Phrase;
        }
      });
    }
    return labels;
  }


  if (screenLoader) {
    return (
      <div>
        <Spinner spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="iview-welcome">
      <Container fluid className="background-White p-3 pt-4 pb-0 p-md-5 pb-md-0">
        <Row>
          <Col md="12" lg="7" className="mb-3 pb-4">
            <Cirrus.Button
              isSecondary
              onClick={() => navigate("/iview-welcome")}
              className="mb-4"
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
            <Cirrus.Header className="iView-collection-header" size="h3">
              {apiContent?.browserTitle}
            </Cirrus.Header>
            <div className="iview-assesment-heading">
              <Cirrus.Label id="cardtype">
                <p className="iview-assesment-heading">
                  {apiContent?.assessmentType?.value} <sup>.</sup>{" "}
                  {apiContent?.asessmentCount?.toUpperCase()}
                </p>
              </Cirrus.Label>
              <Cirrus.Label id="cardtype">
                {apiContent?.shortDescription}
              </Cirrus.Label>
            </div>
            <div className="d-flex align-items-center mt-3 mb-3 iView-collection-focusArea">
              <Cirrus.Label id="category-label">
                {apiContent?.focusArea[0]?.value}
              </Cirrus.Label>
              <Cirrus.Label id="cardtype">
                {cmsContent && cmsContent?.ResourceType} <sup> . </sup>
                <span className="iview-assesment-count">
                  {(apiContent?.asessmentCount || 0)}
                </span>
              </Cirrus.Label>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={apiContent?.resourcePages?.length > 0 ? "p-3 pt-5 pb-0 p-md-5 pb-md-0" : "p-5"}>
        <h2 className="iview-interview-heading">
          {/* iView Interview Assesments */}
          {fetchResourceLabel("CommonResourceComponent.IViewCollectionPageHeading")}
        </h2>
      </Container>
      <Container fluid className="iview-assesments-card p-3 pt-3 p-md-5 pt-md-4 ">
        <Row>
          <div style={{ display: 'flex', flexDirection: "row", flexWrap: 'wrap', gap: "10px" }}>
            {apiContent?.resourcePages &&
              apiContent?.resourcePages?.map((data) => (
                // <Col md="6" lg="3">
                <IviewInterviewAssesmentCard
                  Title={data?.browserTitle}
                  SubTitle={cmsContent?.assessmentType}
                  ShortDescription={data?.shortDescription}
                  focusArea={data?.focusArea}
                  ResourceType={data?.resourceType?.value}
                  Duration={data?.duration}
                  LearningResourceStatus={data.status === "Completed" ? fetchResourceLabel("CommonResourceComponent.Completed") : data.status }
                  totalLikedCount={data?.totalLikedCount}
                  isLiked={data?.isLiked}
                  issaved={data?.issaved}
                  id={data?.id}
                  externalId={data?.externalId}
                  saveAssessmentCard={saveAssessmentCard}
                />

                // </Col>
              ))}
          </div>
        </Row>
      </Container>
      <Container fluid className="background-Black other-iView-collection p-3 py-4 p-md-5">
        <div className="other-iView-video" style={{ width: "50%" }}>
          <Cirrus.Header size="h5">
            {/* iView.VideoCollections - Other interview practice options */}
            {cmsContent.Title}
          </Cirrus.Header>
          <Cirrus.Label>
            {/* iView.VideoCollections - Select from our collection of common interview topics and question sets. */}
            {cmsContent.Description}
          </Cirrus.Label>
          <Row className="videoCollectionListRow">
            <Col md="12" lg="12">
              <ul className="videocollectionList twoCloumn">
                {cmsContent &&
                  cmsContent?.videoCollection?.map((x, index) => {
                    return (
                      <li
                        key={index}
                        className="iview-view-collection-subheading"
                        data-link={`/iview-collecion-page/${x?.ID}`}
                        onClick={() => {
                          navigate(`/iview-collecion-page/${x?.ID}`)
                          setTimeout(() => {
                            iviewWelcomePageContent()
                            window.scrollTo(0, 0)
                          }, 500);
                        }}
                      // onClick={() => { window.location.href = `/iview-collecion-page/${x?.ID}` }}
                      >
                        {/* <Icon icon="arrow_right" size={16} color="#1456FF" /> */}
                        <i className="icon-arrow_forward"></i>
                        <span
                          className="iview-library-item"
                          id="iview-library-items"
                        >
                          {x.Value}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
        </div>
        <div className="iView-collection-img" style={{ margin: "auto" }}>
          <Cirrus.Image src={sideimage} isRounded className="img-fluid w-100"></Cirrus.Image>
        </div>
      </Container>
      {/* </Cirrus.Page>
      <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}

export default IviewCollectionPage;
