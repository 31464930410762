//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import { Row, Col, Container } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import { Context } from "../../../common/context/Context";
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import sideimage from "./sideimage.png";
import { AssessmentActions } from "../AssessmentActions";
import "../style.css";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import { coachActions } from "../../coach/coachActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function StrengthAnalysis() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState();
  const [cmsSA, setCmsSA] = useState({});
  const [assessmentStatus, setAssessmentStatus] = useState({
    Parrent: {},
    Skills: {},
    Interests: {},
  });
  const [assessmentIds, setAssessmentIds] = useState([]);
  let path = JSON.parse(window.sessionStorage.getItem("setPath"));
  const [matrixFlag, setMatrixFlag] = useState(false);
  const [matrix, setMatrix] = useState({
    keyStrengths: [],
    potentialToExcel: [],
    newEnergy: [],
    potentialStressors: [],
    lowRelevance: [],
  });
  const [coachdata, setcoachdata] = useState();

  let content = document.getElementById("assessment-result-wrapper");

  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (/Chrome/.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browser = "Safari";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Microsoft Edge";
  } else {
    browser = "Unknown Browser";
  }

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  useEffect(() => {
    fetchCMSData();
    getMyCoach();
    window.scrollTo(0, 0);
  }, [context.language]);

  const fetchCMSData = async () => {
    setScreenLoader(true);
    let anotherApiCallFlag = false;
    let assIds = [];
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let payload = {
      PageName: "StrengthAnalysis",
    };
    await AssessmentActions.GetAssessmentCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          ActivityAction.SaveActivity(
            resData?.ID,
            "startassessment",
            resData?.Title
          );
          TrackActions.EventTrack(appInsights, "ViewCard", {
            "Entity Id": resData?.ID,
            "Entity Name": resData?.Title,
            CardType: "Assessment",
          });
          TrackActions.PageAction("ViewCard", {
            "Entity Id": resData?.ID,
            "Entity Name": resData?.Title,
            CardType: "Assessment",
          });
          refCmsSA["id"] = resData?.ID;
          refCmsSA["externalId"] = resData?.ExternalId;
          refCmsSA["title"] = resData?.Title;
          refCmsSA["shortDescription"] = resData?.ShortDescription;
          refCmsSA["description"] = resData?.Description;
          refCmsSA["focusArea"] = resData?.FocusArea[0];
          refCmsSA["resourceType"] = resData?.ResourceType;
          refCmsSA["resourceCategoryType"] = resData?.ResourceCategoryType;
          refCmsSA["duration"] = resData?.Duration;
          refCmsSA["largeImage"] = resData?.LargeImage;
          refCmsSA["mediumImage"] = resData?.MediumImage;
          refCmsSA["smallImage"] = resData?.SmallImage;

          refCmsSA["skillsMatrixComponent"] = {
            status: true,
            matrixCMS:
              resData?.Components[
                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent"
              ],
          };

          assIds.push({
            name: "Parrent",
            id: resData?.ID,
          });
          assessmentEntryInDb(resData?.ID, {
            LearningResourceType: resData?.ResourceCategoryType?.ID,
            ResourceTypeId: resData?.ResourceType?.Value,
          });

          resData?.ResourcePages?.map((assCard) => {
            if (assCard.Key === "Assessments.StrengthAnalysis.Interests") {
              let refactorQuestions = {
                ID: assCard.ID,
                SubTitle: assCard.SubTitle,
                ResourceCardTitle: assCard.ResourceCardTitle,
                Title: assCard.Title,
                ShortDescription: assCard.ShortDescription,
                Description: assCard.Description,
                FocusArea: assCard.FocusArea,
                ResourceCategoryType: assCard.ResourceCategoryType,
                ResourceType: assCard.ResourceType,
                Duration: assCard.Duration,
                Question: [],
                questionLabel:
                  assCard?.Components[
                    "Resources.Assessments.StrengthAnalysis.CommonAnswersForIdentifyingYourInterests"
                  ].CommonAnswerList,
                subQuestionLabel:
                  assCard?.Components[
                    "Resources.Assessments.StrengthAnalysis.CommonAnswersForRefiningYourInterests"
                  ]?.CommonAnswerList,
              };
              assCard?.ResourcePages?.map((resoPage) => {
                let mainQuestions = [];
                resoPage?.ChildPages?.map((interestedSet) => {
                  let filterSecondSetComponent =
                    interestedSet?.Components[
                      Object.keys(interestedSet?.Components)[0]
                    ];
                  let subQuestions = [];
                  filterSecondSetComponent?.QuestionList.map(
                    (refineQuestion) => {
                      subQuestions.push({
                        QuestionTitle: refineQuestion.QuestionTitle,
                        QuestionId: refineQuestion.QuestionId,
                        RefinedAnswer: 0,
                      });
                    }
                  );
                  mainQuestions.push({
                    Title: interestedSet.Title,
                    Description: interestedSet.Description,
                    ID: interestedSet.ID,
                    Skills: interestedSet.Skills,
                    InterestedPercentage: 0,
                    IsSelected: "",
                    SubQuestion: subQuestions,
                  });
                });
                refactorQuestions.Question.push(mainQuestions);
              });
              refCmsSA["assessmentInterests"] = refactorQuestions;

              assIds.push({
                name: assCard.ResourceName,
                id: assCard.ID,
              });

              let inRE = assCard.ChildPages.filter(
                (resPage) =>
                  resPage.Key ===
                  "Assessments.StrengthAnalysis.Interests.InterestsResultsPage"
              );
              refCmsSA["interestsResultsPage"] = inRE[0];
            }
            if (assCard.Key === "Assessments.StrengthAnalysis.Skills") {
              let refactorQuestions = {
                ID: assCard.ID,
                SubTitle: assCard.SubTitle,
                ResourceCardTitle: assCard.ResourceCardTitle,
                Title: assCard.Title,
                ShortDescription: assCard.ShortDescription,
                Description: assCard.Description,
                FocusArea: assCard.FocusArea,
                ResourceCategoryType: assCard.ResourceCategoryType,
                ResourceType: assCard.ResourceType,
                Duration: assCard.Duration,
                Question: [],
                questionLabel:
                  assCard?.Components[
                    "Resources.Assessments.StrengthAnalysis.CommonAnswersForIdentifyingYourSkills"
                  ]?.CommonAnswerList,
              };
              assCard?.ResourcePages?.map((resoPage) => {
                let mainQuestions = [];
                resoPage?.ChildPages?.map((refineSet) => {
                  mainQuestions.push({
                    Title: refineSet.Title,
                    Description: refineSet.Description,
                    ID: refineSet.ID,
                    Skills: refineSet.Skills,
                    SkillsPercentage: 0,
                    RefinedAnswer: 0,
                  });
                });
                refactorQuestions.Question.push(mainQuestions);
              });
              refCmsSA["assessmentSkills"] = refactorQuestions;

              assIds.push({
                name: assCard.ResourceName,
                id: assCard.ID,
              });
              let skRE = assCard.ChildPages.filter(
                (resPage) =>
                  resPage.Key ===
                  "Assessments.StrengthAnalysis.Skills.SkillsResultsPage"
              );
              refCmsSA["skillsResultsPage"] = skRE[0];
            }
          });

          let label = [...resData?.Components?.CommonComponent?.Labels];
          resData?.Components?.CommonComponent?.LOVList[0]?.Values?.map((x) => {
            let obj = {
              Key: x.Key,
              Phrase: x.Value,
            };
            label.push(obj);
          });
          resData?.Components[
            "Resources.Assessments.StrengthAnalysis.StrengthAnlaysisComponent"
          ]?.RichTexts?.map((x) => {
            let obj = {
              Key: x.Key,
              Phrase: x.Value,
            };
            label.push(obj);
          });

          label = [
            ...label,
            ...resData?.Components?.CommonResourceComponent?.Labels,
            ...resData?.Components[
              "Resources.Assessments.StrengthAnalysis.StrengthAnlaysisComponent"
            ]?.Labels,
            ...resData?.Components[
              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent"
            ]?.Labels,
          ];
          refCmsSA["masterLabel"] = label;

          refCmsSA["assIds"] = assIds;
          setCmsSA(refCmsSA);
          setAssessmentIds(assIds);
          context.setCmsStrengthAnalysis({
            masterCMS: resData,
            masterLabel: label,
            assIds: assIds,
            assessmentInterests: refCmsSA["assessmentInterests"],
            interestsResultsPage: refCmsSA["interestsResultsPage"],
            assessmentSkills: refCmsSA["assessmentSkills"],
            skillsResultsPage: refCmsSA["skillsResultsPage"],
          });
          anotherApiCallFlag = true;
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );

    if (anotherApiCallFlag) {
      let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
      await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(
        refCmsSA.id
      ).then(
        (res) => {
          if (res.status === 200) {
            let parent = res?.data.filter(
              (status) => status.subLearningResourceId === undefined
            );
            refAssessmentStatus.Parrent = parent[0];
            assIds.map((x) => {
              res?.data.map((status) => {
                if (x.id === status.subLearningResourceId) {
                  refAssessmentStatus[`${x.name}`] = status;
                }
              });
            });
            setAssessmentStatus(refAssessmentStatus);
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );
      if (
        refAssessmentStatus?.Interests?.subLearningResourceStatus ===
          "completed" &&
        refAssessmentStatus?.Skills?.subLearningResourceStatus === "completed"
      ) {
        setMatrixFlag(true);
      } else {
        setScreenLoader(false);
      }
    }
  };

  useEffect(() => {
    if (matrixFlag) {
      skillsMatrix();
    }
  }, [matrixFlag]);

  const skillsMatrix = async () => {
    let refMatrix = JSON.parse(JSON.stringify(matrix));
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));

    if (Object.keys(refCmsSA).length !== 0) {
      let modifiedSkillsSet = [];
      refCmsSA?.assessmentSkills?.Question.map((resourcePages) => {
        resourcePages?.map((refineQuestion) => {
          modifiedSkillsSet.push(refineQuestion);
        });
      });

      let interestedCombine = [];
      let filterInterested = [];
      let filterNotInterested = [];
      let modifiedInterestedSet = [];
      refCmsSA?.assessmentInterests?.Question?.map((resourcePages) => {
        resourcePages?.map((interestedSet) => {
          modifiedInterestedSet.push(interestedSet);
        });
      });

      await AssessmentActions.GetStrengthsAnalysisIdentifyYourInterest(
        refCmsSA.id
      ).then(
        (resp) => {
          if (resp.status === 200) {
            let interestedResp = resp?.data?.filter(
              (interestAns) => interestAns.isInterested === true
            );
            if (interestedResp.length > 0) {
              modifiedInterestedSet.map((interestedSet) => {
                resp?.data?.map((interestAns) => {
                  if (interestAns.categoryId === interestedSet.Skills.ID) {
                    interestedSet.IsSelected = interestAns.isInterested;
                  }
                });
                return interestedSet;
              });
              filterInterested = modifiedInterestedSet.filter(
                (interestedSet) => interestedSet.IsSelected === true
              );
              filterNotInterested = modifiedInterestedSet.filter(
                (notInterestedSet) => notInterestedSet.IsSelected === false
              );
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );

      await AssessmentActions.StrengthsAnalysisSkillsMatrix(refCmsSA.id).then(
        (res) => {
          if (res.status === 200) {
            let skills = res.data.filter(
              (item) => item.subAssessmentId === refCmsSA?.assessmentSkills?.ID
            );
            let interest = res.data.filter(
              (item) =>
                item.subAssessmentId === refCmsSA?.assessmentInterests?.ID
            );

            modifiedSkillsSet?.map((refineQuestion) => {
              skills[0]?.response?.map((refineAns) => {
                if (refineAns.questionId === refineQuestion.Skills.ID) {
                  if (parseInt(refineAns.answerValue) === 1) {
                    refineQuestion.RefinedAnswer =
                      parseInt(refineAns.answerValue) - 1;
                  } else {
                    refineQuestion.RefinedAnswer = parseInt(
                      refineAns.answerValue
                    );
                  }
                }
              });
              return refineQuestion;
            });

            filterInterested?.map((interestedSet) => {
              interest[0]?.response?.map((refineAns) => {
                interestedSet?.SubQuestion?.map((subQuestion) => {
                  if (refineAns.questionId === subQuestion.QuestionId) {
                    if (parseInt(refineAns.answerValue) === 1) {
                      subQuestion.RefinedAnswer =
                        parseInt(refineAns.answerValue) - 1;
                    } else {
                      subQuestion.RefinedAnswer = parseInt(
                        refineAns.answerValue
                      );
                    }
                  }
                  return subQuestion;
                });
              });
              return interestedSet;
            });

            filterInterested?.map((interestedSet) => {
              let arr = [];
              interestedSet?.SubQuestion?.map((refineQuestion) => {
                arr.push(refineQuestion.RefinedAnswer);
              });

              let questotal = arr?.reduce(function (a, b) {
                return a + b;
              }, 0);
              interestedSet.InterestedPercentage =
                questotal / interestedSet.SubQuestion.length;
              return interestedSet;
            });
            interestedCombine = [...filterInterested, ...filterNotInterested];

            interestedCombine.map((interest) => {
              modifiedSkillsSet.map((skills) => {
                if (interest.Skills.ID === skills.Skills.ID) {
                  if (
                    interest.InterestedPercentage >= 4 &&
                    skills.RefinedAnswer >= 3
                  ) {
                    refMatrix.keyStrengths.push({
                      ID: skills.Skills.ID,
                      name: skills.Skills.Value,
                      percentage:
                        (interest.InterestedPercentage + skills.RefinedAnswer) /
                        2,
                    });
                  }

                  // potentialToExcel
                  if (
                    interest.InterestedPercentage >= 3 &&
                    interest.InterestedPercentage < 4 &&
                    skills.RefinedAnswer >= 3 &&
                    skills.RefinedAnswer < 4
                  ) {
                    refMatrix.potentialToExcel.push({
                      ID: skills.Skills.ID,
                      name: skills.Skills.Value,
                      percentage:
                        (interest.InterestedPercentage + skills.RefinedAnswer) /
                        2,
                    });
                  }

                  // newEnergy
                  if (
                    interest.InterestedPercentage >= 4 &&
                    skills.RefinedAnswer < 3
                  ) {
                    refMatrix.newEnergy.push({
                      ID: skills.Skills.ID,
                      name: skills.Skills.Value,
                      percentage:
                        (interest.InterestedPercentage + skills.RefinedAnswer) /
                        2,
                    });
                  }

                  // potentialStressors
                  if (
                    interest.InterestedPercentage < 4 &&
                    skills.RefinedAnswer >= 4
                  ) {
                    refMatrix.potentialStressors.push({
                      ID: skills.Skills.ID,
                      name: skills.Skills.Value,
                      percentage:
                        (interest.InterestedPercentage + skills.RefinedAnswer) /
                        2,
                    });
                  }

                  // lowRelevance
                  if (
                    interest.InterestedPercentage < 3 &&
                    skills.RefinedAnswer < 4
                  ) {
                    refMatrix.lowRelevance.push({
                      ID: skills.Skills.ID,
                      name: skills.Skills.Value,
                      percentage:
                        (interest.InterestedPercentage + skills.RefinedAnswer) /
                        2,
                    });
                  }
                }
              });
            });
            setMatrix(refMatrix);
            setScreenLoader(false);
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          setScreenLoader(false);
          console.log(err);
        }
      );
    }
  };

  const assessmentEntryInDb = async (assmentID, payload) => {
    await AssessmentActions.StrengthsAnalysisAssessmentEntryInDb(
      assmentID,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveAndLikeHandler = async (type) => {
    let anotherApiCallFlag = false;
    let payload = {
      LearningResourceType: cmsSA.resourceCategoryType.ID,
      ResourceTypeId: cmsSA.resourceType.Value,
      IsSaved:
        type === "save"
          ? assessmentStatus?.Parrent?.isSaved === true
            ? false
            : true
          : assessmentStatus?.Parrent?.isSaved,
      IsLiked:
        type === "like"
          ? assessmentStatus?.Parrent?.isLiked === true
            ? false
            : true
          : assessmentStatus?.Parrent?.isLiked,
    };
    await AssessmentActions.StrengthsAnalysisLikeAndSave(
      cmsSA.id,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          anotherApiCallFlag = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );

    if (anotherApiCallFlag) {
      let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
      await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(
        cmsSA.id
      ).then(
        (res) => {
          if (res.status === 200) {
            let parent = res?.data.filter(
              (status) => status.subLearningResourceId === undefined
            );
            refAssessmentStatus.Parrent = parent[0];
            assessmentIds.map((x) => {
              res?.data.map((status) => {
                if (x.id === status.subLearningResourceId) {
                  refAssessmentStatus[`${x.name}`] = status;
                }
              });
            });
            setAssessmentStatus(refAssessmentStatus);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const startRetakContinueHandler = async (assessment, assmentFlag) => {
    setScreenLoader(true);
    let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));

    if (assmentFlag === "Interests") {
      if (
        refAssessmentStatus?.Interests?.subLearningResourceStatus ===
          undefined ||
        refAssessmentStatus?.Interests?.subLearningResourceStatus === ""
      ) {
        await assessmentEntryInDb(cmsSA.id, {
          LearningResourceType: assessment.ResourceCategoryType.ID,
          ResourceTypeId: assessment.ResourceType.Value,
          SubLearningResourceId: assessment.ID,
        });
      }
      if (
        refAssessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() ===
        fetchLabel("ResourceStatus.Completed").toLowerCase()
      ) {
        await AssessmentActions.StrengthsAnalysisRetakeAssessment(
          assessment.ID
        ).then(
          (res) => {
            if (res.status === 200) {
              TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              TrackActions.PageAction("StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              setScreenLoader(false);
              navigate(
                "/" + pageOrgin.id + "/strength-analysis/interest-start"
              );
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      } else {
        let updatePayload = {
          SubLearningResourceStatus: "inprogress",
          SubLearningResourceId: assessment.ID,
          LastAnsweredPageId: parseInt(
            refAssessmentStatus?.Interests.lastAnsweredPageId
          ),
        };
        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
          cmsSA.id,
          updatePayload
        ).then(
          (res) => {
            if (res.status === 200) {
              TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              TrackActions.PageAction("StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              setScreenLoader(false);
              navigate(
                "/" + pageOrgin.id + "/strength-analysis/interest-start"
              );
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      }
    } else {
      if (
        refAssessmentStatus?.Skills?.subLearningResourceStatus === undefined ||
        refAssessmentStatus?.Skills?.subLearningResourceStatus === ""
      ) {
        await assessmentEntryInDb(cmsSA.id, {
          LearningResourceType: assessment.ResourceCategoryType.ID,
          ResourceTypeId: assessment.ResourceType.Value,
          SubLearningResourceId: assessment.ID,
        });
      }
      if (
        refAssessmentStatus?.Skills?.subLearningResourceStatus?.toLowerCase() ===
        fetchLabel("ResourceStatus.Completed").toLowerCase()
      ) {
        await AssessmentActions.StrengthsAnalysisRetakeAssessment(
          assessment.ID
        ).then(
          (res) => {
            if (res.status === 200) {
              TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              TrackActions.PageAction("StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              setScreenLoader(false);
              navigate("/" + pageOrgin.id + "/strength-analysis/skill-start");
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      } else {
        let updatePayload = {
          SubLearningResourceStatus: "inprogress",
          SubLearningResourceId: assessment.ID,
          LastAnsweredPageId: parseInt(
            refAssessmentStatus?.Skills.lastAnsweredPageId
          ),
        };
        await AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
          cmsSA.id,
          updatePayload
        ).then(
          (res) => {
            if (res.status === 200) {
              TrackActions.EventTrack(appInsights, "StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              TrackActions.PageAction("StartinternalAssessment", {
                InternalAssessmentId: cmsSA?.id,
                InternalAssessmentName: cmsSA?.title,
                SubAssessmentName: assessment?.Title,
                SubAssessmentID: assessment.ID,
              });
              setScreenLoader(false);
              navigate("/" + pageOrgin.id + "/strength-analysis/skill-start");
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      }
    }
  };

  const fetchButtonStatus = (status) => {
    if (status !== undefined && status !== "") {
      if (status?.toLowerCase() === "inprogress") {
        return <>{fetchLabel("CommonComponent.CommonContinueButton")}</>;
      } else if (status?.toLowerCase() === "completed") {
        return <>{fetchLabel("CommonComponentCommonRetakeButton")}</>;
      } else {
        return <>{fetchLabel("CommonResourceComponent.GetStartedButton")}</>;
      }
    } else {
      return <>{fetchLabel("CommonResourceComponent.GetStartedButton")}</>;
    }
  };

  const fetchLabel = (key) => {
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let label = "";
    if (refCmsSA?.masterLabel) {
      refCmsSA?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const downloadPDF = () => {
    let content = document.getElementById("assessment-result-wrapper");

    const datetime = new Date();
    const year = datetime.getFullYear();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const hours = datetime.getHours();
    const mins = datetime.getMinutes();
    const sec = datetime.getSeconds();
    const newDatetime =
      year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

    let img = new Image();
    html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        img.src = imgData;

        const pdf = new jsPDF({ orientation: "l", format: "a2", unit: "px" });
        pdf.viewerPreferences(
          { FitWindow: true, PrintArea: "MediaBox", PrintClip: "MediaBox" },
          true
        );
        console.log(browser);
        if (browser == "Safari") {
          let totalPages = Math.ceil(
            canvas.height / pdf.internal.pageSize.height
          );
          for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
              pdf.addPage();
            }
            let pageHeight = pdf.internal.pageSize.height;
            let pageWidth = pdf.internal.pageSize.width;
            let yPosition = -(i * pageHeight);

            // Add the canvas image to the PDF
            pdf.addImage(
              canvas,
              "JPEG",
              20,
              yPosition + 60,
              canvas.width,
              canvas.height
            );

            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
            pdf.setFontSize(18);
            //header background
            pdf.setFillColor(244, 244, 245);
            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

            //header
            const header = `${PDFheaderLogo}`;
            pdf.addImage(header, "png", 20, 20, 60, 20);

            //line above footer
            pdf.setDrawColor(236, 236, 236);
            pdf.line(
              20,
              pdf.internal.pageSize.height - 90,
              pdf.internal.pageSize.width - 20,
              pdf.internal.pageSize.height - 90,
              "S"
            );

            // footer
            const footerLeft = `${PDFfooterLogo}`;
            const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`;
            pdf.text(
              footerRight,
              pdf.internal.pageSize.width - 300,
              pdf.internal.pageSize.height - 50
            );
            pdf.addImage(
              footerLeft,
              "png",
              20,
              pdf.internal.pageSize.height - 80,
              160,
              45
            );
          }
          let file = new File(
            [pdf.output("blob")],
            context?.cmsStrengthAnalysis?.masterCMS?.Title +
              " " +
              cmsSA?.skillsMatrixComponent?.matrixCMS?.Title +
              "-" +
              newDatetime.toLocaleString() +
              ".pdf",
            { type: "application/pdf" }
          );

          let formData = new FormData();
          formData.append(file.name, file);
          let candidateID =
            context?.idTokenClaims?.signupId != undefined
              ? context?.idTokenClaims?.signupId
              : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
                  "signupId"
                ];
          formData.append("OwnerId", candidateID);
          formData.append("OwnerType", "Candidate");

          MyDocumentsActions.UploadDocuments(formData).then(
            (res) => {
              let docId;
              res.data.map((doc) => {
                docId = doc.id;
              });
              context.setGenerateMatrixPdfFlag(false);

              let statusPayload = {
                learningResourceStatus:
                  assessmentStatus?.Parrent?.learningResourceStatus,
                resultDocumentId: docId,
              };
              AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
                assessmentStatus?.Parrent?.learningResourceId,
                statusPayload
              ).then(
                (res) => {
                  if (res.status === 200) {
                    let refAssessmentStatus = JSON.parse(
                      JSON.stringify(assessmentStatus)
                    );
                    AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(
                      cmsSA.id
                    ).then(
                      (resp) => {
                        if (resp.status === 200) {
                          let parent = resp?.data.filter(
                            (status) =>
                              status.subLearningResourceId === undefined
                          );
                          refAssessmentStatus.Parrent = parent[0];
                          assessmentIds.map((x) => {
                            resp?.data.map((status) => {
                              if (x.id === status.subLearningResourceId) {
                                refAssessmentStatus[`${x.name}`] = status;
                              }
                            });
                          });
                          setAssessmentStatus(refAssessmentStatus);
                        } else {
                          console.log(resp);
                        }
                      },
                      (err) => {
                        console.log(err);
                      }
                    );
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          pdf.html(img, {
            margin: [100, 10, 120, 10],
            callback: (pdf) => {
              let pageCount = pdf.internal.getNumberOfPages();

              for (let i = 0; i < pageCount; i++) {
                pdf.setPage(i);

                let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                pdf.setFontSize(18);

                //header background
                pdf.setFillColor(244, 244, 245);
                pdf.rect(0, 0, pdf.internal.pageSize.width, 60, "F");

                //header
                const header = `${PDFheaderLogo}`;
                pdf.addImage(header, "png", 20, 20, 60, 20);

                //line above footer
                pdf.setDrawColor(236, 236, 236);
                pdf.line(
                  20,
                  pdf.internal.pageSize.height - 90,
                  pdf.internal.pageSize.width - 20,
                  pdf.internal.pageSize.height - 90,
                  "S"
                );

                // footer
                const footerLeft = `${PDFfooterLogo}`;
                const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`;
                pdf.text(
                  footerRight,
                  pdf.internal.pageSize.width - 300,
                  pdf.internal.pageSize.height - 50
                );
                pdf.addImage(
                  footerLeft,
                  "png",
                  20,
                  pdf.internal.pageSize.height - 80,
                  160,
                  45
                );
              }

              let file = new File(
                [pdf.output("blob")],
                context?.cmsStrengthAnalysis?.masterCMS?.Title +
                  " " +
                  cmsSA?.skillsMatrixComponent?.matrixCMS?.Title +
                  "-" +
                  newDatetime.toLocaleString() +
                  ".pdf",
                { type: "application/pdf" }
              );

              let formData = new FormData();
              formData.append(file.name, file);
              let candidateID =
                context?.idTokenClaims?.signupId != undefined
                  ? context?.idTokenClaims?.signupId
                  : JSON.parse(
                      window.sessionStorage.getItem("setIdTokenClaims")
                    )["signupId"];
              formData.append("OwnerId", candidateID);
              formData.append("OwnerType", "Candidate");

              MyDocumentsActions.UploadDocuments(formData).then(
                (res) => {
                  let docId;
                  res.data.map((doc) => {
                    docId = doc.id;
                  });
                  context.setGenerateMatrixPdfFlag(false);

                  let statusPayload = {
                    learningResourceStatus:
                      assessmentStatus?.Parrent?.learningResourceStatus,
                    resultDocumentId: docId,
                  };
                  AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
                    assessmentStatus?.Parrent?.learningResourceId,
                    statusPayload
                  ).then(
                    (res) => {
                      if (res.status === 200) {
                        let refAssessmentStatus = JSON.parse(
                          JSON.stringify(assessmentStatus)
                        );
                        AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(
                          cmsSA.id
                        ).then(
                          (resp) => {
                            if (resp.status === 200) {
                              let parent = resp?.data.filter(
                                (status) =>
                                  status.subLearningResourceId === undefined
                              );
                              refAssessmentStatus.Parrent = parent[0];
                              assessmentIds.map((x) => {
                                resp?.data.map((status) => {
                                  if (x.id === status.subLearningResourceId) {
                                    refAssessmentStatus[`${x.name}`] = status;
                                  }
                                });
                              });
                              setAssessmentStatus(refAssessmentStatus);
                            } else {
                              console.log(resp);
                            }
                          },
                          (err) => {
                            console.log(err);
                          }
                        );
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                },
                (err) => {
                  console.log(err);
                }
              );
            },
          });
        }
      }
    );
  };

  useEffect(() => {
    if (screenLoader === false) {
      if (context.generateMatrixPdfFlag) {
        downloadPDF();
      }
    }
  }, [screenLoader]);

  const shareResultWithCoach = () => {
    if (assessmentStatus?.Parrent?.resultDocumentId) {
      let payload = {
        assessmentName: cmsSA.title,
        assessmentId: cmsSA.id,
        documentId: assessmentStatus?.Parrent?.resultDocumentId,
      };
      setScreenLoader(true);
      AssessmentActions.ShareResultWithCoach(payload).then(
        (res) => {
          if (res.status === 201) {
            let statusPayload = {
              learningResourceStatus:
                assessmentStatus?.Parrent?.learningResourceStatus,
              resultDocumentId: assessmentStatus?.Parrent?.resultDocumentId,
              isSharedWithCoach: true,
            };
            setScreenLoader(false);
            AssessmentActions.StrengthsAnalysisUpdateAssessmentStatus(
              assessmentStatus?.Parrent?.learningResourceId,
              statusPayload
            ).then(
              (res) => {
                if (res.status === 200) {
                  context.setIsPopUpVisible({
                    status: true,
                    message: fetchLabel(
                      "CommonResourceComponent.AssessmentConfirmationMessage"
                    ),
                    closeText: fetchLabel("CommonComponent.CommonClose"),
                  });
                }
              },
              (err) => {
                console.log(err);
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
                setScreenLoader(false);
              }
            );
          }
        },
        (err) => {
          console.log(err);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
          setScreenLoader(false);
        }
      );
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
      <div className="assessment-page strength-analysis">
        <Container
          fluid
          className="background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20"
        >
          <Row className="justify-content-between">
            <Col md="12" lg="7" className="mb-3 pb-lg-5">
              <Cirrus.Button
                isSecondary
                onClick={() => {
                  navigate(`/${context.path ? context.path : path}`);
                }}
                className="mb-lg-5 mb-3"
              >
                {fetchLabel("CommonComponent.CommonBackButton")}
              </Cirrus.Button>
              <Cirrus.Header size="h3" className="assessment-title">
                {cmsSA?.title}
              </Cirrus.Header>
              <div className="save-section mt-4 mb-3">
                <button
                  className="saveBtn"
                  onClick={() => saveAndLikeHandler("save")}
                >
                  {assessmentStatus?.Parrent?.isSaved === true ? (
                    <i className="icon-saved"></i>
                  ) : (
                    <i className="icon-save"></i>
                  )}
                  <span className="saveBtnText">
                    {assessmentStatus?.Parrent?.isSaved === true
                      ? fetchLabel("CommonResourceComponent.Saved")
                      : fetchLabel("CommonComponent.CommonSaveButton")}
                  </span>
                </button>
                <Cirrus.Label id="category-label">
                  {cmsSA?.focusArea?.Value}
                </Cirrus.Label>
                <Cirrus.Label id="cardtype">
                  {cmsSA?.resourceType?.Value} <sup>.</sup>
                  <span>{cmsSA?.duration}</span>
                  {/* {fetchStatus(assessmentStatus?.Parrent?.learningResourceStatus)} */}
                </Cirrus.Label>
              </div>
              <div>
                <Cirrus.Button
                  size="small"
                  className="likeBtn"
                  onClick={() => saveAndLikeHandler("like")}
                >
                  {assessmentStatus?.Parrent?.isLiked === true ? (
                    <Icon icon="heart_fill" size={16} color="#9D323D" />
                  ) : (
                    <Icon icon="heart" size={16} color="#000" />
                  )}
                  <span className="likeBtnText">
                    {" "}
                    {assessmentStatus?.Parrent?.likeCount === 0
                      ? ""
                      : assessmentStatus?.Parrent?.likeCount}{" "}
                    {assessmentStatus?.Parrent?.likeCount === 0 ||
                    assessmentStatus?.Parrent?.likeCount === 1
                      ? fetchLabel(
                          "CommonComponent.CommonLikeText"
                        ).toUpperCase()
                      : fetchLabel(
                          "CommonComponent.CommonLikesText"
                        ).toUpperCase()}
                  </span>
                </Cirrus.Button>
              </div>
            </Col>
            <Col md="12" lg="5" className="position-relative">
              <div className="assesment_startpage-image-wrapper">
                <img
                  src={cmsSA?.largeImage?.ImageUrl}
                  alt="sideimage"
                  className="image-overflow"
                />
              </div>
              {/* <img src={sideimage} alt='sideimage' className='image-overflow' /> */}
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-lg-5 pb-lg-3 pb-md-0 p-3 pb-0">
          <Row>
            <Col md="12" lg="6" className="assessment_second-container-intro">
              <Cirrus.Headline
                size="Medium"
                className="mb-4 assessment_introHeading"
              >
                {cmsSA?.shortDescription}
              </Cirrus.Headline>
              <p className="mb-0">
                <Cirrus.RichText content={cmsSA?.description} />
              </p>
            </Col>
            <Col md="6" lg="6"></Col>
          </Row>
        </Container>

        <Container
          fluid
          className="strength-analysis_cards p-lg-5 p-md-3 p-3 pt-lg-3"
        >
          <Row>
            <Col lg={6}>
              <Row className="mb-3 card-row">
                {/* classname ->  card-status is default, 
                                classname ->  'inprogress' & 'completed' should come dynamically */}
                <Col
                  xs={2}
                  lg={1}
                  className={
                    "card-status" +
                    (assessmentStatus?.Interests?.subLearningResourceStatus ===
                      undefined ||
                    assessmentStatus?.Interests?.subLearningResourceStatus ===
                      ""
                      ? ""
                      : assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() ===
                        "inprogress"
                      ? " inprogress"
                      : " completed")
                  }
                >
                  <span>
                    <i className="icon-check"></i>
                  </span>
                </Col>
                <Col xs={10} lg={11}>
                  {/* Add your card here */}
                  <div className="card-style">
                    <div className="title">
                      {cmsSA?.assessmentInterests?.ResourceCardTitle}
                    </div>
                    <p className="mb-2">
                      <Cirrus.RichText
                        content={cmsSA?.assessmentInterests?.ShortDescription}
                        className="instructions"
                      />
                    </p>
                    <div className="cardFooter">
                      <div className="button-wrap">
                        <Cirrus.Button
                          isSecondary
                          className="mb-5"
                          onClick={() =>
                            startRetakContinueHandler(
                              cmsSA?.assessmentInterests,
                              "Interests"
                            )
                          }
                        >
                          {fetchButtonStatus(
                            assessmentStatus?.Interests
                              ?.subLearningResourceStatus
                          )}
                        </Cirrus.Button>
                        {assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() ===
                          "completed" && (
                          <Cirrus.Button
                            isSecondary
                            className="mb-5"
                            onClick={() => {
                              navigate(
                                "/" +
                                  pageOrgin.id +
                                  "/strength-analysis/interest-result"
                              );
                            }}
                          >
                            {fetchLabel("CommonResourceComponent.ViewResults")}
                          </Cirrus.Button>
                        )}
                      </div>
                      <Cirrus.Label>
                        {/* {fetchStatus(assessmentStatus?.Interests?.subLearningResourceStatus)} */}
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Icon icon="clock-icon" size={20} /> */}
                          <i className="icon-watch_later"></i>
                          <span style={{ marginLeft: "7px" }}>
                            {cmsSA?.assessmentInterests?.Duration}
                          </span>
                        </span>
                      </Cirrus.Label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 card-row">
                {/* classname ->  card-status is default, 
                                classname ->  'inprogress' & 'completed' should come dynamically */}
                {/* <Col xs={2} lg={1} className={"card-status" + (assessmentStatus?.Skills?.subLearningResourceStatus === undefined || assessmentStatus?.Skills?.subLearningResourceStatus === ""  ? "" : assessmentStatus?.Skills?.subLearningResourceStatus?.toLowerCase() === 'inprogress' ? " inprogress" : " completed")}> */}
                <Col
                  xs={2}
                  lg={1}
                  className={
                    "card-status" +
                    (assessmentStatus?.Interests?.subLearningResourceStatus ==
                    "inprogress"
                      ? ""
                      : assessmentStatus?.Skills?.subLearningResourceStatus ===
                          undefined ||
                        assessmentStatus?.Skills?.subLearningResourceStatus ===
                          ""
                      ? " inprogress"
                      : assessmentStatus?.Skills?.subLearningResourceStatus?.toLowerCase() ===
                        "inprogress"
                      ? " inprogress"
                      : " completed")
                  }
                >
                  <span>
                    <i className="icon-check"></i>
                  </span>
                </Col>
                <Col xs={10} lg={11}>
                  {/* <div className='card-style card-disabled'> */}
                  <div
                    className={
                      "card-style" +
                      (assessmentStatus?.Interests
                        ?.subLearningResourceStatus === undefined
                        ? " card-disabled"
                        : assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() ===
                          "inprogress"
                        ? " card-disabled"
                        : "")
                    }
                  >
                    <div className="title">
                      {cmsSA?.assessmentSkills?.ResourceCardTitle}
                    </div>
                    <p className="mb-2">
                      <Cirrus.RichText
                        content={cmsSA?.assessmentSkills?.ShortDescription}
                      />
                    </p>
                    <div className="cardFooter">
                      <div className="button-wrap">
                        {assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() ===
                          "completed" && (
                          <>
                            <Cirrus.Button
                              isSecondary
                              className="mb-2"
                              onClick={() =>
                                startRetakContinueHandler(
                                  cmsSA?.assessmentSkills,
                                  "Skills"
                                )
                              }
                            >
                              {fetchButtonStatus(
                                assessmentStatus?.Skills
                                  ?.subLearningResourceStatus
                              )}
                            </Cirrus.Button>
                            {assessmentStatus?.Skills?.subLearningResourceStatus?.toLowerCase() ===
                              "completed" && (
                              <Cirrus.Button
                                isSecondary
                                className="mb-2"
                                onClick={() => {
                                  navigate(
                                    "/" +
                                      pageOrgin.id +
                                      "/strength-analysis/skill-result"
                                  );
                                }}
                              >
                                {fetchLabel(
                                  "CommonResourceComponent.ViewResults"
                                )}
                              </Cirrus.Button>
                            )}
                          </>
                        )}

                        {/* {
                                                        (assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() === 'completed' && assessmentStatus.Skills.subLearningResourceStatus === undefined) ||
                                                            (assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() === 'inprogress' && assessmentStatus.Skills.subLearningResourceStatus === 'completed') ||
                                                            (assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() === 'completed' && assessmentStatus.Skills.subLearningResourceStatus === 'completed') ||
                                                            (assessmentStatus?.Interests?.subLearningResourceStatus?.toLowerCase() === 'completed' && assessmentStatus.Skills.subLearningResourceStatus === 'inprogress') ? <>
                                                            <Cirrus.Button isSecondary className="mb-2" onClick={() => startRetakContinueHandler(cmsSA?.assessmentSkills, 'Skills')}>
                                                                {fetchButtonStatus(assessmentStatus?.Skills?.subLearningResourceStatus)}
                                                            </Cirrus.Button>
                                                            {
                                                                assessmentStatus?.Skills?.subLearningResourceStatus?.toLowerCase() === 'completed' && <Cirrus.Button isSecondary className="mb-2" onClick={() => { navigate('/' + pageOrgin.id + '/strength-analysis/skill-result') }}>{fetchLabel("CommonResourceComponent.ViewResults")}</Cirrus.Button>
                                                            }
                                                        </> : ''
                                                    } */}
                      </div>
                      <Cirrus.Label>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Icon icon="clock-icon" size={20} /> */}
                          <i className="icon-watch_later"></i>
                          <span style={{ marginLeft: "7px" }}>
                            {cmsSA?.assessmentSkills?.Duration}
                          </span>
                        </span>
                      </Cirrus.Label>
                    </div>
                  </div>
                  {(assessmentStatus?.Interests?.subLearningResourceStatus ===
                    undefined &&
                    assessmentStatus?.Skills?.subLearningResourceStatus ===
                      undefined) ||
                  (assessmentStatus?.Interests?.subLearningResourceStatus !==
                    "completed" &&
                    assessmentStatus?.Skills?.subLearningResourceStatus ===
                      undefined) ||
                  (assessmentStatus?.Interests?.subLearningResourceStatus ===
                    "completed" &&
                    assessmentStatus?.Skills?.subLearningResourceStatus !==
                      "completed") ||
                  (assessmentStatus?.Interests?.subLearningResourceStatus ===
                    "inprogress" &&
                    assessmentStatus?.Skills?.subLearningResourceStatus ===
                      "completed") ? (
                    <>
                      <div className="matrix-label mt-3">
                        {fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixText"
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {assessmentStatus?.Interests?.subLearningResourceStatus ===
          "completed" &&
        assessmentStatus?.Skills?.subLearningResourceStatus === "completed" ? (
          <>
            <Container
              fluid
              className="background-White p-lg-5 p-3 pt-5 skill-matrix-section"
            >
              <Container>
                <Row className="mb-lg-5 mb-3">
                  <Col lg={8} className="p-0">
                    <Cirrus.Headline size="Medium" className="mb-2">
                      {cmsSA?.skillsMatrixComponent?.matrixCMS?.Title}
                    </Cirrus.Headline>
                    <p className="mb-3">
                      {cmsSA?.skillsMatrixComponent?.matrixCMS?.Description}
                    </p>
                  </Col>
                  <Col lg={4} className="p-0 text-lg-end text-start">
                    <Cirrus.Button
                      isSecondary
                      onClick={() => shareResultWithCoach()}
                      disabled={coachdata ? false : true}
                    >
                      {fetchLabel(
                        "CommonResourceComponent.ShareResultsWithCoach"
                      )}
                    </Cirrus.Button>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col
                    lg={{ span: 4, order: 1 }}
                    xs={{ order: 1 }}
                    className="p-0"
                  >
                    <div className="skill-matrix">
                      <OverlayTrigger
                        key={fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                        )}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                            )}
                          >
                            {`${
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                              ) +
                              " / " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                              )
                            }`}
                            {/* High Skills, High Interests / Medium Skills and High interests */}
                          </Tooltip>
                        }
                      >
                        <div className="skill-title mb-3">
                          <Cirrus.Label>
                            {fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                            )}
                          </Cirrus.Label>
                        </div>
                      </OverlayTrigger>
                      <div className="skill-list">
                        <ul>
                          {matrix?.keyStrengths?.map((item) => {
                            return (
                              <li>
                                <Cirrus.Label>{item?.name}</Cirrus.Label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={{ span: 4, order: 2 }}
                    xs={{ order: 2 }}
                    className="p-0"
                  >
                    <div className="skill-matrix skill-overlap">
                      <OverlayTrigger
                        key={fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                        )}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                            )}
                          >
                            {`${
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumInterest"
                              )
                            }`}
                            {/* Medium Skills, Medium interest */}
                          </Tooltip>
                        }
                      >
                        <div className="skill-title mb-3">
                          <Cirrus.Label>
                            {fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                            )}
                          </Cirrus.Label>
                        </div>
                      </OverlayTrigger>
                      <div className="skill-list">
                        <ul>
                          {matrix?.potentialToExcel?.map((item) => {
                            return (
                              <li>
                                <Cirrus.Label>{item?.name}</Cirrus.Label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={{ span: 4, order: 3 }}
                    xs={{ order: 3 }}
                    className="p-0"
                  >
                    <div className="skill-matrix">
                      <OverlayTrigger
                        key={fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                        )}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                            )}
                          >
                            {`${
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                              )
                            }`}
                            {/* Low Skills, High interests */}
                          </Tooltip>
                        }
                      >
                        <div className="skill-title mb-3">
                          <Cirrus.Label>
                            {fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                            )}
                          </Cirrus.Label>
                        </div>
                      </OverlayTrigger>
                      <div className="skill-list">
                        <ul>
                          {matrix?.newEnergy?.map((item) => {
                            return (
                              <li>
                                <Cirrus.Label>{item?.name}</Cirrus.Label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={12}>
                    <p className="mb-3">
                      {fetchLabel(
                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixVariables"
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="skill-matrix">
                      <OverlayTrigger
                        key={fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                        )}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                            )}
                          >
                            {`${
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterests"
                              ) +
                              " / " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumInterests"
                              )
                            }`}
                            {/* High Skills, Low Interests / High Skills, Medium interests */}
                          </Tooltip>
                        }
                      >
                        <div className="skill-title mb-3">
                          <Cirrus.Label>
                            {fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                            )}
                          </Cirrus.Label>
                        </div>
                      </OverlayTrigger>
                      <div className="skill-list">
                        <ul>
                          {matrix?.potentialStressors?.map((item) => {
                            return (
                              <li>
                                <Cirrus.Label>{item?.name}</Cirrus.Label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="skill-matrix">
                      <OverlayTrigger
                        key={fetchLabel(
                          "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                        )}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                            )}
                          >
                            {`${
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterests"
                              ) +
                              " / " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                              ) +
                              ", " +
                              fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterest"
                              )
                            }`}
                            {/* Low Skills, Low Interests / Medium Skills, Low Interest */}
                          </Tooltip>
                        }
                      >
                        <div className="skill-title mb-3">
                          <Cirrus.Label>
                            {fetchLabel(
                              "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                            )}
                          </Cirrus.Label>
                        </div>
                      </OverlayTrigger>
                      <div className="skill-list">
                        <ul>
                          {matrix?.lowRelevance?.map((item) => {
                            return (
                              <li>
                                <Cirrus.Label>{item?.name}</Cirrus.Label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </>
        ) : (
          ""
        )}
      </div>

      {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
      <div
        style={{
          opacity: "0",
          width: "100%",
          minHeight: "10px",
          overflow: "scroll",
          position: "absolute",
        }}
      >
        <div
          id="assessment-result-wrapper"
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            zIndex: "-1",
            display: "block",
            width: "1300px",
          }}
        >
          <table
            cellPadding={10}
            cellSpacing={10}
            style={{
              width: "80%",
              border: "0px",
              borderRadius: "5px",
              textAlign: "left",
              fontSize: "13px",
            }}
          >
            <tbody>
              <tr>
                <td>
                  {assessmentStatus?.Interests?.subLearningResourceStatus ===
                    "completed" &&
                  assessmentStatus?.Skills?.subLearningResourceStatus ===
                    "completed" ? (
                    <>
                      <Container fluid className="background-White p-5">
                        <Row className="mb-5">
                          <Col lg={8} className="p-0">
                            <Cirrus.Headline size="Medium" className="mb-2">
                              {cmsSA?.skillsMatrixComponent?.matrixCMS?.Title}
                            </Cirrus.Headline>
                            <p className="mb-3">
                              {
                                cmsSA?.skillsMatrixComponent?.matrixCMS
                                  ?.Description
                              }
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          {/* <Col lg={10}>
                                                        <Row> */}
                          <Col lg={4} className="p-0">
                            <div className="skill-matrix">
                              <OverlayTrigger
                                key={fetchLabel(
                                  "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                                )}
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                                    )}
                                  >
                                    {`${
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                                      ) +
                                      " / " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                                      )
                                    }`}
                                    {/* High Skills, High Interests / Medium Skills and High interests */}
                                  </Tooltip>
                                }
                              >
                                <div className="skill-title mb-3">
                                  <Cirrus.Label>
                                    {fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixKeyStrengthsText"
                                    )}
                                  </Cirrus.Label>
                                </div>
                              </OverlayTrigger>
                              <div className="skill-list">
                                <ul>
                                  {matrix?.keyStrengths?.map((item) => {
                                    return (
                                      <li>
                                        <Cirrus.Label>
                                          {item?.name}
                                        </Cirrus.Label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4} className="p-0">
                            <div className="skill-matrix skill-overlap">
                              <OverlayTrigger
                                key={fetchLabel(
                                  "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                                )}
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                                    )}
                                  >
                                    {`${
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumInterest"
                                      )
                                    }`}
                                    {/* Medium Skills, Medium interest */}
                                  </Tooltip>
                                }
                              >
                                <div className="skill-title mb-3">
                                  <Cirrus.Label>
                                    {fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialToExcelText"
                                    )}
                                  </Cirrus.Label>
                                </div>
                              </OverlayTrigger>
                              <div className="skill-list">
                                <ul>
                                  {matrix?.potentialToExcel?.map((item) => {
                                    return (
                                      <li>
                                        <Cirrus.Label>
                                          {item?.name}
                                        </Cirrus.Label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4} className="p-0">
                            <div className="skill-matrix">
                              <OverlayTrigger
                                key={fetchLabel(
                                  "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                                )}
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                                    )}
                                  >
                                    {`${
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighInterests"
                                      )
                                    }`}
                                    {/* Low Skills, High interests */}
                                  </Tooltip>
                                }
                              >
                                <div className="skill-title mb-3">
                                  <Cirrus.Label>
                                    {fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixNewEnergyText"
                                    )}
                                  </Cirrus.Label>
                                </div>
                              </OverlayTrigger>
                              <div className="skill-list">
                                <ul>
                                  {matrix?.newEnergy?.map((item) => {
                                    return (
                                      <li>
                                        <Cirrus.Label>
                                          {item?.name}
                                        </Cirrus.Label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Col>
                          {/* </Row>
                                                    </Col> */}
                        </Row>
                        <Row className="mb-2">
                          <Col lg={12}>
                            <p className="mb-3">
                              {fetchLabel(
                                "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixVariables"
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="skill-matrix">
                              <OverlayTrigger
                                key={fetchLabel(
                                  "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                                )}
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                                    )}
                                  >
                                    {`${
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterests"
                                      ) +
                                      " / " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixHighSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumInterests"
                                      )
                                    }`}
                                    {/* High Skills, Low Interests / High Skills, Medium interests */}
                                  </Tooltip>
                                }
                              >
                                <div className="skill-title mb-3">
                                  <Cirrus.Label>
                                    {fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixPotentialStressorsText"
                                    )}
                                  </Cirrus.Label>
                                </div>
                              </OverlayTrigger>
                              <div className="skill-list">
                                <ul>
                                  {matrix?.potentialStressors?.map((item) => {
                                    return (
                                      <li>
                                        <Cirrus.Label>
                                          {item?.name}
                                        </Cirrus.Label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Col>
                          {/* <Col lg={1}></Col> */}
                          <Col>
                            <div className="skill-matrix">
                              <OverlayTrigger
                                key={fetchLabel(
                                  "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                                )}
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                                    )}
                                  >
                                    {`${
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterests"
                                      ) +
                                      " / " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixMediumSkills"
                                      ) +
                                      ", " +
                                      fetchLabel(
                                        "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowInterest"
                                      )
                                    }`}
                                    {/* Low Skills, Low Interests / Medium Skills, Low Interest */}
                                  </Tooltip>
                                }
                              >
                                <div className="skill-title mb-3">
                                  <Cirrus.Label>
                                    {fetchLabel(
                                      "Resources.Assessments.StrengthAnalysis.SkillsMatrixComponent.SkillsMatrixLowRelevance"
                                    )}
                                  </Cirrus.Label>
                                </div>
                              </OverlayTrigger>
                              <div className="skill-list">
                                <ul>
                                  {matrix?.lowRelevance?.map((item) => {
                                    return (
                                      <li>
                                        <Cirrus.Label>
                                          {item?.name}
                                        </Cirrus.Label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default StrengthAnalysis;
