//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import "./IviewDonePage.css";
import { Row, Col, Container } from "react-bootstrap";
import { Context } from "../../../common/context/Context";
import { iViewActions } from ".././iViewActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function IviewInterviewDonePage() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const { id, token, toolId } = useParams();
  localStorage.setItem("AccessToken", token);
  const URL = window.location.href.split("#");
  const [cmsContent, setCmsContent] = useState({
    assessmentTitle: "",
    title: "",
    description: "",
    masterLabel: [],
  });
  const appInsights = useAppInsightsContext();
  const [screenLoader, setScreenLoader] = useState(true);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [coachdata, setcoachdata] = useState({});
  let candidateID =
    context?.idTokenClaims?.signupId != undefined
      ? context?.idTokenClaims?.signupId
      : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
          "signupId"
        ];
  let languageCode = JSON.parse(sessionStorage.getItem("setProgramDetails"))[
    "programLanguageCode"
  ];

  useEffect(() => {
    getMyCoach();
    iViewResultPageContent();
    TrackActions.PageViewRecord(appInsights, "IviewDone");
    TrackActions.PageAction("IviewDone");
  }, [context.language]);

  const iViewResultPageContent = async () => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    await iViewActions
      .GetIviewContent({ PageName: "OnDemandVideoInterview" })
      .then(
        (res) => {
          if (res.status == 200) {
            let externalIDS = [];
            let iViewData = {};
            res?.data?.resourcePages?.map((reso1) => {
              reso1.resourcePages?.filter((reso2) => {
                externalIDS.push(reso2.externalId);
                if (reso2.externalId === id) {
                  iViewData = reso2;
                }
              });
            });

            if (Object.keys(iViewData).length !== 0) {
              refCmsContent.assessmentTitle = iViewData?.title;
              refCmsContent.title = iViewData?.childPages[0]?.title;
              refCmsContent.description = iViewData?.childPages[0]?.description;
              let label = [
                ...res?.data?.components?.commonComponent?.Labels,
                ...res?.data?.components?.commonResourceComponent?.Labels,
              ];
              refCmsContent.masterLabel = label;
              setCmsContent(refCmsContent);
              savingTheAssessment(iViewData?.issaved, iViewData?.isLiked);
              setScreenLoader(false);
            } else {
              let payload = {
                learningResourceType: "tools",
                recommended: "false",
                isAllLearningResources: "true",
                ResourceCategory: "tools",
                ProgramClassIds: null,
              };
              iViewActions.GetLearningresourceToolsData(payload).then(
                (res) => {
                  if (res.status == 200) {
                    let filteredData = res?.data?.responsemodel?.filter(
                      (item) => item.externalId === id
                    );
                    savingTheAssessment(
                      filteredData[0]?.isSaved,
                      filteredData[0]?.isLiked
                    );
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
              iViewActions
                .Get_30_secondCommercialCMS({
                  PageName: "30 Second Commercial",
                })
                .then(
                  (res) => {
                    if (res.status == 200) {
                      refCmsContent.assessmentTitle = res?.data?.Title;
                      refCmsContent.title = res?.data?.ChildPages[0]?.Title;
                      refCmsContent.description =
                        res?.data?.ChildPages[0]?.Description;
                      let label = [
                        ...res?.data?.Components?.CommonComponent?.Labels,
                        ...res?.data?.Components?.CommonResourceComponent
                          ?.Labels,
                      ];
                      refCmsContent.masterLabel = label;
                      setCmsContent(refCmsContent);

                      setScreenLoader(false);
                    } else {
                      setScreenLoader(false);
                    }
                  },
                  (err) => {
                    console.log(err);
                    setScreenLoader(false);
                  }
                );
            }
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );
  };

  // const viewResultHandler = async () => {
  //     setLoadSpinner(true)
  //     await iViewActions.GetIviewReview(id).then((res) => {
  //         if (res.status == 200) {
  //             window.open(res?.data?.reviewUrl, "_blank");
  //             setLoadSpinner(false)
  //         } else {
  //             setLoadSpinner(false)
  //         }
  //     }, (err) => {
  //         console.log(err);
  //         setLoadSpinner(false)
  //     })
  // }

  const shareResultsWithCoachHandler = async () => {
    setLoadSpinner(true);
    await iViewActions.GetIviewReview(id).then(
      (res) => {
        if (res.status == 200) {
          let payload = {
            assessmentName: cmsContent?.assessmentTitle,
            assessmentId: toolId,
            IsIview: true,
            AssessmentResultUrl: res?.data?.reviewUrl,
          };
          iViewActions.ShareResultWithCoach(payload).then(
            (res) => {
              if (res.status === 201) {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: true,
                  message: fetchLabel(
                    "CommonResourceComponent.AssessmentConfirmationMessage"
                  ),
                  closeText: fetchLabel("CommonComponent.CommonClose"),
                });
              } else {
                setLoadSpinner(false);
                context.setIsPopUpVisible({
                  status: false,
                  message: "",
                  closeText: "",
                });
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
              context.setIsPopUpVisible({
                status: false,
                message: "",
                closeText: "",
              });
            }
          );
        } else {
          setLoadSpinner(false);
          context.setIsPopUpVisible({
            status: false,
            message: "",
            closeText: "",
          });
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );
  };

  const retakeHandler = async () => {
    setLoadSpinner(true);
    await iViewActions.ResetIview(id).then(
      (res) => {
        if (res.status == 200) {
          const payload = {
            LearningResourceId: toolId,
            CallbackURL: `${
              URL[0]
            }#/iview-interview-done/${id}/${toolId}/${localStorage.getItem(
              "AccessToken"
            )}`,
            Languagecode: languageCode,
          };
          iViewActions.GetIviewInterview(payload, id).then(
            (res) => {
              if (res.status == 200) {
                navigate(`/iview-interview-start/${toolId}/${id}`);
                setLoadSpinner(false);
              } else {
                setLoadSpinner(false);
              }
            },
            (err) => {
              console.log(err);
              setLoadSpinner(false);
            }
          );
        } else {
          setLoadSpinner(false);
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const savingTheAssessment = (isSaved, isLiked) => {
    const payload = {
      LearningResourceId: toolId,
      CallbackURL: `${
        URL[0]
      }#/iview-interview-done/${id}/${toolId}/${localStorage.getItem(
        "AccessToken"
      )}`,
      Languagecode: languageCode,
    };
    iViewActions.GetIviewInterview(payload, id).then(
      (res) => {
        if (res.status == 200) {
          let payload = {
            candidateId: candidateID,
            LearningResourceId: toolId,
            LearningResourceType: "iView",
            LearningResourceStatus: "Completed",
            IsDownloaded: false,
            IsSharedWithCoach: false,
            status: "Completed",
            isSaved: isSaved,
            isLiked: isLiked,
            InterviewId: res?.data?.interviewId,
            InterviewType: "Video",
            IsRetaken: false,
            RedirectUrl: res?.data?.interviewUrl,
            RequisitionId: id,
            Languagecode: languageCode,
          };
          iViewActions.SaveIviewAssessmentCard(toolId, payload).then(
            (res) => {
              if (res.status == 200) {
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await iViewActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (refCmsContent?.masterLabel) {
      refCmsContent?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="iView-done-page">
      {loadSpinner && (
        <div className="loader">
          <Cirrus.Spinner size={50} />
        </div>
      )}
      <Container className="py-3 iView-done">
        <Row>
          <Col md={10} lg={12} xs={12} className="text-end">
            <Cirrus.Button
              onClick={(e) => {
                window.open(`${URL[0]}#/iview-welcome`, "_self");
              }}
            >
              {fetchLabel("CommonComponent.CommonExitButton")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>
      <div className="card iView-done container">
        <div className="mobile-header-wrapper">
          <div className="header mb-2">{cmsContent?.title}</div>
          <Container className="assessment-review-result-wrapper">
            <Row>
              <Col lg={12} className="download-share-retake">
                {/* <Cirrus.Button isSecondary className="me-2" onClick={viewResultHandler}>
                                    {fetchLabel("CommonResourceComponent.ViewResults")}
                                </Cirrus.Button> */}
                <Cirrus.Button
                  isSecondary
                  className="me-2"
                  onClick={shareResultsWithCoachHandler}
                  disabled={Object.keys(coachdata).length !== 0 ? false : true}
                >
                  {fetchLabel("CommonResourceComponent.ShareResultsWithCoach")}
                </Cirrus.Button>
                <Cirrus.Button isSecondary onClick={retakeHandler}>
                  {fetchLabel("CommonComponentCommonRetakeButton")}
                </Cirrus.Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bodyTextWrapper">
          <div className="bodyContent">{cmsContent?.description}</div>
        </div>
      </div>
    </div>
  );
}

export default IviewInterviewDonePage;
