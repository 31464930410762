//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { Context } from '../../../common/context/Context';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from '../../header/headerWithNavigation';
import Spinner from '../../../common/components/spinner-loader/Spinner';
import Footer from "../../../common/components/footer";
import { Row, Col, Container, Overlay, Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PDFheaderLogo from "../Brandname.png";
import PDFfooterLogo from "../TS_RM_Logo_RGB_HOR.png";
import { MyDocumentsActions } from '../../myDocuments/MyDocumentsActions';
import VandDpdfTemplate from './pdfTemplate';

const VandDReviewPageMobile = () => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [cmsAssessmentContent, setCmsAssessmentContent] = useState();
    const [assessmentStatus, setAssessmentStatus] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesDropdown, setCategoriesDropdown] = useState([]);
    const [getResponse, setGetResponse] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [disabled, setDisabled] = useState(true)

    const [pdfResponse, setPdfResponse] = useState([]);

    const [hidden, setHidden] = useState({});
    const [toggle, setToggle] = useState(false);
    const [moveToModal, setMoveToModal] = useState(false);
    const [questionAnswer, setQuestionAnswer] = useState({
        QuestionId: "",
        AnswerId: ""
    });

    const userAgent = window.navigator.userAgent;
    let browser = '';
    if (/Chrome/.test(userAgent)) {
        browser = 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent)) {
        browser = 'Safari';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Microsoft Edge';
    } else {
        browser = 'Unknown Browser';
    }

    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setViewPortWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        fetchCMSData()
        getAssessmentStatus()
        if (categories.length) {
            renderSavedAssessment()
        }
        window.scrollTo(0, 0)

        let tempHidden = {};
        document.querySelectorAll('.vd-toggleHeader').forEach((ele, index) => {
            tempHidden = { ...tempHidden, [index]: false }
        })
        setHidden(tempHidden)
    }, [categories, context.language])

    const getSavedAssessmentResponses = async () => {
        let assessmentId = context.cmsValuesAndDriversContent.internalAssessmentId;
        let res = await AssessmentActions.GetSavedResponseByAssessmentId(assessmentId);
        return res.data;
    }

    const fetchCMSData = () => {
        let resData = context.cmsValuesAndDriversContent;
        setCmsAssessmentContent(resData);
        setCategories(resData?.commonAnswer);
        setCategoriesDropdown(
            resData?.commonAnswer?.map(category => {
                return (
                    { ...category, name: category.AnswerText, value: category.AnswerId }
                )
            })
        )
    }

    const getAssessmentStatus = async () => {
        let response = await AssessmentActions.GetAssessmentStatusById(context.cmsValuesAndDriversContent.internalAssessmentId)
        setAssessmentStatus(response.data);
        setIsSaved(response.data.isSaved);
        setIsLiked(response.data.isLiked);
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = context.cmsValuesAndDriversContent;
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const renderSavedAssessment = async () => {
        let savedResponses = await getSavedAssessmentResponses();
        let cmsContent = context.cmsValuesAndDriversContent;
        let categorizedArr = [];
        if (savedResponses.length) {
            savedResponses?.map(savedResponse => {
                let QuestionId = savedResponse.questionId;
                let AnswerId = savedResponse.answerValue;
                cmsContent?.assessmentQuestionSet.map(assessment => {
                    if (assessment.ID === QuestionId) {
                        categories?.length && categories?.map(category => {
                            if (category.AnswerId === AnswerId) {
                                categorizedArr.push({
                                    assessmentID: cmsContent?.internalAssessmentId,
                                    questionId: assessment.ID,
                                    questionText: assessment.Title,
                                    questionDescription: assessment.Description,
                                    answerId: category.AnswerId,
                                    answerText: category.AnswerText
                                })
                            }
                        })
                    }
                })
            })
            setGetResponse(categorizedArr);
            setPdfResponse(categorizedArr);
            errorValidation(categorizedArr);
            setMoveToModal(false)
            setLoadSpinner(false);
        }
    }

    const updateAssessmentStatus = (docId) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];

        let payload = {
            "candidateId": candidateID,
            "learningResourceId": assessmentStatus.learningResourceId,
            "learningResourceStatus": fetchStatusID("ResourceStatus.Completed"),
            "learningResourceType": context.cmsValuesAndDriversContent?.resourceCategoryType?.ID,
            "resourceTypeId": context.cmsValuesAndDriversContent?.resourceType?.ID,
            "isSaved": isSaved,
            "isLiked": isLiked,
            "lastAnsweredPageId": assessmentStatus.lastAnsweredPageId,
            "isDownloaded": assessmentStatus?.isDownloaded,
            "isSharedWithCoach": assessmentStatus?.isSharedWithCoach,
            "resultDocumentId": docId
        }
        AssessmentActions.SaveAssessmentStatus(context.cmsValuesAndDriversContent.resourceCategoryType?.ID, assessmentStatus.learningResourceId, payload).then((res) => {
            if (res.status === 200) {
                if (viewPortWidth <= 992) {
                    navigate("/" + pageOrgin.id + "/values-and-drivers/result-mobile-view")
                } else {
                    navigate("/" + pageOrgin.id + "/values-and-drivers/result")
                }
            }
        }, err => {
            console.log(err);
        })
    }

    const downloadPDF = () => {
        let content = document.getElementById('assessment-result-wrapper');
        setLoadSpinner(true);

        const datetime = new Date();
        const year = datetime.getFullYear();
        const date = datetime.getDate();
        const month = datetime.getMonth() + 1;
        const hours = datetime.getHours();
        const mins = datetime.getMinutes();
        const sec = datetime.getSeconds();
        const newDatetime = year + "-" + month + "-" + date + " " + hours + "-" + mins + "-" + sec;

        let img = new Image();
        html2canvas(content, { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            img.src = imgData;
            const pdf = new jsPDF({ orientation: 'l', format: 'a2', unit: 'px' });
            pdf.viewerPreferences(
                { 'FitWindow': true, 'PrintArea': 'MediaBox', 'PrintClip': 'MediaBox' },
                true
            )
            console.log(browser)
            if (browser == 'Safari') {
                // let pageCount = pdf.internal.getNumberOfPages();
                // for (let i = 0; i < pageCount; i++) {
                //     pdf.setPage(i);

                //     let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                //     pdf.setFontSize(18);

                //     //header background
                //     pdf.setFillColor(244, 244, 245);
                //     pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                //     //header
                //     const header = `${PDFheaderLogo}`;
                //     pdf.addImage(header, 'png', 20, 20, 60, 20);

                //     //line above footer
                //     pdf.setDrawColor(236, 236, 236);
                //     pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                //     // footer
                //     const footerLeft = `${PDFfooterLogo}`
                //     const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                //     pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                //     pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);

                //     // main Content
                //     pdf.addImage(img, 'JPEG', 20, 70, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 160)
                // }
                let totalPages = Math.ceil(canvas.height / pdf.internal.pageSize.height)
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) {
                        pdf.addPage();
                    }
                    let pageHeight = pdf.internal.pageSize.height;
                    let pageWidth = pdf.internal.pageSize.width;
                    let yPosition = -(i * pageHeight);

                    // Add the canvas image to the PDF
                    pdf.addImage(canvas, 'JPEG', 20, yPosition + 60, canvas.width, canvas.height);

                    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                    pdf.setFontSize(18);
                    //header background
                    pdf.setFillColor(244, 244, 245);
                    pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                    //header
                    const header = `${PDFheaderLogo}`;
                    pdf.addImage(header, 'png', 20, 20, 60, 20);

                    //line above footer
                    pdf.setDrawColor(236, 236, 236);
                    pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                    // footer
                    const footerLeft = `${PDFfooterLogo}`
                    const footerRight = `Page: ${pageCurrent} of ${totalPages}    Date downloaded ${date}/${month}/${year}`
                    pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                    pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                }
                let file = new File([pdf.output('blob')], context.cmsValuesAndDriversContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })
                let formData = new FormData();
                formData.append(file.name, file)
                let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                formData.append('OwnerId', candidateID);
                formData.append('OwnerType', "Candidate");
                MyDocumentsActions.UploadDocuments(formData).then(res => {
                    let docId;
                    res.data.map(doc => {
                        docId = doc.id
                    })
                    console.log(docId, "Doc Id") // for testing in Apple devices
                    updateAssessmentStatus(docId)
                    setLoadSpinner(false);
                }, err => {
                    console.log(err);
                    setLoadSpinner(false);
                });
            } else {
                pdf.html(img, {
                    margin: [100, 10, 120, 10],
                    callback: (pdf) => {
                        let pageCount = pdf.internal.getNumberOfPages();
                        for (let i = 0; i < pageCount; i++) {
                            pdf.setPage(i);

                            let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
                            pdf.setFontSize(18);

                            //header background
                            pdf.setFillColor(244, 244, 245);
                            pdf.rect(0, 0, pdf.internal.pageSize.width, 60, 'F');

                            //header
                            const header = `${PDFheaderLogo}`;
                            pdf.addImage(header, 'png', 20, 20, 60, 20);

                            //line above footer
                            pdf.setDrawColor(236, 236, 236);
                            pdf.line(20, pdf.internal.pageSize.height - 90, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 90, 'S')

                            // footer
                            const footerLeft = `${PDFfooterLogo}`
                            const footerRight = `Page: ${pageCurrent} of ${pageCount}    Date downloaded ${date}/${month}/${year}`
                            pdf.text(footerRight, pdf.internal.pageSize.width - 300, pdf.internal.pageSize.height - 50);
                            pdf.addImage(footerLeft, 'png', 20, pdf.internal.pageSize.height - 80, 160, 45);
                        }

                        let file = new File([pdf.output('blob')], context.cmsValuesAndDriversContent.title + "-" + newDatetime.toLocaleString() + ".pdf", { type: "application/pdf" })
                        let formData = new FormData();
                        formData.append(file.name, file)
                        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
                        formData.append('OwnerId', candidateID);
                        formData.append('OwnerType', "Candidate");
                        MyDocumentsActions.UploadDocuments(formData).then(res => {
                            let docId;
                            res.data.map(doc => {
                                docId = doc.id
                            })
                            updateAssessmentStatus(docId)
                            setLoadSpinner(false);
                        }, err => {
                            console.log(err);
                            setLoadSpinner(false);
                        });
                    }
                })
            }
        });
    }

    const errorValidation = (res) => {
        // let essentialCategory = categories.find(filt => filt.AnswerText === "Essential");
        // let essentialList = res.filter(filt => filt.answerText === "Essential")
        let essentialCategory = categories.filter(filt => filt.AnswerText)[0];
        let essentialList = res.filter(filt => essentialCategory.AnswerId === filt.answerId);
        let categoryColumn = document.getElementById(essentialCategory.AnswerId)
        if (essentialList.length > 10) {
            categoryColumn.classList.add('error');
            setErrorMessage(true);
            setDisabled(true);
        } else {
            categoryColumn.classList.remove('error');
            setErrorMessage(false);
            setDisabled(false);
        }
    }

    const toggleHide = index => {
        setHidden({ ...hidden, [index]: !hidden[index] })
        setToggle(!toggle)
    }

    const toggleAll = () => {
        Object.keys(hidden).forEach(key => {
            hidden[key] = !toggle
        })
        setToggle(!toggle)
    }

    const exitMoveToModal = () => {
        setMoveToModal(false)
    }

    const moveToDropdown = (e) => {
        setQuestionAnswer({
            "QuestionId": questionAnswer.QuestionId,
            "AnswerId": e.target.value
        })
    }

    const submitMoveTo = (ev) => {
        setLoadSpinner(true);
        let payload = [{
            "AssessementId": context.cmsValuesAndDriversContent.internalAssessmentId,
            "AssessementType": "Internal",
            "CategoryType": null,
            "CategoryValue": null,
            "QuestionId": questionAnswer.QuestionId,
            "AnswerType": "id",
            "AnswerValue": questionAnswer.AnswerId
        }]
        AssessmentActions.SaveAssessment(payload).then(async (res) => {
            if (res.status === 200) {
                renderSavedAssessment();
            } else {
                console.log(res);
                setLoadSpinner(false);
                setMoveToModal(false)
            }
        }, err => {
            console.log(err);
            setLoadSpinner(false);
            setMoveToModal(false)
        })
    }

    const openMoveToModal = (questionId) => {
        setQuestionAnswer({
            "QuestionId": questionId,
        })
        setMoveToModal(true)
    }

    return (
        <>
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <div className="assessment-page VD-mobile-page">
                <Container className='pt-lg-4 pt-3 pb-4 VD-mobile_container'>
                    <Row className='justify-content-between'>
                        <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                            <Cirrus.Button isSecondary onClick={() => navigate("/" + pageOrgin.id + "/values-and-drivers/assessment")}>{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                        </Col>
                        <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                            {/* <div className='progress-wrapper'>
                                <Cirrus.Label className='progress-label'>Progress {percentage}%</Cirrus.Label>
                                <ProgressBar now={percentage} label={`${percentage}%`} visuallyHidden className="progressBar" />
                            </div> */}
                        </Col>
                        <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end">
                            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>{fetchCommonResourceLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                        </Col>
                    </Row>
                </Container>
                <Container className='assessment-review-result-wrapper p-lg-5 p-4 mb-5'>
                    {
                        loadSpinner &&
                        <div className='loader'>
                            <Cirrus.Spinner size={50} />
                        </div>
                    }
                    <Row className='mb-3'>
                        <Col lg={7} className='m-auto'>
                            <Cirrus.Header size='h4' className='mb-3'>{cmsAssessmentContent?.reviewPage?.Title}</Cirrus.Header>
                            <p>{cmsAssessmentContent?.reviewPage?.Description}</p>
                        </Col>
                    </Row>
                </Container>
                <Container className='mb-5 text-start'>
                    <Row className='mb-3'>
                        <Col>
                            <Cirrus.Button isSecondary onClick={() => { toggleAll() }}>
                                {toggle == false ? fetchCommonResourceLabel("CommonResourceComponent.OpenAll") : fetchCommonResourceLabel("CommonResourceComponent.CloseAll")}
                            </Cirrus.Button>
                        </Col>
                    </Row>
                    {
                        categories?.length && categories?.map((category, categoryIndex) => {
                            return (
                                <Row className='mb-1' key={categoryIndex}>
                                    <Col key={categoryIndex}>
                                        <div className='vd-accordion' id={category.AnswerId}>
                                            <div className='vd-toggleHeader' onClick={() => toggleHide(categoryIndex)}>
                                                <span>{category.AnswerText}</span>
                                                <i className='icon-button-arrow'></i>
                                            </div>

                                            {
                                                !!hidden[categoryIndex] &&
                                                <div className='vd-toggleBody'>
                                                    {
                                                        getResponse?.map((res, resIndex) => {
                                                            if (res.answerId === category.AnswerId) {
                                                                return (
                                                                    <div className='column-item' key={resIndex} id={res.questionId}
                                                                        assessmentid={getResponse.assessmentID}
                                                                    >
                                                                        <div>
                                                                            <OverlayTrigger
                                                                                trigger="click"
                                                                                key={'bottom'}
                                                                                placement={'bottom'}
                                                                                rootClose={'true'}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                                        <div>
                                                                                            {res.questionDescription}
                                                                                        </div>
                                                                                        <i className='icon-close' onClick={() => { document.body.click() }}></i>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <i className='icon-information'></i>
                                                                            </OverlayTrigger>
                                                                            <span>{res.questionText}</span>
                                                                        </div>
                                                                        <div className='edit-option' onClick={() => openMoveToModal(res.questionId)}>
                                                                            <i className='icon-edit-alt'></i>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }

                    {
                        moveToModal &&
                        <Cirrus.Modal
                            closeButtonProps={{
                                children: fetchCommonResourceLabel("CommonResourceComponent.Exit"),
                                isSecondary: true,
                                onClick: () => { exitMoveToModal() },
                                size: 'Small',
                                type: 'button'
                            }}
                            headerProps={{
                                children: fetchCommonResourceLabel("CommonResourceComponent.MoveTo"),
                                className: 'no-margin',
                                size: 'h5'
                            }}
                            isScrollable
                            role="dialog"
                            size="default"
                        >
                            <Cirrus.Select
                                id="category-default"
                                name="categories"
                                onChange={(e) => { moveToDropdown(e) }}
                                options={categoriesDropdown}
                                placeholder={fetchCommonResourceLabel("CommonResourceComponent.MoveTo")}
                                className='mb-3 categories-dropdown'
                            />
                            <div className='text-center'>
                                <Cirrus.Button onClick={() => { submitMoveTo() }}>{fetchCommonLabel("CommonComponent.CommonContinueButton")}</Cirrus.Button>
                            </div>
                        </Cirrus.Modal>
                    }

                    {
                        errorMessage && <Row>
                            <Col className='col-auto m-auto mb-4'>
                                <Cirrus.Alert
                                    ariaRole="error"
                                    bodyText={fetchCommonResourceLabel("CommonResourceComponent.ValidationMessage")}
                                    closeText="Close error"
                                    heading={<i className="bi bi-exclamation-triangle"></i>}
                                    onClose={() => { }}
                                    state="error"
                                    className='assessment-error'
                                />
                            </Col>
                        </Row>
                    }
                    <Row className='mt-5'>
                        <Col xs={6} sm={3} md={3} lg={3} className="m-auto">
                            <Cirrus.Button
                                children={fetchCommonLabel("CommonComponent.CommonCompleteButton")}
                                disabled={disabled}
                                isFullWidth
                                onClick={() => downloadPDF()}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div style={{ opacity: '0', width: '100%', minHeight: '10px', overflow: 'scroll', position: 'absolute' }}>
                <div id='assessment-result-wrapper' style={{ position: 'static', left: '0', bottom: '0', zIndex: '-1', display: 'block', width: '1140px' }}>
                    <VandDpdfTemplate categories={categories} getResponse={pdfResponse}></VandDpdfTemplate>
                </div>
            </div>
        </>
    )
}

export default VandDReviewPageMobile;
