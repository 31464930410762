//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from "manpowergroup-cirrus";
import { useEffect, useState, useContext } from "react";
import { React, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../common/context/Context";
import { AssessmentActions } from "../AssessmentActions";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { getSatelliteCountryCode } from "../../../common/helpers/CountryCode";
import "../style.css";
import sideimage from "./sideimage.png";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function PersonalityAssessment() {
  const context = useContext(Context);
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [screenLoader, setScreenLoader] = useState(false);
  const [cmsMasterContent, setCmsMasterContent] = useState({});
  const [assessmentStartPageData, setAssessmentStartPageData] = useState();
  const candidateID =
    context?.idTokenClaims?.signupId != undefined
      ? context?.idTokenClaims?.signupId
      : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
          "signupId"
        ];
  let path = JSON.parse(window.sessionStorage.getItem("setPath"));

  useEffect(() => {
    fetchCMSData();
    window.scrollTo(0, 0);
  }, [context.language]);

  const fetchCMSData = async () => {
    let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
    setScreenLoader(true);
    let payload = {
      PageName: "PersonalityAssessment",
    };
    await AssessmentActions.GetAssessmentCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          if (resData !== undefined) {
            refCmsMasterContent["id"] = resData?.ID;
            refCmsMasterContent["largeImage"] = resData?.LargeImage?.ImageUrl;
            refCmsMasterContent["mediumImage"] = resData?.MediumImage;
            refCmsMasterContent["smallImage"] = resData?.SmallImage;
            refCmsMasterContent["resourceType"] = resData?.ResourceType;
            refCmsMasterContent["resourceCategoryType"] =
              resData?.ResourceCategoryType;
            refCmsMasterContent["duration"] = resData?.Duration;
            refCmsMasterContent["focusArea"] = resData?.FocusArea[0];
            refCmsMasterContent["title"] = resData?.Title;
            refCmsMasterContent["subTitle"] = resData?.SubTitle;
            refCmsMasterContent["description"] = resData?.Description;
            refCmsMasterContent["shortDescription"] = resData?.ShortDescription;

            let label = [
              ...resData?.Components?.CommonResourceComponent?.Labels,
              ...resData?.Components?.CommonComponent?.Labels,
            ];
            resData?.Components?.CommonResourceComponent?.LOVList[0]?.Values?.map(
              (x) => {
                let obj = {
                  Key: x.Key,
                  Phrase: x.Value,
                };
                label.push(obj);
              }
            );
            resData?.Components?.CommonComponent?.LOVList[0]?.Values?.map(
              (x) => {
                let obj = {
                  Key: x.Key,
                  Phrase: x.Value,
                };
                label.push(obj);
              }
            );
            refCmsMasterContent["masterLabel"] = label;
            ActivityAction.SaveActivity(
              refCmsMasterContent?.id,
              "startassessment",
              refCmsMasterContent?.title
            );
            TrackActions.EventTrack(appInsights, "ViewCard", {
              "Entity Id": refCmsMasterContent?.id,
              "Entity Name": refCmsMasterContent?.title,
              CardType: "Assessment",
            });
            TrackActions.PageAction("ViewCard", {
              "Entity Id": refCmsMasterContent?.id,
              "Entity Name": refCmsMasterContent?.title,
              CardType: "Assessment",
            });
            setCmsMasterContent(refCmsMasterContent);
            context.setCmsPersonalityAssessment({
              masterCMS: resData,
              masterLabel: label,
              resultPage: resData?.ChildPages[0],
            });
            fetchStartPageData(refCmsMasterContent?.id);
          } else {
            setScreenLoader(false);
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const saveAndLikeHandler = async (type) => {
    let payload = {
      candidateId: candidateID,
      learningResourceId: cmsMasterContent?.id,
      learningResourceType: cmsMasterContent?.resourceCategoryType?.ID,
      learningResourceStatus: assessmentStartPageData?.learningResourceStatus,
      resourceTypeId: cmsMasterContent?.resourceType?.ID,
      isRetaken: assessmentStartPageData?.isRetaken,
      lastAnsweredPageId: assessmentStartPageData?.lastAnsweredPageId,
      isSaved:
        type === "save"
          ? assessmentStartPageData?.isSaved === true
            ? false
            : true
          : assessmentStartPageData?.isSaved,
      isLiked:
        type === "like"
          ? assessmentStartPageData?.isLiked === true
            ? false
            : true
          : assessmentStartPageData?.isLiked,
      isDownloaded: assessmentStartPageData?.isDownloaded,
      isSharedWithCoach: assessmentStartPageData?.isSharedWithCoach,
      resultDocumentId: assessmentStartPageData?.resultDocumentId,
    };
    await AssessmentActions.SaveAssessmentStatus(
      cmsMasterContent?.resourceCategoryType?.ID,
      cmsMasterContent?.id,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          fetchStartPageData(cmsMasterContent?.id);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchStartPageData = async (assID) => {
    let resourceType = "assessmentsandtasks";
    let payload = {
      learningResourceType: resourceType,
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: resourceType,
    };

    await AssessmentActions.GetStartPageData(payload).then(
      (res) => {
        if (res.status === 200) {
          let data = res?.data && res?.data?.responsemodel;
          let resData = data?.filter(
            (x) => x.key === "Tools.PersonalityAssessment"
          );
          setAssessmentStartPageData(resData[0]);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  // get cost center value
  const getCostCenter = () => {
    // satelliteCountryCode - this is for generic account users
    const satelliteCountryCode = getSatelliteCountryCode();

    // programSatelliteCountryCode - this is for RT reg.code users
    const programSatelliteCountryCode = JSON.parse(
      sessionStorage.getItem("setProgramDetails")
    )?.programSatelliteCountryCode;

    if (programSatelliteCountryCode) {
      return programSatelliteCountryCode;
    } else {
      return satelliteCountryCode;
    }
  };

  const startRetakHandler = async () => {
    if (
      assessmentStartPageData?.learningResourceStatus?.toLowerCase() ===
      fetchLabel("ResourceStatus.Completed").toLowerCase()
    ) {
      navigate(
        "/" + pageOrgin.id + "/personality-assessment/result" + "/savePDF=false"
      );
    } else {
      setScreenLoader(true);
      let isUrliGenerated = "";
      /* let urlPayload = {
                "cost_centre": JSON.parse(sessionStorage.getItem("setProgramDetails"))?.programCountryCode,
                "assessment_language": JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageISOCode']
            } */
      let urlPayload = {
        cost_centre: getCostCenter(),
        assessment_language: JSON.parse(
          window.sessionStorage.getItem("Language")
        )["selectedLanguageISOCode"],
      };
      await AssessmentActions.GeneratePersonalityAssessmentURL(urlPayload).then(
        (res) => {
          if (res.status === 200) {
            isUrliGenerated = res?.data?.assessment_url;
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );

      if (isUrliGenerated != "") {
        let payload = {
          candidateId: candidateID,
          learningResourceId: cmsMasterContent?.id,
          learningResourceStatus: "inprogress",
          learningResourceType: cmsMasterContent?.resourceCategoryType?.ID,
          resourceTypeId: cmsMasterContent?.resourceType?.ID,
          isSaved: assessmentStartPageData?.isSaved,
          isLiked: assessmentStartPageData?.isLiked,
          isRetaken: assessmentStartPageData?.isRetaken,
          lastAnsweredPageId: assessmentStartPageData?.lastAnsweredPageId,
          isDownloaded: assessmentStartPageData?.isDownloaded,
          isSharedWithCoach: assessmentStartPageData?.isSharedWithCoach,
          resultDocumentId: assessmentStartPageData?.resultDocumentId,
        };
        await AssessmentActions.SaveAssessmentStatus(
          cmsMasterContent?.resourceCategoryType?.ID,
          cmsMasterContent?.id,
          payload
        ).then(
          (res) => {
            if (res.status === 200) {
              TrackActions.EventTrack(
                appInsights,
                "StartPersonalityAssessment",
                { EventType: "Personality Assessment" }
              );
              TrackActions.PageAction("StartPersonalityAssessment", {
                EventType: "Personality Assessment",
              });
              setScreenLoader(false);
              window.open(isUrliGenerated, "_self");
            } else {
              setScreenLoader(false);
            }
          },
          (err) => {
            console.log(err);
            setScreenLoader(false);
          }
        );
      }
    }
  };

  const fetchLabel = (key) => {
    let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
    let label = "";
    if (refCmsMasterContent?.masterLabel) {
      refCmsMasterContent?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchButtonStatus = (status) => {
    if (status !== undefined && status !== "") {
      if (status?.toLowerCase() === "inprogress") {
        return <>{fetchLabel("CommonComponent.CommonContinueButton")}</>;
      } else if (status?.toLowerCase() === "completed") {
        return <>{fetchLabel("CommonResourceComponent.ViewResults")}</>;
      } else {
        return <>{fetchLabel("CommonComponent.CommonStartButton")}</>;
      }
    } else {
      return <>{fetchLabel("CommonComponent.CommonStartButton")}</>;
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="assessment-page">
      <Container
        fluid
        className="background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20"
      >
        <Row className="justify-content-between">
          <Col md="12" lg="7" className="mb-3 pb-lg-5">
            <Cirrus.Button
              isSecondary
              onClick={() => {
                navigate(`/${context.path ? context.path : path}`);
              }}
              className="mb-5"
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
            <Cirrus.Header size="h3" className="assessment-title">
              {cmsMasterContent?.title}
            </Cirrus.Header>
            <div className="save-section mt-4 mb-3">
              <button
                className="saveBtn"
                onClick={() => saveAndLikeHandler("save")}
              >
                {assessmentStartPageData?.isSaved === true ? (
                  <i className="icon-saved"></i>
                ) : (
                  <i className="icon-save"></i>
                )}
                <span className="saveBtnText">
                  {assessmentStartPageData?.isSaved === true
                    ? fetchLabel("CommonResourceComponent.Saved")
                    : fetchLabel("CommonComponent.CommonSaveButton")}
                </span>
              </button>
              <Cirrus.Label id="category-label">
                {cmsMasterContent?.focusArea?.Value}
              </Cirrus.Label>
              <Cirrus.Label id="cardtype">
                {/* {fetchStatus(assessmentStartPageData?.learningResourceStatus)} */}
                {cmsMasterContent?.resourceType?.Value} <sup>. </sup>
                <span>{cmsMasterContent?.duration}</span>
              </Cirrus.Label>
            </div>
            <div>
              <Cirrus.Button
                size="small"
                className="likeBtn"
                onClick={() => {
                  saveAndLikeHandler("like");
                }}
              >
                {assessmentStartPageData?.isLiked === true ? (
                  <Icon icon="heart_fill" size={16} color="#9D323D" />
                ) : (
                  <Icon icon="heart" size={16} color="#000" />
                )}
                <span className="likeBtnText">
                  {assessmentStartPageData?.likeCount === 0
                    ? " " +
                      fetchLabel("CommonComponent.CommonLikeText").toUpperCase()
                    : assessmentStartPageData?.likeCount === 1
                    ? assessmentStartPageData?.likeCount +
                      " " +
                      fetchLabel("CommonComponent.CommonLikeText").toUpperCase()
                    : assessmentStartPageData?.likeCount +
                      " " +
                      fetchLabel(
                        "CommonComponent.CommonLikesText"
                      ).toUpperCase()}
                </span>
              </Cirrus.Button>
            </div>
          </Col>
          <Col md="12" lg="5" className="position-relative">
            <div className="assesment_startpage-image-wrapper">
              <img
                src={cmsMasterContent?.largeImage}
                alt="sideimage"
                className="image-overflow"
              />
            </div>
            {/* <img src={sideimage} alt="sideimage" className="image-overflow" /> */}
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-lg-5 p-3 pb-0">
        <Row>
          <Col md="12" lg="6" className="assessment_second-container-intro">
            <Cirrus.Headline
              size="Medium"
              className="mb-5 assessment_introHeading"
            >
              {cmsMasterContent?.shortDescription}
            </Cirrus.Headline>
            <Cirrus.Header size="h5" className="mb-3">
              {cmsMasterContent?.subTitle}
            </Cirrus.Header>
            <p className="mb-3">
              <Cirrus.RichText
                content={cmsMasterContent?.description}
                className="instructions"
              />
            </p>

            <Cirrus.Button className="my-4" onClick={startRetakHandler}>
              {fetchButtonStatus(
                assessmentStartPageData?.learningResourceStatus
              )}
            </Cirrus.Button>
          </Col>
          <Col md="12" lg="6"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default PersonalityAssessment;
