export const GlobalConstants = {
    defaultCountryLanguage: {
        country: 'US',
        language: 'en-US'
    },
    supportedCountryLanguage: [
        // wave 1
        { country: 'US', language: 'en-US', browserCode: 'US' }, // United state
        { country: 'CA', language: 'fr-CA', browserCode: 'CA' }, // Canada (Optional Language-'en-CA')


        // wave 2
        { country: 'FR', language: 'fr-FR', browserCode: 'FR' }, // France (Optional Language-'en-GB')
        { country: 'AU', language: 'en-AU', browserCode: 'AU' }, // Australia
        { country: 'UK', language: 'en-GB', browserCode: 'GB' }, // United kingdom


        // Wave 3.1
        { country: 'NL', language: 'nl-NL', browserCode: 'NL' }, // Netherland (Optional Language-'en-GB')
        { country: 'ES', language: 'es-ES', browserCode: 'ES' }, // Spain (Optional Language-'en-GB')
        { country: 'IN', language: 'en-GB', browserCode: 'IN' }, // India
        { country: 'BE', language: 'fr-BE', browserCode: 'BE' }, // Belgium (Optional Language-'nl-BE','en-GB')


        // Wave 3.2
        { country: 'DE', language: 'de-DE', browserCode: 'DE' }, // Germany (Optional Language-'en-GB')
        { country: 'JP', language: 'ja-JP', browserCode: 'JA' }, // Japan (Optional Language-'en-GB')
        { country: 'IT', language: 'it-IT', browserCode: 'IT' }, // Italy (Optional Language-'en-GB')
        { country: 'CH', language: 'fr-CH', browserCode: 'CH' }, // Switzerland (Optional Language-'de-CH','en-GB')


        // Wave 4.1
        { country: 'CZ', language: 'en-GB', browserCode: 'CS' }, // Czech Republic
        { country: 'PL', language: 'en-GB', browserCode: 'PL' }, // Poland (Optional Language-'pl-PL')
        { country: 'IE', language: 'en-GB', browserCode: 'IE' }, // Ireland
        { country: 'ZA', language: 'en-GB', browserCode: 'ZA' }, // South Africa


        // Wave 4.2
        { country: 'BR', language: 'pt-BR', browserCode: 'BR' }, // Brazil (Optional Language-'en-GB')
        { country: 'IL', language: 'en-GB', browserCode: 'HE' }, // Israel

    
        // Wave 5.1
        { country: 'KR', language: 'ko-KR', browserCode: 'KO' }, // Korea (Optional Language-'en-GB')
        { country: 'GR', language: 'en-GB', browserCode: 'EL' }, // Greece
        { country: 'NO', language: 'nb-NO', browserCode: 'NB' }, // Norway (Optional Language-'en-GB')
        { country: 'SE', language: 'sv-SE', browserCode: 'SV' }, // Sweden (Optional Language-'en-GB')
        { country: 'DK', language: 'da-DK', browserCode: 'DA' }, // Denmark (Optional Language-'en-GB')
        { country: 'FI', language: 'fi-FI', browserCode: 'FI' }, // Finland (Optional Language-'en-GB') 

        /* **********
        Start LATAM-ES (Mexico,Argentina,Chile,Colombia,Paraguay,Peru,Uruguay,Costa Rica,El Salvador,Guatemala,Honduras,Nicaragua,Panama)
        ********** */
        { country: 'LE', language: 'es-MX', browserCode: 'MX' }, // Mexico (Optional Language-'en-GB') 
        { country: 'LE', language: 'es-MX', browserCode: 'AR' }, // Argentina (Optional Language-'en-GB'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: 'CL' }, // Chile (Optional Language-'en-GB')
        { country: 'LE', language: 'es-MX', browserCode: 'CO' }, // Colombia (Optional Language-'en-GB')
        // { country: 'LE', language: 'es-MX', browserCode: '' }, // Paraguay - There is no browser lang for this country. We are considering (browserCode: '419'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: 'PE' }, // Peru (Optional Language-'en-GB'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: 'UY' }, // Uruguay (Optional Language-'en-GB'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: 'CR' }, // Costa Rica (Optional Language-'en-GB'), There is no en-BG in oneTrust
        // { country: 'LE', language: 'es-MX', browserCode: '' }, // El Salvador - There is no browser lang for this country. We are considering (browserCode: '419'), There is no en-BG in oneTrust
        // { country: 'LE', language: 'es-MX', browserCode: '' }, // Guatemala - There is no browser lang for this country. We are considering (browserCode: '419'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: 'HN' }, // Honduras (Optional Language-'en-GB'), There is no en-BG in oneTrust
        // { country: 'LE', language: 'es-MX', browserCode: '' }, // Nicaragua - There is no browser lang for this country. We are considering (browserCode: '419'), There is no en-BG in oneTrust
        // { country: 'LE', language: 'es-MX', browserCode: '' }, // Panama - There is no browser lang for this country. We are considering (browserCode: '419'), There is no en-BG in oneTrust
        { country: 'LE', language: 'es-MX', browserCode: '419' }, // If user selected there browser lang as Spanish(Latin America)
        // ********** End LATAM-ES ********** //

        // Wave 5.2 APME
        // { country: 'AP', language: 'en-GB', browserCode: 'EN' }, // Singapore - There is no browser lang for this country.
        { country: 'AP', language: 'en-GB', browserCode: 'ID' }, // Indonesia
        // { country: 'AP', language: 'en-GB', browserCode: 'PH' }, // Philippines - There is no browser lang for this country.
         { country: 'AP', language: 'en-GB', browserCode: 'TH' }, // Thailand
        // { country: 'AP', language: 'en-GB', browserCode: 'KM' }, // Cambodia - There is no browser lang for this country.
        // { country: 'AP', language: 'en-GB', browserCode: 'MY' }, // Myanmar - There is no browser lang for this country.
        { country: 'AP', language: 'en-GB', browserCode: 'LO' }, // Laos
        { country: 'AP', language: 'en-GB', browserCode: 'MS' }, // Malaysia
        { country: 'AP', language: 'en-GB', browserCode: 'VI' }, // Vietnam
        { country: 'AP', language: 'en-GB', browserCode: 'AR' }, // UAE
        { country: 'AP', language: 'en-GB', browserCode: 'AR' }, // Qatar
        { country: 'AP', language: 'en-GB', browserCode: 'UR' }, // Pakistan
        { country: 'AP', language: 'en-GB', browserCode: 'AR' }, // Saudi Arabia
        { country: 'AP', language: 'en-GB', browserCode: 'AR' }, // Bahrain
    ],
    pageName: {
        personalityAssessment: "PersonalityAssessment",
    }
}