//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
import { HttpResponse } from '@microsoft/signalr';
const http = new httpHelper();

export const OnBoardingActions = {
    ReferenceCode: referenceCode,
    CandidateProgramDetails: candidateProgramDetails,
    SaveCandisdateProfile: saveCandisdateProfile,
    SaveUserConsent: saveUserConsent,
    saveCandidatePreferences: saveCandidatePreferences,
    getCandidatePreferences: getCandidatePreferences,
    updateCandidatePreferences: updateCandidatePreferences,
    GetOnBoardingCMSContent: getOnBoardingCMSContent,
    GetOnBoardingStatus: getOnBoardingStatus,
    SaveCandidateLoginSession: saveCandidateLoginSession,
    TKcityList: TKcityList,
    GetcandidateProfile: GetcandidateProfile,
    MasterDetailsCMS: masterDetailsCMS,
    CandidateOnboarding: candidateOnboarding,
    MasterTimezoneData: masterTimezoneData,
    GetOnboardingQuestions: getOnboardingQuestions,
    GetOnboardingQuestionsList: getOnboardingQuestionsList,
    GetFeaturesConfig: getFeaturesConfig,
    GetProgramLanguage: getProgramLanguage
}

function referenceCode(payload) {
    return http.post(environments.VALIDATE_REGISTRATION_CODE, payload);
}
function candidateProgramDetails(email) {
    let relpaceEmail = environments.GET_CANDIDATE_PROGRAM_DETAILS.replace('{emailid}', email);
    return http.get(relpaceEmail);
}
function saveCandisdateProfile(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_PROFILE.replace('{id}', id);
    return http.put(relpaceId, payload);
}

function saveUserConsent(id, payload) {
    let userConsent = environments.CANDIDATE_CONSENT.replace('{candidateId}', id);
    return http.post(userConsent, payload);
}

function getOnBoardingCMSContent(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function saveCandidatePreferences(id, payload) {
    let savePreferences = environments.SAVE_CANDIDATE_PREFERENCES.replace('{id}', id);
    return http.post(savePreferences, payload);
}
function getCandidatePreferences(id) {
    let getPreferences = environments.GET_CANDIDATE_PREFERENCES.replace('{id}', id);
    return http.get(getPreferences);
}
function updateCandidatePreferences(id) {
    let updateCandidatePreferences = environments.UPDATE_PREFERENCES_COMPLETED.replace('{id}', id);
    return http.put(updateCandidatePreferences);
}
function getOnBoardingStatus(candidateId) {
    let getOnBoarding = environments.GET_ON_BOARDING_STATUS.replace('{candidateId}', candidateId);
    return http.get(getOnBoarding);
}

function saveCandidateLoginSession(payload) {
    let savePreferences = environments.GET_LAST_SESSION;
    return http.post(savePreferences, payload);
}

function TKcityList(payload) {
    return http.textCernelPost(environments.LOCATION_AUTOCOMPLETE, payload);
}

function GetcandidateProfile(id) {
    let candidateProfielReq = environments.GET_CANDIDATE_PROFILE.replace('{id}', id);
    return http.get(candidateProfielReq);
}

function masterDetailsCMS(payload) {
    return http.CMSpost(environments.MASTER_CMS_DATA, payload);
}

function candidateOnboarding(data) {
    return http.put(environments.CANDIDATE_ONBOARDING, data)
}

function masterTimezoneData(key) {
    const userLang = JSON.parse(window.sessionStorage.getItem("Language"))?.selectedLanguageISOCode ? JSON.parse(window.sessionStorage.getItem("Language"))?.selectedLanguageISOCode : JSON.parse(window.sessionStorage.getItem("setProfileDetails"))['preferredLanguageCode'];
    return http.getCountryList(environments.TIMEZONE_MASTER_DATA.replace('{locale}', userLang).replace('{timezone}', key))
}

function getOnboardingQuestions(key, payload) {
    const userLang = JSON.parse(window.sessionStorage.getItem("Language"))?.selectedLanguageISOCode ? JSON.parse(window.sessionStorage.getItem("Language"))?.selectedLanguageISOCode : JSON.parse(window.sessionStorage.getItem("setProfileDetails"))['preferredLanguageCode'];
    let onBoardingUrl = environments.ONBOARDING_AUTOCOMPLETE_QUESTIONS.replace('{locale}', userLang).replace('{key}', key)
    return http.OnBoardingQuestions(onBoardingUrl, payload);
}
function getOnboardingQuestionsList(payload) {
    return http.OnBoardingQuestions(environments.GET_ONBOARDING_AUTOCOMPLETE_QUESTIONS_LIST, payload);
}

function getFeaturesConfig() {
    return http.get(environments.GET_FEATURES_CONFIG);
}

function getProgramLanguage(refCode) {
    return http.get(environments.GET_PROGRAM_LANGUAGE.replace('{registrationcode}', refCode))
}