//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';


function SkillStartPage() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const pageOrgin = useParams();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsContent, setCmsContent] = useState({});
    const [assessmentStatus, setAssessmentStatus] = useState({
        Parrent: {},
        Skills: {},
        Interests: {}
    })
    const [saveQueInDb, setSaveQueInDb] = useState([])

    useEffect(() => {
        fetchCMSData()
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        setScreenLoader(true);
        let resData = context.cmsStrengthAnalysis;
        if (Object.keys(resData).length !== 0) {
            setCmsContent(resData)
            let refAssessmentStatus = JSON.parse(JSON.stringify(assessmentStatus));
            await AssessmentActions.GetStrengthsAnalysisAssessmentStatusById(resData.masterCMS.ID).then(
                (res) => {
                    if (res.status === 200) {
                        let parent = res?.data.filter((status) => status.subLearningResourceId === undefined)
                        refAssessmentStatus.Parrent = parent[0];
                        resData?.assIds?.map((x) => {
                            res?.data.map((status) => {
                                if (x.id === status.subLearningResourceId) {
                                    refAssessmentStatus[`${x.name}`] = status;
                                }
                            })
                        })
                        setAssessmentStatus(refAssessmentStatus)
                        setScreenLoader(false);
                    } else {
                        setScreenLoader(false);
                    }
                },
                (err) => {
                    console.log(err);
                    setScreenLoader(false);
                }
            );

            resData?.assessmentSkills?.Question?.map((resourcePages) => {
                resourcePages?.map((refineSet) => {
                    saveQueInDb.push({
                        "SubAssessmentId": resData?.assessmentSkills?.ID,
                        "AssessementType": "Internal",
                        "CategoryValue": refineSet.Skills.Value,
                        "QuestionId": refineSet.Skills.ID,
                        "AnswerType": "Id",
                        "AnswerValue": 3
                    })
                })
            })
            isDefaultValueSetInDb(resData?.masterCMS?.ID, resData?.assessmentSkills?.ID)
        }
    }

    const isDefaultValueSetInDb = async (AssessmentID, subAssId) => {
        await AssessmentActions.GetStrengthsAnalysisResult(AssessmentID).then(resp => {
            if (resp.status === 200) {
                let saveApiCall = false;

                if (resp.data.length) {
                    let isDataSaved = resp.data.filter((item) => item.subAssessmentId === subAssId)
                    if (isDataSaved.length === 0) {
                        saveApiCall = true;
                    } else {
                        if (isDataSaved[0].response.length === 0) {
                            saveApiCall = true;
                        }
                    }
                }

                if (saveApiCall) {
                    AssessmentActions.StrengthsAnalysisRefiningYourInterestSkills(AssessmentID, saveQueInDb).then(res => {
                        if (res.status === 200) {
                            saveApiCall = false;
                        }
                    }, err => {
                        console.log(err);
                    })
                }
            }
        }, err => {
            console.log(err);
        })
    }

    const fetchButtonStatus = (status) => {
        if (status !== undefined && status !== '') {
            if (status?.toLowerCase() === 'inprogress') {
                return (
                    <>
                        {fetchLabel("CommonComponent.CommonContinueButton")}
                    </>
                )
            } else if (status?.toLowerCase() === 'completed') {
                return (
                    <>
                        {fetchLabel("CommonComponentCommonRetakeButton")}
                    </>
                )
            } else {
                return (
                    <>
                        {fetchLabel("CommonComponent.CommonStartButton")}
                    </>
                )
            }
        } else {
            return (
                <>
                    {fetchLabel("CommonComponent.CommonStartButton")}
                </>
            )
        }
    }

    const fetchLabel = (key) => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let label = '';
        if (Object.keys(refCmsContent).length !== 0) {
            refCmsContent?.masterLabel?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                backgroundShape="singleDesktop"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}
            <Container className='pt-lg-4 pt-3 pb-lg-4 pb-3'>
                <Row className='justify-content-between'>
                    <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
                        <Cirrus.Button isSecondary onClick={(e) => navigate('/' + pageOrgin.id + '/strength-analysis')}>{fetchLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                    </Col>
                    <Col sm={12} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} xs={{ order: 3 }} className='d-flex'>
                    </Col>
                    <Col md={2} lg={2} xs={{ span: 6, order: 2 }} className="text-end" onClick={() => navigate("/" + pageOrgin.id)}>
                        <Cirrus.Button>{fetchLabel("CommonResourceComponent.Exit")}</Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            <Container className='assessment-review-result-wrapper assessment-result-wrapper text-start mb-lg-5 mb-3 p-lg-5'>
                <Row>
                    <Col lg={10} className='pt-5 pl-5 pb-4 m-auto'>
                        <Cirrus.Header size='h4' className='pb-1'>{cmsContent?.assessmentSkills?.Title}</Cirrus.Header>
                        <div className='save-section'>
                            <Cirrus.Label id='category-label'>{cmsContent?.assessmentSkills?.FocusArea[0]?.Value}</Cirrus.Label>
                            <Cirrus.Label id="category-label">{cmsContent?.assessmentSkills?.ResourceType?.Value}</Cirrus.Label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={10} className='pl-5 pt-2 m-auto strength_skillstart'>
                        <Cirrus.Headline size='Small'>{cmsContent?.assessmentSkills?.SubTitle}:</Cirrus.Headline>
                        <p>
                            <Cirrus.RichText content={cmsContent?.assessmentSkills?.Description} />
                        </p>
                        <Cirrus.Button
                            className='mt-3 my-5 w-25'
                            onClick={() => { navigate('/' + pageOrgin.id + '/strength-analysis/skill-question') }}
                        >
                            {fetchButtonStatus(assessmentStatus?.Skills?.subLearningResourceStatus)}
                        </Cirrus.Button>
                    </Col>
                </Row>
            </Container>
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default SkillStartPage;
