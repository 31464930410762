//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const AssessmentActions = {
    GetAssessmentCMS: getAssessmentCMS,
    SaveAssessment: saveAssessment,
    GetAllAssessmentsStatus: getAllAssessmentsStatus,
    GetAssessmentStatusById: getAssessmentStatusById,
    GetSavedResponseByAssessmentId: getSavedResponseByAssessmentId,
    GetAssessmentStatusAndSavedResponseById: getAssessmentStatusAndSavedResponseById,
    RetakeAssessment: retakeAssessment,
    SaveAssessmentStatus: saveAssessmentStatus,
    SaveAssessmentCard: saveAssessmentCard,
    AssessmentDetails: assessmentDetails,
    GetStartPageData: getStartPageData,
    SaveCareerPath: saveCareerPath,
    DeleteCareerPath: deleteCareerPath,
    GetAllCareerpath: getAllCareerpath,
    AddNewFactor: addNewFactor,
    GetAllNewFactors: getAllNewFactors,
    SaveCareerFactors: saveCareerFactors,
    GetAllCareerPathWithFactors: getAllCareerPathWithFactors,
    GetAssessmentHistory: getAssessmentHistory,
    RetakeCareerAssessment: retakeCareerAssessment,
    StrengthsAnalysisAssessmentEntryInDb: strengthsAnalysisAssessmentEntryInDb,
    StrengthsAnalysisLikeAndSave: strengthsAnalysisLikeAndSave,
    GetStrengthsAnalysisAssessmentStatusById: getStrengthsAnalysisAssessmentStatusById,
    StrengthsAnalysisRetakeAssessment: strengthsAnalysisRetakeAssessment,
    StrengthsAnalysisUpdateAssessmentStatus: strengthsAnalysisUpdateAssessmentStatus,
    StrengthsAnalysisUpdateIdentifyYourInterest: strengthsAnalysisUpdateIdentifyYourInterest,
    GetStrengthsAnalysisIdentifyYourInterest: getStrengthsAnalysisIdentifyYourInterest,
    StrengthsAnalysisRefiningYourInterestSkills: strengthsAnalysisRefiningYourInterestSkills,
    GetStrengthsAnalysisResult: getStrengthsAnalysisResult,
    StrengthsAnalysisSkillsMatrix: strengthsAnalysisSkillsMatrix,
    GetEJOAllSavedJob: getEJOAllSavedJob,
    PostEJOSaveUpdateJobOffer: postEJOSaveUpdateJobOffer,
    GetEJOSaveUpdateJobOffer: getEJOSaveUpdateJobOffer,
    EJORetakeAssessment: EJORetakeAssessment,
    ShareResultWithCoach: shareResultWithCoach,
    SaveJobRole: saveJobRole,
    GetJobRole: getJobRole,
    RetakePrepareInterview: retakePrepareInterview,
    GeneratePersonalityAssessmentURL: generatePersonalityAssessmentURL,
    CreateActivity:createActivity,
    GetCreatedActivity:getCreatedActivity,
    PersonalityAssessmentDocumentDownload: personalityAssessmentDocumentDownload
}

function getAssessmentCMS(payload) {
    return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function getStartPageData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function saveAssessment(payload) {
    return http.AssessmentsPost(environments.SAVE_ASSESSMENTS, payload);
}

function getAllAssessmentsStatus() {
    return http.AssessmentsGet(environments.GET_ALL_ASSESSMENTS);
}

function getAssessmentStatusById(assessmentId) {
    return http.AssessmentsGet(environments.GET_ASSESSMENT_BY_ASSESSMENT_ID.replace('{id}', assessmentId));
}

function getSavedResponseByAssessmentId(assessmentId) {
    return http.AssessmentsGet(environments.GET_ASSESSMENT_RESPONSES_BY_ASSESSMENT_ID.replace('{id}', assessmentId));
}

function getAssessmentStatusAndSavedResponseById(assessmentId) {
    return http.AssessmentsGet(environments.GET_ASSESSMENT_AND_RESPONSES_BY_ASSESSMENT_ID.replace('{id}', assessmentId));
}

function saveAssessmentStatus(resourceType, id, payload) {
    return http.AssessmentsPut(environments.SAVE_ASSESSMENT_CARD.replace('{resourcetype}', resourceType).replace('{id}', id), payload);
}

function saveAssessmentCard(resourceType, id, payload) {
    let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
    return http.learningResourcePut(url, payload);
}

function assessmentResults(id) {
    let assessmentResults = environments.GET_ASSESSMENT_RESPONSES_BY_ASSESSMENT_ID.replace('{id}', id);
    return http.learningResourceGet(assessmentResults);
}

function assessmentDetails(id) {
    let assessmentResults = environments.GET_ASSESSMENT_BY_ASSESSMENT_ID.replace('{id}', id);
    return http.learningResourceGet(assessmentResults);
}

function retakeAssessment(id) {
    let assessmentResults = environments.RETAKE_ASSESSMENT_BY_ID.replace('{id}', id);
    return http.AssessmentsDelete(assessmentResults);
}

function saveCareerPath(payload, assessmentId) {
    return http.AssessmentsPost(environments.LOOKING_AT_OPTIONS_CAREER_PATH.replace('{id}', assessmentId), payload);
}

function getAllCareerpath(assessmentId) {
    return http.AssessmentsGet(environments.LOOKING_AT_OPTIONS_CAREER_PATH.replace('{id}', assessmentId));
}

function addNewFactor(payload, assessmentId) {
    return http.AssessmentsPost(environments.LOOKING_AT_OPTIONS_CAREER_FACTORS.replace('{id}', assessmentId), payload);
}

function getAllNewFactors(assessmentId) {
    return http.AssessmentsGet(environments.LOOKING_AT_OPTIONS_CAREER_FACTORS.replace('{id}', assessmentId));
}

function saveCareerFactors(payload, assessmentId) {
    return http.AssessmentsPost(environments.LOOKING_AT_OPTIONS_CAREER_FACTORS_SAVE_UPDATE.replace('{id}', assessmentId), payload);
}

function getAllCareerPathWithFactors(assessmentId) {
    return http.AssessmentsGet(environments.LOOKING_AT_OPTIONS_CAREER_WITH_FACTORS.replace('{id}', assessmentId));
}

function deleteCareerPath(careerPathId, assessmentId) {
    return http.AssessmentsDelete(environments.LOOKING_AT_OPTIONS_CAREER_PATH_DELETE.replace('{id}', assessmentId).replace('{careerpathid}', careerPathId));
}

function getAssessmentHistory(assessmentId) {
    return http.AssessmentsGet(environments.LOOKING_AT_OPTIONS_HISTORY.replace('{id}', assessmentId));
}

function retakeCareerAssessment(assessmentId) {
    return http.AssessmentsDelete(environments.LOOKING_AT_OPTIONS_RETAKE.replace('{id}', assessmentId));
}

// Strengths Analysis
function strengthsAnalysisAssessmentEntryInDb(id, payload) {
    let url = environments.POST_STRENGTHS_ANALYSIS_ASSESSMENT_ENTRY_IN_DB.replace('{assessmentid}', id);
    return http.AssessmentsPost(url, payload);
}

function strengthsAnalysisLikeAndSave(id, payload) {
    let url = environments.PUT_STRENGTHS_ANALYSIS_LIKE_AND_SAVE.replace('{assessmentId}', id);
    return http.AssessmentsPut(url, payload);
}

function getStrengthsAnalysisAssessmentStatusById(id) {
    let url = environments.GET_STRENGTHS_ANALYSIS_ASSESSMENT_STATUS_BY_ID.replace('{assessmentId}', id);
    return http.AssessmentsGet(url);
}

function strengthsAnalysisRetakeAssessment(id) {
    let url = environments.DELETE_STRENGTHS_ANALYSIS_RETAKE_ASSESSMENT.replace('{id}', id);
    return http.AssessmentsDelete(url);
}

function strengthsAnalysisUpdateAssessmentStatus(id, payload) {
    let url = environments.PUT_STRENGTHS_ANALYSIS_UPDATE_ASSESSMENT_STATUS.replace('{assessmentId}', id);
    return http.AssessmentsPut(url, payload);
}

function strengthsAnalysisUpdateIdentifyYourInterest(id, payload) {
    let url = environments.PUT_STRENGTHS_ANALYSIS_IDENTIFY_YOUR_INTEREST.replace('{assessmentId}', id);
    return http.AssessmentsPut(url, payload);
}

function getStrengthsAnalysisIdentifyYourInterest(id) {
    let url = environments.GET_STRENGTHS_ANALYSIS_IDENTIFY_YOUR_INTEREST.replace('{assessmentid}', id);
    return http.AssessmentsGet(url);
}

function strengthsAnalysisRefiningYourInterestSkills(id, payload) {
    let url = environments.POST_STRENGTHS_ANALYSIS_REFINING_YOUR_INTEREST_SKILLS.replace('{assessmentId}', id);
    return http.AssessmentsPost(url, payload);
}

function getStrengthsAnalysisResult(id) {
    let url = environments.GET_STRENGTHS_ANALYSIS_RESULT.replace('{assessmentid}', id);
    return http.AssessmentsGet(url);
}

function strengthsAnalysisSkillsMatrix(id) {
    let url = environments.GET_STRENGTHS_ANALYSIS_SKILLS_MATRIX.replace('{assessmentid}', id);
    return http.AssessmentsGet(url);
}

function getEJOAllSavedJob() {
    return http.jobGet(environments.GET_APPLIED_JOBS);
}

function postEJOSaveUpdateJobOffer(id, payload) {
    let url = environments.POST_SAVE_UPDATE_JOB_OFFER.replace('{assessmentId}', id);
    return http.AssessmentsPost(url, payload);
}

function getEJOSaveUpdateJobOffer(id) {
    let url = environments.POST_SAVE_UPDATE_JOB_OFFER.replace('{assessmentId}', id);
    return http.AssessmentsGet(url);
}

function EJORetakeAssessment(id) {
    let url = environments.EVALUATE_JOB_RETAKE.replace('{assessmentId}', id);
    return http.AssessmentsDelete(url);
}

function shareResultWithCoach(payload) {
    return http.put(environments.SHARE_RESULT_WITH_COACH, payload);
}

function saveJobRole(payload, assessmentId) {
    return http.AssessmentsPost(environments.JOBROLE.replace('{assessmentId}', assessmentId), payload)
}

function getJobRole(assessmentId) {
    return http.AssessmentsGet(environments.JOBROLE.replace('{assessmentId}', assessmentId))
}

function retakePrepareInterview(assessmentId) {
    return http.AssessmentsDelete(environments.RETAKE_PREPARE_INTERVIEW.replace('{assessmentId}', assessmentId));
}

function generatePersonalityAssessmentURL(payload) {
    return http.AssessmentsPost(environments.GENERATE_PERSONALITY_ASSESSMENT_URL, payload);
}

function createActivity(payload) {
    return http.post(environments.CreateCandidateActivities, payload);
}

function getCreatedActivity() {
    const id = `CandidateId=${JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"]}`
    // console.log(id)
    return http.get(environments.CandidateActivities.replace("{payload}",id))
}

function personalityAssessmentDocumentDownload() {
    return http.AssessmentsGet(environments.PERSONALITY_ASSESSMENT_DOCUMENT_DOWNLOAD);
}
