//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import * as Cirrus from "manpowergroup-cirrus";
import { useEffect, useState, useContext } from "react";
import { React, useNavigate, useParams, useLocation } from "react-router-dom";
import Moment from "moment";
import { Context } from "../../../common/context/Context";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { AssessmentActions } from "../AssessmentActions";
import { coachActions } from "../../coach/coachActions";
import { MyDocumentsActions } from "../../myDocuments/MyDocumentsActions";
import "../style.css";
import { ActivityAction } from "../../../common/utility/ActivityActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";

function ResultPage() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const location = useLocation();
  const [screenLoader, setScreenLoader] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cmsMasterContent, setCmsMasterContent] = useState({});
  const [assessmentStartPageData, setAssessmentStartPageData] = useState();
  const [embedFile, setEmbedFile] = useState();
  const [embedFileType, setEmbedFileType] = useState();
  const [fileNotAvailable, setFileNotAvailable] = useState(false);
  const [isFileAvailable, setIsFileAvailable] = useState(true);
  const [coachdata, setcoachdata] = useState({});
  const [blobStore, setBlobStore] = useState();
  const [uploadedDocId, setUploadedDocId] = useState();
  const candidateID =
    context?.idTokenClaims?.signupId != undefined
      ? context?.idTokenClaims?.signupId
      : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
          "signupId"
        ];
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (location.search !== "") {
      navigate("/" + pageOrgin.id + "/personality-assessment");
      return;
    }
    window.scrollTo(0, 0);
    fetchCMSData();
    getMyCoach();
    viewHandler();
    TrackActions.PageViewRecord(appInsights, "AssessmentResultPage");
    TrackActions.PageAction("AssessmentResultPage");
  }, [context.language]);

  const fetchCMSData = async () => {
    let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
    setScreenLoader(true);
    let startPageDataFlag = false;
    let updateAssessmentStatusFlag = false;
    let startPageResponse;

    let payload = {
      PageName: "PersonalityAssessment",
    };
    await AssessmentActions.GetAssessmentCMS(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          if (resData !== undefined) {
            refCmsMasterContent["id"] = resData?.ID;
            refCmsMasterContent["assessmentTitle"] = resData?.Title;
            refCmsMasterContent["resourceType"] = resData?.ResourceType;
            refCmsMasterContent["resourceCategoryType"] =
              resData?.ResourceCategoryType;
            refCmsMasterContent["title"] = resData?.ChildPages[0]?.Title;
            refCmsMasterContent["description"] =
              resData?.ChildPages[0]?.Description;

            let label = [
              ...resData?.Components?.CommonResourceComponent?.Labels,
              ...resData?.Components?.CommonComponent?.Labels,
            ];
            resData?.Components?.CommonResourceComponent?.LOVList[0]?.Values?.map(
              (x) => {
                let obj = {
                  Key: x.Key,
                  Phrase: x.Value,
                };
                label.push(obj);
              }
            );
            refCmsMasterContent["masterLabel"] = label;

            ActivityAction.SaveActivity(
              refCmsMasterContent?.id,
              "completedassesment",
              resData?.Title
            );
            setCmsMasterContent(refCmsMasterContent);
            startPageDataFlag = true;

            setScreenLoader(false);
          } else {
            setScreenLoader(false);
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );

    if (startPageDataFlag) {
      startPageResponse = await AssessmentActions.GetAssessmentStatusById(
        refCmsMasterContent.id
      );
      setAssessmentStartPageData(startPageResponse?.data);
      updateAssessmentStatusFlag = true;
    }

    if (
      updateAssessmentStatusFlag &&
      startPageResponse?.data?.learningResourceStatus === "inprogress"
    ) {
      let payload = {
        candidateId: candidateID,
        learningResourceId: refCmsMasterContent?.id,
        learningResourceStatus: "completed",
        learningResourceType: refCmsMasterContent?.resourceCategoryType?.ID,
        resourceTypeId: refCmsMasterContent?.resourceType?.ID,
        isSaved: startPageResponse?.data?.isSaved,
        isLiked: startPageResponse?.data?.isLiked,
        isRetaken: startPageResponse?.data?.isRetaken,
        lastAnsweredPageId: startPageResponse?.data?.lastAnsweredPageId,
        isDownloaded: startPageResponse?.isDownloaded,
        isSharedWithCoach: startPageResponse?.isSharedWithCoach,
        resultDocumentId: startPageResponse?.resultDocumentId,
      };
      saveAssessmentStatus(
        refCmsMasterContent?.resourceCategoryType?.ID,
        refCmsMasterContent?.id,
        payload
      );
    }
  };

  const downloadDocument = async () => {
    setLoadSpinner(true);
    await AssessmentActions.PersonalityAssessmentDocumentDownload().then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          var bufferArray = base64ToArrayBuffer(resData?.response);
          var blobStore = new Blob([bufferArray], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobStore);
            return;
          }
          var data = window.URL.createObjectURL(blobStore);
          var link = document.createElement("a");
          document.body.appendChild(link);
          link.href = data;
          let date = Moment(new Date()).format("YYYY-MM-DD");
          let time = Moment(new Date()).format("HH-mm-ss");
          link.download = `${cmsMasterContent?.assessmentTitle}-${
            date + " " + time
          }.pdf`;
          link.click();
          setLoadSpinner(false);

          let payload = {
            candidateId: candidateID,
            learningResourceId: cmsMasterContent?.id,
            learningResourceStatus: "completed",
            learningResourceType: cmsMasterContent?.resourceCategoryType?.ID,
            resourceTypeId: cmsMasterContent?.resourceType?.ID,
            isSaved: assessmentStartPageData?.isSaved,
            isLiked: assessmentStartPageData?.isLiked,
            lastAnsweredPageId: assessmentStartPageData?.lastAnsweredPageId,
            isDownloaded: true,
            isSharedWithCoach: assessmentStartPageData?.isSharedWithCoach,
            resultDocumentId: assessmentStartPageData?.resultDocumentId,
          };
          saveAssessmentStatus(
            cmsMasterContent?.resourceCategoryType?.ID,
            cmsMasterContent?.id,
            payload
          );
        } else {
          setLoadSpinner(false);
        }
      },
      (err) => {
        setLoadSpinner(false);
        console.log(err);
      }
    );
  };

  useEffect(() => {
    const timer = setInterval(() => isFileAvailable && viewHandler(), 30000);
    return () => clearInterval(timer);
  }, [isFileAvailable]);

  const viewHandler = async () => {
    await AssessmentActions.PersonalityAssessmentDocumentDownload().then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          setIsFileAvailable(false);
          setFileNotAvailable(true);

          let bufferArray = base64ToArrayBuffer(resData?.response);
          let blobStore = new Blob([bufferArray], { type: "application/pdf" });
          setBlobStore(blobStore);

          /* embed is working only for pdf and images */
          let reader = new FileReader();
          reader.readAsDataURL(blobStore);
          reader.onloadend = function () {
            let base64data =
              reader.result + "#toolbar=0&page=1&view=FitH&zoom=100";
            setEmbedFile(
              "data:application/pdf;base64," +
                base64data.replace("data:", "").replace(/^.+,/, "")
            );
            setEmbedFileType("application/pdf");
          };
        } else if (res.status === 400) {
          setIsFileAvailable(true);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  useEffect(() => {
    if (
      assessmentStartPageData !== undefined &&
      (assessmentStartPageData?.resultDocumentId === undefined ||
        assessmentStartPageData?.resultDocumentId === null) &&
      blobStore !== undefined
    ) {
      uploadResult();
    }
  }, [assessmentStartPageData, blobStore]);

  const uploadResult = async () => {
    let refAssessmentStartPageData = JSON.parse(
      JSON.stringify(assessmentStartPageData)
    );
    let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
    let date = Moment(new Date()).format("YYYY-MM-DD");
    let time = Moment(new Date()).format("HH-mm-ss");
    let file = new File(
      [blobStore],
      `Personality Assessment-${date + " " + time}.pdf`,
      { type: "application/pdf" }
    );
    let formData = new FormData();
    formData.append(file.name, file);
    formData.append("OwnerId", candidateID);
    formData.append("OwnerType", "Candidate");

    MyDocumentsActions.UploadDocuments(formData).then(
      (res) => {
        if (res.status === 200) {
          let payload = {
            candidateId: candidateID,
            learningResourceId: refCmsMasterContent?.id,
            learningResourceStatus:
              refAssessmentStartPageData?.learningResourceStatus,
            learningResourceType: refCmsMasterContent?.resourceCategoryType?.ID,
            resourceTypeId: refCmsMasterContent?.resourceType?.ID,
            isSaved: refAssessmentStartPageData?.isSaved,
            isLiked: refAssessmentStartPageData?.isLiked,
            isRetaken: refAssessmentStartPageData?.isRetaken,
            lastAnsweredPageId: refAssessmentStartPageData?.lastAnsweredPageId,
            isDownloaded: refAssessmentStartPageData?.isDownloaded,
            isSharedWithCoach: refAssessmentStartPageData?.isSharedWithCoach,
            resultDocumentId: res?.data[0]?.id,
          };
          saveAssessmentStatus(
            refCmsMasterContent?.resourceCategoryType?.ID,
            refCmsMasterContent?.id,
            payload
          );
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const getMyCoach = async () => {
    let coachData = await coachActions.GetMyCoach();
    setcoachdata(coachData?.data);
  };

  const shareResultWithCoach = () => {
    setLoadSpinner(true);
    let payload = {
      assessmentName: cmsMasterContent?.assessmentTitle,
      assessmentId: cmsMasterContent?.id,
      documentId: assessmentStartPageData?.resultDocumentId,
    };
    AssessmentActions.ShareResultWithCoach(payload).then(
      (res) => {
        if (res.status === 201) {
          setLoadSpinner(false);
          let payload = {
            candidateId: candidateID,
            learningResourceId: cmsMasterContent?.id,
            learningResourceStatus: "completed",
            learningResourceType: cmsMasterContent?.resourceCategoryType?.ID,
            resourceTypeId: cmsMasterContent?.resourceType?.ID,
            isSaved: assessmentStartPageData?.isSaved,
            isLiked: assessmentStartPageData?.isLiked,
            lastAnsweredPageId: assessmentStartPageData?.lastAnsweredPageId,
            isDownloaded: assessmentStartPageData?.isDownloaded,
            isSharedWithCoach: true,
            resultDocumentId: assessmentStartPageData?.resultDocumentId,
          };
          saveAssessmentStatus(
            cmsMasterContent?.resourceCategoryType?.ID,
            cmsMasterContent?.id,
            payload,
            "shareResultsWithCoach"
          );
        } else {
          setLoadSpinner(false);
        }
      },
      (err) => {
        console.log(err);
        setLoadSpinner(false);
      }
    );
  };

  const saveAssessmentStatus = async (
    resourceCategoryTypeId,
    id,
    payload,
    shareResultsWithCoach
  ) => {
    let getStatus = false;
    await AssessmentActions.SaveAssessmentStatus(
      resourceCategoryTypeId,
      id,
      payload
    ).then(
      (res) => {
        if (res.status === 200) {
          getStatus = true;
          if (shareResultsWithCoach === "shareResultsWithCoach") {
            context.setIsPopUpVisible({
              status: true,
              message: fetchLabel(
                "CommonResourceComponent.AssessmentConfirmationMessage"
              ),
              closeText: fetchLabel("CommonComponent.CommonClose"),
            });
          }
        } else {
          setScreenLoader(false);
          if (shareResultsWithCoach === "shareResultsWithCoach") {
            context.setIsPopUpVisible({
              status: true,
              message: fetchLabel(
                "CommonResourceComponent.AssessmentConfirmationMessage"
              ),
              closeText: fetchLabel("CommonComponent.CommonClose"),
            });
          }
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
        context.setIsPopUpVisible({
          status: false,
          message: "",
          closeText: "",
        });
      }
    );

    if (getStatus) {
      let startPageResponse = await AssessmentActions.GetAssessmentStatusById(
        id
      );
      setAssessmentStartPageData(startPageResponse?.data);
    }
  };

  const fetchLabel = (key) => {
    let refCmsMasterContent = JSON.parse(JSON.stringify(cmsMasterContent));
    let label = "";
    if (refCmsMasterContent?.masterLabel) {
      refCmsMasterContent?.masterLabel.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  function base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="assessment-page">
      <Container className="pt-4 pb-4">
        <Row className="justify-content-between">
          <Col md={2} lg={2} xs={{ span: 6, order: 1 }}>
            <Cirrus.Button
              isSecondary
              onClick={() =>
                navigate("/" + pageOrgin.id + "/personality-assessment")
              }
            >
              {fetchLabel("CommonComponent.CommonBackButton")}
            </Cirrus.Button>
          </Col>
          <Col
            md={2}
            lg={2}
            xs={{ span: 6, order: 2 }}
            className="text-end exit"
          >
            <Cirrus.Button onClick={() => navigate("/" + pageOrgin.id)}>
              {fetchLabel("CommonResourceComponent.Exit")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>

      <Container className="assessment-review-result-wrapper assessment-result-wrapper p-5 mb-5">
        {loadSpinner && (
          <div className="loader">
            <Cirrus.Spinner size={50} />
          </div>
        )}
        <Row className="mb-5 pb-3 border-bottom you-are-done-wrapper">
          <Col lg={6}>
            <Cirrus.Header size="h4" className="mb-3 text-start">
              {cmsMasterContent?.title}
            </Cirrus.Header>
          </Col>
          {/* fileNotAvailable ? false : true */}
          <Col lg={6} className="download-share-retake text-end">
            <Cirrus.Button
              isSecondary
              disabled={
                Object.keys(coachdata).length !== 0
                  ? fileNotAvailable
                    ? false
                    : true
                  : true
              }
              onClick={() => shareResultWithCoach()}
            >
              {fetchLabel("CommonResourceComponent.ShareResultsWithCoach")}
            </Cirrus.Button>
          </Col>
        </Row>
        <Row className="pl-5 ml-5  pb-2">
          <Col lg={10} className="pl-5 mb-5 text-start">
            <p className="mb-3">
              <Cirrus.RichText
                content={cmsMasterContent?.description}
                className="instructions"
              />
            </p>
            <Cirrus.Button
              disabled={fileNotAvailable ? false : true}
              isSecondary
              onClick={downloadDocument}
            >
              {fetchLabel("CommonResourceComponent.DownloadDocument")}
            </Cirrus.Button>
          </Col>
        </Row>
        {!fileNotAvailable && (
          <Row className="pl-5 ml-5  pb-2">
            <Col lg={12} className="pl-5 mb-5 text-start">
              {fetchLabel("CommonResourceComponent.ResultNotAvailable")}
            </Col>
          </Row>
        )}
        {fileNotAvailable && (
          <Row className="pl-5 ml-5  pb-2">
            <Col lg={12} className="pl-5 mb-5 text-start">
              <embed
                src={embedFile}
                type={embedFileType}
                width="100%"
                style={{ minHeight: "450px", maxHeight: "100%" }}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default ResultPage;
