//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useNavigate } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import { useEffect, useState } from 'react';
import Header from '../../header/simpleHeader';
import { OnBoardingActions } from "../OnBoardingActions";

function SearchAndSelect() {
    const [questionShortName, setQuestionShortName] = useState();
    const [answerShortName, setAnswerShortName] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("/question-2");
    }
    const exitBtnClick = () => {
        setIsModal(true);
    }
    const modalClose = () => {
        setIsModal(false);
    }
    const saveExitClick = () => {
        setIsModal(false);
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        if (answerShortName == "") {
            onBoardingQuestions.map((questions) => {
                if (questions.enablesearch) {
                    savePreferences = {
                        "questionShortName": questions.questionShortName,
                        "answerShortName": answerShortName.toString()
                    }
                }
            })
        } else {
            savePreferences = {
                "questionShortName": questionShortName,
                "answerShortName": answerShortName.toString()
            }
        }
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigate("/myprofile");
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    let answerShortNameArr = [];
    const checkedAnswers = (e) => {
        onBoardingQuestions.map((questions) => {
            let options = questions.options.map((options) => {
                if (options.id == e.target.id) {
                    if (e.target.checked) {
                        answerShortNameArr.push(e.target.name);
                        setQuestionShortName(questions.questionShortName);
                        setAnswerShortName(answerShortNameArr);
                    } else {
                        const unchecked = answerShortNameArr.indexOf(e.target.name);
                        if (unchecked > -1) {
                            answerShortNameArr.splice(unchecked, 1);
                        }
                        setAnswerShortName(answerShortNameArr);
                    }
                }
            })
        })
    }
    const [onBoardingQuestions, setOnBoardingQuestions] = useState([
        {
            "desc": "What are sectors interested?",
            "questionShortName": "sector",
            "isoptional": true,
            "multiselect": true,
            "enablesearch": true,
            "options": [
                {
                    id: "Finance ID",
                    label: "Finance Label",
                    name: "Finance Name",
                    value: 'finance Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Manufacturing ID",
                    label: "Manufacturing Label",
                    name: "Manufacturing Name",
                    value: 'Manufacturing Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Banking ID",
                    label: "Banking Label",
                    name: "Banking Name",
                    value: 'Banking Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Automation ID",
                    label: "Automation Label",
                    name: "Automation Name",
                    value: 'Automation Value',
                    defaultChecked: false,
                    onChange: checkedAnswers
                }
            ]
        },
        {
            "desc": "What best describe you?",
            "questionShortName": "describe",
            "isoptional": false,
            "multiselect": true,
            "enablesearch": false,
            "options": [
                {
                    id: "Reason_Job",
                    label: "Find a new job quickly",
                    name: "Reason_Job",
                    value: 'newjob',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Career",
                    label: "Progress my career",
                    name: "Reason_Career",
                    value: 'career',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Retire",
                    label: "Help with retirement",
                    name: "Reason_Retire",
                    value: 'retire',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: "Reason_Business",
                    label: "Start my own business",
                    name: "Reason_Business",
                    value: 'business',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
            ]
        },
        {
            "desc": "What is your level of experience?",
            "questionShortName": "experience",
            "isoptional": false,
            "multiselect": false,
            "enablesearch": false,
            "options": [
                {
                    id: '2_5_years',
                    labelText: '2-5 years',
                    name: 'expYears',
                    value: '2_5_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '5_10_years',
                    labelText: '5-10 years',
                    name: 'expYears',
                    value: '5_10_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '10_15_years',
                    labelText: '10-15 years',
                    name: 'expYears',
                    value: '10_15_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                },
                {
                    id: '15_20_years',
                    labelText: '15-20 years',
                    name: 'expYears',
                    value: '15_20_years',
                    defaultChecked: false,
                    onChange: checkedAnswers
                }
            ]
        }
    ]);
    const nextHandler = async (e) => {
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        if (answerShortName == "") {
            onBoardingQuestions.map((questions) => {
                if (questions.enablesearch) {
                    savePreferences = {
                        "questionShortName": questions.questionShortName,
                        "answerShortName": answerShortName.toString()
                    }
                }
            })
        } else {
            savePreferences = {
                "questionShortName": questionShortName,
                "answerShortName": answerShortName.toString()
            }
        }
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigateTo();
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    const skipHandler = async (e) => {
        let savePreferences;
        let canID = JSON.parse(window.sessionStorage.getItem('LoginCandidateToken'));
        onBoardingQuestions.map((questions) => {
            if (questions.enablesearch) {
                savePreferences = {
                    "questionShortName": questions.questionShortName,
                    "answerShortName": ""
                }
            }
        })
        OnBoardingActions.saveCandidatePreferences(canID, savePreferences).then(res => {
            if (res.status == '200') {
                navigateTo();
            } else {

            }
        }, err => {
            console.log(err);
        })
    }
    return (
        <>
            <Cirrus.FullHeightContainerLayout
                ref={{
                    current: '[Circular]'
                }}
                footerHeight={60}
                headerHeight={90}
            >
                {onBoardingQuestions.map((questions, index) => {
                    if (questions.enablesearch) {
                        return (
                            <Cirrus.Container key={index}
                                ref={{
                                    current: '[Circular]'
                                }}
                                exitButton={{
                                    children: 'Exit',
                                    isSecondary: true,
                                    onClick: exitBtnClick,
                                    size: 'Small',
                                    type: 'button'
                                }}
                                heading={questions.desc}
                                primaryButton={{
                                    children: 'Next',
                                    onClick: () => nextHandler(),
                                    size: 'Large',
                                    type: 'button'
                                }}
                                secondaryButton={{
                                    children: 'Skip',
                                    isSecondary: true,
                                    onClick: skipHandler,
                                    size: 'Large',
                                    type: 'button'
                                }}
                                subheading="Please select all that apply"
                            >
                                <Cirrus.MultipleSelectQuestion
                                    fixedHeightReference={{
                                        current: '[Circular]'
                                    }}
                                    heightReference={{
                                        current: '[Circular]'
                                    }}
                                    options={questions.options}
                                    searchInput={{
                                        hideLabel: true,
                                        icon: 'search',
                                        id: 'search',
                                        isRounded: true,
                                        label: 'search',
                                        name: 'search',
                                        onChange: () => { },
                                        placeholder: 'Search here',
                                        type: 'text'
                                    }}
                                />
                                {isModal &&
                                    <Cirrus.AlertModal
                                        closeIconButtonProps={{
                                            icon: 'close',
                                            isSecondary: true,
                                            onClick: modalClose,
                                            text: 'Cancel',
                                            type: 'button'
                                        }}
                                        heading="Exit Page"
                                        primaryButton={{
                                            children: 'Save and exit',
                                            onClick: saveExitClick,
                                            size: 'Large',
                                            type: 'button'
                                        }}
                                        role="alertdialog"
                                        subheading="Are you sure you want to leave building your profile? we will save your answers and You can always complete or edit your answers later on in your profile section."
                                    />}
                            </Cirrus.Container>
                        );
                    }
                })}


            </Cirrus.FullHeightContainerLayout>
        </>
    )
}

export default SearchAndSelect;
