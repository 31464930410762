//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import './job.css';

function ResourceCard({ data, cmsCardContent, loadMoreJobHandler, savedDataLength, viewAll, saveHandler }) {

  const fetchLearningStatus = (id) => {
    let refCmsContent = cmsCardContent && cmsCardContent?.commonComponent;
    let label = "";
    if (refCmsContent?.LOVList[0]?.Values) {
      refCmsContent?.LOVList[0]?.Values.map((x) => {
        if (x.ID === id) {
          label = x.Value;
        }
      });
    }
    return label;
  }

  const fetchSavedLabel = (id) => {
    let refCmsSavedContent = cmsCardContent && cmsCardContent?.commonComponent;
    let label = "";
    if (refCmsSavedContent?.Labels) {
      refCmsSavedContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  return (
    <>
      <ul className="jobListTwoColumn">
        {data?.map(bookmark => {
          return (
            <li key={bookmark.resourceId}>
              <Cirrus.Card className=""
                title={bookmark.title}
                description={bookmark.shortDescription}
                onCardClick={()=>{
                  window.open(`#/article-page/${bookmark?.resourceId}`, "_self");
                }}
                tags={bookmark?.focusArea?.map((item) => item.value)}
                image={{ src: bookmark?.largeImage?.imageUrl }}
                saveButton={{
                  // label: 'Save',
                  // secondaryLabel: "Saved",
                  label: fetchSavedLabel("CommonComponent.CommonSaveButton"),
                  secondaryLabel: fetchSavedLabel("CommonComponent.CommonSavedButton"),
                  onClick: (e) => { saveHandler(e, 'save', bookmark) }
                }}
                isSaved={bookmark && bookmark.isSaved && bookmark.isSaved}
                resourceType={{
                  name: bookmark?.resourceType?.id,
                  label: bookmark?.resourceType?.value,
                  value: bookmark && bookmark.learningResourceStatus === "notstarted" ? bookmark && bookmark.duration && bookmark.duration : fetchLearningStatus(bookmark && bookmark.learningResourceStatus),
                }}
                type='resource'
                layout={'horizontal'}
              />
            </li>
          )
        })}
      </ul>
      {
        viewAll === "Resources" && (savedDataLength && savedDataLength > 10 && savedDataLength !== data?.length) ? <>
          {
            <div style={{ paddingTop: '50px', width: '100%' }}>
              <Cirrus.Button
                onClick={loadMoreJobHandler}
              >
                {
                  'Load more'
                }
              </Cirrus.Button>
            </div>
          }
        </> : ''
      }
    </>
  )
}

export default ResourceCard
