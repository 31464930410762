//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import './index.css';

function MeetingConfirmtionDialog({ fetchLabel, cmsContent, mycoachData, myConfirmMeetingData, closeDialog }) {

    return (
        <>
            <Cirrus.Modal
                className="meetingConfirmModal"
                closeButtonProps={{
                    leadingIcon: "close",
                    isSecondary: false,
                    onClick: () => closeDialog(false),
                    children: "Close",
                    type: "button",
                }}
                closeIconButtonProps={{
                    icon: "close",
                    isSecondary: true,
                    onClick: () => closeDialog(false),
                    text: "Cancel",
                    type: "button",
                }}
                headerProps={{
                    children: cmsContent.meetingConfirm_Title,
                    className: "no-margin",
                    size: "h5",
                }}
                isScrollable
                role="dialog"
                size="mobile"
            >
                <div style={{ textAlign: "center", color: "black" }}>
                    <p>
                        {
                            fetchLabel("MyCoachPage.MyCoachMeetingConfirmation.Description", "meetingConfirm_Labels")
                                .replace("#NAME.", mycoachData?.firstName + " " + mycoachData?.lastName)
                                .replace("#DATE", myConfirmMeetingData.date)
                                .replace("#TIME", myConfirmMeetingData.time)
                        }
                    </p>
                </div>

                <div>
                    <Cirrus.Button
                        size="Large"
                        disabled={false}
                        onClick={() => closeDialog(false)}
                        isFullWidth={true}
                    >
                        {fetchLabel(
                            "CommonComponent.CommonClose",
                            "commonComponents_Labels"
                        )}
                    </Cirrus.Button>
                </div>

                <div className="mt-2">
                    <Cirrus.Button
                        isSecondary
                        disabled={true}
                        onClick={() => alert("Schedule a personal event")}
                        isFullWidth={true}
                    >
                        {fetchLabel(
                            "MyCoachPage.MyCoachMeetingConfirmation.ScheduleNewMeeting",
                            "meetingConfirm_Labels"
                        )}
                    </Cirrus.Button>
                </div>
            </Cirrus.Modal>
        </>
    );
}

export default MeetingConfirmtionDialog;
