//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const ArticleActions = {
    GetArticlesCMS: getArticlesCMS,
    SaveAssessmentCard: saveAssessmentCard,
    GetDiscoverData: getDiscoverData,
    UpdateStatus: updateStatus
}

function getArticlesCMS (payload) {
    return http.CMSpost(environments.ASSESSMENTS_CMS, payload);
}

function saveAssessmentCard(resourceType, id, payload) {
    let url = environments.SAVE_ASSESSMENT_CARD.replace("{resourcetype}", resourceType).replace("{id}", id)
    return http.learningResourcePut(url, payload);
}

function getDiscoverData(payload) {
    let url = environments.ASSESSMENT_CARD_DATA
    return http.learningResourcePost(url, payload);
}

function updateStatus(resourceType, id, payload) {
    return http.AssessmentsPut(environments.SAVE_ASSESSMENT_CARD.replace('{resourcetype}', resourceType).replace('{id}', id), payload);
}
