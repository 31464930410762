//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate, useParams } from "react-router-dom";
import { Context } from '../../../common/context/Context';
import * as Cirrus from 'manpowergroup-cirrus';
import { AssessmentActions } from '../AssessmentActions';
import HeaderWithNavigation from "../../header/headerWithNavigation";
import Footer from "../../../common/components/footer";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "../../../assets/fonts/icomoon/Icon";
import Spinner from '../../../common/components/spinner-loader/Spinner';
import '../style.css';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

const IdentifyYourIdealPosition = () => {
    const pageOrgin = useParams();
    const context = useContext(Context);
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const [screenLoader, setScreenLoader] = useState(false);
    const [cmsIYIPContent, setCmsIYIPContent] = useState();
    const [startPageData, setStartPageData] = useState();
    let path = JSON.parse(window.sessionStorage.getItem("setPath"));

    useEffect(() => {
        fetchCMSData()
        fetchStartPageData();
        window.scrollTo(0, 0)
    }, [context.language])

    const fetchCMSData = async () => {
        setScreenLoader(true);
        let refCmsAssessmentContent = {};
        let payload = {
            PageName: "IdentifyYourIdealPosition"
        };
        await AssessmentActions.GetAssessmentCMS(payload).then(async (res) => {
            if (res.status === 200) {
                let resData = res.data;

                ActivityAction.SaveActivity(resData?.ID, "startassessment", resData?.Title)
                TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                TrackActions.PageAction("ViewCard", { "Entity Id": resData?.ID, "Entity Name": resData?.Title, "CardType": "Assessment" })
                refCmsAssessmentContent.browserTitle = resData.BrowserTitle;
                refCmsAssessmentContent.metaDescription = resData.MetaDescription;
                refCmsAssessmentContent.metadata = resData.Metadata;
                refCmsAssessmentContent.title = resData.Title;
                refCmsAssessmentContent.focusArea = resData.FocusArea[0];
                refCmsAssessmentContent.resourceType = resData.ResourceType;
                refCmsAssessmentContent.resourceCategoryType = resData.ResourceCategoryType;
                refCmsAssessmentContent.duration = resData.Duration;
                refCmsAssessmentContent.description = resData.Description;
                refCmsAssessmentContent.shortDescription = resData.ShortDescription;
                refCmsAssessmentContent.subTitle = resData.SubTitle;
                refCmsAssessmentContent.internalAssessmentId = resData.ID;
                refCmsAssessmentContent.externalAssessmentId = resData.ExternalId;
                refCmsAssessmentContent.commonLabels = resData.Components.CommonComponent.Labels;
                refCmsAssessmentContent.commonResourceLabels = resData.Components.CommonResourceComponent.Labels;
                refCmsAssessmentContent.largeImage = resData.LargeImage.ImageUrl;
                refCmsAssessmentContent.mediumImage = resData.MediumImage;
                refCmsAssessmentContent.smallImage = resData.SmallImage;
                refCmsAssessmentContent.commonStatus = resData.Components.CommonComponent.LOVList[0].Values;
                refCmsAssessmentContent.assessmentQuestionSet = resData.ChildPages.filter(x => x.Key !== "Assessments.IdentifyYourIdealPosition.AssessmentComplete");

                let questionsArr = [];
                refCmsAssessmentContent.assessmentQuestionSet.map(assessment => {
                    Object.keys(assessment.Components).forEach(key => {
                        if (key === "Resources.Assessments.IdentifyYourIdealPosition.AboutTheOrganisation") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                        if (key === "Resources.Assessments.IdentifyYourIdealPosition.AboutTheJobRole") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                        if (key === "Resources.Assessments.IdentifyYourIdealPosition.Compensation") {
                            questionsArr.push({ ...assessment, QuestionList: assessment.Components[key].QuestionList })
                        }
                    })
                });
                refCmsAssessmentContent.assessmentQuestionSet = questionsArr;

                refCmsAssessmentContent.resultPage = resData.ChildPages.find(x => x.Key === "Assessments.IdentifyYourIdealPosition.AssessmentComplete")
                setCmsIYIPContent(refCmsAssessmentContent);

                context.setCmsIdentifyYourIdealPositionContent(refCmsAssessmentContent);
                setScreenLoader(false);
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }


    const fetchStartPageData = async () => {
        let resourceType = "assessmentsandtasks";
        let payload = {
            "learningResourceType": resourceType,
            "recommended": "false",
            "isAllLearningResources": "true",
            "ResourceCategory": resourceType
        }
        await AssessmentActions.GetStartPageData(payload).then((res) => {
            if (res.status === 200) {
                let resData = res?.data && res?.data?.responsemodel;
                let refData = resData?.filter(x => x.key === "Assessments.IdentifyYourIdealPosition")
                setStartPageData(refData && refData[0] && refData[0]);
            }
        }, (err) => {
            console.log(err);
        }
        );
    }

    const fetchCommonLabel = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsIYIPContent));
        let label = '';
        if (refCmsAssessmentContent?.commonLabels) {
            refCmsAssessmentContent?.commonLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchCommonStatus = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsIYIPContent));
        let label = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    label = x.Value
                }
            })
        }
        return label;
    }

    const fetchCommonResourceLabel = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsIYIPContent));
        let label = '';
        if (refCmsAssessmentContent?.commonResourceLabels) {
            refCmsAssessmentContent?.commonResourceLabels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchStatusID = (key) => {
        let refCmsAssessmentContent = JSON.parse(JSON.stringify(cmsIYIPContent));
        let id = '';
        if (refCmsAssessmentContent?.commonStatus) {
            refCmsAssessmentContent?.commonStatus.map((x) => {
                if (x.Key === key) {
                    id = x.ID
                }
            })
        }
        return id;
    }

    const saveAndLikeHandler = async (type) => {
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            "candidateId": candidateID,
            "learningResourceId": cmsIYIPContent.internalAssessmentId,
            "learningResourceStatus": startPageData.learningResourceStatus,
            "learningResourceType": cmsIYIPContent.resourceCategoryType?.ID,
            "resourceTypeId": cmsIYIPContent.resourceType?.ID,
            "isSaved": type === "save" ? startPageData.isSaved == true ? false : true : startPageData.isSaved,
            "isLiked": type === "like" ? startPageData.isLiked == true ? false : true : startPageData.isLiked,
            "lastAnsweredPageId": startPageData.lastAnsweredPageId,
            "isDownloaded": startPageData?.isDownloaded,
            "isSharedWithCoach": startPageData?.isSharedWithCoach,
            "resultDocumentId": startPageData?.resultDocumentId
        }
        AssessmentActions.SaveAssessmentStatus(cmsIYIPContent.resourceCategoryType.ID, cmsIYIPContent.internalAssessmentId, payload).then(res => {
            if (res.status === 200) {
                fetchStartPageData();
            }
        }, err => {
            console.log(err);
        })
    }

    const updateAssessmentStatus = () => {
        setScreenLoader(true)
        if (startPageData.learningResourceStatus === fetchStatusID("ResourceStatus.Completed")) {
            AssessmentActions.RetakeAssessment(cmsIYIPContent.internalAssessmentId).then(res => {
                if (res.status === 200) {
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsIYIPContent?.internalAssessmentId, "InternalAssessmentName": cmsIYIPContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsIYIPContent?.internalAssessmentId, "InternalAssessmentName": cmsIYIPContent?.title })
                    setScreenLoader(false);
                    navigate("/" + pageOrgin.id + "/identify-your-ideal-position/assessment");
                } else {
                    setScreenLoader(false);
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        } else {
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let payload = {
                "candidateId": candidateID,
                "learningResourceId": cmsIYIPContent.internalAssessmentId,
                "learningResourceStatus": fetchStatusID("ResourceStatus.InProgress"),
                "learningResourceType": cmsIYIPContent.resourceCategoryType.ID,
                "resourceTypeId": cmsIYIPContent.resourceType?.ID,
                "isSaved": startPageData.isSaved,
                "isLiked": startPageData.isLiked,
                "lastAnsweredPageId": startPageData.lastAnsweredPageId,
                "isDownloaded": startPageData?.isDownloaded,
                "isSharedWithCoach": startPageData?.isSharedWithCoach,
                "resultDocumentId": startPageData?.resultDocumentId
            }
            AssessmentActions.SaveAssessmentStatus(cmsIYIPContent.resourceCategoryType?.ID, cmsIYIPContent.internalAssessmentId, payload).then(async (res) => {
                if (res.status === 200) {
                    setScreenLoader(false)
                    TrackActions.EventTrack(appInsights, "StartinternalAssessment", { "InternalAssessmentId": cmsIYIPContent?.internalAssessmentId, "InternalAssessmentName": cmsIYIPContent?.title })
                    TrackActions.PageAction("StartinternalAssessment", { "InternalAssessmentId": cmsIYIPContent?.internalAssessmentId, "InternalAssessmentName": cmsIYIPContent?.title })
                    navigate("/" + pageOrgin.id + "/identify-your-ideal-position/assessment");
                } else {
                    setScreenLoader(false)
                }
            }, err => {
                console.log(err);
                setScreenLoader(false);
            })
        }
    }

    const isRedirectHandler = () => {
        sessionStorage.removeItem("EJO-IYIP-Redirection-Flag");
        navigate("/" + pageOrgin.id + "/evaluate-a-job-offer")
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner screenLoader={true} spinnerTimer={screenLoader} />
            </div>
        );
    }

    return (
        <div className="assessment-page">
            {/* <Cirrus.Page className='assessment-page'
                background="secondary"
                header={<HeaderWithNavigation />}
                removeFooterPadding
            > */}

            {
                cmsIYIPContent &&
                <>
                    <Container fluid className='background-White p-lg-5 pb-lg-0 pb-md-0 p-4 custom-mb-20'>
                        <Row className='justify-content-between'>
                            <Col md="12" lg="7" className='mb-3 pb-lg-5'>
                                {
                                    JSON.parse(window.sessionStorage.getItem("EJO-IYIP-Redirection-Flag")) === true ? <>
                                        <Cirrus.Button isSecondary onClick={isRedirectHandler} className="mb-lg-5 mb-3">{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                    </> : <>
                                        <Cirrus.Button isSecondary onClick={() => { navigate(`/${context.path ? context.path : path}`) }} className="mb-5">{fetchCommonLabel("CommonComponent.CommonBackButton")}</Cirrus.Button>
                                    </>
                                }
                                <Cirrus.Header size='h3' className='assessment-title'>{cmsIYIPContent?.title}</Cirrus.Header>
                                <div className='save-section mt-4 mb-3'>
                                    <button className='saveBtn' onClick={() => { saveAndLikeHandler('save') }}>
                                        {startPageData?.isSaved === true ? <i className='icon-saved'></i> : <i className='icon-save'></i>}
                                        <span className='saveBtnText'>
                                            {startPageData?.isSaved === true ? fetchCommonResourceLabel("CommonResourceComponent.Saved") : fetchCommonLabel("CommonComponent.CommonSaveButton")}
                                        </span>
                                    </button>
                                    <Cirrus.Label id='category-label'>{cmsIYIPContent?.focusArea?.Value}</Cirrus.Label>
                                    <Cirrus.Label id="cardtype">
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                            <>
                                                {cmsIYIPContent?.resourceType?.Value} <sup>.</sup>
                                                <span>{cmsIYIPContent?.duration}</span>
                                            </>
                                        }

                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.InProgress")}
                                            </>
                                        }
                                        {
                                            startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                            <>
                                                {fetchCommonStatus("ResourceStatus.Completed")}
                                            </>
                                        }
                                    </Cirrus.Label>
                                </div>
                                <div>
                                    <Cirrus.Button size='small' className='likeBtn' onClick={() => { saveAndLikeHandler('like') }}>
                                        {startPageData?.isLiked === true ? <Icon icon="heart_fill" size={16} color="#9D323D" /> : <Icon icon="heart" size={16} color="#000" />}
                                        <span className='likeBtnText'>
                                            {startPageData?.likeCount <= 1 ? startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikeText").toUpperCase() : startPageData?.likeCount + " " + fetchCommonLabel("CommonComponent.CommonLikesText").toUpperCase()}
                                        </span>
                                    </Cirrus.Button>
                                </div>
                            </Col>
                            <Col md="12" lg="5" className='position-relative'>
                                <div className='assesment_startpage-image-wrapper'>
                                    <img src={cmsIYIPContent.largeImage} alt='sideimage' className='image-overflow' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='p-lg-5 p-3 pb-lg-0 pb-md-0'>
                        <Row>
                            <Col md="12" lg="6" className='assessment_second-container-intro'>
                                <Cirrus.Headline size='Medium' className='mb-5 assessment_introHeading'>{cmsIYIPContent?.shortDescription}</Cirrus.Headline>
                                <Cirrus.Header size='h5' className='mb-3'>{cmsIYIPContent?.subTitle}</Cirrus.Header>
                                <p className='mb-3'>
                                    <Cirrus.RichText content={cmsIYIPContent?.description} className="instructions" />
                                </p>

                                <Cirrus.Button className='my-4' onClick={() => { updateAssessmentStatus(cmsIYIPContent?.internalAssessmentId) }}>
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.NotStarted") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonStartButton")}
                                        </>
                                    }

                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.InProgress") &&
                                        <>
                                            {fetchCommonLabel("CommonComponent.CommonContinueButton")}
                                        </>
                                    }
                                    {
                                        startPageData?.learningResourceStatus?.toLowerCase() === fetchStatusID("ResourceStatus.Completed") &&
                                        <>
                                            {fetchCommonLabel("CommonComponentCommonRetakeButton")}
                                        </>
                                    }
                                </Cirrus.Button>

                            </Col>
                            <Col md="6" lg="6">

                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {/* </Cirrus.Page>
            <Footer isLoggedIn={true} className="login-footer" /> */}
        </div>
    )
}

export default IdentifyYourIdealPosition;
